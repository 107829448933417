// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-TaskEditor-SaveButton-_SaveButton__root{display:flex;margin-top:40px;justify-content:space-between;align-items:center}.src-client-modules-common-TaskEditor-SaveButton-_SaveButton__buttonWrapper{margin-left:auto;width:180px}.src-client-modules-common-TaskEditor-SaveButton-_SaveButton__button{height:32px}.src-client-modules-common-TaskEditor-SaveButton-_SaveButton__requiredFieldsMarker{font-family:'Open Sans';font-size:14px;line-height:18px;letter-spacing:0.25px;color:#000000}.src-client-modules-common-TaskEditor-SaveButton-_SaveButton__requiredFieldsMarkerGray{color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/TaskEditor/SaveButton/SaveButton.scss"],"names":[],"mappings":"AAAA,mEACI,YAAa,CACb,eAAgB,CAChB,6BAA8B,CAC9B,kBAAmB,CACtB,4EAGG,gBAAiB,CACjB,WAAY,CACf,qEAGG,WAAY,CACf,mFAGG,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CAEjB,qBAAsB,CAEtB,aAAc,CACjB,uFAGG,aAAc","sourcesContent":[".root {\n    display: flex;\n    margin-top: 40px;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.buttonWrapper {\n    margin-left: auto;\n    width: 180px;\n}\n\n.button {\n    height: 32px;\n}\n\n.requiredFieldsMarker {\n    font-family: 'Open Sans';\n    font-size: 14px;\n    line-height: 18px;\n\n    letter-spacing: 0.25px;\n\n    color: #000000;\n}\n\n.requiredFieldsMarkerGray {\n    color: #7E8681;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-TaskEditor-SaveButton-_SaveButton__root",
	"buttonWrapper": "src-client-modules-common-TaskEditor-SaveButton-_SaveButton__buttonWrapper",
	"button": "src-client-modules-common-TaskEditor-SaveButton-_SaveButton__button",
	"requiredFieldsMarker": "src-client-modules-common-TaskEditor-SaveButton-_SaveButton__requiredFieldsMarker",
	"requiredFieldsMarkerGray": "src-client-modules-common-TaskEditor-SaveButton-_SaveButton__requiredFieldsMarkerGray"
};
export default ___CSS_LOADER_EXPORT___;
