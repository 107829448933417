import * as React from 'react';
import * as style from './CreativeCard.scss';
import classNames from 'classnames';
import { StageName } from '../../types';
import {
    CardBody,
    CardBodyIndicatorColor,
    CardBodyIndicatorPosition,
    CardContent,
    CardContentLeftSidebar,
    CardContentRightSidebar,
    CardAuthor,
    CardDate,
} from '@common/Card';
import { CardTitle } from './CardTitle';
import { Footer } from './Footer';
import { RowWithLinks } from './RowWithLinks/RowWithLinks';
import * as moment from 'moment';
import { CreativeRequestByBudgetEntry } from '@sbermarketing/mrm-metacom-client';

interface Props {
    isLoading: boolean;
    data: Partial<CreativeRequestByBudgetEntry>;
}

type ColorStageMap = Record<StageName, CardBodyIndicatorColor>;

const colorStageMap: ColorStageMap = {
    [StageName.PREPARATION]: CardBodyIndicatorColor.YELLOW,
    [StageName.EXECUTION]: CardBodyIndicatorColor.GREEN,
    [StageName.RESULTS]: CardBodyIndicatorColor.BLUE,
    [StageName.COMPLETED]: CardBodyIndicatorColor.GRAY,
};

const STATUS_PARAMS_BY_STAGE = {
    draft: {
        title: 'Создание',
        className: style.gray,
    },
    fillingByCustomer: {
        title: 'Заполнение',
        className: style.orange,
    },
    fillingByAgency: {
        title: 'Заполнение',
        className: style.orange,
    },
    checkingByCustomer: {
        title: 'Проверка',
        className: style.gray,
    },
    approvement: {
        title: 'Согласование',
        className: style.green,
    },
    actClosing: {
        title: 'Согласование',
        className: style.green,
    },
    closed: {
        title: 'Закрыта',
        className: style.blue,
    },
};

export const CreativeCard: React.FC<Props> = ({
    isLoading,
    data: {
        projectName,
        projectId,
        createdAt,
        customer,
        acceptors,
        donors,
        description,
        id,
        status,
        name,
        creativeRequestTableItemNumber,
    },
}) => {
    return (
        <CardBody
            indicator={{
                position: CardBodyIndicatorPosition.TOP,
                color: colorStageMap[CardBodyIndicatorColor.WHITE],
            }}
        >
            <CardContent>
                <CardContentLeftSidebar>
                    <RowWithLinks
                        label="Проект"
                        isLoading={isLoading}
                        links={[{ label: projectName, link: `/activity/${projectId}/tasks` }]}
                    />
                    <RowWithLinks
                        label="ID источника"
                        isLoading={isLoading}
                        links={donors?.map((item) => ({
                            label: String(item.serialNumber),
                            link: null,
                        }))}
                    />
                    <RowWithLinks
                        label="ID исполнения"
                        isLoading={isLoading}
                        links={acceptors?.map((item) => ({
                            label: String(item.serialNumber),
                            link: null,
                        }))}
                    />
                </CardContentLeftSidebar>
                <CardContentRightSidebar>
                    <CardDate startDate={moment(createdAt)} loading={isLoading} />
                    <CardAuthor name={customer} loading={isLoading} />
                </CardContentRightSidebar>
            </CardContent>
            <Footer>
                {status && (
                    <div className={classNames(style.status, STATUS_PARAMS_BY_STAGE[status].className)}>
                        {STATUS_PARAMS_BY_STAGE[status].title}
                    </div>
                )}
                <CardTitle loading={isLoading} link={`/activity/${projectId}/creative/${id}`}>
                    {name} - #{creativeRequestTableItemNumber}
                </CardTitle>
            </Footer>
        </CardBody>
    );
};
