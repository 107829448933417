import * as React from 'react';
import { AsyncPaginate as Select } from 'react-select-async-paginate';

interface Props {
    defaultValue?: Option | Option[];
    options?: Option[];
    placeholder?: string;
    isMulti?: boolean;
    onChange: OnChange;
    loadOptions?: LoadOptions;
}

interface Option {
    value: string;
    label: string;
}

interface OnChange {
    (option: Option | Option[]): void;
}

export interface LoadOptions {
    (searchQuery: string, loadedOptions: Option[], { page }: { page: number }): Promise<LoadOptionsResult>;
}

export interface LoadOptionsResult {
    options: Option[];
    hasMore: boolean;
    additional: {
        page: number;
    };
}

export const SelectInput: React.FC<Props> = ({
    options,
    defaultValue,
    placeholder,
    isMulti = false,
    loadOptions,
    onChange,
}) => {
    return (
        <Select
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: '#F8F8F8',
                    borderRadius: 6,
                    minHeight: 32,
                    border: 'none',
                    borderColor: 'initial',
                    boxShadow: 'none',
                    outline: 'none',
                    '&:hover': {
                        border: 'none',
                        borderColor: 'initial',
                        boxShadow: 'none',
                        outline: 'none',
                    },
                }),
                indicatorSeparator: (baseStyles) => ({
                    ...baseStyles,
                    display: 'none',
                }),
                dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    padding: 4,
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                    fontFamily: 'Open Sans',
                    fontSize: 14,
                }),
                option: (baseStyle, status) => ({
                    ...baseStyle,
                    fontFamily: 'Open Sans',
                    fontSize: 14,
                    backgroundColor: status.isSelected || status.isFocused ? '#F8F8F8' : 'none',
                    color: '#092A3A',
                }),
                menu: (baseStyle) => ({
                    ...baseStyle,
                    borderRadius: 4,
                    background: '#FFF',
                    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.10)',
                }),
                placeholder: (baseStyle) => ({
                    ...baseStyle,
                    fontFamily: 'Open Sans',
                    fontSize: 14,
                    color: '#7E8681',
                }),
            }}
            placeholder={placeholder || 'Выберите значение...'}
            loadOptions={loadOptions}
            options={options}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onChange}
            defaultValue={defaultValue}
            additional={{ page: 1 }}
            isMulti={isMulti}
        />
    );
};

export { Option, OnChange };
