import * as React from 'react';
import classNames from 'classnames';

import * as style from './LineMenu.scss';

import { LineMenuColor } from '../../TableBody';
import { Icon, IconType, DynamicIcon, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

interface Props {
    lineId: string;
    backgroundColor: string;
    lineIsHovered: boolean;
    isDisabledSendCorrectionButton: boolean;
    linePlanOverrunedByReserve: boolean;
    linePlanOverrunedByFact: boolean;
    lineHasPlanReserveCorrections: boolean;
    showTransferBudgetItemsToPlanningButton: boolean;
    lineHasChildrenBudgetItems: boolean;
    onEditActivityButtonClick: () => void;
    onCopyLineButtonClick: () => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onInfoClick: () => void;
    onSendToCorrectionButtonClick: () => Promise<void>;
    onOpenCorrectionsButtonClick: () => void;
    isSendCorrectionButtonDisabled: boolean;
    isEditActivityButtonDisabled: boolean;
    onPlanTransferClick: () => void;
    onTransferBudgetItemsToPlanningButtonClick: () => void;
    onRootClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    userCanEditOrCreateBudgetData: boolean;
}

export const LineMenu = ({
    lineId,
    backgroundColor,
    lineIsHovered,
    isDisabledSendCorrectionButton,
    linePlanOverrunedByReserve,
    linePlanOverrunedByFact,
    lineHasPlanReserveCorrections,
    showTransferBudgetItemsToPlanningButton,
    lineHasChildrenBudgetItems,
    onEditActivityButtonClick,
    onCopyLineButtonClick,
    onInfoMouseEnter,
    onInfoMouseLeave,
    onInfoClick,
    onSendToCorrectionButtonClick,
    onOpenCorrectionsButtonClick,
    isSendCorrectionButtonDisabled,
    isEditActivityButtonDisabled,
    onPlanTransferClick,
    onTransferBudgetItemsToPlanningButtonClick,
    onRootClick,
    userCanEditOrCreateBudgetData,
}: Props): JSX.Element => {
    const isExpanded = lineIsHovered;
    const planTransferQaParams: any = {
        'qa-id': 'lineMenuPlanTranferButton',
    };
    const infoQaParams: any = {
        'qa-id': 'lineMenuInfoButton',
    };
    const copyActivityQaParams: any = {
        'qa-id': 'lineMenuCopyActivityButton',
    };
    const editActivityQaParams: any = {
        'qa-id': 'lineMenuEditActivityButton',
        'qa-disabled': isEditActivityButtonDisabled ? 'true' : 'false',
    };
    const sendToCorrectionQaParams: any = {
        'qa-id': 'lineMenuSendToCorrectionButton',
        'qa-disabled': isSendCorrectionButtonDisabled ? 'true' : 'false',
    };
    const openCorrectionsQaParams: any = {
        'qa-id': 'lineMenuOpenCorrectionsButton',
    };
    const transferBudgetItemsToPlanningQaParams: any = {
        'qa-id': 'transferBudgetItemsToPlanningButton',
        'qa-disabled': lineHasChildrenBudgetItems ? 'true' : 'false',
    };

    const bgColor = LineMenuColor[backgroundColor];

    const linePlanOverruned = linePlanOverrunedByReserve || linePlanOverrunedByFact;

    return (
        <div
            onClick={onRootClick}
            className={classNames(style.root, isExpanded && style.expanded, lineIsHovered && style.lineHover)}
            {...{
                'qa-id': 'budgetExecutionLineMenu',
            }}
        >
            <div className={style.content}>
                <div
                    className={style.menu}
                    style={{
                        background: `linear-gradient(to right,
                            rgba(${bgColor}, 0.0) 0%,
                            rgba(${bgColor}, 1.0) 16px,
                            rgba(${bgColor}, 1.0) 100%
                        )`,
                    }}
                >
                    <div className={style.buttons}>
                        {userCanEditOrCreateBudgetData && linePlanOverruned && (
                            <div
                                className={style.button}
                                title={'Перенос планового бюджета'}
                                onClick={onPlanTransferClick}
                                {...planTransferQaParams}
                            >
                                <Icon type={IconType.TURN_ARROW} className={style.planTransferIcon} svgSize={24} />
                            </div>
                        )}

                        <WithTooltip content="Информация">
                            <div
                                className={style.button}
                                onMouseEnter={() => onInfoMouseEnter(lineId)}
                                onMouseLeave={onInfoMouseLeave}
                                onClick={onInfoClick}
                                {...infoQaParams}
                            >
                                <Icon
                                    type={IconType.INFO}
                                    className={classNames(
                                        style.icon,
                                        linePlanOverruned && style.iconRed,
                                        lineHasPlanReserveCorrections && style.iconYellow,
                                    )}
                                    svgSize={24}
                                />
                            </div>
                        </WithTooltip>

                        {showTransferBudgetItemsToPlanningButton && (
                            <WithTooltip
                                content={
                                    lineHasChildrenBudgetItems
                                        ? `На основе данной строки уже\nсозданы строки в планировании`
                                        : `Создать строку в планировании\nна основе этой строки`
                                }
                                anchor={TooltipAnchor.BOTTOM}
                            >
                                <div
                                    className={classNames(
                                        style.button,
                                        lineHasChildrenBudgetItems && style.buttonDisabled,
                                    )}
                                    onClick={
                                        lineHasChildrenBudgetItems ? null : onTransferBudgetItemsToPlanningButtonClick
                                    }
                                    {...transferBudgetItemsToPlanningQaParams}
                                >
                                    <Icon
                                        type={IconType.TRANSFER_BUDGET_ITEMS_TO_PLANNING}
                                        className={classNames(
                                            style.icon,
                                            lineHasChildrenBudgetItems && style.iconNonClickabke,
                                        )}
                                        svgSize={18}
                                    />
                                </div>
                            </WithTooltip>
                        )}

                        {userCanEditOrCreateBudgetData && (
                            <React.Fragment>
                                <WithTooltip content="Копирование строки">
                                    <div
                                        className={style.button}
                                        onClick={onCopyLineButtonClick}
                                        {...copyActivityQaParams}
                                    >
                                        <DynamicIcon
                                            common={{ svgSize: 24 }}
                                            normal={{ type: IconType.COPY }}
                                            hover={{ type: IconType.COPY_HOVER }}
                                        />
                                    </div>
                                </WithTooltip>

                                <WithTooltip content="Добавить строку бюджета">
                                    <div
                                        className={classNames(
                                            style.button,
                                            isEditActivityButtonDisabled && style.buttonDisabled,
                                        )}
                                        onClick={isEditActivityButtonDisabled ? null : onEditActivityButtonClick}
                                        {...editActivityQaParams}
                                    >
                                        <Icon
                                            type={IconType.WRITING}
                                            className={classNames(
                                                style.icon,
                                                isEditActivityButtonDisabled && style.buttonDisabled,
                                            )}
                                            svgSize={18}
                                        />
                                    </div>
                                </WithTooltip>

                                <WithTooltip content="Отправить на согласование">
                                    <div
                                        className={classNames(
                                            style.button,
                                            isSendCorrectionButtonDisabled && style.buttonDisabled,
                                        )}
                                        onClick={onSendToCorrectionButtonClick}
                                        {...sendToCorrectionQaParams}
                                    >
                                        <Icon
                                            type={IconType.SEND_FOR_APPROVAL}
                                            className={classNames(
                                                style.sendForApprovalIcon,
                                                isSendCorrectionButtonDisabled && style.sendForApprovalIconDisabled,
                                            )}
                                        />
                                    </div>
                                </WithTooltip>
                            </React.Fragment>
                        )}

                        <WithTooltip content="Показать корректировки по этой строке">
                            <div
                                className={style.button}
                                onClick={onOpenCorrectionsButtonClick}
                                {...openCorrectionsQaParams}
                            >
                                <Icon type={IconType.OPEN_CORRECTIONS} className={style.icon} svgSize={18} />
                            </div>
                        </WithTooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};
