import { useCreativeRequesteItemUnreadComments } from '../hooks/useCreativeRequesteItemUnreadComments';
import * as React from 'react';
import classnames from 'classnames';
import * as styles from './ItemCard.scss';
import { CreativeRequestItem } from '@api';

export interface CreativeRequestItemWithTitle {
    title: string;
    dto: CreativeRequestItem;
}

interface ItemCardProps {
    creativeRequestItem?: CreativeRequestItemWithTitle;
    activeCreativeRequestItemId: string;
    setActiveCreativeRequestItemId: (activeCreativeRequestItem: string) => void;
}

export function ItemCard({
    creativeRequestItem,
    activeCreativeRequestItemId,
    setActiveCreativeRequestItemId,
}: ItemCardProps): JSX.Element {
    const isUnread = creativeRequestItem ? useCreativeRequesteItemUnreadComments(creativeRequestItem.dto) : null;

    const creativeRequestItemId = creativeRequestItem?.dto?.model?.id || null;
    const isActive = creativeRequestItemId === activeCreativeRequestItemId;

    const title: string = creativeRequestItem ? `Строка №${creativeRequestItem?.title}` : 'Общее';

    return (
        <div
            className={classnames(
                styles.creativeRequestItem,
                isUnread && styles.creativeRequestItemMessagesMarker,
                isActive && styles.creativeRequestItemActive,
            )}
            onClick={isActive ? null : () => setActiveCreativeRequestItemId(creativeRequestItemId)}
        >
            {title}
        </div>
    );
}
