import * as React from 'react';
import * as moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter, RouteProps, RouteComponentProps, Route } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { AuthResponse } from 'sber-marketing-types/frontend';
import { UserConfigType } from 'sber-marketing-types/openid';
import { StyledPopup, CustomScrollbar, PagePreloader } from 'sber-marketing-ui';
import autobind from 'autobind-decorator';

import { AuthApi, JivoSiteApi } from '@api';

import { StoreState } from '@store';
import { loadUser } from '@store/user/actions';
import { updateCurrentPath, updatePageOptions, resetStore, setRequestInProgress } from '@store/common/actions';
import { getLoginUser } from '@store/user/selector';
import { isRequestInProgress, getPageOptions } from '@store/common/selectors';
import { User } from '@store/user/types';
import { PageOptions } from '@store/common/types';
import { LoadingStatus } from '@store/commonTypes';
import { loadUserConfig, saveUserConfig } from '@store/userConfig';
import { CommonUserConfig, getLoadingStatus, getCommonUserConfig } from '@store/userConfig/common';

import { HeaderView } from '@common/Page';
import { Footer } from '@common/Footer';
import { SearchMenu } from '@common/SearchMenu';
import { ProfileMenu } from '@common/ProfileMenu';
import { ReleaseNote } from '../../release_notes/ReleaseNotesPage';
import { ReleaseNotesPopup } from '../../release_notes/ReleaseNotesPopup';
import { releaseData } from '../../release_notes/ReleaseData';

import { JivoSiteCurrentPageUpdater } from 'sber-marketing-ui';
import { Header } from '../Header';
import { Notification } from '../NotificationList';
import { NavMenu } from '../NavMenu';

import * as style from './Route.scss';

interface Props extends RouteProps, Partial<MapProps>, Partial<DispatchProps> {
    component?: React.ComponentType;
    children?: React.ReactNode;
    setHeaderView?: (view: HeaderView) => void;
    match?: {
        params: {
            activityId?: string;
        };
    };
    firstLine: JSX.Element;
    additionalContent: JSX.Element;
    withoutNavMenu: boolean;
    hasAccess?: (user: User) => boolean;
}

interface MapProps {
    user?: User;
    currentPath?: string;
    headerHeight: number;
    pageOptions: PageOptions;
    preloader: boolean;
    userConfig: CommonUserConfig;
    userConfigLoadingStatus: LoadingStatus;
}

interface DispatchProps {
    loadUser?: (user: User) => void;
    resetStore: () => void;
    updateCurrentPath?: (path: string) => void;
    updatePageOptions?: (options: PageOptions) => void;
    loadUserConfig?: () => void;
    saveUserConfig?: (userConfig: Partial<CommonUserConfig>) => void;
    setRequestInProgress?: (isRequestInProgress: boolean) => void;
}

interface State {
    onLoad: boolean;
    timeLeft: number;
    nextSessionCheck: moment.Moment;
    releaseNotes: ReleaseNote[];
    noAccessPopupVisible: boolean;
}

const HALF_HOUR = 1800000;

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class RouteContainer extends React.PureComponent<Props, State> {
    private authorizedUser: AuthResponse = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            onLoad: null,
            timeLeft: 1,
            nextSessionCheck: moment().add(5, 'minutes'),
            releaseNotes: [],
            noAccessPopupVisible: false,
        };
    }

    public async componentDidMount() {
        window.addEventListener('mousemove', this.handleUserActivity);
        window.addEventListener('click', this.handleUserActivity);

        if (!this.props.user || !this.props.user.attributes) {
            this.authorizedUser = await AuthApi.getAuthorizedUser();
            if (get(this.authorizedUser, 'user.attributes')) {
                this.props.loadUserConfig();
            }
            this.props.loadUser(this.authorizedUser.user);
            this.setJivoContacts(this.authorizedUser);
        }

        this.startSessionCount();
        this.props.updateCurrentPath(this.props.path as string);

        this.setState({
            onLoad: false,
            noAccessPopupVisible: !this.canRenderContent(),
        });
    }

    public componentDidUpdate(prevProps: Props): void {
        const userConfigWasLoaded =
            this.props.userConfigLoadingStatus === LoadingStatus.LOADED &&
            prevProps.userConfigLoadingStatus !== LoadingStatus.LOADED;

        if (get(this.authorizedUser, 'user.attributes') && userConfigWasLoaded) {
            this.updateUserConfig();
        }
    }

    public render() {
        const { location, path, exact, strict } = this.props;

        return React.createElement(Route, {
            location,
            path,
            exact,
            strict,
            render: this.renderContent,
        });
    }

    public componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleUserActivity);
        window.removeEventListener('click', this.handleUserActivity);

        this.props.setRequestInProgress(false);
        this.props.setHeaderView(null);
    }

    protected setJivoContacts({ user }: AuthResponse) {
        if (user.attributes) {
            const { id, email, firstName, secondName, middleName } = user.attributes;
            const name = `${secondName} ${firstName} ${middleName ? middleName : ''}`.trim();
            JivoSiteApi.setUserId(id);
            JivoSiteApi.setUserData(email, name);
        }
    }

    @autobind
    protected renderContent(routeProps: RouteComponentProps<any>) {
        let content: JSX.Element | {}[] = null;

        if (this.state.onLoad !== null) {
            if (isEmpty(this.props.user) || !this.props.user.attributes) {
                this.redirectToLogin();
            } else {
                content = this.renderPage(routeProps);
            }
        }

        return content;
    }

    @autobind
    protected handleRedirect() {
        this.props.resetStore();
    }

    @autobind
    protected async getLogoutUrl() {
        const url = await AuthApi.logout();

        return url;
    }

    @autobind
    protected async getUser() {
        return this.props.user.attributes;
    }

    @autobind
    protected async handleUserActivity() {
        if (moment().isAfter(this.state.nextSessionCheck)) {
            this.setState({
                nextSessionCheck: moment().add(5, 'minutes'),
                timeLeft: HALF_HOUR,
            });
            AuthApi.setTimeLeft();
        }
    }

    protected renderPage(routeProps: RouteComponentProps<any>) {
        const { component, pageOptions, preloader, firstLine, additionalContent, withoutNavMenu, hasAccess, ...props } =
            this.props;

        const componentClassName = classNames(
            style.component,
            get(pageOptions, 'withoutDownPadding') && style.component_withoutDownPadding,
            pageOptions.withoutFooter && style.footerDisabled,
        );

        const canRenderContent = this.canRenderContent();

        return (
            <React.Fragment>
                <JivoSiteCurrentPageUpdater appName={JivoSiteApi.appName} />

                <Header firstLine={firstLine} additionalContent={additionalContent} />

                <Notification />

                {!withoutNavMenu && <NavMenu />}

                <div className={style.content} style={{ height: `calc(100vh - ${this.props.headerHeight}px)` }}>
                    <CustomScrollbar
                        renderView={(props) => <div {...props} className={style.scrollbarContent} id={'pageContent'} />}
                        renderTrackVertical={(props) => <div {...props} className={style.trackVertical} />}
                        renderTrackHorizontal={(props) => <div {...props} className={style.trackHorizontal} />}
                        renderThumbVertical={(props) => <div {...props} className={style.thumbVertical} />}
                        renderThumbHorizontal={(props) => <div {...props} className={style.thumbHorizontal} />}
                        hideTracksWhenNotNeeded
                        autoHide
                    >
                        <div className={componentClassName}>
                            {canRenderContent
                                ? React.createElement(this.props.component, {
                                      ...routeProps,
                                      ...props,
                                      getLogoutUrl: this.getLogoutUrl,
                                      getUser: this.getUser,
                                  } as any)
                                : null}
                            {this.state.noAccessPopupVisible ? (
                                <NoAccessPopup closeNoAccessPopup={this.closeNoAccessPopup} />
                            ) : null}
                        </div>

                        {pageOptions && !pageOptions.withoutFooter && (
                            <div className={style.footer}>
                                <Footer />
                            </div>
                        )}

                        {!pageOptions?.withoutSearch && <SearchMenu />}

                        <ProfileMenu />
                    </CustomScrollbar>

                    {preloader && <PagePreloader />}

                    {this.renderModalNotification()}
                    {this.renderReleaseNotesModal()}
                </div>
            </React.Fragment>
        );
    }

    @autobind
    protected onCloseReleaseNotes() {
        this.setState(() => ({
            releaseNotes: [],
        }));
    }

    protected renderModalNotification(): React.ReactNode {
        if (this.state.timeLeft <= 0) {
            return React.createElement(
                StyledPopup,
                {
                    key: 'popup',
                    title: 'Пожалуйста, войдите заново',
                    fullMessage:
                        'Ваша сессия истекла. Пожалуйста, авторизуйтесь заново. ' +
                        'Это необходимо для безопасности вашего аккаунта',
                    firstButtonText: 'Авторизоваться',
                    firstButtonHandler: AuthApi.logout,
                },
                null,
            );
        }
        return null;
    }

    private async startSessionCount() {
        const timeLeft = await AuthApi.getTimeLeft();

        this.setState({ timeLeft });

        if (timeLeft > 0) {
            setTimeout(() => this.startSessionCount(), timeLeft);
        } else {
            window.removeEventListener('mousemove', this.handleUserActivity);
            window.removeEventListener('click', this.handleUserActivity);
        }
    }

    private async redirectToLogin() {
        let from = '/dashboard';
        if (!isEmpty(this.props.location.pathname)) {
            from = this.props.location.pathname;
            if (!isEmpty(this.props.location.search)) {
                from = `${from}?${this.props.location.search}`;
            }
        }
        AuthApi.login(from);
    }

    private updateUserConfig() {
        const { userConfig } = this.props;

        const configDate = moment(userConfig.lastSeenRelease, 'YYYY-MM-DD');
        const currentReleaseData = releaseData;
        const dates = currentReleaseData.filter((item) => moment(item.date, 'YYYY-MM-DD').isAfter(configDate));

        if (dates.length > 0) {
            this.setState({
                releaseNotes: dates,
            });

            this.props.saveUserConfig({
                lastSeenRelease: moment(dates[0].date).format(),
            });
        }
    }

    private renderReleaseNotesModal() {
        if (this.state.releaseNotes.length > 0) {
            return React.createElement(
                ReleaseNotesPopup,
                {
                    key: 'releaseNotes',
                    onCloseButtonClick: this.onCloseReleaseNotes,
                    messages: this.state.releaseNotes,
                },
                null,
            );
        }
        return null;
    }

    @autobind
    private closeNoAccessPopup(): void {
        this.setState({ noAccessPopupVisible: false });
    }

    private canRenderContent(): boolean {
        return this.props.hasAccess && this.authorizedUser?.user
            ? this.props.hasAccess(this.authorizedUser.user)
            : true;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { currentPath, headerHeight } = state.common;

    return {
        user: getLoginUser(state),
        currentPath,
        headerHeight,
        pageOptions: getPageOptions(state),
        preloader: isRequestInProgress(state),
        userConfig: getCommonUserConfig(state),
        userConfigLoadingStatus: getLoadingStatus(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        loadUser: (user: User) => dispatch(loadUser(user)),
        resetStore: () => dispatch(resetStore()),
        updateCurrentPath: (path: string) => dispatch(updateCurrentPath(path)),
        updatePageOptions: (options: PageOptions) => dispatch(updatePageOptions(options)),
        loadUserConfig: () => dispatch(loadUserConfig(UserConfigType.Common)),
        saveUserConfig: (payload: Partial<CommonUserConfig>) =>
            dispatch(
                saveUserConfig({
                    type: UserConfigType.Common,
                    payload,
                }),
            ),
        setRequestInProgress: (isRequestInProgress: boolean) => dispatch(setRequestInProgress(isRequestInProgress)),
    };
}

interface NoAccessPopupProps {
    closeNoAccessPopup: () => void;
}

function NoAccessPopup({ closeNoAccessPopup }: NoAccessPopupProps): JSX.Element {
    return (
        <StyledPopup
            qaId="noAccessPopup"
            title="Модуль недоступен"
            fullMessage="Вы не имеете доступа к данному модулю. Пожалуйста, обратитесь к бизнес-администратору системы."
            firstButtonQaId="noAccessPopupCloseButton"
            firstButtonText="OK"
            firstButtonHandler={closeNoAccessPopup}
        />
    );
}
