import * as lodash from 'lodash';
import * as moment from 'moment';

import { UserCard } from 'sber-marketing-types/frontend';
import { TableNumberByType, TableType, ColumnName } from '@store/creative/types';
import type { LineId } from './types';
import {
    Project,
    CreativeRequest,
    CreativeRequestItem,
    CreativeRequestItemFileKind,
    CreativeRequestContract,
    Dictionary,
    DictionaryType,
} from '@api';
import { CreativeRequestItemActStatus } from '@sbermarketing/mrm-metacom-client';
import { IconType } from 'sber-marketing-ui';
import { store, StoreState } from '@store/index';

export const CAPEX_ITEM_VALUE = '206.6';

export const enum ColumnHeaderType {
    Text = 'text',
    Filters = 'filters',
}

export const enum LineType {
    Line = 'line',
    Total = 'total',
}

export const enum CellType {
    LineHeader = 'line_header',
    Text = 'text',
    Input = 'input',
    Textarea = 'textarea',
    FundsInput = 'funds_input',
    FundsSelect = 'funds_select',
    Select = 'select',
    SelectWithIcon = 'select-with-icon',
    CheckboxList = 'checkbox_list',
    Datepicker = 'datepicker',
    RangeDatepickerCell = 'range_datepicker',
    Files = 'files',
    Status = 'status',
}

export interface AccessorParams {
    lineId: LineId;
    line: CreativeRequestItem;
    allLines: CreativeRequestItem[];
    project: Project;
    creativeRequest: CreativeRequest;
    creativeRequestLot: string;
    contracts: CreativeRequestContract[];
    dictionariesByType: Partial<Record<DictionaryType, Dictionary[]>>;
    users: UserCard[];
}

type Accessor<T> = (params: AccessorParams) => Promise<T>;
type AccessorWithValue<T> = (params: AccessorParams, value: any) => Promise<T>;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type ReadOnlyAccessor = Accessor<boolean>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;
export type SuggestItemsAccessor = Accessor<string[]>;
export type ValidationAccessor = AccessorWithValue<boolean>;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType> | CellType;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, ReadOnlyAccessor | boolean>> | ReadOnlyAccessor | boolean;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>> | CustomStyleAccessor;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    validateValue?: Partial<Record<LineType, ValidationAccessor>> | ValidationAccessor;
    getTitle?: Partial<Record<LineType, TitleAccessor>> | TitleAccessor;
    getValue: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
    getSnapshotValue?: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
    setValue?: Partial<Record<LineType, ValueSetter>> | ValueSetter;
    getItems?: Partial<Record<LineType, ItemsAccessor>> | ItemsAccessor;
    getSuggestItems?: Partial<Record<LineType, SuggestItemsAccessor>> | SuggestItemsAccessor;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>> | DescriptionAccessor;
}

export interface ColumnsConfigParams {
    displayStatusColumns?: boolean;
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.LineHeader]: {
        title: '№',
        headerType: ColumnHeaderType.Text,
        type: CellType.LineHeader,
        defaultWidth: 60,
        disableWidthChange: true,
        readOnly: true,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const { value: tableType } = await params.line.model.creativeRequestGroup;

                return `${TableNumberByType[tableType]}.${params.line.model.number}`;
            },
        },
    },
    [ColumnName.SourceId]: {
        title: 'ID источ.',
        headerType: ColumnHeaderType.Filters,
        type: CellType.CheckboxList,
        defaultWidth: 70,
        readOnly: async (params: AccessorParams) => !params.line.model.addDonor || !params.line.model.removeDonor,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.donors.map((item) => item.id),
        },
        getItems: {
            [LineType.Line]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const selectedIds = [...params.line.model.donors, ...params.line.model.acceptors].map(
                    (item) => item.id,
                );

                const hasSelectesOpexBudgetItems = selectedIds.some(
                    (item) => itemValueByBudgetItemId[item] !== CAPEX_ITEM_VALUE,
                );
                const hasSelectesCapexBudgetItems = selectedIds.some(
                    (item) => itemValueByBudgetItemId[item] === CAPEX_ITEM_VALUE,
                );

                const items = creativeRequestBudgetItems.map((item) => {
                    const isCapex = itemValueByBudgetItemId[item.model.id] === CAPEX_ITEM_VALUE;

                    const itemIsSelected = selectedIds.includes(item.model.id);

                    return {
                        title: `${item.model.serialNumber}${isCapex ? ' (CAPEX)' : ''}`,
                        value: item.model.id,
                        isCapex,
                        disabled:
                            !itemIsSelected && (isCapex ? hasSelectesOpexBudgetItems : hasSelectesCapexBudgetItems),
                    };
                });

                return lodash.sortBy(items, (item) => [item.isCapex, item.title]);
            },
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.donors;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue.map((item) => item.serialNumber).join(', '),
                    newValue: params.line.model.donors.map((item) => item.serialNumber).join(', '),
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: string[]) => {
                const oldValue = params.line.model.donors.map((item) => item.id);

                const idsToAdd = lodash.difference(value, oldValue);
                const idsToRemove = lodash.difference(oldValue, value);

                await Promise.all([
                    ...idsToAdd.map((id) => params.line.model.addDonor({ donorId: id })),
                    ...idsToRemove.map((id) => params.line.model.removeDonor({ donorId: id })),
                ]);
            },
        },
    },
    [ColumnName.ExecutionId]: {
        title: 'ID испол.',
        headerType: ColumnHeaderType.Filters,
        type: CellType.CheckboxList,
        defaultWidth: 70,
        readOnly: {
            [LineType.Line]: async (params: AccessorParams) =>
                !params.line.model.addAcceptor || !params.line.model.removeAcceptor,
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.acceptors.map((item) => item.id) || [],
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.acceptors;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue.map((item) => item.serialNumber).join(', '),
                    newValue: params.line.model.acceptors.map((item) => item.serialNumber).join(', '),
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        getItems: {
            [LineType.Line]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const selectedIds = [...params.line.model.donors, ...params.line.model.acceptors].map(
                    (item) => item.id,
                );

                const hasSelectesOpexBudgetItems = selectedIds.some(
                    (item) => itemValueByBudgetItemId[item] !== CAPEX_ITEM_VALUE,
                );
                const hasSelectesCapexBudgetItems = selectedIds.some(
                    (item) => itemValueByBudgetItemId[item] === CAPEX_ITEM_VALUE,
                );

                const items = projectBudgetItems.map((item) => {
                    const isCapex = itemValueByBudgetItemId[item.model.id] === CAPEX_ITEM_VALUE;

                    const itemIsSelected = selectedIds.includes(item.model.id);

                    return {
                        title: `${item.model.serialNumber}${isCapex ? ' (CAPEX)' : ''}`,
                        value: item.model.id,
                        isCapex,
                        disabled:
                            !itemIsSelected && (isCapex ? hasSelectesOpexBudgetItems : hasSelectesCapexBudgetItems),
                    };
                });

                return lodash.sortBy(items, (item) => [item.isCapex, item.title]);
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: string[]) => {
                const oldValue = params.line.model.acceptors.map((item) => item.id);

                const idsToAdd = lodash.difference(value, oldValue);
                const idsToRemove = lodash.difference(oldValue, value);

                await Promise.all([
                    ...idsToAdd.map((id) => params.line.model.addAcceptor({ acceptorId: id })),
                    ...idsToRemove.map((id) => params.line.model.removeAcceptor({ acceptorId: id })),
                ]);
            },
        },
    },
    [ColumnName.ProjectName]: {
        title: 'Название РК/Проекта',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Textarea,
        defaultWidth: 255,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.projectName,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.projectName;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.projectName,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setProjectName({ projectName: value });
            },
        },
    },
    [ColumnName.StartDate]: {
        title: 'Дата начала кампании',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Datepicker,
        defaultWidth: 90,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.startDate,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.startDate;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue ? moment(snapshot.previousValue).format('DD.MM.YYYY') : null,
                    newValue: params.line.model.startDate
                        ? moment(params.line.model.startDate).format('DD.MM.YYYY')
                        : null,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setStartDate({ startDate: value });
            },
        },
    },
    [ColumnName.EndDate]: {
        title: 'Дата оконч. кампании',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Datepicker,
        defaultWidth: 90,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.endDate,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.endDate;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue ? moment(snapshot.previousValue).format('DD.MM.YYYY') : null,
                    newValue: params.line.model.endDate ? moment(params.line.model.endDate).format('DD.MM.YYYY') : null,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setEndDate({ endDate: value });
            },
        },
    },
    [ColumnName.TariffName]: {
        title: 'Название тарифа',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Select,
        defaultWidth: 300,
        readOnly: {
            [LineType.Line]: async (params: AccessorParams) => !params.line.model.setTariff,
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const tariff = await params.line.model.tariff;

                return tariff?.id || null;
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: string) => {
                const dictionaries = params.dictionariesByType[DictionaryType.CreativeRequestTariff];
                const dictionary = value ? dictionaries.find((item) => item.id == value) : null;

                params.line.model.setTariff({ tariff: dictionary });

                const { tariffsQuantity } = params.line.model;

                if (tariffsQuantity !== undefined) {
                    const newTariffLimit = lodash.get(dictionary, ['data', 'data', 'costWithoutVat']) as number;
                    const vat = params.line.model.vat || 0;

                    const newActualCostWithoutVat = newTariffLimit * tariffsQuantity;
                    const newActualCostWithVat = newActualCostWithoutVat * (vat / 100 + 1);

                    params.line.model.setActualCostWithoutVat({
                        actualCostWithoutVat: formatMoneyInput(newActualCostWithoutVat),
                    });
                    params.line.model.setActualCostWithVat({
                        actualCostWithVat: formatMoneyInput(newActualCostWithVat),
                    });
                }
            },
        },
        getItems: {
            [LineType.Line]: async (params: AccessorParams) => {
                const dictionaries = params.dictionariesByType[DictionaryType.CreativeRequestTariff];

                return dictionaries.map((item) => ({
                    title: item.value,
                    value: item.id,
                }));
            },
        },
    },
    [ColumnName.TariffQuantity]: {
        title: 'Тарифы, шт.',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Input,
        defaultWidth: 65,
        readOnly: {
            [LineType.Line]: async (params: AccessorParams) => !params.line.model.setTariffsQuantity,
        },
        validateValue: {
            [LineType.Line]: async (params: AccessorParams, value: string) => {
                const integerRegex = /^\+?\d+$/;

                return integerRegex.test(value);
            },
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.tariffsQuantity,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.tariffsQuantity;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.tariffsQuantity,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                params.line.model.setTariffsQuantity({ tariffsQuantity: value });

                const tariff = await params.line.model.tariff;

                const tariffLimit = lodash.get(tariff, ['data', 'data', 'costWithoutVat']) as number;

                if (tariffLimit !== undefined) {
                    const vat = params.line.model.vat || 0;
                    const newTariffsQuantity = value;
                    const newActualCostWithoutVat = tariffLimit * newTariffsQuantity;
                    const newActualCostWithVat = newActualCostWithoutVat * (vat / 100 + 1);

                    params.line.model.setActualCostWithoutVat({
                        actualCostWithoutVat: formatMoneyInput(newActualCostWithoutVat),
                    });
                    params.line.model.setActualCostWithVat({
                        actualCostWithVat: formatMoneyInput(newActualCostWithVat),
                    });
                }
            },
        },
    },
    [ColumnName.TariffLimit]: {
        title: 'Лимит тарифа до НДС за 1 шт',
        headerType: ColumnHeaderType.Filters,
        type: CellType.FundsInput,
        defaultWidth: 120,
        readOnly: true,

        getValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const tariff = await params.line.model.tariff;

                const costWithoutVat = lodash.get(tariff, ['data', 'data', 'costWithoutVat']) as number;

                return costWithoutVat || null;
            },
        },
    },
    [ColumnName.ActualCostExcludingVat]: {
        title: 'Фактическая стоимость без НДС',
        headerType: ColumnHeaderType.Filters,
        type: CellType.FundsInput,
        defaultWidth: 120,
        customStyle: {
            [LineType.Total]: async (params: AccessorParams) => ({
                fontWeight: 900,
            }),
        },
        validateValue: {
            [LineType.Line]: async (params: AccessorParams, value: string) => {
                const { value: tableType } = await params.line.model.creativeRequestGroup;

                if (tableType !== TableType.Tariff) {
                    return true;
                }

                const tariff = await params.line.model.tariff;

                const costWithoutVat = lodash.get(tariff, ['data', 'data', 'costWithoutVat']) as number;

                const tariffsQuantity = params.line.model.tariffsQuantity;

                const maxValue = costWithoutVat * tariffsQuantity;

                return parseFloat(value) <= maxValue;
            },
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => formatMoney(params.line.model.actualCostWithoutVat),
            [LineType.Total]: async (params: AccessorParams) => {
                const linesByTableType = await Promise.all(
                    params.allLines.map(async (item) => ({
                        line: item,
                        tableType: (await item.model.creativeRequestGroup).value as TableType,
                    })),
                );

                const tableTypeByLineId = {
                    tariffTotal: TableType.Tariff,
                    productionTotal: TableType.Production,
                };

                const lines = linesByTableType
                    .filter(
                        (item) =>
                            item.tableType === tableTypeByLineId[params.lineId] &&
                            item.line.model.status !== 'archived',
                    )
                    .map((item) => item.line);

                const sum = lodash.sumBy(lines, (item) => item.model.actualCostWithoutVat);

                return formatMoney(sum);
            },
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.actualCostWithoutVat;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: formatCurrencyValue(roundNumber(formatMoney(snapshot.previousValue))),
                    newValue: formatCurrencyValue(roundNumber(formatMoney(params.line.model.actualCostWithoutVat))),
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: number) => {
                const formatedValue = formatMoneyInput(value);

                await params.line.model.setActualCostWithoutVat({ actualCostWithoutVat: formatedValue });

                const newActualCostWithVat = Math.round(formatedValue * (params.line.model.vat / 100 + 1));

                await params.line.model.setActualCostWithVat({
                    actualCostWithVat: newActualCostWithVat,
                });
            },
        },
    },
    [ColumnName.Vat]: {
        title: 'Ставка НДС, %',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Line]: CellType.FundsSelect,
            [LineType.Total]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Line]: async (params: AccessorParams) => {
                const { value: tableType } = await params.line.model.creativeRequestGroup;

                return tableType === TableType.Tariff;
            },
            [LineType.Total]: true,
        },
        customStyle: {
            [LineType.Total]: async (params: AccessorParams) => ({
                fontWeight: 900,
            }),
        },
        getTitle: {
            [LineType.Line]: async (params: AccessorParams) => {
                const { value: tableType } = await params.line.model.creativeRequestGroup;

                return tableType === TableType.Ak
                    ? formatMoney((params.line.model.commissionWithoutVat * params.line.model.vat) / 100)
                    : formatMoney((params.line.model.actualCostWithoutVat * params.line.model.vat) / 100);
            },
        },
        getDescription: {
            [LineType.Line]: async (params: AccessorParams) => `НДС ${params.line.model.vat || 0}%`,
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.vat,
            [LineType.Total]: async (params: AccessorParams) => {
                const linesByTableType = await Promise.all(
                    params.allLines.map(async (item) => ({
                        line: item,
                        tableType: (await item.model.creativeRequestGroup).value as TableType,
                    })),
                );

                const tableTypeByLineId = {
                    tariffTotal: TableType.Tariff,
                    productionTotal: TableType.Production,
                    akTotal: TableType.Ak,
                };

                const lines = linesByTableType
                    .filter(
                        (item) =>
                            item.tableType === tableTypeByLineId[params.lineId] &&
                            item.line.model.status !== 'archived',
                    )
                    .map((item) => item.line);

                const sum =
                    tableTypeByLineId[params.lineId] === TableType.Ak
                        ? lodash.sumBy(lines, (item) => (item.model.commissionWithoutVat * item.model.vat) / 100)
                        : lodash.sumBy(lines, (item) => (item.model.actualCostWithoutVat * item.model.vat) / 100);

                return formatMoney(sum);
            },
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.vat;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: `${snapshot.previousValue}%`,
                    newValue: `${params.line.model.vat}%`,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setVat({ vat: value });

                const { value: tableType } = await params.line.model.creativeRequestGroup;

                if (tableType === TableType.Ak) {
                    const newCommissionWithVat =
                        formatMoney(params.line.model.commissionWithoutVat) * (value / 100 + 1);

                    await params.line.model.setCommissionWithVat({
                        commissionWithVat: formatMoneyInput(newCommissionWithVat),
                    });
                } else {
                    const newActualCostWithVat =
                        formatMoney(params.line.model.actualCostWithoutVat) * (value / 100 + 1);

                    await params.line.model.setActualCostWithVat({
                        actualCostWithVat: formatMoneyInput(newActualCostWithVat),
                    });
                }
            },
        },
        getItems: {
            [LineType.Line]: async (params: AccessorParams) => [
                { title: '0%', value: 0 },
                { title: '20%', value: 20 },
            ],
        },
    },
    [ColumnName.ActualCostWithVat]: {
        title: 'Фактическая стоимость с НДС',
        headerType: ColumnHeaderType.Filters,
        type: CellType.FundsInput,
        defaultWidth: 120,
        readOnly: true,
        customStyle: {
            [LineType.Total]: async (params: AccessorParams) => ({
                fontWeight: 900,
            }),
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => formatMoney(params.line.model.actualCostWithVat),
            [LineType.Total]: async (params: AccessorParams) => {
                const linesByTableType = await Promise.all(
                    params.allLines.map(async (item) => ({
                        line: item,
                        tableType: (await item.model.creativeRequestGroup).value as TableType,
                    })),
                );

                const tableTypeByLineId = {
                    tariffTotal: TableType.Tariff,
                    productionTotal: TableType.Production,
                };

                const lines = linesByTableType
                    .filter(
                        (item) =>
                            item.tableType === tableTypeByLineId[params.lineId] &&
                            item.line.model.status !== 'archived',
                    )
                    .map((item) => item.line);

                const sum = lodash.sumBy(lines, (item) => item.model.actualCostWithVat);

                return formatMoney(sum);
            },
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.actualCostWithVat;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: formatCurrencyValue(roundNumber(formatMoney(snapshot.previousValue))),
                    newValue: formatCurrencyValue(roundNumber(formatMoney(params.line.model.actualCostWithVat))),
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setActualCostWithVat({ actualCostWithVat: formatMoneyInput(value) });
            },
        },
    },
    [ColumnName.RightsType]: {
        title: 'Вид передаваемых прав',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Select,
        defaultWidth: 300,
        readOnly: true,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => 1,
        },
        getItems: {
            [LineType.Line]: async (params: AccessorParams) => [{ title: 'Отчуждение', value: 1 }],
        },
        // getValue: async (params: AccessorParams) => {
        //     const dictionary =
        //         params.dictionaryValuesByLineId[params.lineId][DictionaryType.TransferredRightsType];

        //     return dictionary?.id || null;
        // },
        // setValue: async (params: AccessorParams, value: string) => {
        //     const dictionaries = params.dictionariesByType[DictionaryType.TransferredRightsType];

        //     const dictionary = dictionaries.find(item => item.id == value);

        //     params.line.model.setTransferredRightsType({ transferredRightsType: dictionary });
        // },
        // getItems: async (params: AccessorParams) => {
        //     const dictionaries = params.dictionariesByType[DictionaryType.TransferredRightsType];

        //     return dictionaries.map(item => ({
        //         title: item.value,
        //         value: item.id
        //     }));
        // }
    },
    [ColumnName.RightsTypeInput]: {
        title: 'Вид передаваемых прав',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Textarea,
        defaultWidth: 300,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.transferredRightsTypeText,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.transferredRightsTypeText;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.transferredRightsTypeText,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setTransferredRightsTypeText({ transferredRightsTypeText: value });
            },
        },
    },
    [ColumnName.ValidityTerritory]: {
        title: 'Территория действия прав',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Textarea,
        defaultWidth: 120,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.transferredRightsValidityTerritory,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.transferredRightsValidityTerritory;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.transferredRightsValidityTerritory,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: string) => {
                await params.line.model.setTransferredRightsValidityTerritory({
                    transferredRightsValidityTerritory: value,
                });
            },
        },
    },
    [ColumnName.RightsDuration]: {
        title: 'Срок действия прав',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Textarea,
        defaultWidth: 120,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.rightsDuration?.value || null,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.rightsDuration;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue?.value,
                    newValue: params.line.model.rightsDuration?.value,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: string) => {
                await params.line.model.setRightsDuration({ rightsDuration: { value } });
            },
        },
    },
    [ColumnName.ImageLink]: {
        title: 'Ссылка на имидж',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Textarea,
        defaultWidth: 120,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.stockLink,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.stockLink;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.stockLink,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setStockLink({ stockLink: value });
            },
        },
    },
    [ColumnName.CreativeType]: {
        title: 'Тип работ / материала',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Input,
        defaultWidth: 300,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.objectType,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.objectType;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.objectType,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setObjectType({ objectType: value });
            },
        },
    },
    [ColumnName.ImageNumber]: {
        title: '№ имиджа',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Input,
        defaultWidth: 65,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.stockNumber,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.stockNumber;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.stockNumber,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setStockNumber({ stockNumber: value });
            },
        },
    },
    [ColumnName.ThirdParty]: {
        title: '3е лицо (юр.название)',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Input,
        defaultWidth: 120,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.thirdParty,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.thirdParty;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.thirdParty,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setThirdParty({ thirdParty: value });
            },
        },
        getSuggestItems: {
            [LineType.Line]: async (params: AccessorParams) => {
                const dictionaries = params.dictionariesByType[DictionaryType.Agency] || [];

                return dictionaries.map((item) => item.value.trim()).sort();
            },
        },
    },
    [ColumnName.CommissionAmount]: {
        title: 'Размер комиссии',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Text,
        defaultWidth: 300,
        readOnly: true,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const { contractId } = params.creativeRequest.model as any;

                const contract = params.contracts.find((item) => item.model.id === contractId);

                const { agencyCommissionLimit, agencyCommissionRate } = contract.model;

                return `${agencyCommissionRate}%, но не более ${formatCurrencyValue(
                    formatMoney(agencyCommissionLimit),
                )}`;
            },
        },
    },
    [ColumnName.Provider]: {
        title: 'Поставщик',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Input,
        defaultWidth: 120,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.thirdParty,
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.thirdParty;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: snapshot.previousValue,
                    newValue: params.line.model.thirdParty,
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setThirdParty({ thirdParty: value });
            },
        },
        getSuggestItems: {
            [LineType.Line]: async (params: AccessorParams) => {
                const dictionaries = params.dictionariesByType[DictionaryType.Agency] || [];

                return dictionaries.map((item) => item.value.trim()).sort();
            },
        },
    },
    [ColumnName.CommissionWithoutVat]: {
        title: 'Сумма комиссии без НДС',
        headerType: ColumnHeaderType.Filters,
        type: CellType.FundsInput,
        defaultWidth: 120,
        customStyle: {
            [LineType.Total]: async (params: AccessorParams) => ({
                fontWeight: 900,
            }),
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => formatMoney(params.line.model.commissionWithoutVat),
            [LineType.Total]: async (params: AccessorParams) => {
                const linesByTableType = await Promise.all(
                    params.allLines.map(async (item) => ({
                        line: item,
                        tableType: (await item.model.creativeRequestGroup).value as TableType,
                    })),
                );

                const lines = linesByTableType
                    .filter((item) => item.tableType === TableType.Ak && item.line.model.status !== 'archived')
                    .map((item) => item.line);

                const sum = lodash.sumBy(lines, (item) => item.model.commissionWithoutVat);

                return formatMoney(sum);
            },
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.commissionWithoutVat;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: formatCurrencyValue(formatMoney(snapshot.previousValue)),
                    newValue: formatCurrencyValue(roundNumber(formatMoney(params.line.model.commissionWithoutVat))),
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setCommissionWithoutVat({ commissionWithoutVat: formatMoneyInput(value) });

                const vat = params.line.model.vat || 0;

                const newCommissionWithVat = value * (vat / 100 + 1);

                await params.line.model.setCommissionWithVat({
                    commissionWithVat: formatMoneyInput(newCommissionWithVat),
                });
            },
        },
    },
    [ColumnName.CommissionWithVat]: {
        title: 'Сумма комиссии с НДС',
        headerType: ColumnHeaderType.Filters,
        type: CellType.FundsInput,
        defaultWidth: 120,
        readOnly: true,
        customStyle: {
            [LineType.Total]: async (params: AccessorParams) => ({
                fontWeight: 900,
            }),
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => formatMoney(params.line.model.commissionWithVat),
            [LineType.Total]: async (params: AccessorParams) => {
                const linesByTableType = await Promise.all(
                    params.allLines.map(async (item) => ({
                        line: item,
                        tableType: (await item.model.creativeRequestGroup).value as TableType,
                    })),
                );

                const lines = linesByTableType
                    .filter((item) => item.tableType === TableType.Ak && item.line.model.status !== 'archived')
                    .map((item) => item.line);

                const sum = lodash.sumBy(lines, (item) => item.model.commissionWithVat);

                return formatMoney(sum);
            },
        },
        getSnapshotValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const snapshot = (await params.line.model.getSnapshot()).model.commissionWithVat;

                if (!snapshot) {
                    return undefined;
                }

                const user = params.users.find((user) => user.id === snapshot.userId);

                return {
                    previousValue: formatCurrencyValue(roundNumber(formatMoney(snapshot.previousValue))),
                    newValue: formatCurrencyValue(roundNumber(formatMoney(params.line.model.commissionWithVat))),
                    timestamp: snapshot.timestamp,
                    userName: `${user.firstName} ${user.secondName}`,
                };
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                await params.line.model.setCommissionWithVat({ commissionWithVat: formatMoneyInput(value) });
            },
        },
    },
    [ColumnName.Documents]: {
        title: 'Смета \nБиддинг',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Files,
        defaultWidth: 120,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const lineFiles = await params.line.model.getFiles();

                return lineFiles.filter((file) => file.model.kind === (CreativeRequestItemFileKind.Estimate as any));
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                params.line.model.addEstimateFile({ fileId: value.id });
            },
        },
    },
    [ColumnName.Creatives]: {
        title: 'Результат \nработ',
        headerType: ColumnHeaderType.Filters,
        type: CellType.Files,
        defaultWidth: 120,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => {
                const lineFiles = await params.line.model.getFiles();

                return lineFiles.filter((file) => file.model.kind === (CreativeRequestItemFileKind.Creative as any));
            },
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                params.line.model.addCreativeFile({
                    fileId: value.id,
                    data: { isPreview: false },
                });
            },
        },
    },
    [ColumnName.LineStatus]: {
        title: 'Стр\nока',
        headerType: ColumnHeaderType.Text,
        type: CellType.Status,
        defaultWidth: 40,
        readOnly: true,
        disableWidthChange: true,
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.status === 'approved',
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: any) => {
                if (params.line.model.status !== 'archived') {
                    if (params.line.model.status === 'approved') {
                        await params.line.model.disapprove();
                    } else {
                        await params.line.model.approve();
                    }
                }
            },
        },
    },
    [ColumnName.ActStatus]: {
        title: 'Статус Акта',
        headerType: ColumnHeaderType.Text,
        type: CellType.SelectWithIcon,
        defaultWidth: 220,
        readOnly: {
            [LineType.Line]: async (params: AccessorParams) => {
                const state: StoreState = store.getState();
                const actStatus: CreativeRequestItemActStatus = params.line.model.actStatus;
                const currentUserRoles = state?.user?.attributes?.roles || [];
                const canEditRight: boolean =
                    actStatus === 'actPaid' ? currentUserRoles.some((item) => [29, 31].includes(item.id)) : true;

                return !(params.line.model.setActStatus && canEditRight);
            },
        },
        getItems: async (params: AccessorParams) => {
            const items = [
                {
                    value: 'actInfoCollecting',
                    title: 'Сбор информации в Агентстве',
                },
                {
                    value: 'actDocsPreparation',
                    title: 'Подготовка документов в Агентстве',
                },
                {
                    value: 'actSentToSb',
                    title: 'Отправлен в ПАО',
                },
                {
                    value: 'actRevision',
                    title: 'Доработка по комментарию',
                },
                {
                    value: 'actSignAwaited',
                    title: 'На подписи',
                },
                {
                    value: 'actPaymentAwaited',
                    title: 'В оплате',
                },
                {
                    value: 'actPaid',
                    title: 'Оплачено',
                    iconType: IconType.ACCEPT_ICON_ROUND_ACTIVE,
                },
            ];

            // adding description as current userName
            const state: StoreState = store.getState();
            const useAtributes = state.user.attributes;
            const userName = `${useAtributes.firstName} ${useAtributes.middleName} ${useAtributes.secondName}`;
            const itemsWithDescription = items.map((item) => ({ ...item, description: userName }));

            return itemsWithDescription;
        },
        getDescription: {
            [LineType.Line]: async (params: AccessorParams) => {
                //the one who changed the status
                const snapshot = await params.line.model.getSnapshot();
                const state: StoreState = store.getState();
                const userId = snapshot?.model?.actStatus?.userId;
                const user = userId ? state?.users?.byIds[userId] : null;
                return user ? `${user.firstName} ${user.middleName} ${user.secondName}` : null;
            },
        },
        getValue: {
            [LineType.Line]: async (params: AccessorParams) => params.line.model.actStatus,
        },
        setValue: {
            [LineType.Line]: async (params: AccessorParams, value: CreativeRequestItemActStatus) => {
                await params.line.model.setActStatus({ actStatus: value });
            },
        },
    },
    ...lodash.times(6).reduce((acc, index) => {
        acc[`empty${index + 1}`] = {
            headerType: ColumnHeaderType.Text,
            type: CellType.Text,
            defaultWidth: index === 0 ? 65 : 120,
            readOnly: true,
            getValue: async (params: AccessorParams) => undefined,
        } as ColumnParams;

        return acc;
    }, {}),
});

export const MakeTariffTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ColumnName.SourceId,
    ColumnName.ExecutionId,
    ColumnName.ProjectName,
    ColumnName.StartDate,
    ColumnName.EndDate,
    ColumnName.TariffName,
    ColumnName.ActualCostExcludingVat,
    ColumnName.Vat,
    ColumnName.ActualCostWithVat,
    ColumnName.RightsType,
    ColumnName.Creatives,
    ColumnName.TariffQuantity,
    ColumnName.TariffLimit,
    ColumnName.Empty3,
    ColumnName.Empty4,
    ColumnName.Empty5,
    ColumnName.Empty6,
];

export const MakeProductionTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (
    columnsConfigParams,
) => [
    ColumnName.SourceId,
    ColumnName.ExecutionId,
    ColumnName.ProjectName,
    ColumnName.StartDate,
    ColumnName.EndDate,
    ColumnName.CreativeType,
    ColumnName.ActualCostExcludingVat,
    ColumnName.Vat,
    ColumnName.ActualCostWithVat,
    ColumnName.RightsTypeInput,
    ColumnName.Creatives,
    ColumnName.ImageNumber,
    ColumnName.ValidityTerritory,
    ColumnName.RightsDuration,
    ColumnName.Documents,
    ColumnName.ThirdParty,
    ColumnName.ImageLink,
];

export const MakeAkTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ColumnName.SourceId,
    ColumnName.ExecutionId,
    ColumnName.ProjectName,
    ColumnName.StartDate,
    ColumnName.EndDate,
    ColumnName.CreativeType,
    ColumnName.CommissionWithoutVat,
    ColumnName.Vat,
    ColumnName.CommissionWithVat,
    ColumnName.CommissionAmount,
    ColumnName.Provider,
    ColumnName.Empty1,
    ColumnName.Empty2,
    ColumnName.Empty3,
    ColumnName.Empty4,
    ColumnName.Empty5,
    ColumnName.Empty6,
];

export const MakeLeftFixedColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [];

export const MakeRightFixedColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ...(columnsConfigParams.displayStatusColumns ? [ColumnName.LineStatus, ColumnName.ActStatus] : []),
];

function formatMoney(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    return parsedValue / 100;
}

function formatMoneyInput(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;

    return Math.round(parsedValue * 100);
}

function formatCurrencyValue(value: React.ReactText): string {
    if (!value) {
        return null;
    }

    let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

    let sign = '';

    if (lodash.first(decimalPart) === '-') {
        decimalPart = decimalPart.substring(1);
        sign = '-';
    }

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

    return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''} ₽`;
}

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

export type { ColumnName };
