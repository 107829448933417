import * as React from 'react';
import { CreativeRequestItem } from '@api';

export function useCreativeRequesteItemUnreadComments(creativeRequestItem: CreativeRequestItem) {
    const [isUnread, setIsUnread] = React.useState(false);

    async function onCreativeRequestItemUpdated(payload: any) {
        if (payload.property === 'comments') {
            fillIsUnread();
        }
    }

    async function fillIsUnread() {
        const comments = await creativeRequestItem.model.getComments();
        setIsUnread(!!comments?.some((comment) => !comment.model.isRead || comment.model.isFavorite));
    }

    React.useEffect(
        function fetchCreativeRequestItemCommentsUnreadMarkerAndInitListeners() {
            async function worker() {
                fillIsUnread();
            }

            worker();

            creativeRequestItem.events.onUpdated(onCreativeRequestItemUpdated);

            return function resetListeners() {
                creativeRequestItem.events.offUpdated(onCreativeRequestItemUpdated);
            };
        },
        [creativeRequestItem],
    );

    return isUnread;
}
