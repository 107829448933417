import * as React from 'react';
import * as moment from 'moment';
import autobind from 'autobind-decorator';
import { TaskStatus, EditTaskParams } from 'sber-marketing-types/frontend';

import { TaskApi } from '@api';

import { Utils } from '@common/Utils';

import { TaskCard } from './TaskCard';
import { Executor } from './Executor';
import {
    CardIndicator,
    CardIndicatorColor,
    DeleteButton,
    EditButton,
    // ShareButton
} from '@common/Card';
import { TaskCardContainerProps, TaskCardContainerState } from './types';
import { TaskCardParams } from '@store/tasksList';

export class TaskCardContainer extends React.Component<TaskCardContainerProps, TaskCardContainerState> {
    constructor(props: TaskCardContainerProps) {
        super(props);

        this.state = {
            isDeleteConfirmationOpened: false,
            // isSharingPopupOpened: false,
            isTaskEditorOpen: false,
            showTaskEditorForEnteringRequiredFields: false,
        };
    }

    public render(): JSX.Element {
        const {
            id,
            link,
            change,
            taskPermissions,
            task: {
                title,
                department,
                activityId,
                activity,
                authorDepartment,
                status,
                rate,
                deadline,
                author,
                isLoading,
                stageName,
                description,
                budgetApproval,
            },
            taskWorkType,
            displayActivityName,
            cardType,
            activityStages,
            isOpenedInSidebar,
        } = this.props;

        const { isDeleteConfirmationOpened, showTaskEditorForEnteringRequiredFields } = this.state;
        const isTaskUnvalidForInteraction = this.isTaskUnvalidForInteraction();
        const canEdit = this.canEditTask();

        return React.createElement(TaskCard, {
            id,
            canEdit: canEdit && !isTaskUnvalidForInteraction,
            canDisplayRate: taskWorkType?.enable_rates || false,
            taskPermissions,
            link,
            title,
            description,
            budgetApproval,
            department,
            activityId,
            activity,
            workTypeName: taskWorkType?.name,
            authorDepartment,
            author,
            status,
            rate,
            indicators: this.buildIndicators(),
            buttons: this.buildButtons(),
            change,
            deadline: moment(deadline),
            participantIds: this.getFilterParticipantIds(),
            showTaskEditorForEnteringRequiredFields,
            // isSharingPopupOpened: this.state.isSharingPopupOpened,
            isDeleteConfirmationOpened,
            isLoading,
            displayActivityName,
            cardType,
            stage: stageName,
            isTaskEditorOpen: this.state.isTaskEditorOpen,
            activityStages,
            isOpenedInSidebar,
            closeDeletionConfirmation: this.closeDeletionConfirmation,
            onTaskEditorPopupClose: this.onTaskEditorPopupClose,
            // onSharingPopupClose: this.onSharingPopupClose,
            // onSharingPopupButtonClick: this.onSharingPopupButtonClick,
            onTaskEditorPopupCreated: this.onTaskEditorPopupCreated,
            deleteTask: this.deleteTask,
            editDeadline: this.editDeadline,
            editStatus: this.editStatus,
            editStage: this.editStage,
            closeStatusChangeErrorPopup: this.closeStatusChangeErrorPopup,
            onRootClick: this.onRootClick,
            onCardClick: this.onCardClick,
        });
    }

    @autobind
    private async onTaskEditorPopupCreated(): Promise<void> {
        this.closeTaskEditorPopup();
        this.props.updateTask();
        this.props.reloadTaskSidebarContent(this.props.id);
    }

    @autobind
    private onTaskEditorPopupClose(): void {
        this.closeTaskEditorPopup();
    }

    // @autobind
    // private onSharingPopupClose(): void {
    //     this.closeSharingPopup();
    // }

    // @autobind
    // private onSharingPopupButtonClick(): void {
    //     this.props.updateTask();
    // }

    @autobind
    private onDeleteButtonClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        event.preventDefault();
        event.stopPropagation();
        this.openDeletionConfirmation();
    }

    @autobind
    private onEditButtonClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        event.preventDefault();
        this.openTaskEditorPopup();
    }

    // @autobind
    // private onShareButtonClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    //     event.preventDefault();
    //     this.openSharingPopup();
    // }

    @autobind
    private closeDeletionConfirmation() {
        this.setState({
            isDeleteConfirmationOpened: false,
        });
    }

    @autobind
    private deleteTask() {
        this.closeDeletionConfirmation();
        this.props.deleteTask();
    }

    private getFilterParticipantIds(): number[] {
        const {
            task: { participantIds, executor },
        } = this.props;
        return executor ? participantIds.filter((participantId) => participantId !== executor.id) : participantIds;
    }

    private openTaskEditorPopup() {
        this.setState({
            isTaskEditorOpen: true,
        });
    }

    private closeTaskEditorPopup(): void {
        this.setState({
            isTaskEditorOpen: false,
        });
    }

    // private closeSharingPopup(): void {
    //     this.setState({
    //         isSharingPopupOpened: false
    //     });
    // }

    // private openSharingPopup(): void {
    //     this.setState({
    //         isSharingPopupOpened: true
    //     });
    // }

    private openDeletionConfirmation() {
        this.setState({
            isDeleteConfirmationOpened: true,
        });
    }

    private buildIndicators(): JSX.Element[] {
        const {
            task: { executor, hasBrief },
        } = this.props;
        const canEdit = this.canEditTask();

        const indicators: JSX.Element[] = [];

        const text = `Исполнитель: ${executor ? `${executor.firstName} ${executor.secondName}` : 'Не задан'}`;

        indicators.push(
            React.createElement(Executor, {
                canEdit: canEdit && !this.isTaskUnvalidForInteraction(),
                text,
                executorId: executor?.id,
                taskDepartmentId: this.props.task.departmentId,
                taskWorkType: this.props.taskWorkType,
                editExecutor: this.editExecutor,
            }),
        );

        // if (status === TaskStatus.Draft) {
        //     const text = 'Черновик';
        //     const tooltip = `Статус: ${text}`;
        //     indicators.push(React.createElement(
        //         CardIndicator,
        //         { color: CardIndicatorColor.GRAY, tooltip },
        //         text
        //     ));
        // }

        if (hasBrief) {
            const text = 'Есть бриф задачи';
            const tooltip = `Наличие брифа: ${text}`;
            indicators.push(React.createElement(CardIndicator, { color: CardIndicatorColor.GREEN, tooltip }, text));
        }

        return indicators;
    }

    private buildButtons(): JSX.Element[] {
        const { canDelete } = this.props.task;
        const buttons: JSX.Element[] = [];
        const canEdit = this.canEditTask();

        if (canEdit) {
            buttons.push(
                React.createElement(EditButton, {
                    onClick: this.onEditButtonClick,
                    qaId: 'taskCardEditButton',
                }),
            );
        }

        // buttons.push(React.createElement(ShareButton, { onClick: this.onShareButtonClick }));

        if (canDelete) {
            buttons.push(
                React.createElement(DeleteButton, {
                    onClick: this.onDeleteButtonClick,
                    qaId: 'taskCardDeleteButton',
                }),
            );
        }

        return buttons;
    }

    private canEditTask(): boolean {
        const { canChangeExecutorDeadlineAndFiles, canChangeRest } = this.props.taskPermissions;

        return canChangeExecutorDeadlineAndFiles || canChangeRest;
    }

    @autobind
    private onRootClick(): void {
        if (this.isTaskUnvalidForInteraction()) {
            this.setState({
                isTaskEditorOpen: true,
                showTaskEditorForEnteringRequiredFields: true,
            });
        }
    }

    @autobind
    private async editDeadline(date: moment.Moment): Promise<void> {
        await this.updateTask({ deadline: date.toString() });
    }

    @autobind
    private async editStatus(status: TaskStatus): Promise<void> {
        await this.updateTask({ status });
    }

    @autobind
    private closeStatusChangeErrorPopup() {
        this.setState({
            showTaskEditorForEnteringRequiredFields: false,
        });
    }

    @autobind
    private async editStage(stageId: string): Promise<void> {
        const stageName = this.props.activityStages.find((stage) => stage.id === stageId)?.name || 'Без этапа';

        await this.updateTask({ stageId }, { stageId, stageName });
        this.props.reloadStagesData();
    }

    @autobind
    private async editExecutor(executorId: number): Promise<void> {
        const executor = this.props.appUsers.find((user) => user.id === executorId);
        await this.updateTask(
            {
                executionOrder: {
                    executorId,
                    workTypeId: this.props.task.workTypeId,
                },
            },
            {
                executor: {
                    id: executorId,
                    firstName: executor ? executor.firstName : '',
                    secondName: executor ? executor.secondName : '',
                },
            },
        );
    }

    @autobind
    private onCardClick(): void {
        if (!this.isTaskUnvalidForInteraction()) {
            this.props.openTaskSidebar(this.props.task.id);
        }
    }

    private async updateTask(apiParams: EditTaskParams, storeParams?: Partial<TaskCardParams>): Promise<void> {
        const id = this.props.id;
        const storePropsToUse = storeParams || (apiParams as Partial<TaskCardParams>);

        await TaskApi.editTask(id, { ...apiParams });

        this.props.updateSavedTask({
            taskId: id,
            params: storePropsToUse,
        });

        if (this.props.onTaskUpdate) {
            this.props.onTaskUpdate(id, storePropsToUse);
        }
    }

    private isTaskUnvalidForInteraction(): boolean {
        const { title, deadline, executor, status, budgetApproval } = this.props.task;

        return (
            status === TaskStatus.Draft &&
            !(
                title &&
                deadline &&
                executor &&
                executor.id &&
                (Utils.addBudgetControlsForSelectedWorkType(this.props.taskWorkType?.name)
                    ? !!budgetApproval?.clientDivisionId &&
                      !!budgetApproval?.clientName &&
                      !!budgetApproval?.budgetItemId &&
                      !!budgetApproval?.segmentId &&
                      !!budgetApproval?.productId &&
                      !!budgetApproval?.period &&
                      !!budgetApproval?.productId &&
                      (budgetApproval?.hasLot ? !!budgetApproval.mediaRequest : true) &&
                      (budgetApproval?.hasTitle ? !!budgetApproval.project && !!budgetApproval.naming : true)
                    : true)
            )
        );
    }
}
