import * as React from 'react';
import classNames from 'classnames';

import { useAccordionContext } from '../Accordion';
import * as styles from './styles.scss';

interface Props {}

export const AccordionDetails: React.FC<Props> = ({ children }) => {
    const rootRef = React.useRef<HTMLDivElement>(null);
    const { isExpended } = useAccordionContext();

    const transitionendHandler = React.useCallback(() => {
        if (rootRef.current.style.height !== '0px') {
            rootRef.current.style.height = 'auto';
        }
    }, [rootRef.current]);

    React.useEffect(() => {
        if (isExpended) {
            rootRef.current.style.height = `${rootRef.current.scrollHeight}px`;
        } else {
            rootRef.current.style.height = `${rootRef.current.scrollHeight}px`;
            window.getComputedStyle(rootRef.current, null).getPropertyValue('height');
            rootRef.current.style.height = '0';
        }

        rootRef.current.addEventListener('transitionend', transitionendHandler);

        return () => rootRef?.current?.removeEventListener('transitionend', transitionendHandler);
    }, [isExpended]);

    return (
        <div className={classNames(styles.root)} ref={rootRef}>
            {children}
        </div>
    );
};
