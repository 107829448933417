import * as React from 'react';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type {
    ActivityParams as Activity,
    Brief as BriefParams,
    BriefBlock,
    BriefScheme,
    UserResponseParams as User,
    TaskResponseParams as Task,
} from 'sber-marketing-types/frontend';
import type { NotificationMessage } from '@store/common/types';
import { NotificationActionType, NotificationType } from '@store/common/types';
import { FileToRemove, BriefLoading } from '@store/brief/types';
import type { CopiedBriefProps } from '../withCopiedBrief';

import { BriefTemplate } from './BriefTemplate';
import { BriefStatus } from 'sber-marketing-types/frontend';
import type { StoreState } from '@store';
import { setNotification } from '@store/common/actions';
import { setBriefLoading } from '@store/brief/actions';
import { getBriefState } from '@store/brief/selectors';
import { getHeaderHeight } from '@store/common/selectors';
import { getUserOrganizationId } from '@store/user/selector';
import { withCopiedBrief } from '../withCopiedBrief';
import { BriefType } from '../enums';
import type { BudgetItem } from '@mrm/budget';
import {
    CommonBriefSaver,
    CommonBriefLoader,
    BudgetItemBriefLoader,
    BudgetItemBriefSaver,
    BriefSaver,
    BriefLoader,
} from './modules';
import { PageSaver } from '../modules';
import { Utils } from '@common/Utils';
import { checkerByFieldTypeForAvailabilityFormulasInFields } from '../utils';
import { BriefApi } from '@api';

interface Props extends Partial<CopiedBriefProps & MapProps & DispatchProps> {
    id: string;
    mode: BriefType;
    onFinishPasteCopiedBrief: () => Promise<void>;
    onStartFetchBriefSum: () => Promise<void>;
    reloadBriefHistory: () => void;
}

interface MapProps {
    activity: Activity;
    activityId: number;
    divisionId: string;
    departmentId: string;
    task: Task;
    schemes: BriefScheme[];
    users: User[];
    budgetItem: BudgetItem;
    brief: BriefParams;
    currentBrief: BriefParams;
    filesToRemove: FileToRemove[];
    blocks: BriefBlock[];
    blocksRoot: BriefBlock[];
    headerHeight: number;
    organizationId: string;
    isLoading: boolean;
}

interface DispatchProps {
    setNotification: (notification: NotificationMessage) => void;
    setBriefLoading: (briefLoading: BriefLoading) => void;
}

interface State {
    isPasteCopiedBriefModalOpened: boolean;
}

@(withCopiedBrief as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BriefContainer extends React.Component<Props, State> {
    private briefLoader: BriefLoader;
    private briefSaver: BriefSaver;
    private pageSaver: PageSaver;

    constructor(props: Props) {
        super(props);

        this.state = {
            isPasteCopiedBriefModalOpened: false,
        };

        if (props.mode === BriefType.BudgetItemBrief) {
            const { id, budgetItem } = this.props;

            this.briefSaver = new BudgetItemBriefSaver({
                briefId: id,
                budgetItemId: budgetItem.id,
            });

            this.briefLoader = new BudgetItemBriefLoader({
                briefId: id,
                budgetItemId: budgetItem.id,
            });
        } else {
            const activityId = props.activity.id;
            const taskId = (props.task && props.task.id) || null;

            this.briefSaver = new CommonBriefSaver({
                briefId: props.id,
                activityId,
                taskId,
            });

            this.briefLoader = new CommonBriefLoader({
                activityId,
                taskId,
            });
        }

        this.pageSaver = PageSaver.getInstance();
    }

    public componentDidMount() {
        this.pageSaver.registerBriefSaver(this.briefSaver);
    }

    public componentWillUnmount() {
        this.pageSaver.removeBriefSaver(this.briefSaver);
    }

    public render() {
        const {
            brief,
            organizationId,
            task,
            activity,
            activityId,
            divisionId,
            departmentId,
            schemes,
            users,
            budgetItem,
            blocks,
            blocksRoot,
            isLoading,
        } = this.props;

        const { isPasteCopiedBriefModalOpened } = this.state;

        return React.createElement(BriefTemplate, {
            brief,
            organizationId,
            task,
            activity,
            activityId,
            divisionId,
            departmentId,
            schemes,
            users,
            budgetItem,
            blocks,
            blocksRoot,
            editRight: this.editRight,
            isBriefReady: this.isBriefReady,
            isLoading,
            isBriefPasteDisabled: this.isDisabledOfBriefPaste,
            isBriefCopyDisabled: this.isDisabledOfBriefCopy,
            isBriefExportDisabled: this.isDisabledOfBriefExport,
            isPasteCopiedBriefModalOpened,
            isCalculated: this.isCalculated,
            onBriefCopy: this.onBriefCopy,
            onBriefPaste: this.onBriefPaste,
            onBriefExport: this.onBriefExport,
            onBriefSchemeSelection: this.onBriefSchemeSelection,
            onPasteCopiedBriefModalConfirmClick: this.onPasteCopiedBriefModalConfirmClick,
            onPasteCopiedBriefModalCancelClick: this.onPasteCopiedBriefModalCancelClick,
            onStartFetchSum: this.onStartFetchSum,
            onFinishFetchSum: this.onFinishFetchSum,
        });
    }

    @autobind
    private onBriefCopy(): void {
        this.props.setNotification({
            type: NotificationType.SUCCESS,
            typeAction: NotificationActionType.BRIEF_COPIED_ON_BRIEF_PAGE,
            comment: (
                <>
                    Бриф успешно скопирован в буфер обмена!
                    <br />
                    Чтобы использовать его, зайдите в бриф другого проекта или задачи и нажмите кнопку "Вставить бриф"
                </>
            ),
        });
        this.props.copyBrief(this.props.currentBrief);
    }

    @autobind
    private async onBriefPaste(): Promise<void> {
        if (this.briefAlreadyHasScheme) {
            this.openPopupAboutPasteCopiedBrief();
        } else {
            await this.pasteCopiedBrief();
        }
    }

    @autobind
    private async onBriefExport(): Promise<void> {
        const { activity, task, brief, budgetItem } = this.props;

        const xlsxContent = await BriefApi.getBriefAsXLSL(brief.id, {
            activityId: (activity && Number(activity.id)) || null,
            taskId: (task && task.id) || null,
            budgetItemId: (budgetItem && budgetItem.id) || null,
        });

        Utils.downloadAsXLSX(xlsxContent, `${activity.name || task.title || 'brief'}`);
    }

    @autobind
    private async onBriefSchemeSelection(schemeId: string) {
        const { brief, schemes } = this.props;

        try {
            this.props.setBriefLoading({ briefId: brief.id, loading: true });
            this.briefSaver.setBriefWasCopied(false);
            if (schemeId && (schemeId === brief.schemeId || schemeId === brief.actualSchemeId)) {
                const actualScheme = (schemes || []).find((scheme) => scheme.id === brief.actualSchemeId);
                await this.applyBriefSchemeIdToBrief(brief.actualSchemeId || schemeId);
                if (actualScheme) {
                    this.props.setBriefLoading({ briefId: brief.id, loading: true });
                    await this.briefSaver.actualBrief(brief, actualScheme);
                    await this.briefLoader.updateBrief();
                    this.props.setBriefLoading({ briefId: brief.id, loading: true });
                    await this.props.onFinishPasteCopiedBrief();
                }
            } else {
                await this.applyBriefSchemeIdToBrief(schemeId);
            }

            this.props.reloadBriefHistory();
        } finally {
            this.props.setBriefLoading({ briefId: brief.id, loading: false });
        }
    }

    @autobind
    private async onPasteCopiedBriefModalConfirmClick() {
        this.setState({ isPasteCopiedBriefModalOpened: false });
        await this.pasteCopiedBrief();
    }

    @autobind
    private onPasteCopiedBriefModalCancelClick() {
        this.setState({ isPasteCopiedBriefModalOpened: false });
    }

    @autobind
    private async onStartFetchSum(): Promise<void> {
        await this.props.onStartFetchBriefSum();
    }

    @autobind
    private async onFinishFetchSum(): Promise<void> {}

    private get editRight(): boolean {
        const { brief } = this.props;
        return Boolean(brief && brief.canEdit);
    }

    private get isBriefReady(): boolean {
        const { currentBrief } = this.props;
        return Boolean(currentBrief && currentBrief.status == BriefStatus.Ready);
    }

    private get briefAlreadyHasScheme(): boolean {
        const { currentBrief } = this.props;
        return Boolean(currentBrief && currentBrief.schemeId);
    }

    private get isDisabledOfBriefCopy(): boolean {
        const { currentBrief } = this.props;

        if (currentBrief && currentBrief.schemeId) {
            return lodash.isEmpty(currentBrief.schemeId);
        }

        return true;
    }

    private get isDisabledOfBriefExport(): boolean {
        return !this.props.currentBrief.schemeId;
    }

    private get isDisabledOfBriefPaste(): boolean {
        return lodash.isEmpty(this.props.copiedBrief);
    }

    private openPopupAboutPasteCopiedBrief(): void {
        this.setState({ isPasteCopiedBriefModalOpened: true });
    }

    private async pasteCopiedBrief(): Promise<void> {
        await this.applyBriefSchemeIdToBrief(this.props.copiedBrief.schemeId);
        this.briefLoader.pasteCurrentBrief(this.props.copiedBrief, this.props.currentBrief);
        this.briefSaver.setBriefWasCopied(true);
        await this.briefSaver.saveBrief();
        this.props.onFinishPasteCopiedBrief();
    }

    private async applyBriefSchemeIdToBrief(schemeId: string): Promise<void> {
        const hasBudgetItemWithoutBrief = this.props.mode === BriefType.BudgetItemBrief && !this.hasBriefOfBudgetItem;

        if (hasBudgetItemWithoutBrief) {
            await this.applyBriefSchemeWithCreateBrief(schemeId);
        } else {
            await this.applyBriefSchemeWithoutCreateBrief(schemeId);
        }
    }

    private async applyBriefSchemeWithCreateBrief(schemeId: string): Promise<void> {
        const briefId = await this.briefSaver.createBrief(schemeId);

        this.briefSaver.setBriefId(briefId);
        this.briefLoader.setBriefId(briefId);

        await this.briefLoader.updateBrief();
        await this.briefLoader.updateBudgetItem();
    }

    private async applyBriefSchemeWithoutCreateBrief(schemeId: string): Promise<void> {
        await this.briefSaver.applyBriefScheme(schemeId);
        await this.briefLoader.updateBrief();
    }

    private get hasBriefOfBudgetItem(): boolean {
        return Boolean(this.props.budgetItem && this.props.budgetItem.briefId);
    }

    private get isCalculated(): boolean {
        const { schemes, brief } = this.props;
        const briefScheme = schemes.find((scheme) => scheme.id === brief.schemeId);

        return briefScheme ? this.checkSchemeForAvailabilityFormulas(briefScheme) : false;
    }

    private checkSchemeForAvailabilityFormulas(scheme: BriefScheme): boolean {
        return scheme.blocks
            .map((block) =>
                block.fields
                    .map((field) => {
                        const checkerField = checkerByFieldTypeForAvailabilityFormulasInFields[field.type];
                        return checkerField ? checkerField(field) : false;
                    })
                    .some((item) => item),
            )
            .some((item) => item);
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { id: briefId } = props;

    const {
        initialActivity,
        initialTasks,
        schemes,
        users,
        budgetItem,
        briefs,
        currentBriefs,
        filesToRemove,
        briefsLoading,
    } = getBriefState(state);

    const blocksTree = currentBriefs[briefId].tree
        ? lodash.sortBy(currentBriefs[briefId].tree, ['briefBlockId', 'order'])
        : [];
    const blocks = currentBriefs[briefId].blocks
        ? lodash.sortBy(currentBriefs[briefId].blocks, ['briefBlockId', 'order'])
        : [];
    const blocksRoot = blocksTree.filter((item) => !item.briefBlockId);

    return {
        activity: initialActivity,
        activityId: (initialActivity && Number(initialActivity.id)) || null,
        departmentId: (initialActivity && initialActivity.departmentId) || null,
        divisionId: (initialActivity && initialActivity.divisionId) || null,
        task: initialTasks && initialTasks[briefId],
        users,
        budgetItem,
        schemes,
        brief: briefs[briefId],
        currentBrief: currentBriefs[briefId],
        filesToRemove,
        blocks,
        blocksRoot,
        headerHeight: getHeaderHeight(state),
        organizationId: getUserOrganizationId(state),
        isLoading: briefsLoading[briefId].loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setNotification,
            setBriefLoading,
        },
        dispatch,
    );
}
