// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Error-_Error__root{color:var(--color-red-50)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Error/Error.scss"],"names":[],"mappings":"AAAA,gEACE,yBAA0B","sourcesContent":[".root {\n  color: var(--color-red-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Error-_Error__root"
};
export default ___CSS_LOADER_EXPORT___;
