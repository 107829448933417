import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import {
    TableHeaderCellParams,
    TableBodyCellParams,
    CellPosition,
    ColumnWidths,
    Filter,
    LineId,
    ColumnName,
    SortingParams,
    CustomFilter,
    CustomFilterType,
} from './types';
import { TableNumberByType, TableType } from '@store/creative/types';
import { CreativeRequestLine, CreativeRequestSubLine, Dictionary, DictionaryType } from '@api';
import type { StoreState } from '@store';

import { CellsStorage, TableView, CellEvent } from 'sber-marketing-ui';
import { CreativeTableTemplate } from './CreativeTableTemplate';
import {
    lot1tableColumns,
    lot2tableColumns,
    lot1LeftFixedColumns,
    lot2LeftFixedColumns,
    rightFixedColumns,
    ColumnsConfig,
    LineType,
    CellType,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { getTotalTableExpandedStatus } from '@store/pivotTable/selectors';

const columnWidths: ColumnWidths = lodash.mapValues(ColumnsConfig, (item) => item.defaultWidth);

interface Props extends Partial<MapProps> {
    lot: 1 | 2;
    loading: boolean;
    setCreativeRequestId: (creativeRequestId: string) => void;
    getLine: (lineId: LineId) => CreativeRequestLine;
    getSubLine: (subLineId: LineId) => CreativeRequestSubLine;
    getLines: () => CreativeRequestLine[];
    getSubLines: () => CreativeRequestSubLine[];
    getSubLinesByLineId: (lineId: LineId) => CreativeRequestSubLine[];
    getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
    createLine: () => Promise<void>;
    createSubLine: (lineId: string) => Promise<void>;
    transferLine: (lineId: string, contractId: string) => Promise<void>;
    archiveLine: (lineId: string) => Promise<void>;
    restoreLine: (lineId: string) => Promise<void>;
    onColumnWidthsChange?: (lot: 1 | 2, columnWidths: ColumnWidths) => void;
    onVisibleColumnsChange?: (lot: 1 | 2, newVisibleColumns: ColumnName[]) => void;
    onArchivedFilterChange?: (lot: 1 | 2, archivedFilterStatus: boolean) => void;
    onFixedColumnsChange?: (lot: 1 | 2, fixedColumns: ColumnName[]) => void;
    onFiltersChange?: (lot: 1 | 2, filters: Filter[]) => void;
}

interface MapProps {
    totalTableIsExpanded: boolean;
}

interface State {
    lineIds: string[];
    statusFilterIsActive: boolean;
    leftFixedColumns: Record<'lot1' | 'lot2', ColumnName[]>;
    columns: Record<'lot1' | 'lot2', ColumnName[]>;
    visibleColumns: Record<'lot1' | 'lot2', ColumnName[]>;
    sortingParams: SortingParams;
    filters: Filter[];
    customFilter: CustomFilter;
    expandedTariffLineIds: string[];
    tableHeight: number;
}

@(connect(mapStateToProps, null, null, { forwardRef: true }) as any)
export class CreativeTableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private fixedWidthColumns: ColumnName[];

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineIds: [],
            statusFilterIsActive: false,
            leftFixedColumns: {
                lot1: lot1LeftFixedColumns,
                lot2: lot2LeftFixedColumns,
            },
            columns: {
                lot1: lot1tableColumns,
                lot2: lot2tableColumns,
            },
            visibleColumns: {
                lot1: [...lot1LeftFixedColumns, ...lot1tableColumns],
                lot2: [...lot2LeftFixedColumns, ...lot2tableColumns],
            },
            sortingParams: { columnName: null, orderType: null },
            filters: [],
            customFilter: {
                filterType: CustomFilterType.None,
                name: '',
                params: [],
            },
            expandedTariffLineIds: [],
            tableHeight: 0,
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getLot: this.getLot,
            getLine: this.props.getLine,
            getSubLine: this.props.getSubLine,
            getLines: this.props.getLines,
            getSubLines: this.props.getSubLines,
            getSubLinesByLineId: this.props.getSubLinesByLineId,
            getDictionaries: this.props.getDictionaries,
            getLeftFixedColumns: this.getLeftFixedColumns,
            setLeftFixedColumns: this.setLeftFixedColumns,
            getArchivedFilter: this.getArchivedFilter,
            getFilters: this.getFilters,
            setFilters: this.setFilters,
        });

        this.cellsFactory = new CellsFactory({
            getLot: this.getLot,
            getLine: this.props.getLine,
            getSubLine: this.props.getSubLine,
            getSubLinesByLineId: this.props.getSubLinesByLineId,
            getDictionaries: this.props.getDictionaries,
            setCreativeRequestId: this.props.setCreativeRequestId,
            getExpandedTariffLineIds: this.getExpandedTariffLineIds,
            onCreateSubLineClick: this.onCreateSubLineClick,
            onLineTransfer: this.onLineTransferClick,
            onLineArchive: this.onLineArchiveClick,
            onLineRestore: this.onLineRestoreClick,
            onCellClose: () => this.table.setCursorPosition(null),
            onTariffTotalExpansionToggle: this.onTariffTotalExpansionToggle,
        });

        this.headerCellsStorage = new CellsStorage({
            makeCellParams: this.columnHeaderFactory.makeColumnHeaderParams,
        });

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateFixedWidthColumns();
    }

    public async componentDidMount() {
        this.updateTableHeight();
        this.updateLineIds();

        window.addEventListener('resize', this.onPageResize);
    }

    public async componentWillUnmount() {
        window.removeEventListener('resize', this.onPageResize);
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const lotChanged = this.props.lot !== prevProps.lot;
        const sortingParamsChanged = this.state.sortingParams !== prevState.sortingParams;
        const filtersChanged =
            this.state.filters !== prevState.filters ||
            this.state.customFilter !== prevState.customFilter ||
            this.state.statusFilterIsActive !== prevState.statusFilterIsActive;
        const loadingFinished = !this.props.loading && prevProps.loading;
        const onExpandedStatusChange = this.props.totalTableIsExpanded !== prevProps.totalTableIsExpanded;

        if (lotChanged) {
            this.updateHeaderCells();
        }

        if (sortingParamsChanged || filtersChanged || loadingFinished) {
            this.updateLineIds();
        }

        if (filtersChanged) {
            const columnNames = this.state.filters.map((item) => item.columnName);
            const prevColumnNames = prevState.filters.map((item) => item.columnName);

            const removedColumnsNames = lodash.difference(prevColumnNames, columnNames);
            const addedColumnsNames = lodash.difference(columnNames, prevColumnNames);

            [...removedColumnsNames, ...addedColumnsNames].forEach((columnName) => {
                this.updateHeaderCell(columnName);
            });
        }

        if (onExpandedStatusChange) {
            this.updateTableHeight();
        }
    }

    public render(): JSX.Element {
        const { lot, loading } = this.props;
        const {
            lineIds,
            leftFixedColumns,
            columns,
            visibleColumns,
            statusFilterIsActive,
            filters,
            customFilter,
            tableHeight,
        } = this.state;

        return React.createElement(CreativeTableTemplate, {
            loading,
            lot,
            creativeCount: this.props.getLines().length,
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            columns: columns[`lot${lot}`],
            leftFixedColumns: leftFixedColumns[`lot${lot}`],
            rightFixedColumns,
            fixedWidthColumns: this.fixedWidthColumns,
            visibleColumns: visibleColumns[`lot${lot}`],
            lineIds,
            columnWidths,
            statusFilterIsActive,
            filters,
            customFilter,
            tableHeight,
            tableRef: this.tableRef,
            getFilters: this.getFilters,
            setFilters: this.setFilters,
            getSpecialFilter: this.getSpecialFilter,
            setSpecialFilter: this.setSpecialFilter,
            onAddButtonClick: this.onAddLineClick,
            onFilterButtonClick: this.onFilterButtonClick,
            onCellEvent: this.onCellEvent,
            onColumnWidthsChange: (columnWidths: ColumnWidths) => this.props.onColumnWidthsChange(lot, columnWidths),
            onVisibleColumnsChange: this.onVisibleColumnsChange,
        });
    }

    @autobind
    public onLineCreate(lineId: string) {
        const line = this.props.getLine(lineId);

        if (line) {
            this.updateLineIds();
        }
    }

    @autobind
    public onSubLineCreate(subLineId: string) {
        const newSubLine = this.props.getSubLine(subLineId);

        if (newSubLine) {
            const lineId = newSubLine.model.itemId;
            const line = this.props.getLine(lineId);

            const lineLotMatches = this.getLineLot(line) === this.props.lot;

            if (lineLotMatches) {
                this.updateLineIds();
                this.updateLineCells(lineId);
            }
        }
    }

    @autobind
    public onSubLineDelete(deletedSubLine: CreativeRequestSubLine) {
        if (deletedSubLine) {
            const lineId = deletedSubLine.model.itemId;
            const line = this.props.getLine(lineId);

            const lineLotMatches = this.getLineLot(line) === this.props.lot;

            if (lineLotMatches) {
                this.updateLineIds();
            }
        }
    }

    @autobind
    public onLineUpdate(lineId: string) {
        const sublines = this.props.getSubLinesByLineId(lineId);

        this.updateLineCells(lineId);

        sublines.forEach((item) => {
            this.updateLineCells(item.model.id);
        });

        this.updateLineIds();
    }

    @autobind
    public onSubLineUpdate(subLineId: string) {
        const subLine = this.props.getSubLine(subLineId);

        const lineId = subLine.model.itemId;
        const line = this.props.getLine(lineId);

        const lineIsManual = !line.model.creativeRequestId;

        this.updateLineCells(lineId);
        this.updateLineCells(subLineId);

        if (!lineIsManual) {
            this.updateLineCells(`${lineId}|${LineType.TariffTotal}`);
        }

        this.updateLineIds();
    }

    @autobind
    public onLineContractChange(lineId: string) {
        this.updateLineIds();

        const line = this.props.getLine(lineId);

        if (!!line) {
            const sublines = this.props.getSubLinesByLineId(lineId);

            this.updateLineCells(lineId);

            sublines.forEach((item) => {
                this.updateLineCells(item.model.id);
            });
        }
    }

    @autobind
    public getLeftFixedColumns(): Record<'lot1' | 'lot2', ColumnName[]> {
        return this.state.leftFixedColumns;
    }

    @autobind
    public setLeftFixedColumnList(newFixedColumns: ColumnName[], lot: 1 | 2) {
        if (newFixedColumns !== undefined) {
            const { leftFixedColumns, columns } = this.state;

            const updatedFixedColumns = lodash.clone(leftFixedColumns);
            const updatedColumns = lodash.clone(columns);

            const validColumnNames = newFixedColumns.filter((columnName) => !!ColumnsConfig[columnName]);

            updatedFixedColumns[`lot${lot}`] = validColumnNames;

            updatedColumns[`lot${lot}`] = lodash.without(
                lot === 1
                    ? [...lot1LeftFixedColumns, ...lot1tableColumns]
                    : [...lot2LeftFixedColumns, ...lot2tableColumns],
                ...newFixedColumns,
            );

            this.setState({
                leftFixedColumns: updatedFixedColumns,
                columns: updatedColumns,
            });
        }
    }

    @autobind
    public getArchivedFilter(): boolean {
        return this.state.statusFilterIsActive;
    }

    @autobind
    public setArchivedFilter(archivedFilterStatus: boolean) {
        this.setState({
            statusFilterIsActive: archivedFilterStatus,
        });
    }

    @autobind
    public setColumnWidths(columnWidths: ColumnWidths) {
        if (!this.props.loading && columnWidths) {
            this.table.setColumnWidths(columnWidths);
        }
    }

    @autobind
    public setVisibleColumns(visibleColumns: ColumnName[] = []) {
        const validColumnNames = visibleColumns.filter((columnName) => !!ColumnsConfig[columnName]);

        let updatedVisibleColumns = {
            ...this.state.visibleColumns,
            [`lot${this.props.lot}`]: validColumnNames,
        };

        this.setState({ visibleColumns: updatedVisibleColumns });
    }

    @autobind
    public setFilterValues(filters: Filter[]) {
        if (lodash.isArray(filters)) {
            this.setState({
                filters,
            });
        }
    }

    @autobind
    public async getTableDataForXLSX(): Promise<{
        columnsWidths: number[];
        headers: string[];
        rows: React.ReactText[][];
    }> {
        const { lot } = this.props;
        const { lineIds, leftFixedColumns, columns } = this.state;

        const columnWidths = this.table.getColumnWidths();

        const allColumns = [...leftFixedColumns[`lot${lot}`], ...columns[`lot${lot}`], ...(rightFixedColumns || [])];

        const headers = await Promise.all(
            allColumns.map(async (columnName) => {
                const params = await this.headerCellsStorage.getCellParams(columnName);

                return params.cellProps.title;
            }),
        );

        const rows = await Promise.all(
            lineIds.map(
                async (lineId) =>
                    await Promise.all(
                        allColumns.map(async (columnName) => {
                            const cellParams = await this.tableCellsStorage.getCellParams({ lineId, columnName });

                            const column = ColumnsConfig[columnName];

                            const cellIsInLine = this.props.getLine(lineId);

                            const cellType = cellIsInLine
                                ? column.type[LineType.Creative]
                                : column.type[LineType.Assignment];

                            let title = cellParams?.cellProps?.title || '';

                            if (
                                cellType === CellType.LineHeader ||
                                cellType === CellType.Text ||
                                cellType === CellType.Select
                            ) {
                                title = title.toString();
                            }

                            if (cellType === CellType.FundsInput || cellType === CellType.FundsSelect) {
                                title = title.replaceAll(' ', '');
                                title = title.replaceAll(',', '.');
                                title = parseFloat(title);

                                if (lodash.isNaN(title)) {
                                    title = '';
                                }
                            }

                            return title;
                        }),
                    ),
            ),
        );

        return {
            columnsWidths: allColumns.map((columnName) => columnWidths[columnName]),
            headers,
            rows,
        };
    }

    @autobind
    protected onPageResize() {
        this.updateTableHeight();
        this.updateLineIds();
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component || null;
    }

    @autobind
    protected async onAddLineClick() {
        await this.props.createLine();
    }

    @autobind
    protected async onFilterButtonClick() {
        await this.toggleArchivedFilter();
    }

    @autobind
    protected async onCreateSubLineClick(position: CellPosition) {
        await this.props.createSubLine(position.lineId);
    }

    @autobind
    protected async onLineTransferClick(lineId: LineId, contractId: string) {
        await this.props.transferLine(lineId, contractId);
    }

    @autobind
    protected async onLineArchiveClick(lineId: LineId) {
        await this.props.archiveLine(lineId);
    }

    @autobind
    protected async onLineRestoreClick(lineId: LineId) {
        await this.props.restoreLine(lineId);
    }

    @autobind
    protected async onTariffTotalExpansionToggle(lineId: LineId) {
        this.setState(
            {
                expandedTariffLineIds: lodash.xor(this.state.expandedTariffLineIds, [lineId]),
            },
            async () => {
                await this.updateLineIds();
                await this.updateCell({ lineId: `${lineId}|${LineType.TariffTotal}`, columnName: 'serialNumber' });
            },
        );
    }

    @autobind
    protected onVisibleColumnsChange(newVisibleColumns: ColumnName[]) {
        const updatedVisibleColumns = {
            ...this.state.visibleColumns,
            [`lot${this.props.lot}`]: newVisibleColumns,
        };

        this.setState(
            {
                visibleColumns: updatedVisibleColumns,
            },
            () => {
                if (this.props.onVisibleColumnsChange) {
                    this.props.onVisibleColumnsChange(this.props.lot, newVisibleColumns);
                }
            },
        );
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.MouseSelection:
                await this.updateCell(position, true);
                break;

            case CellEvent.SelectionCancel:
                await this.updateCell(position, false);
                break;
        }
    }

    private updateTableHeight() {
        const tableBodyOffsetTop = 189;
        const tableBodyOffsetBottom = 147;
        const expandedTotalTableTableBodyOffsetBottom = 312;

        const offsetBottom = this.props.totalTableIsExpanded
            ? expandedTotalTableTableBodyOffsetBottom
            : tableBodyOffsetBottom;

        this.setState({
            tableHeight: window.innerHeight - tableBodyOffsetTop - offsetBottom,
        });
    }

    private async updateHeaderCells() {
        const { lot } = this.props;
        const { leftFixedColumns, columns } = this.state;

        [...leftFixedColumns[`lot${lot}`], ...columns[`lot${lot}`]].forEach((columnName) => {
            this.updateHeaderCell(columnName);
        });
    }

    private async updateHeaderCell(columnName: ColumnName) {
        const cellParams = await this.columnHeaderFactory.makeColumnHeaderParams(columnName);

        this.headerCellsStorage.setCellParams(columnName, cellParams);
    }

    private async updateCell(position: CellPosition, edit = false) {
        const cellParams = await this.cellsFactory.makeCellParams(position, edit);

        this.tableCellsStorage.setCellParams(position, cellParams);
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        const { lot } = this.props;
        const { leftFixedColumns, columns } = this.state;

        if (this.table) {
            const allColumns = [...leftFixedColumns[`lot${lot}`], ...columns[`lot${lot}`], ...rightFixedColumns];

            allColumns.forEach((columnName) => {
                const cellPosition = { lineId, columnName };

                const cellEditStatus = this.table.getCellEditStatus(cellPosition);

                if (!cellEditStatus) {
                    this.updateCell(cellPosition, cellEditStatus);
                }
            });
        }
    }

    private updateFixedWidthColumns() {
        this.fixedWidthColumns = lodash
            .keys(ColumnsConfig)
            .filter((columnName) => ColumnsConfig[columnName].disableWidthChange) as ColumnName[];
    }

    @autobind
    private async updateLineIds() {
        const lines = this.props.getLines();
        const subLines = this.props.getSubLines();

        let filteredLines = this.filterArchived(lines);
        let filteredSubLines = this.filterArchived(subLines);

        if (this.state.customFilter) {
            const { creativeRequestLines, creativeRequestSubLines } = await this.applyCustomFilter(
                filteredLines,
                filteredSubLines,
            );

            filteredLines = creativeRequestLines;
            filteredSubLines = creativeRequestSubLines;
        }

        [filteredLines, filteredSubLines] = await Promise.all([
            this.filterLines(filteredLines, LineType.Creative),
            this.filterLines(filteredSubLines, LineType.Assignment),
        ]);

        const lineIds: string[] = [];

        const creativeRequestGroupsBySubLineId = lodash.zipObject(
            filteredSubLines.map((item) => item.model.id),
            await Promise.all(filteredSubLines.map((item) => item.model.creativeRequestGroup)),
        );

        lines.forEach(async (line) => {
            const lineId = line.model.id;
            const lineIsManual = !line.model.creativeRequestId;

            if (filteredLines.includes(line)) {
                lineIds.push(lineId);
            }

            const lineSubLines = filteredSubLines.filter((item) => item.model.itemId === lineId);

            if (lineIsManual) {
                lineIds.push(...lineSubLines.map((item) => item.model.id));
            } else if (!lodash.isEmpty(lineSubLines)) {
                const groupedSublines = lodash.groupBy(
                    lineSubLines,
                    (item) => creativeRequestGroupsBySubLineId[item.model.id]?.value,
                );

                const tariffSublines = groupedSublines[TableType.Tariff];

                if (!lodash.isEmpty(tariffSublines)) {
                    lineIds.push(`${lineId}|${LineType.TariffTotal}`);
                }

                const tariffIsExpanded = this.state.expandedTariffLineIds.some((item) => item.includes(lineId));

                const tableTypes = tariffIsExpanded
                    ? [TableType.Tariff, TableType.Production, TableType.Ak]
                    : [TableType.Production, TableType.Ak];

                tableTypes.forEach((tableType) => {
                    const groupSublines = groupedSublines[tableType] || [];

                    lineIds.push(...groupSublines.map((item) => item.model.id));
                });
            }
        });

        if (!lodash.isEqual(lineIds, this.state.lineIds)) {
            this.setState({
                lineIds,
            });
        }
    }

    private filterArchived<T extends CreativeRequestLine | CreativeRequestSubLine>(lines: T[]) {
        let filteredLines = lines;
        if (this.state.statusFilterIsActive) {
            filteredLines = lines.filter((item) => !item.model.isArchived);
        }
        return filteredLines;
    }

    private async applyCustomFilter(lines: CreativeRequestLine[], sublines: CreativeRequestSubLine[]) {
        let filteredLines = lines;
        let filteredSubLines = sublines;

        switch (this.state.customFilter.filterType) {
            case CustomFilterType.NotClosed:
                const excludeStatus = 'closed';

                const columnsValues = await Promise.all(
                    filteredLines.map((line) =>
                        this.cellsFactory.getCellValue({ columnName: 'status', lineId: line.model.id }),
                    ),
                );

                let excludedIds: string[] = [];

                filteredLines = filteredLines.filter((line, index) => {
                    let value = columnsValues[index];
                    const include = !excludeStatus.includes(value);
                    if (!include) {
                        excludedIds.push(line.model.id);
                    }
                    return include;
                });

                const excludedSerials = await Promise.all(
                    excludedIds.map((lineId) =>
                        this.cellsFactory.getCellValue({ columnName: 'serialNumber', lineId: lineId }),
                    ),
                );

                const subLinesSerial = await Promise.all(
                    filteredSubLines.map((line) =>
                        this.cellsFactory.getCellValue({ columnName: 'serialNumber', lineId: line.model.id }),
                    ),
                );

                filteredSubLines = filteredSubLines.filter((line, index) => {
                    let subLineSerial = subLinesSerial[index];
                    const lineSerial = subLineSerial.substring(0, subLineSerial.indexOf('.'));
                    return !excludedSerials.some((excSerial) => lineSerial == excSerial);
                });

                break;
        }

        return {
            creativeRequestLines: filteredLines,
            creativeRequestSubLines: filteredSubLines,
        };
    }

    private async filterLines<T extends CreativeRequestLine | CreativeRequestSubLine>(
        lines: T[],
        lineType: LineType,
    ): Promise<T[]> {
        const { filters } = this.state;

        let filteredLines = lines;

        for (const filter of filters) {
            const cellsType = lodash.get(ColumnsConfig, [filter.columnName, 'type', lineType]);

            const columnsValues = await Promise.all(
                filteredLines.map((line) =>
                    this.cellsFactory.getCellValue({ columnName: filter.columnName, lineId: line.model.id }),
                ),
            );

            filteredLines = filteredLines.filter((line, index) => {
                const selectedValues = filter.selectedValues;

                if (lodash.isEmpty(selectedValues)) {
                    return true;
                }

                let value = columnsValues[index];

                if ((cellsType === CellType.Input || cellsType === CellType.Textarea) && value === '') {
                    value = null;
                }

                if (cellsType === CellType.Datepicker && value !== null) {
                    value = value.valueOf();
                }

                if (cellsType === CellType.FundsInput) {
                    value = roundNumber(value as number);
                }

                return lodash.isArray(value)
                    ? selectedValues.some((item) => value.includes(item) || (item === null && lodash.isEmpty(value)))
                    : selectedValues.includes(value);
            });
        }

        return filteredLines;

        function roundNumber(value: number, digitsAfterComma = 2): string {
            const roundedValue = Math.round(value * 100) / 100;
            const formatedValue = roundedValue.toFixed(digitsAfterComma);

            const [decimalPart, fractionPart] = formatedValue.split('.');

            return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
        }
    }

    @autobind
    private getLot(): 1 | 2 {
        return this.props.lot;
    }

    @autobind
    private getLineLot(line: CreativeRequestLine): number {
        if (!line) {
            return null;
        }

        const lotDictionary = line.model.dictionary.lot;

        return parseInt(lodash.last(lotDictionary.value.split(' ')), 10);
    }

    @autobind
    private getExpandedTariffLineIds() {
        return this.state.expandedTariffLineIds;
    }

    @autobind
    private getFilters(): Filter[] {
        return this.state.filters;
    }

    @autobind
    private setFilters(filters: Filter[]) {
        this.setState(
            {
                filters,
            },
            () => {
                if (this.props.onFiltersChange) {
                    this.props.onFiltersChange(this.props.lot, this.state.filters);
                }
            },
        );
    }

    @autobind
    private getSpecialFilter(): CustomFilter {
        return this.state.customFilter;
    }

    @autobind
    private setSpecialFilter(specialFilter: CustomFilter) {
        this.setState(
            {
                customFilter: specialFilter,
            },
            () => {
                if (this.props.onFiltersChange) {
                    this.props.onFiltersChange(this.props.lot, this.state.filters);
                }
            },
        );
    }

    @autobind
    private setLeftFixedColumns(newFixedColumns: ColumnName[], lot: 1 | 2) {
        if (newFixedColumns !== undefined) {
            const { leftFixedColumns, columns } = this.state;

            const updatedFixedColumns = lodash.clone(leftFixedColumns);
            const updatedColumns = lodash.clone(columns);

            updatedFixedColumns[`lot${lot}`] = newFixedColumns;

            updatedColumns[`lot${lot}`] = lodash.without(
                lot === 1
                    ? [...lot1LeftFixedColumns, ...lot1tableColumns]
                    : [...lot2LeftFixedColumns, ...lot2tableColumns],
                ...newFixedColumns,
            );

            this.setState(
                {
                    leftFixedColumns: updatedFixedColumns,
                    columns: updatedColumns,
                },
                () => {
                    if (this.props.onFixedColumnsChange) {
                        this.props.onFixedColumnsChange(this.props.lot, newFixedColumns);
                    }
                },
            );
        }
    }

    private async toggleArchivedFilter() {
        return new Promise((resolve) => {
            this.setState(
                {
                    statusFilterIsActive: !this.state.statusFilterIsActive,
                },
                () => {
                    if (this.props.onArchivedFilterChange) {
                        this.props.onArchivedFilterChange(this.props.lot, this.state.statusFilterIsActive);
                    }

                    resolve(true);
                },
            );
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        totalTableIsExpanded: getTotalTableExpandedStatus(state),
    };
}
