import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CloseButton, Popup, Preloader } from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    ComponentState,
    getImportFMPTableMenuState,
    resetComponentState,
} from '@store/budgetExecution/importFMPTableMenu';

import { FileSelectionState } from './FileSelectionState';
import { ReadyToLoadState } from './ReadyToLoadState';

import * as styles from './FMPTableImportMenu.scss';

interface Props {
    budgetId: string;
}

export function FMPTableImportMenu({ budgetId }: Props): JSX.Element {
    const dispatch = useDispatch();

    const componentState = useSelector((state: StoreState) => getImportFMPTableMenuState(state).componentState);
    const shouldRender = componentState !== ComponentState.Hidden;
    const dataUploadingInProgress = useSelector(
        (state: StoreState) => getImportFMPTableMenuState(state).dataUploadingInProgress,
    );

    function closeMenu() {
        dispatch(resetComponentState());
    }

    let content: JSX.Element = null;
    switch (componentState) {
        case ComponentState.FileSelection:
            content = <FileSelectionState budgetId={budgetId} />;
            break;
        case ComponentState.ReadyToLoad:
            content = <ReadyToLoadState budgetId={budgetId} />;
            break;
        default:
            break;
    }

    return shouldRender ? (
        <Popup onOutOfContentClick={closeMenu}>
            <div className={styles.root}>
                <div className={styles.title}>
                    Импорт финального медиаплана (ФМП)
                    <CloseButton onClick={closeMenu} />
                </div>

                <div className={styles.content}>{content}</div>

                {dataUploadingInProgress && <Preloader />}
            </div>
        </Popup>
    ) : null;
}
