import { useEffect } from 'react';

interface Props {
    statusesRootRef: React.RefObject<HTMLInputElement>;
}

interface Result {}

export const useStatusesScroll = ({ statusesRootRef }: Props): Result => {
    useEffect(() => {
        const FULL_SIZE_H = 108;
        const SMALL_SIZE_H = 44;
        const PAGE_PADDING_TOP = 24;

        const pageContent = document.getElementById('pageContent');
        const sticyHeaderWithStagesList = document.getElementById('sticyHeaderWithStagesList');

        const changeStatusesSizes = (offset: number) => {
            const stagesListGroupCardActive = document.getElementById('stagesListGroupCardActive');

            statusesRootRef.current.style.height = FULL_SIZE_H - offset + 'px';
            sticyHeaderWithStagesList.style.paddingTop = offset + 'px';
            sticyHeaderWithStagesList.style.top = -offset + PAGE_PADDING_TOP + 'px';
            if (stagesListGroupCardActive) {
                stagesListGroupCardActive.style.backgroundColor = `rgba(248, 248, 248, ${
                    1 - (1 / (FULL_SIZE_H - SMALL_SIZE_H)) * offset
                })`;
            }
        };

        const scrollHandler = () => {
            const scrollTop = pageContent.scrollTop;

            if (FULL_SIZE_H - scrollTop >= SMALL_SIZE_H) {
                changeStatusesSizes(scrollTop);
            } else {
                changeStatusesSizes(FULL_SIZE_H - SMALL_SIZE_H);
            }
        };

        const mouseoverHandler = () => {
            changeStatusesSizes(0);
        };
        const mouseoutHandler = () => {
            changeStatusesSizes(pageContent.scrollTop);
        };

        pageContent.addEventListener('scroll', scrollHandler);
        statusesRootRef.current.addEventListener('mouseover', mouseoverHandler);
        statusesRootRef.current.addEventListener('mouseout', mouseoutHandler);
        return () => {
            pageContent.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    return {};
};
