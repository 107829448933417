import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Month } from '@mrm/budget';
import {
    CustomScrollbar_new as CustomScrollbar,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Icon,
    IconType,
    Dropdown,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import {
    FetchedData,
    ColumnNames,
    getImportFMPTableMenuState,
    resetComponentState,
    initCorrections,
    setTargetMonth,
} from '@store/budgetExecution/importFMPTableMenu';

import { Utils, DatesFormatter, Money, MoneyFormatter, Captions } from '@common/Utils';

import { ImportFileButton } from '../ImportFileButton';
import { ErrorMessage } from '../ErrorMessage';

import * as styles from './ReadyToLoadState.scss';

const Arrow = (
    <svg width="27" height="8" viewBox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.77899e-08 3.99242C5.20161e-08 3.63802 0.262808 3.34513 0.603784 3.29878L0.698577 3.29239L24.3055 3.2874L22.219 1.19503C21.9689 0.944428 21.9481 0.551105 22.1565 0.276721L22.219 0.205034C22.4691 -0.0455625 22.8616 -0.066446 23.1354 0.142385L23.2069 0.205034L26.5 3.505C26.7501 3.7556 26.7709 4.14892 26.5625 4.42331L26.5 4.495L23.2069 7.79497C22.9341 8.06834 22.4918 8.06834 22.219 7.79497C21.9689 7.54437 21.9481 7.15105 22.1565 6.87666L22.219 6.80498L24.3334 4.68746L0.698577 4.69245C0.312764 4.69245 4.31796e-08 4.37903 4.77899e-08 3.99242Z"
            fill="#7E8681"
        />
    </svg>
);

interface Props {
    budgetId: string;
}

export function ReadyToLoadState({ budgetId }: Props): JSX.Element {
    const dispatch = useDispatch();

    const file = useSelector((state: StoreState) => getImportFMPTableMenuState(state).workbookDescriptor.file);
    const targetMonth = useSelector((state: StoreState) => getImportFMPTableMenuState(state).targetMonth);

    const fetchedData = useSelector((state: StoreState) => getImportFMPTableMenuState(state).fetchedData);

    const fileNameFormatted = file.name.match(/(.*)\.xlsx/)?.[1] || '';

    function onSaveButtonClick() {
        dispatch(initCorrections(null));
    }

    function onCloseButtonClick() {
        dispatch(resetComponentState());
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>Медиаплан:</div>

            <div className={styles.buttonWrapper}>
                <div className={styles.loadedFileInfo}>
                    <div className={styles.loadedFileInfoExtension}>XLS</div>

                    <div>
                        <div className={styles.loadedFileInfoFileName}>{fileNameFormatted}</div>

                        <div className={styles.loadedFileInfoFileParams}>
                            {Utils.formatFileSize(file.size)} • {DatesFormatter.ddMonthyyyy(file.lastModified)}
                        </div>
                    </div>
                </div>
            </div>

            <TargetMonth />

            <ErrorMessage />

            <div className={styles.fetchedDataTableTitle}>Загруженные строки</div>

            <div className={styles.fetchedDataTable}>
                <div className={styles.fetchedDataTableHeader}>
                    <span>ID:</span>
                    <span>Деньги:</span>
                </div>

                <div className={styles.fetchedDataTableBody}>
                    <CustomScrollbar maxHeight={336} hideScrollX freezeScrollX>
                        {fetchedData.map((data, i) => (
                            <FetchedDataTableRow key={i} data={data} targetMonth={targetMonth} />
                        ))}
                    </CustomScrollbar>
                </div>
            </div>

            <div className={styles.controllButtons}>
                <Button theme={ButtonTheme.GhostRoundedBlack} onClick={onSaveButtonClick}>
                    <div className={styles.controllButton}>Сохранить</div>
                </Button>

                <Button theme={ButtonTheme.GhostRounded} onClick={onCloseButtonClick}>
                    <div className={styles.controllButton}>Отменить</div>
                </Button>
            </div>
        </div>
    );
}

interface FetchedDataTableRowProps {
    data: FetchedData;
    targetMonth: Month;
}

function FetchedDataTableRow({ data, targetMonth }: FetchedDataTableRowProps): JSX.Element {
    const budgetItem = useSelector(
        (state: StoreState) => getImportFMPTableMenuState(state).budgetItems.byId[data[ColumnNames.SerialNumber]],
    );

    const moneyBefore = MoneyFormatter.toThousands(Money.fromCopecks(budgetItem.factFunds[targetMonth]), {
        hideCaption: true,
    });
    const moneyAfter = MoneyFormatter.toThousands(
        Money.fromCopecks(budgetItem.factFunds[targetMonth] + data[ColumnNames.FactValue]),
        { hideCaption: true },
    );

    return (
        <div className={styles.fetchedDataTableBodyRow}>
            <div className={classnames(styles.fetchedDataTableRowColumn, styles.fetchedDataTableRowIdColumn)}>
                <span className={styles.fetchedDataTableRowIdColumnContent}>{data[ColumnNames.SerialNumber]}</span>
            </div>

            <div className={classnames(styles.fetchedDataTableRowColumn, styles.fetchedDataTableRowSumColumn)}>
                {moneyBefore}&nbsp;<span className={styles.fetchedDataTableRowValueCurency}>{Captions.Thousands}</span>
            </div>

            <div className={classnames(styles.fetchedDataTableRowDiffColumn, styles.fetchedDatatTableRowArrowColumn)}>
                {Arrow}
            </div>

            <div className={styles.fetchedDataTableRowColumn}>
                {moneyAfter}&nbsp;<span className={styles.fetchedDataTableRowValueCurency}>{Captions.Thousands}</span>
            </div>
        </div>
    );
}

function TargetMonth(): JSX.Element {
    const dispatch = useDispatch();

    const targetMonth = useSelector((state: StoreState) => getImportFMPTableMenuState(state).targetMonth);

    function onTargetMonthSelect(targetMonth: Month) {
        dispatch(setTargetMonth(targetMonth));
    }

    return (
        <Dropdown
            disableScroll
            closeOnContentClick
            title={(isOpened) => (
                <div className={styles.targetMonthWrapper}>
                    <div className={styles.targetMonth}>
                        Месяц для заполнения данных:{' '}
                        <span className={styles.targetMonthBlack}>
                            {DatesFormatter.MonthNamesCapitalized[targetMonth]}
                        </span>
                        <Icon
                            type={IconType.EXPAND_ICON}
                            svgSize={16}
                            className={classnames(styles.titleExpandIcon, isOpened && styles.titleExpandIconRotated)}
                        />
                    </div>
                </div>
            )}
            content={
                <div className={styles.targetMonthContent}>
                    {DatesFormatter.MonthOrder.map((month) => (
                        <div
                            key={month}
                            className={styles.targetMonthContentItem}
                            onClick={() => onTargetMonthSelect(month)}
                        >
                            {DatesFormatter.MonthNamesCapitalized[month]}
                        </div>
                    ))}
                </div>
            }
        />
    );
}
