import * as React from 'react';

import { useAccordionContext } from '../Accordion';
import * as styles from './styles.scss';

interface Props {}

export const AccordionSummary: React.FC<Props> = ({ children }) => {
    const { isExpended, setExpansion } = useAccordionContext();

    return (
        <div className={styles.root} onClick={() => setExpansion(!isExpended)}>
            {children}
        </div>
    );
};
