import * as React from 'react';

import { Table } from './Table';
import { DownloadLink } from './DownloadLink';

import * as styles from './styles.scss';

interface Props {
    title: string;
    data: string[][];
}

export const List: React.FC<Props> = ({ title, data }) => {
    const dataWithHeaders = [['Канал/Площадка', 'Текст'], ...data];

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <div>
                    {title} {data.length}
                </div>
                <div className={styles.download}>
                    <DownloadLink data={dataWithHeaders} />
                </div>
            </div>
            <Table data={dataWithHeaders} />
        </div>
    );
};
