import * as React from 'react';

import type { FieldValueLongId } from '@store/autopilot/creativeForm';
import type { CommonProps } from '../types';

import { SelectInput, Option, useLoadOptions } from '../../../common';
import { Header } from '../common';

import * as style from './styles.scss';

interface Props extends CommonProps<FieldValueLongId> {}

export const LongIdField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }) => {
    const { loadOptions } = useLoadOptions({ referenceId: field.referenceId });

    const onChange = React.useCallback(
        (selectedOption: Option) => {
            outsideChangeHandler({
                fieldId: field.id,
                fieldError: '',
                fieldValue: {
                    ...field.value.current,
                    value: selectedOption.value,
                },
            });
        },
        [field, outsideChangeHandler],
    );

    const defaultValue = {
        value: field.value?.current?.value,
        label: field.value?.current?.value,
    };

    return (
        <div className={style.root}>
            <Header field={field} />

            <div className={style.input}>
                <SelectInput defaultValue={defaultValue} loadOptions={loadOptions} onChange={onChange} />
            </div>
        </div>
    );
};
