import * as React from 'react';
import * as lodash from 'lodash';

import type { CreativeRequest, CreativeRequestBudgetItem } from '@api';

export function useCreativeRequestBudgetItems(creativeRequest: CreativeRequest) {
    const [budgetItems, setBudgetItems] = React.useState<CreativeRequestBudgetItem[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function loadBudgetItems() {
            const newBudgetItems = lodash.clone(await creativeRequest?.model.getBudgetItems()) || [];
            setBudgetItems(newBudgetItems);
            setLoading(false);
        }

        loadBudgetItems();

        creativeRequest?.events.onBudgetItemsAdded(loadBudgetItems);
        creativeRequest?.events.onBudgetItemsRemoved(loadBudgetItems);

        return () => {
            creativeRequest?.events.offBudgetItemsAdded(loadBudgetItems);
            creativeRequest?.events.offBudgetItemsRemoved(loadBudgetItems);
        };
    }, [creativeRequest]);

    return {
        loading,
        budgetItems,
    };
}
