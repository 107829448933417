import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './TableSettings.scss';

import { Icon, IconType, FilterMenu, FilterCheckboxBorderType, BodyPortal } from 'sber-marketing-ui';
import { TableType } from '@store/creative/types';

import { ColumnName } from '../types';
import { MakeColumnsConfig } from '../ColumnsConfig';

interface Props {
    columns: Record<TableType, Record<'left' | 'center' | 'right', ColumnName[]>>;
    enabledColumns: (ColumnName | string)[];
    onEnabledColumnsChange: (enabledColumns: (ColumnName | string)[]) => void;
}

export const TableSettings = ({ columns, enabledColumns, onEnabledColumnsChange }: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);

    const ColumnsConfig = MakeColumnsConfig({});

    const columnsToExclude = ['serialNumber', 'lineStatus', 'actStatus'];

    const filterMenuItems = React.useMemo(() => {
        const columnsByTableType = lodash.mapValues(columns, (item) =>
            lodash.without([...item.left, ...item.center, ...item.right], ...columnsToExclude),
        );

        const groupedColumns = columnsByTableType[TableType.Tariff].map((_, index) =>
            lodash.uniq([
                columnsByTableType[TableType.Tariff][index],
                columnsByTableType[TableType.Production][index],
                columnsByTableType[TableType.Ak][index],
            ]),
        );

        return groupedColumns.map((columnNames) => {
            const titles = columnNames.map((columnName) => ColumnsConfig[columnName].title as string);

            return {
                id: columnNames.join('|'),
                title: lodash.compact(titles).join(' | '),
            };
        });
    }, [columns]);

    const onOpenerClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
    }, [isOpened]);

    const onMaskClick = React.useCallback(() => {
        setOpenedStatus(false);
    }, []);

    return (
        <div className={style.root}>
            <div className={style.opener} onClick={onOpenerClick}>
                <div className={style.openerIcon}>
                    <Icon type={IconType.GEAR16} />
                </div>
                Настроить таблицу
            </div>

            {isOpened && (
                <>
                    <div className={style.content}>
                        <div className={style.contentDescription}>Настроить таблицу</div>

                        <FilterMenu
                            filterTitle={'Отображаемые колонки'}
                            items={filterMenuItems}
                            checkedItemsIds={enabledColumns}
                            onItemSelection={onEnabledColumnsChange}
                            border={FilterCheckboxBorderType.NONE}
                            preserveAllSelectedState
                            freezeExpansion
                            disableSort
                            isExpanded
                        />
                    </div>

                    <BodyPortal>
                        <div className={style.mask} onClick={onMaskClick} />
                    </BodyPortal>
                </>
            )}
        </div>
    );
};
