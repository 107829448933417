// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Name-_styles__root{display:flex;align-items:center;column-gap:5px;overflow:hidden}.src-client-modules-activity-pages-creative-Name-_styles__number{font-family:Open Sans;font-weight:600;font-size:22px;color:#092A3A;white-space:nowrap}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Name/styles.scss"],"names":[],"mappings":"AAAA,+DACI,YAAa,CACb,kBAAmB,CACnB,cAAe,CACf,eAAgB,CACnB,iEAGG,qBAAsB,CACtB,eAAgB,CAChB,cAAe,CACf,aAAc,CACd,kBAAmB","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n    column-gap: 5px;\n    overflow: hidden;\n}\n\n.number {\n    font-family: Open Sans;\n    font-weight: 600;\n    font-size: 22px;\n    color: #092A3A;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-Name-_styles__root",
	"number": "src-client-modules-activity-pages-creative-Name-_styles__number"
};
export default ___CSS_LOADER_EXPORT___;
