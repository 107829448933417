import { useState } from 'react';

export const useVisibility = () => {
    const [isVisible, setVisibility] = useState(false);

    return {
        isVisible,
        setVisibility,
    };
};
