import * as React from 'react';
import * as styles from './IdSelector.scss';
import classnames from 'classnames';
import { BudgetItemDirection, Itemtype } from '../PlanTransferModal';
import { BudgetItem } from '@mrm/budget';

interface Props {
    title: string;
    IDsList?: BudgetItem[] | null;
    selectedItem: BudgetItem;
    setID?: (ID: BudgetItem) => void;
    directionType: BudgetItemDirection;
    budgetItemType: Itemtype;
}

export const IdSelector = ({
    title,
    IDsList,
    selectedItem,
    setID,
    directionType,
    budgetItemType,
}: Props): JSX.Element => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>{title}</div>
            <div className={styles.itemsList}>
                {directionType === BudgetItemDirection.from ? (
                    IDsList?.map((item) => (
                        <div
                            key={item.id}
                            className={classnames(styles.item, selectedItem.id === item.id && styles.selectedItem)}
                            onClick={() => setID(item)}
                        >
                            {item.serialNumber + (budgetItemType === Itemtype.CAPEX ? ' CAPEX' : '')}
                        </div>
                    ))
                ) : (
                    <div className={classnames(styles.item, styles.selectedItem)}>{selectedItem.serialNumber}</div>
                )}
            </div>
        </div>
    );
};
