import * as React from 'react';
import QRCode from 'react-qr-code';

import { Button, Divider, Flex, Modal, ModalProps, Typography } from '@sbermarketing/mrm-ui';

export interface TelegramModalProps extends ModalProps {
    link?: string;
}

export function TelegramModal({ link, ...props }: TelegramModalProps) {
    return link ? (
        <Modal width={440} title="Создание чата" vertical {...props}>
            <Flex gap={16}>
                <Typography>
                    <p>Если используете мобильную версию Telegram:</p>
                    <ul>
                        <li>отсканируйте QR-код камерой устройства</li>
                        <li>нажмите кнопку «Старт» в&nbsp;чат-боте для создания чата</li>
                    </ul>
                </Typography>
                <Flex>
                    <QRCode value={link} size={160} />
                </Flex>
            </Flex>
            <Divider>ИЛИ</Divider>
            <Button element="a" to={link}>
                Открыть Telegram
            </Button>
        </Modal>
    ) : null;
}
