import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './MediaplanSettingsStageWidget.scss';

import type { StoreState } from '@store';

import { Toggle, TogglePosition, WithTooltip, Icon, IconType } from 'sber-marketing-ui';
import { RadiobuttonGroup } from './RadiobuttonGroup';
import { Suggest, SuggestItem } from './Suggest';
import { TimeDistributionTable } from './TimeDistributionTable';
import { ManualRatingsSplitTable } from './ManualRatingsSplitTable';
import { BudgetSplitTable } from './BudgetSplitTable';
import { setPreloaderStatus, setBriefFormValues } from '@store/autopilotTv/actions';
import { getAutopilot, getMediaplan, getBriefStageForm } from '@store/autopilotTv/selectors';
import { Saver, Loader } from '../../modules';

const secondAdvertiserMarginItems: SuggestItem[] = [
    { title: '5%', value: 5 },
    { title: '10%', value: 10 },
    { title: '15%', value: 15 },
    { title: '20%', value: 20 },
];

interface Props {
    readOnly?: boolean;
}

export const MediaplanSettingsStageWidget = ({ readOnly }: Props): JSX.Element => {
    const saver = Saver.getInstance();
    const loader = Loader.getInstance();

    const autopilot = useSelector((state: StoreState) => getAutopilot(state));
    const mediaplan = useSelector((state: StoreState) => getMediaplan(state));
    const briefForm = useSelector((state: StoreState) => getBriefStageForm(state));

    const displayBudgetSplitTable = !!autopilot.mediaplan && !lodash.isEmpty(mediaplan);

    const dispatch = useDispatch();

    const onSecondAdvertiserSwitch = React.useCallback(
        (value: boolean) => {
            dispatch(setBriefFormValues({ secondAdvertiserEnable: value }));

            if (value === false) {
                dispatch(setBriefFormValues({ secondAdvertiserMargin: null }));
            }
        },
        [dispatch],
    );

    const onSecondAdvertiserMarginChange = React.useCallback(
        (value: string) => {
            let parsedValue = value !== '' ? parseInt(value, 10) : 0;

            if (parsedValue < 0) {
                parsedValue = 0;
            }

            if (parsedValue > 100) {
                parsedValue = 100;
            }

            dispatch(setBriefFormValues({ secondAdvertiserMargin: parsedValue }));
        },
        [dispatch],
    );

    const onManualRatingSplitSwitch = React.useCallback(() => {
        dispatch(setBriefFormValues({ manualRatingSplitEnable: !briefForm.manualRatingSplitEnable }));
    }, [dispatch, briefForm.manualRatingSplitEnable]);

    const onUpdateMediaplanButtonClick = React.useCallback(async () => {
        dispatch(setPreloaderStatus(true));
        await saver.saveBriefForm();
        await saver.updateMediaplan();
        await loader.loadAutopilot();
        await loader.loadMediaplan();
        dispatch(setPreloaderStatus(false));
    }, [dispatch]);

    return (
        <div className={style.root}>
            <div className={style.title}>Параметры расчёта</div>

            <div className={style.form}>
                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Регион</div>

                    <div className={style.fieldTextValue}>Вся Россия</div>
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Каналы коммуникации</div>

                    <div className={style.fieldTextValue}>
                        Нац ТВ
                        <br />
                        Не эфир ТВ
                    </div>
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Второй рекламодатель</div>

                    <div className={style.secondAdvertiserSwitch}>
                        <RadiobuttonGroup
                            items={[
                                {
                                    title: 'Нет',
                                    value: false,
                                },
                                {
                                    title: 'Есть',
                                    value: true,
                                },
                            ]}
                            selectedValue={briefForm.secondAdvertiserEnable}
                            onSelection={onSecondAdvertiserSwitch}
                            disabled={readOnly}
                        />
                    </div>
                </div>

                {briefForm.secondAdvertiserEnable && (
                    <div className={classNames(style.field, style.line)}>
                        <div className={style.fieldTitle}>Наценка *</div>

                        <div className={style.fieldDescription}>
                            Выберите процент наценки или введите собственное значение
                        </div>

                        <div className={style.secondAdvertiserMarginSuggest}>
                            <Suggest
                                title={'от 0 до 100%'}
                                sign={'%'}
                                value={
                                    briefForm.secondAdvertiserMargin !== null
                                        ? briefForm.secondAdvertiserMargin.toString()
                                        : null
                                }
                                items={secondAdvertiserMarginItems}
                                onValueChange={onSecondAdvertiserMarginChange}
                            />
                        </div>
                    </div>
                )}

                <div className={classNames(style.field, style.line)}>
                    <TimeDistributionTable />
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>
                        <div className={style.manualRatingSplitToggle}>
                            <Toggle
                                position={
                                    briefForm.manualRatingSplitEnable ? TogglePosition.RIGHT : TogglePosition.LEFT
                                }
                                onClick={onManualRatingSplitSwitch}
                            />
                        </div>

                        <div className={style.toggleTitle} onClick={onManualRatingSplitSwitch}>
                            Вручную задать сплит рейтингов по неделям
                            <WithTooltip
                                className={style.infoIcon}
                                content={
                                    'При отключенной опции рейтинги по неделям будут распределены равномерно,\nпропорционально количеству дней, вошедших в период кампании.'
                                }
                            >
                                <Icon type={IconType.INFO_CIRCLE} svgSize={16} />
                            </WithTooltip>
                        </div>
                    </div>

                    {briefForm.manualRatingSplitEnable && (
                        <div className={classNames(style.field, style.line)}>
                            <ManualRatingsSplitTable />
                        </div>
                    )}
                </div>
            </div>

            <div className={style.title}>
                Плановый сплит бюджета по телеканалам
                <div className={style.button} onClick={onUpdateMediaplanButtonClick}>
                    <div className={style.buttonIcon}>
                        <Icon type={IconType.CIRCULAR_ARROW24_CLOCKWISE} svgSize={24} />
                    </div>
                    Пересчитать
                </div>
            </div>

            {displayBudgetSplitTable && (
                <div className={style.budgetSplitTable}>
                    <BudgetSplitTable />
                </div>
            )}
        </div>
    );
};
