import * as React from 'react';

import type { BudgetItem } from '@mrm/budget';
import type { ProjectBudgetItem, CreativeRequestBudgetItem } from '@api';

import { BudgetItemApi } from '@api';

const CAPEX_ITEM_VALUE = '206.6';

export function useBudgetItems<T extends ProjectBudgetItem | CreativeRequestBudgetItem>(linkedBudgetItems: T[]) {
    const [budgetItems, setBudgetItems] = React.useState<BudgetItem[]>([]);
    const [groupedBudgetItems, setGroupedBudgetItems] = React.useState<Record<'opex' | 'capex', BudgetItem[]>>({
        opex: [],
        capex: [],
    });
    const [loading, setLoading] = React.useState(true);

    async function loadBudgetItems() {
        const budgetItemIds = linkedBudgetItems.map((item) => item.model.id);

        const newBudgetItems = await Promise.all(budgetItemIds.map((id) => BudgetItemApi.getBudgetItem(id)));
        setBudgetItems(newBudgetItems);
        setGroupedBudgetItems(groupBudgetItemsByItemType(newBudgetItems));
        setLoading(false);
    }

    React.useEffect(() => {
        loadBudgetItems();
    }, [linkedBudgetItems]);

    return {
        loading,
        budgetItems,
        groupedBudgetItems,
        refetch: loadBudgetItems,
    };
}

function groupBudgetItemsByItemType(budgetItems: BudgetItem[]): Record<'opex' | 'capex', BudgetItem[]> {
    const groupedBudgetItems: Record<'opex' | 'capex', BudgetItem[]> = {
        opex: [],
        capex: [],
    };

    budgetItems.forEach((item) => {
        const budgetItemType = item.dictionary.item?.value !== CAPEX_ITEM_VALUE ? 'opex' : 'capex';

        groupedBudgetItems[budgetItemType].push(item);
    });

    return groupedBudgetItems;
}
