import * as React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '@store';
import { CreativeRequest } from '@api';
import { getLoginUser } from '@store/user';
import { getAppUsers } from '@store/appUsers';
import { uniq, compact, flatten, isEmpty } from 'lodash';

export function useUsersForMentionOrganizationIds(creativeRequest: CreativeRequest): string[] {
    const creativeRequestRef = React.useRef<CreativeRequest>(null);
    const appUsers = useSelector((state: StoreState) => getAppUsers(state));
    const loginUserOrganization = useSelector((state: StoreState) => getLoginUser(state).attributes.organizationId);

    const [organizationIds, setOrganizationIds] = React.useState<string[]>([]);

    async function worker() {
        const creativeRequestUsers = flatten(
            await Promise.all([
                creativeRequest.model.getInitiators(),
                creativeRequest.model.getAgencyExecutors(),
                creativeRequest.model.findAvailableExperts(),
            ]),
        ).filter((user) => !isEmpty(user));

        setOrganizationIds(
            uniq(
                compact([
                    loginUserOrganization,
                    ...creativeRequestUsers.map(
                        (creativeRequestUser) => appUsers.entities[creativeRequestUser.id]?.organizationId,
                    ),
                ]),
            ),
        );
    }

    React.useEffect(() => {
        if (!creativeRequest) return;
        worker();
    }, [appUsers]);

    React.useEffect(() => {
        if (!creativeRequest) return;
        if (creativeRequestRef.current) {
            creativeRequest.events.offAgencyExecutorsUpdated(worker);
        }

        creativeRequestRef.current = creativeRequest;
        creativeRequest.events.onAgencyExecutorsUpdated(worker);
    }, [creativeRequest]);

    return organizationIds;
}
