import * as React from 'react';

import * as style from './EmptySourseLinesMessage.scss';

export const EmptySourseLinesMessage: React.FC = () => {
    return (
        <div className={style.root}>
            <div className={style.title}>ID исполнения</div>
            <div className={style.message}>Привяжите ID источника</div>
        </div>
    );
};
