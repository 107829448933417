import * as React from 'react';
import { v4 as uuid } from 'uuid';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { Line, LineId, DICTIONARY_TYPES, UPDATE_SUBSCRIPTION_METHOD_NAMES } from '../types';
import {
    CreativeRequest,
    CreativeRequestItem,
    CreativeRequestDonor,
    CreativeRequestContract,
    ProjectBudgetItem,
    Dictionary,
    DictionaryType,
    Project,
} from '@api';

import { CreativeRequestDomain, TableType } from '@store/creative';
import { MrmClient } from '@api';

interface Table {
    onCreativeRequestReload: (newCreativeRequest: CreativeRequest) => Promise<void>;
    onCreativeRequestBudgetItemUpdate: (budgetItems: CreativeRequestDonor[]) => Promise<void>;
    onBudgetItemsAdded: (budgetItem: ProjectBudgetItem) => Promise<void>;
    onBudgetItemsRemoved: (budgetItem: ProjectBudgetItem) => Promise<void>;
    onLineCreate: (newLine: Line) => Promise<void>;
    onLineRemoved: (newLine: Line) => Promise<void>;
    onLineUpdate: (lineId: string) => Promise<void>;
    onLineReloaded: (newLine: Line) => Promise<void>;
    onLineCommentsUpdate: (lineId: string) => Promise<void>;
}

export interface ChildrenProps {
    loading: boolean;
    getProject: () => Project;
    getCreativeRequest: () => CreativeRequestDomain;
    getLine: (lineId: string) => Line;
    getLines: () => Line[];
    getCreativeRequestLot: () => string;
    getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
    getContracts: () => Record<'lot1' | 'lot2', CreativeRequestContract[]>;
    createLine: (tableType: TableType) => Promise<void>;
    archiveLine: (lineId: string) => Promise<void>;
    restoreLine: (lineId: string) => Promise<void>;
}

interface Props extends ExternalProps {}

interface ExternalProps {
    creativeRequestId: string;
    tableRef: React.MutableRefObject<Table>;
    children: (props: ChildrenProps) => JSX.Element;
}

interface State {
    loading: boolean;
}

export class WithClientData extends React.PureComponent<Props, State> {
    private project: Project;
    private creativeRequest: CreativeRequestDomain;
    private creativeRequestItems: Line[];
    private creativeRequestLot: string;
    private dictionariesByType: Partial<Record<DictionaryType, Dictionary[]>> = {};
    private contracts: Record<'lot1' | 'lot2', CreativeRequestContract[]>;

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    public async componentDidMount() {
        await this.init();

        this.setState({ loading: false });
    }

    public render(): JSX.Element {
        const childrenProps = this.makeChildrenProps();

        return this.props.children(childrenProps);
    }

    private makeChildrenProps(): ChildrenProps {
        const { loading } = this.state;

        return {
            loading,
            getProject: this.getProject,
            getCreativeRequest: this.getCreativeRequest,
            getLine: this.getLine,
            getLines: this.getLines,
            getCreativeRequestLot: this.getCreativeRequestLot,
            getDictionaries: this.getDictionaries,
            getContracts: this.getContracts,
            createLine: this.createLine,
            archiveLine: this.archiveLine,
            restoreLine: this.restoreLine,
        };
    }

    @autobind
    private async onCreativeRequestReload(newCreativeRequest: CreativeRequest) {
        await this.init();

        this.props.tableRef.current.onCreativeRequestReload(newCreativeRequest);
    }

    @autobind
    private async onLineReloaded(newLine: CreativeRequestItem) {
        newLine.events.onReloaded(this.onLineReloaded);
        this.subscribeLineChanges(newLine);
        this.subscribeLineCommentsUpdate(newLine);

        this.props.tableRef.current.onLineReloaded(newLine);
    }

    @autobind
    private async onLineCreate(newLine: CreativeRequestItem) {
        await this.loadLines();

        newLine.events.onReloaded(this.onLineReloaded);
        this.subscribeLineChanges(newLine);
        this.subscribeLineCommentsUpdate(newLine);

        this.props.tableRef.current.onLineCreate(newLine);
    }

    @autobind
    private async onLineRemoved(removedLine: CreativeRequestItem) {
        await this.loadLines();

        this.props.tableRef.current.onLineRemoved(removedLine);
    }

    @autobind
    private async onBudgetItemsAdded(budgetItem: ProjectBudgetItem) {
        await this.props.tableRef.current.onBudgetItemsAdded(budgetItem);
    }

    @autobind
    private async onBudgetItemsRemoved(budgetItem: ProjectBudgetItem) {
        await this.props.tableRef.current.onBudgetItemsRemoved(budgetItem);
    }

    @autobind
    private async onLineUpdate(lineId: string) {
        this.props.tableRef.current?.onLineUpdate(lineId);
    }

    @autobind
    private async onLineCommentsUpdate(lineId: string) {
        this.props.tableRef.current.onLineCommentsUpdate(lineId);
    }

    @autobind
    private async init() {
        await this.loadCreativeRequest();
        await this.loadLines();

        await Promise.all([
            this.loadProject(),
            this.loadCreativeRequestLot(),
            this.loadDictionaries(),
            this.loadContracts(),
        ]);

        this.creativeRequest.events.onReloaded(this.onCreativeRequestReload);
        this.creativeRequest.events.onItemsAdded(this.onLineCreate);
        this.creativeRequest.events.onItemsRemoved(this.onLineRemoved);
        this.project.events.onBudgetItemsAdded(this.onBudgetItemsAdded);
        this.project.events.onBudgetItemsRemoved(this.onBudgetItemsRemoved);

        this.creativeRequestItems.forEach((line) => {
            line.events.onReloaded(this.onLineReloaded);
            this.subscribeLineChanges(line);
            this.subscribeLineCommentsUpdate(line);
        });
    }

    @autobind
    private async loadCreativeRequest() {
        const client = await MrmClient.getInstance();

        this.creativeRequest = await client.domain.creativeRequests.getCreativeRequest({
            id: this.props.creativeRequestId,
        });
    }

    @autobind
    private async loadProject() {
        const client = await MrmClient.getInstance();
        const projectId: number = this.creativeRequest.model.projectId as any;
        this.project = await client.domain.projects.getProject({ id: projectId });
    }

    @autobind
    private async loadLines() {
        this.creativeRequestItems = await this.creativeRequest.model.getItems();

        this.creativeRequestItems.sort(
            (itemA: CreativeRequestItem, itemB: CreativeRequestItem) => itemA.model.number - itemB.model.number,
        );
    }

    @autobind
    private async loadCreativeRequestLot(): Promise<void> {
        const lotDictionary = await this.creativeRequest.model.lot;

        this.creativeRequestLot = lotDictionary?.value || null;
    }

    private async loadDictionaries(): Promise<void> {
        const client = await MrmClient.getInstance();

        await Promise.all(
            DICTIONARY_TYPES.map(
                (dictionaryType) =>
                    new Promise<void>(async (resolve) => {
                        const dictionaries = await client.Dictionary.getByType(dictionaryType as any);

                        this.dictionariesByType[dictionaryType] = dictionaries;

                        resolve();
                    }),
            ),
        );
    }

    private async loadContracts(): Promise<void> {
        const client = await MrmClient.getInstance();

        const contracts = await client.domain.creativeRequests.getContracts();

        this.contracts = await this.groupContractsByLot(contracts);
    }

    private async groupContractsByLot(
        contracts: CreativeRequestContract[],
    ): Promise<Record<'lot1' | 'lot2', CreativeRequestContract[]>> {
        const groupedContracts: Record<'lot1' | 'lot2', CreativeRequestContract[]> = {
            lot1: [],
            lot2: [],
        };

        await Promise.all(
            contracts.map(async (item) => {
                const lotDictionary = await item.model.lot;

                const lotNumber = lodash.first(lotDictionary.value.match(/\d/g)) as '1' | '2';

                groupedContracts[`lot${lotNumber}`].push(item);
            }),
        );

        groupedContracts.lot1 = lodash.sortBy(groupedContracts.lot1, (item) => item.model.createdAt);
        groupedContracts.lot2 = lodash.sortBy(groupedContracts.lot2, (item) => item.model.createdAt);

        return groupedContracts;
    }

    @autobind
    private subscribeLineChanges(line: CreativeRequestItem) {
        UPDATE_SUBSCRIPTION_METHOD_NAMES.forEach((methodName) => {
            line.events[methodName](() => this.onLineUpdate(line.model.id));
        });
    }

    @autobind
    private subscribeLineCommentsUpdate(line: CreativeRequestItem) {
        line.events.onUpdated((payload: any) => {
            if (payload.property === 'comments') {
                this.onLineCommentsUpdate(line.model.id);
            }
        });
    }

    @autobind
    private async createLine(tableType: TableType) {
        const dictionaryId = await this.getTableDictionaryIdByType(tableType);

        await this.creativeRequest.model.addItem({
            itemId: uuid(),
            groupId: dictionaryId,
        });
    }

    @autobind
    private async archiveLine(lineId: LineId) {
        const line = this.getLine(lineId);

        await line.model.archive();
    }

    @autobind
    private async restoreLine(lineId: LineId) {
        const line = this.getLine(lineId);

        await line.model.return();
    }

    @autobind
    private getProject(): Project {
        return this.project;
    }

    @autobind
    private getCreativeRequest(): CreativeRequest {
        return this.creativeRequest;
    }

    @autobind
    private getLine(lineId: LineId): Line {
        return this.creativeRequestItems.find((item) => item.model.id === lineId);
    }

    @autobind
    private getLines(): Line[] {
        return this.creativeRequestItems || [];
    }

    @autobind
    private getCreativeRequestLot(): string {
        return this.creativeRequestLot;
    }

    @autobind
    private getDictionaries(): Partial<Record<DictionaryType, Dictionary[]>> {
        return this.dictionariesByType;
    }

    @autobind
    private getContracts(): Record<'lot1' | 'lot2', CreativeRequestContract[]> {
        return this.contracts;
    }

    private async getTableDictionaryIdByType(tableType: TableType): Promise<string> {
        const client = await MrmClient.getInstance();

        const dictionaries = await client.Dictionary.getByType(DictionaryType.CreativeRequestGroup as any);

        const dictionary = dictionaries.find((item) => item.value === tableType);

        return dictionary.id;
    }
}
