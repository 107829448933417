import * as React from 'react';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { MultiReferenceDictionaryApi } from '@api';

import {
    ColumnData,
    ColumnName,
    TableLine,
    ColumnsWidth,
    getBudgetItemsWithPlanOverrunedByReserve,
    getBudgetItemsWithPlanOverrunedByFact,
    calculateCorrectedPlanReserveBudget,
    getBudgetItemByLineId,
    getBudgetExecutionPageState,
    onLineClick,
} from '@store/budgetExecution';
import { isTransferBudgetItemsToPlanningMenuClosed } from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

import { LineColor } from '../TableBody/TableBody';
import { CellParams } from '../LayerManager';

import { Line } from './Line';
import { StoreState } from '@store';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    line: TableLine;
    columns: ColumnData[];
    visibleColumnsNames: ColumnName[];
    fixedColumnsNames: ColumnName[];
    columnsWidth: ColumnsWidth;
    width: number;
    lineColor: LineColor;
    isTransitionMenuOpened: boolean;
    currentYScroll: number;
    fixedColumnsLinesRef: (element: HTMLDivElement) => void;
    onMouseEnter: (lineId: string) => void;
    onMouseLeave: () => void;
    onCellClick: (lineId: string, columnName: ColumnName) => void;
    onCellMouseEnter: (lineId: string, columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onInputValueChange: (
        lineId: string,
        columnName: ColumnName,
        value: string,
        originalValue: string,
        generateSameValueChange: boolean,
    ) => void;
    onDropdownCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDropdownItemSelection: (lineId: string, columnName: ColumnName, selectedId: string, originalId: string) => void;
    onDatepickerCellClick: (
        columnName: ColumnName,
        budgetItemId: string,
        dropdownContent: JSX.Element,
        contentHeight: number,
    ) => void;
    onDatepickerValueChange: (lineId: string, columnName: ColumnName, value: Date, originalValue: Date) => void;
    onInfoMouseEnter: (lineId: string) => void;
    onInfoMouseLeave: () => void;
    onCellCopy: (
        lineId: string,
        columnName: ColumnName,
        cellParams: CellParams,
        event: React.ClipboardEvent<HTMLDivElement>,
    ) => void;
}

interface MapProps {
    lineIsHovered: boolean;
    isLineTransferToPlanningMenuOpened: boolean;
    allUsers: UserResponseParams[];
    lineHasPlanReserveCorrections: boolean;
    planIsOverrunned: boolean;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
}

interface DispatchProps {
    onLineClick(lineId: string): void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class LineContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const {
            allUsers,
            planIsOverrunned,
            lineHasPlanReserveCorrections,
            lineIsHovered,
            multiReferenceDictionaryApi,
        } = this.props;

        return (
            <Line
                {...this.props}
                isTransitionMenuOpened={
                    this.props.isTransitionMenuOpened || this.props.isLineTransferToPlanningMenuOpened
                }
                allUsers={allUsers}
                planIsOverrunned={planIsOverrunned}
                lineHasPlanReserveCorrections={lineHasPlanReserveCorrections}
                lineIsHovered={lineIsHovered}
                multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                onLineClick={this.onLineClick}
            />
        );
    }

    @autobind
    private onLineClick(event: React.MouseEvent<HTMLDivElement>): void {
        const { id: lineId } = this.props.line;

        event.preventDefault();
        event.stopPropagation();
        this.props.onLineClick(lineId);
    }
}

function mapStateToProps(state: StoreState, props: Props): MapProps {
    const { id: lineId } = props.line;
    const { pageData, hoveredLineId, multiReferenceDictionaryApi } = getBudgetExecutionPageState(state);
    const budgetItemsWithPlanOverrunedByReserve = getBudgetItemsWithPlanOverrunedByReserve(state);
    const budgetItemsWithPlanOverrunedByFact = getBudgetItemsWithPlanOverrunedByFact(state);

    const budgetItem = getBudgetItemByLineId(state, props.line.id);

    const correctedPlanReserveBudget = budgetItem ? calculateCorrectedPlanReserveBudget(pageData, budgetItem) : null;

    return {
        lineIsHovered: lineId === hoveredLineId,
        isLineTransferToPlanningMenuOpened: !isTransferBudgetItemsToPlanningMenuClosed(state),
        allUsers: pageData.allUsers,
        lineHasPlanReserveCorrections: correctedPlanReserveBudget
            ? correctedPlanReserveBudget.before !== correctedPlanReserveBudget.after
            : false,
        planIsOverrunned: lodash.includes(
            [...budgetItemsWithPlanOverrunedByReserve, ...budgetItemsWithPlanOverrunedByFact],
            lineId,
        ),
        multiReferenceDictionaryApi,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        onLineClick: (lineId: string) => dispatch(onLineClick(lineId)),
    };
}
