import * as React from 'react';
import * as lodash from 'lodash';
import classnames from 'classnames';

import * as style from './SwitchGroup.scss';

import { SwitchGroupFields } from './SwitchGroupFields';

import { SwitchType, BriefField } from 'sber-marketing-types/frontend';
import type { FieldValue, FieldProperties } from 'sber-marketing-types/frontend';
import type { FieldInput } from '@store/brief/types';

import {
    Checkbox_redesign as Checkbox,
    RadioButtonGroup_redesign as RadioButtonGroup,
    Icon,
    IconType,
    WithTooltip,
} from 'sber-marketing-ui';

import { HistoryWidget } from '../common/HistoryWidget';

const DEFAULT_VALIDATION_MESSAGE = 'Обязательное поле';
const CHECKBOX_ICON_SIZE = 20;

interface Props extends FieldProperties {
    id: string;
    uniqId?: number;
    parentUniqId?: number;
    activityId: number;
    briefId: string;
    blockId: string;
    index?: number;
    value?: FieldValue;
    isHasExtraFields?: boolean;
    displayError?: boolean;
    disabled?: boolean;
    fields: BriefField[];
    addHistoryWidget?: boolean;
    onValueChange: (value: FieldInput) => void;
}

export function SwitchGroup({
    activityId,
    briefId,
    blockId,
    index,
    name,
    tooltipMessage,
    switchType,
    switches = [],
    fields = [],
    value,
    validationMessage,
    displayError,
    isHasExtraFields,
    addHistoryWidget,
    id,
    uniqId,
    parentUniqId,
    onValueChange,
    disabled,
}: Props): JSX.Element {
    const hasTooltip = !!tooltipMessage;
    const noSwitches = !switches.length;
    const checkedIds =
        value && value.switches ? value.switches.filter((item) => !!item.value).map((item) => item.id) : [];

    return (
        <div
            className={classnames(style.root, noSwitches && style.empty)}
            {...{
                'qa-id': 'briefBlockField',
                'qa-index': index,
                'qa-field-type': 'SwitchGroup',
            }}
        >
            <div className={style.title}>{name || 'Название поля'}</div>

            {hasTooltip && (
                <WithTooltip
                    content={<div className={style.tooltipMessage}>{tooltipMessage}</div>}
                    className={style.iconTooltip}
                >
                    <Icon type={IconType.INFO_CIRCLE} svgSize="16" />
                </WithTooltip>
            )}

            <div className={style.switches}>
                {(switchType == SwitchType.Checkbox || switchType == undefined) &&
                    switches.map((item) => (
                        <div className={style.switchItem} key={item.id}>
                            <Checkbox
                                qaId="briefBlockSwitchGroupFieldItem"
                                qaLabel={item.name}
                                title={item.name}
                                tooltip={item.tooltipMessage}
                                isChecked={lodash.includes(checkedIds, item.id)}
                                iconSize={CHECKBOX_ICON_SIZE}
                                onSwitch={(value) => onValueChange({ id: item.id, value })}
                                disabled={disabled}
                            />

                            {isHasExtraFields && lodash.includes(checkedIds, item.id) && (
                                <SwitchGroupFields
                                    activityId={activityId}
                                    briefId={briefId}
                                    blockId={blockId}
                                    fields={fields}
                                    switchPropertyId={item.id}
                                />
                            )}
                        </div>
                    ))}

                {switchType == SwitchType.Radio && (
                    <RadioButtonGroup
                        items={switches.map((item) => ({
                            title: item.name,
                            value: item.id,
                            tooltip: item.tooltipMessage,
                            qaId: 'briefBlockSwitchGroupFieldItem',
                            qaLabel: item.name,
                        }))}
                        selectedValue={lodash.get(value, 'selected')}
                        onSelection={(value) => onValueChange(value as string)}
                        disabled={disabled}
                        renderAdditional={(item, index) =>
                            isHasExtraFields && lodash.get(value, 'selected') === switches[index].id ? (
                                <SwitchGroupFields
                                    activityId={activityId}
                                    briefId={briefId}
                                    blockId={blockId}
                                    fields={fields}
                                    switchPropertyId={switches[index].id}
                                />
                            ) : null
                        }
                    />
                )}
            </div>

            {displayError && (
                <div className={style.errorMessage}>{validationMessage || DEFAULT_VALIDATION_MESSAGE}</div>
            )}

            {addHistoryWidget && (
                <HistoryWidget
                    id={id}
                    uniqId={uniqId}
                    parentUniqId={parentUniqId}
                    type={switchType === SwitchType.Checkbox ? 'switchGroupCheckbox' : 'switchGroupRadio'}
                    switches={switches}
                />
            )}
        </div>
    );
}
