import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { ActivityApi } from '@api';
import type { BriefField, BriefBlock, BriefCost } from 'sber-marketing-types/frontend';
import type { FieldChangeParams, BlockDoubleParams } from '@store/brief/types';

import { FieldType } from 'sber-marketing-types/frontend';
import { Block } from './Block';
import { setFieldValue, setBlockDouble, removeBlockDouble } from '@store/brief/actions';

interface Props extends Partial<DispatchProps> {
    activityId: number;
    divisionId: string;
    departmentId: string;
    briefId: string;
    blockId: string;
    blockParentId?: string;
    block: BriefBlock;
    name: string;
    fields: BriefField[];
    blocks: BriefBlock[];
    briefCost?: BriefCost;
    isRequired: boolean;
    isOriginal: boolean;
    editRight: boolean;
}

interface State {
    isCopyLoading: boolean;
}

interface DispatchProps {
    setFieldValue?: (params: FieldChangeParams) => void;
    setBlockDouble?: (params: BlockDoubleParams) => void;
    removeBlockDouble?: (params: BlockDoubleParams) => void;
}

@(connect(null, mapDispatchToProps) as any)
export class BlockContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCopyLoading: false,
        };
    }

    public render() {
        const { isCopyLoading } = this.state;
        const {
            activityId,
            divisionId,
            departmentId,
            blockId,
            blockParentId,
            briefId,
            block,
            blocks,
            briefCost,
            name,
            isRequired,
            isOriginal,
            editRight,
        } = this.props;

        const blocksById = block.children || blocks.filter((item) => item.briefBlockId === blockId);

        return React.createElement(Block, {
            id: blockId,
            activityId,
            divisionId,
            departmentId,
            parentId: blockParentId,
            briefId,
            name,
            tooltipMessage: block.tooltipMessage,
            block,
            blocks,
            blocksById,
            briefCost,
            fields: this.getFieldsWithoutTypeToggle(),
            isRequired: lodash.isNil(isRequired) ? true : isRequired,
            isOriginal: lodash.isNil(isOriginal) ? true : isOriginal,
            isOpened: this.isOpened(),
            editRight,
            isCopyLoading,
            onClickToggle: this.onClickToggle,
            onBlockDoubleCreate: this.onBlockDoubleCreate,
            onBlockDoubleRemove: this.onBlockDoubleRemove,
        });
    }

    @autobind
    private async onBlockDoubleCreate(): Promise<void> {
        this.setState({ isCopyLoading: true });

        const { activityId, briefId, block } = this.props;

        const fields = block.fields.map((field) => ({
            ...field,
            value: {},
        }));

        const createBlockCopyResponse = await ActivityApi.createActivityBriefCopyBlock(activityId, { fields });

        this.props.setBlockDouble({
            briefId,
            tree: createBlockCopyResponse.tree,
            blocks: createBlockCopyResponse.blocks,
        });

        this.setState({ isCopyLoading: false });
    }

    @autobind
    private async onBlockDoubleRemove(): Promise<void> {
        this.setState({ isCopyLoading: true });

        const { activityId, briefId, block } = this.props;

        const removeBlockCopyResponse = await ActivityApi.removeActivityBriefCopyBlock(activityId, block);

        this.props.removeBlockDouble({
            briefId,
            tree: removeBlockCopyResponse.tree,
            blocks: removeBlockCopyResponse.blocks,
        });

        this.setState({ isCopyLoading: false });
    }

    @autobind
    private onClickToggle(): void {
        const { briefId } = this.props;
        this.props.setFieldValue({
            briefId,
            fieldId: this.toggleField.id,
            uniqId: this.toggleField.uniqId || 0,
            parentUniqId: this.toggleField.parentUniqId || 0,
            value: {
                togglePosition: this.toggleFieldPosition === 'right' ? 'left' : 'right',
            },
        });
    }

    private isOpened(): boolean {
        if (this.toggleField) {
            return this.toggleFieldPosition === 'right';
        }

        return false;
    }

    private get toggleFieldPosition() {
        return lodash.get(this.toggleField, 'value.togglePosition') || 'left';
    }

    private get toggleField() {
        const fieldsWithToggleType = this.getFieldsWithTypeToggle();
        return lodash.first(fieldsWithToggleType);
    }

    private getFieldsWithoutTypeToggle() {
        const { fields } = this.props;
        return fields.filter(({ type }) => type !== FieldType.TOGGLE);
    }

    private getFieldsWithTypeToggle(props?: Props) {
        const field = lodash.first(props ? props.fields : this.props.fields);
        const blocks = props ? props.blocks : this.props.blocks;
        const blockId = props ? props.blockId : this.props.blockId;
        const block = blocks.find((item) => item.id === blockId);

        return block.fields.filter(
            ({ type, briefBlockId, uniqId, parentUniqId }) =>
                briefBlockId === this.props.blockId &&
                type === FieldType.TOGGLE &&
                field.uniqId === uniqId &&
                field.parentUniqId === parentUniqId,
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setFieldValue,
            setBlockDouble,
            removeBlockDouble,
        },
        dispatch,
    );
}
