import * as React from 'react';
import * as style from './WithButtonLayout.scss';
import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    showButton: boolean;
    buttonAction: () => void;
    children: string | JSX.Element | JSX.Element[];
}

const WithButtonLayout = ({ showButton, buttonAction, children }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.content}>{children}</div>
            {showButton && (
                <div className={style.buttonWrapper}>
                    <div className={style.button} onClick={buttonAction}>
                        <div>В таблицу</div>
                        <Icon type={IconType.ARROW16_STRAIGHT_RIGHT} svgSize={14} />
                    </div>
                </div>
            )}
        </div>
    );
};
export default WithButtonLayout;
