import { CreativeRequestStatus } from '@api';

export enum GroupCards {
    APPLICATOIN = 'APLICATION',
    FILLING = 'FILLING',
    APPROVAL = 'APPROVAL',
    END = 'END',
}

export interface StatusesListType {
    name: CreativeRequestStatus;
    title: string;
    type: GroupCards;
    isDone: boolean;
    isCurrent: boolean;
    nextStatus: CreativeRequestStatus | null;
    nextButtonText: string;
    promtText: string;
}

interface RightGroupCardsType {
    cardTitle: string;
    cardType: GroupCards;
}

export const RightGroupCards: RightGroupCardsType[] = [
    { cardTitle: 'Заполнение', cardType: GroupCards.FILLING },
    { cardTitle: 'Согласование', cardType: GroupCards.APPROVAL },
    { cardTitle: 'Итог', cardType: GroupCards.END },
];

export const StatusesList: StatusesListType[] = [
    {
        name: CreativeRequestStatus.draft,
        nextStatus: CreativeRequestStatus.fillingByCustomer,
        title: 'Форма заявки',
        type: GroupCards.APPLICATOIN,
        isDone: false,
        isCurrent: false,
        nextButtonText: 'Продолжить',
        promtText: 'Заполните поля',
    },
    {
        name: CreativeRequestStatus.fillingByCustomer,
        nextStatus: CreativeRequestStatus.fillingByAgency,
        title: 'На стороне заказчика',
        type: GroupCards.FILLING,
        isDone: false,
        isCurrent: false,
        nextButtonText: 'Отправить в агентство',
        promtText: 'Создайте необходимое количество строк-креативов и отправьте исполнителю в агентство',
    },
    {
        name: CreativeRequestStatus.fillingByAgency,
        nextStatus: CreativeRequestStatus.checkingByCustomer,
        title: 'На стороне агентства',
        type: GroupCards.FILLING,
        isDone: false,
        isCurrent: false,
        nextButtonText: 'Отправить на проверку',
        promtText: 'Заполните данные в таблице, приложите смету и другие документы, отправьте на проверку заказчику',
    },
    {
        name: CreativeRequestStatus.checkingByCustomer,
        nextStatus: CreativeRequestStatus.approvement,
        title: 'Проверка',
        type: GroupCards.FILLING,
        isDone: false,
        isCurrent: false,
        nextButtonText: 'Отправить на согласование',
        promtText: 'Проверьте данные в таблице и отправьте на согласование или доработку',
    },
    {
        name: CreativeRequestStatus.approvement,
        nextStatus: CreativeRequestStatus.actClosing,
        title: 'Строки',
        type: GroupCards.APPROVAL,
        isDone: false,
        isCurrent: false,
        nextButtonText: 'Далее',
        promtText: 'Проверьте данные в таблице',
    },
    {
        name: CreativeRequestStatus.actClosing,
        nextStatus: CreativeRequestStatus.closed,
        title: 'Акты',
        type: GroupCards.APPROVAL,
        isDone: false,
        isCurrent: false,
        nextButtonText: 'Закрыть заявку',
        promtText: 'Проверьте данные в таблице',
    },
    {
        name: CreativeRequestStatus.closed,
        nextStatus: null,
        title: 'Заявка закрыта',
        type: GroupCards.END,
        isDone: false,
        isCurrent: false,
        nextButtonText: '',
        promtText: 'Заявка закрыта',
    },
];
