import * as React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary, values } from 'lodash';
import { FileResponse } from 'sber-marketing-types/frontend';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { StaticSkeleton } from 'sber-marketing-ui';

import { FileApiUploadParams } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { Utils } from '@common/Utils';
import { FileCardsList, FileCardsListTheme, FileCardsListPreloader } from '@common/FileCardsList';
import { NoResultsMarker } from '@common/SidebarWithTabs/common/NoResultsMarker';

import * as styles from './FilesTab.scss';
import { DownloadAllButton } from '@common/DownloadAllButton';

interface Props {
    taskContent: OneTaskResponseParams;
}

type DownloadParamsMap = Dictionary<FileApiUploadParams>;
type CanEditInR7Map = Dictionary<boolean>;

function useFiles(taskContent: OneTaskResponseParams) {
    const [downloadParamsMap, setDownloadParamsMap] = React.useState<DownloadParamsMap>({});
    const [canEditInR7Map, setCanEditInR7Map] = React.useState<CanEditInR7Map>({});
    const [files, setFiles] = React.useState<FileResponse[]>([]);

    const loginnedUserId = useSelector((state: StoreState) => getLoginUser(state).attributes.id);

    React.useEffect(() => {
        if (taskContent) {
            const downloadParams: DownloadParamsMap = {};
            const canEditInR7: CanEditInR7Map = {};
            const filesUpd: typeof files = [];

            const taskId = taskContent.id;

            taskContent.files.forEach((taskFile) => {
                filesUpd.push(taskFile);
                downloadParams[taskFile.id] = { taskId };
                canEditInR7[taskFile.id] = true;
            });

            values(taskContent.chanels).forEach((channel) => {
                channel.comments?.forEach((comment) => {
                    const commentFileDownloadParams = { taskId, commentId: comment.id };

                    comment.files?.forEach((file) => {
                        filesUpd.push(file);
                        downloadParams[file.id] = commentFileDownloadParams;
                        canEditInR7[file.id] = comment.authorId === loginnedUserId;
                    });
                });
            });

            setDownloadParamsMap(downloadParams);
            setCanEditInR7Map(canEditInR7);
            setFiles(filesUpd);
        }
    }, [taskContent]);

    function getFileDownloadParams(file: FileResponse): FileApiUploadParams {
        const res = downloadParamsMap[file.id];

        if (!res) {
            console.warn(`Missing file download params for file ${file.originName}.${file.type} (${file.id})`);
        }

        return res;
    }

    function canEditInR7(file: FileResponse): boolean {
        return canEditInR7Map[file.id];
    }

    return {
        files,
        downloadParamsMap,
        getFileDownloadParams,
        canEditInR7,
    };
}

export function FilesTab(props: Props): JSX.Element {
    const { files, downloadParamsMap, getFileDownloadParams, canEditInR7 } = useFiles(props.taskContent);
    const { taskContent } = props;

    const showDownloadAllButtonClick = files.length > 1;
    const showPreloader = !taskContent;
    const showNoResultsMarker = !files.length;

    if (showPreloader) {
        return <Preloader />;
    }
    if (showNoResultsMarker) {
        return <NoResultsMarker text="Нет файлов" />;
    }

    return (
        <div qa-id="taskSidebarFilesTab">
            <div className={styles.title}>
                {files.length} {Utils.getFilesCountDeclension(files.length)}
            </div>

            {showDownloadAllButtonClick && (
                <div className={styles.downloadAllButtonWrapper}>
                    <DownloadAllButton
                        qa-id="taskSidebarFilesTabDownloadAllButton"
                        files={files}
                        params={downloadParamsMap}
                        fileName={`Файлы задачи "${taskContent.title}"`}
                    />
                </div>
            )}

            <FileCardsList
                theme={FileCardsListTheme.LargeWithBorders}
                canEditInR7={canEditInR7}
                files={files.map((file) => {
                    const downloadParams = downloadParamsMap[file.id];
                    return {
                        ...file,
                        containerName: downloadParams.commentId || downloadParams.taskId,
                        parent: downloadParams.commentId ? 'comment' : 'task',
                    };
                })}
                getFileDownloadParams={getFileDownloadParams}
            />
        </div>
    );
}

function Preloader(): JSX.Element {
    return (
        <React.Fragment>
            <StaticSkeleton loadingClassName={styles.titlePreloader} />

            <div className={styles.downloadAllButtonWrapper}>
                <StaticSkeleton loadingClassName={styles.downloadAllButtonPreloader} />
            </div>

            <FileCardsListPreloader theme={FileCardsListTheme.LargeWithBorders} />
        </React.Fragment>
    );
}
