// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-FileSelectionState-_FileSelectionState__root{margin-top:16px}.src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-FileSelectionState-_FileSelectionState__title{margin-bottom:16px;font-family:'Open Sans';font-size:14px;font-weight:400;line-height:19px;color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/FMPTableImportMenu/FileSelectionState/FileSelectionState.scss"],"names":[],"mappings":"AAAA,sHACI,eAAgB,CACnB,uHAGG,kBAAmB,CAEnB,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aAAc","sourcesContent":[".root {\n    margin-top: 16px;\n}\n\n.title {\n    margin-bottom: 16px;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 19px;\n    color: #7E8681;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-FileSelectionState-_FileSelectionState__root",
	"title": "src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-FileSelectionState-_FileSelectionState__title"
};
export default ___CSS_LOADER_EXPORT___;
