// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-ActionsMenu-_styles__root{justify-self:left;position:relative}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/ActionsMenu/styles.scss"],"names":[],"mappings":"AAAA,sEACI,iBAAkB,CAClB,iBAAkB","sourcesContent":[".root {\n    justify-self: left;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-ActionsMenu-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
