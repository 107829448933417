import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    WithTooltip,
    TooltipAnchor,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { Utils } from '@common/Utils';

import { StoreState } from '@store';
import { ComponentMode, getTaskEditorState, saveTask } from '@store/taskEditor2';

import * as styles from './SaveButton.scss';

const BUTTON_QA_ID = 'taskEditorSaveButton';

function useInteractivity(props: Props) {
    const { componentMode, hasBudgetApproval } = props;
    const dispatch = useDispatch();

    const taskTitleIsEntered = useSelector((state: StoreState) => !!getTaskEditorState(state).values.title);
    const taskDeadlineIsEntered = useSelector((state: StoreState) => !!getTaskEditorState(state).values.deadline);
    const taskExecutorWorkTypeAreEntered = useSelector((state: StoreState) => {
        const { workType, executor } = getTaskEditorState(state).values;

        return !!(workType && executor);
    });
    const taskBudgetApprovalDivisionIsEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.clientDivisionId,
    );
    const taskBudgetApprovalClientIsEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.clientName,
    );
    const taskBudgetApprovalIdIsEntered = useSelector((state: StoreState) => {
        const { budgetApproval } = getTaskEditorState(state).values;

        return !!budgetApproval?.budgetItemIds?.length;
    });
    const taskBudgetApprovalSegmentIsEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.segmentId,
    );
    const taskBudgetApprovalProductIsEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.productId,
    );
    const taskBudgetApprovalPeriodIsEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.period,
    );
    const hasLot = useSelector((state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.hasLot);
    const taskBudgetApprovalLotIsEntered = useSelector(
        (state: StoreState) =>
            getTaskEditorState(state).values.budgetApproval?.hasLot === true ||
            getTaskEditorState(state).values.budgetApproval?.hasLot === false,
    );
    const taskBudgetApprovalMediaRequestEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.mediaRequest,
    );
    const hasTitle = useSelector((state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.hasTitle);
    const taskBudgetApprovalTitleIsEntered = useSelector(
        (state: StoreState) =>
            getTaskEditorState(state).values.budgetApproval?.hasTitle === true ||
            getTaskEditorState(state).values.budgetApproval?.hasTitle === false,
    );
    const taskBudgetApprovalNamingEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.naming,
    );
    const taskBudgetApprovalProjectEntered = useSelector(
        (state: StoreState) => !!getTaskEditorState(state).values.budgetApproval?.project,
    );

    const taskRequestInProgress = useSelector(
        (state: StoreState) => getTaskEditorState(state).common.taskRequestInProgress,
    );

    let disableSaveButton = taskTitleIsEntered;
    let nonEmptyRequesredFieldsCount = 0;
    let requiredFieldsCount = 0;

    if (componentMode === ComponentMode.enterRequiredFieldsForExistingTask) {
        disableSaveButton =
            taskTitleIsEntered &&
            taskDeadlineIsEntered &&
            taskExecutorWorkTypeAreEntered &&
            (hasBudgetApproval
                ? taskBudgetApprovalDivisionIsEntered &&
                  taskBudgetApprovalClientIsEntered &&
                  taskBudgetApprovalIdIsEntered &&
                  taskBudgetApprovalSegmentIsEntered &&
                  taskBudgetApprovalProductIsEntered &&
                  taskBudgetApprovalPeriodIsEntered &&
                  taskBudgetApprovalLotIsEntered &&
                  (hasLot ? taskBudgetApprovalMediaRequestEntered && taskBudgetApprovalTitleIsEntered : true) &&
                  (hasTitle ? taskBudgetApprovalNamingEntered && taskBudgetApprovalProjectEntered : true)
                : true);

        [
            taskTitleIsEntered,
            taskDeadlineIsEntered,
            taskExecutorWorkTypeAreEntered,
            ...(hasBudgetApproval
                ? [
                      taskBudgetApprovalDivisionIsEntered,
                      taskBudgetApprovalClientIsEntered,
                      taskBudgetApprovalIdIsEntered,
                      taskBudgetApprovalSegmentIsEntered,
                      taskBudgetApprovalProductIsEntered,
                      taskBudgetApprovalPeriodIsEntered,
                      taskBudgetApprovalLotIsEntered,
                      ...(hasLot ? [taskBudgetApprovalMediaRequestEntered, taskBudgetApprovalTitleIsEntered] : []),
                      ...(hasTitle ? [taskBudgetApprovalNamingEntered, taskBudgetApprovalProjectEntered] : []),
                  ]
                : []),
        ].forEach((fieldIsNonEmpty) => (nonEmptyRequesredFieldsCount += fieldIsNonEmpty ? 1 : 0));

        requiredFieldsCount = 3;
        if (hasBudgetApproval) {
            requiredFieldsCount += 7;
        }
        if (hasLot) {
            requiredFieldsCount += 2;
        }
        if (hasTitle) {
            requiredFieldsCount += 2;
        }
    }

    function initTaskSave() {
        dispatch(saveTask(null));
    }

    return {
        disableSaveButton,
        taskRequestInProgress,
        nonEmptyRequesredFieldsCount,
        requiredFieldsCount,
        initTaskSave,
    };
}

interface Props {
    componentMode: ComponentMode;
    hasBudgetApproval?: boolean;
}

export function SaveButton(props: Props): JSX.Element {
    const { componentMode } = props;
    const status = useSelector((state: StoreState) => getTaskEditorState(state).data?.rawTask?.status);
    const workType = useSelector((state: StoreState) => getTaskEditorState(state).values.workType);
    const workTypeId = useSelector((state: StoreState) => getTaskEditorState(state).data?.rawTask?.workTypeId);
    const workTypes = useSelector(
        (state: StoreState) => getTaskEditorState(state).data.userWorkType.workTypesWithUsers,
    );
    const selectedWorkType = workTypes.find((wType) => wType.id === workType);
    const initialWorkType = workTypes.find((wType) => wType.id === workTypeId);
    const hasBudgetApproval = Utils.addBudgetControlsForSelectedWorkType(selectedWorkType?.title);
    const isBudgetEditor = Utils.addBudgetControlsForSelectedWorkType(initialWorkType?.title);
    const budgetApprovalRequired = hasBudgetApproval && !isBudgetEditor && status === TaskStatus.InProgress;

    if (componentMode === ComponentMode.enterRequiredFieldsForExistingTask || budgetApprovalRequired) {
        return (
            <EnterRequiredFieldsForExistingTaskMode
                hasBudgetApproval={hasBudgetApproval}
                {...props}
                componentMode={
                    budgetApprovalRequired ? ComponentMode.enterRequiredFieldsForExistingTask : componentMode
                }
            />
        );
    }

    return <RegularMode hasBudgetApproval={hasBudgetApproval} {...props} />;
}

function EnterRequiredFieldsForExistingTaskMode(props: Props): JSX.Element {
    const {
        disableSaveButton,
        taskRequestInProgress,
        nonEmptyRequesredFieldsCount,
        requiredFieldsCount,
        initTaskSave,
    } = useInteractivity(props);

    const showRequiredFieldsMarker = nonEmptyRequesredFieldsCount !== requiredFieldsCount;

    return (
        <div className={styles.root}>
            {showRequiredFieldsMarker && (
                <div
                    className={styles.requiredFieldsMarker}
                    {...{
                        'qa-id': 'taskEditorSaveButtonRequiredFields',
                    }}
                >
                    Заполните обязательные поля&nbsp;
                    <span className={styles.requiredFieldsMarkerGray}>
                        ({nonEmptyRequesredFieldsCount}/{requiredFieldsCount})
                    </span>
                </div>
            )}

            <div className={styles.buttonWrapper}>
                <WithTooltip
                    content="Заполните обязательные поля"
                    hidden={disableSaveButton}
                    anchor={TooltipAnchor.BOTTOM}
                >
                    <div className={styles.button}>
                        <Button
                            qaId={BUTTON_QA_ID}
                            disabled={!disableSaveButton || taskRequestInProgress}
                            theme={ButtonTheme.GhostRoundedBlack}
                            onClick={initTaskSave}
                        >
                            {taskRequestInProgress ? 'В работу...' : 'В работу'}
                        </Button>
                    </div>
                </WithTooltip>
            </div>
        </div>
    );
}

function RegularMode(props: Props) {
    const { disableSaveButton, taskRequestInProgress, initTaskSave } = useInteractivity(props);

    return (
        <div className={styles.root}>
            <div className={styles.buttonWrapper}>
                <WithTooltip content="Введите название" hidden={disableSaveButton} anchor={TooltipAnchor.BOTTOM}>
                    <div className={styles.button}>
                        <Button
                            qaId={BUTTON_QA_ID}
                            disabled={!disableSaveButton || taskRequestInProgress}
                            theme={ButtonTheme.GhostRoundedBlack}
                            onClick={initTaskSave}
                        >
                            {taskRequestInProgress ? 'Сохранение...' : 'Сохранить'}
                        </Button>
                    </div>
                </WithTooltip>
            </div>
        </div>
    );
}
