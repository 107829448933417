import * as React from 'react';
import * as moment from 'moment';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import type { ActivityParams, TaskCardParams } from 'sber-marketing-types/frontend';

import {
    OpacityTransition,
    Icon,
    IconType,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

import { TasksListType } from '@store/tasksList';
import { allowAllPermissions, defaultPermissions } from '@store/taskEditor2';

import { ActivitySidebarRef, ActivitySidebar } from '@common/ActivitySidebar';
import { TaskSidebar, TaskSidebarRef } from '@common/TaskSidebar';
import { TasksList } from '@common/TasksList';
import { TaskEditor } from '@common/TaskEditor';
import { ActivityPageSortingMenu } from '@common/DashboardSortingMenu';
import { CardsViewSwitch } from '@common/CardsViewSwitch';
import { QuickAccessIcons } from '@common/QuickAccessIcons';
import { isRequestInProgress } from '@store/common';
import { LoadingStatus } from '@store/commonTypes';

import { ActivityStages } from './ActivityStages';
import { TitleWithStagesFilter } from './TitleWithStagesFilter';
import { AutopilotsList } from './AutopilotsList';
import { AutopilotWidget } from './AutopilotWidget';
import { AutopilotLegacyWidget } from './AutopilotLegacyWidget';

import * as style from './ActivityTasksPage.scss';

interface Props {
    delayLoading: boolean;
    activityId: number;
    isLoaded: boolean;
    mode: ActivityTasksPageMode;
    activity?: ActivityParams;
    taskSidebarId: string;
    activitySharingInProgress: boolean;
    userConfigLoadingStatus: LoadingStatus;
    activitySidebarRef(ref: ActivitySidebarRef): void;
    taskSidebarRef(ref: TaskSidebarRef): void;
    onTaskCreated(): Promise<void> | void;
    onCreateTaskClose(): Promise<void> | void;
    onCreateTaskButtonClick?(): void;
    onBeforeTaskDeleted(taskId: string): void;
    reloadTaskFilters(): void;
    createTaskByNameAndStage(taskName: string, stageId: string): Promise<void>;
    onTaskUpdate?(id: string, params: Partial<TaskCardParams>): void;
    reloadStagesData?(): void;
    fillActivityTasks(): void;
    openTaskSidebar(taskId: string): void;
    reloadTaskSidebarContent(taskId: string): void;
    closeTaskSidebar(): void;
    shareActivity: (userIds: number[]) => void;
}

export enum ActivityTasksPageMode {
    Default,
    NewTask,
}

export function ActivityTasksPage({
    delayLoading,
    activityId,
    activity,
    isLoaded,
    mode,
    taskSidebarId,
    activitySharingInProgress,
    userConfigLoadingStatus,
    activitySidebarRef,
    taskSidebarRef,
    onTaskCreated,
    onCreateTaskClose,
    onCreateTaskButtonClick,
    reloadTaskFilters,
    onBeforeTaskDeleted,
    createTaskByNameAndStage,
    onTaskUpdate,
    reloadStagesData,
    fillActivityTasks,
    openTaskSidebar,
    reloadTaskSidebarContent,
    closeTaskSidebar,
    shareActivity,
}: Props): JSX.Element {
    const today = moment();
    const isLoading = useSelector(isRequestInProgress);

    const activityExpired = activity && !!moment(activity.realizationEnd).add(1, 'day').isBefore(today);

    const isActivityLoaded = !isNil(activity) && !isNil(activity.id);
    const userConfigLoaded = userConfigLoadingStatus === LoadingStatus.LOADED;

    const showTaskEditor = isActivityLoaded && mode === ActivityTasksPageMode.NewTask;
    const showTaskList = isActivityLoaded && userConfigLoaded;

    return (
        <React.Fragment>
            <OpacityTransition start={isLoaded}>
                <div className={style.root}>
                    <div className={style.content}>
                        <AutopilotsList className={style.autopilotWidget} activityId={activityId} />
                        <AutopilotWidget activityId={activityId} />
                        <AutopilotLegacyWidget activityId={activityId} />

                        {isLoaded && (
                            <div className={style.activityStages}>
                                <ActivityStages
                                    delayLoading={delayLoading}
                                    createTaskByNameAndStage={createTaskByNameAndStage}
                                />
                            </div>
                        )}

                        <TitleWithStagesFilter />

                        {delayLoading && (
                            <div className={style.delayedLoadingMarker}>
                                <Icon
                                    className={style.delayedLoadingMarkerIcon}
                                    type={IconType.DELAY_LOADING_MARKER}
                                    svgSize={24}
                                />
                                Создаются типовые задачи
                            </div>
                        )}

                        <div className={style.tasksOptions}>
                            {userConfigLoaded && (
                                <div className={style.sortingMenu}>
                                    <ActivityPageSortingMenu activityId={activityId} />
                                </div>
                            )}

                            <div className={style.cardsViewSwitch}>
                                <CardsViewSwitch />
                            </div>
                        </div>

                        {showTaskList && (
                            <TasksList
                                delayLoading={delayLoading}
                                type={TasksListType.ACTIVITY_TASKS}
                                activityId={activity.id}
                                briefStatus={activity.brief && activity.brief.status}
                                activityExpired={activityExpired}
                                taskIdWithSidebar={taskSidebarId}
                                onCreateTaskButtonClick={onCreateTaskButtonClick}
                                onBeforeTaskDeleted={onBeforeTaskDeleted}
                                reloadTaskFilters={reloadTaskFilters}
                                onTaskUpdate={onTaskUpdate}
                                reloadStagesData={reloadStagesData}
                                fillActivityTasks={fillActivityTasks}
                                openTaskSidebar={openTaskSidebar}
                                reloadTaskSidebarContent={reloadTaskSidebarContent}
                            />
                        )}
                    </div>

                    <div className={style.rightSidebar} />

                    {showTaskEditor && (
                        <TaskEditor
                            permissions={
                                mode === ActivityTasksPageMode.NewTask ? allowAllPermissions : defaultPermissions
                            }
                            activityId={activityId}
                            taskId={null}
                            closePopup={onCreateTaskClose}
                            onTaskCreated={onTaskCreated}
                        />
                    )}
                </div>
            </OpacityTransition>

            {!isLoading && (
                <ActivitySidebar
                    sidebarRef={activitySidebarRef}
                    zIndex={10}
                    addDAMArchiveWidget
                    activityId={activityId}
                    activitySharingInProgress={activitySharingInProgress}
                    width={410}
                    top={98}
                    shareActivity={shareActivity}
                    openTaskSidebar={openTaskSidebar}
                />
            )}

            {taskSidebarId && (
                <TaskSidebar
                    sidebarRef={taskSidebarRef}
                    zIndex={11}
                    taskId={taskSidebarId}
                    width={410}
                    top={98}
                    goBackToActivitySidebar={closeTaskSidebar}
                />
            )}
        </React.Fragment>
    );
}

interface HeaderContentProps {
    onCreateTaskButtonClick(): void;
}

export function HeaderContent({ onCreateTaskButtonClick }: HeaderContentProps): JSX.Element {
    return (
        <div className={style.buttonBlockWrapper}>
            <div className={style.quickAccessIcons}>
                <QuickAccessIcons />
            </div>

            <div className={style.buttonWrapper}>
                <Button qaId="activityNewTaskButton" theme={ButtonTheme.Main} onClick={onCreateTaskButtonClick}>
                    Новая задача
                </Button>
            </div>
        </div>
    );
}
