import * as lodash from 'lodash';

import type { Funds } from '@mrm/budget';
import { ColumnName, LineType, Line, LineId } from './types';
import { Project, CreativeRequest, CreativeRequestItem } from '@api';
import { CAPEX_ITEM_VALUE } from '../../consts';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum CellType {
    Text = 'text',
    AddSourse = 'addSourse',
    FundsInput = 'funds_input',
}

export interface AccessorParams {
    lineId: string;
    line: Line;
    allLines: Line[];
    capex: boolean;
    project: Project;
    creativeRequest: CreativeRequest;
    creativeRequestItems: CreativeRequestItem[];
}

type Accessor<T> = (params: AccessorParams) => Promise<T>;
type AccessorWithValue<T> = (params: AccessorParams, value: any) => Promise<T>;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type ReadOnlyAccessor = Accessor<boolean>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;
export type SuggestItemsAccessor = Accessor<string[]>;
export type ValidationAccessor = AccessorWithValue<boolean>;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType> | CellType;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, ReadOnlyAccessor | boolean>> | ReadOnlyAccessor | boolean;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>> | CustomStyleAccessor;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    validateValue?: Partial<Record<LineType, ValidationAccessor>> | ValidationAccessor;
    getTitle?: Partial<Record<LineType, TitleAccessor>> | TitleAccessor;
    getValue: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
    setValue?: Partial<Record<LineType, ValueSetter>> | ValueSetter;
    getItems?: Partial<Record<LineType, ItemsAccessor>> | ItemsAccessor;
}

export interface ColumnsConfigParams {
    capex: boolean;
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.SourceId]: {
        title: 'ID источника',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.PlaceholderLine]: CellType.AddSourse,
            [LineType.TotalLine]: CellType.Text,
            [LineType.BudgetItemLine]: CellType.Text,
        },
        defaultWidth: 108,
        disableWidthChange: true,
        readOnly: true,
        customStyle: {
            [LineType.TotalLine]: async (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.BudgetItemLine]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.PlaceholderLine]: async (params: AccessorParams) => 'Привязать\nID источника',
            [LineType.TotalLine]: async (params: AccessorParams) =>
                params.allLines.map((item) => item.serialNumber).join(', '),
            [LineType.BudgetItemLine]: async (params: AccessorParams) => params.line.serialNumber,
        },
    },
    [ColumnName.SourceFunds]: {
        title: 'Средства в источниках',
        headerType: ColumnHeaderType.Text,
        type: CellType.FundsInput,
        defaultWidth: 119,
        disableWidthChange: true,
        readOnly: true,
        getValue: {
            [LineType.PlaceholderLine]: async (params: AccessorParams) => null,
            [LineType.TotalLine]: async (params: AccessorParams) => {
                const planSum = lodash.sumBy(params.allLines, (item) => getFundsSum(item.plannedFunds));
                const reserveSum = lodash.sumBy(params.allLines, (item) => getFundsSum(item.reservedFunds));

                return formatMoney(planSum - reserveSum);
            },
            [LineType.BudgetItemLine]: async (params: AccessorParams) =>
                formatMoney(getFundsSum(params.line.plannedFunds) - getFundsSum(params.line.reservedFunds)),
        },
    },
    [ColumnName.FactSumWithoutVat]: {
        title: 'Итого по заявке без НДС',
        headerType: ColumnHeaderType.Text,
        type: CellType.FundsInput,
        defaultWidth: 119,
        disableWidthChange: true,
        readOnly: true,
        customStyle: {
            [LineType.PlaceholderLine]: async (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.TotalLine]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.PlaceholderLine]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const filteredBudgetItems = params.creativeRequestItems.filter((item) =>
                    [...item.model.donors, ...item.model.acceptors].some(
                        (item) =>
                            !!itemValueByBudgetItemId[item.id] && itemValueByBudgetItemId[item.id] !== CAPEX_ITEM_VALUE,
                    ),
                );

                const factSum = lodash.sumBy(filteredBudgetItems, (item) => item.model.actualCostWithoutVat);

                return formatMoney(factSum);
            },
            [LineType.TotalLine]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const filteredCreativeRequestItems = params.creativeRequestItems.filter((item) =>
                    [...item.model.donors, ...item.model.acceptors].some(
                        (item) =>
                            !!itemValueByBudgetItemId[item.id] && itemValueByBudgetItemId[item.id] !== CAPEX_ITEM_VALUE,
                    ),
                );

                const factSum = lodash.sumBy(filteredCreativeRequestItems, (item) => item.model.actualCostWithoutVat);

                return formatMoney(factSum);
            },
            [LineType.BudgetItemLine]: async (params: AccessorParams) => undefined,
        },
    },
    [ColumnName.FactSumWithVat]: {
        title: 'Итого по заявке с НДС',
        headerType: ColumnHeaderType.Text,
        type: CellType.FundsInput,
        defaultWidth: 119,
        disableWidthChange: true,
        readOnly: true,
        customStyle: {
            [LineType.PlaceholderLine]: async (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.TotalLine]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.PlaceholderLine]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const filteredBudgetItems = params.creativeRequestItems.filter((item) =>
                    [...item.model.donors, ...item.model.acceptors].some(
                        (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                    ),
                );

                const factSum = lodash.sumBy(filteredBudgetItems, (item) => item.model.actualCostWithVat);

                return formatMoney(factSum);
            },
            [LineType.TotalLine]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const filteredBudgetItems = params.creativeRequestItems.filter((item) =>
                    [...item.model.donors, ...item.model.acceptors].some(
                        (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                    ),
                );

                const factSum = lodash.sumBy(filteredBudgetItems, (item) => item.model.actualCostWithVat);

                return formatMoney(factSum);
            },
            [LineType.BudgetItemLine]: async (params: AccessorParams) => undefined,
        },
    },
    [ColumnName.SourceBalance]: {
        title: 'Остаток в источниках',
        headerType: ColumnHeaderType.Text,
        type: CellType.FundsInput,
        defaultWidth: 119,
        disableWidthChange: true,
        readOnly: true,
        getValue: {
            [LineType.PlaceholderLine]: async (params: AccessorParams) => null,
            [LineType.TotalLine]: async (params: AccessorParams) => {
                const planSum = lodash.sumBy(params.allLines, (item) => getFundsSum(item.plannedFunds));
                const reserveSum = lodash.sumBy(params.allLines, (item) => getFundsSum(item.reservedFunds));

                const creativeCostSum = lodash.sumBy(params.creativeRequestItems, (item) =>
                    params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat,
                );

                return formatMoney(planSum - reserveSum - creativeCostSum);
            },
            [LineType.BudgetItemLine]: async (params: AccessorParams) => undefined,
        },
    },
});

export const MakeTableColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [
    ColumnName.SourceId,
    ColumnName.SourceFunds,
    columnsConfigParams.capex ? ColumnName.FactSumWithVat : ColumnName.FactSumWithoutVat,
    ColumnName.SourceBalance,
];

export const MakeLeftFixedColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (columnsConfigParams) => [];

export const MakeRightFixedColumns: (columnsConfigParams: ColumnsConfigParams) => string[] = (
    columnsConfigParams,
) => [];

function formatMoney(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    return parsedValue / 100;
}

function formatCurrencyValue(value: React.ReactText): string {
    if (!value) {
        return null;
    }

    let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

    let sign = '';

    if (lodash.first(decimalPart) === '-') {
        decimalPart = decimalPart.substring(1);
        sign = '-';
    }

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

    return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''} ₽`;
}

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

function getFundsSum(funds: Funds): number {
    return lodash.sum(lodash.values(funds));
}

export type { ColumnName };
