import * as moment from 'moment/moment';

import { DatesFormatter } from '@common/Utils';

import { useGetUserVacationQuery, UserVacation } from '@api';

export interface UseUserVacation {
    description: string;
    isLoading: boolean;
    isVacation: boolean;
    vacation?: UserVacation;
}

export function useUserVacation(userId: number, skip?: boolean): UseUserVacation {
    const { isLoading: isVacationLoading, data: vacation } = useGetUserVacationQuery({ id: userId }, { skip });
    const isLoading = isVacationLoading;

    return {
        description:
            vacation &&
            `В отпуске с ${DatesFormatter.ddMonthyyyy(vacation.start)} по ${DatesFormatter.ddMonthyyyy(
                vacation.end,
            )}. ${vacation.comment}`,
        isLoading,
        vacation,
        isVacation: Boolean(vacation) && moment().isBetween(vacation.start, vacation.end),
    };
}
