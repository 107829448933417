import * as React from 'react';

import { TableWidgetTemplate } from './TableWidgetTemplate';
import { CreativeRequestStatus } from '@api';

interface Props {
    creativeRequestId: string;
    currentStage: CreativeRequestStatus;
}

export class TableWidgetBehaviour extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { creativeRequestId, currentStage } = this.props;

        return React.createElement(TableWidgetTemplate, {
            creativeRequestId,
            displayStatusColumns: [
                CreativeRequestStatus.approvement,
                CreativeRequestStatus.actClosing,
                CreativeRequestStatus.closed,
            ].includes(currentStage),
        });
    }
}
