import type { BudgetItem } from '@mrm/budget';
import type {
    CreativeRequest,
    CreativeRequestItem,
    CreativeRequestContract,
    CreativeRequestCorrectionEntry as CreativeRequestCorrection,
    Project,
} from '@api';

import { useUrl } from './useUrl';
import { useCreativeRequest } from './useCreativeRequest';
import { useCreativeRequestItems } from './useCreativeRequestItems';
import { useProject } from './useProject';
import { useProjectBudgetItems } from './useProjectBudgetItems';
import { useContracts } from './useContracts';
import { useCreativeRequestBudgetItems } from './useCreativeRequestBudgetItems';
import { useCreativeRequestCorrections } from './useCreativeRequestCorrections';
import { useBudgetItems } from './useBudgetItems';

export interface ClientData {
    loading: boolean;
    projectId: number;
    creativeRequestId: string;
    creativeRequest: CreativeRequest;
    creativeRequestItems: CreativeRequestItem[];
    creativeRequestBudgetItems: BudgetItem[];
    groupedCreativeRequestBudgetItems: Record<'opex' | 'capex', BudgetItem[]>;
    refetchCreativeRequestBudgetItems: () => void;
    project: Project;
    projectBudgetItems: BudgetItem[];
    groupedProjectBudgetItems: Record<'opex' | 'capex', BudgetItem[]>;
    refetchProjectBudgetItems: () => void;
    corrections: CreativeRequestCorrection[];
    groupedCorrections: Record<'plan' | 'reserve', CreativeRequestCorrection[]>;
    refetchCorrections: () => void;
    contracts: CreativeRequestContract[];
    grouppedContracts: Record<'lot1' | 'lot2', CreativeRequestContract[]>;
}

export function useClientData(): ClientData {
    const { projectId, creativeRequestId } = useUrl();
    const { creativeRequest, loading: creativeRequestLoading } = useCreativeRequest(creativeRequestId);
    const { creativeRequestItems, loading: creativeRequestItemsLoading } = useCreativeRequestItems(creativeRequest);
    const { budgetItems: creativeRequestLinkedBudgetItems, loading: creativeRequestBudgetItemsLoading } =
        useCreativeRequestBudgetItems(creativeRequest);
    const { project, loading: projectLoading } = useProject(projectId);
    const { budgetItems: projectLinkedBudgetItems, loading: projectBudgetItemsLoading } =
        useProjectBudgetItems(project);
    const {
        corrections,
        groupedCorrections,
        loading: loadingCorrections,
        refetch: refetchCorrections,
    } = useCreativeRequestCorrections(creativeRequest);
    const { contracts, grouppedContracts, loading: contractsLoading } = useContracts();

    const {
        budgetItems: creativeRequestBudgetItems,
        groupedBudgetItems: groupedCreativeRequestBudgetItems,
        loading: loadingGroupedCreativeRequestBudgetItems,
        refetch: refetchCreativeRequestBudgetItems,
    } = useBudgetItems(creativeRequestLinkedBudgetItems);
    const {
        budgetItems: projectBudgetItems,
        groupedBudgetItems: groupedProjectBudgetItems,
        loading: loadingGroupedProjectBudgetItems,
        refetch: refetchProjectBudgetItems,
    } = useBudgetItems(projectLinkedBudgetItems);

    const loading =
        creativeRequestLoading ||
        creativeRequestItemsLoading ||
        creativeRequestBudgetItemsLoading ||
        projectLoading ||
        projectBudgetItemsLoading ||
        loadingCorrections ||
        contractsLoading ||
        loadingGroupedCreativeRequestBudgetItems ||
        loadingGroupedProjectBudgetItems;

    return {
        loading,
        projectId,
        creativeRequestId,
        creativeRequest,
        creativeRequestItems,
        creativeRequestBudgetItems,
        groupedCreativeRequestBudgetItems,
        refetchCreativeRequestBudgetItems,
        project,
        projectBudgetItems,
        groupedProjectBudgetItems,
        refetchProjectBudgetItems,
        corrections,
        groupedCorrections,
        refetchCorrections,
        contracts,
        grouppedContracts,
    };
}
