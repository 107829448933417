// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-common-FileAssetList-_styles__root{display:grid;grid-auto-rows:max-content;grid-auto-flow:row;grid-row-gap:10px}.src-client-modules-activity-pages-autopilot-common-FileAssetList-_styles__title{font-family:\"Open Sans\";color:#000;font-size:12px;font-weight:600}.src-client-modules-activity-pages-autopilot-common-FileAssetList-_styles__list{display:grid;grid-template-columns:repeat(auto-fill, minmax(300px, 1fr));grid-column-gap:15px;grid-row-gap:15px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/common/FileAssetList/styles.scss"],"names":[],"mappings":"AAAA,gFACI,YAAa,CACb,0BAA2B,CAC3B,kBAAmB,CACnB,iBAAkB,CACrB,iFAGG,uBAAwB,CACxB,UAAW,CACX,cAAe,CACf,eAAgB,CACnB,gFAGG,YAAa,CACb,2DAA4D,CAC5D,oBAAqB,CACrB,iBAAkB","sourcesContent":[".root {\n    display: grid;\n    grid-auto-rows: max-content;\n    grid-auto-flow: row;\n    grid-row-gap: 10px;\n}\n\n.title {\n    font-family: \"Open Sans\";\n    color: #000;\n    font-size: 12px;\n    font-weight: 600;\n}\n\n.list {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n    grid-column-gap: 15px;\n    grid-row-gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-common-FileAssetList-_styles__root",
	"title": "src-client-modules-activity-pages-autopilot-common-FileAssetList-_styles__title",
	"list": "src-client-modules-activity-pages-autopilot-common-FileAssetList-_styles__list"
};
export default ___CSS_LOADER_EXPORT___;
