// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-common-Accordion-_styles__root{border-radius:6px;border:1.5px solid #E6EDF1}.src-client-modules-activity-pages-autopilot-common-Accordion-_styles__folded:hover{background:#F9FCFF}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/common/Accordion/styles.scss"],"names":[],"mappings":"AAAA,4EACI,iBAAkB,CAClB,0BAA2B,CAC9B,oFAIO,kBAAmB","sourcesContent":[".root {\n    border-radius: 6px;\n    border: 1.5px solid #E6EDF1;\n}\n\n.folded {\n    &:hover {\n        background: #F9FCFF;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-common-Accordion-_styles__root",
	"folded": "src-client-modules-activity-pages-autopilot-common-Accordion-_styles__folded"
};
export default ___CSS_LOADER_EXPORT___;
