import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconProps, IconType } from 'sber-marketing-ui';

import { useDefaultRef, useDefaultState } from '@common/hooks';

import { InputBase, InputBaseOnChangeHandler, InputBaseProps, InputValues, RenderInput } from '@common/components';

import * as styles from './Input.scss';

export type InputOnChangeHandler<V extends InputValues> = InputBaseOnChangeHandler<V, HTMLInputElement>;
export type InputIconProps = Omit<IconProps, 'onClick' | 'type'>;

export interface InputProps<V extends InputValues> extends Partial<InputBaseProps<V, HTMLInputElement>> {
    clearable?: boolean;
    onClear?: () => void;
    autoFocus?: boolean;
    iconBefore?: IconType;
    iconAfter?: IconType;
    afterIconProps?: InputIconProps;
    beforeIconProps?: InputIconProps;

    /** React on Enter key press */
    onEnter?: React.KeyboardEventHandler<HTMLDivElement>;

    /** React on after icon click */
    onAfterIconClick?: React.MouseEventHandler<SVGSVGElement>;

    /** React on before icon click */
    onBeforeIconClick?: React.MouseEventHandler<SVGSVGElement>;
}

export function Input<V extends InputValues>({
    iconBefore,
    iconAfter,
    before,
    after,
    onClear,
    clearable = Boolean(onClear),
    onAfterIconClick,
    onBeforeIconClick,
    afterIconProps,
    beforeIconProps,
    autoFocus,
    inputRef: inputRefProp,
    onEnter,
    onKeyDown,
    value: valueProp,
    defaultValue,
    renderInput: renderInputRaw,
    name,
    loading,
    onChange,
    ...props
}: InputProps<V>) {
    const [value, setValue] = useDefaultState(valueProp, onChange, defaultValue);
    const inputRef = useDefaultRef(inputRefProp);

    React.useEffect(() => {
        if (autoFocus && !loading) {
            const timer = setTimeout(() => inputRef.current.focus());

            return () => {
                clearTimeout(timer);
            };
        }

        return () => {};
    }, [autoFocus, loading]);

    const handleChange: typeof onChange = (value, e) => {
        setValue(value, e);
    };

    const renderInput: RenderInput<V, HTMLInputElement> = (props) => {
        const newProps: React.HTMLProps<HTMLInputElement> = { ...props, value: String(props.value || '') };
        return renderInputRaw ? renderInputRaw(newProps as any) : <input {...newProps} />;
    };

    const beforeElement = (
        <>
            {before}
            {iconBefore && (
                <Icon
                    {...beforeIconProps}
                    onClick={onBeforeIconClick}
                    className={classNames(
                        styles.beforeIcon,
                        onBeforeIconClick && styles.clickIcon,
                        beforeIconProps?.className,
                    )}
                    type={iconBefore}
                />
            )}
        </>
    );

    const afterElement = (
        <>
            {clearable && (
                <Icon
                    onClick={onClear}
                    className={styles.clickIcon}
                    svgSize={24}
                    type={IconType.PROJECT_STAGED_CLOSE}
                />
            )}
            {iconAfter && (
                <Icon
                    {...afterIconProps}
                    onClick={onAfterIconClick}
                    className={classNames(onAfterIconClick && styles.clickIcon, afterIconProps.className)}
                    type={iconAfter}
                />
            )}
            {after}
        </>
    );

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        onKeyDown?.(e);

        if (onEnter && e.key === 'Enter') {
            onEnter(e);
        }
    };

    return (
        <InputBase<V, HTMLInputElement>
            {...props}
            loading={loading}
            name={name}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            renderInput={renderInput}
            before={beforeElement}
            after={afterElement}
        />
    );
}
