import * as React from 'react';
import classNames from 'classnames';

const videoExtensions: string[] = require('video-extensions');
const audioExtensions: string[] = require('audio-extensions');

import { Icon, IconType } from 'sber-marketing-ui';

import { useGetR7EditorQuery, useGetVideoPreviewQuery } from '@api';

import { FileData } from '@modules/files/types';

import { download } from '@common/Utils';

import { getImagePreview, openEditorWindow } from '@modules/files/utils';

import { useDownloadLink } from '@modules/files/hooks';

import { FileThumbnail, FileThumbnailProps } from '../FileThumbnail';

export const R7_FILE_TYPES = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];

import * as styles from './FileThumbnailButtons.scss';

export interface FileThumbnailButtonsProps extends FileThumbnailProps {
    file: FileData;
    editable?: boolean;
    onDownload?: () => void;
    onView?: () => void;
}

export function FileThumbnailButtons({
    file,
    className,
    children,
    editable,
    loading,
    onDownload,
    onView,
    ...props
}: FileThumbnailButtonsProps) {
    const isVideo = videoExtensions.includes(file.type);
    const [isDownload, setDownload] = React.useState(false);
    const [viewR7Editor, setViewR7Editor] = React.useState(false);
    const { data: { url } = {}, isLoading: isVideoPreviewLoading } = useGetVideoPreviewQuery(
        {
            fileName: file.name,
            containerName: file.containerName,
            originName: file.originName,
            storageEngine: file.storage as any,
        },
        { skip: !isVideo },
    );
    const {
        data: r7Data,
        refetch,
        isError,
        isLoading: isR7DataLoading,
    } = useGetR7EditorQuery(
        {
            fileId: file.id,
            containerName: file.containerName,
            fileName: file.name,
            fileType: file.type,
            storageEngine: file.storage as any,
            editable,
        },
        { skip: !viewR7Editor },
    );

    const { link, isLoading: isDownloadLinkLoading } = useDownloadLink(isDownload ? file : undefined);

    const imagePreview = isVideoPreviewLoading ? null : url || getImagePreview(file);
    const isAudio = !imagePreview && !isVideo && audioExtensions.includes(file.type);
    const isR7Editable = R7_FILE_TYPES.includes(file.type);
    const isPreview = imagePreview || isAudio || isVideo || isR7Editable;

    React.useEffect(() => {
        if (r7Data) {
            setViewR7Editor(false);
            openEditorWindow(r7Data.content, `${file.originName}.${file.type}`);
        } else if (isError) {
            setViewR7Editor(false);
        }
    }, [r7Data]);

    React.useEffect(() => {
        if (isDownload && !isDownloadLinkLoading && link) {
            download(link, `${file.originName}.${file.type}`);
            setDownload(false);
        }
    }, [isDownload, link, isDownloadLinkLoading]);

    const handleView = () => {
        if (isR7Editable) {
            setViewR7Editor(true);

            if (r7Data || isError) {
                refetch();
            }
        } else {
            onView?.();
        }
    };

    const handleFileDownload = async () => {
        setDownload(true);
        onDownload?.();
    };

    return (
        <FileThumbnail
            {...props}
            loading={loading || isR7DataLoading || isDownloadLinkLoading}
            className={classNames(styles.root, imagePreview && styles.image, className)}
            typeClassName={styles.type}
            file={file}
        >
            <div className={styles.buttons}>
                {isPreview && (
                    <button data-qa-id="FileThumbnailButtons__view" onClick={handleView} className={styles.button}>
                        <Icon type={IconType.VIEW_ASSET_EYE} />
                    </button>
                )}
                <button
                    data-qa-id="FileThumbnailButtons__download"
                    onClick={handleFileDownload}
                    className={styles.button}
                >
                    <Icon type={IconType.DOWNLOAD} />
                </button>
            </div>
        </FileThumbnail>
    );
}
