import * as React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
    WithTooltip,
    TooltipAnchor,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getUserById } from '@store/appUsers';
import {
    ComponentMode,
    getTaskEditorState,
    setWorkType,
    setBudgetApproval,
    setExecutor,
    setParticipants,
    TaskEditorBudgetApprovalValues,
} from '@store/taskEditor2';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { Utils } from '@common/Utils';

import { DropdownEditor } from './DropdownEditor';
import { BudgetEditor } from './BudgetEditor';

import * as styles from './WorkTypeExecutorEditor.scss';

const enum ComponentState {
    NoEditors = 'NoEditors',
    SelectingExecutor = 'SelectingExecutor',
    SelectingWorkType = 'SelectingWorkType',
}

function useInteractivity() {
    const workTypes = useSelector(
        (state: StoreState) => getTaskEditorState(state).data.userWorkType.workTypesWithUsers,
    );

    const [componentState, setComponentState] = React.useState<ComponentState>(ComponentState.NoEditors);

    const dispatch = useDispatch();
    const workTypeId = useSelector((state: StoreState) => getTaskEditorState(state).data?.rawTask?.workTypeId);
    const status = useSelector((state: StoreState) => getTaskEditorState(state).data?.rawTask?.status);
    const workType = useSelector((state: StoreState) => getTaskEditorState(state).values.workType);
    const executor = useSelector((state: StoreState) => getTaskEditorState(state).values.executor);
    const participants = useSelector((state: StoreState) => getTaskEditorState(state).values.participants.editing);
    const canChangeWorkType = useSelector((state: StoreState) => getTaskEditorState(state).permissions.canChangeRest);
    const canChangeExecutor = useSelector(
        (state: StoreState) => getTaskEditorState(state).permissions.canChangeExecutorDeadlineAndFiles,
    );

    function saveWorkType(workType: string) {
        const selectedWorkType = workTypes.find((wType) => wType.id === workType);
        dispatch(setWorkType(workType));
        dispatch(
            setBudgetApproval(
                Utils.addBudgetControlsForSelectedWorkType(selectedWorkType?.title)
                    ? ({} as TaskEditorBudgetApprovalValues)
                    : null,
            ),
        );
    }
    function saveExecutor(oldExecutor: number, newExecutor: number) {
        const updatedParticipants = newExecutor
            ? [...participants, { userId: newExecutor, canRemove: false }]
            : participants.filter((participant) => participant.userId !== oldExecutor);
        dispatch(setParticipants(updatedParticipants));

        dispatch(setExecutor(newExecutor));
    }

    return {
        componentState,
        setComponentState,
        workTypes,
        workTypeId,
        workType,
        saveWorkType,
        executor,
        status,
        saveExecutor,
        canChangeWorkType,
        canChangeExecutor,
    };
}

interface Props {
    componentMode: ComponentMode;
}

export function WorkTypeExecutorEditor({ componentMode }: Props): JSX.Element {
    const { status, canChangeExecutor, canChangeWorkType, ...editorProps } = useInteractivity();
    const { componentState, setComponentState } = editorProps;

    const selectedWorkType = editorProps.workTypes.find((wType) => wType.id === editorProps.workType);
    const initialWorkType = editorProps.workTypes.find((wType) => wType.id === editorProps.workTypeId);
    const isBudgetEditor = Utils.addBudgetControlsForSelectedWorkType(initialWorkType?.title);
    const showBudgetEditor = Utils.addBudgetControlsForSelectedWorkType(selectedWorkType?.title);
    const showRequiredMarker = componentMode === ComponentMode.enterRequiredFieldsForExistingTask;
    const cantEdit = status === TaskStatus.InProgress || status === TaskStatus.Closed;
    const isEditorActive = !(isBudgetEditor && cantEdit) && componentState !== ComponentState.NoEditors;

    return (
        <React.Fragment>
            <div
                className={styles.root}
                {...{
                    'qa-id': 'taskEditorWorkTypExecutorEditor',
                }}
            >
                <div className={styles.title}>
                    Исполнитель
                    {showRequiredMarker ? <React.Fragment>&nbsp;*</React.Fragment> : null}
                </div>

                <div className={classNames(styles.editor, isEditorActive && styles.editorActive)}>
                    <WorkTypeEditor disabled={!canChangeWorkType || (isBudgetEditor && cantEdit)} {...editorProps} />

                    <ExecutorEditor disabled={!canChangeExecutor || (isBudgetEditor && cantEdit)} {...editorProps} />
                </div>
            </div>

            {!(canChangeExecutor || canChangeWorkType) && isEditorActive && (
                <div className={styles.mask} onClick={() => setComponentState(ComponentState.NoEditors)} />
            )}

            {showBudgetEditor && (
                <BudgetEditor
                    disabled={isBudgetEditor && cantEdit}
                    showRequiredMarker={showRequiredMarker}
                    {...editorProps}
                />
            )}
        </React.Fragment>
    );
}

interface EditorProps {
    componentState: ComponentState;
    workType: string;
    executor: number;
    disabled: boolean;
    saveWorkType: (workType: string) => void;
    saveExecutor: (oldExecutor: number, newExecutor: number) => void;
    setComponentState: (state: ComponentState) => void;
}

interface WorkTypeEditorProps extends EditorProps {}

function WorkTypeEditor({
    componentState,
    executor,
    workType,
    disabled,
    saveWorkType,
    saveExecutor,
    setComponentState,
}: WorkTypeEditorProps): JSX.Element {
    const elementRef = React.useRef<HTMLDivElement>();
    const isEditorOpened = componentState === ComponentState.SelectingWorkType;
    const workTypes = useSelector(
        (state: StoreState) => getTaskEditorState(state).data.userWorkType.workTypesWithUsers,
    );
    const selectedWorkType = workTypes.find((wType) => wType.id === workType);
    const textContent = `Тип работ: ${selectedWorkType?.title || 'не найден'}`;

    const [isHovered, isHoveredSetter] = React.useState(false);
    function setIsHovered(isHovered: boolean) {
        if (!disabled) {
            isHoveredSetter(isHovered);
        }
    }

    function changeWorkType(workType: string, newExecutor?: number) {
        saveWorkType(workType);
        saveExecutor(executor, newExecutor || null);
        setComponentState(ComponentState.NoEditors);
    }

    function openEditor() {
        setComponentState(ComponentState.SelectingWorkType);
    }

    function closeEditor() {
        setComponentState(ComponentState.NoEditors);
    }

    const content = !selectedWorkType ? (
        <div
            className={classNames(
                styles.workType,
                disabled && styles.workTypeDisabled,
                isHovered && styles.workTypeHovered,
                isEditorOpened && styles.workTypeActive,
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={!disabled ? openEditor : () => {}}
        >
            Тип работ:&nbsp;
            <span className={styles.workTypeLink}>Выбрать</span>
        </div>
    ) : (
        <WithTooltip content={textContent} anchor={TooltipAnchor.BOTTOM}>
            <div
                className={classNames(
                    styles.workType,
                    isEditorOpened && styles.workTypeActive,
                    disabled && styles.workTypeDisabled,
                )}
                onClick={!disabled ? openEditor : () => {}}
            >
                <span className={styles.workTypeContent}>{textContent}</span>

                <div
                    className={classNames(styles.deleteWorkTypeButton, disabled && styles.deleteWorkTypeButtonDisabled)}
                    onClick={
                        !disabled
                            ? (event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  changeWorkType(null);
                              }
                            : () => {}
                    }
                >
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="currentColor"
                            d="M1.80775 0.310123C1.39426 -0.103374 0.723848 -0.103374 0.310352 0.310122C-0.103145 0.723619 -0.103145 1.39403 0.310352 1.80752L2.50271 3.99989L0.310122 6.19247C-0.103374 6.60597 -0.103374 7.27638 0.310122 7.68988C0.723618 8.10337 1.39403 8.10337 1.80752 7.68988L4.00011 5.49729L6.1927 7.68988C6.6062 8.10337 7.27661 8.10337 7.69011 7.68988C8.1036 7.27638 8.1036 6.60597 7.69011 6.19247L5.49752 3.99989L7.68988 1.80752C8.10337 1.39403 8.10337 0.723619 7.68988 0.310122C7.27638 -0.103374 6.60597 -0.103374 6.19247 0.310123L4.00011 2.50248L1.80775 0.310123Z"
                        />
                    </svg>
                </div>
            </div>
        </WithTooltip>
    );

    return (
        <div
            ref={elementRef}
            {...{
                'qa-id': 'taskEditorWorkTypeEditor',
            }}
        >
            {content}

            {isEditorOpened && (
                <WithGlobalPopup
                    bottom={-6}
                    maskZIndex={100}
                    container={elementRef}
                    onMaskClick={closeEditor}
                    positionCalculator={AbsoluteLikePositionCalculator}
                >
                    <DropdownEditor
                        subDropdownTitle="Исполнитель"
                        items={workTypes}
                        searchBySubtitle={false}
                        onSelect={changeWorkType}
                    />
                </WithGlobalPopup>
            )}
        </div>
    );
}

interface ExecutorEditorProps extends EditorProps {
    disabled: boolean;
}

function useExecutorEditorInteractivity(props: ExecutorEditorProps) {
    const {
        workType: selectedWorkType,
        componentState,
        executor,
        saveWorkType,
        saveExecutor,
        setComponentState,
    } = props;
    const isEditorOpened = componentState === ComponentState.SelectingExecutor;
    const wrapperRef = React.useRef<HTMLDivElement>();
    const inputRef = React.useRef<Input>();

    const [editorValue, setEditorValue] = React.useState<string>('');

    const users = useSelector((state: StoreState) => getTaskEditorState(state).data.userWorkType.usersWithWorkTypes);
    const userByWorkType = users.find((user) => user.id === executor);
    const selectedExecutor = useSelector((state: StoreState) => {
        if (userByWorkType) {
            return userByWorkType;
        }

        const user = getUserById(state, executor);
        if (user) {
            return {
                title: `${user.firstName} ${user.secondName}`,
                vacation: null,
                subTitle: 'Тип работ не найден',
            };
        }

        return null;
    });
    const workTypeExists = !!useSelector((state: StoreState) =>
        getTaskEditorState(state).data.userWorkType.workTypesWithUsers.find(
            (workType) => workType.id === selectedWorkType,
        ),
    );

    function openEditor() {
        const userFormatted = selectedExecutor ? `${selectedExecutor.title}` : '';
        setEditorValue(userFormatted);
        setComponentState(ComponentState.SelectingExecutor);
    }

    function closeEditor() {
        setComponentState(ComponentState.NoEditors);
    }

    function onSelect(executorId: number, workTypeId: string) {
        saveWorkType(workTypeId);
        saveExecutor(executor, executorId);
        setComponentState(ComponentState.NoEditors);
    }

    React.useEffect(() => {
        if (isEditorOpened && inputRef.current) {
            inputRef.current.focus();
        }
    });

    return {
        inputRef,
        wrapperRef,
        selectedExecutor,
        users,
        isEditorOpened,
        editorValue,
        workTypeExists,
        openEditor,
        closeEditor,
        onSelect,
        setEditorValue,
    };
}

function ExecutorEditor(props: ExecutorEditorProps): JSX.Element {
    const { workType, executor, disabled } = props;
    const {
        inputRef,
        wrapperRef,
        selectedExecutor,
        users,
        isEditorOpened,
        editorValue,
        workTypeExists,
        openEditor,
        closeEditor,
        onSelect,
        setEditorValue,
    } = useExecutorEditorInteractivity(props);

    let content: JSX.Element;
    if (isEditorOpened) {
        content = (
            <div className={styles.executorContent}>
                <Input
                    qaId="taskEditorExecutorEditorInput"
                    ref={inputRef}
                    placeholder="Имя исполнителя"
                    value={editorValue}
                    onInputChange={setEditorValue}
                    theme={InputTheme.Simple}
                />
            </div>
        );
    } else if (!selectedExecutor) {
        content = (
            <span className={classNames(styles.executorContent, styles.executorContentGrey)}>
                {executor ? 'Пользователь не найден' : 'Имя исполнителя'}
            </span>
        );
    } else {
        content = (
            <span className={styles.executorContent}>
                {selectedExecutor.vacation ? (
                    <span title={selectedExecutor.vacation?.comment}>
                        🌴&nbsp;
                        {selectedExecutor.title}&nbsp;
                    </span>
                ) : (
                    <span>{selectedExecutor.title}</span>
                )}
                <span className={styles.executorContentGrey}>({selectedExecutor.subTitle})</span>
            </span>
        );
    }

    const escapedEditorValue = (editorValue.match(/\d|[а-я]|[А-Я]| /g) || []).join('');
    const editorValueRegexp = new RegExp(escapedEditorValue, 'i');

    return (
        <React.Fragment>
            <div
                ref={wrapperRef}
                className={classNames(styles.executor, disabled && styles.executorDisabled)}
                onClick={!disabled ? openEditor : null}
                {...{
                    'qa-id': 'taskEditorExecutorEditor',
                }}
            >
                {content}
            </div>

            {!disabled && isEditorOpened && (
                <WithGlobalPopup
                    left={-13}
                    bottom={-8}
                    maskZIndex={100}
                    container={wrapperRef}
                    onMaskClick={closeEditor}
                    positionCalculator={AbsoluteLikePositionCalculator}
                >
                    <DropdownEditor
                        addSearch={false}
                        items={Utils.spacebarInsensitiveFilter(editorValueRegexp, users, (user) => user.title).filter(
                            (user) =>
                                workType && workTypeExists
                                    ? user.subItems.some((workTypeSubItem) => workTypeSubItem.id === workType)
                                    : true,
                        )}
                        subDropdownTitle="Тип работ"
                        subDropdownItemId={workType}
                        onSelect={onSelect}
                    />
                </WithGlobalPopup>
            )}
        </React.Fragment>
    );
}
