import * as React from 'react';
import * as styles from './BackStatusPopup.scss';
import {
    ButtonTheme_redesign as ButtonTheme,
    Button_redesign as Button,
    Icon,
    IconType,
    Popup,
    Textarea_theme,
    Textarea_redesign,
} from 'sber-marketing-ui';
import { SetStatus } from '../../hooks/useGetCurrentStatus';
import { CreativeRequest, CreativeRequestStatus } from '@api';
import { compose, graphql } from 'react-apollo';
import { CREATE_COMMENT_MUTATION } from '@modules/common/CreateCommentForm/WithCreateCommentMutation/query';
import { v4 } from 'uuid';

type QueryProps = {
    createCommentMutation: MutationFunc<{
        id: string;
        text: string;
        fileIds?: any[];
    }>;
    createCommentMutationResult: MutationResult;
};
type MutationFunc<V> = (params: { variables: V }) => Promise<void>;
type MutationResult = {
    loading: boolean;
};

export interface ChildrenProps {
    comments: Comment[];
    loading: boolean;
    createComment: (text: string, fileIds: string[]) => Promise<void>;
}

interface ExternalProps {
    children: (props: ChildrenProps) => JSX.Element;
}

interface Props extends ExternalProps, QueryProps {
    onClose: () => void;
    setStatus: SetStatus;
    reviewComment: string | null;
    setReviewComment: ({ commentId }: { commentId: string }) => void;
    creativeRequest: CreativeRequest;
}

export const BackStatusPopup = compose(
    graphql<ExternalProps>(CREATE_COMMENT_MUTATION, { name: 'createCommentMutation' }),
)(
    ({
        onClose,
        setStatus,
        reviewComment,
        setReviewComment,
        createCommentMutation,
        creativeRequest,
    }: Props): JSX.Element => {
        const [comment, setComment] = React.useState<string>('');

        const createComment = async (text = '') => {
            const commentId = v4();

            await createCommentMutation({
                variables: {
                    id: commentId,
                    text,
                },
            });

            creativeRequest.model.addComment({ commentId });

            return commentId;
        };

        return (
            <Popup onOutOfContentClick={onClose}>
                <div className={styles.popup}>
                    <div className={styles.modalHeader}>
                        <div>Вернуть заявку в агентство на доработку</div>
                        <Icon type={IconType.CLOSE_SEMIBOLD} color="#000" onClick={onClose} />
                    </div>
                    <div>
                        <Textarea_redesign
                            placeholder="Напишите комментарий"
                            value={comment}
                            onChange={(value) => {
                                setComment(value);
                            }}
                            theme={Textarea_theme.REGULAR}
                        />
                        <div className={styles.buttonGroup}>
                            <div className={styles.sendButton}>
                                <Button
                                    disabled={false}
                                    theme={ButtonTheme.MainRounded}
                                    onClick={async () => {
                                        setReviewComment({ commentId: comment ? await createComment(comment) : '' });
                                        setStatus({ status: CreativeRequestStatus.fillingByAgency });
                                        onClose();
                                    }}
                                >
                                    Отправить
                                </Button>
                            </div>
                            {/* <div className={styles.withoutCommentButton}>
                                <Button
                                    disabled={false}
                                    theme={ButtonTheme.GhostRounded}
                                    onClick={() => {
                                        setReviewComment({ commentId: '' });
                                        setStatus({ status: CreativeRequestStatus.fillingByAgency });
                                        onClose();
                                    }}
                                >
                                    Отправить без комментария
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Popup>
        );
    },
) as React.ComponentType<ExternalProps>;
