import * as React from 'react';
import * as style from './Status.scss';
import { Icon, IconType } from 'sber-marketing-ui';
import classNames from 'classnames';

interface Props {
    title: string;
    isCurrent?: boolean;
    isDone?: boolean;
    isLink?: boolean;
}

const Status = ({ title, isCurrent, isDone, isLink }: Props): JSX.Element => {
    return (
        <div className={style.root}>
            {isDone ? (
                <Icon type={IconType.ACCEPT_ICON_ROUND_ACTIVE} svgSize={14} />
            ) : (
                <Icon type={IconType.ACCEPT_ICON_ROUND} svgSize={14} color="#7E8681" />
            )}
            <div className={classNames(style.title, isCurrent && style.current, isLink && style.link)}>{title}</div>
        </div>
    );
};
export default Status;
