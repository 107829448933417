import * as React from 'react';
import * as styles from './styles.scss';

interface Props {
    saveButtonElement: React.ReactElement;
}

export const Footer: React.FC<Props> = ({ saveButtonElement }) => {
    return <div className={styles.root}>{saveButtonElement}</div>;
};
