import { compact, intersection, keys } from 'lodash';

import { Constraints, ErrorMessage } from './types';
import { constraintHandlerMap } from './constraintHandlerMap';

interface Params {
    constraints: Constraints;
    value: string;
}

interface Validate {
    (params: Params): Promise<ErrorMessage[]>;
}

export const validate: Validate = async ({ constraints, value }) => {
    const constraintKeys = intersection(keys(constraints), keys(constraintHandlerMap));

    return compact(
        await Promise.all(
            constraintKeys.map((constraintKey) =>
                constraintHandlerMap[constraintKey](constraints[constraintKey], value),
            ),
        ),
    );
};
