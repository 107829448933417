import * as React from 'react';
import classNames from 'classnames';

import { IconType } from 'sber-marketing-ui';

import {
    Chip,
    DropdownInput,
    DropdownInputProps,
    DynamicOptionsProps,
    getOptionValue,
    InputIconProps,
    InputOnChangeHandler,
    OptionMultiple,
    OptionProps,
    Options,
    OptionValues,
    SearchOptionProps,
    SearchOptions,
    StaticOptionsProps,
    TagClose,
} from '@common/components';
import { DropdownOptions, useDefaultRef, useDefaultState } from '@common/hooks';

import * as styles from './Selector.scss';

export interface SelectorProps<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
> extends StaticOptionsProps,
        DynamicOptionsProps<M, OP, O, ID, V, SV>,
        Omit<DropdownInputProps<string>, 'height' | 'after' | 'multiple' | 'selected' | 'onSelect'> {
    optionsProps?: Omit<
        SearchOptionProps<M, OP, O, ID, V, SV>,
        keyof DynamicOptionsProps<M, OP, O, ID, V, SV> | keyof StaticOptionsProps
    >;
    exclude?: V[];
    arrow?: boolean;
    exact?: boolean;
    searchable?: boolean;
    search?: string;
    selectedTitle?: string;
    keepDropdownOnSelect?: boolean;
    onSearch?: (value: string) => void;
    onChange?: (value: string) => void;
    onChangeTitle?: (value: string) => void;
}

export function Selector<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
>({
    inputRef: inputRefProp,
    children,
    className,
    before,
    options,
    exclude,
    optionId,
    optionsProps,
    arrow,
    exact,
    disabled,
    selected,
    preventDefaultSelect,
    iconAfter,
    dropdownRef,
    onAfterIconClick,
    searchable,
    search,
    value,
    multiple,
    maxMultipleCount,
    defaultSelected = multiple ? ([] as SV) : undefined,
    afterIconProps,
    inputProps,
    selectedTitle,
    isDropdownShow,
    keepDropdownOnSelect = multiple as boolean,
    renderOption,
    optionsAfter,
    optionsLoading,
    optionsLoader,
    optionsHeight,
    optionHeight,
    optionsBefore,
    onChangeTitle,
    onSelect,
    onShowChangeDropdown,
    onMouseDown,
    onSearch,
    onChange,
    ...props
}: SelectorProps<M, OP, O, ID, V, SV>) {
    const inputRef = useDefaultRef(inputRefProp);
    const [isOpen, setOpen] = useDefaultState(isDropdownShow, onShowChangeDropdown, false);
    const [rawValue, setRawValue] = useDefaultState(value, onChange, '');
    const [title, setTitle] = useDefaultState(selectedTitle, onChangeTitle, '');
    const [currentSearch, setSearch] = useDefaultState(search, onSearch, '');
    const [currentSelected, setSelected] = useDefaultState(selected, onSelect, defaultSelected, preventDefaultSelect);
    const valueRef = React.useRef<string>();
    const titleRef = React.useRef<string>();

    const currentValue = !exact ? rawValue : !searchable ? title : isOpen ? currentSearch : title;

    valueRef.current = currentValue;
    titleRef.current = title;

    if (exclude) {
        options = options?.filter(({ value }) => !exclude.includes(getOptionValue(value, optionId)));
    }

    const handleSetValue: typeof onChange = (newValue) => {
        valueRef.current = newValue;
        setRawValue(newValue);
    };

    const handleShowChangeDropdown = (newOpen: boolean) => {
        if (newOpen) {
            if (currentSearch !== valueRef.current) {
                setSearch(valueRef.current);
            }
        } else {
            if (exact && titleRef.current !== valueRef.current) {
                handleSetValue(titleRef.current);
            }
        }
        setOpen(newOpen);
    };

    const handleSetSelected: typeof onSelect = (newValue, option) => {
        handleSetValue(option?.title || currentValue || '');
        setSelected(newValue, option);
    };

    const handleSelect: typeof onSelect = (newValue, option) => {
        handleSetSelected(newValue, option);
        setSearch('');

        if (multiple) {
            inputRef.current.focus();
        }

        if (!keepDropdownOnSelect) {
            dropdown.current.close();
        }
    };

    React.useEffect(() => {
        if (!exact && title !== currentValue) {
            handleSetSelected(null, null);
        }
    }, [exact, title, currentValue]);

    React.useEffect(() => {
        const newTitle = options?.find(({ value }) => getOptionValue(value, optionId) === currentSelected)?.title || '';
        setTitle(newTitle);
    }, [currentSelected, options]);

    const rawDropdownRef = React.useRef<DropdownOptions>();
    const dropdown = dropdownRef || rawDropdownRef;

    const currentIconAfter = iconAfter || (!arrow ? undefined : IconType.ARROW16_DOWN_BLACK);
    const afterArrowIconProps: InputIconProps = {
        className: classNames(styles.arrow, isOpen && styles.openArrow, afterIconProps?.className),
    };

    const optionsCurrentProps: Required<StaticOptionsProps & DynamicOptionsProps<M, OP, O, ID, V, SV>> = {
        options,
        multiple,
        maxMultipleCount,
        preventDefaultSelect,
        selected: currentSelected,
        defaultSelected,
        optionId,
        optionsAfter,
        optionsBefore,
        renderOption,
        optionsLoading,
        optionsLoader,
        optionsHeight,
        optionHeight,
        onSelect: handleSelect,
    };

    const optionsContent = searchable ? (
        <SearchOptions
            {...optionsProps}
            {...optionsCurrentProps}
            hideSearch
            search={currentSearch}
            onSelect={handleSelect}
        />
    ) : (
        <Options {...optionsProps} {...optionsCurrentProps} onSelect={handleSelect} />
    );

    const handleAfterIconClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
        if (arrow && !disabled && dropdown.current && !dropdown.current.isDropdownShow) {
            dropdown.current.show();
        }

        onAfterIconClick?.(e);
    };

    const handleInputClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
        if (exact && !disabled && !searchable && dropdown.current) {
            if (isOpen) {
                dropdown.current.close();
            } else {
                dropdown.current.show();
            }
        }

        inputProps?.onMouseDown?.(e);
    };

    const handleInputChange: InputOnChangeHandler<string> = (value: string) => {
        if (searchable) {
            setSearch(value);
        }

        handleSetValue(value);

        if (!exact) {
            const option = options.find(({ title }) => title === value);

            if (option) {
                const selectedValue = optionId ? option.value[optionId] : (option.value as any);
                handleSetSelected(selectedValue, option);
            }
        }
    };

    const handleRemoveChip = (option: OP) => {
        if (multiple) {
            const optionValue = getOptionValue(option.value, optionId);
            setSelected(
                selected.filter((selectedValue: any) => selectedValue !== optionValue),
                option,
            );
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (multiple && searchable && !currentSearch && selected.length && e.key === 'Backspace') {
            const lastSelected = selected[selected.length - 1];
            const option = options.find((option) => getOptionValue(option.value, optionId) === lastSelected);

            if (option) {
                handleRemoveChip(option);
            }
        }

        inputProps?.onKeyDown?.(e);
    };

    const beforeContent = (
        <>
            {before}
            {multiple &&
                selected &&
                selected?.map((selectedId: any) => {
                    const option = options.find((option) => getOptionValue(option.value, optionId) === selectedId);

                    return (
                        option && (
                            <Chip key={selectedId}>
                                {option.title || option.titleContent || getOptionValue(option.value, optionId)}
                                <TagClose onClick={() => handleRemoveChip(option)} />
                            </Chip>
                        )
                    );
                })}
        </>
    );

    return (
        <DropdownInput
            stretch
            {...props}
            inputRef={inputRef}
            before={beforeContent}
            className={classNames(multiple && styles.multiple, className)}
            afterIconProps={afterArrowIconProps}
            onAfterIconClick={handleAfterIconClick}
            value={currentValue}
            inputProps={{
                ...inputProps,
                // @ts-ignore: FIXME
                'data-qa-id': 'Selector__input',
                autoComplete: 'off',
                onMouseDown: handleInputClick,
                onKeyDown: handleKeyDown,
            }}
            readOnly={exact && !searchable}
            disabled={disabled}
            dropdownRef={dropdown}
            iconAfter={currentIconAfter}
            isDropdownShow={isOpen}
            onChange={handleInputChange}
            onShowChangeDropdown={handleShowChangeDropdown}
        >
            {optionsContent}
            {children}
        </DropdownInput>
    );
}
