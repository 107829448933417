import { useState } from 'react';

import { FileApiUploadParams, FileOrAsset, useAddFileMutation } from '@api';

import { FileAsset } from '@store/commonTypes';

export interface UseAddFileData {
    isAddFileLoading: boolean;
    progress: number;
}

export function useAddFiles(
    paramsRaw?: Partial<FileApiUploadParams>,
): [(files: FileOrAsset[], params?: Partial<FileApiUploadParams>) => Promise<FileAsset[]>, UseAddFileData] {
    const [progress, setProgress] = useState(0);
    const [isAddFileLoading, setAddFileLoading] = useState(false);
    const [addFile] = useAddFileMutation();

    const reset = () => {
        setProgress(0);
        setAddFileLoading(false);
    };

    return [
        async (files: FileOrAsset[], params = paramsRaw) => {
            const result: Promise<FileAsset>[] = [];
            const count = files.length;
            const progresses = files.map(() => 0);

            setAddFileLoading(true);
            setProgress(0);

            for (let i = 0; i < count; i++) {
                const file = files[i];

                result.push(
                    addFile({
                        file,
                        params,
                    }).then(async (data): Promise<FileAsset> => {
                        if ('error' in data) {
                            reset();
                            return Promise.reject(data.error);
                        }

                        let fileAsset: FileAsset;
                        for await (const progress of data.data) {
                            fileAsset = progress;
                            progresses[i] = progress.loadingProgress;
                            setProgress(progresses.reduce((a, b) => a + b, 0) / count);
                        }

                        return fileAsset;
                    }),
                );
            }

            try {
                const returnResult = await Promise.all(result);
                reset();
                return returnResult;
            } catch (e) {
                console.error(e);
                reset();
                return [];
            }
        },
        { isAddFileLoading, progress },
    ];
}
