import * as React from 'react';
import { BriefScheme } from 'sber-marketing-types/frontend/Brief2';
import { Button_redesign, ButtonTheme_redesign, LabeledSelect, StyledPopup } from 'sber-marketing-ui';

import * as style from './SchemeSelectionForm.scss';

interface Props {
    schemes: BriefScheme[];
    selectedSchemeId: string;
    organizationId: string;
    editRight: boolean;
    isBriefReady: boolean;
    isChangeSchemeModalOpened: boolean;
    onSchemeSelection: (schemeId: string) => void;
    onSchemeSelectionButtonClick: () => void;
    onModalConfirmButtonClick: () => void;
    onModalCancelButtonClick: () => void;
}

const BRIEF_SCHEMES_SORT = {
    'ДУД: Бриф "Выставочный проект"': 1,
    'ДУД: Бриф "Событийный проект"': 2,
    'ДУД: Бриф "Спонсорское мероприятие"': 3,
};

export const SchemeSelectionForm: React.FC<Props> = ({
    schemes,
    selectedSchemeId,
    organizationId,
    editRight,
    isBriefReady,
    isChangeSchemeModalOpened,
    onSchemeSelection,
    onSchemeSelectionButtonClick,
    onModalConfirmButtonClick,
    onModalCancelButtonClick,
}) => {
    const selectedBriefScheme = schemes.find(({ id }) => selectedSchemeId === id);
    const selectedSchemeIncludeInOrganisation = selectedBriefScheme
        ? selectedBriefScheme.organizationId === organizationId
        : true;

    return (
        <div className={style.schemeSelectionWrapper}>
            <div className={style.schemeSelect}>
                <LabeledSelect
                    withTopPlacement
                    qaId="briefSchemeDropdown"
                    title={'Схема брифа'}
                    items={schemes
                        .sort((a, b) => {
                            return BRIEF_SCHEMES_SORT[a.name] && BRIEF_SCHEMES_SORT[b.name]
                                ? BRIEF_SCHEMES_SORT[a.name] - BRIEF_SCHEMES_SORT[b.name]
                                : BRIEF_SCHEMES_SORT[a.name]
                                ? -1
                                : BRIEF_SCHEMES_SORT[b.name]
                                ? 1
                                : 0;
                        })
                        .map((scheme) => ({
                            label: scheme.name,
                            value: scheme.id,
                            disabled: scheme.status === 'inactive',
                        }))}
                    selectedValue={selectedSchemeId}
                    onSelection={(value) => onSchemeSelection(value as string)}
                    disabled={!editRight || isBriefReady}
                    fullNameOfItem
                />

                {!selectedSchemeIncludeInOrganisation && (
                    <div className={style.briefSchemeOrganizationName}>{selectedBriefScheme.organizationName}</div>
                )}
            </div>

            <div className={style.schemeSelectionButton}>
                <Button_redesign
                    qaId="briefSelectSchemeButton"
                    theme={ButtonTheme_redesign.GhostRoundedUpdated}
                    onClick={onSchemeSelectionButtonClick}
                    disabled={!selectedSchemeId || isBriefReady || !editRight}
                >
                    Применить схему
                </Button_redesign>
            </div>

            {isChangeSchemeModalOpened && (
                <ChangeSchemeConfigModal
                    onConfirmClick={onModalConfirmButtonClick}
                    onCancelClick={onModalCancelButtonClick}
                />
            )}
        </div>
    );
};

const BRIEF_SCHEME_CHANGE_MESSAGE =
    'При изменении схемы брифа, она заменит текущую и все заполненные поля будут удалены. Изменить схему брифа?';

interface ChangeBriefSchemeConfigModalProps {
    onConfirmClick: () => void;
    onCancelClick: () => void;
}

const ChangeSchemeConfigModal: React.FunctionComponent<ChangeBriefSchemeConfigModalProps> = ({
    onConfirmClick,
    onCancelClick,
}: ChangeBriefSchemeConfigModalProps) => (
    <StyledPopup
        title={'Будет изменена схема брифа'}
        fullMessage={BRIEF_SCHEME_CHANGE_MESSAGE}
        firstButtonText={'Изменить'}
        firstButtonHandler={onConfirmClick}
        secondButtonText={'Отмена'}
        secondButtonHandler={onCancelClick}
    />
);
