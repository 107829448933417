import * as React from 'react';
import { positionValues } from 'react-custom-scrollbars';
import { CustomScrollbar } from 'sber-marketing-ui';

import { Header } from './Header';
import { TabBar, TabBarHeader, TabBarContent } from './TabBar';
import { Modal } from './Modal';
import { Warnings, GeneralInfo, BudgetInfo, RelatedActivities, CommentsWidget, TagsEditorWidget } from './Content';

import * as style from './LineModal.scss';
import { RelatedCreative } from './Content/RelatedCreative';

interface Props {
    lineId: string;
    // showOnlyRelatedActivitiesBlock: boolean;
    onCloseButtonClick: () => void;
    onCommonInfoScroll: (positionValues: positionValues) => void;
    onAttachedTagsChange: () => void;
}

export const Tab: React.FC = ({ children }) => {
    return (
        <div
            className={style.tab}
            {...{
                'qa-id': 'budgetExeuctionLineModalTabButton',
            }}
        >
            {children}
        </div>
    );
};

export const LineModal: React.FC<Props> = ({
    lineId,
    // showOnlyRelatedActivitiesBlock,
    onCloseButtonClick,
    onCommonInfoScroll,
    onAttachedTagsChange,
}) => {
    // const commonInfoContent = showOnlyRelatedActivitiesBlock
    //     ? (
    //         <RelatedActivities lineId={lineId} onBindActivityFinish={onBindActivityFinish}/>
    //     )
    //     : (
    //         <React.Fragment>
    //             <Warnings lineId={lineId} />
    //             <GeneralInfo lineId={lineId} />
    //             <BudgetInfo lineId={lineId} />
    //             <RelatedActivities lineId={lineId} onBindActivityFinish={onBindActivityFinish}/>
    //             {/*<Comments />*/}
    //         </React.Fragment>
    //     );

    return (
        <Modal lineId={lineId}>
            <div
                className={style.modal}
                {...{
                    'qa-id': 'budgetExecutionLineModal',
                }}
            >
                <div className={style.headerWrapper}>
                    <Header lineId={lineId} onCloseButtonClick={onCloseButtonClick} />
                </div>

                <TabBar>
                    <div className={style.tabBarHeaderWrapper}>
                        <TabBarHeader disableTabsIndexes={[1, 2]}>
                            <Tab>Общая информация</Tab>
                        </TabBarHeader>
                    </div>

                    <TabBarContent>
                        <div
                            className={style.commonInfoTabContainer}
                            {...{
                                'qa-id': 'budgetExeucitonLineModalCommonInfo',
                            }}
                        >
                            <CustomScrollbar hideHorizontalScrollbar onScrollFrame={onCommonInfoScroll}>
                                <Warnings lineId={lineId} />
                                <GeneralInfo lineId={lineId} />
                                <BudgetInfo lineId={lineId} />
                                <TagsEditorWidget lineId={lineId} onAttachedTagsChange={onAttachedTagsChange} />
                                <RelatedActivities lineId={lineId} />
                                <RelatedCreative lineId={lineId} />
                                <CommentsWidget lineId={lineId} />
                            </CustomScrollbar>
                        </div>
                    </TabBarContent>
                </TabBar>
            </div>
        </Modal>
    );
};
