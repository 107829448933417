// tslint:disable:max-line-length
// tslint:disable:max-file-line-count
export const releaseData = [
    {
        date: '2023-12-22:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Добавлена возможность выбора нескольких ID бюджета для задач с видом работ "Согласование нейминга и ID".
        <img src="/static/release_notes/2023-12-22:00:00:00+0300/projects1.gif" alt="Выбор нескольких ID бюджета" />
        \n
        • Добавлена кнопка "Очистить форматирование" в панель редактирования текста в комментариях к задаче.
        <img src="/static/release_notes/2023-12-22:00:00:00+0300/projects2.png" alt="Кнопка Очистить форматирование" />
        \n
        • Добавлен прелоадер при скачивании файлов из задачи.
        \n
        • Добавлена возможность для участников удалять себя из задач, если задача, например, уже неактуальна.
        \n
        • Исправлена ошибка, при которой измененное название задачи не отображалось в правом сайдбаре.
        \n
        • Исправлена ошибка, при которой неверно нумеровался список в комментариях к задаче.
        \n
        • Настроен функционал для брифов:
        &nbsp;&nbsp;&nbsp;&nbsp;◦ Добавлена возможность просматривать историю изменения полей брифа.
        &nbsp;&nbsp;&nbsp;&nbsp;◦ Добавлена возможность импорта в шаблон брифа документа со стороны бизнес-администратора. Настроена работа с документом через Р7.
        &nbsp;&nbsp;&nbsp;&nbsp;◦ Добавлена возможность автоматического сохранения введенных значений в поля брифа.
        \n
        <b>Бюджетирование</b>\n
        • Исправлена ошибка, при которой при копировании значений в таблице Исполнения вставлялось неправильное значение.
        \n
        <b>Онбординг</b>\n
        • Настроена подсветка управляющих кнопок для поиска активности в Проектном модуле, модуле Бюджетирования.
        `,
    },
    {
        date: '2023-12-06:00:00:00+0300',
        message: `
        <b>Онбординг</b>\n
        • Добавлен раздел онбординга, который позволит максимально эффективно и быстро начать свою работу в MRM.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/onboarding.gif" alt="Раздел онбординга" />
        \n
        <b>Управление проектами</b>\n
        • Реализованы сообщения в ветках, а не обсуждением в отдельном окне. По умолчанию отображаются два последних ответа с возможностью посмотреть всю цепочку сообщений.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/projects1.gif" alt="Сообщения в ветках" />
        \n
        • Реализована возможность добавлять комментарии в чате в избранное, чтобы всегда вернуться к ним.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/projects2.gif" alt="Избранные комментарии" />
        \n
        • Добавлена кнопка для копирования ссылки на задачу.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/projects3.png" alt="Кнопка копирования ссылки на задачу" />
        \n
        • Добавлена возможность создать дополнительный канал только для себя, например, чтобы хранить важные вложения по задаче.
        \n
        • Исправлена ошибка "Версия файла была изменена" при открытии файла в онлайн редакторе документов.
        \n
        • Добавлено слово "Итого" в карточке привязки ID бюджета к проекту.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/projects4.png" alt="ИТОГО в карточке привязки ID бюджета к проекту" />
        \n
        <b>Бюджетирование</b>\n
        • Добавлена возможность выполнять арифметические действия (сложение и вычитание) в ячейках Факт и Резерв таблицы Исполнения.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/budget1.gif" alt="Арифметические действия в ячейках Факт и Резерв таблицы Исполнения" />
        \n
        • Добавлена возможность импорта Финального медиаплана в таблицу Исполнения и автоматического заполнения ячеек Факт на его основании.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/budget2.png" alt="Функционал импорта Финального медиаплана в таблицу Исполнения" />
        \n
        • Изменена логика экспорта таблиц Бюджетирования: теперь расположение колонок в выгружаемом файле соответствует расположению колонок в таблице у пользователя.
        \n
        • Добавлена колонка «Утвержденный план итого, тыс. ₽» в Исполнение, в которой отображается суммарный утвержденный план по строке по результатам Планирования.
        \n
        • Реализована индикация строк с отрицательной разностью план-факт и план-резерв в Исполнении.
        \n
        • Реализована механика в Планировании, при которой автор строки всегда имеет доступ к ней для редактирования вне зависимости от доступов, настроенных в конструкторе ролей.
        \n
        • Исправлена ошибка, при которой не изменялась ширина колонки "Тег" в таблице Исполнения.
        \n
        <b>Заявка на креатив</b>\n
        • Обновили дизайн и UX статусов согласно новому макету. Теперь статусы выглядят более ясно и понятно.
        \n
        • При скролле страницы, элемент со статусами будет прилипать к верху страницы. Это поможет вам всегда видеть текущий статус, не важно, насколько далеко вы прокрутили страницу.
        \n
        • Добавили новый статус "Отправить на доработку". Это позволит вам более гибко управлять процессом работы над заявками.
        \n
        • Теперь комментарии, оставленные при отправке заявки на доработку, будут публиковаться в общем сайдбаре.
        \n
        • Когда все строки согласованы и все акты оплачены, статус "Заявка закрыта" будет проставляться автоматически.
        <img src="/static/release_notes/2023-12-06:00:00:00+0300/creative1.gif" alt="Изменения интерфейса Заявки на креатив" />
        \n
        <b>Брифы</b>\n
        • Добавлена возможность редактировать бриф, если он уже используется в проектах.
        \n
        • Настроен вывод скоращенных брифов при создании строки в Планировании.
        `,
    },
    {
        date: '2023-11-23:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Проведен редизайн экранов авторизации: обновлен интерфейс, добавлена возможность сбросить пароль и добавлен чат с технической поддержкой.
        <img src="/static/release_notes/2023-11-23:00:00:00+0300/projects1.png" alt="Редизайн экранов авторизации" />
        \n
        • Исправлена ошибка, при которой в случае выбора "Нет ID источника" в шаблоне создания задачи на "Согласование ID бюджета" не отображалось выбранное значение в задаче.
        \n
        • Исправлена ошибка, при которой не применялось форматирование (нумерованный и ненумерованный списки) к тексту комментария.
        \n
        • Исправлена ошибка, при которой в некоторых случаях не отображался текст комментария при его редактировании.
        \n
        • Исправлена ошибка, при которой удаленного или заблокированного пользователя было возможно добавить участником в задачу.
        \n
        • Исправлена ошибка, при которой оставалась пустая белая полоса между комментарием и окном ввода текста, при его расположении в верхней части задачи.
        \n
        • Исправлена ошибка, при которой на почту приходило уведомление с битой ссылкой на карточку связи ID бюджета и проекта.
        \n
        • Исправлена ошибка, при которой при снятии чек-бокса в конструкторе уведомлений письмо все равно поступало.
        \n
        <b>Бюджетирование</b>\n
        • Исправлена ошибка, при которой у некоторых пользователей блокировался доступ к Планированию и Исполнению при выборе определенных значений в фильтрах.
        \n
        • Исправлена ошибка сброса связей справочников для пользователей Маркетинга Территориальных банков.
        \n
        • Исправлена ошибка, при которой в некоторых случаях в ячейке после сброса связей нельзя было выбрать нужное значение.
        \n
        • Исправлена ошибка при выгрузке всех корректировок.
        `,
    },
    {
        date: '2023-10-30:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Реализована возможность увеличивать окно для ввода комментариев в задаче, а также располагать его по желанию пользователя сверху или снизу пространства с сообщениями.
        <img src="/static/release_notes/2023-10-30:00:00:00+0300/projects1.gif" alt="Увеличение окна ввода комментариев в задаче" />
        \n
        • Добавлена возможность очистки форматирования текста комментария, например, при вставке текста из другого документа.
        <img src="/static/release_notes/2023-10-30:00:00:00+0300/projects2.png" alt="Очистка форматирования текста комментария" />
        \n
        • Добавлено отображение даты создания задачи.
        <img src="/static/release_notes/2023-10-30:00:00:00+0300/projects3.png" alt="Отображение даты создания задачи" />
        \n
        • Добавлено сохранение текста комментария в задаче и в сайдбаре при обновлении страницы или при переходе между вкладками.
        <img src="/static/release_notes/2023-10-30:00:00:00+0300/projects4.gif" alt="Cохранение текста комментария в задаче и в сайдбаре" />
        \n
        • Реализована возможность добавлять вложения в задачу перетаскиванием файлов (drag-and-drop) из другого окна, а не только через скрепку.
        \n
        • Ускорено открытие раздела "Календарь проектов".
        \n
        • Исправлена ошибка, при которой область комментария сильно растягивалась при вставке длинной ссылки.
        \n
        • Исправлено отображение статуса "В отпуске" при добавлении участника в задачу или при выборе пользователя через символ @.
        \n
        • Исправлено сбрасывание фильтров и сортировки задач внутри проекта при обновлении страницы.
        \n
        • Исправлена работа сортировки по Просмотренным / Непросмотренным задачам в проектах.
        `,
    },
    {
        date: '2023-10-20:00:00:00+0300',
        message: `
        <b>ЗНК</b>\n

        <b>Обновленная логика расчета АК</b>\n
        • Улучшена логика расчета строки АК для удобства работы. Теперь, для каждой строки Производства для трех лиц, создается отдельная строка АК. Информация, такая как ID источников, ID исполнения, название проекта, даты, поставщик, согласование строк и статусы Актов, синхронизируются между связанными строками таблиц Производство и АК.

        <b>Двухсторонняя синхронизация ЗНК и сводной таблицы</b>\n
        • Добавлена синхронизация подстрок ЗНК и сводной таблицы для удобства сравнения.
        • Теперь в сводной таблице отображаются подстроки ЗНК, которые автоматически генерируются на основе заполненных таблиц в ЗНК. Эти данные обновляются в режиме реального времени.
        <img src="/static/release_notes/2023-10-20:00:00:00+0300/creativeRequests1.png" alt="Двухсторонняя синхронизация ЗНК и сводной таблицы" />

        <b>Синхронный скролл таблиц и высота строк</b>\n
        • Добавлена функция синхронного скролла для удобства работы с таблицами ЗНК. Теперь все 3 таблицы ЗНК могут скролиться одновременно по горизонтали. Также, добавлена возможность ручной настройки высоты строк в таблицах ЗНК.
        <img src="/static/release_notes/2023-10-20:00:00:00+0300/creativeRequests2.png" alt="Синхронный скролл таблиц и высота строк" />

        <b>Перенос строки в ячейке по комбинации клавиш Shift+Enter</b>\n
        • Добавлена функция переноса строки в ячейке по нажатию Shift+Enter в следующих ячейках: Название РК/Проекта, Вид передаваемых прав, Территория действия прав, Срок действия прав, Ссылка на имидж.
        <img src="/static/release_notes/2023-10-20:00:00:00+0300/creativeRequests3.png" alt="Перенос строки в ячейке по комбинации клавиш Shift+Enter" />

        <b>А также другие мелкие ошибки</b>\n
        • Исправлены различные мелкие ошибки для повышения стабильности и удобства использования.
        `,
    },
    {
        date: '2023-10-18:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Исправлена ошибка, при которой происходило удаление пользователя из задачи, если он добавлял в нее участника.
        \n
        • Исправлена ошибка, при которой нельзя добавить автора участником в дополнительный канал задачи.
        \n
        • Добавлена кнопка "Скопировать" для поля название проекта в задаче.

        <b>Бюджетирование</b>\n
        • Исправлена ошибка, при которой при смене МВЗ не менялся код МВЗ до отправки изменения на согласование.
        \n
        • Исправлена ошибка, при которой слетал курсор при упоминании пользователя через @ в комментариях к строке.
        \n
        `,
    },
    {
        date: '2023-09-29:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Значительно переработали внешний вид и организацию пространства внутри задачи: теперь интерфейс выглядит более современным и легким. Мы улучшили расположение элементов, добавили новые иконки и цвета, чтобы пользователи могли интуитивно ориентироваться, а также укорили время отклика системы на разные действия и исправили ряд ошибок. Если у вас есть вопросы или вы хотите оставить отзыв, пожалуйста, напишите в нашу поддержку support@sbermarketing.ru
        <img src="/static/release_notes/2023-09-29:00:00:00+0300/projects1.png" alt="Обновленный интерфейс пространства задачи" />
        \n
        • Исправлена ошибка при отображении календаря проектов.
        \n
        • Исправлена ошибка, которая возникала при скачивании файлов в формате .jpeg и .png.
        \n
        • Исправлена ошибка, при которой отображались доступные проекты со статусом "Завершен" при активации фильтра "Текущие, задачи закрыты".
        \n
        • Скрыта кнопка "Рассчитать" для брифов "Событийный проект 1-3 категории" при работе с брифами.

        <b>Бюджетирование</b>\n
        • Исправлены ошибки и ускорена работа фильтров в таблице Исполнения.
        \n
        • Ускорена загрузка фильтров в модуле корректировок.
        \n
        • Исправлена ошибка, при которой длинное название МВЗ не помещалось на карточке переноса плана.
        \n
        • Добавлена возможность копирования остатка по году (план-резерв год) в карточке переноса планового бюджета.

        <b>Заявка на креатив</b>\n
        • Исправлена проблема, из-за которой Исполнителю от Агентства показывались суммы из ID источников. Теперь бюджеты скрыты, но ID источников остаются видимыми.
        \n
        • Изменена логика согласования строк. Теперь зеленая галочка показывает, что строка считается согласованной и можно начинать делопроизводство. Зеленая галочка автоматически проставляется для утвержденных строк, если в них есть хотя бы один ID источника и хотя бы один ID исполнения. Желтая галочка указывает, что строка согласована, но еще не окончательно утверждена. Сумма по всем строкам с желтой или зеленой галочкой отображается как согласованная сумма. Согласующий эксперт ЗНК может проставить желтую галочку в любое время без каких-либо условий. Новые статусы согласования помогут лучше контролировать процесс.
        <img src="/static/release_notes/2023-09-29:00:00:00+0300/creative1.png" alt="Измененная логика согласования строк" />
        \n
        • Добавлены статусы актов в строке ЗНК. Теперь можно отслеживать статус каждой отдельной строки в таблицах "Тариф", "Производство" и "АК". Логика работы актов будет совершенствоваться и дорабатываться в будущих релизах.
        \n
        • В таблицах ЗНК добавлена функция контекстного меню, которая позволяет копировать и вставлять значения ячеек, а также выполнять другие действия с ячейками и их содержимым. В будущих обновлениях будем улучшать функционал и упрощать работу с выделением ячеек и контекстным меню.
        <img src="/static/release_notes/2023-09-29:00:00:00+0300/creative2.png" alt="Контекстное меню в таблице ЗНК" />
        \n
        • Реализована подсветка измененных ячеек только после того, когда заявка отправлена на согласование. Это поможет согласующим заметить важные изменения и избежать случайного согласования без их просмотра.
        \n
        • Файлы в правом сайдбаре группируются по таблицам Тарифы и Производство и их можно скачивать по отдельности. Теперь удобно определить, к какой группе файлов относится каждый файл, и скачать только нужные файлы.
        <img src="/static/release_notes/2023-09-29:00:00:00+0300/creative3.png" alt="Группировка файлов по таблицам Тарифы и Производство в сайдбаре" />
        \n
        `,
    },
    {
        date: '2023-09-14:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Добавлена возможность прикреплять файлы в ответах на комментарии (в обсуждениях).
        \n
        • Исправлена ошибка, при которой при упоминании нескольких пользователей через правый сайдбар они отображались слитно.
        \n
        • Добавлена кнопка для удобного открытия задачи в текущей вкладке в компактном режиме отображения карточек.
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/projects1.png" alt="Кнопка открытия задачи в текущей вкладке в компактном режиме отображения карточек" />

        <b>Бюджетирование</b>\n
        • Восстановлено ограничение при создании активностей в Бюджетировании: сотрудники Центрального аппарата при создании строки могут выбрать в поле ЦА/ТБ (территория) только ЦА, а сотрудники Территориальных банков - только свой банк.
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/budgets1.png" alt="Ограничение поля ЦА/ТБ при создании активности в Бюджетировании" />
        \n
        • Реализована возможность копирования суммы итогов по выбранным ID.
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/budgets2.png" alt="Копирование сумм итогов по выбранным ID" />
        \n
        • Добавлена возможность выбрать "Нет ID источника" в шаблоне задачи для "Согласование нейминга и ID".
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/budgets3.png" alt="Пункт 'Нет ID источника' в шаблоне задачи для 'Согласование нейминга и ID'" />
        \n
        • Добавлена колонка "Номер корректировки из САП" в шаблоны для выгрузки всех видов корректировок.
        \n
        • Реализована сортировка по возрастанию ID в выгрузке всех строк таблицы Исполнения (при нажатии на кнопку "Скачать без учета фильтров").
        \n
        • Исправлена ошибка в Планировании, при которой поиск по фильтрам сбрасывался после нажатия на чек-бокс.
        \n
        • Исправлена ошибка при отображении исторических данных в карточках корректировок и при выгрузке в некоторых сценариях работы.

        <b>Заявка на креатив</b>\n
        • Добавлена возможность выгрузки таблиц ЗНК в формате Excel с дополнительными листами "Информация" и "Участники".
        \n
        • В таблице ЗНК теперь выделены все важные суммы жирным шрифтом для большей наглядности.
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/creativeRequests1.png" alt="Важные суммы выделены жирным" />
        \n
        • Добавлена индикация закрепленных колонок в таблице ЗНК.
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/creativeRequests2.png" alt="Индикация закрепленных колонок" />
        \n
        • Исправлена логика расчета фактической суммы в ЗНК, теперь сумма рассчитывается независимо от прикрепленного ID источника.
        \n
        • В таблице "Расчет финансирования" теперь подсвечивается разница бюджетов при отрицательном значении дельты.
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/creativeRequests3.png" alt="Подсвечивание отрицательная разница бюджетов в таблице 'Рассчет финансирования'" />
        \n
        • Добавлена возможность редактирования ID Исполнения в строке таблицы АК.
        \n
        • Исправлена ошибка отсутствия текущего контракта в списке контрактов при создании заявки на креатив.
        \n
        • Добавлена кнопка "Скачать файл" внутри ячеек ЗНК.
        <img src="/static/release_notes/2023-09-14:00:00:00+0300/creativeRequests4.png" alt=Кнопка 'Скачать файл' внутри ячеек ЗНК" />
        \n
        • Сумма в строке таблицы АК теперь обнуляется при выборе юр. лица "ООО С-Маркетинг".
        \n
        `,
    },
    {
        date: '2023-08-29:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Добавлена возможность скопировать название для поля "Название проекта (комментарий для SAP)" в карточке прикрепленной ID бюджета к проекту.
        <img src="/static/release_notes/2023-08-29:00:00:00+0300/projects1.png" alt="Кнопка копирования названия проекта в карточке прикрепленной ID бюджета к проекту" />
        \n
        • Исправлена ошибка, при которой не срабатывала отметка о выполнении этапа в проекте.
        \n
        • Исправлена ошибка, при которой после обновления страницы в шаблоне этапов изменялся порядок этапов.
        \n
        • Исправлена ошибка, при которой при смене типа проекта не изменялся шаблон этапов.
        \n
        • Исправлена ошибка, при которой не отображались значения полей Заказчик и Сегмент для задач "Согласование ID бюджет".
        \n
        • Добавлена возможность для ответственного по проекту просматривать и редактировать черновики задач в проекте.

        <b>Бюджетирование</b>\n
        • Проведено ускорение таблицы бюджетирования в Исполнении.
        \n
        • Исправлена работа шаблонов брифов в Планировании.

        <b>Заявка на креатив</b>\n
        • Добавили новую роль "Доступ к сводной таблице ЗНК", чтобы предоставить доступ к сводной таблице ЗНК для финансистов от Исполнителя.
        \n
        • Добавили новую итоговую строку в таблицу АК, так же, как это сделано для таблиц Тарифы и Производство.
        \n
        • Добавили функцию проверки на наличие ID бюджета в строках ЗНК. Если ID источника или ID исполнения отсутствует в строке ЗНК, кнопка "согласовать и закрыть" будет деактивирована.
        \n
        • Добавили функцию email-уведомления всех участников ЗНК о загрузке вложенных файлов в ячейки "Смета Биддинг" и "Результат" работ. Это поможет всем оставаться в курсе без необходимости вручную сообщать об изменениях.
        \n
        • Улучшили логику добавления новых участников в ЗНК.
        \n
        • Улучшили логику названия новых строк в ЗНК. Первая строка в таблице Тарифы теперь будет использовать название проекта, а последующие строки будут использовать название первой отредактированной строки в производстве. Кроме того, мы добавили возможность для пользователей редактировать название строки в таблице АК.
        \n
        • В таблице АК реализовали возможность редактирования поля "Название проекта/РК".
        \n
        • Исправили ошибку со скачиванием таблицы ЗНК.
        \n
        • Исправили ошибку, из-за которой в боковой панели появлялось несколько файлов при загрузке файла.
        \n
        • Исправили ошибку, из-за которой дельта некорректно отображалась в сводной таблице ЛОТ1.
        \n
        • Исправили ошибку, которая не позволяла сохранять название юридического лица в ЗНК.
        \n
        `,
    },
    {
        date: '2023-08-11:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Исправлена ошибка, при которой не сохранялось исходное название файла при скачивании.
        \n
        • Исправлена ошибка при скачивании архива ("Скачать все файлы"), содержащего файлы форматов .png или .jpeg.
        \n
        • Реализована возможность осуществлять поиск пользователя по формату Фамилия_Имя и Имя_Фамилия при добавлении участника в задачу.
        \n
        • Реализована возможность перехода из уведомления о новом комментарии в почте в дополнительный канал задачи, где был оставлен комментарий.

        <b>Бюджетирование</b>\n
        • Ускорена работа раздела "Корректировки" в модуле Бюджетирование.

        <b>Заявка на креатив</b>\n
        • Реализована механика автоматического "редиректа" на страницу ЗНК из бюджетирования с автоматическим закрытием страницы с попапом прикрепления ID исполнения.
        \n
        • Исправлена ошибка, при которой поиск Участников для добавления в ЗНК был не чувствителен к регистру.
        \n
        • Реализовано автоматическое прикрепление ID источников из добавленных в параметрах ЗНК к строкам в таблицах ЗНК, т.к. в большинстве случаев все ID источники из параметров прикрепляются ко всем строкам ЗНК.
        \n
        • Также исправлен ряд технических ошибок.
        `,
    },
    {
        date: '2023-07-25:00:00:00+0300',
        message: `
        <b>Бюджетирование</b>\n
        • Реализована логика мультисвязей справочников при создании строки бюджета и при работе с таблицами Планирования и Исполнения: доступные для выбора значения в полях справочников предлагаются системой на основе уже ранее выбранных значений. В случае, если доступно единственное значение, система заполнит соответствующее поле автоматически.
        <img src="/static/release_notes/2023-07-25:00:00:00+0300/budget1.gif" alt="Мультисвязи справочников" />
        \n
        • Исправлено отображение кнопок "Согласовать / Отклонить" для Согласующих бюджетирования в Планировании 2024.
        <img src="/static/release_notes/2023-07-25:00:00:00+0300/budget2.png" alt="Отображение кнопок согласующих бюджетирования" />
        \n
        • Добавлена колонка "Код" для справочника "Канал" в Планировании.
        <img src="/static/release_notes/2023-07-25:00:00:00+0300/budget3.png" alt="Новая колонка Код Канала" />
        \n
        • Добавлена возможность фильтрации строк без тегов в таблицах Планирования и Исполнения (в фильтре добавлено "Значение отсутствует").
        <img src="/static/release_notes/2023-07-25:00:00:00+0300/budget4.png" alt="Фильтрация строк без тегов" />
        \n
        • Реализован числовой формат данных для колонок "ID исполнения" и "Квартал" в выгрузке Корректировок.

        <b>Сервис уведомлений</b>\n
        • Реализована возможность гибко управлять уведомлениями в "Проектах и задачах" и "Бюджетировании":
        &nbsp;&nbsp;&nbsp;&nbsp;◦ включать/выключать получение уведомлений на почту и в ленту новостей по конкретным активностям,
        &nbsp;&nbsp;&nbsp;&nbsp;◦ управлять уведомлениями в выбранные часы, дни недели,
        &nbsp;&nbsp;&nbsp;&nbsp;◦ получать на почту еженедельный отчёт по активным проектам и задачам.
        <img src="/static/release_notes/2023-07-25:00:00:00+0300/notifications1.gif" alt="Механика управления нотификациями" />
        `,
    },
    {
        date: '2023-07-13:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Исправлена проблема с получением уведомления и выделением текста при упоминании пользователя в комментариях через @.
        <img src="/static/release_notes/2023-07-13:00:00:00+0300/projects1.png" alt="Исправлена ошибка с получением и форматированием упоминаний пользователя" />
        \n
        • Исправлено отображение кнопки привязки строк бюджета 2024 года к проектам.

        <b>Заявка на креатив</b>\n
        • Реализована логика, при которой пользователь, назначенный участником ЗНК, автоматически становится участником родительского проекта, к которому относится заявка на креатив.
        \n
        • В виджете расчета финансирования в колонке "Факт. стоимость + Комиссия без НДС" добавлено отображение копеек 00, возможность копирования значений, а также добавлена новая колонка "Согласованная факт. стоимость без НДС". Итоговое значение новой колонки рассчитывается как сумма всех строк в статусе "Согласовано"  по колонке "Фактическая стоимость без НДС" в таблицах "Креатив", "Производство" + "Сумма комиссия без НДС" в таблице "АК".
        <img src="/static/release_notes/2023-07-13:00:00:00+0300/creative1.png" alt="Колонка «Согласованная факт. стоимость без НДС»" />
        \n
        • В таблице "АК" агентская комиссия в механике расчета агентской комиссии убрано правило, что расчет агентской комиссии происходит только после согласования строки. Теперь расчет агентской комиссии в "АК" осуществляется после появления значения  в колонке "Фактическая стоимость без НДС" в таблице "Производство".
        \n
        • Реализована механика, при которой в таблицах "Креатив", "Производство", "АК" любые изменения ячеек подсвечиваются синим цветом. При наведении на измененную ячейке отображается история последнего изменения ячейки: Имя Фамилия пользователя, который внес изменение, дата и время изменения, исходное значение ячейки и текущее значение ячейки.
        <img src="/static/release_notes/2023-07-13:00:00:00+0300/creative2.png" alt="Подсвечивание изменений по ячейке ЗНК" />
        \n
        • Реализована функция закрепления, скрытия столбцов таблиц "Креатив", "Производство", "АК".
        <img src="/static/release_notes/2023-07-13:00:00:00+0300/creative3.png" alt="Меню скрытия таблиц «Креатив», «Производство», «АК»" />
        \n
        • В Сводной таблице ЗНК в автоматически сгенерированных строках в колонке "Согласованная факт. ст-сть ЗНК" реализована возможность копировать значение.
        `,
    },
    {
        date: '2023-06-30:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Добавлены инструменты форматирования текста комментариев внутри задачи и правом сайдбаре.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/projects1.png" alt="Инструменты форматирования текстов комментария" />
        \n
        • Реализован отдельный шаблон для задач с видом работ "Согласования ID бюджета", включающий необходимые поля для упрощения согласования.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/projects2.png" alt="Шаблон согласования ID бюджета в окне создания задачи" />
        \n
        • Реализована возможность открытия задач и проектов в текущей вкладке браузера при нажатии на кнопку "Открыть проект" и "Открыть задачу", а также увеличена ширина правого сайдбара для проектов и задач, исправлена проблема с отображением на широких экранах.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/projects3.png" alt="Кнопка открытия задач и проектов в текщей вкладке браузера" />
        \n
        • Раздел с фильтрами в проектах и задачах перенесен в отдельный интерфейс.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/projects3.png" alt="Новый интерфейс фильтра проектов и задач" />

        <b>Заявка на креатив</b>\n
        • При переходе из формы создания ЗНК в таблицу ЗНК правый сайдбар открыт по умолчанию на вкладке "Комментарии/Общее".
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/creativeRequest1.png" alt="Автоматическое открытие сайдбара ЗНК на вклдке «Комментарии/Общее»" />
        \n
        • Реализована возможность перехода из заявки на креатив в сводную таблицу ЗНК в строку, которая создана на основе заявки на креатив.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/creativeRequest2.png" alt="Автоматическое открытие сайдбара ЗНК на вклдке «Комментарии/Общее»" />
        \n
        • В таблицы "Тариф", "Производство" добавлены итоговые значения.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/creativeRequest3.png" alt="Итоговые значения в таблицах «Тариф» и «Производство»" />
        \n
        • Реализована механика размещения курсора в ячейке в месте первого клика по ячейке.
        \n
        • В сводной таблице ЗНК добавлена колонка «Согласованная фактическая стоимость ЗНК» в которой отображается согласованная фактическая сумма без НДС из заявки на креатив.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/creativeRequest4.png" alt="Кнопка отображения согласованной фактической суммы без НДС из ЗНК" />
        \n
        • В сводной таблице ЗНК реализована возможность скрыть / отобразить столбцы таблицы.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/creativeRequest4.png" alt="Фильтры по столбцам в таблице ЗНК" />

        <b>Брифы</b>\n
        • В схеме брифа появилась возможность настраивать функционал для группы переключателей (радиокнопки / чекбоксы) с целью вывода дополнительного набора дочерних областей и полей при выборе того или иного значения.
        <img src="/static/release_notes/2023-06-30:00:00:00+0300/brief1.png" alt="Настройка функционала для группы переключателей с целью вывода дополнительного набора дочерних областей и полей при выборе того или иного значения" />
        \n
        • Реализована возможность задавать информационные подсказки (тултипы) для блоков и полей брифа.
        \n
        • Расширен функционал для текстовых полей: возможность указывать в поле только ссылки, латинские символы, а также автоматически инициировать поле с текущей датой по умолчанию (для типа «Календарь»).
        \n
        • Добавлена возможность вывести значения для ID строки бюджета (по аналогии связи проекта и ID) в схеме брифа.
        \n
        • Доработана выгрузка схемы брифа в файл эксель с учётом нового функционала и добавлен автоматический перенос текста внутри ячеек строки выгруженного файла.
        Вложенные.
        `,
    },
    {
        date: '2023-06-16:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Добавлена возможность скачать сразу все файлы, содержащиеся во вложении в комментарии.
        <img src="/static/release_notes/2023-06-16:00:00:00+0300/projects1.png" alt="Функция «Скачать все вложения комментария»" />
        \n
        • Добавлена индикация о прогрессе загрузки файлов в комментарии.
        <img src="/static/release_notes/2023-06-16:00:00:00+0300/projects2.png" alt="Индикация процесса загрузки файлов комментария" />
        \n
        • Исправлена ошибка, которая возникала при удалении вложения в комментарии.
        <img src="/static/release_notes/2023-06-16:00:00:00+0300/projects3.png" alt="Окно подтверждение удаления вложения комментария" />
        \n
        • Исправлена ошибка, при которой происходило зависание страницы при удалении комментария.
        \n
        • Исправлена ошибка, при которой на карточке проекта отображалось дважды название организации вместо блока.

        <b>Заявка на креатив</b>\n
        • В Форме создания заявки на креатив реализована возможность множественного выбора исполнителей из списка пользователей организации «СберМаркетинг» с ролью "Исполнитель заявки на креатив".
        <img src="/static/release_notes/2023-06-16:00:00:00+0300/creative1.png" alt="Выбор нескольких исполнителей ЗНК" />
        \n
        • В Форме создания заявки на креатив реализована механика обратной зависимости Дивизиона бизнес-заказчика и Блока. Когда пользователь выбирает Дивизион, в результате, подтягивается соответствующий Блок, которому относится выбранный дивизион.
        \n
        • В таблицах “Тариф”, “Производство” в ЛОТ1 реализовано автозаполнение единственного прикрепленного ID источника.
        \n
        • В таблице “Производство” в ЛОТ1 реализована функция подсказки названия юридического лица при вводе символов, которые содержатся в его названии.
        <img src="/static/release_notes/2023-06-16:00:00:00+0300/creative2.png" alt="Меню подсказки названия юридического лица" />
        \n
        • В таблице "АК" заявки на креатив реализована механика автоматического создания строки таблицы и ее автозаполнения.
        \n
        • В таблице "АК" список ID источников автоматически подтягиваются ID источники, которые указаны в таблице «Производство» для строк ЗНК с 3-ми лицами (кроме ООО С-Маркетинг).
        \n
        • В таблице "АК" заявки на креатив в колонке "Сумма комиссии без НДС" реализован автоматический расчет размера комиссии как произведение суммы «Фактическая стоимость без НДС» из таблицы «Производство» по согласованным строкам ЗНК, в которых указаны 3-и лица (кроме ООО С-Маркетинг).
        \n
        • В сводной таблице ЗНК реализовано автоматическое раскрытия правого сайдбара заявки на креатив для строк, которые созданы автоматически на основе заявок на креатив. Правый сайдбар по умолчанию открывается на вкладке “Комментарии” - Общий. В меню строки ЗНК добавлена кнопка "Показать информацию по ЗНК". При клике на которую открывается правый сайдбар строки ЗНК.
        <img src="/static/release_notes/2023-06-16:00:00:00+0300/creative3.png" alt="Кнопка «Показать информацию по ЗНК»" />
        \n
        • В сводной таблице ЗНК в колонке «Год» реализовано автоматическое отображение текущего года по дате создания строки и подстроки с возможностью изменения года из выпадающего списка.

        <b>Бюджетирование</b>\n
        • В разделе корректировок исправлена ошибка при работе вертикального скролла в уменьшенном масштабе отображения страницы.
        `,
    },
    {
        date: '2023-06-02:00:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Добавлена возможность вставлять картинки в комментарии из буфера обмена при нажатии Ctrl + V.

        <b>Заявка на креатив</b>\n
        • Реализована функция "Скачать таблицу", с помощью котрой пользователь может выгрузить в один Excel файл все 3 таблицы заявки на креатив, а именно «Тариф», «Производство», «АК» с учетом форматов ячеек и расположения колонок с деньгами друг под другом.
        <img src="/static/release_notes/2023-06-02:00:00:00+0300/creativeRequest1.png" alt="Функция «Скачать таблицу»" />
        \n
        • В таблицах заявки на креатив для строк в статусе «Удалено» применен эффект зачеркнутого текста, который наглядно показывает, что строка не активна.
        <img src="/static/release_notes/2023-06-02:00:00:00+0300/creativeRequest2.png" alt="Форматирование удаленных строк" />

        <b>Бюджетирование</b>\n
        • В разделе корректировок добавлены фильтры "МВЗ (Дивизион)" и "ЦЗ" с возможностью поиска нужного значения для отображения.
        <img src="/static/release_notes/2023-06-02:00:00:00+0300/budget1.png" alt="Фильтры «МВЗ (Дивизион)» и «ЦЗ» в корректировках бюджетирования" />
        \n
        • Добавлена возможность осуществлять отдельно выгрузку согласованных внешних корректировок и параметров строки (ключевых аналитик: Блок, МВЗ, ЦЗ, ЦА/ТБ (территория), Территория, Статья). При нажатии на кнопку "Скачать все доступные" скачиваются все доступные корректировки плана.
        <img src="/static/release_notes/2023-06-02:00:00:00+0300/budget2.png" alt="Выгрузка согласованных внешних корректировок и корректировок параметров строки" />
        \n
        • В таблице бюджетирования во всех колонках, содержащих числовые данные, добавлена возможность отображения строк по условиям “больше”, “меньше”, “больше или равно”, “меньше или равно” введенного в поле значения (по аналогии с эксель).
        <img src="/static/release_notes/2023-06-02:00:00:00+0300/budget3.png" alt="Числовые фильтры в таблицах бюджетирования" />
        \n
        • Добавлена возможность отображения только строк с бюджетом, только строк без бюджета и только строк с отрицательным остатком по факту (строки с отрицательной разностью план-факт).
        <img src="/static/release_notes/2023-06-02:00:00:00+0300/budget4.png" alt="Измененные фильтры по строкам в таблицах планирования/исполнения бюджета" />
        \n
        • В модуле Бюджетирование во вкладке Исполнение в таблице добавлены колонки "Итого План, тыс. руб.", "Итого Резерв, тыс. руб.", "Итого Факт, тыс. руб." по кварталам, во вкладке Планирование в таблице добавлены колонки "Итого План, тыс. руб." по кварталам.
        \n
        • В шаблоне файла выгрузки корректировок изменено название столбца «Дата создания, (ММ//ДД/ГГГГ), удалена подпись столбцов латинскими буквами, удалены столбцы: "Название статьи Источника", "Название статьи Получателя", "ПЦП/ТБ/ЦА", "ПЦП/ТБ/ЦА".
        \n
        • В шаблоне импорта строк бюджета из Excel в MRM Планирование добавлены колонки «Итого план, тыс. руб» и «Факт предыдущего периода».
        `,
    },
    {
        date: '2023-05-23:00:00:00+0300',
        message: `
        <b>Заявка на креатив</b>\n
        • На этапе создания заявки на креатив обновлена механика прикрепления ID источника к заявке на креатив из таблицы Исполнения без шага согласования связи ID источника с ЗНК и с автоматическим перенаправлением пользователя из бюджетирования обратно в заявку на креатив.
        <img src="/static/release_notes/2023-05-23:00:00:00+0300/creative1.png" alt="Обновленная механика прикрепления ID источника к заявке на креатив" />
        \n
        • На этапе «Заполнение (заказчик)» добавлен виджет «Расчет финансирования ID источников», с помощью которого заказчик принимает решение о достаточности средств в ID источниках для финансирования заявки на креативы. Система автоматически рассчитает итоговую разницу (дельту) между Планом и Резервом средств, которые выделены на покрытие фактической стоимости без НДС с комиссией агентства.
        <img src="/static/release_notes/2023-05-23:00:00:00+0300/creative2.png" alt="Виджет «Расчет финансирования ID источников»" />
        \n
        • В таблице ЗНК у пользователя реализована возможность оставить комментарий к конкретной ячейке таблицы с подсветкой непрочитанных комментариев, а также реализована группировка комментариев по строкам заявки на креатив с возможностью пометить комментарий избранным. Непрочитанный комментарий становится прочитанным при наведении курсора на комментарий.
        <img src="/static/release_notes/2023-05-23:00:00:00+0300/creative3.png" alt="Комментарий к конекретной ячейке таблицы ЗНК" />
        \n
        • В таблице заявки на креатив для строк в статусе “Акт закрыт” реализовано изменение фона строки на зеленый.
        <img src="/static/release_notes/2023-05-23:00:00:00+0300/creative4.png" alt="Изменение цвета для строк в статусе «Акт закрыт»" />

        <b>Сервис уведомлений</b>\n
        • В почтовом уведомлении обновлен текст письма о транзитном пароле.
        `,
    },
    {
        date: '2023-05-04:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Для проектов и задач добавлен правый сайдбар, который содержит основную информацию по ним. Чтобы открыть сайдбар, необходимо сделать один клик по соответствующей карточке проекта или задачи.
        <img src="/static/release_notes/2023-05-04:00:00+0300/projects1.gif" alt="Сайдбар проектов и задач" />
        \n
        • В новом сайдбаре задач реализована возможность ответить на комментарии с отображением цепочки ответов. В результате клика на кнопку «Ответить» введенный ответный комментарий после опубликования отобразится под родительским.
        <img src="/static/release_notes/2023-05-04:00:00+0300/projects2.png" alt="Функционал ответов на комментарии в сайдбаре задач" />
        \n
        • Реализована возможность проставления реакций для комментариев - наведите курсор на нужный комментарий и выберите реакцию или смайл. Для одного комментария можно оставить несколько реакций.
        <img src="/static/release_notes/2023-05-04:00:00+0300/projects3.png" alt="Функционал реакций на комментарии в сайдбаре задач" />

        <b>Заявка на креатив</b>\n
        • В форме создания заявки на креатив кнопка “ЛОТ2” сделана временно неактивной.
        <img src="/static/release_notes/2023-05-04:00:00+0300/creativeRequest1.png" alt="Блокировка кнопки ЛОТ2" />
        \n
        • Исправлен баг с автоматическим отображением заявок на креатив в Сводной таблице ЗНК.
        \n
        • Для роли “Закрывающий акт ЗНК” исправлен баг с доступом к Сводной таблице ЗНК.
        \n
        • В заявке на креатив исправлен баг с доступом к созданию строк в таблице “Производство” для роли “Исполнитель ЗНК”.
        \n
        • В сводной таблице ЗНК во вкладке ЛОТ2 обновлено представление таблицы “Итого ЛОТ2” с разделением сумм на “Тариф”, “Производство” и “Неоформленное”.
        <img src="/static/release_notes/2023-05-04:00:00+0300/creativeRequest2.png" alt="Обновленный визуал таблицы ЛОТ2" />
        `,
    },
    {
        date: '2023-04-18:00:00+0300',
        message: `
        <b>Заявка на креатив</b>\n
        • В сервисе e-mail уведомлений при изменении статуса заявки на креатив реализована отправка сообщений-уведомлений по электронной почте:
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequest1.jpeg" alt="email-уведомление при измененеии статуса заявки на креатив" />
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequest2.jpeg" alt="email-уведомление при измененеии статуса заявки на креатив" />
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequest3.jpeg" alt="email-уведомление при измененеии статуса заявки на креатив" />
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequest4.jpeg" alt="email-уведомление при измененеии статуса заявки на креатив" />
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequest5.jpeg" alt="email-уведомление при измененеии статуса заявки на креатив" />
        \n
        • При упоминании пользователя в комментарии реализована отправка сообщений-уведомлений по электронной почте.
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequest6.jpeg" alt="email-уведомление при упоминании пользователя в кеомментарии" />
        \n
        • В правом сайдбаре заявки на креатив реализована возможность ответить на комментарии с отображением цепочки ответов. В результате клика на кнопку «Ответить» введенный ответный комментарий после опубликования отобразится под родительским комментарием.
        \n
        • В форме создания заявки на креатив в ЛОТ 1 на этапе создания заявки на креатив реализована логика выбора контракта организации СберМаркетинг. Список контрактов подтягивается из бизнес-административной панели организации С-Маркетинг.
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequest7.png" alt="Логика выбора контракта заявки на креатив" />
        \n
        • Настроены расширенные права доступа к функциям заявки на креатив для разных бизнес-ролей пользователей. Теперь права доступа к действиям (создание, редактирование, удаление данных) в заявке на креатив разграничены в зависимости от роли пользователя.
        \n
        • При прикреплении ID источника к заявке на креатив исправлен баг с отображением 2023 года.

        <b>Сводная таблица заявки на креатив</b>\n
        • В сводной таблице ЗНК реализована логика группировки строк ЗНК по контрактам (в т.ч строк без контракта). У пользователя есть возможность из списка выбрать один контракт согласно ролевой модели и отфильтровать все строки ЗНК, которые принадлежат выбранному контракту. Контракт для строк заявки на креатив, которые созданы автоматически на основе ЗНК должен подтягиваться из формы создания ЗНК и не редактируется в сводной таблице ЗНК. Если строка ЗНК в сводной таблице ЗНК создана вручную, то для пользователя должна быть реализована функция изменения контракта на другой контракт организаций с контрактом или выбор признака “Без контракта”.
        <img src="/static/release_notes/2023-04-18:00:00+0300/creativeRequestTable1.png" alt="Логика группировки строк заявки на креатив по контрактам" />
        \n
        • В сводной таблице ЗНК реализовано автоматическое создание строк ЗНК, данные в которые подтягиваются из заявок на креатив. Если изменить параметры родительской ЗНК, то в дочерней строке ЗНК в сводной таблице эти параметры также обновятся. Подстроки поручений создаются и заполняются вручную.

        <b>Бизнес-административная панель</b>\n
        • Добавлен раздел “Контракты” с возможностью создания, редактирования и архивации контрактов заявки на креатив.
        <img src="/static/release_notes/2023-04-18:00:00+0300/businessAdmin1.png" alt="Раздел контракты" />
        `,
    },
    {
        date: '2023-03-03:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Выбранное значение в фильтрах проектов и задач располагается в верхней части списка значений фильтра.
        <img src="/static/release_notes/2023-03-03:00:00+0300/projects1.png" alt="Сортировка выбранных значений в фильтрах" />
        \n
        • В интерфейсе добавления участников в задачу ранее добавленные пользователи располагаются в верхней части списка.
        <img src="/static/release_notes/2023-03-03:00:00+0300/projects2.png" alt="Сортировка добавленных в задачу участников" />
        \n
        • Добавлена возможность удаления дополнительных каналов в задачах для авторов этих каналов.
        <img src="/static/release_notes/2023-03-03:00:00+0300/projects3.png" alt="Функционал удаление дополнительных каналов" />
        \n
        • Реализована возможность у Ответственного по проекту видеть и удалять задачи в статусе “Черновик”, ранее такая возможность была только у Автора проекта.
        \n
        • Реализована возможность дублирования и удаления блока и набора полей в брифе проекта и задачи.
        <img src="/static/release_notes/2023-03-03:00:00+0300/projects4.png" alt="Интерфейс дублирования полей брифа" />

        <b>Бюджетирование</b>\n
        • Реализована механика, при которой при создании или редактировании строки бюджета в Исполнении и Планировании значение Справочника проставляется автоматически, если для выбора доступно его единственное значение.

        <b>Заявка на креатив (ЗНК), сводная таблица ЗНК</b>\n
        • Во всех колонках  добавлена возможность фильтрации.
        \n
        • В таблицах ЛОТ 1, ЛОТ 2 реализована возможность закрепления колонок.
        \n
        • Таблица ЛОТ 1 и ЛОТ 2 вынесены на разные вкладки с доработанной механикой прокрутки.
        \n
        • В таблицах ЛОТ 1 и ЛОТ 2 добавлены следующие колонки: «Год» с выпадающим списком годов, "Автор строки ЗНК / поручения", которое содержит ФИО автора строки и дату создания строки ЗНК в формате dd.mm.yyyy
        \n
        • В таблицах ЛОТ 1 и ЛОТ 2 в колонках  «ID исп» и «Комментарий…» реализована возможность редактирования ячеек строк ЗНК и строк поручений.
        \n
        • В таблице ЛОТ 2 добавлены следующие колонки: "Блок" с выпадающим списком справочников "Блок"; “Сводное название" с автоматической генерацией.
        \n
        • В таблицах ЛОТ 1, ЛОТ 2 для строк ЗНК в статусе "Заявка закрыта" и строк поручений в статусе "Оплачено" реализовано автоматическое изменение фона на зеленый.
        \n
        • В таблице ЛОТ 2  индикатор из ячейки “Лимит по протоколу” перенесен в ячейку “Резерв по ID исполнения без НДС”.
        \n
        • В таблицах ЛОТ 1, ЛОТ 2 в колонке "Статус ЗНК" в выпадающем списке статусов добавлено значение "Правки к ЗНК" после статуса "Согласование строки".
        \n
        • В таблице “Итого ЛОТ 1” итоговые суммы “На подписи” и “В оплате” вынесены в отдельные пункты.
        \n
        • В таблицах ЛОТ 1, ЛОТ 2 в колонке "Тип документа" из выпадающего списка убрано значение "Выкупы".
        \n
        • В таблицах ЛОТ 1, ЛОТ 2 убраны колонки: "Дата отправки документов на закрытие актов", "Статус актов по поручению", "Примечание".
        \n
        • В таблицах ЛОТ 1, ЛОТ 2 реализована возможность размесить курсор в текстовой ячейке внутри содержимого текста.
        \n
        • В таблицах ЛОТ 1, ЛОТ 2 в строке поручения поле "Сумма факта с НДС" по умолчанию подтягивается ставка 20% и сумма рассчитывается умножением на коэффициент 1,2.

        <b>Заявка на креатив (ЗНК), форма ЗНК</b>\n
        • В форме создания ЗНК подключен ЛОТ2 с опцией загрузки документации по тендеру, статуса протокола и указания лимита по протоколу.
        \n
        • В таблице заявки на креатив подключен медиаплеер и превьюер медийных файлов.
        \n
        • Обновлена ролевая модель c разграничением прав доступа к функциям заявки на креатив ЛОТ 1 и ЛОТ 2.
        `,
    },
    {
        date: '2023-02-10:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Исправлена ошибка, при которой у некоторых пользователей некорректно отображалась вкладка "Доступные проекты".

        <b>Бюджетирование</b>\n
        • Исправлена ошибка, при которой происходил сброс фильтров и закреплённых столбцов в Исполнении и Планировании.
        \n
        • Исправлена ошибка, при которой указанный пользователь в поле “Руководители проекта (ответственные от ДМиК/ДУД)” в Исполнении 2023г не имел доступа к редактированию.
        \n
        • Изменен источник данных для заполнения поля “Комментарии” в выгружаемых Корректировках на “Название проекта (Комментарий для SAP)”.
        `,
    },
    {
        date: '2022-12-26:00:00+0300',
        message: `
        <b>Управление проектами</b>\n
        • Реализован аналитический дашборд для руководителей, позволяющий просматривать операционную аналитику по задачам сотрудников в их подразделениях.
        <img src="/static/release_notes/2022-12-26:00:00+0300/projects1.png" alt="Аналитический дашборд руководителя" />
        \n
        • Реализована механика оценивания задач после их завершения. Выставленные оценки отображаются в карточках задач и на дашборде руководителя.
        <img src="/static/release_notes/2022-12-26:00:00+0300/projects2.png" alt="Механика оценивания задач после их завершения" />

        <b>Заявка на креатив</b>\n
        • Реализована форма создания заявки на креатив.
        <img src="/static/release_notes/2022-12-26:00:00+0300/creative1.png" alt="Форма создания заявки на креатив" />
        \n
        • Реализована статусная модель заявки на креатив и ее строк.
        <img src="/static/release_notes/2022-12-26:00:00+0300/creative2.png" alt="Статусная модель заявки на креатив и ее строк" />
        \n
        • Настроены таблицы “Тариф”, “Производство” и “АК” в заявке на креатив.
        <img src="/static/release_notes/2022-12-26:00:00+0300/creative3.png" alt="Таблицы “Тариф”, “Производство” и “АК” в заявке на креатив" />
        \n
        • Реализована механика прикрепления ID бюджета к заявке на креатив с учетом принадлежности ID бюджета к дивизиону.
        <img src="/static/release_notes/2022-12-26:00:00+0300/creative1.png" alt="Механика прикрепления ID бюджета к заявке на креатив" />

        <b>Бизнес-административная панель</b>\n
        • Настроена и подключена ролевая модель для работы с заявкой на креатив.
        \n
        • Настроены справочники “Тарифы” в организации Сбермаркетинг.
        \n
        • Расширена возможность управления характеристиками справочников «Услуга/Материал»: добавлена возможность присваивать справочникам виды характеристик: «Стандарт», «Диапазон», «Соответствие», «Итог».
        \n
        • Реализована возможность работы с иерархической структурой блоков в схеме брифа, добавлено поле «Расчёт по блоку».
        <img src="/static/release_notes/2022-12-26:00:00+0300/businessAdmin1.png" alt="Работа с иерархической структурой блоков в схеме брифа" />
        \n
        • Реализован поиск по справочникам в бизнес-административной панели.
        \n
        • Реализован функционал перехода в модальное окно дочернего справочника из родительского с возможность внести и сохранить изменения.
        \n
        • Настроен шаблон брифа «Калькуляция спонсорских опций» с возможностью рассчитать стоимость мероприятия спонсорского проекта по заданным параметрам.
        `,
    },
    {
        date: '2022-12-09:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • Реализован онлайн редактор документов, который  поддерживает совместную работу над файлами следующих типов doc, docx, xls, xlsx, ppt, pptx, pdf.
            <img src="/static/release_notes/2022-12-09:00:00+0300/projects1.png" alt="Онлайн редактор документов" />
            \n
            • В онлайн редакторе при совместной работе над файлами .doc, .docx, .xls, .xlsx, .ppt, .pptx реализованы следующие возможности:
            &nbsp;&nbsp;&nbsp;&nbsp;◦ редактирование файла;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ сохранение обновленной версии файла;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ вставка текста/изображений/таблиц/фигур/формул/ссылок;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ копирование текста;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ комментирование в файле;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ редактирование своих комментариев для другого пользователя;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ отслеживание изменений (рецензирование) файла doc, docx для нескольких пользователей;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ морфологический поиск по-содержимому файла.
            \n
            • В онлайн редакторе при совместной работе над файлами pdf включает следующие возможности:
            &nbsp;&nbsp;&nbsp;&nbsp;◦ масштабирование;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ полоса прокрутки;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ вывод файла на весь экран.
            \n
            • Реализована настройка медиаплеера и возможность воспроизведения медийных файлов, которые вложены в комментарии в задачи. Видеофайлы в MRM теперь можно просматривать в браузере без необходимости их скачивания, а также включает следующие возможности:
            &nbsp;&nbsp;&nbsp;&nbsp;◦ старт проигрывания;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ остановка проигрывания;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ перемотка вперёд и назад;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ вывод видео на весь экран;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ вывод обложки видео на экран предпросмотра файла.
            \n
            • Реализована возможность воспроизведения gif-файлов, которые вложены в комментарии в задачи. Gif-файлы в MRM теперь можно просматривать в браузере без необходимости их скачивания.
            \n
            • Реализована возможность просмотра графических файлов, которые вложены в комментарии в задачи. Графические файлы формата PNG, JPG, JPEG в MRM теперь можно открывать в браузере без необходимости их скачивания, а также включает следующие возможности:
            &nbsp;&nbsp;&nbsp;&nbsp;◦ масштабирование;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ перемещение по увеличенному изображению;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ вывод файла на весь экран.

            <b>Заявка на креатив (ЗНК)</b>\n
            • Реализована форма создания заявки на креатив для ЛОТа 1.
            <img src="/static/release_notes/2022-12-09:00:00+0300/creative1.png" alt="Форма создания заявки на креатив для ЛОТа 1" />
            \n
            • Обновлена статусная модель заявки на креатив. Настроены 6 этапов при работе над ЗНК.
            \n
            • Обновлена ролевая модель заявки на креатив. Добавлены 6 новых ролей для работы с ЗНК:
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Инициатор заявки на креатив (системная роль);
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Автор заявки на креатив (системная роль);
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Участник заявки на креатив (системная роль);
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Исполнитель заявок на креатив (бизнес-роль);
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Эксперт по заявкам на креатив (бизнес-роль);
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Закрывающий акт ЗНК (бизнес-роль).

            <b>Бизнес-административная панель</b>\n
            • Добавлены 4 новых справочника для работы с заявкой на креатив:
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Тарифы для ЗНК;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Размер комиссии;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Вид передаваемых прав;
            &nbsp;&nbsp;&nbsp;&nbsp;◦ Территория действия прав.
        `,
    },
    {
        date: '2022-11-28:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • Реализована возможность выгрузки файлов из проекта в облачное хранилище цифровых активов (DAM).
            <img src="/static/release_notes/2022-11-28:00:00+0300/projects1.png" alt="Меню выгрузки файлов из проекта в облачное хранилище цифровых активов (DAM)" />
            \n
            • Реализована механика автоматического копирования тегов и расчета дат “Старт подготовки” и “Подведение итогов” в проектах, созданных на основе строк бюджета.
            \n
            • Исправлена ошибка, при которой не приходило уведомление на электронную почту при упоминании пользователя в чате Телеграмма.
            \n
            • Обновлена ссылка на FAQ и инструкции по системе, оптимизирована структура базы знаний.
            <img src="/static/release_notes/2022-11-28:00:00+0300/projects2.png" alt="Обновленная ссылка на FAQ по системе" />

            <b>Сводная таблица заявки на креатив</b>\n
            • Добавлены фильтры в колонках сводной таблицы.
            \n
            • В сводной таблице Заявки на креатив реализована возможность вставки чисел из Excel в ячейки через Ctrl+V
            \n
            • Ячейка “Тип документа” реализованна выпадающим списком с возможностью редактирования содержимого ячейки.
            \n
            • В таблице “ЛОТ 2” в ячейку “Лимит по протоколу” добавлен индикатор, которые отображается  в случае превышения суммы резерва над суммой лимита по протоколу.
            \n
            • Кнопка “Создать строку ЗНК” перенесена под таблицу ЛОТ 1 и ЛОТ 2.
            \n
            • В ячейке “Дельта” индикатор отрицательного значения сделан более ярким.
            \n
            • При выгрузке в эксель реализовано, что все денежные значения выгружаются в формате числа.
            \n
            • Исправлена ошибка при которой содержимое одной ячейки некорректно отображалось в другой ячейке.
            <img src="/static/release_notes/2022-11-28:00:00+0300/creative1.png" alt="Исправлена ошибка при которой содержимое одной ячейки некорректно отображалось в другой ячейке" />
        `,
    },
    {
        date: '2022-10-28:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • Реализована возможность отметить метками проекты, задачи или строки бюджета, а все отмеченные объекты отобразятся в виде диаграммы связей с учетом зависимостей между ними.
            <img src="/static/release_notes/2022-10-28:00:00+0300/projects1.gif" alt="Тегирование проектов, задач и строк бюджета" />
            \n
            • Обновлены параметры фильтров для “Задач”, “Доступных проектов” и “Моих проектов”, фильтры по умолчанию отображаются в свернутом состоянии.
            <img src="/static/release_notes/2022-10-28:00:00+0300/projects2.png" alt="Обновление фильтров для 'Задач', 'Доступных проектов' и 'Моих проектов'" />
            \n
            • Исправлена ошибка в шаблоне создания проекта, при которой календарь выбора дат изменял свой размер при переключении месяца.
            <img src="/static/release_notes/2022-10-28:00:00+0300/projects3.png" alt="Ошибка календаря выбора дат на странице создания проекта" />
            \n
            • Добавлено сообщение для пользователя при переходе по прямой ссылке в недоступный модуль.
            \n
            • Обновлен текст письма с транзитным паролем для входа в систему, добавлена инструкция для новых пользователей.
            \n
            • В сводной таблице ЗНК добавлена возможность изменять копейки в суммах столбцов "Резерв" и "Сумма факта".
        `,
    },
    {
        date: '2022-10-06:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • В разделе “Дашборды” настроен сервис “Сводная таблица заявки на креатив”, который позволяет управлять сводной таблицей для внесения данных по заявкам на креатив и сопроводительным поручениям.
            <img src="/static/release_notes/2022-10-06:00:00+0300/projects1.png" alt="Сводная таблица заявки на креатив" />
        `,
    },
    {
        date: '2022-09-14:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • Исправлена задержка отображения текста при вводе комментария в задаче.

            <b>Бюджетирование</b>\n
            • Для экспертов по бюджетированию отдела Бизнес-планирования (ЦА) организации Сбербанк открыт бюджет Исполнения 2023.
            <img src="/static/release_notes/2022-09-14:00:00+0300/budget1.png" alt="Бюджет исполнения за 2023 год" />
            \n
            • Исправлена ошибка, которая при копировании строки приводила к удваиванию суммы бюджета активности.
        `,
    },
    {
        date: '2022-09-02:00:00+0300',
        message: `
            <b>Бюджетирование</b>\n
            • Исправлено отображение выбранного бюджета для экранов с низким разрешением, а также сброс фильтров и закрепленных столбцов в таблице при изменении бюджета.
            <img src="/static/release_notes/2022-09-02:00:00+0300/budget1.png" alt="Увеличина ширина блока выбранного бюджета" />
            \n
            • Отключено обновление страницы жестами при работе с тачпадом (свайп двумя пальцами).

            <b>Управление проектами</b>\n
            • Исправлена работа уведомлений на почту, если у пользователя, отмеченного знаком @ в комментарии к задаче, в ФИО присутствовали специальные символы, например, дефис.
        `,
    },
    {
        date: '2022-08-19:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • Реализован интерфейс для управления статусом “В отпуске” и добавлены всплывающие подсказки, если сотрудник ушел в отпуск.
            <img src="/static/release_notes/2022-08-19:00:00+0300/projects1.gif" alt="Интерфейс управления стусом В отпуске" />
            \n
            • Добавлена механика, позволяющая запустить автоматическую генерацию этапов и задач в уже созданных ранее проектах.
            <img src="/static/release_notes/2022-08-19:00:00+0300/projects2.png" alt="Автоматическая генерация этапов и задач в уже существующих проектах" />
            \n

            <b>Бюджетирование</b>\n
            • Реализована возможность осуществлять выгрузку корректировок за любой период с активными фильтрами. Обновлен шаблон выгружаемого файла корректировок и добавлено выделение строк, по которым были сделаны возвраты.
            <img src="/static/release_notes/2022-08-19:00:00+0300/budget1.png" alt="Выгрузка корректировок с учетом фильтров за любой период" />
            \n
            • Добавлена возможность переноса планового бюджета из ID-источника в несколько ID-получателей.
            <img src="/static/release_notes/2022-08-19:00:00+0300/budget2.png" alt="Перенос планового бюджета из ID-источника в несколько ID-получателей" />
            \n
        `,
    },
    {
        date: '2022-07-21:00:00+0300',
        message: `
            <b>Бюджетирование</b>\n
            • Реализована механика, при которой внесенные незначительные изменения в строку не приводят к необходимости ее повторного согласования.
            \n
            • Добавлено всплывающее сообщение при изменении названия активности, в случае если изменение затрагивает несколько строк бюджета из одной активности.
            <img src="/static/release_notes/2022-07-21:00:00+0300/budget1.png" alt="Всплывающее сообщение при изменении названия активности" />
            \n
            • Реализована возможность фильтрации строк, в которые были внесены изменения, а также реализована возможность просмотра истории изменений по конкретной строке.
            <img src="/static/release_notes/2022-07-21:00:00+0300/budget2.png" alt="Механика индикации истории изменений строк" />
            \n
            • В таблице исполнения добавлена возможность учета строк с отрицательным планом при сортировке таблицы.
            <img src="/static/release_notes/2022-07-21:00:00+0300/budget3.png" alt="Учет строк с отрицательным планом при сортировке таблицы." />
            \n
            • В шаблоне Excel для импорта строк бюджета добавлен необязательный для заполнения столбец «Руководители проекта (ответственные от ДМиК/ДУД)”.
            <img src="/static/release_notes/2022-07-21:00:00+0300/budget4.png" alt="Необязательный столбец Руководители проекта в шаблоне Excel для импорта строк" />
            \n
            • Новые строки бюджета, добавленные при импорте из Excel в MRM, отображаются вверху таблицы вне зависимости от фильтров.
            <img src="/static/release_notes/2022-07-21:00:00+0300/budget5.png" alt="Отображение импортированных из Excel строк вверху таблицы" />
            \n
            • Исправлена ошибка, возникающая при переносе планового бюджета - копировалась сумма на 1 руб. больше.

            <b>Бизнес-административная панель</b>\n
            • Пользователи организаций Сбербанк ДМиК, Сбербанк ДУД и Сбербанк Бизнес-заказчики объединены в единую организацию Сбербанк.
            \n
            • Организационная структура департаментов в бизнес-административной панели организации Сбербанк обновлена и соответствует текущей структуре банка.
            \n
        `,
    },
    {
        date: '2022-06-24:00:00+0300',
        message: `
            <b>Бюджетирование</b>\n
            • Реализована механика создания дочерних строк бюджета Планирования на основе строк бюджета Исполнения текущего и прошлого периодов.
            <img src="/static/release_notes/2022-06-24:00:00+0300/budget1.png" alt="Меню создания дочерних строк бюджета Планирования на основе строк бюджета Исполнения текущего и прошлого периодов" />
            \n
            • При создании строки бюджета Планирования на основе строк бюджета Исполнения реализовано отображение суммы факта строки бюджета за предыдущий период.
            <img src="/static/release_notes/2022-06-24:00:00+0300/budget2.png" alt="Поле ввода факта предыдущего периода" />
            \n
            • Во вкладке “Планирование” в информационной части строки бюджета реализовано отображение сумм плана и факта родительской строки бюджета за предыдущий период.
            <img src="/static/release_notes/2022-06-24:00:00+0300/budget3.png" alt="Отображение сумм плана и факта родительской строки бюджета за предыдущий период в информационной части строки бюджета" />
            \n
            • Для бизнес-роли “Куратор бюджетирования” убрана возможность создания строк бюджета.

            <b>Бизнес-административная панель</b>\n
            • Реализована механика управления статусами бюджетов, которая позволяет бизнес-администратору системы перевести бюджет из Планирования в Исполнение, а также в архив.
            <img src="/static/release_notes/2022-06-24:00:00+0300/business-admin1.png" alt="Меню управления статусами бюджетов" />
            \n
            • В разделе “Бюджеты” в таблице настройки правил доступов к строкам бюджета добавлены фильтры по колонкам “Бюджет”, “Организация”, “Департамент”, “Роль”.
            <img src="/static/release_notes/2022-06-24:00:00+0300/business-admin2.png" alt="Новые фильтры по колонкам в таблице настройки правил доступок к строкам бюджета" />

            <b>Системная административная панель</b>\n
            • Реализована механика создания бизнес-ролей для модуля “Бюджетирования” и назначения им прав на просмотр, редактирование и других действий со строками бюджета.
            <img src="/static/release_notes/2022-06-24:00:00+0300/system-admin.png" alt="Меню создания бизнес-ролей для модуля “Бюджетирования”" />
        `,
    },
    {
        date: '2022-06-08:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • В форме “Новый проект” добавлена всплывающая подсказка для типа проекта “Мероприятия“ с описанием категорий.
            <img src="/static/release_notes/2022-06-08:00:00+0300/project_1.png" alt="Добавлена всплывающая подсказка для типа проекта “Мероприятия“" />
            \n
            • На странице проекта в форме “Редактирование этапов” при первичном назначении шаблона этапов добавлена возможность деактивации не актуальных этапов проекта.
            <img src="/static/release_notes/2022-06-08:00:00+0300/project_2.png" alt="Добавлена возможность деактивации неактуальных этапов при назначении шаблона этапов" />

            <b>Бюджетирование</b>\n
            • Во вкладке “Планирование” реализован сервис импорта строк бюджета из Excel в MRM и выгрузки Excel-шаблона для импорта строк.
            <img src="/static/release_notes/2022-06-08:00:00+0300/budget_1.png" alt="Добавлена механика выгрузки Excel-шаблона и импорта строк из выгруженного шаблона во вкладке “Планирование”" />
            \n
            • Во вкладках “Исполнение” и “Планирование” реализована механика отображения новых строк бюджета с меткой New по умолчанию вверху таблицы.
            <img src="/static/release_notes/2022-06-08:00:00+0300/budget_2.png" alt="Добавлена механика отображения новых строк бюджета с меткой New по умолчанию вверху таблицы" />
            \n
            • Во вкладке “Планирование” реализована дополнительная фильтрация строк бюджета по статусам “На согласовании”, “Согласованы”, “Не согласованы”, что позволяет отфильтровать строки бюджета, которые переданы на согласование согласующему бюджетирования.
            <img src="/static/release_notes/2022-06-08:00:00+0300/budget_3.png" alt="Добавлена механика фильтрации строк по соглассующим бюджетирования во вкладке “Планирование”" />
            \n
            • Во вкладке “Планирование” реализована механика индикация строк бюджета, которые содержат ошибки “Не заполнены обязательные поля”. При наведении на индикатор отображается всплывающая подсказка с перечнем незаполненных обязательных ячеек. В результате нажатия на индикатор ошибки, курсор перемещается на ближайшую не заполненную ячейку таблицы бюджетирования.
            <img src="/static/release_notes/2022-06-08:00:00+0300/budget_4.png" alt="Добавлена механика отображения индикатора “Не заполнены обязательные поля” во вкладке “Планирование”" />
            \n
            • В иформационном окне по строке бюджета Исполнения удалены не актуальные вкладки “Корректировки“ и “Файлы“, отображается только вкладка “Общая информация”.
            <img src="/static/release_notes/2022-06-08:00:00+0300/budget_5.png" alt="В информационном окне по строке удалены неактуальные вкладки “Корректировки“ и “Файлы“" />
        `,
    },
    {
        date: '2022-05-18:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • В меню MRM обновлена ссылка на инструкцию для пользователей. При нажатии на иконку “Ракета” открывается обновленная страница.
            <img src="/static/release_notes/2022-05-18:00:00+0300/1.png" alt="Обновлена инструкция для пользователей MRM" />
            \n
            • На странице брифа реализована возможность вводить числа с двумя знаками после запятой.
            \n
            • Обновлена механика уведомлений в задачах. Теперь при отключенных уведомлениях в задаче пользователь получит уведомления на почту, если он был упомянут в комментариях через @.

            <b>Бюджетирование</b>\n
            • В несколько раз увеличена скорость работы сервиса “Перенос планового бюджета”.
            \n
            • Во вкладке “Корректировки” реализована технология фиксации исходных параметров строки бюджета на дату корректировки, что дает возможность пользователю в карточке корректировки видеть параметры строки бюджета на момент внесения в нее изменений.
            \n
            • В таблицах “Планирование” и “Исполнение” при наведении на ячейки и заголовки колонок реализован вывод подсказки с их содержанием независимо от формата данных в ячейке.

            <b>Бизнес-административная панель</b>\n
            • В новом разделе “Бюджеты” реализована механика управления настройками бюджетов.
            <img src="/static/release_notes/2022-05-18:00:00+0300/2.png" alt="Новая механика управления настройками бюджета" />
            \n
            • В новом разделе “Бюджеты” реализована механика гибкого управления ролями и правами доступа пользователей к строкам бюджета “Планирование” и “Исполнение”.
            <img src="/static/release_notes/2022-05-18:00:00+0300/3.png" alt="Новая механика гибкого управления ролями и правами доступа пользователей к строкам бюджета “Планирование” и “Исполнение”" />
            \n
            • В разделах “Шаблоны брифа” и “Справочники” реализована возможность указать числа с двумя знаками после запятой, что позволяет производить более точный расчет стоимости мероприятий с использованием калькулятора мероприятий.
        `,
    },
    {
        date: '2022-04-29:00:00+0300',
        message: `
            <b>Интеграция с Telegram 2.0</b>\n
            • Улучшен пользовательский опыт при работе с файлами, сообщениями и задачами MRM через чат-бот Telegram:
            &nbsp;&nbsp;&nbsp;&nbsp;- расширена возможность загрузки в чате мессенджера файлов с большим объемом до 1,5 Гб;
            &nbsp;&nbsp;&nbsp;&nbsp;- в Telegram чат задачи добавлена инструкция “Как работать с чатом” и обновлен онбординг пользователя при создании чата;
            &nbsp;&nbsp;&nbsp;&nbsp;- реализовано автоматическое упоминание пользователей MRM из чата Telegram.
            <img src="/static/release_notes/2022-04-29:00:00+0300/telegram.gif" alt="реализовано автоматическое упоминание пользователей MRM из чата Telegram" />

            <b>Управление проектами</b>\n
            • В левой части экрана “Мои задачи”, “Доступные проекты” и “Мои проекты” добавлен фильтр по автору.
            <img src="/static/release_notes/2022-04-29:00:00+0300/project.png" alt="В левой части экрана “Мои задачи”, “Доступные проекты” и “Мои проекты” добавлен фильтр по автору" />
            \n
            • В разделе “Персональный дашборд” исправлена проблема с невозможностью отклонить связь ID строки бюджета и проекта.
            \n
            • Исправлена проблема с не отображением автора задачи в списке участников задачи.

            <b>Бюджетирование</b>\n
            • В таблицах Исполнение и Планирование обновлена механика активации фильтров.
            <img src="/static/release_notes/2022-04-29:00:00+0300/budget1.png" alt="В таблицах Исполнение и Планирование обновлена механика активации фильтров" />
            \n
            • В таблицах Исполнение и Планирование реализована механика быстрого закрепления колонок.
            <img src="/static/release_notes/2022-04-29:00:00+0300/budget2.png" alt="В таблицах Исполнение и Планирование реализована механика быстрого закрепления колонок" />
            \n
            • В таблицах Исполнение и Планирование исправлена проблема с автоматической самопроизвольной очисткой кода справочника и его наименования после сохранения и публикации строки бюджета.
            \n
            • В таблицах Планирование и Исполнение колонка "Автор строки бюджета" перенесена в конец таблицы.
            <img src="/static/release_notes/2022-04-29:00:00+0300/budget3.png" alt="В таблицах Планирование и Исполнение колонка "Автор строки бюджета" перенесена в конец таблицы" />
            \n
            • Во вкладке “Корректировки” исправлена проблема с нарушением верстки карточки корректировки.
            \n
            • Во вкладке “Корректировки” исправлена проблема с автоматическим сбросом фильтров после 5 минут бездействия пользователя в системе.
        `,
    },
    {
        date: '2022-04-07:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • Улучшена механика создания и редактирования задач.
            <img src="/static/release_notes/2022-04-07:00:00+0300/1.gif" alt="Улучшена механика создания и редактирования задач" />
            \n
            • На странице задачи в заголовок задачи добавлена дополнительная информация о дедлайне задачи, ее статус, а также кнопки активации уведомлений, чата Телеграм, редактирования параметров задачи и ее удаления.
            <img src="/static/release_notes/2022-04-07:00:00+0300/2.png" alt="На странице задачи в заголовок задачи добавлена дополнительная информация о дедлайне задачи" />
            \n
            • Исправлена ошибка с отправкой уведомления о назначении на исполнителя задачи в статусе “Черновик”.

            <b>Бюджетирование</b>\n
            • Реализована механика отображения ограниченного списка фильтров с учетом выбранного родительского фильтра.
            <img src="/static/release_notes/2022-04-07:00:00+0300/3.gif" alt="Реализована механика отображения ограниченного списка фильтров с учетом выбранного родительского фильтра" />
            \n
            • Реализован поиск названия активности без зависимости от регистра букв.
            \n
            • В виджет связи ID строки и проекта добавлена рекомендация с указанием ID строки.
            <img src="/static/release_notes/2022-04-07:00:00+0300/4.png" alt="В виджет связи ID строки и проекта добавлена рекомендация с указанием ID строки" />
            \n
            • Во вкладке “Корректировки” добавлены поля "ЦА/ТБ (территория)", "Территория" и одноименные фильтры.
            \n
            • В Планировании при удалении строки бюджета реализовано всплывающее окно для подтверждения действия пользователем.
            \n
            • В форму "Создание строки" и таблицу Исполнение добавлено поле "ФИО Заказчика", "Бизнес-цель" (не обязательные поля), в таблицу Исполнения - "Автор строки". В таблицу Планирование добавлено поле "ЦЗ" (не обязательное поле).
            \n
            • Исправлена проблема с изменением ширины колонки. При изменении ширины колонки ее название теперь отображается полностью.
            \n
            • Во вкладке Исполнение устранена ошибка с копированием содержания поля "Примечание к строке". При копировании строки бюджета поле “Примечание к строке” теперь остается пустым.
        `,
    },
    {
        date: '2022-03-18:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            • Добавлена возможность изменения дедлайна задачи для исполнителя задачи и пользователя с ролью “Директор”.
            \n
            • Доработана механика автоматического распределения задач по этапам:
            &nbsp;&nbsp;&nbsp;&nbsp;- если изменить «Тип проекта», в котором есть активные этапы и задачи, то существующие задачи будут автоматически перенесены во «Вне этапов», а этапы удалены из проекта;
            &nbsp;&nbsp;&nbsp;&nbsp;- если изменить «Шаблон этапов» проекта, в котором есть активные этапы и задачи, то существующие задачи будут автоматически перенесены во «Вне этапов», а этапы удалены из проекта;
            &nbsp;&nbsp;&nbsp;&nbsp;- если деактивировать типовой этап, в котором есть активные задачи, то они будут автоматически перенесены во «Вне этапов».
            \n
            • Исправлены баги, которые влияли на корректность работы:
            &nbsp;&nbsp;&nbsp;&nbsp;- функции связи ID бюджета с проектом;
            &nbsp;&nbsp;&nbsp;&nbsp;- функции добавления задачи в этап;
            &nbsp;&nbsp;&nbsp;&nbsp;- фильтров на странице проекта;
            &nbsp;&nbsp;&nbsp;&nbsp;- отображения этапов проекта в календаре.
            \n
            • Добавлена возможность поиска по проектам, задачам, комментариям, файлам на страницах “Персональный дашборд”, “Календарь”,  “Запуски недели”, “Бюджетирование”.
        `,
    },
    {
        date: '2022-02-18:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            - Реализовано автоматическое создание проектов на основе активностей бюджетирования 2022 года.
            - Исправлена проблема с  удалением проектов из вкладки "Мои проекты".
            - Для ответственного проекта реализована возможность добавления участников в проект.
            - На флоучарте проекта с этапами реализована подсветка задач по статусам.
              <img src="/static/release_notes/2022-02-18:00:00+0300/1.png" alt="На флоучарте проекта с этапами реализована подсветка задач по статусам" />

            <b>Бюджетирование</b>\n
            - Исправлена проблема с отображением дублей одинаковых значений в фильтре (например, в выпадающем списке фильтра убран дубль 0, в фильтре "Активности" убраны дубли одноименных активностей).
            - Реализовано автоматическое удаление лишних пробелы в начале, середине и конце названия активностей.
        `,
    },
    {
        date: '2022-02-03:00:00+0300',
        message: `
            <b>Управление проектами</b>\n
            - Упрощена механика создания и редактирования задач. Назначить исполнителя в задаче теперь возможно зная только его фамилию.
              <img src="/static/release_notes/2022-02-03:00:00+0300/1.gif" alt="Упрощена механика создания и редактирования задач" />
            - Шапка (header) страницы проекта, задачи, брифа приведена к общему единому виду, что позволяет перейти в задачу, проект или бриф из любой страницы проекта.
              <img src="/static/release_notes/2022-02-03:00:00+0300/2.png" alt="Шапка страницы проекта, задачи и брифа приведены к общему единому виду" />

            <b>Бюджетирование</b>\n
            - Исправлена ошибка “Значение не найдено”. В таблице Исполнения и при выгрузке таблицы в excel в поле “Руководитель проекта (инициаторы от ДМиК)” ФИО пользователя отображается корректно.
            - Оптимизирована скорость привязки ID бюджета с проектом. Скорость загрузки привязанных ID строк к проекту увеличена.
        `,
    },
    {
        date: '2021-12-17:00:00+0300',
        message: `
            <b>Модуль “Управление проектами”</b>\n
            - Реализована интеграция MRM с мессенджером Telegram, которая позволяет коммуницировать по задачам MRM непосредственно с телефона или декстоп клиента Telegram. Все сообщения из Telegram-чата попадут в MRM-задачу, а из MRM-задачи в Telegram-чат.
              <img src="/static/release_notes/2021-12-17:00:00+0300/1.gif" alt="Интеграция MRM с мессенджером Telegram" />
            - На странице проекта в форме “Редактирование этапов” доработана механика: если дата начала или окончания этапа находится вне периода проекта, то система отобразит сообщение “Период этапа выходит за рамки проекта”.
              <img src="/static/release_notes/2021-12-17:00:00+0300/2.png" alt="Период этапа выходит за рамки проекта" />

            <b>Модуль “Бюджетирование”</b>\n
            - Строки бюджета Планирования 2022 перенесены в Исполнение 2022: а Планирование 2022 заархивировано.  Обновлена ролевая модель с разграничением прав доступа просмотра и редактирования строк бюджета по признаку “ЦА/ТБ (территория)” и “Территория”.
              <img src="/static/release_notes/2021-12-17:00:00+0300/3.png" alt="Планирования 2022 перенесены в Исполнение 2022" />
            - В Исполнении 2022 добавлено условие: если в поле "ЦА/ТБ (территория)" указано название территориального банка, то в списке “МВЗ/Дивизион” отображается список МВЗ территориального банка.
            - В Исполнении 2022 в поле ID бюджета “Руководитель проекта” добавлены ответственные по направлениям и дивизионам.
            - Обновлен интерфейс и логика расчета функции “Массовый перенос бюджета”.
        `,
    },
    {
        date: '2021-12-03:00:00+0300',
        message: `
            <b>Модуль "Управление проектами"</b>\n
            - Интегрирована Business Process Model (BPM) с функцией автоматического создания этапов и задач для типовых проектов.
            - На странице проекта реализована возможность редактирования статуса задачи, этапа, дедлайна, исполнителя в карточке задачи.
            - На странице проекта добавлен фильтр задач, которые принадлежат к различным этапам.
            - В “Персональном дашборде” реализован аналитический дашборд для руководителей со сводными данными по проектам.
            - Подключена проверка на наличие вирусов всех загружаемых файлов в MRM.

            <b>Модуль "Бюджетирование"</b>\n
            - Во вкладке “Исполнение” в функции массового переноса бюджета исправлена логика расчета средств бюджета в строках.

            <b>Бизнес-административная панель</b>\n
            - Добавлены новые справочники: Шаблоны задач, Этапы и Шаблоны этапов.
            - Реализовано управление связями новых справочников и приоритетами (очередностью) этапов.
            - Настроен редактор процессов BPM.
        `,
    },
    {
        date: '2021-10-28:00:00+0300',
        message: `
            <b>Модуль "Персональный дашборд"</b>\n
            - В Персональном дашборде в карточке "Связь ID  и проекта" удалены активные ссылки на проект и ID. В  правом сайдбаре карточки ссылки на проект и ID остались активными.

            <b>Модуль "Управление проектами"</b>\n
            - Разработан сервис калькуляции, который позволяет формировать логику и рассчитывать стоимость мероприятия на основе данных из брифа.
            - В проектах и задачах для неактуальных справочников добавлена метка "удален".
            - В модуле "Проекты" в карточку Проекта (правый сайдбар) добавлено поле "Продукт", которое подтягивается из строки бюджета Исполнения.
            - В форме "Новый проект" для поля "Ответственный" добавлена подсказка "Контактное лицо может быть указано со стороны заказчика или исполнителя".
            - Исправлена ошибка, которая возникала при добавлении проекта в избранное.
            - Исправлена ошибка, которая возникала при заполнении дат на странице создания Проекта.

            <b>Модуль "Бюджетирование"</b>\n
            - Во вкладке Исполнение и Планирование для неактуальных справочников добавлена метка "удален".
            - В Исполнении во вкладке Корректировки в эксель-выгрузке корректировок после колонки "Комментарии" добавлена колонка "Автор корректировки".

            <b>Бизнес-административная панель</b>\n
            - Реализована возможность изменения для справочников статусов "Активный" и "Архивный".
        `,
    },
    {
        date: '2021-10-06:00:00+0300',
        message: `
            <b>Модуль "Управление проектами"</b>\n
            - Для пользователей с ролью "Исполнитель" и "Директор" реализована возможность закрытия задачи.
            - В форме создания задачи поле "Департаменты" исправлено на "Подразделение/Подрядчик".
            - Исправлена ошибка. Теперь начало недели в календаре начинается корректно с понедельника.
            - Исправлена ошибка, связанная с копированием брифа из проекта в проект и из задачи в задачу.

            <b>Модуль "Бюджетирование"</b>\n
            - Реализован массовый перенос бюджета из нескольких ячеек в одну.
            - В Исполнении и Планировании реализовано отображение кодов УВХД в выгрузке и таблице.
            - Ограничен доступ к редактированию строк бюджета Планирования для бренд-менеджеров, супервайзеров бюджетирования и согласующих бюджетирования.
            - Полный доступ  к строкам бюджета Планирования остался открытым для экспертов по бюджетированию.
            - Проведен редизайн функции "Внешние корректировки".
            - В таблице Планирование и в excel выгрузке добавлена колонка "Канал".
            - В форме "Связь ID и проекта" кнопка "Отменить" переименована в "Закрыть".

            <b>Бизнес-административная панель</b>\n
            - В каталог справочников добавлены 2 новых справочника "Группа" и "Наименование услуги/материала" для калькуляции стоимости мероприятий.
        `,
    },
    {
        date: '2021-09-17:00:00+0300',
        message: `
            <b>Модуль "Бюджетирование". Планирование.</b>\n
            - В Планировании реализован функционал переноса строки бюджета из одной активности в другую.
            - В таблицу Планирование добавлена колонка "Код МВЗ / Дивизион".
            - В лайн меню строки бюджета Планирования добавлена кнопка "Копировать строку".
            - В правом сайдбаре строки бюджета Планирования добавлена вкладка "История изменений", в которой отображается хронология изменения полей и история согласования строки.
            - Для согласующих бюджетирования кнопки "Одобрить"/ "Отклонить" и иконка согласующих бюджетирования вынесены из лайн меню строки бюджета Планирования на основной экран.
            - Для супервайзера бюджетирования кнопка "Отправить на утверждение" и иконка согласующих бюджетирования вынесены из лайн меню строки бюджета Планирования на основной экран.
            - Исправлена проблема перескакивания курсора в конец текста при редактировании комментария.
            - Расположение полей в таблице "Планирование" реализовано в том же порядке как в форме "Создать строку".

            <b>Модуль "Бюджетирование". Исполнение.</b>\n
            - В Исполнении реализован функционал переноса строки бюджета из одной активности в другую.
            - В таблицу Исполнения добавлена колонка "Код МВЗ / Дивизион".

            <b>Сервис авторизации.</b>\n
            - Решена проблема со сбросом пароля при авторизации.
        `,
    },
    {
        date: '2021-09-02:00:00+0300',
        message: `
            <b>Модуль "Проекты".</b>\n
            - Исправление: в браузерах Яндекс и Google Chrome при создании проекта календарь отображается полностью при 100% масштабе страницы.
            - Исправление: при удалении символов курсор не перескакивает в конец строки.

            <b>Модуль "Бюджетирование". Планирование.</b>\n
            - При создании или редактировании строки бюджета в полях "Руководитель проекта" и  "Комментарии" для пользователей Сбербанк Маркетинг Территориальных банков, Сбербанк ДУД, Сбербанк ДМиК, Сбербанк Бизнес-заказчики добавлена возможности выбрать руководителя проекта из другой организации.
            - Для пользователей Сбербанка Маркетинг Территориальных банков добавлена возможность выбрать дивизион, который относится к конкретному территориальному банку.
            - Кнопка "Редактировать активность" позволяет редактировать все поля через данную страницу.
            - При нажатии на кнопки "Сохранить и опубликовать" и "Сохранить" происходит переход к первому незаполненному обязательному полю.
            - Исправление: при расчете бюджета в итоговой сумме не учитываются удаленные строки.
            - Исправление: при создании новой активности доступно добавление новой строки.
        `,
    },
    {
        date: '2021-08-23:00:00+0300',
        message: `
            <b>Планирование. Модуль "Бюджетирование"</b>\n
            - В форме "Создание активности" добавлена возможность выбора и заполнения брифа для каждой отдельной строки бюджета в активности. В списке шаблонов брифов отображаются только брифы, которые доступны для пользователя конкретной организации.
            - Доработана логика согласования строк бюджета планирования по процессу Сбербанк ДУД, тригером для его запуска является значение "Мероприятие" в поле "Направление"
            - Доработана логика  Сбербанка Маркетинг территориальных банков, тригером для его запуска является "Название территориального банка" в поле "ЦА/ТБ (территория)" и принадлежность автора строки к Сбербанк Маркетинг территориальных банков.
            - Для пользователей из организации Сбербанк Бизнес-заказчики открыт доступ исключительно к строкам бюджета в планировании только для пользователей в рамках одного департамента и соответствующего блока.
            - Реализована проверка названий активностей на наличие их дублей. При создании новой активности,  если в системе уже имеется активность с идентичным названием, то пользователю отображается список уже существующих активностей в Планировании. Когда пользователь выбирает из выпадающего списка существующую активность, то система перекидывает его в форму "Редактирование активности".
            - В форме "Создание строки" поле "Сумма" и "Месяц" являются обязательными для заполнения.
            - В форму "Создание строки" и в таблицу планирования добавлено поле "Драйвер аллокации (Бизнес-блок)", которое является не обязательным для заполнения.
            - В таблице Планирования добавлена колонка "Автор строки бюджета", которая позволяет отфильтровать строки бюджета по их автору.
            - Для  пользователя - согласующего бюджетирования при отклонении им строки бюджета реализовано всплывающее окно, в котором согласующий обязан оставить комментарий с указанием причины отклонения.
            - Пользователям с ролью согласующий бюджетирования открыт доступ к редактированию строк бюджета планирования, которые ему необходимо одобрить.
            - Реализовано обновление списка согласующих бюджетирования в результате публикации строки бюджета планирования после внесения в нее изменений.

            <b>Исполнение. Модуль "Бюджетирование"</b>\n
            - В модуле Бюджетирования во вкладке "Исполнение" при копировании суммы с копейками из MRM в excel реализовано, что копируемая сумма не содержит пробелы между цифрами, а точка замена на запятую. Вставка скопированной сумму в excel распознается как число, что позволяет пользователю проводить математические операции.
            - В модуле "Бюджетирование" в Исполнении в окне "Перенос бюджета" во вкладке "Внешние источники" во вкладке "Добавить" исправлена опечатка "СТАТЬЯ ИСТОЧНИКА" и "СТАТЬЯ ПОЛУЧАТЕЛЯ".
            - Во вкладке "Корректировки" исправлена опечатка "СТАТЬЯ ИСТОЧНИКА" и "СТАТЬЯ ПОЛУЧАТЕЛЯ".

            <b>Сервис Уведомлений</b>\n
            - Для Авторов-Пользователей с ролью бренд-менеджеры в результате редактирования их строки согласующими бюджетирования, супервайзерами или экспертами по бюджетированию приходит на почту уведомление об изменении данной строки.
            - В правом сайдбаре строки вкладки "Планирование" пользователю, которого тэгнули в поле "Комментарий" и автору строки отправляется е-мейл уведомление с текстом комментария  и указания ID строки с гиперактивной ссылкой на строку бюджета.
        `,
    },
    {
        date: '2021-08-09:00:00+0300',
        message: `
            <b>Модуль "Проекты"</b>\n
            - Добавлен прелоадер для кнопки "Закрыть задачу". На экране отобразился прелоадер обработки команды смены статуса задачи. Аналогично прелоадер отображается при клике на кнопку "Вернуть в работу".

            <b>Модуль "Бюджетирование". Планирование</b>\n
            - Обновлена механика согласования строк бюджета по процессу Сбербанк ДУД и Сбербанк Маркетинг Территориальных банков с участием согласующих бюджетирования.
            - Обновлена механика согласования строк бюджета супервайзером бюджетирования в зависимости от блока.
            - В форме "Создание строки" реализованы обязательные поля. Система НЕ позволит опубликовать новые строки, если не заполнены обязательные поля.
            - Для быстрого заполнения в поле Дата старта в календаре реализована возможность прокрутки не только по месяцам, но и по годам.
            - Исправлены опечатки в правом сайдбаре строки бюджета.

            <b>Сервис нотификаций</b>\n
            - Пользователям с ролью бренд-менеджер бюджетирования, супервайзер бюджетирования, которые являются участниками процесса планирования строки бюджета приходит уведомление о статусе согласования/отклонения строки бюджета экспертом по бюджетированию. Обновлена верстка писем.
        `,
    },
    {
        date: '2021-07-15:00:00+0300',
        message: `
            <b>Главное меню</b>\n
            - В меню добавлена кнопка "Архив" для перехода в пространства файлообменника с автоматической авторизацией.
            - В меню добавлена кнопка "Qlik Sense" для перехода в дашборды QlikSense с автоматической авторизацией.

            <b>Модуль "Бюджетирования"</b>\n
            - Во вкладке "Планирование" обновлена логика отображения строк из разных департаментов и организаций.
            - Во вкладке "Планирование" обновлена логика отправления строк бюджета от бренд-менеджера на согласование соответствующему супервизору ДМиК из блока, который был указан при создании строки.
        `,
    },
    {
        date: '2021-07-01:00:00+0300',
        message: `
            <b>Справочники</b>\n
            - Справочники модулей Проектов и Бюджетирования полностью синхронизированы.

            <b>Модуль "Проекты"</b>\n
            - При создании нового проекта добавлено поле "Группа в календаре", которое позволяет отображать проекты в календаре в сгруппированном виде.

            <b>Модуль "Бюджетирование"</b>\n
            Оптимизирована логика совместного планирования активностей ДМиК и ДУД
            - Для пользователей из ДМиК и ДУД выводится общая таблица планирования активностей на основе единых справочников с возможностью согласования строк бюджета экспертами по бюджетирования из ДМиК.
            - На вкладке "Планирование" на странице создания активности кнопки "Сохранить" и "Сохранить и опубликовать" сделаны плавающими.
            - Реализована сортировка строк по ID Плана от меньшего к большему по умолчанию.
        `,
    },
    {
        date: '2021-06-15:00:00+0300',
        message: `
            <b>Раздел "Персональный дашборд"</b>\n
            <b>Доработана карточка "Связь ID и Проекта":</b>\n
            - В блоке Бюджет для поля "Название проекта (Комментарий для SAP)" реализована функция редактирования названия.
            - В блоке Бюджет добавлено поле "Активность", которая подтягивается из строки бюджета из поля "Активность".
            - В блоке "Проект" для поля "Название проекта" убрана функция редактирования и согласования.
            - В поле "Название проекта (Комментарий для SAP)" отображается последняя предложенная версия названия проекта, которая совпадает с отображаемым значением в строке бюджета в поле "Название проекта (Комментарий для SAP).
            - Не согласованная корректировка "Название проекта (комментарий для SAP)" не блокирует кнопку "Согласовать" для согласования Связи проекта и ID бюджета.

            <b>Модуль "Управление проектами"</b>\n
            <b>Изменен интерфейс страницы "Создание нового проекта":</b>\n
            - Обновлен дизайн кнопок, полей ввода и выпадающих списков.
            - Изменена верстка страницы.

            <b>Доработан сайдбар карточки проекта:</b>\n
            - Блок "Название проекта" убран из сайдбара.
            - В блоке ID бюджета вместо поле "Инициатор" отображается поле "Название проекта (Комментарий для SAP)", которая подтягивается из строки бюджета из поля "Название проекта (Комментарий для SAP)".
            - В блоке ID бюджета добавлена функция редактирования поля "Название проекта (Комментарий для SAP)".
            - В поле "Название проекта (Комментарий для SAP)" отображается последняя предложенная версия названия проекта.

            <b>Модуль "Бюджетирование"</b>\n
            <b>При создании активности в системе реализована автоматическая проверка на наличие дублей активностей</b>\n
            - Если система обнаружила, что в системе имеется активность с идентичным названием, то на экране пользователю отображается сообщение, что "Обнаружен дубль. Активность с введенным названием уже существует".
            - Если пользователь выбирает из выпадающего списка существующую активность, то система перекидывает его в форм "Редактирование активности", в которой пользователь может ознакомиться с содержанием существующей активности и создать новую строку, кликнув на кнопку "Новая строка", но при этом отредактировать существующую строку пользователь не может.
            - При поиске активностей по названию для активностей из разных годов отображается  индикация, что "активность N года".
            - Во вкладке Исполнение - Таблица при наведении на ячейку "План" отображается полная сумма с копейками со значком R (рубль).
            - Для пользователя реализована возможность копирования полной суммы с копейками из ячейки "План".
            - Во вкладке "Корректировки" для поля "Дата" сокращен формат даты до dd.mm.yy.
            - Во вкладках "Исполнение" и "Планирование" - При вводе в поиске символов в фильтре по колонке таблицы система предлагает выбрать все значения, которые содержат введенные символы.
        `,
    },
    {
        date: '2021-05-17T00:00:00+0300',
        message: `
            <b>Модуль "Управление проектами"</b>\n
            1. Во вкладке "Бриф" в левой части экрана добавлен список всех брифов, созданных в проекте и задачах, которые относятся к проекту. В верхней части Брифа отображается дополнительная информация:
            - Автор
            - Категория брифа
            - К какой задаче/проекту относится
            - Ответственный
            - Дата создания
            2. В правой части экрана скрыт блок "ID бюджета (2020)".
            3. Обновлены прелоадеры при загрузке страницы.

            <b>Модуль "Бюджетирование"</b>\n
            - Во вкладке "Исполнение" - Таблица теперь при клике на "Копирование строки" создается копия конкретной выбранной строки, а не всех вложенных строк.
            - В форме создания активности реализована плавающая кнопка "Сохранить". Теперь правом верхнем углу при прокрутке экрана вниз у пользователя на экране всегда отображается кнопка "Сохранить".
            - В форме "Привязать существующий проект к ID ..." добавлены 2 поля для загрузки файлов - Медиаплан и Заявка на креатив.
            - В форме "Перенос бюджета" теперь пользователь может в поле "Переносимая сумма, руб" вставить сумму, которая была скопирована из Excel со знаком Рубля в денежном или текстовом формате.
        `,
    },
    {
        date: '2021-04-13T00:00:00+0300',
        message: `
            <b>Новый процесс для работы с ID бюджета</b>\n
            - Разработан новый процесс для утверждения связей ID бюджета с проектом и названий проектов для повышения эффективности согласований и ускорения финансовых сверок с подрядчиками.
            - В скором времени будут запланированы обучающие встречи по обновлённому процессу.

            <b>Модуль управления Проектами</b>\n
            - Обновлена правая информационная панель проекта.
            - Теперь в правой панели проекта отображаются статусы согласования названия проекта и привязанных к нему ID бюджета, а также, подробная информация по привязанным ID: план, резерв, факт, инструмент.
            - Добавлена возможность копирования названия проекта из шапки проекта.
            - Разработан персональный дашборд. Функционал персонального дашборда будет расширяться в будущих релизах, становясь персонализированной единой панелью управления проектами.
            - В текущей версии через персональный дашборд реализован процесс согласования связи ID бюджета и проекта.
        `,
    },
    {
        date: '2021-03-30T00:00:00+0300',
        message: `
            <b>Модуль Управления проектами</b>\n
            - Реализована возможность добавления в конструкторе Брифа опционального поля "Разворачивающийся блок", внутри которого могут содержаться любые типы полей брифа, разворачивающиеся после включения тоггла (переключателя) пользователем.

            <b>Модуль Бюджетирования</b>\n
            - Раздел Исполнение - Таблица окно: "Перенос бюджета" перемещено в правый нижний угол страницы.
            - Раздел Исполнение - Корректировки: в поле "Сумма" реализован вывод полного значения без сокращений.
            - Карточка создания/редактирования активности: добавлено поле "Канал".
            - Карточка создания/редактирования активности: при добавлении новой строки теперь по умолчанию подтягиваются все параметры предыдущей строки.
        `,
    },
    {
        date: '2021-02-24T00:00:00+0300',
        message: `
            <b>Модуль Управления проектами:</b>\n
            - Добавлена возможность копирования и вставки брифа в проект/задачу;
            - Добавлена компактная версия карточки задач с возможностью переключения вида "компактный/полный".

            <b>Модуль Бюджетирования:</b>\n
            - На странице исполнения добавлено модальное окно с информацией о выделенной строке бюджетирования.
        `,
    },
    {
        date: '2021-02-02T00:00:00+0300',
        message: `
            <b>Модуль Управления проектами</b>\n
            - Доработана форма уведомлений: добавлены название канала, в котором создан комментарий, а также прямая ссылка для перехода из уведомления в канал;
            - В фильтр по исполнителю добавлено поле "Пользователь не задан", которое позволяет отображать задачи, у которых исполнитель не назначен;
            - В комментариях добавлена подсветка упоминаемого пользователя;
            - В задачах поле "Название проекта" реализовано ссылкой.
        `,
    },
    {
        date: '2020-12-24T00:00:00+0300',
        message: `
            <b>Модуль Бюджетирования:</b>\n
            - Значительно увеличена скорость загрузки списка корректировок и фильтров таблицы.
        `,
    },
    {
        date: '2020-12-17T00:00:00+0300',
        message: `
            <b>Модуль Управления проектами:</b>\n
            - Реализована возможность упоминания пользователей в комментариях через "@";
            - Добавлена возможность поделиться проектом непосредственно из карточки проекта;
            - Улучшен дизайн карточек проектов и задач;
            - Поиск: добавлена возможность копирования текста комментария;
            - Улучшена производительность.

            <b>Календарь активностей:</b>\n
            - Изменены виджеты: теперь они показывают информацию по проектам только за отображаемый период.

            <b>Модуль Бюджетирования:</b>\n
            - Улучшена производительность.
        `,
    },
    {
        date: '2020-11-23T00:00:00+0300',
        message: `
            <b>Модуль Управления проектами:</b>\n
            - Увеличена скорость загрузки проектов и задач.
        `,
    },
    {
        date: '2020-11-06T00:00:00+0300',
        message: `
            <b>Модуль Управления проектами:</b>\n
            - Реализована возможность поиска по комментариям и файлам;
            - Реализована возможность добавления активных ссылок в Задачи;
            - Доработана фильтрация проектов/задач: добавлен чекбокс "Все" для выделения и снятия выделения всех проектов/задач;
            - Добавлена кнопка для автоматического копирования названия проекта и задачи в буфер обмена;
            - В основное меню добавлена ссылка на инструкции по MRM;
            - Добавлена возможность прикрепления файлов к Задаче и Комментариям, путем перетаскивания иконки в указанную область ("Drag and drop");
            - Изменен дизайн иконок в MRM.

            <b>Календарь активностей:</b>\n
            - Доработана индикация просроченных этапов в календаре.
        `,
    },
    {
        date: '2020-10-20T00:00:00+0300',
        message: `
            <b>Модуль Управления проектами:</b>\n
                - Реализован <b>функционал поиска</b> по проектам и задачам! <i>В ближайших релизах добавим поиск по файлам и комментариям</i>;\n

            <b>Календарь активностей:</b>\n
                - Добавлена возможность фильтрации по департаменту автора активности;\n

            <b>Модуль Бюджетирования:</b>\n
                - Реализована возможность присвоения нескольких ответственных к строке бюджета.\n
        `,
    },
    {
        date: '2020-10-07T00:00:00+0300',
        message: `
            <b>Календарь активностей:</b>\n
            - Доработана выгрузка календаря в PDF: теперь выгрузка разбивается на сраницы;\n
            - Улучшена верстка выгрузки;\n

            <b>Модуль Бюджетирования:</b>\n
            - Теперь факт предыдущего года считается корректно в суммирующей строке;\n

            <b>Модуль Управления проектами:</b>\n
            - Реализована возможность загружать сразу несколько файлов одновременно в задачах и проектах;\n
            - Виджет этапов проекта: добавлен тултип с указанием дат проведения мероприятий и оптимизирован размер виджета;\n
            - Лента уведомлений: оптимизирована производительность.\n
        `,
    },
    {
        date: '2020-09-23T00:00:00+0300',
        message: `
            <b>Календарь активностей:</b>\n

            - Появилась возможность выгружать календарь в pdf нажатием кнопки\n
            - Оптимизирован размер выгружаемого файла и скорость его генерации\n
            - При выгрузке документа, в него попадают только те проекты и задачи, которые указаны в фильтрах\n

            <b>Модуль бюджетирования:</b>\n

            - Ролевая модель: эксперт по бюджетированию теперь имеет доступ ко всем проектам своей организации\n

            <b>Модуль управления проектами:</b>\n

            - Уведомления: при отправке уведомления на почту теперь в письме указывается и текст комментария\n
            - Названия проектов и задач теперь доступны для копирования\n
        `,
    },
    {
        date: '2020-08-31T00:00:00+0300',
        message: `
            <b>Новый функционал - шеринг календарей:</b>\n
            - Бизнес-администратор может открыть доступ к календарю своей организации другим организациям. К примеру, Сбербанк может открыть календарь СберМаркетингу. Шеринг можно ограничить блоками или типами активностей.\n
            - Организация, получившая доступ к стороннему календарю, не получит доступ к задачам, файлам и перепискам.\n

            <b>Модуль управления проектами - этапы проекта:</b>\n
            - Исправлен баг, при котором некоторым пользователям был недоступен виджет этапов проекта.\n

            <b>Модуль управления проектами - файлы:</b>\n
            - Исправлены ошибки, которые возникали при загрузке изображений.\n

            <b>Модуль бюджетирования - корректировки:</b>\n
            - Устранена проблема долгой загрузки страницы, которая возникала после утверждения корректировки.\n
        `,
    },
    {
        date: '2020-08-12T00:00:00+0300',
        message: `
            <b>Новый функционал - Этапы проекта:</b>\n
            - Бизнес администратор может создавать шаблоны этапов проекта.\n
            - Пользователи могут выбрать шаблон этапов и задать даты их проведения.\n
            - В проекте добавлен виджет проведения этапов проекта.\n
            - Автор проекта и ответственный за проект могут завершать этапы. \n
            - Также этапы проекта отображаются при наведении мышки на проект в календаре.\n

            <b>Модуль календаря:</b>\n
            - На странице календаря добавлены виджеты c данными о количестве проектов, количестве запусков на неделе и количестве просроченных этапов.\n

            <b>Модуль бюджетирования:</b>\n
            - В окне переноса планового бюджета исправлена ошибка неправильного указания последовательности действий при переносе бюджета.\n
            - Исправлен баг при котором можно было обнулить ячейку "Название проекта".\n
        `,
    },
    {
        date: '2020-07-31T00:00:00+0300',
        message: `
            <b>Модуль управления проектами:</b>\n
            - Добавлена новая функция "Ключевые проекты". Теперь супервайзеры могут делать проекты ключевыми, помечая их флагом.\n
            - Добавлена кнопка перехода в DAM.\n
            - Заблокированные пользователи больше не будут отображаться в выпадающих списках.\n

            <b>Календарь:</b>\n
            - В календаре появился фильтр по ключевым проектам.\n
            - Недельный календарь переименован из "Неделя" в "Запуски недели".\n
            - Исправлен баг, из-за которого при применении определенных фильтров дата перескакивала на 2018 год.\n

            <b>Модуль бюджетирования:</b>\n
            - Исправлен баг, при котором при принятии/отклонении измененной строки возникала ошибка.\n
            - Исправлена ошибка таблицы, когда супервайзер не мог редактировать строки, созданные экспертом, с ответственным за проект бренд-менеджером из своего департамента.\n
            - Исправлена ошибка, из-за которой оранжевая подсветка не отображалась, когда бренд-менеджер опустошал ячейку-словарь.\n
            - Исправлен баг в таблице планирования, при котором табличка из кнопки i отображала неверную сумму.\n
            - В таблице планирования переименованы и добавлены столбцы:\n
              "Название проекта" преименовано в "Активность".\n
              "Название проекта (комментарий для SAP)", переменовано в "Название проекта".\n
        `,
    },
    {
        date: '2020-07-16T00:00:00+0300',
        message: `
            <b>Календарь:</b>\n
            - Исправлен баг, при котором новый проект отображался только после перезагрузки страницы.\n
            - Добавлена индикация статусов задач:\n
              Закрытая задача в календаре отображается с галочкой внутри круга, а название задачи в блоке слева становится зелёным.\n
              Просроченные задачи в календаре отображаются с восклицательным знаком на кружке задачи, на свечке проекта и в блоке слева.\n
            - Исправлен баг, при котором пользователь мог попасть в недоступные ему задачи через название задачи в левом блоке.\n
        `,
    },
    {
        date: '2020-07-08T00:00:00+0300',
        message: `
            <b>Модуль управления проектами:</b>\n
            - Поле "Название продукта" теперь является обязательным при создании проекта.\n
            - Блок фильтров "Моё участие" теперь расположен в самом верху списка фильтров.\n
            - Исправлен баг на странице создания проектов, при котором дату "Подведение итогов" можно было выставить раньше даты "Старт проекта".\n
            - Исправлен баг, при котором во время отображения в ленте новостей удаленного блока или дивизиона, страница падала.\n
            - Исправлена ошибка, из-за которой в ленте уведомлений появлялась лишнее сообщение, при упоминании пользователя в комментариях.\n
            - Исправлен баг падения страницы из-за привязки ID бюджетов к проектам.\n

            <b>Модуль бюджетирования:</b>\n
            - Запущен модуль планирования.\n
            - Восстановлена работа календаря с планом активностей из модуля бюджетирования.\n
            - Произведена оптимизация работы таблиц исполнения и планирования. Сортировка и фильтры теперь работают быстрее.\n
            - Исправлены баги, приводившие к падению страницы исполнения.\n
            - Исправлен баг, при котором новые карточки корректировок попадали не в самый верх списка корректировок.\n
            - Исправлен баг не корректной сортировки по ID плана.\n
            - Прелоадер больше не исчезает во время загрузки списка корректировок.\n
            - Исправлен баг, когда при сохранении строки актуальный список корректировок добавлялся к старому, а не замещал его.\n
            - Исправлена ошибка таблицы, когда в колонку "Факт предыдущий год" после запятой можно было вносить больше 2-х символов.\n
            - В ячейке "Факт предыдущий год" исправили ошибку, из-за которой при наличии суммы больше нуля, было невозможно сохранить нулевое значение.\n
            - Исправлена ошибка, из-за которой при совместной работе над строкой у последнего создавшего корректировку, изменения не сохранялись.\n
        `,
    },
    {
        date: '2020-06-15T00:00:00+0300',
        message: `
            <b>Модуль бюджетирования:</b>\n
            - Переработан и оптимизирован, скорость работы увеличена в 7-10 раз.\n
        `,
    },
    {
        date: '2020-05-11T00:00:00+0300',
        message: `
            <b>Модуль управления Проектами:</b>\n
            - Теперь все участники каналов в задачах могут добавлять других участников.\n
            - Добавлены новые фильтры по параметрам.\n
            - Интерфейсные доработки меню страницы проекта.\n

            <b>Календарь:</b>\n
            - Названия активностей и задач в блоке слева сделаны ссылками на указанные сущности\n
        `,
    },
    {
        date: '2020-04-15T00:00:00+0300',
        message: `
            <b>Модуль управления Проектами:</b>\n
            - Теперь кнопка добавления участников к задаче не скрывается за пределами экрана независимо от его размера.\n
            - Исправили баг с необрезаемым именем проекта на экране задачи.\n
            - Исправили баг с привязкой к проекту большого кол-ва  ID строк из бюджетирования, при котором на дэшборде ID строк выходили за пределы карточки проекта.\n
            - Внесли косметические изменения в карточки обновлений.\n
            - Исправили баг, связанный с неработающей сортировкой "непросмотренных" проектов и задач.\n
            - Карточки уведомлений с длинным текстом теперь сворачиваются.\n
            - Теперь из проекта можно перейти в календарь-флоучарт к соответствующему разделу.\n

            <b>Модуль Бюджетирования:</b>\n
            - Исправили проблему с выгрузкой корректировок в Эксель.\n
            - Теперь в выгрузке таблицы бюджетирования на вкладке "в рублях" ячейки плана в рублях с копейками.\n
            - Исправили возможность добавления строк в "чужие" активности через меню строки.\n
            - Исправили баги с внесением резерва.\n
            - Факт за предыдущий год теперь может вносить только Эксперт по бюджетированию.\n

            <b>Вся система</b>\n
            - Убрали футтер на всех страницах.\n
        `,
    },
    {
        date: '2020-04-02T00:00:00+0300',
        message: `
            <b>В этом релизе полностью переделан календарь-флоучарт активностей.</b>\n
            - Улучшены юзабилити и визуальная составляющая.\n
            - Расширены возможности фильтрации и поиска. \n

            <b>Модуль Бюджетирования:</b>\n
            - В Excel-выгрузку таблицы бюджетирования добавлен второй лист с целыми суммами бюджетов.\n
            - Текст в шапке таблицы теперь чёрный.\n
            - Теперь белые строки попадают в самый низ таблицы после обнуления бюджетов.\n
            - Изменено поведение фильтров при создании корректировки планового бюджета, теперь не может быть ситуаций, когда во время проведения корректировки остаётся одна строка на экране.\n
            - Интерфейс создания корректировки планового бюджета теперь не изменяет свой размер, и не закрывает лишнюю рабочую область экрана.\n
            - Переименовали кнопку "Отменить" в меню корректировки плана/внешних источников на "Закрыть".\n
            - Изменили вординг в меню внешних корректировок.\n
            - Добавили возможность копировать текст и суммы из таблицы ячеек, а также из карточек корректировок.\n

            <b>Модуль Проекты</b>\n
            - Минорное улучшение в меню добавления участников к задаче.\n

            <b>Вся система</b>\n
            - Теперь левое навигационное меню открывается только при клике на него.\n

            <b>Email-уведомления:</b>\n
            - Убрали ссылки из писем при удалении проекта\\задачи\\участника, закрытии доступа к проекту\задаче.\n
            - Уведомления шлются персонально пользователю, а не на список пользователей.\n

            <b>Администрирование:</b>\n
            - Теперь брифы копируются.\n
        `,
    },
    {
        date: '2020-03-04T00:00:00+0300',
        message: `
            <b>Улучшения модуля Бюджетирования: </b>\n
            • Порядок полей на странице создания / редактирования активности сделан по аналогии с порядком колонок в таблице Исполнения. \n
            • Скроллы на всех страницах сделаны шире. \n
            • Заголовки в таблицах и фильтрах Бюджетирования сделаны контрастнее. \n
            • В таблицах Бюджетирования добавлены фильтры по всем колонкам, в том числе по колонкам с суммами. \n
            • В Исполнении добавлена возможность очищать все поля, кроме Активности. \n
            • На странице создания / редактирования активности добавлены дополнительные необязательные поля - "Центр затрат" и "Драйвер аллокации". \n
            • Во всех фильтрах добавлена возможность использовать Enter для выбора / удаления значения. \n
            • ID плана в таблице Исполнения и в карточках Корректировок теперь идут в порядке добавления, а не возрастания. \n
            • Теперь в таблице Исполнения, если внесённая сумма в ячейку резерва превысит допустимый лимит для резервирования, то сумма резерва будет сброшена для максимально возможной, а эта ячейка будет подсвечена оранжевой рамкой, пока не будет внесено исправление в введенную сумму, либо изменение не будет отправлено на согласование. \n
            • В меню строки добавлена возможность открыть страницу корректировок в новой вкладке, где будут отображены корректировок по этой строке. \n
            • В таблицах Бюджетирования добавлены фильтры по всем колонкам. \n
        `,
    },
    {
        date: '2020-02-28T00:00:00+0300',
        message: `
            <b>Улучшение страницы корректировок в Исполнении: </b>\n
            • Исправлены ошибки верстки и расположение отдельных элементов карточек корректировок. \n
            • Страница корректировок теперь поддерживает сохранение настроек фильтров при выходе или переключении на другую страницу. \n
            • Исправлен сброс фильтров страницы Исполнения при переходе в страницу Исполнения из карточек корректировок по кнопке "Посмотреть в таблице". \n
            • Карточки корректировок показывают правильные сводные суммы - по строкам, а не по активностям, с сохранением историчности изменений. \n

            <b>Сделаны улучшения в модуле Бюджетирования:</b>\n
            • Добавлена возможность построчной отправки изменений для создания корректировок и сохранения изменений. \n
            • Добавлен прелоадер на страницу Исполнения, если приложение при запуске страницы загружается медленно. \n

            <b>Сделаны улучшения в модуле Проекты и задачи:</b>\n
            • Страницы Проектов и задач позволяют теперь сохранять настройки фильтров и сортировок при выходе или переключении на другую страницу. \n
            • Настройки фильтров новостей так же сохраняются при выходе или переключении на другую страницу. \n

            <b>Улучшения инфраструктуры. Важно:</b>\n
            • Платформа [sbermarketing.ru](http://sbermarketing.ru) переехала на новую серверную архитектуру. Это ощутимо улучшает производительность работы системы, делает работу в системе удобнее. \n
            Так же были произведены изменения в хранении файлов для полного соответствия стандартам безопасности хранения цифровых активов. \n
            • Часть файлов (не более 2%) из-за изменений в инфраструктуре может скачиваться в некорректном виде. \n
            В таких ситуациях файлы можно восстанавливать самостоятельно, удаляя и загружая заново файлы, или обращаясь в чат технической поддержки, или на e-mail support@sbermarketing.ru с указанием ссылки на задачу или проект, содержащие поврежденный файл. \n
            В этом случае файл мы постараемся восстановить самостоятельно.  \n
        `,
    },
    {
        date: '2020-02-04T00:00:00+0300',
        message: `
            <b>Добавлен тоггл "Новые" в колонке с обновлениями в модуле Проекты и задачи: </b>\n
            • Теперь можно посмотреть отдельно обновления по непросмотренным задачам и проектам. \n
            <b>Исправлен ряд ошибок в модуле Исполнение:</b>\n
            • Исправили ошибку в работе кнопки сброса фильтров в Исполнении. \n
            • Исправили ошибки с сохранением состояний настроенных фильтров в Исполнении. \n
        `,
    },
    {
        date: '2020-01-28T00:00:00+0300',
        message: `
            <b>Новый функционал в модуле Бюджетирования в части исполнения:</b>\n
            • Снята проверка на обязательное заполнение всех полей, кроме названия Активности. \n
            • Теперь в исходном состоянии таблицы включено отображение всех колонок. \n
            • Добавлена сортировка значений в справочниках по правилу А-Я.\n
            • Добавлено меню действий по строке, в дальнейшем планируется расширять этот функционал. \n
            • Добавлена подсветка кнопки "Фильтр", если есть активные фильтры. \n
            • Кнопка "Сброс настроек отображения" разделена на две кнопки:
            "Сброс настроек отображения", сбрасывающую количество столбцов, сортировки, ширину и закрепление колонок в исходное состояние;
            И "Сброс фильтров", сбрасывающую фильтры по колонкам и глобальные фильтры, управляющие отображением строк. \n
            • Реализован новый формат отображения уведомлений об успешности совершения действий: создание новой активности, добавление строки, создание корректировки и другие. \n
            <b>Исправленные ошибки:</b>\n
            • Исправлены ошибки в работе обновленных фильтров. \n
            • Исправлены ошибки с отображением и изменением размера последней колонки в таблице бюджетирования. \n
        `,
    },
    {
        date: '2020-01-21T00:00:00+0300',
        message: `
            <b>Изменен функционал в модуле Бюджетирования:</b>\n
            • Добавлено новое обязательное поле для каждой строки: "Название проекта (комментарий для SAP)." \n
            • В корректировки добавлен новый фильтр - по ID корректировки, в уведомления по корректировкам добавлен ID корректировки и возможность сразу перейти по ссылке из письма к нужной корректировке.\n
            • Добавлена подсветка суммирующих колонок по кварталам.\n
            • Улучшен поиск в меню связи проектов и строк бюджета, реализована ролевая модель при связывании проектов и строк бюджета. \n
            • Улучшено отображение колонок в таблицах бюджетирования, улучшена механика работы подтверждения/отклонения корректировок. \n
            • Улучшен ui таблиц бюджетирования и карточек корректировок, добавлены уведомления об успешности совершения операций в модуле Бюджетирования. \n
            • Исправлены минорные ошибки работы модуля Бюджетирования в части исполнения. \n
        `,
    },
    {
        date: '2019-12-31T00:00:00+0300',
        message: `
            <b>Добавлен новой функционал в бюджетировании:</b>\n
            • Улучшен интерфейс бюджетирования -  добавлены новые функции и размещены в виде отдельного меню с иконками функционала бюджетирования. \n
            • Для экспертов по бюджетированию - добавлена возможность создавать корректировки из "внешних" источников, улучшен механизм утверждения и отклонения корректировок.\n
            • Добавлена механика выбора ответственного эксперта при создании корректировок.\n
            • Добавлены уведомления по корректировкам в бюджетировании: уведомления при создании корректировки для ответственного эксперта, уведомления для авторов корректировок о результатах рассмотрения их предложений по изменению бюджета. \n
            • В модуле бюджетирования в части исполнения бюджетов реализована ролевая модель: изменения и корректировки возможны либо по собственным проектам, либо - для руководителей направлений - по своим департаментам. Эксперты обладают полным функционалом в рамках своей организации. \n

            <b>Внесены важные улучшения модуля Проектов и задач:</b>\n
            • Добавлена возможность перестройки фильтров в зависимости от организации. \n
            • Добавлены фильтры в обновления на главной странице Проектов и задач - это позволит следить за процессами и событиями удобнее и эффективнее.\n
            • Добавлена возможность отключать уведомления по задачам. \n

            <b>Команда <a href="http://sbermarketing.ru">sbermarketing.ru</a> поздравляет всех пользователей системы с Новым годом! Мы желаем достижения всё новых и новых высот в маркетинге, PR, планировании, медиа, позиционировании, аналитике и продвижении. Надеемся, что наш инструмент поможет вам справляться с рутинными задачами удобнее и комфортнее, чтобы больше сил и возможностей было для того, что является истинным вашим — создавать прекрасное вокруг.</b>\n
        `,
    },
    {
        date: '2019-12-10T00:00:00+0300',
        message: `
            <b>Важные улучшения:</b>\n
            • Обновлена система уведомлений, включая рассылку на почту и колонку обновлений в модуле управления проектами.\n
            • Добавлена возможность создания корректировки факта по месяцам в модуле бюджетирования в части исполнения бюджетов. Изменения факта может вносить только пользователь с ролью Эксперт, при сохранении таких изменений автоматически создается утвержденная корректировка.\n
            • Добавлена возможность скачать таблицы бюджетирования в формате xls. Выгружаемый файл учитывает настройки отображения, примененные фильтры и сортировки.\n
            • Добавлена суммирующая строка бюджетов по таблицам в модуле бюджетирования: сумма пересчитывается с учётом опубликованных или утвержденных изменений и примененных фильтров. При выгрузке таблицы суммирующая строка также выгружается. \n
            • Колонки "Остаток по проекту (год)" и "Остаток по факту (год)" дополнительно выделены цветом, для улучшения навигации по таблице Исполнения.\n

            <b>Внесены важные исправления:</b>\n
            • Исправлены 4 ошибки UI в модуле управления проектами 11 ошибок UI работы с таблицами в модуле бюджетирования.\n
            • Обновлены данные по ипотеке в сервисе Конкуренты 360.\n

            <b>Над чем работаем:</b>\n
            • Дальнейшее улучшение работ модулей управления проектами и модуля бюджетирования  на основе обращений пользователей.\n
            • Добавление новых типов корректировок и улучшение фильтров в корректировках - в ближайшем релизе.\n
            • Переработка календаря и разработка недельного дэшборда на основе активностей и задач в модуле управления проектами.\n
        `,
    },
    {
        date: '2019-11-19T00:00:00+0300',
        message: `
            <b>Важные улучшения:</b>\n
            • Добавлена возможность создавать и использовать бриф в задачах.\n
            • Добавлена возможность копировать проекты в бюджетировании.\n
            • Добавлена возможность поиска и фильрации по id в бюджетировании.\n

            <b>Внесены важные исправления:</b>\n
            • Улучшена логика работы с денежными средствами, логика приведена к единому стандарту ввода и отображения во всех страницах проекта.\n
            • Кнопка сброса фильтров в бюджетировании сбрасывает все настройки отображения.\n
            • Перекомпонован UI фильтров по колонкам в бюджетировании по обращениям пользователей.\n
            • Исправлены ошибки поиска и фильтрации при создании корректировок в исполнении.\n
            • Исправлены ошибки сортировки в бюджетировании.\n
            • Исправлен ряд ошибок сервиса Конкуренты 360.\n

            <b>Над чем работаем:</b>\n
            • Дальнейшее улучшение работ модулей Проекты и задачи, Исполнения в бюджетировании на основе обращений пользователей.\n
            • Переработка уведомлений в модуле Проекты и задачи - в ближайшем релизе.\n
            • Добавление новых типов корректировок и улучшение фильтров в корректировках - в ближайшем релизе.\n
            • Добавление возможности выгрузки таблиц бюджетирования в формате xls.\n
            • Переработка календаря и разработка недельного дэшборда на основе активностей и задач.\n
        `,
    },
    {
        date: '2019-11-01T00:00:00+0300',
        message: `
            <b>Важные улучшения:</b>\n
            • Исправили 32 ошибки, надеемся, что работа с системой станет удобнее.\n
            \n
            <b>Над чем работаем:</b>\n
            • Улучшение работ проектов и задач, исполнения в бюджетировании на основе фидбека пользователей.\n
            • Переработка системы уведомлений.\n
            • Переработка модуля бюджетирования: планирование, исполнение и корректировки.\n
            • Переработка календаря и разработка недельного дэшборда на основе активностей и задач.\n
        `,
    },
    {
        date: '2019-10-08T00:00:00+0300',
        message: `
            <b>Важные улучшения:</b>\n
            • Исправили проблемы с загрузкой файлов, в том числе большого размера. \n
            • Устранили ряд критических ошибок работы системы, в том числе утечки мощностей серверных процессоров при высоких нагрузках. \n
            \n
            <b>В Проектах:</b>\n
            • Добавлена возможность установки даты начала подготовки и даты подведения итогов, теперь проекты настраиваются по четырем датам: \n
            Старт подготовки - Старт проекта - Окончание проекта - Подведение итогов \n
            Эти промежутки отображаются в календаре. \n
            • Исправлена проблема с удалением задач в проектах. \n
            • Добавлена кнопка перехода в экран редактирования из карточки проекта. \n
            \n
            <b>Над чем работаем:</b>\n
            • Первая полная версия планирования и исполнения.\n
            • Переработка календаря и разработка недельного дэшборда на основе проектов и задач.
        `,
    },
    {
        date: '2019-09-16T00:00:00+0300',
        message: `
            <b>Важные улучшения:</b>\n
            • Добавили возможность написать нам через чат о проблемах или рассказать об идеях и получить оперативный ответ технической поддержки системы в режиме единого окна. Пишите нам чаще!\n
            \n
            <b>В Проектах:</b>\n
            • Минорные улучшения UI раздела Проекты / Задачи\n
            • Фильтры в разделе Проекты / Задачи развернуты при первом заходе в систему\n
            • Добавлен лоадер отражающий процесс загрузки файлов\n
            • Исправлены проблемы при просмотре новой задачи в разделе Проекты / Задачи\n
            • Только автор задачи и текущий исполнитель могут менять исполнителя задачи\n
            • Улучшения UI раздела Календарь\n
            \n
            <b>В Бюджетировании:</b>\n
            • Исправлены минорные проблемы в работе таблицы Исполнения\n
            • Добавлена возможность отменить / вернуть изменения в таблице Исполнения через горячие клавиши ctrl+z / ctrl+y\n
            • В таблице добавлена возможность закреплять столбцы\n
            • В разделе Исполнение сделан тултип к комментарию отклонения корректировки\n
            • Добавлена возможность перехода в таблицу Исполнения из карточек корректировок к строкам, которые были скорректированы\n
            • Примененные фильтры и настройки в Бюджетировании сохраняются при выходе со страницы\n
            • Добавлена возможность вводить дробные значения в ячейки бюджетов\n
            • Изменения после Корректировок отображаются в таблице более явно\n
            • При переносе сумм Бюджета в Исполнении указатель ввода суммы по умолчанию слева\n
            \n
            <b>В административной панели:</b>\n
            • Улучшена навигация панели редактирования Департаментов\n
            • Исправлены проблемы с добавлением пользователей\n
            \n
            <b>В системе в целом:</b>\n
            • Исправлены проблемы с отправкой уведомлений\n
            • Исправления системы авторизации\n
            • На сервис Конкуренты 360 можно перейти из панели Меню системы\n
            \n
            <b>Над чем работаем:</b>\n
            • Сделать новый функционал - модуль Планирования бюджетов. Это большая задача, которая делает работу более удобной и связной, а покрытие системой задач по автоматизации процессов маркетинга ещё более полным.\n
            • Исправить минорные проблемы с UI / UX в Проектах / Задачах и Календаре. Добавить небольшие улучшения и расширения текущего функционала.\n
            • UI и UX Бюджетирования сделать более удобным, добавить функционал, например, итоговые суммы, выгрузку таблиц.\n
        `,
    },
];
