import * as React from 'react';

import * as styles from './styles.scss';

import {
    MenuItem,
    MenuItemProps,
    Icon,
    IconType,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
} from 'sber-marketing-ui';
import { List } from './List';

interface Props extends MenuItemProps {
    activityId: number;
    activityName: string;
    canCreateCreativeRequest: boolean;
}

export const MenuItemDropdown: React.FC<Props> = (props) => {
    const itemRef = React.useRef();

    const [opened, setOpened] = React.useState(false);

    const onClick = React.useCallback(() => {
        setOpened(!opened);
    }, [opened]);
    function close() {
        setOpened(false);
    }

    return (
        <div className={styles.root} ref={itemRef}>
            <MenuItem {...props} onClick={onClick}>
                <span className={styles.menuItem}>
                    Креатив
                    <div className={opened ? styles.arrowUp : styles.arrowDown}>
                        <Icon type={IconType.ARROW16_RIGHT} svgSize={16} />
                    </div>
                    {opened && (
                        <WithGlobalPopup
                            positionCalculator={AbsoluteLikePositionCalculator}
                            container={itemRef}
                            bottom={-4}
                            left={-10}
                            maskZIndex={20}
                            onMaskClick={close}
                        >
                            <List
                                activityId={props.activityId}
                                activityName={props.activityName}
                                canCreateCreativeRequest={props.canCreateCreativeRequest}
                                closeList={close}
                            />
                        </WithGlobalPopup>
                    )}
                </span>
            </MenuItem>
        </div>
    );
};
