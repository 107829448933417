import * as React from 'react';

import * as styles from './index.scss';

import { MonthItem } from './MonthItem';
import { EditMothItem, MonthItemType } from '../hooks';

interface Props {
    sourceMonthItemList: MonthItemType[];
    editMothItem: EditMothItem;
}

export const MonthItems = ({ sourceMonthItemList, editMothItem }: Props): JSX.Element => {
    return (
        <div className={styles.monthItemsContainer}>
            {sourceMonthItemList?.map((item) => (
                <MonthItem key={item.id + item.month} monthItem={item} editMothItem={editMothItem} />
            ))}
        </div>
    );
};
