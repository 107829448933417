import * as React from 'react';
import * as lodash from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';

import { BriefStageActionsTemplate } from './BriefStageActionsTemplate';
import type { StoreState } from '@store';
import { setCurrentStage, setMediaplanStatus } from '@store/autopilot_legacy/actions';
import { getBriefStageForm } from '@store/autopilot_legacy/selectors';
import { Loader, Saver } from '../../modules';
import { Utils } from '@common/Utils';

interface Props extends MapProps, DispatchProps {
    canEdit: boolean;
}

interface State {
    calculatingMediaplan: boolean;
}

interface MapProps {
    filledFieldsCount?: number;
    hasErrors: boolean;
}

interface DispatchProps {
    setCurrentStage?: (stage: number) => void;
    setMediaplanStatus?: (loading: boolean) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BriefStageActionsBehaviour extends React.PureComponent<Props, State> {
    private loader: Loader;
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();

        this.state = {
            calculatingMediaplan: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(BriefStageActionsTemplate, {
            canEdit: this.props.canEdit,
            hasErrors: this.props.hasErrors,
            isButtonLoading: this.state.calculatingMediaplan,
            filledFieldsCount: this.props.filledFieldsCount,
            onButtonClick: this.onButtonClick,
        });
    }

    @autobind
    protected async onButtonClick() {
        this.setState({ calculatingMediaplan: true });

        try {
            await this.saver.saveBriefForm();
            await this.saver.calculateMediaplan();

            await this.loader.loadAutopilot();
            await this.loader.loadMediaplan();

            this.props.setMediaplanStatus(true);
            this.props.setCurrentStage(2);
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({ calculatingMediaplan: false });
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const briefForm = getBriefStageForm(state);

    const filledFieldsCount = [
        briefForm.blockId,
        briefForm.productId,
        briefForm.budget,
        briefForm.dateStart,
        briefForm.dateEnd,
        briefForm.targetAgeFrom,
        briefForm.targetAgeTo,
    ].filter((item) => item !== null && !lodash.isEmpty(item)).length;

    const hasErrors = [briefForm.budgetError].some((error) => !!error);

    return {
        filledFieldsCount,
        hasErrors,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setCurrentStage,
            setMediaplanStatus,
        },
        dispatch,
    );
}
