import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { AutopilotTVVideo } from 'sber-marketing-types/backend';
import type { CellPosition, ColumnWidths, LineId } from './types';
import { CellEvent } from './types';
import type { StoreState } from '@store';
import type { BriefStageForm } from '@store/autopilotTv/types';
import type { ColumnParams, ColumnsConfigParams } from './ColumnsConfig';

import { TimeDistributionTableTemplate } from './TimeDistributionTableTemplate';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import { MakeColumnsConfig, MakeTableColumns } from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { getPercentageDistributionByWeakCount } from './utils';
import { setBriefFormValues } from '@store/autopilotTv/actions';
import { getBriefStageForm, getVideosInvalidWeeksIndexes } from '@store/autopilotTv/selectors';

interface Props extends Partial<MapProps & DispatchProps> {
    onColumnWidthsChange?: (columnWidths: ColumnWidths) => void;
}

interface MapProps {
    dateStart: string;
    dateEnd: string;
    videos: AutopilotTVVideo[];
    invalidWeeksIndexes: number[];
}

interface DispatchProps {
    setBriefFormValues: (briefForm: Partial<BriefStageForm>) => void;
}

interface State {
    lineIds: string[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TimeDistributionTableBehaviour extends React.PureComponent<Props, State> {
    private cellsStorage: TableViewModel;
    private table: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private tableColumns: string[] = [];
    private defaultColumnWidths: ColumnWidths = {};
    private weeks: { dateStart: string; dateEnd: string }[] = [];

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineIds: [...props.videos.map((item) => item.id), 'total'],
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getColumnsConfig: this.getColumnsConfig,
            getLine: this.getLine,
            getLines: this.getAllLines,
            getInvalidWeeksIndexes: this.getInvalidWeeksIndexes,
            onCellClose: () => this.table.setCursorEditStatus(false),
            onLineChange: this.onLineChange,
        });

        this.cellsStorage = new TableViewModel({
            makeColumnHeaderParams: this.columnHeaderFactory.makeColumnHeaderParams,
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateWeeks();
        this.initVideosPercents();
        this.updateDefaultColumnWidths();
        this.updateTableColumns();
    }

    // public async componentDidMount() {
    //     this.updateTableColumns();
    //     this.updateLineIds();
    // }

    public componentDidUpdate(prevProps: Props) {
        const invalidWeeksIndexesChanged = this.props.invalidWeeksIndexes !== prevProps.invalidWeeksIndexes;

        if (invalidWeeksIndexesChanged) {
            const columnsToUpdate = lodash.uniq([...this.props.invalidWeeksIndexes, ...prevProps.invalidWeeksIndexes]);

            columnsToUpdate.forEach((weekIndex) => {
                this.updateColumnCells(`month${weekIndex}`);
            });
        }
    }

    public render(): JSX.Element {
        const { lineIds } = this.state;

        return React.createElement(TimeDistributionTableTemplate, {
            viewModel: this.cellsStorage,
            columns: this.tableColumns,
            lineIds,
            columnWidths: this.defaultColumnWidths,
            isDistributeByConfigButtonDisabled: this.defineDistributeByConfigButtonDisabled(),
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
            onColumnWidthsChange: this.props.onColumnWidthsChange,
            onClearButtonClick: this.onClearButtonClick,
            onDistributeEvenlyButtonClick: this.onDistributeEvenlyButtonClick,
            onDistributeByConfigButtonClick: this.onDistributeByConfigButtonClick,
        });
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        this.table.setColumnWidths(columnWidths);
    }

    @autobind
    protected tableRef(component: TableView) {
        this.table = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected onClearButtonClick() {
        this.clearPercents();
    }

    @autobind
    protected onDistributeEvenlyButtonClick() {
        let updatedVideos = lodash.clone(this.props.videos);

        this.weeks.forEach((week, weekIndex) => {
            updatedVideos = this.distributePercentsEvenlyInWeek(updatedVideos, weekIndex);
        });

        this.setVideos(updatedVideos);
    }

    @autobind
    protected onDistributeByConfigButtonClick() {
        const clonedVideos = lodash.cloneDeep(this.props.videos);

        const videoWithMinDuration = lodash.minBy(clonedVideos, ({ duration }) => duration);
        const videoWithMaxDuration = lodash.maxBy(clonedVideos, ({ duration }) => duration);

        this.setVideos(this.distributePercentsByConfigInWeek({ videoWithMinDuration, videoWithMaxDuration }));
    }

    @autobind
    protected onLineChange(lineId: LineId) {
        this.updateLineCells(lineId);
        this.updateLineCells('total');
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                break;

            case CellEvent.MouseSelection:
                this.updateCell(position, false);
                break;
        }
    }

    private updateCell(position: CellPosition, edit: boolean) {
        this.cellsStorage.setCellParams(position, this.cellsFactory.makeCellParams(position, edit));
    }

    @autobind
    private updateLineCells(lineId: LineId) {
        this.tableColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    @autobind
    private updateColumnCells(columnName: string) {
        this.state.lineIds.forEach((lineId) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.table.getCellEditStatus(cellPosition);
            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateDefaultColumnWidths() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        const columnsConfig = MakeColumnsConfig(columnsConfigParams);

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    private updateTableColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        this.tableColumns = MakeTableColumns(columnsConfigParams);
    }

    private updateLineIds() {
        this.setState({
            lineIds: [...this.props.videos.map((item) => item.id), 'total'],
        });
    }

    private updateWeeks() {
        const { dateStart, dateEnd } = this.props;

        const weeks: { dateStart: string; dateEnd: string }[] = [];

        let periodStart = moment(dateStart);
        let periodEnd = moment(dateStart);

        while (periodStart.isBefore(moment(dateEnd))) {
            const weekEnd = periodStart.clone().endOf('week');
            const monthEnd = periodStart.clone().endOf('month');

            periodEnd = moment.min([weekEnd, monthEnd, moment(dateEnd)]);

            weeks.push({
                dateStart: periodStart.format('YYYY-MM-DD'),
                dateEnd: periodEnd.format('YYYY-MM-DD'),
            });

            periodStart = periodEnd.clone().add(1, 'day');
        }

        this.weeks = weeks;
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {
            weeks: this.weeks,
        };
    }

    @autobind
    private getLine(lineId: string): AutopilotTVVideo {
        return this.props.videos.find((item) => item.id === lineId);
    }

    @autobind
    private getAllLines(): AutopilotTVVideo[] {
        return this.props.videos;
    }

    private initVideosPercents() {
        const { dateStart, dateEnd, videos } = this.props;

        let updatedVideos = lodash.cloneDeep(videos);

        const newVideos = updatedVideos.filter((item) => lodash.isEmpty(item.percents));

        const someVideosAreNew = newVideos.length > 0;
        const allVideosAreNew = newVideos.length === updatedVideos.length;

        const datesChanged =
            lodash.first(lodash.first(updatedVideos).percents)?.dateStart !== dateStart ||
            lodash.last(lodash.first(updatedVideos).percents)?.dateEnd !== dateEnd;

        if (someVideosAreNew || datesChanged) {
            if (allVideosAreNew) {
                updatedVideos.forEach((video) => {
                    video.percents = this.weeks.map((week) => ({
                        dateStart: week.dateStart,
                        dateEnd: week.dateEnd,
                        value: 0,
                    }));
                });

                if (this.canUseDistributionByConfig) {
                    const videoWithMinDuration = lodash.minBy(updatedVideos, ({ duration }) => duration);
                    const videoWithMaxDuration = lodash.maxBy(updatedVideos, ({ duration }) => duration);

                    updatedVideos = this.distributePercentsByConfigInWeek({
                        videoWithMinDuration,
                        videoWithMaxDuration,
                    });
                } else {
                    this.weeks.forEach((week, weekIndex) => {
                        updatedVideos = this.distributePercentsEvenlyInWeek(updatedVideos, weekIndex);
                    });
                }
            } else if (datesChanged) {
                updatedVideos.forEach((video) => {
                    video.percents = this.weeks.map((week) => {
                        const foundVideo = updatedVideos.find((item) => item.id === video.id);

                        const foundWeek = foundVideo?.percents?.find(
                            (item) => item.dateStart === week.dateStart && item.dateEnd === week.dateEnd,
                        );

                        return {
                            dateStart: week.dateStart,
                            dateEnd: week.dateEnd,
                            value: foundWeek ? foundWeek.value : null,
                        };
                    });
                });

                if (this.canUseDistributionByConfig) {
                    const videoWithMinDuration = lodash.minBy(updatedVideos, ({ duration }) => duration);
                    const videoWithMaxDuration = lodash.maxBy(updatedVideos, ({ duration }) => duration);

                    updatedVideos = this.distributePercentsByConfigInWeek({
                        videoWithMinDuration,
                        videoWithMaxDuration,
                    });
                } else {
                    this.weeks.forEach((week, weekIndex) => {
                        const weekIsNew = updatedVideos.every((video) => video.percents[weekIndex].value === null);

                        if (weekIsNew) {
                            updatedVideos = this.distributePercentsEvenlyInWeek(updatedVideos, weekIndex);
                        }
                    });
                }

                updatedVideos.forEach((video) => {
                    video.percents.forEach((percent) => {
                        if (percent.value === null) {
                            percent.value = 0;
                        }
                    });
                });
            } else {
                newVideos.forEach((video) => {
                    video.percents = this.weeks.map((week) => ({
                        dateStart: week.dateStart,
                        dateEnd: week.dateEnd,
                        value: 0,
                    }));
                });
            }

            this.setVideos(updatedVideos);
        }
    }

    private clearPercents() {
        const updatedVideos = lodash.clone(this.props.videos);

        updatedVideos.forEach((item) => {
            this.weeks.forEach((week, weekIndex) => {
                item.percents[weekIndex].value = 0;
            });
        });

        this.setVideos(updatedVideos);
    }

    private distributePercentsEvenlyInWeek(videos: AutopilotTVVideo[], weekIndex: number) {
        let remainderOfDivision = 100 % videos.length;
        const basePercent = (100 - remainderOfDivision) / videos.length;

        videos.forEach((video) => {
            video.percents[weekIndex].value = basePercent;
        });

        let videoIndex = videos.length - 1;

        while (remainderOfDivision > 0) {
            videos[videoIndex].percents[weekIndex].value += 1;
            remainderOfDivision -= 1;
            videoIndex -= 1;
        }

        return videos;
    }

    private distributePercentsByConfigInWeek(videos: {
        videoWithMinDuration: AutopilotTVVideo;
        videoWithMaxDuration: AutopilotTVVideo;
    }): AutopilotTVVideo[] {
        const percentageDistribution = getPercentageDistributionByWeakCount(this.weeks.length);

        return [
            {
                ...videos.videoWithMinDuration,
                percents: videos.videoWithMinDuration.percents.map((percent, weekIndex) => ({
                    ...percent,
                    value: percentageDistribution[weekIndex].videoWithMinDuration,
                })),
            },
            {
                ...videos.videoWithMaxDuration,
                percents: videos.videoWithMaxDuration.percents.map((percent, weekIndex) => ({
                    ...percent,
                    value: percentageDistribution[weekIndex].videoWithMaxDuration,
                })),
            },
        ];
    }

    private defineDistributeByConfigButtonDisabled(): boolean {
        return !this.canUseDistributionByConfig;
    }

    private get canUseDistributionByConfig(): boolean {
        return (this.weeks || []).length <= 8 && (this.props?.videos || []).length === 2;
    }

    private setVideos(updatedVideos: AutopilotTVVideo[]) {
        this.props.setBriefFormValues({
            videos: updatedVideos,
        });

        // TODO Timeout устраняет кейс, когда ячейки в таблице начинают обновлятся не дождавшись данных.
        setTimeout(() => {
            this.state.lineIds.forEach((lineId) => {
                this.updateLineCells(lineId);
            });
        }, 0);
    }

    // @autobind
    // private getLine(lineId: LineId): any {
    //     return this.lines.find(item => item.model.id === lineId) || null;
    // }

    @autobind
    private getInvalidWeeksIndexes(): number[] {
        return this.props.invalidWeeksIndexes;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { dateStart, dateEnd, videos } = getBriefStageForm(state);
    const invalidWeeksIndexes = getVideosInvalidWeeksIndexes(state);

    return {
        dateStart,
        dateEnd,
        videos,
        invalidWeeksIndexes,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setBriefFormValues,
        },
        dispatch,
    );
}
