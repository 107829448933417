import { actionCreatorFactory } from 'typescript-fsa';

import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    RequestStageForm,
    SwitchKeyActivityParams,
    InitCreativeRequestDomainParams,
    InitCreativeRequestDomainResult,
} from './types';
import { CreativeRequestStatus } from '@api';

const actionCreator = actionCreatorFactory('CREATIVE_PAGE');

const loadActivity = actionCreator<Activity>('LOAD_ACTIVITY');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setRequestStageFormValues = actionCreator<Partial<RequestStageForm>>('SET_REQUEST_STAGE_FORM_VALUES');
const setCurrentStage = actionCreator<CreativeRequestStatus>('SET_CURRENT_STAGE');
const setSelectedStage = actionCreator<number>('SET_SELECTED_STAGE');

const switchKeyActivityAsync = actionCreator.async<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');
const initCreativeRequestDomainAsync = actionCreator.async<
    InitCreativeRequestDomainParams,
    InitCreativeRequestDomainResult,
    Error
>('INIT_CREATIVE_REQUEST_DOMAIN_ASYNC');
const updateCreativeRequestDomainAsync = actionCreator.async<
    InitCreativeRequestDomainParams,
    InitCreativeRequestDomainResult,
    Error
>('UPDATE_CREATIVE_REQUEST_DOMAIN_ASYNC');

export {
    loadActivity,
    resetPageState,
    setRequestStageFormValues,
    setCurrentStage,
    setSelectedStage,
    switchKeyActivityAsync,
    initCreativeRequestDomainAsync,
    updateCreativeRequestDomainAsync,
};
