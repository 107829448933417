import * as React from 'react';
import classNames from 'classnames';

import { UserConfigType } from 'sber-marketing-types/openid';

import { useEditChannelMutation, useGetTaskQuery, useGetUserConfigQuery } from '@api';

import { DropdownOptionsProps, useDropdown } from '@common/hooks';
import { ChannelAccessAction, useChannelAccess } from '@modules/channel/hooks';

import { Button, ButtonProps, Buttons, Details, Dropdown, Input } from '@common/components';

import { ChannelParticipants } from '@modules/channel/components';
import { TaskParticipantOptions } from '@modules/task/components';

import * as styles from './EditChannelButton.scss';

export interface EditChannelButtonProps extends ButtonProps<'button'>, DropdownOptionsProps {
    taskId: string;
    channelId: number;
}

export function EditChannelButton({
    rootRef,
    channelId,
    taskId,
    children,
    className,
    onShowChangeDropdown,
    loading,
    ...rest
}: EditChannelButtonProps) {
    const [dropdown, ref, props] = useDropdown(
        {
            ...rest,
            trigger: 'focus',
            target: rootRef,
            onShowChangeDropdown(show) {
                if (show === false) {
                    setTitle(undefined);
                    setParticipantIds(undefined);
                }

                onShowChangeDropdown?.(show);
            },
        },
        HTMLButtonElement,
    );
    const {
        isLoading: isAccessLoading,
        access: [canChangeTitle],
    } = useChannelAccess([ChannelAccessAction.changeTitle], channelId, taskId);
    const { data } = useGetTaskQuery({ id: taskId });
    const [editChannel, { isLoading: isChannelUpdating }] = useEditChannelMutation();
    const { data: authUser } = useGetUserConfigQuery(UserConfigType.Common);
    const channel = data?.task.chanels.find(({ id }) => id === channelId);
    const [title = channel?.title || '', setTitle] = React.useState<string>();
    const [participantIds = channel?.participantIds || [], setParticipantIds] = React.useState<number[]>();
    const inputRef = React.useRef<HTMLInputElement>();
    const myId = authUser?.config.userId;
    const isLoading = loading || isAccessLoading || isChannelUpdating;

    const handleEditChannel = async () => {
        if (title) {
            await editChannel({
                taskId,
                channelId,
                title,
                participantIds: String(participantIds),
            });
            dropdown.close();
        } else {
            inputRef.current.focus();
        }
    };

    return (
        <span className={classNames(styles.root, className)}>
            <Button {...props} rootRef={ref}>
                {children}
            </Button>
            <Dropdown width={360} dropdown={dropdown}>
                {canChangeTitle && (
                    <Input<string>
                        autoFocus
                        inputRef={inputRef}
                        value={title}
                        onEnter={handleEditChannel}
                        onChange={setTitle}
                        label="Название канала"
                    />
                )}
                <ChannelParticipants
                    channelId={channelId}
                    taskId={taskId}
                    participantIds={participantIds}
                    onChangeParticipantIds={setParticipantIds}
                />
                <Details defaultOpen={!canChangeTitle} summary="Добавить участников">
                    <TaskParticipantOptions
                        excludeUserIds={[...participantIds, ...(authUser ? [myId] : []), channel?.authorId]}
                        multiple
                        selected={participantIds}
                        onSelect={setParticipantIds}
                        taskId={taskId}
                    />
                </Details>
                <Buttons>
                    <Button
                        disabled={!title}
                        handleDisabled
                        loading={isLoading}
                        onClick={handleEditChannel}
                        flex
                        view="primary"
                    >
                        Сохранить
                    </Button>
                </Buttons>
            </Dropdown>
        </span>
    );
}
