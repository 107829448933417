// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-Footer-_Footer__root{margin-top:10px;display:flex;gap:5px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/RelatedCreative/CreativeCardList/CreativeCard/Footer/Footer.scss"],"names":[],"mappings":"AAAA,2IACI,eAAgB,CAChB,YAAa,CACb,OAAQ","sourcesContent":[".root {\n    margin-top: 10px;\n    display: flex;\n    gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-Footer-_Footer__root"
};
export default ___CSS_LOADER_EXPORT___;
