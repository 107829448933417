import * as React from 'react';
import { AutopilotApi } from '@api';

import type { LoadOptions } from './SelectInput';

interface UseLoadOptions {
    (params: Params): Return;
}

interface Params {
    referenceId: string;
}
interface Return {
    loadOptions: any;
}

const LIMIT = 20;

export const useLoadOptions: UseLoadOptions = ({ referenceId }) => {
    const loadOptions: LoadOptions = React.useCallback(
        async (searchQuery, loadedOptions, { page }) => {
            const response = await AutopilotApi.getSpecReferences(referenceId, {
                limit: LIMIT,
                offset: loadedOptions.length,
                filter: searchQuery,
            });

            const options = response.map(({ value, label }) => ({
                value: value.toString(),
                label: label.toString(),
            }));

            return {
                options,
                hasMore: !(options.length < LIMIT),
                additional: {
                    page: page + 1,
                },
            };
        },
        [referenceId],
    );

    return {
        loadOptions,
    };
};
