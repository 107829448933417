import { includes } from 'lodash';
import { HandlerMap } from './types';

export const constraintHandlerMap: HandlerMap = {
    min: async () => {},
    max: async () => {},
    maxKb: async (maxKb, file) => {
        const value = file.size >= maxKb * 1000 ? 'Превышен максимальный размер файла' : undefined;
        return (
            value && {
                type: 'error',
                value,
            }
        );
    },
    allowedMimeTypes: async (allowedMimeTypes, file) => {
        const value = !includes(allowedMimeTypes, file.type) ? 'Недопустимый тип файла' : undefined;
        return (
            value && {
                type: 'error',
                value,
            }
        );
    },
    ratioW: async (ratioW, file) => {
        try {
            const image = await getImage(file);
            const result =
                image.width / gcdTwoNumbers(image.width, image.height) !== ratioW
                    ? 'Соотношение по ширине не совпадает'
                    : undefined;
            await revokeImage(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку по ширине',
            };
        }
    },
    ratioH: async (ratioH, file) => {
        try {
            const image = await getImage(file);
            const result =
                image.height / gcdTwoNumbers(image.width, image.height) !== ratioH
                    ? 'Соотношение по высоте не совпадает'
                    : undefined;
            await revokeImage(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку по высоте',
            };
        }
    },
    recommendedWidth: async () => {},
    recommendedHeight: async () => {},
    minWidth: async (minWidth, file) => {
        try {
            const image = await getImage(file);
            const result = image.width < minWidth ? 'Превышена минимальная ширина изображения' : undefined;
            await revokeImage(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на минимальную ширину изображения',
            };
        }
    },
    minHeight: async (minHeight, file) => {
        try {
            const image = await getImage(file);
            const result = image.height < minHeight ? 'Превышена минимальная высота изображения' : undefined;
            await revokeImage(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на минимальную высоту изображения',
            };
        }
    },
    maxWidth: async (maxWidth, file) => {
        try {
            const image = await getImage(file);
            const result = image.width > maxWidth ? 'Превышена максимальная ширина изображения' : undefined;
            await revokeImage(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на минимальную ширину изображения',
            };
        }
    },
    maxHeight: async (maxHeight, file) => {
        try {
            const image = await getImage(file);
            const result = image.height > maxHeight ? 'Превышена максимальная высота изображения' : undefined;
            await revokeImage(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на максимальную высоту изображения',
            };
        }
    },
};

const getImage = (file: File): Promise<HTMLImageElement> => {
    return new Promise((resolve) => {
        const image = new Image();
        image.src = window.URL.createObjectURL(file);
        image.onload = () => resolve(image);
    });
};

const revokeImage = async (image: HTMLImageElement): Promise<void> => {
    window.URL.revokeObjectURL(image.src);
};

const gcdTwoNumbers = (x: number, y: number) => {
    x = Math.abs(x);
    y = Math.abs(y);
    while (y) {
        let t = y;
        y = x % y;
        x = t;
    }
    return x;
};
