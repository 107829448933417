// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__root{position:relative;width:100%}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__form{padding:16px;position:relative;width:100%;border-radius:6px;background:#E6EDF1}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__title{margin-bottom:8px;font-family:'Open Sans';font-weight:600;font-size:16px;line-height:22px;color:#092A3A}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__texts{margin-top:40px}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__buttons{display:grid;grid-auto-flow:column;grid-auto-columns:max-content;grid-column-gap:10px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/ContextStageWidget.scss"],"names":[],"mappings":"AAAA,uGACI,iBAAkB,CAClB,UAAW,CACd,uGAGG,YAAa,CACb,iBAAkB,CAClB,UAAW,CACX,iBAAkB,CAElB,kBAAmB,CACtB,wGAGG,iBAAkB,CAElB,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACjB,wGAGG,eAAgB,CACnB,0GAGG,YAAa,CACb,qBAAsB,CACtB,6BAA8B,CAC9B,oBAAqB","sourcesContent":[".root {\n    position: relative;\n    width: 100%;\n}\n\n.form {\n    padding: 16px;\n    position: relative;\n    width: 100%;\n    border-radius: 6px;\n\n    background: #E6EDF1;\n}\n\n.title {\n    margin-bottom: 8px;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 22px;\n    color: #092A3A;\n}\n\n.texts {\n    margin-top: 40px;\n}\n\n.buttons {\n    display: grid;\n    grid-auto-flow: column;\n    grid-auto-columns: max-content;\n    grid-column-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__root",
	"form": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__form",
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__title",
	"texts": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__texts",
	"buttons": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-_ContextStageWidget__buttons"
};
export default ___CSS_LOADER_EXPORT___;
