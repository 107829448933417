import * as React from 'react';
import { Statuses } from './Statuses';
import { useGetCurrentStatus } from './hooks/useGetCurrentStatus';
import { StatusPromotionButton } from './StatusPromotionButton';

interface Props {}

export const StagesListWidget = ({}: Props): JSX.Element => {
    const {
        creativeRequest,
        currentStatus,
        availableStatuses,
        underRevision,
        quickApprovement,
        setStatus,
        setReviewComment,
        reviewComment,
    } = useGetCurrentStatus();
    return (
        <div>
            <Statuses currentStatus={currentStatus} />
            <StatusPromotionButton
                creativeRequest={creativeRequest}
                currentStatus={currentStatus}
                availableStatuses={availableStatuses}
                underRevision={underRevision}
                quickApprovement={quickApprovement}
                setStatus={setStatus}
                setReviewComment={setReviewComment}
                reviewComment={reviewComment}
            />
        </div>
    );
};
