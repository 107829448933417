// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-DropdownKebab-_DropdownKebab__root{color:var(--color-gray-50)}.src-client-modules-common-components-main-DropdownKebab-_DropdownKebab__root:hover{color:var(--color-gray-100)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/DropdownKebab/DropdownKebab.scss"],"names":[],"mappings":"AAAA,8EACE,0BAA2B,CAD7B,oFAII,2BAA4B","sourcesContent":[".root {\n  color: var(--color-gray-50);\n\n  &:hover {\n    color: var(--color-gray-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-main-DropdownKebab-_DropdownKebab__root"
};
export default ___CSS_LOADER_EXPORT___;
