import type { BudgetItem } from '@mrm/budget';
import { DictionaryType } from '@api';

export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface CellPosition {
    columnName: ColumnName;
    lineId: LineId;
}

export interface BaseCellParams {
    component: React.Component;
    cellProps: any;
}

export interface TableHeaderCellParams extends BaseCellParams {}

export interface TableBodyCellParams extends BaseCellParams {
    readOnly?: boolean;
    сellBackgroundColor?: string;
    preventCloseOnClick?: boolean;
}

export type CellUpdateHandler<TCellParams extends BaseCellParams> = (cellParams: TCellParams) => void;

export const enum ColumnName {
    SourceId = 'sourceId',
    SourceFunds = 'sourceFunds',
    FactSumWithoutVat = 'factSumWithoutVat',
    FactSumWithVat = 'factSumWithVat',
    SourceBalance = 'sourceBalance',
}

export const enum LineType {
    PlaceholderLine = 'placeholderLine',
    TotalLine = 'totalLine',
    BudgetItemLine = 'budgetItemLine',
}

export const enum TableType {
    Total = 'total',
    Sourse = 'sourse',
}

export type LineId = string;
export type Line = BudgetItem;

export type ColumnWidths = Partial<Record<ColumnName, number>>;
export type LineHeights = Record<LineId, number>;

export enum TableEvent {
    Scroll = 'scroll',
    SizeChange = 'size_change',
}

export const enum CellEvent {
    MouseSelection = 'mouseSelection',
    SelectionCancel = 'selectionCancel',
}

export interface Filter {
    columnName: ColumnName;
    selectedValues: React.ReactText[];
}

export interface SortingParams {
    columnName: ColumnName;
    orderType: OrderType;
}

export const enum OrderType {
    Asc = 'asc',
    Desc = 'desc',
}

export const DICTIONARY_TYPES = [
    DictionaryType.CreativeRequestGroup,
    DictionaryType.CreativeRequestTariff,
    DictionaryType.CommissionAmount,
    DictionaryType.TransferredRightsType,
    DictionaryType.TransferredRightsValidityTerritory,
    DictionaryType.Agency,
];

export const UPDATE_SUBSCRIPTION_METHOD_NAMES = [
    // 'onProjectNameUpdated',
    // 'onStartDateUpdated',
    // 'onEndDateUpdated',
    // 'onTariffUpdated',
    // 'onTariffsQuantityUpdated',
    // 'onTransferredRightsTypeUpdated',
    // 'onTransferredRightsTypeTextUpdated',
    // 'onRightsDurationUpdated',
    // 'onActualCostWithoutVatUpdated',
    // 'onActualCostWithVatUpdated',
    // 'onVatUpdated',
    // 'onObjectTypeUpdated',
    // 'onStockLinkUpdated',
    // 'onStockNumberUpdated',
    // 'onThirdPartyUpdated',
    // 'onCommissionAmountUpdated',
    // 'onTransferredRightsValidityTerritoryUpdated',
    // 'onDonorsUpdated',
    // 'onAcceptorsUpdated',
    // 'onCommissionWithVatUpdated',
    // 'onCommissionWithoutVatUpdated',
    // 'onFilesAdded',
    // 'onFilesRemoved',
    // 'onCommentsAdded',
    'onUpdated',
];
