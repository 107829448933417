// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-ActionsMenu-Button-_styles__root{display:flex;height:20px;width:20px;align-items:end;justify-content:center;cursor:pointer;user-select:none}.src-client-modules-activity-pages-creative-ActionsMenu-Button-_styles__icon{color:#7E8681}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/ActionsMenu/Button/styles.scss"],"names":[],"mappings":"AAAA,6EACI,YAAa,CACb,WAAY,CACZ,UAAW,CACX,eAAgB,CAChB,sBAAuB,CACvB,cAAe,CACf,gBAAiB,CACpB,6EAGG,aAAc","sourcesContent":[".root {\n    display: flex;\n    height: 20px;\n    width: 20px;\n    align-items: end;\n    justify-content: center;\n    cursor: pointer;\n    user-select: none;\n}\n\n.icon {\n    color: #7E8681;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-ActionsMenu-Button-_styles__root",
	"icon": "src-client-modules-activity-pages-creative-ActionsMenu-Button-_styles__icon"
};
export default ___CSS_LOADER_EXPORT___;
