import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getUserConfigState, saveUserConfig, UserConfigType } from '@store/userConfig';

interface UseStore {
    (): UseStoreReturn;
}

interface UseStoreReturn {
    openedRightSidebar: boolean;
    openRightSidebar: () => void;
    closeRightSidebar: () => void;
}

export const useStore: UseStore = () => {
    const dispatch = useDispatch();

    const { creative } = useSelector((state: StoreState) => getUserConfigState(state));

    const openRightSidebar = React.useCallback(
        () =>
            dispatch(
                saveUserConfig({
                    type: UserConfigType.Creative,
                    payload: {
                        sidebar: {
                            visibility: true,
                        },
                    },
                }),
            ),
        [],
    );

    const closeRightSidebar = React.useCallback(
        () =>
            dispatch(
                saveUserConfig({
                    type: UserConfigType.Creative,
                    payload: {
                        sidebar: {
                            visibility: false,
                        },
                    },
                }),
            ),
        [],
    );

    return {
        openedRightSidebar: creative.fields.sidebar.visibility,
        openRightSidebar,
        closeRightSidebar,
    };
};
