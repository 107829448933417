import * as React from 'react';
import * as styles from './styles.scss';

interface Props {
    closeButtonElement: React.ReactElement;
}

export const Header: React.FC<Props> = ({ closeButtonElement }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>Параметры кампании для площадки</div>
            {closeButtonElement}
        </div>
    );
};
