import * as React from 'react';
import { useSelector } from 'react-redux';

import { BudgetItemApi, TaskApi, DictionaryApi } from '@api';

import { TaskPageState } from '@store/taskPage';
import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { DictionaryType } from '@mrm/dictionary';
import { InfoSection, InfoSectionFieldPreloader } from '@common/SidebarWithTabs/common/InfoSection';

import * as styles from './TaskBudgetApproval.scss';

export interface Props {
    task: TaskPageState;
}

export function TaskBudgetApproval({ task }: Props): JSX.Element {
    return task?.budgetApproval ? (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskBudgetApproval',
            }}
        >
            <BudgetSection task={task} />
        </div>
    ) : null;
}

interface BudgetSectionProps {
    task: TaskPageState;
}

function BudgetSection({ task }: BudgetSectionProps): JSX.Element {
    const user = useSelector((state: StoreState) => getLoginUser(state));

    const [budgetApproval, setBudgetApproval] = React.useState(null);
    const [budgetItemsSerialNumbers, setBudgetItemsSetialNumbers] = React.useState<string>(null);
    const [division, setDivision] = React.useState(null);
    const [segment, setSegment] = React.useState(null);

    React.useEffect(() => {
        TaskApi.getTaskBudgetApproval(task.id).then((data) => {
            setBudgetApproval(data);
        });
    }, []);

    React.useEffect(() => {
        async function worker() {
            if (budgetApproval && budgetApproval.budgetItemIds?.length && !budgetItemsSerialNumbers) {
                setBudgetItemsSetialNumbers(
                    (await Promise.all(budgetApproval.budgetItemIds.map(BudgetItemApi.getSerialNumber))).join(', '),
                );
            }
        }

        worker();
    }, [budgetApproval?.budgetItemIds]);

    React.useEffect(() => {
        if (budgetApproval && budgetApproval.clientDivisionId && !division) {
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.Division],
                organizationId: user.attributes.organizationId,
                ids: [budgetApproval.clientDivisionId],
            }).then((dictionary) => {
                dictionary && setDivision(dictionary[0]);
            });
        }
    }, [budgetApproval?.clientDivisionId]);

    React.useEffect(() => {
        if (budgetApproval && budgetApproval.clientDivisionId && !segment) {
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.Segment],
                organizationId: user.attributes.organizationId,
                ids: [budgetApproval.segmentId],
            }).then((dictionary) => {
                dictionary && setSegment(dictionary[0]);
            });
        }
    }, [budgetApproval?.segmentId]);

    return (
        <div className={styles.budgetApproval}>
            <InfoSection
                qaId="tasksSiddebarInfoTabBudgetApproval"
                title="Заказчик"
                content={budgetApproval?.clientDivision || division?.value}
            />
            <InfoSection
                qaId="tasksSiddebarInfoTabBudgetApproval"
                title="Представитель заказчика от бизнеса (ФИО)"
                content={budgetApproval?.clientName}
            />
            {budgetApproval && (
                <InfoSection
                    qaId="tasksSiddebarInfoTabBudgetApproval"
                    title="ID-источник"
                    content={
                        budgetApproval ? (
                            <div>
                                <a>{budgetItemsSerialNumbers || 'Не задан'}</a>
                            </div>
                        ) : (
                            <InfoSectionFieldPreloader />
                        )
                    }
                />
            )}
            <InfoSection qaId="tasksSiddebarInfoTabBudgetApproval" title="Продукт" content={budgetApproval?.product} />
            <InfoSection
                qaId="tasksSiddebarInfoTabBudgetApproval"
                title="Сегмент"
                content={budgetApproval?.segment || segment?.value}
            />
            <InfoSection qaId="tasksSiddebarInfoTabBudgetApproval" title="Период РК" content={budgetApproval?.period} />
            {budgetApproval?.hasLot && (
                <>
                    <InfoSection
                        qaId="tasksSiddebarInfoTabBudgetApproval"
                        title="ЛОТ креатива"
                        content={
                            budgetApproval?.mediaRequest === 'not'
                                ? 'Нет креатива по ЛОТам'
                                : `ЛОТ ${budgetApproval?.mediaRequest}`
                        }
                    />
                    {budgetApproval?.hasTitle && (
                        <>
                            <InfoSection
                                qaId="tasksSiddebarInfoTabBudgetApproval"
                                title="Название креатива"
                                content={budgetApproval?.naming}
                            />
                            <InfoSection
                                qaId="tasksSiddebarInfoTabBudgetApproval"
                                title="РК на основании"
                                content={budgetApproval?.project}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
}
