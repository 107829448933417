import * as React from 'react';
import { Button, Buttons, Modal, ModalProps } from '@sbermarketing/mrm-ui';
import { useDeleteCommentMutation } from '@api';

export interface RemoveCommentModalProps extends ModalProps {
    taskId: string;
    commentId: string;
    onRemove?: () => void;
    onCancel?: () => void;
}

export function RemoveCommentModal({ taskId, commentId, onRemove, onCancel, ...props }: RemoveCommentModalProps) {
    const [deleteComment] = useDeleteCommentMutation();

    const handleDeleteComment = () => {
        deleteComment({
            taskId,
            commentId,
        });

        onRemove?.();
    };

    return (
        <Modal data-qa-id="RemoveCommentModal" title="Удаление комментария" {...props}>
            Вы действительно хотите удалить комментарий?
            <Buttons>
                <Button data-qa-id="RemoveCommentModal__cancel" flex onClick={onCancel}>
                    Отмена
                </Button>
                <Button data-qa-id="RemoveCommentModal__remove" flex onClick={handleDeleteComment} view="danger">
                    Да, удалить
                </Button>
            </Buttons>
        </Modal>
    );
}
