import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, IconType, AnimatedEllipsis } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { ComponentState, getImportFMPTableMenuState, loadFMPTable } from '@store/budgetExecution/importFMPTableMenu';

import { FileInput } from '@common/FileInput';

import * as styles from './ImportFileButton.scss';

interface Props {
    budgetId: string;
}

export function ImportFileButton({ budgetId }: Props): JSX.Element {
    const dispatch = useDispatch();

    const componentState = useSelector((state: StoreState) => getImportFMPTableMenuState(state).componentState);
    const dataFetchingInProgress = useSelector(
        (state: StoreState) => getImportFMPTableMenuState(state).dataFetchingInProgress,
    );

    const fileInputRef = React.useRef<FileInput>();

    function onClick() {
        if (!dataFetchingInProgress) {
            fileInputRef.current.triggerOpen();
        }
    }

    function onFileInput(files: File[]) {
        dispatch(
            loadFMPTable({
                budgetId,
                file: files[0],
            }),
        );
    }

    const smallTheme = componentState === ComponentState.ReadyToLoad;
    let clickableTheme = true;
    let content: JSX.Element = null;
    if (dataFetchingInProgress) {
        clickableTheme = false;
        content = <AnimatedEllipsis text="Загрузка" />;
    } else {
        switch (componentState) {
            case ComponentState.FileSelection:
                content = (
                    <React.Fragment>
                        <Icon type={IconType.ATTACH_ACTIVE} svgSize={16} className={styles.icon} />
                        Загрузите файл
                    </React.Fragment>
                );

                break;
            case ComponentState.ReadyToLoad:
                content = (
                    <React.Fragment>
                        <Icon type={IconType.ATTACH_ACTIVE} svgSize={16} className={styles.icon} />
                        Загрузить другой
                    </React.Fragment>
                );

                break;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <div
                className={classnames(
                    styles.root,
                    smallTheme && styles.rootSmall,
                    clickableTheme && styles.rootClickable,
                )}
                onClick={onClick}
            >
                {content}
            </div>

            <FileInput multiple={false} ref={fileInputRef} onChange={onFileInput} />
        </React.Fragment>
    );
}
