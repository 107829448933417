import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { UserCard } from 'sber-marketing-types/frontend';
import { ColumnName, CreativeRequestDomain, TableType } from '@store/creative/types';
import { WatchCommentsByItemAndColumnState } from '@store/creative/sidebar/types';
import {
    CellPosition,
    ColumnWidths,
    LineHeights,
    Line,
    LineId,
    TableHeaderCellParams,
    TableBodyCellParams,
} from './types';
import { TableEvent } from './types';
import type { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import {
    Project,
    ProjectBudgetItem,
    CreativeRequest,
    CreativeRequestItem,
    CreativeRequestDonor,
    CreativeRequestContract,
    Dictionary,
    DictionaryType,
} from '@api';

import { CellsStorage, TableView, CellEvent } from 'sber-marketing-ui';
import { TableTemplate } from './TableTemplate';
import {
    MakeLeftFixedColumns,
    MakeRightFixedColumns,
    MakeTariffTableColumns,
    MakeProductionTableColumns,
    MakeAkTableColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
    AccessorParams,
    CellType,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';
import { setWatchCommentsByItemAndColumn } from '@store/creative/sidebar/actions';
import {
    CreativeUserConfig,
    getCreativeUserConfig,
    getLoadingStatus,
    Tabs,
    updateCreativeUserConfig,
    UpdateCreativeUserConfig,
} from '@store/userConfig/creative';
import { getAllUsers } from '@store/appUsers/selectors';

interface Props extends Partial<MapProps & DispatchProps> {
    displayStatusColumns: boolean;
    loading: boolean;
    getProject: () => Project;
    getCreativeRequest: () => CreativeRequestDomain;
    getLine: (lineId: string) => Line;
    getLines: () => Line[];
    getCreativeRequestLot: () => string;
    getDictionaries: () => Partial<Record<DictionaryType, Dictionary[]>>;
    getContracts: () => Record<'lot1' | 'lot2', CreativeRequestContract[]>;
    createLine: (tableType: TableType) => Promise<void>;
    archiveLine: (lineId: string) => Promise<void>;
    restoreLine: (lineId: string) => Promise<void>;
}

interface MapProps {
    userConfig: CreativeUserConfig;
    userConfigLoaded: boolean;
    users: UserCard[];
}

interface DispatchProps {
    updateCreativeUserConfig: (params: UpdateCreativeUserConfig) => void;
    setWatchCommentsByItemAndColumn: (cellPosition: WatchCommentsByItemAndColumnState) => void;
}

interface State {
    tariffLineIds: LineId[];
    productionLineIds: LineId[];
    akLineIds: LineId[];
    columns: Record<TableType, Record<'left' | 'center' | 'right', ColumnName[]>>;
    enabledColumns: (ColumnName | string)[];
    archivedFilterIsActive: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }) as any)
export class TableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: Record<TableType, CellsStorage<string, TableHeaderCellParams>>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private tariffTable: TableView;
    private productionTable: TableView;
    private akTable: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private fixedWidthColumns: ColumnName[];
    private defaultColumnWidths: ColumnWidths = {};
    private defaultLineHeights: LineHeights = {};
    private updateCreativeUserConfig: (params: UpdateCreativeUserConfig) => void;

    public constructor(props: Props) {
        super(props);

        const columns = this.makeDefaultColumns();
        const enabledColumns = this.makeDefaultEnabledColumns(columns);

        this.state = {
            tariffLineIds: [],
            productionLineIds: [],
            akLineIds: [],
            columns,
            enabledColumns,
            archivedFilterIsActive: false,
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
            getAllLines: this.props.getLines,
            getLeftFixedColumns: this.getLeftFixedColumns,
            onColumnFixClick: this.onColumnFixClick,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.props.getLine,
            getAllLines: this.props.getLines,
            getContracts: this.getContracts,
            getColumnsConfig: this.getColumnsConfig,
            getAccessorParamsData: this.getAccessorParamsData,
            onCellEditorClose: this.onCellEditorClose,
            onLineArchive: this.onLineArchiveClick,
            onLineRestore: this.onLineRestoreClick,
            onCommentsButtonClick: this.onCommentsButtonClick,
        });

        this.headerCellsStorage = {
            [TableType.Tariff]: new CellsStorage({
                makeCellParams: (columnName: ColumnName) =>
                    this.columnHeaderFactory.makeColumnHeaderParams(TableType.Tariff, columnName),
            }),
            [TableType.Production]: new CellsStorage({
                makeCellParams: (columnName: ColumnName) =>
                    this.columnHeaderFactory.makeColumnHeaderParams(TableType.Production, columnName),
            }),
            [TableType.Ak]: new CellsStorage({
                makeCellParams: (columnName: ColumnName) =>
                    this.columnHeaderFactory.makeColumnHeaderParams(TableType.Ak, columnName),
            }),
        };

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateFixedWidthColumns();

        this.updateCreativeUserConfig = lodash.debounce((params) => props.updateCreativeUserConfig(params), 1000);
    }

    public async componentDidMount() {
        if (this.props.userConfigLoaded) {
            this.initFromUserConfig();
        }

        this.tariffTable.subscribeTableEvent(TableEvent.Scroll, (eventType: TableEvent) =>
            this.onTableEvent(eventType, TableType.Tariff),
        );
        this.productionTable.subscribeTableEvent(TableEvent.Scroll, (eventType: TableEvent) =>
            this.onTableEvent(eventType, TableType.Production),
        );
        this.akTable.subscribeTableEvent(TableEvent.Scroll, (eventType: TableEvent) =>
            this.onTableEvent(eventType, TableType.Ak),
        );
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        const displayStatusColumnsChanged = this.props.displayStatusColumns !== prevProps.displayStatusColumns;
        const archivedFilterChanged = this.state.archivedFilterIsActive !== prevState.archivedFilterIsActive;
        const loadingFinished = !this.props.loading && prevProps.loading;
        const userConfigLoaded = this.props.userConfigLoaded && !prevProps.userConfigLoaded;

        if (displayStatusColumnsChanged) {
            this.updateTableColumns();
        }

        if (loadingFinished || archivedFilterChanged) {
            await this.updateTableLineIds();
        }

        if (userConfigLoaded) {
            this.initFromUserConfig();
        }
    }

    public render(): JSX.Element {
        const { loading } = this.props;
        const { tariffLineIds, productionLineIds, akLineIds, enabledColumns, archivedFilterIsActive } = this.state;

        return React.createElement(TableTemplate, {
            loading,
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            tableColumns: this.state.columns,
            fixedWidthColumns: this.fixedWidthColumns,
            enabledColumns,
            tariffTablelines: tariffLineIds,
            productionTablelines: productionLineIds,
            akTablelines: akLineIds,
            columnWidths: this.defaultColumnWidths,
            lineHeights: this.defaultLineHeights,
            archivedFilterIsActive,
            tariffTableRef: this.tariffTableRef,
            productionTableRef: this.productionTableRef,
            akTableRef: this.akTableRef,
            onAddButtonClick: this.onAddButtonClick,
            onCellEvent: this.onCellEvent,
            onEnabledColumnsChange: this.onEnabledColumnsChange,
            onArchivedFilterClick: this.onArchivedFilterClick,
            onColumnWidthsChange: this.onColumnWidthsChange,
            onLineHeightsChange: this.onLineHeightsChange,
        });
    }

    @autobind
    public scrollToCell(cellPosition: CellPosition) {
        const { lineId } = cellPosition;
        const { tariffLineIds, productionLineIds, akLineIds } = this.state;

        if (tariffLineIds.includes(lineId)) {
            this.tariffTable.scrollToCell(cellPosition);
            this.tariffTable.setHighlightPosition(cellPosition);
        }

        if (productionLineIds.includes(lineId)) {
            this.productionTable.scrollToCell(cellPosition);
            this.productionTable.setHighlightPosition(cellPosition);
        }

        if (akLineIds.includes(lineId)) {
            this.akTable.scrollToCell(cellPosition);
            this.akTable.setHighlightPosition(cellPosition);
        }
    }

    @autobind
    public async onCreativeRequestReload(newCreativeRequest: CreativeRequest) {
        if (!this.props.loading) {
            await this.updateTableLineIds();

            this.tableCellsStorage.clearAllCells();

            const visibleLinesIds = lodash.compact([
                ...this.tariffTable.getVisibleLinesIds(),
                ...this.productionTable.getVisibleLinesIds(),
                ...this.akTable.getVisibleLinesIds(),
            ]);

            await Promise.all([
                ...visibleLinesIds.map(async (lineId) => {
                    await this.updateLineCells(lineId);
                }),
                this.updateLineCells('tariffTotal'),
                this.updateLineCells('productionTotal'),
                this.updateLineCells('akTotal'),
            ]);
        }
    }

    @autobind
    public async onCreativeRequestBudgetItemUpdate(creativeRequestDonor: CreativeRequestDonor) {
        await this.updateTableLineIds();

        this.tableCellsStorage.clearAllCells();

        const visibleLinesIds = lodash.compact([
            ...this.tariffTable.getVisibleLinesIds(),
            ...this.productionTable.getVisibleLinesIds(),
            ...this.akTable.getVisibleLinesIds(),
        ]);

        await Promise.all([
            ...visibleLinesIds.map(async (lineId) => {
                await this.updateLineCells(lineId);
            }),
            this.updateLineCells('tariffTotal'),
            this.updateLineCells('productionTotal'),
            this.updateLineCells('akTotal'),
        ]);
    }

    @autobind
    public async onLineCreate(newLine: CreativeRequestItem) {
        await this.updateTableLineIds();
    }

    @autobind
    public async onLineRemoved(removedLine: CreativeRequestItem) {
        await this.updateTableLineIds();
    }

    @autobind
    public async onLineUpdate(lineId: string) {
        const tableType = await this.getTableTypeByLineId(lineId);

        const totalLineIdByTableType = {
            [TableType.Tariff]: 'tariffTotal',
            [TableType.Production]: 'productionTotal',
            [TableType.Ak]: 'akTotal',
        };

        await Promise.all([
            this.updateTableLineIds(),
            this.updateLineCells(lineId),
            this.updateLineCells(totalLineIdByTableType[tableType]),
        ]);
    }

    @autobind
    public async onBudgetItemsAdded(budgetItem: ProjectBudgetItem): Promise<void> {
        await this.updateTableLineIds();

        this.tableCellsStorage.clearAllCells();

        const visibleLinesIds = lodash.compact([
            ...this.tariffTable.getVisibleLinesIds(),
            ...this.productionTable.getVisibleLinesIds(),
            ...this.akTable.getVisibleLinesIds(),
        ]);

        await Promise.all([
            ...visibleLinesIds.map(async (lineId) => {
                await this.updateLineCells(lineId);
            }),
            this.updateLineCells('tariffTotal'),
            this.updateLineCells('productionTotal'),
            this.updateLineCells('akTotal'),
        ]);
    }

    @autobind
    public async onBudgetItemsRemoved(budgetItem: ProjectBudgetItem): Promise<void> {
        await this.updateTableLineIds();

        this.tableCellsStorage.clearAllCells();

        const visibleLinesIds = lodash.compact([
            ...this.tariffTable.getVisibleLinesIds(),
            ...this.productionTable.getVisibleLinesIds(),
            ...this.akTable.getVisibleLinesIds(),
        ]);

        await Promise.all([
            ...visibleLinesIds.map(async (lineId) => {
                await this.updateLineCells(lineId);
            }),
            this.updateLineCells('tariffTotal'),
            this.updateLineCells('productionTotal'),
            this.updateLineCells('akTotal'),
        ]);
    }

    @autobind
    public async onLineReloaded(newLine: CreativeRequestItem) {
        const lineId = newLine.model.id;

        const tableType = await this.getTableTypeByLineId(lineId);

        const totalLineIdByTableType = {
            [TableType.Tariff]: 'tariffTotal',
            [TableType.Production]: 'productionTotal',
            [TableType.Ak]: 'akTotal',
        };

        await Promise.all([
            this.updateTableLineIds(),
            this.updateLineCells(lineId),
            this.updateLineCells(totalLineIdByTableType[tableType]),
        ]);
    }

    @autobind
    public async onLineCommentsUpdate(lineId: string) {
        await this.updateLineCells(lineId);
    }

    public setColumnWidths(columnWidths: ColumnWidths) {
        if (columnWidths) {
            this.tariffTable.setColumnWidths(columnWidths);
            this.productionTable.setColumnWidths(columnWidths);
            this.akTable.setColumnWidths(columnWidths);
        }
    }

    @autobind
    public async getTableDataForXLSX(tableType: TableType): Promise<{
        columnsWidths: number[];
        headers: string[];
        rows: React.ReactText[][];
    }> {
        const columns = this.state.columns[tableType];

        const allColumns = [...columns.left, ...columns.center, ...columns.right];

        const { tariffLineIds, productionLineIds, akLineIds } = this.state;

        const lineIdsByTableType = {
            [TableType.Tariff]: tariffLineIds,
            [TableType.Production]: productionLineIds,
            [TableType.Ak]: akLineIds,
        };

        const lineIds = lineIdsByTableType[tableType];

        const lines = lodash.compact(lineIds.map((lineId) => this.props.getLine(lineId)));

        const activeLines = lines.filter((line) => line.model.status !== 'archived');

        const tableRefsByTableType = {
            [TableType.Tariff]: this.tariffTable,
            [TableType.Production]: this.productionTable,
            [TableType.Ak]: this.akTable,
        };

        const columnWidths = tableRefsByTableType[tableType].getColumnWidths();

        const headers = await Promise.all(
            allColumns.map(async (columnName) => {
                const params = await this.headerCellsStorage[tableType].getCellParams(columnName);

                const headerTitle = params.cellProps.title.replace('\n', '');

                return headerTitle;
            }),
        );

        const rows = await Promise.all(
            activeLines.map(
                async (line) =>
                    await Promise.all(
                        allColumns.map(async (columnName) => {
                            const lineId = line.model.id;

                            const cellParams = await this.tableCellsStorage.getCellParams({ lineId, columnName });

                            const columnsConfig = this.getColumnsConfig();

                            const cellType = columnsConfig[columnName].type;

                            let title = cellParams?.cellProps?.title || '';

                            if (
                                cellType === CellType.LineHeader ||
                                cellType === CellType.Text ||
                                cellType === CellType.Select ||
                                cellType === CellType.Input
                            ) {
                                title = title.toString();
                            }

                            if (cellType === CellType.FundsInput || cellType === CellType.FundsSelect) {
                                title = title.replaceAll(' ', '');
                                title = title.replaceAll(',', '.');
                                title = parseFloat(title);

                                if (lodash.isNaN(title)) {
                                    title = '';
                                }
                            }

                            if (cellType === CellType.Status) {
                                title = cellParams?.cellProps?.isActive ? 'Да' : 'Нет';
                            }

                            return title;
                        }),
                    ),
            ),
        );

        return {
            columnsWidths: allColumns.map((columnName) => columnWidths[columnName]),
            headers,
            rows,
        };
    }

    @autobind
    protected tariffTableRef(component: TableView) {
        this.tariffTable = component || null;
    }

    @autobind
    protected productionTableRef(component: TableView) {
        this.productionTable = component || null;
    }

    @autobind
    protected akTableRef(component: TableView) {
        this.akTable = component || null;
    }

    @autobind
    protected async onAddButtonClick(tableType: TableType) {
        await this.props.createLine(tableType);
    }

    @autobind
    protected async onArchivedFilterClick() {
        await this.toggleArchivedFilter();
    }

    @autobind
    protected async onLineArchiveClick(lineId: LineId) {
        this.props.archiveLine(lineId);
    }

    @autobind
    protected async onLineRestoreClick(lineId: LineId) {
        this.props.restoreLine(lineId);
    }

    @autobind
    protected async onCommentsButtonClick(cellPosition: CellPosition) {
        const { lineId, columnName } = cellPosition;

        this.props.updateCreativeUserConfig({
            sidebar: {
                visibility: true,
                selectedTab: Tabs.Comments,
            },
        });

        this.props.setWatchCommentsByItemAndColumn({
            creativeRequestItemId: lineId,
            column: columnName,
        });
    }

    @autobind
    protected onCellEditorClose() {
        this.tariffTable.setCursorPosition(null);
        this.productionTable.setCursorPosition(null);
        this.akTable.setCursorPosition(null);
    }

    @autobind
    protected onEnabledColumnsChange(newEnabledColumns: ColumnName[]) {
        const updatedEnabledColumns = lodash.uniq([
            ...newEnabledColumns,
            ColumnName.LineHeader,
            ColumnName.LineStatus,
            ColumnName.ActStatus,
        ]);

        this.setState(
            {
                enabledColumns: updatedEnabledColumns,
            },
            () => {
                this.props.updateCreativeUserConfig({
                    enabledColumns: updatedEnabledColumns,
                });
            },
        );
    }

    @autobind
    protected onColumnWidthsChange(changedTableType: TableType, newColumnsWidth: ColumnWidths) {
        const updatedColumnsWidth = lodash.clone(newColumnsWidth);

        const tablesToUpdate = lodash.without([TableType.Tariff, TableType.Production, TableType.Ak], changedTableType);

        lodash.forEach(this.state.columns[changedTableType], (columnsGroup, groupName: 'left' | 'center' | 'right') => {
            columnsGroup.forEach((columnName, columnIndex) => {
                tablesToUpdate.forEach((tableType) => {
                    const columnToUpdate = this.state.columns[tableType][groupName][columnIndex];

                    updatedColumnsWidth[columnToUpdate] = updatedColumnsWidth[columnName];
                });
            });
        });

        tablesToUpdate.forEach((tableType) => {
            this.getTableByType(tableType).setColumnWidths(updatedColumnsWidth);
        });

        this.updateCreativeUserConfig({ columnsWidth: updatedColumnsWidth });
    }

    @autobind
    protected onLineHeightsChange(lineHeights: LineHeights) {
        const tables = [this.tariffTable, this.productionTable, this.akTable];

        const updatedlineHeights = {
            ...tables.reduce((acc, table) => ({ ...acc, ...table.getLineHeights() }), {}),
            ...lineHeights,
        };

        this.updateCreativeUserConfig({ lineHeights: updatedlineHeights });
    }

    @autobind
    protected onTableEvent(eventType: TableEvent, tableType: TableType) {
        switch (eventType) {
            case TableEvent.Scroll:
                const tablesToUpdate = lodash.without(
                    [TableType.Tariff, TableType.Production, TableType.Ak],
                    tableType,
                );

                const scrollValue = this.getTableByType(tableType).getBodyScroll().x;

                tablesToUpdate.forEach((item) => {
                    const table = this.getTableByType(item);

                    if (table.getBodyScroll().x !== scrollValue) {
                        table.setHorizontalScroll(scrollValue);
                    }
                });

                break;

            case TableEvent.SizeChange:
                break;
        }
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.MouseSelection:
                await this.updateCell(position, true);
                this.tariffTable.setHighlightPosition(null);
                this.productionTable.setHighlightPosition(null);
                this.akTable.setHighlightPosition(null);
                break;

            case CellEvent.SelectionCancel:
                await this.updateCell(position, false);
                break;
        }
    }

    @autobind
    public getLeftFixedColumns(): Record<TableType, ColumnName[]> {
        return {
            [TableType.Tariff]: this.state.columns[TableType.Tariff].left,
            [TableType.Production]: this.state.columns[TableType.Production].left,
            [TableType.Ak]: this.state.columns[TableType.Ak].left,
        };
    }

    @autobind
    private onColumnFixClick(table: TableType, columnName: ColumnName) {
        const { columns } = this.state;

        const updatedColumns = lodash.cloneDeep(columns);

        const defaultColumns = this.makeDefaultColumns();

        const columnsGroup = lodash.findKey(this.state.columns[table], (columns, groupName) =>
            columns.includes(columnName),
        ) as 'left' | 'center' | 'right';

        const columnIndexInGroup = this.state.columns[table][columnsGroup].indexOf(columnName);

        [TableType.Tariff, TableType.Production, TableType.Ak].forEach((tableType) => {
            const allDefaultColumns = [...defaultColumns[tableType].left, ...defaultColumns[tableType].center];

            const columnToMove = updatedColumns[tableType][columnsGroup][columnIndexInGroup];

            const updatedLeftColumns = lodash.xor(updatedColumns[tableType].left, [columnToMove]);
            const updatedCenterColumns = lodash.xor(updatedColumns[tableType].center, [columnToMove]);

            updatedColumns[tableType].left = lodash.intersection(allDefaultColumns, updatedLeftColumns);
            updatedColumns[tableType].center = lodash.intersection(allDefaultColumns, updatedCenterColumns);
        });

        this.setState(
            {
                columns: updatedColumns,
            },
            () => {
                this.updateCreativeUserConfig({
                    tableColumns: updatedColumns,
                });
            },
        );
    }

    private initFromUserConfig() {
        const { userConfig } = this.props;

        if (userConfig.columnsWidth) {
            this.initColumnsWidthFromUserConfig();
        }

        if (userConfig.lineHeights) {
            this.initLineHeightsFromUserConfig();
        }

        if (userConfig.tableColumns) {
            this.initFixedColumnsFromUserConfig();
        }

        if (userConfig.enabledColumns) {
            this.initEnabledColumnsFromUserConfig();
        }

        if (userConfig.archivedFilterIsActive) {
            this.initArchivedFilterFromUserConfig();
        }
    }

    private makeDefaultEnabledColumns(
        columns: Record<TableType, Record<'left' | 'center' | 'right', ColumnName[]>>,
    ): string[] {
        const columnsByTableType = lodash.mapValues(columns, (item) => [...item.left, ...item.center, ...item.right]);

        const groupedColumns = columnsByTableType[TableType.Tariff].map((_, index) =>
            lodash.uniq([
                columnsByTableType[TableType.Tariff][index],
                columnsByTableType[TableType.Production][index],
                columnsByTableType[TableType.Ak][index],
            ]),
        );

        return [
            ...groupedColumns.map((columnNames) => columnNames.join('|')),
            ColumnName.LineHeader,
            ColumnName.LineStatus,
            ColumnName.ActStatus,
        ];
    }

    private initFixedColumnsFromUserConfig() {
        const columnsConfig = this.getColumnsConfig();

        const userConfigColumns = this.props.userConfig.tableColumns as Record<
            TableType,
            Record<'left' | 'center' | 'right', ColumnName[]>
        >;

        const updatedColumns = this.makeDefaultColumns();

        lodash.forEach(updatedColumns, (tableColumns, tableType: TableType) => {
            const leftFixedColumns = userConfigColumns[tableType].left;

            tableColumns.left = leftFixedColumns.filter((columnName) => !!columnsConfig[columnName]);
            tableColumns.center = lodash
                .without([...tableColumns.left, ...tableColumns.center], ...leftFixedColumns)
                .filter((columnName) => !!columnsConfig[columnName]);
        });

        this.setState({
            columns: updatedColumns,
        });
    }

    private initEnabledColumnsFromUserConfig() {
        const { enabledColumns } = this.state;

        const newEnabledColumns = this.props.userConfig.enabledColumns;

        this.setState({
            enabledColumns: lodash.uniq([
                ...lodash.intersection(newEnabledColumns, enabledColumns),
                ColumnName.LineHeader,
                ColumnName.LineStatus,
                ColumnName.ActStatus,
            ]),
        });
    }

    private initColumnsWidthFromUserConfig() {
        const { userConfig } = this.props;

        this.defaultColumnWidths = userConfig.columnsWidth;

        const tables = [this.tariffTable, this.productionTable, this.akTable];

        tables.forEach((table) => {
            if (table) {
                table.setColumnWidths(userConfig.columnsWidth);
            }
        });
    }

    private initLineHeightsFromUserConfig() {
        const { userConfig } = this.props;

        this.defaultLineHeights = userConfig.lineHeights;

        const tables = [this.tariffTable, this.productionTable, this.akTable];

        tables.forEach((table) => {
            if (table) {
                table.setLineHeights(userConfig.lineHeights);
            }
        });
    }

    private initArchivedFilterFromUserConfig() {
        const { archivedFilterIsActive } = this.props.userConfig;

        this.setState({
            archivedFilterIsActive,
        });
    }

    private async updateHeaderCell(table: TableType, columnName: ColumnName) {
        const cellParams = await this.columnHeaderFactory.makeColumnHeaderParams(table, columnName);

        this.headerCellsStorage[table].setCellParams(columnName, cellParams);
    }

    private async updateCell(position: CellPosition, edit = false) {
        const cellParams = await this.cellsFactory.makeCellParams(position, edit);

        this.tableCellsStorage.setCellParams(position, cellParams);
    }

    private async updateLineCells(lineId: LineId) {
        if (lineId) {
            const tableType = await this.getTableTypeByLineId(lineId);

            const table = this.getTableByType(tableType);

            const { left, center, right } = this.state.columns[tableType];

            const columnsToUpdate = [ColumnName.LineHeader, ...left, ...center, ...right];

            columnsToUpdate.forEach(async (columnName) => {
                const cellPosition = { lineId, columnName };

                const cellEditStatus = table.getCellEditStatus(cellPosition);

                this.updateCell(cellPosition, cellEditStatus);
            });
        }
    }

    private updateDefaultColumnWidths() {
        const columnsConfig = this.getColumnsConfig();

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    private updateFixedWidthColumns() {
        const columnsConfig = this.getColumnsConfig();

        this.fixedWidthColumns = lodash
            .keys(columnsConfig)
            .filter((columnName) => columnsConfig[columnName].disableWidthChange) as ColumnName[];
    }

    private updateTableColumns() {
        const { columns } = this.state;

        const columnsConfigParams = this.makeColumnsConfigParams();

        this.setState({
            columns: {
                [TableType.Tariff]: {
                    ...columns[TableType.Tariff],
                    right: MakeRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
                [TableType.Production]: {
                    ...columns[TableType.Production],
                    right: MakeRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
                [TableType.Ak]: {
                    ...columns[TableType.Ak],
                    right: MakeRightFixedColumns(columnsConfigParams) as ColumnName[],
                },
            },
        });
    }

    @autobind
    private async updateTableLineIds() {
        let lines = this.props.getLines();

        lines.sort((itemA: CreativeRequestItem, itemB: CreativeRequestItem) => itemA.model.number - itemB.model.number);

        if (this.state.archivedFilterIsActive) {
            lines = this.applyArchivedFilter(lines);
        }

        const creativeRequestGroups = await Promise.all(lines.map((item) => item.model.creativeRequestGroup));

        const items = lines.map((item, index) => ({
            id: item.model.id,
            creativeRequestGroup: creativeRequestGroups[index],
            actualCostWithoutVat: item.model.actualCostWithoutVat,
        }));

        const tariffLineIds: string[] = [];
        const productionLineIds: string[] = [];
        const akLineIds: string[] = [];

        items.forEach((item) => {
            switch (item.creativeRequestGroup.value) {
                case TableType.Tariff:
                    tariffLineIds.push(item.id);
                    break;

                case TableType.Production:
                    productionLineIds.push(item.id);
                    break;

                case TableType.Ak:
                    akLineIds.push(item.id);
                    break;
            }
        });

        if (
            items.some((item) => item.creativeRequestGroup.value === TableType.Tariff && item.actualCostWithoutVat > 0)
        ) {
            tariffLineIds.push('tariffTotal');
        }

        if (
            items.some(
                (item) => item.creativeRequestGroup.value === TableType.Production && item.actualCostWithoutVat > 0,
            )
        ) {
            productionLineIds.push('productionTotal');
        }

        if (items.some((item) => item.creativeRequestGroup.value === TableType.Ak)) {
            akLineIds.push('akTotal');
        }

        if (!lodash.isEqual(tariffLineIds, this.state.tariffLineIds)) {
            this.setState({ tariffLineIds });
        }

        if (!lodash.isEqual(productionLineIds, this.state.productionLineIds)) {
            this.setState({ productionLineIds });
        }

        if (!lodash.isEqual(akLineIds, this.state.akLineIds)) {
            this.setState({ akLineIds });
        }
    }

    private applyArchivedFilter(lines: CreativeRequestItem[]) {
        return lines.filter((item) => item.model.status !== 'archived');
    }

    private async toggleArchivedFilter() {
        return new Promise((resolve) => {
            const updatedArchivedFilter = !this.state.archivedFilterIsActive;

            this.setState(
                {
                    archivedFilterIsActive: updatedArchivedFilter,
                },
                () => {
                    this.updateCreativeUserConfig({ archivedFilterIsActive: updatedArchivedFilter });

                    resolve(true);
                },
            );
        });
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    @autobind
    private getAccessorParamsData(): Partial<AccessorParams> {
        return {
            project: this.props.getProject(),
            creativeRequest: this.props.getCreativeRequest(),
            creativeRequestLot: this.props.getCreativeRequestLot(),
            dictionariesByType: this.props.getDictionaries(),
            users: this.props.users,
        };
    }

    @autobind
    private makeColumnsConfigParams(): ColumnsConfigParams {
        return {
            displayStatusColumns: this.props.displayStatusColumns,
        };
    }

    @autobind
    private makeDefaultColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        let defaultLeftColumns = MakeLeftFixedColumns(columnsConfigParams) as ColumnName[];
        let defaultRightColumns = MakeRightFixedColumns(columnsConfigParams) as ColumnName[];

        return {
            [TableType.Tariff]: {
                left: defaultLeftColumns,
                center: MakeTariffTableColumns(columnsConfigParams) as ColumnName[],
                right: defaultRightColumns,
            },
            [TableType.Production]: {
                left: defaultLeftColumns,
                center: MakeProductionTableColumns(columnsConfigParams) as ColumnName[],
                right: defaultRightColumns,
            },
            [TableType.Ak]: {
                left: defaultLeftColumns,
                center: MakeAkTableColumns(columnsConfigParams) as ColumnName[],
                right: defaultRightColumns,
            },
        };
    }

    @autobind
    private async getTableTypeByLineId(lineId: LineId): Promise<TableType> {
        const tableTypeByTotalLineId = {
            tariffTotal: TableType.Tariff,
            productionTotal: TableType.Production,
            akTotal: TableType.Ak,
        };

        if (tableTypeByTotalLineId[lineId]) {
            return tableTypeByTotalLineId[lineId];
        }

        const line = this.props.getLine(lineId);

        const creativeRequestGroup = await line.model.creativeRequestGroup;

        return creativeRequestGroup.value as TableType;
    }

    private getTableByType(tableType: TableType): TableView {
        const tableByType = {
            [TableType.Tariff]: this.tariffTable,
            [TableType.Production]: this.productionTable,
            [TableType.Ak]: this.akTable,
        };

        return tableByType[tableType];
    }

    @autobind
    private getContracts(): CreativeRequestContract[] {
        const lot = this.getCreativeRequestLot();

        const contracts = this.props.getContracts();

        return contracts[`lot${lot}`];
    }

    @autobind
    private getCreativeRequestLot(): 1 | 2 {
        const lot = this.props.getCreativeRequestLot();

        return parseInt(lodash.first(lot.match(/\d/g)), 10) as 1 | 2;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        userConfig: getCreativeUserConfig(state),
        userConfigLoaded: getLoadingStatus(state) === LoadingStatus.LOADED,
        users: getAllUsers(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            updateCreativeUserConfig,
            setWatchCommentsByItemAndColumn: setWatchCommentsByItemAndColumn,
        },
        dispatch,
    );
}
