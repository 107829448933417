import * as React from 'react';
import { Moment } from 'moment';
import { Utils } from '@common/Utils';

import { Icon, IconType } from 'sber-marketing-ui';
import { CardSubInfo } from '../CardSubInfo';

import * as style from './CardDate.scss';

interface Props {
    startDate: Moment;
    endDate?: Moment;
    loading?: boolean;
}

const CalendarIcon: React.FC = () => (
    <div className={style.calendarIconWrapper}>
        <Icon className={style.calendarIcon} type={IconType.CALENDAR_REDESIGN_TWO} svgSize={14} />
    </div>
);

export const CardDate: React.FC<Props> = ({ startDate, endDate, loading }) => {
    const dateFormatted = !loading ? `${formatDate(startDate)}${endDate ? ` — ${formatDate(endDate)}` : ''}` : '';

    return (
        <CardSubInfo
            className={style.loading}
            loading={loading}
            tooltip={dateFormatted}
            qaId="cardDate"
            qaTitle="Дата"
            qaValue={dateFormatted}
        >
            <CalendarIcon />
            {dateFormatted}
        </CardSubInfo>
    );
};

const formatDate = (date: Moment) => {
    return Utils.formatDateWithYear(date);
};
