import * as React from 'react';

import { CreativeModal } from '../../CreativeModal';
import { Loader } from '../../../../../modules';
import type { TypeCreative } from 'sber-marketing-types/backend';

import * as styles from './styles.scss';

interface Props {
    specId: string;
    isCreativeSomeFilled: boolean;
}

export const CreativeCell: React.FC<Props> = ({ specId, isCreativeSomeFilled }) => {
    const [isOpen, setOpen] = React.useState(false);

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    const onSave = React.useCallback((updatedCreative: TypeCreative) => {
        updatedCreative && Loader.getInstance().updateAutopilotCreative(updatedCreative);
    }, []);

    return specId ? (
        <>
            <div className={styles.link} onClick={() => setOpen(true)}>
                {isCreativeSomeFilled ? 'Редактировать поля' : 'Заполнить поля'}
            </div>
            {isOpen && <CreativeModal specId={specId} onSave={onSave} onClose={onClose} />}
        </>
    ) : (
        <div className={styles.hint}>Спецификация отсутствует</div>
    );
};
