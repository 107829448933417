import { groupBy } from 'lodash';
import { CreativeRequestComment, CreativeRequestItemComment } from '@api';

export function useCommentsReplyChain<T extends CreativeRequestComment | CreativeRequestItemComment>(
    comments: T[],
    commentIdToReplyTo: string,
) {
    const commentsByReplyId = groupBy(comments, (comment) => comment.model.replyId || null) || {};
    const commentToReplyTo = comments?.find((comment) => comment.model.id === commentIdToReplyTo);

    const commentsToDisplay = commentsByReplyId[commentIdToReplyTo];

    return {
        commentsByReplyId,
        commentToReplyTo,
        commentsToDisplay,
    };
}
