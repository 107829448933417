import * as React from 'react';
import * as styles from './Amount.scss';
import classnames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';
import { formatAmount } from '../utils';

export enum IdCardTypes {
    to = 'to',
    from = 'from',
}

interface Props {
    source: number;
    executor: number;
    amountFrom: number;
    amountTo: number;
    willAmountSource: number;
    wilAmountExecutor: number;
    month: string;
}

export const Amount = ({
    source,
    executor,
    amountFrom,
    amountTo,
    willAmountSource,
    wilAmountExecutor,
    month,
}: Props): JSX.Element => {
    return (
        <div className={styles.root}>
            <div className={styles.section}>
                <div className={styles.row}>
                    <span>Из строки </span>
                    <span className={styles.boldGrey}>ID {source} </span>
                    <span>будет вычтено:</span>
                </div>
                <div className={classnames(styles.row, styles.boldAmount)}>{formatAmount(amountFrom)} ₽</div>
                <div className={styles.row}>
                    <span>План-Резерв за год станет: </span>
                    <span className={styles.bold}>{formatAmount(willAmountSource)} ₽</span>
                </div>
            </div>

            <Icon svgSize={20} type={IconType.ARROW16_STRAIGHT_RIGHT} color="grey" />

            <div className={styles.section}>
                <div className={styles.row}>
                    <span>В ячейку </span>
                    <span className={styles.boldGrey}>
                        ID {executor} {month}{' '}
                    </span>
                    <span>будет внесено:</span>
                </div>
                <div className={classnames(styles.row, styles.boldAmount)}>{formatAmount(amountTo)} ₽</div>
                <div className={styles.row}>
                    <span>План-Резерв за год станет: </span>
                    <span className={classnames(styles.bold, wilAmountExecutor < 0 && styles.redText)}>
                        {formatAmount(wilAmountExecutor)} ₽
                    </span>
                </div>
            </div>
        </div>
    );
};
