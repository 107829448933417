import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { StoreState } from '@store';
import { loadDepartments, StoreTypes as DepartmentsStoreTypes } from '@store/departments';
import { getMyTasksPageFilters, getDashboardUserConfigState } from '@store/userConfig';
import { getActivityTasksUserConfigState } from '@store/userConfig/activityTasks';
import { LoadingStatus } from '@store/commonTypes';
import { getTasksWorkTypes } from '@store/workTypes';
import {
    fetchMoreTasks,
    getTasksListByActivityId,
    getCanBeLoadedMore,
    getStatus,
    getError,
    setType,
    setActivityId,
    TasksListType,
    resetTasksList,
    resetMyTasks,
    loadWorkTypes,
} from '@store/tasksList';

import { TasksListMappedActions, TasksListMappedState, TasksListStoreProps } from './types';
import { TasksListContainer } from './TasksListContainer';
import { setCardType } from '@store/tasksList/actions';
import { TaskCardType } from '@store/tasksList/types';
import { getActivityStageState } from '@store/activityTasksPage/stages';

const mapStateToProps = (state: StoreState, { activityId, type }: TasksListStoreProps): TasksListMappedState => {
    const tasks = getTasksListByActivityId(state, activityId);

    let filters;
    let userConfigLoadingStatus: LoadingStatus;
    let activityStages;
    switch (type) {
        case TasksListType.MY_TASKS:
            filters = getMyTasksPageFilters(state);
            userConfigLoadingStatus = getDashboardUserConfigState(state).loadingStatus;
            break;
        case TasksListType.ACTIVITY_TASKS:
            const activityTasksUserConfig = getActivityTasksUserConfigState(state);

            filters = activityTasksUserConfig.fields.entries[activityId]?.filters;
            userConfigLoadingStatus = activityTasksUserConfig.status;
            activityStages = getActivityStageState(state).existingStages.entities.filter((stage) => stage.active);
            break;
    }

    return {
        filters,
        loadingStatus: getStatus(state),
        userConfigLoadingStatus,
        error: getError(state),
        tasks,
        canBeLoadedMore: getCanBeLoadedMore(state),
        tasksWorkTypes: getTasksWorkTypes(state, tasks),
        activityStages,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<StoreState>, { type }: TasksListStoreProps): TasksListMappedActions => {
    return {
        fetchMoreTasks: () => dispatch(fetchMoreTasks(type)),
        setType: (type: TasksListType) => dispatch(setType(type)),
        setActivityId: (id: number | null) => dispatch(setActivityId(id)),
        setCardType: (type: TaskCardType) => dispatch(setCardType(type)),
        clearTasksList: () => dispatch(resetTasksList()),
        resetMyTasks: () => dispatch(resetMyTasks()),
        loadDepartments: () => dispatch(loadDepartments({ store: DepartmentsStoreTypes.ALL_DEPARTMENTS })),
        loadWorkTypes: () => dispatch(loadWorkTypes(null)),
    };
};

const withStore = connect(mapStateToProps, mapDispatchToProps);

export const TasksListStore: React.ComponentType<TasksListStoreProps> = withStore(TasksListContainer);
