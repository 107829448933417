import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useGetActivityQuery, useGetTaskQuery, useReadTaskMutation } from '@api';

import { DatesFormatter } from '@common/Utils';

import { Flex, Label, SkeletonText } from '@common/components';

import { ActivityLink, ActivityFavorite, CopyActivityTitleIconButton } from '@modules/activity/components';
import { CopyTaskLinkIconButton, CopyTaskTitleIconButton, TaskTitle } from '@modules/task/components';
import { useFirstLineHeader } from '@common/Page/hooks';
import { QuickAccessIcons } from '@common/QuickAccessIcons';

import { TaskNotFound, TaskPageAside, TaskPageContent } from './components';

import * as styles from './TaskPage.scss';

export interface ActivityTaskPageParams {
    activityId: string;
    taskId: string;
}

export const TaskPage: React.FC = () => {
    const { taskId, activityId } = useParams<ActivityTaskPageParams>();
    const { isError, isLoading } = useGetTaskQuery({ id: taskId }, { refetchOnMountOrArgChange: true });
    const activityNumberId = Number(activityId);
    const { data: activity, isLoading: isActivityLoading } = useGetActivityQuery({ id: activityNumberId });
    const [read] = useReadTaskMutation();

    React.useEffect(() => {
        read(taskId);
    }, [taskId]);

    const subheader = isActivityLoading ? (
        <SkeletonText width={220} />
    ) : (
        <>
            {activity.productName}, {DatesFormatter.ddMonthyy(new Date(activity.realizationStart))} —{' '}
            {DatesFormatter.ddMonthyy(new Date(activity.realizationEnd))}
        </>
    );

    useFirstLineHeader(
        <Flex align="center" padding={[2, 24, 6, 120]} gap={8}>
            <Flex flex vertical className={styles.headerContent}>
                <Flex flex gap={8} align="center">
                    <ActivityFavorite activityId={activityNumberId} />
                    <ActivityLink
                        style={{ display: 'inline' }}
                        className={styles.activityName}
                        activityId={activityNumberId}
                    />
                    <CopyActivityTitleIconButton activityId={activityNumberId} />
                    <span className={styles.headerSeparator}>/</span>
                    <TaskTitle className={styles.taskTitle} taskId={taskId} />
                    <CopyTaskLinkIconButton iconSize={14} className={styles.taskTitleCopy} taskId={taskId} />
                    <CopyTaskTitleIconButton className={styles.taskTitleCopy} taskId={taskId} />
                </Flex>
                <Label data-qa-id="TaskPage__subheader" padding={[0, 0, 0, 20]} className={styles.subheader}>
                    {subheader}
                </Label>
            </Flex>
            <QuickAccessIcons />
        </Flex>,
        [isActivityLoading],
    );

    if (!isLoading && isError) {
        return <TaskNotFound />;
    }

    return (
        <div className={styles.root}>
            <TaskPageContent />
            <TaskPageAside />
        </div>
    );
};
