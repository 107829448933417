import * as React from 'react';
import classNames from 'classnames';

import * as style from './CreativePage.scss';

import { OpacityTransition, WithTooltip, TooltipAnchor, Icon, IconType } from 'sber-marketing-ui';
import { StagesListWidget } from './StagesListWidget';
import { RequestStageWidget, TableWidget } from './StageWidgets';
import { Sidebar } from './Sidebar';
import { Name } from './Name';
import { SidebarButton } from './SidebarButton';
import { ActionsMenu } from './ActionsMenu';
import { useSelector } from 'react-redux';
import { StoreState } from '@store/index';

interface Props {
    loading: boolean;
    currentStage: number;
    selectedStage: number;
    activityId: number;
    creativeRequestId: string;
    openedSidebar: boolean;
    onCloseSidebar: () => void;
}

export const CreativePageTemplate = ({
    loading,
    // currentStage,
    // selectedStage,
    activityId,
    creativeRequestId,
    openedSidebar = false,
    onCloseSidebar = () => {},
}: Props): JSX.Element => {
    const selectedStage = useSelector((state: StoreState) => state.creativePage.rest.selectedStage);
    const currentStage = useSelector((state: StoreState) => state.creativePage.rest.currentStage);

    return (
        <OpacityTransition start={!loading}>
            <div className={classNames(style.root, openedSidebar ? style.rootWithIndent : '')}>
                <div className={style.content}>
                    {/* this block strongly depend by 'useStatusesScroll.ts' */}
                    <div className={style.stageList} id="sticyHeaderWithStagesList">
                        <div className={style.header}>
                            <Name />
                            <ActionsMenu />
                            <SidebarButton />
                        </div>

                        <StagesListWidget />
                    </div>

                    <div className={style.stageWidget}>
                        {selectedStage === 0 && (
                            <RequestStageWidget activityId={activityId} creativeRequestId={creativeRequestId} />
                        )}

                        {selectedStage === 1 && (
                            <TableWidget creativeRequestId={creativeRequestId} currentStage={currentStage} />
                        )}
                    </div>
                </div>

                {openedSidebar && (
                    <div className={style.sidebar}>
                        <Sidebar creativeRequestId={creativeRequestId} onClose={onCloseSidebar} />
                    </div>
                )}
            </div>
        </OpacityTransition>
    );
};

interface DownloadButtonProps {
    onClick: () => void;
}

export const DownloadButton = ({ onClick }: DownloadButtonProps) => {
    return (
        <div className={style.downloadButton} onClick={onClick}>
            <WithTooltip content={'Скачать таблицу'} anchor={TooltipAnchor.LEFT}>
                <Icon type={IconType.DOWNLOAD_DOCUMENT} svgSize={20} />
            </WithTooltip>
        </div>
    );
};
