import * as React from 'react';
import { scroller } from 'react-scroll';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Brief } from 'sber-marketing-types/frontend/Brief2';
import type { BudgetItem } from '@mrm/budget';
import type { Activity, Task } from '@store/brief/types';
import type { NavItem } from './types';

import { ContentNav } from './ContentNav';
import type { StoreState } from '@store';
import { getBriefState } from '@store/brief/selectors';

const SCROLL_TOP_OFFSET = -90;
const LINE_TOP_OFFSET = 200;
const SCROLL_DURATION = 500;

interface Props extends Partial<MapProps> {
    selectedSectionId?: string;
}

interface State {
    selectedSectionId: string;
}

interface MapProps {
    briefs: Brief[];
    activity: Activity;
    tasks: lodash.Dictionary<Task>;
    budgetItem: BudgetItem;
}

@(connect(mapStateToProps) as any)
export class ContentNavContainer extends React.PureComponent<Props, State> {
    private scrollContainer: HTMLElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedSectionId: this.props.selectedSectionId || '',
        };
    }

    public componentDidMount() {
        this.scrollContainer = document.getElementById('pageContent');

        this.scrollContainer.addEventListener('scroll', this.handleScroll);

        if (this.state.selectedSectionId) {
            this.scrollTo(`section ${this.state.selectedSectionId}`);
        }
    }

    public componentWillUnmount() {
        this.scrollContainer.removeEventListener('scroll', this.handleScroll);
    }

    public render(): JSX.Element {
        return React.createElement(ContentNav, {
            items: this.items,
        });
    }

    @autobind
    private handleScroll() {
        const newSelectedSectionId = this.getNewSelectedSectionId();

        if (this.getNewSelectedSectionId() !== this.state.selectedSectionId) {
            this.setState({
                selectedSectionId: newSelectedSectionId,
            });
        }
    }

    private get items(): NavItem[] {
        const { briefs, tasks, activity, budgetItem } = this.props;
        const { selectedSectionId } = this.state;

        return briefs.map((brief) => {
            const sectionId =
                tasks && tasks[brief.id]
                    ? String(tasks[brief.id].id)
                    : activity
                    ? String(activity.id)
                    : String(budgetItem.id);

            const isSelected = sectionId === selectedSectionId;

            const blocksHash = {};
            const blocks = lodash
                .sortBy(brief.blocks, (item) => item.order)
                .map((block) => ({
                    id: `${brief.id}-${block.id}`,
                    briefBlockId: block.briefBlockId ? `${brief.id}-${block.briefBlockId}` : null,
                    title: block.name,
                    tooltipMessage: block.tooltipMessage,
                    isClientHide: block.isClientHide,
                    isSelected: selectedSectionId === `${brief.id}-${block.id}`,
                    onClick: () => this.onItemClick(`${brief.id}-${block.id}`),
                    childrenNavItems: [],
                }));
            blocks.forEach((block) => {
                blocksHash[block.id] = block;
            });
            blocks.forEach((block) => {
                if (block.briefBlockId && blocksHash[block.briefBlockId]) {
                    blocksHash[block.briefBlockId].childrenNavItems = [
                        ...blocksHash[block.briefBlockId].childrenNavItems,
                        block,
                    ];
                }
            });
            const blocksRoot = blocks.filter((item) => !item.briefBlockId && !item.isClientHide);

            return {
                id: brief.id,
                title:
                    tasks && tasks[brief.id]
                        ? `Бриф задачи "${tasks[brief.id].title}"`
                        : activity
                        ? `Бриф проекта: "${activity.name}"`
                        : `Бриф строки "${budgetItem.sapComment}"`,
                isSelected,
                onClick: () => this.onItemClick(sectionId),
                childrenNavItems: blocksRoot,
            };
        });
    }

    @autobind
    private onItemClick(id: string) {
        this.scrollTo(`section ${id}`);
    }

    private scrollTo(name: string) {
        const options = {
            offset: SCROLL_TOP_OFFSET,
            smooth: 'easeOutQuad',
            duration: SCROLL_DURATION,
            containerId: 'pageContent',
        };

        scroller.scrollTo(name, options);
    }

    private getNewSelectedSectionId(): string {
        const sectionsElement = document.querySelectorAll<HTMLDivElement>('.section[data-section-id]');

        const sectionsTopOffsets = Array.from(sectionsElement).map((sectionElement) => ({
            id: sectionElement.dataset.sectionId,
            topOffset: sectionElement.getBoundingClientRect().top,
        }));

        return sectionsTopOffsets.reduce((acc, section) => {
            const isSectionOverLine = LINE_TOP_OFFSET - section.topOffset < 0;
            return isSectionOverLine ? acc : section.id;
        }, '');
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { currentBriefs, initialActivity, initialTasks, budgetItem } = getBriefState(state);

    return {
        briefs: lodash.values(currentBriefs),
        activity: initialActivity,
        tasks: initialTasks,
        budgetItem,
    };
}
