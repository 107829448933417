import { BudgetItem } from '@mrm/budget';
import { BudgetItemDirection } from './PlanTransferModal';

export enum FundsFilds {
    plannedFunds = 'plannedFunds',
    reservedFunds = 'reservedFunds',
}

interface GetIdCardDataProps {
    budgetItem: BudgetItem;
    directionType: BudgetItemDirection;
}

interface GetIdCardDataResult {
    id: string | number;
    name: string;
    division: string;
    tool: string;
    direction: string;
    plan: string | number;
}

export const getIdCardData = ({ budgetItem, directionType }: GetIdCardDataProps): GetIdCardDataResult => {
    return {
        id: budgetItem?.serialNumber || '-',
        name: budgetItem?.businessTarget || '-',
        division: budgetItem?.dictionary?.division?.value || '-',
        tool: budgetItem?.dictionary?.tool?.value || '-',
        direction: budgetItem?.dictionary?.direction?.value || '-',
        plan:
            directionType === BudgetItemDirection.from
                ? getSummFromBudgetItem(budgetItem, FundsFilds.plannedFunds) -
                  getSummFromBudgetItem(budgetItem, FundsFilds.reservedFunds)
                : getSummFromBudgetItem(budgetItem, FundsFilds.reservedFunds) -
                  getSummFromBudgetItem(budgetItem, FundsFilds.plannedFunds),
    };
};

export const getSummFromBudgetItem = (budgetItem: BudgetItem, fundsFild: FundsFilds): number =>
    Object.entries(budgetItem[fundsFild])
        .map((item) => item[1])
        .reduce((accumulator, currentValue) => accumulator + currentValue);

export const getZeroIfMinus = (amount: number): number => (amount > 0 ? amount : 0);

export const formatAmount = (value: number) => new Intl.NumberFormat('ru-RU').format(value / 100);
