import * as React from 'react';
import classNames from 'classnames';

import { DropdownInput, Button, Flex, FlexProps, Label, Error } from '@sbermarketing/mrm-ui';

import { UserConfigType } from 'sber-marketing-types/openid';

import { useAddChannelMutation, useGetTaskQuery, useGetUserConfigQuery } from '@api';

import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';

import { ChannelParticipants } from '@modules/channel/components';
import { TaskParticipantOptions } from '@modules/task/components';

import { Channel } from '../Channel';

import * as styles from './Channels.scss';

export interface ChannelsProps extends FlexProps {
    taskId: string;
    activityId?: number;
}

export function Channels({ className, taskId, activityId, children, loading, ...props }: ChannelsProps) {
    const { data: { task } = {}, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const [addChannel, { isLoading: isAddingChannel }] = useAddChannelMutation();
    const { data: authUser } = useGetUserConfigQuery(UserConfigType.Common);
    const [isDropdownShow, setDropdownShow] = React.useState(false);
    const [isRequiredError, setRequiredError] = React.useState(false);
    const [newChannelName, setNewChannelName] = React.useState('');
    const [newChannelParticipants, setNewChannelParticipants] = React.useState<number[]>([]);
    const inputRef = React.useRef<HTMLInputElement>();

    const {
        isLoading: isAccessLoading,
        access: [canAdd],
    } = useTaskAccess([TaskAccessAction.addChannel], taskId, activityId);

    const isLoading = isAccessLoading || isTaskLoading || loading;
    const channels = [...(task?.chanels || [])].reverse();

    const handleReset = () => {
        setRequiredError(false);
        setNewChannelName('');
        setNewChannelParticipants([]);
    };

    const handleNewChannelNameChange = (value: string) => {
        setNewChannelName(value);
        setRequiredError(false);
    };

    const handleAddChannel = async () => {
        if (!newChannelName) {
            setRequiredError(true);
            inputRef.current?.focus();
            return;
        }

        await addChannel({
            taskId,
            title: newChannelName,
            participantIds: newChannelParticipants.join(',') || undefined,
        });
        handleReset();

        inputRef.current.focus();
    };

    const handleShowChangeDropdown = (show: boolean) => {
        if (!show) {
            handleReset();
        }

        setDropdownShow(show);
    };

    const handleSelect = (userIds: number[]) => {
        setNewChannelParticipants(userIds);
    };

    return (
        <Flex gap={[8, 24]} wrap {...props} loading={isLoading} className={classNames(styles.root, className)}>
            {channels.map((channel) => (
                <Channel channel={channel} taskId={taskId} key={channel.id} />
            ))}
            {canAdd && (
                <DropdownInput<string>
                    view={isRequiredError ? 'danger' : 'default'}
                    data-qa-id="Channels__addChannel"
                    inputProps={{ autoComplete: 'off' }}
                    inputRef={inputRef}
                    ghost
                    dropdownProps={{ width: 320, gap: 8 }}
                    className={styles.add}
                    autoPlace
                    placeholder={isDropdownShow ? 'Название канала' : '+ Добавить канал'}
                    value={newChannelName}
                    onShowChangeDropdown={handleShowChangeDropdown}
                    onChange={handleNewChannelNameChange}
                    onEnter={handleAddChannel}
                >
                    <ChannelParticipants
                        participantIds={newChannelParticipants}
                        onChangeParticipantIds={setNewChannelParticipants}
                    />
                    <Label>Добавить участников</Label>
                    <TaskParticipantOptions
                        excludeUserIds={[...newChannelParticipants, ...(authUser ? [authUser.config.userId] : [])]}
                        multiple
                        selected={newChannelParticipants}
                        onSelect={handleSelect}
                        taskId={taskId}
                    />
                    <Button
                        data-qa-id="Channels__addButton"
                        handleDisabled
                        loading={isAddingChannel}
                        onClick={handleAddChannel}
                        view="primary"
                    >
                        Создать канал
                    </Button>
                    {isRequiredError && <Error>Введите название канала</Error>}
                </DropdownInput>
            )}
        </Flex>
    );
}
