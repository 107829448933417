import * as React from 'react';

import { ImportFileButton } from '../ImportFileButton';
import { ErrorMessage } from '../ErrorMessage';

import * as styles from './FileSelectionState.scss';

interface Props {
    budgetId: string;
}

export function FileSelectionState({ budgetId }: Props): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.title}>Приложите .xls файл финального медиаплана:</div>

            <ImportFileButton budgetId={budgetId} />

            <ErrorMessage />
        </div>
    );
}
