import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import { isEmpty, includes } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { RoleId } from 'sber-marketing-types/backend';

// import { UserApi } from '@api';
// import { DepartmentApi } from '../../../api/DepartmentApiNew';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { buildPermissions } from '@store/taskEditor2';
import { User } from '@store/user';
import { getLoginUser } from '@store/user/selector';
import * as departmentsStore from '@store/departments';
import { selectors, cancelTask, moveToWorkTask } from '@store/taskPage';
import { TaskExecutor as TaskExecutorTemplate } from './TaskExecutor';

import { Utils } from '@common/Utils';

/** Task executor container properties */
export interface TaskExecutorContainerProps {
    /** Additional CSS class */
    className?: string;
    taskId: string;
    tagsEditorId: string;
}

/** Mapped Redux state properties */
interface MappedProps {
    /** Executor identifier */
    executorId: number | null | undefined;
    /** Department executor identifier */
    departmentExecutorId: string;
    status: string;
    authorId: number;
    user: User;
    activityAuthorId: number;
    activityStatus: string;
    activityOrganizationId: string;
    hasDescription: boolean;
    hasExecutor: boolean;
    hasBudgetApproval: boolean;
    areDepartmentsLoaded: boolean;
    showAddExecutorBUtton: boolean;
    isRequestInProgress: boolean;
}

/** Mapped Redux actions */
interface MappedActions {
    loadDepartments: () => void;
    moveToWorkTask(): void;
    cancelTask(): void;
}

interface RouterProps {
    activityId: string;
    taskId: string;
}

interface Props
    extends TaskExecutorContainerProps,
        Partial<MappedProps>,
        Partial<MappedActions>,
        Partial<RouteComponentProps<RouterProps>> {}

/** Task executor container state */
interface TaskExecutorContainerState {
    /** Executor input disabled flag */
    isExecutorInputDisabled: boolean;
    /** If "true" no description error modal will be opened */
    isNoDescriptionModalOpened: boolean;
}

const mapStateToProps = (state: StoreState, props: TaskExecutorContainerProps): MappedProps => {
    const {
        status,
        executorId,
        departmentExecutorId,
        authorId,
        isRequestInProgress,
        organizationId,
        canEdit,
        createdBy,
    } = selectors.getTaskInfo(state);
    const activity = selectors.getActivity(state);
    const user = getLoginUser(state);
    const { description, workType, budgetApproval, deadlineAt } = selectors.getTaskInfo(state);
    const workTypeItem = selectors.getWorkTypeById(state, workType);
    const areDepartmentsLoaded =
        departmentsStore.getLoadingStatus(state, departmentsStore.StoreTypes.ALL_DEPARTMENTS) === LoadingStatus.LOADED;

    const showAddExecutorBUtton =
        status !== TaskStatus.Closed &&
        buildPermissions({
            user,
            task: {
                organizationId,
                authorId,
                executorId,
                canEdit,
                createdBy,
                status,
            },
            activity: {
                authorId: activity.authorId,
                responsibleId: activity.responsibleId,
            },
        }).canChangeExecutorDeadlineAndFiles;

    return {
        executorId,
        departmentExecutorId,
        status,
        authorId,
        user,
        activityAuthorId: activity ? activity.authorId : null,
        activityStatus: activity ? activity.status : null,
        activityOrganizationId: activity ? activity.organizationId : null,
        hasDescription: !isEmpty(description),
        hasExecutor: !!executorId && !!departmentExecutorId,
        hasBudgetApproval: Utils.addBudgetControlsForSelectedWorkType(workTypeItem?.name)
            ? !!budgetApproval?.clientDivisionId &&
              !!budgetApproval?.clientName &&
              !!budgetApproval?.budgetItemIds?.length &&
              !!budgetApproval?.segmentId &&
              !!budgetApproval?.productId &&
              !!budgetApproval?.period &&
              !!budgetApproval?.productId &&
              (budgetApproval?.hasLot ? !!budgetApproval.mediaRequest : true) &&
              (budgetApproval?.hasTitle ? !!budgetApproval.project && !!budgetApproval.naming : true) &&
              !!deadlineAt
            : true,
        areDepartmentsLoaded,
        isRequestInProgress,
        showAddExecutorBUtton,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): MappedActions => ({
    moveToWorkTask: () => dispatch(moveToWorkTask(null)),
    cancelTask: () => dispatch(cancelTask(null)),
    loadDepartments: () =>
        dispatch(
            departmentsStore.loadDepartments({
                store: departmentsStore.StoreTypes.ALL_DEPARTMENTS,
            }),
        ),
});

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class TaskExecutorContainer extends React.Component<Props, TaskExecutorContainerState> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isNoDescriptionModalOpened: false,
            isExecutorInputDisabled: true,
        };
    }

    public async componentDidMount(): Promise<void> {
        // const {
        //     authorId,
        //     executorId,
        //     status,
        //     user: {
        //         attributes: loginnedUser
        //     }
        // } = this.props;

        this.props.loadDepartments();

        // let supervisorCanChangeParticipant = false;
        // const userIsSupervisorOrDirector = loginnedUser.roles.some(role => role.id === 5 || role.id === 13);
        // if (userIsSupervisorOrDirector) {
        //     const subdepartmentIds = (await DepartmentApi.getListByParent(loginnedUser.departmentId))
        //         .map(department => department.id);
        //     const usersBySubdepartment = (await UserApi.getUserListFiltered({
        //         departmentIds: subdepartmentIds
        //     })).map(user => user.id);
        //     supervisorCanChangeParticipant = usersBySubdepartment.some(user =>
        //         user === authorId || user === executorId
        //     );
        // }

        // this.setState(() => ({
        //         supervisorCanChangeParticipant
        // }));
    }

    public render(): JSX.Element {
        const {
            taskId,
            executorId,
            departmentExecutorId,
            className,
            areDepartmentsLoaded,
            showAddExecutorBUtton,
            isRequestInProgress,
            tagsEditorId,
            hasDescription,
            hasExecutor,
            hasBudgetApproval,
        } = this.props;

        const { isNoDescriptionModalOpened, isExecutorInputDisabled } = this.state;

        return (
            <TaskExecutorTemplate
                taskId={taskId}
                hasDescription={hasDescription}
                hasExecutor={hasExecutor}
                hasBudgetApproval={hasBudgetApproval}
                isStatusButtonDisable={this.isStatusButtonDisable}
                isChangeExecutorButtonDisable={this.isChangeExecutorButtonDisable}
                buttonLabel={this.getButtonLabel()}
                onChangeTaskStatusClick={this.onChangeTaskStatusClick}
                onChangeExecutorClick={this.onChangeExecutorClick}
                onSuggestItemSelect={this.onSuggestItemSelect}
                onTaskBriefButtonClick={this.onTaskBriefButtonClick}
                executorId={executorId}
                departmentExecutorId={departmentExecutorId}
                isExecutorInputDisabled={isExecutorInputDisabled}
                isNoDescriptionModalOpened={isNoDescriptionModalOpened}
                className={className}
                onNoDescriptionModalClose={this.onNoDescriptionModalClose}
                showAddExecutorButton={showAddExecutorBUtton}
                departmentsLoaded={areDepartmentsLoaded}
                isRequestInProgress={isRequestInProgress}
                tagsEditorId={tagsEditorId}
            />
        );
    }

    protected get isStatusButtonDisable(): boolean {
        const {
            status,
            user: {
                attributes: { id, roles, organizationId },
            },
            authorId,
            executorId,
            activityStatus,
            activityOrganizationId,
        } = this.props;

        const isUserAuthor = id == authorId;
        const isUserExecutor = id == executorId;

        const isUserDirector = includes(
            roles.map((role) => role.id),
            RoleId.Director,
        );
        const isUserInOrganizationOfTask = organizationId === activityOrganizationId;

        const isUserDirectorOfCurrentTask = isUserDirector && isUserInOrganizationOfTask;

        return (
            (status == TaskStatus.Closed && !(isUserAuthor || isUserExecutor || isUserDirectorOfCurrentTask)) ||
            (status == TaskStatus.InProgress && !(isUserAuthor || isUserExecutor || isUserDirectorOfCurrentTask)) ||
            activityStatus == 'draft'
        );
    }

    protected get isChangeExecutorButtonDisable(): boolean {
        const { status } = this.props;
        return status === TaskStatus.Closed || !this.hasUserRightsToChangeExecutor;
    }

    protected get hasUserRightsToChangeExecutor(): boolean {
        const {
            authorId,
            executorId,
            user: {
                attributes: { id },
            },
            activityAuthorId,
        } = this.props;
        return id === authorId || id === executorId || id === activityAuthorId;
    }

    /** Close task button click handler */
    @autobind
    protected onChangeTaskStatusClick(): void {
        const { status, cancelTask, moveToWorkTask } = this.props;

        switch (status) {
            case TaskStatus.Draft:
                this.onChangeDraftStatus();
                break;
            case TaskStatus.InProgress:
                cancelTask();
                break;
            case TaskStatus.Closed:
                moveToWorkTask();
                break;
        }

        this.setState(() => ({
            isExecutorInputDisabled: true,
        }));
    }

    protected onChangeDraftStatus(): void {
        const { moveToWorkTask, hasDescription, hasExecutor, hasBudgetApproval } = this.props;

        if (hasDescription && hasExecutor && hasBudgetApproval) {
            moveToWorkTask();
        } else {
            this.setState(() => ({ isNoDescriptionModalOpened: true }));
        }
    }

    @autobind
    protected onNoDescriptionModalClose(): void {
        this.setState(() => ({ isNoDescriptionModalOpened: false }));
    }

    /** Change task executor button click handler */
    @autobind
    protected onChangeExecutorClick(): void {
        this.setState((state) => ({ isExecutorInputDisabled: !state.isExecutorInputDisabled }));
    }

    /** Executor input value change handler */
    @autobind
    protected onSuggestItemSelect(): void {
        this.setState({
            isExecutorInputDisabled: true,
        });
    }

    @autobind
    protected onTaskBriefButtonClick(): void {
        const { activityId, taskId } = this.props.match.params;
        this.props.history.push(`/activity/${activityId}/task/${taskId}/brief`);
    }

    private getButtonLabel(): string {
        const { status, isRequestInProgress } = this.props;

        let buttonLabel: string;

        switch (status) {
            case TaskStatus.Draft:
                buttonLabel = 'В РАБОТУ';
                break;
            case TaskStatus.InProgress:
                buttonLabel = isRequestInProgress ? 'ЗАКРЫВАЕТСЯ' : 'ЗАКРЫТЬ ЗАДАЧУ';
                break;
            case TaskStatus.Closed:
                buttonLabel = isRequestInProgress ? 'ВОЗВРАЩАЕТСЯ' : 'ВЕРНУТЬ В РАБОТУ';
                break;
            default:
                buttonLabel = 'ИЗМЕНИТЬ СТАТУС';
                break;
        }

        return buttonLabel;
    }
}
