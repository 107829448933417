import * as React from 'react';
import classNames from 'classnames';

import * as style from './ExecutionIdMenu.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { AddNewExecutionIdButton } from './AddNewExecutionIdButton';
import { AddExistingExecutionIdButton } from './AddExistingExecutionIdButton';
import { useBudgetUrl } from './useBudgetUrl';

interface Props {
    displayBudgetButton?: boolean;
    onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;
    onItemClick: () => void;
}

export const ExecutionIdMenu: React.FC<Props> = ({ displayBudgetButton, onModalOpen, onItemClick }: Props) => {
    const { openBudgetUrl } = useBudgetUrl();

    function onBudgetButtonClick() {
        openBudgetUrl();
        onItemClick();
    }

    return (
        <div className={style.root}>
            <div className={style.title}>Добавить ID исполнения</div>

            <div className={style.items}>
                <AddNewExecutionIdButton onModalOpen={onModalOpen}>
                    <div className={style.item} onClick={onItemClick}>
                        <div className={classNames(style.itemIcon, style.plus)}>
                            <Icon type={IconType.PLUS_SIGN} />
                        </div>
                        Создать новый
                    </div>
                </AddNewExecutionIdButton>

                <AddExistingExecutionIdButton>
                    <div className={style.item} onClick={onItemClick}>
                        <div className={classNames(style.itemIcon, style.arrowDownRight)}>
                            <Icon type={IconType.OPEN_ORIGIN_FILE} />
                        </div>
                        Привязать существующий
                    </div>
                </AddExistingExecutionIdButton>

                {displayBudgetButton && (
                    <div className={style.item} onClick={onBudgetButtonClick}>
                        <div className={classNames(style.itemIcon, style.boxArrowUpRight)}>
                            <Icon type={IconType.LINK_FOR_SHARING} />
                        </div>
                        Перейти в бюджетирование
                    </div>
                )}
            </div>
        </div>
    );
};
