import * as React from 'react';
import { CreativeRequest, CreativeRequestItem } from '@api';
import { TableNumberByType } from '@store/creative';

export interface CreativeRequestItemWithTitle {
    title: string;
    dto: CreativeRequestItem;
}

export function useCreativeRequestItems(creativeRequest: CreativeRequest) {
    const [items, setItems] = React.useState<CreativeRequestItemWithTitle[]>([]);

    async function getItemTitle(item: CreativeRequestItem): Promise<string> {
        const group = await item.model.creativeRequestGroup;

        return `${TableNumberByType[group?.value] || ''}.${item.model.number}`;
    }

    React.useEffect(
        function fetchAndListenCreativeRequestItems() {
            if (!creativeRequest) return;
            async function worker() {
                const items = await creativeRequest.model.getItems();
                const itemsTitles = await Promise.all(items.map((item) => getItemTitle(item)));

                setItems(
                    items.map((item, i) => ({
                        dto: item,
                        title: itemsTitles[i],
                    })),
                );
            }

            worker();

            // let itemAddedBound: (item: CreativeRequestItem) => Promise<void>;

            // itemAddedBound = async (item: CreativeRequestItem) => {
            //     console.log('itemAddedBound', item);
            //     const itemTitle = await getItemTitle(item);

            //     setItems((items) => [
            //         ...items,
            //         {
            //             dto: item,
            //             title: itemTitle,
            //         },
            //     ]);
            // };

            creativeRequest.events.onItemsAdded(worker);

            return function unlistenCreativeRequestItems() {
                creativeRequest.events.offItemsAdded(worker);
            };
        },
        [creativeRequest],
    );

    return items;
}
