import * as React from 'react';
import classNames from 'classnames';
import { EmojiClickData } from 'emoji-picker-react';
import {
    Flex,
    Divider,
    Card,
    Details,
    HtmlText,
    Chip,
    ChipProps,
    Text,
    Description,
    FavouriteIcon,
} from '@sbermarketing/mrm-ui';

import { TaskCommentReaction, TaskCommentTransferObject, TaskStatus } from 'sber-marketing-types/backend';
import { IconType } from 'sber-marketing-ui';

import { DatesFormatter } from '@common/Utils';

import {
    useCommentReactionMutation,
    useGetAuthUserQuery,
    useGetTaskQuery,
    useGetUserQuery,
    useSwitchCommentFavouriteMutation,
} from '@api';

import { DropdownOptions, useSearch } from '@common/hooks';

import { getFilesCountText } from '@modules/files/utils';
import { FileData } from '@modules/files/types';

import { IconTag, EmojiPicker } from '@common/components';
import { CommentReaction, RemoveCommentModal } from '@modules/comment/components';
import { DownloadFiles, FileTag } from '@modules/files/components';
import { TaskFilesGallery } from '@modules/task/components';

import * as styles from './Comment.scss';

export interface CommentProps extends ChipProps {
    comment: TaskCommentTransferObject;
    subComments?: TaskCommentTransferObject[];
    fullDate?: boolean;
    onEdit?: (comment: TaskCommentTransferObject) => void;
    onReplay?: () => void;
    onDelete?: () => void;
}

const defaultReactions = ['👍', '👌', '❤️', '✅'];

export function Comment({
    comment,
    subComments = [],
    className,
    children,
    reverse,
    fullDate,
    onMouseEnter,
    onEdit,
    onReplay,
    onDelete,
    ...props
}: CommentProps) {
    const { data: authUser, isLoading: isAuthUserLoading } = useGetAuthUserQuery();
    const { data: author, isLoading: isAuthorLoading } = useGetUserQuery({ id: comment.authorId });
    const { data: { task } = {} } = useGetTaskQuery({ id: comment.taskId });
    const [switchFavourite, { isLoading: isSwitchFavouriteLoading }] = useSwitchCommentFavouriteMutation();
    const [search, setSearch] = useSearch();
    const [isOpenAnswers, setOpenAnswers] = React.useState(false);
    const [viewFile, setViewFile] = React.useState<FileData>();
    const [hovered, setHovered] = React.useState(false);
    const [answersHovered, setAnswersHovered] = React.useState(false);
    const [selected, setSelected] = React.useState(false);
    const [isRemove, setIsRemove] = React.useState(false);
    const isAuthor = authUser?.user.attributes.id === comment.authorId;
    const [commentReaction, { isLoading: isReactionUpdate, originalArgs: reactionArgs }] = useCommentReactionMutation();
    const emojiDropdownRef = React.useRef<DropdownOptions>();
    const reactions = React.useMemo<Record<string, TaskCommentReaction[]>>(
        () =>
            comment.reactions.reduce((result, value) => {
                if (!(value.reaction in result)) {
                    result[value.reaction] = [];
                }
                result[value.reaction].push(value);
                return result;
            }, {}),
        [comment.reactions],
    );

    const files: FileData[] =
        comment.files?.map((file) => ({ ...file, containerName: comment.id, parent: 'comment' })) || [];
    const isClosed = task.status === TaskStatus.Closed;
    const allAnswers = reverse ? [...subComments].reverse() : subComments;
    const answers = isOpenAnswers ? allAnswers : allAnswers.slice(reverse ? 0 : -2, reverse ? 2 : undefined);
    const isShowMenu = hovered && !isAuthorLoading;

    const handleHover: typeof onMouseEnter = (e: any) => {
        setHovered(true);
        onMouseEnter?.(e);
    };

    const handleReaction = (reaction: string) => {
        commentReaction({
            taskId: comment.taskId,
            commentId: comment.id,
            reaction,
        });
    };

    const handleEmojiPicker = (emoji: EmojiClickData) => {
        handleReaction(emoji.emoji);
        emojiDropdownRef.current.close();
    };

    const handleShowReplayComment = () => {
        setSearch({ ...search, commentId: comment.replyId ?? comment.id });
        onReplay?.();
    };

    const handleDeleteComment = () => {
        onDelete?.();

        if ('commentId' in search) {
            setSearch({ ...search, commentId: undefined });
        }
    };

    const handleShowRemoveModal = () => {
        setIsRemove(true);
    };

    const handleHideRemoveModal = () => {
        setIsRemove(false);
    };

    const handleFavouriteToggle = () => {
        switchFavourite({
            commentId: comment.id,
            taskId: task.id,
        });
    };

    const authorActions = !isClosed && isAuthor && (
        <>
            <IconTag
                data-qa-id="Comment__edit"
                flat
                onClick={() => onEdit?.(comment)}
                padding={4}
                aligned
                ghost
                clickable
                icon={IconType.PROJECT_STAGES_EDIT_ICON}
            />
            <IconTag
                data-qa-id="Comment__remove"
                onClick={handleShowRemoveModal}
                padding={4}
                aligned
                ghost
                flat
                clickable
                type="danger"
                icon={IconType.TRASH}
            />
            <RemoveCommentModal
                hide={!isRemove}
                commentId={comment.id}
                taskId={comment.taskId}
                onRemove={handleDeleteComment}
                onCancel={handleHideRemoveModal}
            />
        </>
    );

    const favouriteButton = !comment.replyId && (
        <Chip
            disabled={isSwitchFavouriteLoading}
            data-qa-id="Comment__favourite"
            onClick={handleFavouriteToggle}
            aligned
            flat
            ghost
            padding={4}
        >
            <FavouriteIcon
                color={
                    isSwitchFavouriteLoading
                        ? 'var(--color-gray-20)'
                        : comment.isFavorite
                        ? 'var(--color-orange-40)'
                        : 'var(--color-gray-50)'
                }
                size={14}
            />
        </Chip>
    );

    const summaryAfter = files.length ? (
        <DownloadFiles
            files={files}
            fileName={`Вложения комментария «${comment.id}» от ${new Date().toLocaleDateString()}.zip`}
        >
            Скачать файлы
        </DownloadFiles>
    ) : null;

    return (
        <Chip
            data-qa-id="Comment"
            data-qa-comment-id={comment.id}
            align="stretch"
            flat
            gap={4}
            vertical
            {...props}
            reverse
            onMouseEnter={handleHover}
            className={classNames(
                styles.root,
                selected && styles.selected,
                answersHovered && styles.answersHovered,
                className,
            )}
        >
            {subComments?.length ? (
                <Flex gap={6} vertical className={styles.answers}>
                    <Flex padding={[4, 6]} gap={8}>
                        <Text size={14} style={{ fontWeight: 600 }}>
                            Ответы <Description>{subComments.length}</Description>
                        </Text>
                        {subComments.length > 2 &&
                            (isOpenAnswers ? (
                                <Text
                                    onClick={() => {
                                        setOpenAnswers(false);
                                    }}
                                    style={{ fontWeight: 600, cursor: 'pointer' }}
                                    color="var(--color-blue-50)"
                                >
                                    свернуть
                                </Text>
                            ) : (
                                <Text
                                    onClick={() => {
                                        setOpenAnswers(true);
                                    }}
                                    style={{ fontWeight: 600, cursor: 'pointer' }}
                                    color="var(--color-blue-50)"
                                >
                                    развернуть все
                                </Text>
                            ))}
                    </Flex>
                    {answers.map((comment) => (
                        <Comment
                            fullDate
                            onEdit={onEdit}
                            onDelete={() => setAnswersHovered(false)}
                            onMouseEnter={() => setAnswersHovered(true)}
                            onMouseLeave={() => setAnswersHovered(false)}
                            padding={[3, 3, 3, 5]}
                            ghost
                            key={comment.id}
                            comment={comment}
                        />
                    ))}
                </Flex>
            ) : null}
            {Object.keys(reactions).length ? (
                <Flex className={styles.reactions} gap={6}>
                    {Object.keys(reactions).map((key) => (
                        <CommentReaction
                            loading={isReactionUpdate}
                            key={key}
                            reactions={reactions[key]}
                            reaction={key}
                            onClick={() => handleReaction(key)}
                        />
                    ))}
                    {isReactionUpdate && !(reactionArgs.reaction in reactions) && (
                        <IconTag key={reactionArgs.reaction} icon={reactionArgs.reaction} padding={[1, 7]} loading>
                            1
                        </IconTag>
                    )}
                </Flex>
            ) : null}
            {files.length ? (
                <Details
                    defaultOpen
                    className={styles.files}
                    summary={getFilesCountText(files.length)}
                    summaryAfter={summaryAfter}
                >
                    <Flex vertical gap={6}>
                        {files.map((file) => (
                            <FileTag editable={isAuthor} key={file.id} file={file} onView={() => setViewFile(file)} />
                        ))}
                    </Flex>
                    <TaskFilesGallery taskId={comment.taskId} files={files} file={viewFile} onShowFile={setViewFile} />
                </Details>
            ) : null}
            {comment.text && <HtmlText data-qa-id="Comment__text" className={styles.text} text={comment.text} />}
            <Flex
                gap={4}
                className={classNames(styles.header, isShowMenu && styles.headerMenu)}
                align="center"
                loading={isAuthorLoading}
            >
                <div data-qa-id="Comment__author" className={styles.author}>
                    {author?.firstName} {author?.secondName}
                </div>
                <div data-qa-id="Comment__department" className={styles.department}>
                    {author?.departmentName},
                </div>
                <div data-qa-id="Comment__time" className={styles.time}>
                    {fullDate && <>{DatesFormatter.ddMonthyyyy(comment.createTime)}, </>}
                    {DatesFormatter.hhmm(comment.createTime)}
                </div>
            </Flex>
            {isShowMenu && (
                <Flex justify="flex-end" className={styles.menu}>
                    <Card
                        height={32}
                        loading={isAuthUserLoading || isReactionUpdate}
                        className={styles.actions}
                        padding={[9, 13]}
                        gap={24}
                    >
                        {isClosed ? null : (
                            <IconTag
                                data-qa-id="Comment__replay"
                                onClick={handleShowReplayComment}
                                padding={4}
                                aligned
                                ghost
                                flat
                                clickable
                                icon={IconType.REPLAY}
                            />
                        )}
                        {defaultReactions.map((reaction) => (
                            <IconTag
                                data-qa-id="Comment__react"
                                data-qa-reaction={reaction}
                                flat
                                key={reaction}
                                padding={4}
                                aligned
                                ghost
                                onClick={() => handleReaction(reaction)}
                                icon={reaction}
                            />
                        ))}
                        <EmojiPicker
                            placement="topRight"
                            autoPlace="vertical"
                            absolute
                            dropdownRef={emojiDropdownRef}
                            aligned
                            ghost
                            flat
                            padding={4}
                            onShowChangeDropdown={setSelected}
                            onEmojiClick={handleEmojiPicker}
                        />
                        {favouriteButton || authorActions ? <Divider vertical /> : null}
                        {favouriteButton}
                        {authorActions}
                    </Card>
                </Flex>
            )}
        </Chip>
    );
}
