import { includes } from 'lodash';
import { HandlerMap } from './types';

export const constraintHandlerMap: HandlerMap = {
    maxKb: async (maxKb, file) => {
        return file.size >= maxKb * 1000 ? 'Превышен максимальный размер файла' : undefined;
    },
    allowedMimeTypes: async (allowedMimeTypes, file) => {
        return !includes(allowedMimeTypes, file.type) ? 'Недопустимый тип файла' : undefined;
    },
};
