import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isNil, get } from 'lodash';

import { StoreState } from '@store';
import { getAllUsers } from '@store/appUsers';
import { getLoginUser } from '@store/user';
import { buildPermissions } from '@store/taskEditor2';
import { fillActivityTasks } from '@store/activityTasksPage';
import {
    getTaskById,
    deleteTasks,
    updateTaskCard,
    getCardType,
    updateSavedTaskCard,
    UpdateSavedTaskCardParams,
    getTasksListState,
} from '@store/tasksList';

import { TaskCardStoreProps, TaskCardMappedState, TaskCardMappedActions, TaskCardContainerProps } from './types';

import { TaskCardContainer } from './TaskCardContainer';
import { getNewsStyle } from '../lib';

const mapStateToProps = (state: StoreState, { id }: TaskCardStoreProps): TaskCardMappedState => {
    const task = getTaskById(state, id);
    const taskWorkType = task ? getTasksListState(state).workTypes.dictionary[task.workTypeId] : null;

    const taskPermissions = buildPermissions({
        user: getLoginUser(state),
        task: {
            organizationId: task?.activity?.organizationId,
            authorId: task?.author?.id,
            executorId: task?.executor?.id,
            canEdit: task?.canEdit,
            createdBy: task?.createdBy,
            status: task?.status,
        },
        activity: {
            authorId: task?.activity?.authorId,
            responsibleId: task?.activity?.responsibleId,
        },
    });

    return {
        task,
        taskWorkType,
        taskPermissions,
        cardType: getCardType(state),
        appUsers: getAllUsers(state),
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<StoreState>,
    { id, onTaskDelete }: TaskCardStoreProps,
): TaskCardMappedActions => ({
    deleteTask: async () => {
        await dispatch(deleteTasks(id));
        if (!isNil(onTaskDelete)) {
            onTaskDelete(id);
        }
    },
    updateTask: async () => {
        await dispatch(updateTaskCard(id));
    },
    updateSavedTask: (params: UpdateSavedTaskCardParams) => dispatch(updateSavedTaskCard(params)),
    fillActivityTasks: (activityId: number) => dispatch(fillActivityTasks(activityId)),
});

const mergeProps = (
    { task, cardType, appUsers, taskPermissions, taskWorkType }: TaskCardMappedState,
    { deleteTask, updateTask, updateSavedTask }: TaskCardMappedActions,
    storeProps: TaskCardStoreProps,
): TaskCardContainerProps => {
    const { from, id, displayActivityName } = storeProps;
    const currentTask = storeProps.task ? storeProps.task : task;

    const link = !isNil(from)
        ? `/activity/${currentTask.activityId}/task/${id}?from=${encodeURIComponent(from)}`
        : `/activity/${currentTask.activityId}/task/${id}`;

    return {
        id,
        taskWorkType,
        appUsers,
        deleteTask,
        updateTask,
        updateSavedTask,
        link,
        change: !isNil(currentTask.change)
            ? getNewsStyle(get(currentTask.change, 'entityData.type'), currentTask.change.action, currentTask.status)
            : undefined,
        task: currentTask,
        displayActivityName,
        cardType,
        taskPermissions,
        activityStages: storeProps.activityStages,
        isOpenedInSidebar: storeProps.isOpenedInSidebar,
        fillActivityTasks,
        onTaskUpdate: storeProps.onTaskUpdate,
        reloadStagesData: storeProps.reloadStagesData,
        openTaskSidebar: storeProps.openTaskSidebar,
        reloadTaskSidebarContent: storeProps.reloadTaskSidebarContent,
    };
};

const withStore = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export const TaskCardStore: ComponentType<TaskCardStoreProps> = withStore(TaskCardContainer);
