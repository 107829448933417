import * as React from 'react';
import autobind from 'autobind-decorator';

import { FileInput } from './FileInput';

interface Props {
    multiple?: boolean;
    accept?: string;
    onChange: (files: File[]) => void;
}

export class FileInputContainer extends React.PureComponent<Props> {
    private inputRef: React.RefObject<HTMLInputElement>;

    public constructor(props: Props) {
        super(props);

        this.inputRef = React.createRef<HTMLInputElement>();
    }

    public render(): JSX.Element {
        const multiple = typeof this.props.multiple === 'undefined' ? true : this.props.multiple;

        return (
            <FileInput
                multiple={multiple}
                accept={this.props.accept}
                inputRef={this.inputRef}
                onChange={this.onChange}
            />
        );
    }

    public triggerOpen(): void {
        this.inputRef.current.click();
    }

    public clear(): void {
        this.inputRef.current.value = '';
    }

    @autobind
    private onChange(): void {
        const files = Array.from(this.inputRef.current.files);
        this.inputRef.current.value = '';

        this.props.onChange(files);
    }
}
