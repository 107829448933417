import * as React from 'react';
import classNames from 'classnames';
import { TextNode } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';

import { ToolbarPlugin } from './plugins/ToolbarPlugin';
import { MentionsPlugin, MentionNode } from './plugins/MentionsPlugin';
import { HtmlPlugin, ExtendedTextNode, ExtendedHeadingNode } from './plugins/HtmlPlugin';
import { PastePlugin } from './plugins/PastePlugin';
import { LinkPlugin, AutoLinkPlugin } from './plugins/LinkPlugin';
import { theme } from './theme';
import { LexicalDropdownPlacement } from './plugins/ToolbarPlugin/ui/DropDown';

import * as style from './LexicalTextarea.scss';

export interface LexicalTextareaProps {
    className?: string;
    qaId?: string;
    title: string;
    value?: string;
    errorMessage?: string;
    displayError?: boolean;
    isFocused: boolean;
    mentions?: any[];
    onInputFocus: () => void;
    onInputBlur: () => void;
    onInputChange: (value: string) => void;
    onPaste?: React.ClipboardEventHandler<HTMLTextAreaElement>;
    readOnly?: boolean;
    disabled?: boolean;
    focusOnValueChange?: boolean;
    shortLexical?: boolean;
    dropdownPlacement?: LexicalDropdownPlacement;
    textWrapperRef?: (element: HTMLDivElement) => void;
}

export function LexicalTextarea({
    qaId,
    className,
    title,
    value,
    errorMessage,
    displayError,
    isFocused,
    mentions,
    onInputFocus,
    onInputBlur,
    onInputChange,
    onPaste,
    shortLexical,
    dropdownPlacement,
    readOnly,
    disabled,
    focusOnValueChange,
    textWrapperRef,
}: LexicalTextareaProps): JSX.Element {
    const qaProps: any = {
        'qa-id': qaId,
    };

    const showTitle = !shortLexical && (isFocused || Boolean(value));
    const active = !disabled && !readOnly;

    const placeholder = !showTitle ? <div className={style.editorPlaceholder}>{title}</div> : null;
    const editorConfig = {
        namespace: 'editor',
        theme,
        editable: !readOnly && !disabled,
        onError: console.log,
        nodes: [
            ExtendedTextNode,
            { replace: TextNode, with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key) },
            ExtendedHeadingNode,
            { replace: HeadingNode, with: (node: HeadingNode) => new ExtendedHeadingNode(node.__text, node.__key) },
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            MentionNode,
        ],
    };

    return (
        <div
            className={classNames(
                style.root,
                errorMessage !== undefined && style.hasErrorMessage,
                isFocused && active && style.focused,
                readOnly && style.readOnly,
                disabled && style.disabled,
                className,
            )}
        >
            <div className={style.label}>
                {showTitle && <div className={style.title}>{title}</div>}

                <div {...qaProps} className={shortLexical ? style.shortTextarea : style.textarea} ref={textWrapperRef}>
                    <LexicalComposer initialConfig={editorConfig}>
                        <div className={style.editorContainer}>
                            <ToolbarPlugin dropdownPlacement={dropdownPlacement} shortLexical={shortLexical} />
                            <div className={style.editorInner}>
                                <RichTextPlugin
                                    contentEditable={
                                        <ContentEditable
                                            className={classNames(
                                                style.editorInput,
                                                shortLexical && style.editorInputShort,
                                            )}
                                        />
                                    }
                                    placeholder={placeholder}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                <ListPlugin />
                                <CheckListPlugin />
                                <AutoLinkPlugin />
                                <LinkPlugin />
                                {!editorConfig.editable && <LexicalClickableLinkPlugin />}
                                <HorizontalRulePlugin />
                                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                                <HistoryPlugin />
                                <MentionsPlugin
                                    dropdownPlacement={dropdownPlacement}
                                    shortLexical={shortLexical}
                                    mentions={mentions}
                                />
                                <HtmlPlugin
                                    onHtmlChanged={onInputChange}
                                    onFocus={onInputFocus}
                                    onBlur={onInputBlur}
                                    focusOnValueChange={focusOnValueChange}
                                    initialHtml={value}
                                />
                                <PastePlugin onPaste={onPaste} />
                            </div>
                        </div>
                    </LexicalComposer>
                </div>

                {displayError && errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}
            </div>
        </div>
    );
}
