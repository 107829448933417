import * as React from 'react';
import classNames from 'classnames';

import * as style from './Tooltip.scss';

import { MoneyFormatter, Money } from '@common/Utils';

import { Icon, IconType } from 'sber-marketing-ui';

export const enum TooltipDirection {
    Up = 'up',
    Down = 'down',
}

interface Props {
    activityType: string;
    activityName: string;
    serialNumber: number;
    correctedPlanReserveBudget: { before: number; after: number };
    correctedPlanFactBudget: { before: number; after: number };
    userHasCorrectionsToApprove: boolean;
    product: string;
    segment: string;
    directions: string[];
    budgetsByTools: { name: string; value: number }[];
    activityStart: string;
    activityEnd: string;
    direction: TooltipDirection;
}

// tslint:disable-next-line:cyclomatic-complexity
export const Tooltip = ({
    activityType,
    activityName,
    serialNumber,
    correctedPlanReserveBudget,
    correctedPlanFactBudget,
    userHasCorrectionsToApprove,
    product,
    segment,
    directions,
    budgetsByTools,
    activityStart,
    activityEnd,
    direction,
}: Props): JSX.Element => {
    const showPlanOverrunedByReserveTooltip = correctedPlanReserveBudget.before < 0;
    const showPlanOverrunnedByReserveCorrectedTooltip =
        correctedPlanReserveBudget.before !== correctedPlanReserveBudget.after;

    const showPlanOverrunedByFactTooltip = correctedPlanFactBudget.before < 0;
    const showPlanOverrunedByFactCorrectedTooltip = correctedPlanFactBudget.before !== correctedPlanFactBudget.after;

    const showCreateCorrectionsMarker =
        (showPlanOverrunedByReserveTooltip && !showPlanOverrunnedByReserveCorrectedTooltip) ||
        (showPlanOverrunedByFactTooltip && !showPlanOverrunedByFactCorrectedTooltip);
    const showApproveCorrectionsMarker =
        userHasCorrectionsToApprove &&
        (showPlanOverrunnedByReserveCorrectedTooltip || showPlanOverrunedByFactCorrectedTooltip);

    return (
        <div
            className={classNames(
                style.root,
                direction == TooltipDirection.Up && style.directedUp,
                direction == TooltipDirection.Down && style.directedDown,
            )}
            {...{
                'qa-id': 'budgetExecutionLineInfoTooptip',
            }}
        >
            {showPlanOverrunedByReserveTooltip && (
                <div
                    className={classNames(style.planOverrun, showPlanOverrunedByFactTooltip && style.planOverrunMargin)}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipPlanOverunnedByReserveMarker',
                    }}
                >
                    <div className={style.icon}>
                        <Icon type={IconType.WARNING_IN_CIRCLE} />
                    </div>

                    <div className={classNames(style.textCaption, style.textRed)}>
                        <div>
                            Резерв превышает План на&nbsp;
                            <span className={style.textBold}>
                                {MoneyFormatter.toMostAppropriate(
                                    Money.fromCopecks(Math.abs(correctedPlanReserveBudget.before)),
                                )}
                            </span>
                            &nbsp;по&nbsp;
                            <span className={style.textBold}>ID {serialNumber}</span>
                        </div>
                    </div>
                </div>
            )}

            {showPlanOverrunedByFactTooltip && (
                <div
                    className={style.planOverrun}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipPlanOverunnedByFactMarker',
                    }}
                >
                    <div className={style.icon}>
                        <Icon type={IconType.WARNING_IN_CIRCLE} />
                    </div>

                    <div className={classNames(style.textCaption, style.textRed)}>
                        <div>
                            Факт превышает План на&nbsp;
                            <span className={style.textBold}>
                                {MoneyFormatter.toMostAppropriate(
                                    Money.fromCopecks(Math.abs(correctedPlanFactBudget.before)),
                                )}
                            </span>
                            &nbsp;по&nbsp;
                            <span className={style.textBold}>ID {serialNumber}</span>
                        </div>
                    </div>
                </div>
            )}

            {showPlanOverrunnedByReserveCorrectedTooltip && (
                <div
                    className={classNames(style.planOverrun, style.planOverrunCorrected)}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipPlanOverrunedByReserveCorrectedMarker',
                    }}
                >
                    <div className={style.icon}>
                        <Icon type={IconType.WARNING_IN_TRIANGLE} />
                    </div>

                    <div className={classNames(style.textCaption, style.textYellow)}>
                        С учетом неутвержденных корректировок: План - Резерв по строке&nbsp;
                        <span className={style.textBold}>
                            {MoneyFormatter.toThousands(Money.fromCopecks(correctedPlanReserveBudget.after))}
                        </span>
                    </div>
                </div>
            )}

            {showPlanOverrunedByFactCorrectedTooltip && (
                <div
                    className={classNames(style.planOverrun, style.planOverrunCorrected)}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipPlanOverrunedByFactCorrectedMarker',
                    }}
                >
                    <div className={style.icon}>
                        <Icon type={IconType.WARNING_IN_TRIANGLE} />
                    </div>

                    <div className={classNames(style.textCaption, style.textYellow)}>
                        С учетом неутвержденных корректировок: План - Факт по строке&nbsp;
                        <span className={style.textBold}>
                            {MoneyFormatter.toThousands(Money.fromCopecks(correctedPlanFactBudget.after))}
                        </span>
                    </div>
                </div>
            )}

            {showCreateCorrectionsMarker && (
                <div
                    className={style.actionTooltip}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipCreateCorrrectionMarker',
                    }}
                >
                    <div className={classNames(style.icon, style.iconCreateCorrection)}>
                        <Icon type={IconType.TURN_ARROW} />
                    </div>

                    <div className={classNames(style.textCaption, style.textBold)}>Создайте корректировку</div>
                </div>
            )}

            {showApproveCorrectionsMarker && (
                <div
                    className={style.actionTooltip}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipApproveCorrectionsTooltip',
                    }}
                >
                    <div className={classNames(style.icon, style.iconApproveCorrection)}>
                        <Icon type={IconType.OPEN_CORRECTIONS} />
                    </div>

                    <div className={classNames(style.textCaption, style.textBold)}>Утвердите корректировки</div>
                </div>
            )}

            <div
                className={classNames(style.text, style.activityName)}
                {...{
                    'qa-id': 'budgetExecutionLineInfoTooptipActivityName',
                }}
            >
                <b>{activityName}</b>
            </div>

            <div
                className={style.text}
                {...{
                    'qa-id': 'budgetExecutionLineInfoTooptipActivityType',
                }}
            >
                Тип проекта: {activityType || '–'}
            </div>

            <div
                className={style.text}
                {...{
                    'qa-id': 'budgetExecutionLineInfoTooptipProduct',
                }}
            >
                Продукт: {product || '–'}
            </div>

            <div
                className={style.text}
                {...{
                    'qa-id': 'budgetExecutionLineInfoTooptipSegment',
                }}
            >
                Сегмент: {segment || '–'}
            </div>

            <div
                className={classNames(style.text)}
                {...{
                    'qa-id': 'budgetExecutionLineInfoTooptipDirrections',
                }}
            >
                <div className={style.directions}>Направления:</div>

                {directions.map((item, index) => (
                    <div className={style.directionItem} key={index}>
                        {item || '–'}
                    </div>
                ))}
            </div>

            {budgetsByTools.length > 0 && (
                <div
                    className={classNames(style.text, style.toolBudget)}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipBudgetsByTools',
                    }}
                >
                    <div>Бюджеты по инструментам:</div>

                    {budgetsByTools.map((item) => (
                        <div className={classNames(style.text, style.toolBudget)} key={item.name}>
                            {item.name} - <b>{MoneyFormatter.toMostAppropriate(Money.fromCopecks(item.value))}</b>
                        </div>
                    ))}
                </div>
            )}

            {(activityStart || activityEnd) && (
                <div
                    className={classNames(style.dates, style.text)}
                    {...{
                        'qa-id': 'budgetExecutionLineInfoTooptipActivityStartEnd',
                    }}
                >
                    <b>
                        {activityStart || '–'} - {activityEnd || '–'}
                    </b>
                </div>
            )}
        </div>
    );
};
