import { CreativeRequest, MrmClient, CreativeRequestStatus } from '@api';
import { setCurrentStage, setSelectedStage } from '@store/creative';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export type SetStatus = ({ status }: { status: CreativeRequestStatus }) => Promise<any>;

interface Result {
    creativeRequest: CreativeRequest;
    currentStatus: CreativeRequestStatus;
    availableStatuses: CreativeRequestStatus[]; // Список статусов доступных для смены текущего
    underRevision: boolean; // Был возврат по статусам назмд к агенству
    quickApprovement: boolean; // Может ли агентство отправить после доработки сразу на согласование, минуя проверку
    setStatus: SetStatus | null; // Для смены статусов
    reviewComment: string | null; // Комментарий в статусах, созданный при отправке на доработку в агентство
    setReviewComment: ({ commentId }: { commentId: string }) => void; // Привязывает id коментария ЗНК к статусам
}

export const useGetCurrentStatus = (): Result => {
    const dispatch = useDispatch();
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();

    const [creativeRequest, setCreativeRequest] = useState<any>(null);
    const [currentStatus, setCurrentStatus] = useState<any>(null);
    const [availableStatuses, setAvailableStatuses] = useState<any[]>([]);
    const [underRevision, setUnderRevision] = useState<boolean>(false);
    const [quickApprovement, setQuickApprovement] = useState<boolean>(false);
    const [reviewComment, setReviewComment] = useState<string | null>(null);

    useEffect(() => {
        const getCreativeRequest = async () => {
            const client = await MrmClient.getInstance();
            const creativeRequest = (await client.domain.creativeRequests.getCreativeRequest({
                id: creativeRequestId,
            })) as CreativeRequest;
            setCreativeRequest(creativeRequest);
        };
        getCreativeRequest();
    }, [creativeRequestId]);

    useEffect(() => {
        if (!creativeRequest) return;
        window.creativeRequest = creativeRequest;

        creativeRequest.model.status !== CreativeRequestStatus.draft
            ? dispatch(setSelectedStage(1))
            : dispatch(setSelectedStage(0));

        const updateStatus = async () => {
            setCurrentStatus(creativeRequest.model.status);
            dispatch(setCurrentStage(creativeRequest.model.status));
            const availableStatuses = await creativeRequest.model.findAvailableStatuses();
            setAvailableStatuses(availableStatuses);
        };

        const updateReviewComment = async () => {
            const comments = await creativeRequest.model.getComments();
            const comment = comments.find(({ model }) => model.id === creativeRequest.model.reviewComment)?.model.text;
            setReviewComment(comment);
        };

        const updatedUnderRevision = () => {
            setUnderRevision(creativeRequest.model.underRevision);
        };

        const updateQuickApprovement = async () => {
            setQuickApprovement(creativeRequest.model.quickApprovement);
        };

        updateStatus();
        updateReviewComment();
        updatedUnderRevision();
        updateQuickApprovement();

        creativeRequest.events.onStatusUpdated(updateStatus);
        creativeRequest.events.onReviewCommentUpdated(updateReviewComment);
        creativeRequest.events.onUnderRevisionUpdated(updatedUnderRevision);
        creativeRequest.events.onQuickApprovementUpdated(updateQuickApprovement);

        return () => {
            creativeRequest.events.offStatusUpdated(updateStatus);
            creativeRequest.events.offReviewCommentUpdated(updateReviewComment);
            creativeRequest.events.offUnderRevisionUpdated(updatedUnderRevision);
            creativeRequest.events.offQuickApprovementUpdated(updateQuickApprovement);
        };
    }, [creativeRequest]);

    return {
        creativeRequest,
        currentStatus: currentStatus as CreativeRequestStatus,
        availableStatuses: availableStatuses as CreativeRequestStatus[],
        underRevision,
        quickApprovement,
        setStatus: creativeRequest?.model?.setStatus,
        reviewComment,
        setReviewComment: creativeRequest?.model?.setReviewComment,
    };
};
