// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-DownloadLink-_styles__root{text-decoration:none;color:#417ec6}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-DownloadLink-_styles__root:hover{color:#124084}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/Texts/List/DownloadLink/styles.scss"],"names":[],"mappings":"AAAA,mHACI,oBAAqB,CAErB,aAAc,CAHlB,yHAMQ,aAAc","sourcesContent":[".root {\n    text-decoration: none;\n\n    color: #417ec6;\n\n    &:hover {\n        color: #124084;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-DownloadLink-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
