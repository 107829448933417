import * as React from 'react';
import classnames from 'classnames';
import { times } from 'lodash';
import { FileResponse } from 'sber-marketing-types/frontend';
import { StaticSkeleton } from 'sber-marketing-ui';

import { FileApiUploadParams } from '@api';

import { Utils } from '@common/Utils';
import { MediaFilesGallery } from '@common/MediaFilesGallery';
import { FileTag } from '@modules/files/components';
import { FileData } from '@modules/files/types';

import * as styles from './FileCardsList.scss';

export enum Theme {
    Regular = 'Regular',
    LargeWithBorders = 'LargeWithBorders',
}

interface MediaGalleryState {
    isVisible: boolean;
    index: number;
}

interface Props {
    files: FileData[];
    theme?: Theme;
    displayCount?: number;
    getFileDownloadParams: (file: FileResponse) => FileApiUploadParams;
    canEditInR7?: boolean | ((file: FileResponse) => boolean);
}

function useTheme(theme: Theme) {
    switch (theme) {
        case Theme.LargeWithBorders:
            return styles.rootLargeWithBordersTheme;
        case Theme.Regular:
        default:
            return null;
    }
}

function makeDefaultMediaGalleryState(): MediaGalleryState {
    return {
        isVisible: false,
        index: null,
    };
}

export function FileCardsList({ files, theme, displayCount, canEditInR7, getFileDownloadParams }: Props): JSX.Element {
    const displayCountToUse = displayCount || files.length;

    const [mediaGalleryState, setMediaGalleryState] = React.useState<MediaGalleryState>(makeDefaultMediaGalleryState());

    function onGalleryButtonClick(fileId: string) {
        const index = mediaGalleryItems.findIndex((file) => file.id === fileId);

        setMediaGalleryState({
            isVisible: true,
            index,
        });
    }

    function closeMediaGallery() {
        setMediaGalleryState(makeDefaultMediaGalleryState());
    }

    const themeClass = useTheme(theme);

    const mediaGalleryItems = React.useMemo(
        () => Utils.mapFilesToMediaGalleryItems(files, getFileDownloadParams),
        [files],
    );

    return (
        <React.Fragment>
            <div
                className={classnames(styles.root, themeClass)}
                {...{
                    'qa-id': 'fileCardsList',
                }}
            >
                {times(displayCountToUse).map((i) => {
                    const file = files[i];
                    const canEditInR7Value = typeof canEditInR7 === 'function' ? canEditInR7(file) : canEditInR7;

                    return (
                        <FileTag
                            editable={canEditInR7Value}
                            key={file.id}
                            file={file}
                            onView={() => onGalleryButtonClick(file.id)}
                        />
                    );
                })}
            </div>

            {mediaGalleryState.isVisible && (
                <MediaFilesGallery
                    startIndex={mediaGalleryState.index}
                    items={mediaGalleryItems}
                    onOutsideClick={closeMediaGallery}
                />
            )}
        </React.Fragment>
    );
}

interface PreloaderProps {
    cardsCount?: number;
    theme?: Theme;
}

export function FileCardsListPreloader({ cardsCount = 3, theme }: PreloaderProps): JSX.Element {
    const themeClass = useTheme(theme);

    return (
        <div className={classnames(styles.root, themeClass)}>
            {times(cardsCount, (i) => (
                <FilePreloader key={i} />
            ))}
        </div>
    );
}

function FilePreloader(): JSX.Element {
    return (
        <div className={styles.card}>
            <StaticSkeleton loadingClassName={styles.extension} />

            <div className={styles.infoWrapper}>
                <StaticSkeleton loadingClassName={styles.fileNamePreloader} />
                <StaticSkeleton loadingClassName={styles.fileInfoPreloader} />
            </div>
        </div>
    );
}
