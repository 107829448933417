import { useEffect, useState } from 'react';

export function useWindowSize() {
    const [width, setWidth] = useState(document.body.clientWidth);
    const [height, setHeight] = useState(document.body.clientHeight);

    useEffect(() => {
        const listener = () => {
            setWidth(document.body.clientWidth);
            setHeight(document.body.clientHeight);
        };

        document.addEventListener('resize', listener);

        return () => {
            document.removeEventListener('resize', listener);
        };
    }, []);

    return { width, height };
}
