import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconType } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getImportFMPTableMenuState } from '@store/budgetExecution/importFMPTableMenu';

import * as styles from './ErrorMessage.scss';

export function ErrorMessage(): JSX.Element {
    const errorMessage = useSelector((state: StoreState) => getImportFMPTableMenuState(state).errorMessage);

    return errorMessage ? (
        <div className={styles.root}>
            <Icon type={IconType.WARNING_IN_CIRCLE} svgSize={20} className={styles.icon} />

            {errorMessage}
        </div>
    ) : null;
}
