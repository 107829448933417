// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-common-AccordionSummary-_styles__root{cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/common/AccordionSummary/styles.scss"],"names":[],"mappings":"AAAA,mFACI,cAAe","sourcesContent":[".root {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-common-AccordionSummary-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
