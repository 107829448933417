import * as React from 'react';
import * as style from './RowWithLinks.scss';
import { Skeleton } from 'sber-marketing-ui';
import classNames from 'classnames';

interface Links {
    label: string;
    link: string | null;
}

interface Props {
    label?: string;
    isLoading: boolean;
    links?: Links[];
}

export const RowWithLinks: React.FC<Props> = ({ label, isLoading, links }) => {
    return (
        <Skeleton isReady={!isLoading}>
            <div className={style.wrapper}>
                <div className={style.label}>{label}:</div>
                {links?.map(({ label, link }) => (
                    <div key={link} className={classNames(style.text, link && style.link, !link && style.notLink)}>
                        <a href={link} target="_blank">
                            {label}
                        </a>
                    </div>
                ))}
            </div>
        </Skeleton>
    );
};
