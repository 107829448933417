import * as React from 'react';
import * as lodash from 'lodash';
import * as queryString from 'query-string';
import autobind from 'autobind-decorator';
import { v4 } from 'uuid';
import { parse } from 'url';
import { connect } from 'react-redux';
import type { TaskCardParams } from 'sber-marketing-types/frontend';

import { HistoryApi, TaskApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getStatus } from '@store/tasksList';

import { ActivitySidebarRef } from '@common/ActivitySidebar';
import { TaskSidebarRef } from '@common/TaskSidebar';

import { ActivityTasksPage, ActivityTasksPageMode, HeaderContent } from './ActivityTasksPage';
import { ActivityTasksPageContainerProps } from './types';

const LOADING_DELAY = 10000;

interface State {
    pageWidth: number;
    mode: ActivityTasksPageMode;
    delayLoading: boolean;
    taskSidebarId: string;
}

interface Props extends ActivityTasksPageContainerProps, Partial<MapProps> {
    setHeaderRightContent: (content: JSX.Element) => void;
}

interface MapProps {
    tasksListLoadingStatus: LoadingStatus;
}

@(connect(mapStateToProps) as any)
export class ActivityTasksPageContainer extends React.Component<Props, State> {
    private isComponentMounted: boolean;
    private activityId = Number(this.props.match.params.activityId);
    private activitySidebarRef: ActivitySidebarRef;
    private taskSidebarRef: TaskSidebarRef;

    constructor(props: Props) {
        super(props);

        this.state = {
            pageWidth: document.body.clientWidth,
            mode: ActivityTasksPageMode.Default,
            delayLoading: queryString.parse(this.props.location.search).delayLoading === 'true',
            taskSidebarId: null,
        };

        this.renderHeader();
        HistoryApi.setRecentView(`activity_${this.activityId}`);
    }

    public async componentDidMount(): Promise<void> {
        this.props.setIsMounted(true);
        this.isComponentMounted = true;

        const {
            fillActivityTasks,
            loadActivity,
            loadDivisions,
            activityLoadingStatus,
            divisionLoadingStatus,
            resetTasksList,
            loadUserConfig,
        } = this.props;

        this.updateRequestInProgress();
        this.renderHeader();
        resetTasksList();

        loadUserConfig();

        if (activityLoadingStatus === LoadingStatus.NOT_LOADED) {
            loadActivity(this.activityId);
        }

        if (divisionLoadingStatus === LoadingStatus.NOT_LOADED) {
            loadDivisions();
        }

        if (this.state.delayLoading) {
            setTimeout(() => {
                if (this.isComponentMounted) {
                    this.setState({
                        delayLoading: false,
                    });
                    fillActivityTasks(this.activityId);
                }
            }, LOADING_DELAY);
        } else {
            fillActivityTasks(this.activityId);
        }

        window.addEventListener('resize', this.handlePageResize);
    }

    public componentWillUnmount() {
        this.props.setIsMounted(false);
        this.isComponentMounted = false;

        window.removeEventListener('resize', this.handlePageResize);
        this.props.resetDivisions();
        this.props.resetPageStore();
        this.props.resetDashboardUserConfig();

        HistoryApi.setRecentView(`activity_${this.activityId}`);
    }

    public componentDidUpdate({
        divisionLoadingStatus: prevDivisionLoadingStatus,
        activityLoadingStatus: prevActivityLoadingStatus,
        usersLoadingStatus: prevUsersLoadingStatus,
        activity: prevActivity,
        ...prevProps
    }: Props): void {
        const { divisionLoadingStatus, activityLoadingStatus, usersLoadingStatus, activity } = this.props;
        const isDivisionLoadingStatusChanged = divisionLoadingStatus !== prevDivisionLoadingStatus;
        const isActivityLoadingStatusChanged = activityLoadingStatus !== prevActivityLoadingStatus;
        const isUsersLoadingStatusChanged = usersLoadingStatus !== prevUsersLoadingStatus;
        const hasChangedKeyActivity =
            activity && prevActivity && (activity.isKey !== prevActivity.isKey || activity.name !== prevActivity.name);

        if (
            isDivisionLoadingStatusChanged ||
            isActivityLoadingStatusChanged ||
            isUsersLoadingStatusChanged ||
            hasChangedKeyActivity
        ) {
            this.updateRequestInProgress();
            this.renderHeader();
        }

        const refetchSidebarContent =
            prevProps.tasksListLoadingStatus !== LoadingStatus.LOADING &&
            this.props.tasksListLoadingStatus === LoadingStatus.LOADING;

        if (refetchSidebarContent) {
            this.activitySidebarRef?.refetchContent();
        }
    }

    public render(): JSX.Element {
        const { activity, activitySharingInProgress, userConfigLoadingStatus, reloadStagesData, reloadTaskFilters } =
            this.props;
        const { taskSidebarId } = this.state;

        return React.createElement(ActivityTasksPage, {
            delayLoading: this.state.delayLoading,
            mode: this.state.mode,
            activity,
            activityId: this.activityId,
            taskSidebarId,
            activitySharingInProgress,
            userConfigLoadingStatus,
            reloadTaskFilters,
            onBeforeTaskDeleted: this.onBeforeTaskDeleted,
            isLoaded: this.isLoaded(),
            onTaskCreated: this.onTaskCreated,
            onCreateTaskClose: this.onCreateTaskClose,
            onCreateTaskButtonClick: this.onCreateTaskButtonClick,
            createTaskByNameAndStage: this.createTaskByNameAndStage,
            onTaskUpdate: this.onTaskUpdate,
            reloadStagesData,
            fillActivityTasks: this.fillActivityTasks,
            openTaskSidebar: this.openTaskSidebar,
            reloadTaskSidebarContent: this.reloadTaskSidebarContent,
            closeTaskSidebar: this.closeTaskSidebar,
            shareActivity: this.shareActivity,
            activitySidebarRef: this.setActivitySidebarRef,
            taskSidebarRef: this.setTaskSidebarRef,
        });
    }

    protected hasFilters(): boolean {
        const { status, workTypeId } = parse(location.href, true).query;
        return !lodash.isNil(status) || !lodash.isNil(workTypeId);
    }

    protected isLoaded(): boolean {
        return (
            this.props.activityLoadingStatus === LoadingStatus.LOADED &&
            this.props.divisionLoadingStatus === LoadingStatus.LOADED &&
            this.props.usersLoadingStatus === LoadingStatus.LOADED
        );
    }

    protected updateRequestInProgress() {
        this.props.setRequestInProgress(!this.isLoaded());
    }

    protected renderHeader(): void {
        const { activity, user } = this.props;

        if (activity) {
            this.props.setHeaderRightContent(
                HeaderContent({
                    onCreateTaskButtonClick: this.onCreateTaskButtonClick,
                }),
            );

            this.props.updatePageOptions({
                previousLabel: ' ',
                previousUrl:
                    activity.authorId == user.id || activity.responsibleId == user.id
                        ? '/dashboard?currentPage=myProjects'
                        : '/dashboard?currentPage=accessibleProjects',
                withoutFooter: true,
            });
        }
    }

    @autobind
    private onCreateTaskButtonClick(): void {
        this.setState({ mode: ActivityTasksPageMode.NewTask });
    }

    @autobind
    private async onTaskCreated(): Promise<void> {
        this.onCreateTaskClose();
        this.props.resetEditor();
        this.props.resetTasksList();
        this.props.fetchMoreTasks();
        this.props.fillActivityTasks(this.props.activity?.id);
    }

    @autobind
    private onBeforeTaskDeleted(taskId: string): void {
        this.setState((state) => ({
            ...state,
            taskSidebarId: state.taskSidebarId === taskId ? null : state.taskSidebarId,
        }));
    }

    @autobind
    private onCreateTaskClose(): void {
        this.setState({ mode: ActivityTasksPageMode.Default });
    }

    @autobind
    private handlePageResize() {
        this.setState({
            pageWidth: document.body.clientWidth,
        });
    }

    @autobind
    private async createTaskByNameAndStage(title: string, stageId: string): Promise<void> {
        const id = v4();
        const activityId = this.props.activity.id;

        await TaskApi.createTask({
            id,
            activityId,
            stageId,
            title,
        });

        this.props.resetTasksList();
        this.props.fetchMoreTasks();
        this.props.fillActivityTasks(activityId);
    }

    @autobind
    private onTaskUpdate(id: string, params: Partial<TaskCardParams>): void {
        this.props.updateActivityTask({ id, params });
    }

    @autobind
    private fillActivityTasks(): void {
        this.props.fillActivityTasks(this.activityId);
    }

    @autobind
    private openTaskSidebar(taskSidebarId: string) {
        this.setState({ taskSidebarId });
    }

    @autobind
    private reloadTaskSidebarContent(taskId: string) {
        if (this.state.taskSidebarId === taskId) {
            this.taskSidebarRef?.refetchContent();
        }
    }

    @autobind
    private closeTaskSidebar() {
        this.setState({ taskSidebarId: null });
    }

    @autobind
    private shareActivity(usersIds: number[]) {
        this.props.shareActivity({
            activityId: this.props.activity.id,
            usersIds,
        });
    }

    @autobind
    private setActivitySidebarRef(ref: ActivitySidebarRef) {
        this.activitySidebarRef = ref;
    }

    @autobind
    private setTaskSidebarRef(ref: TaskSidebarRef) {
        this.taskSidebarRef = ref;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        tasksListLoadingStatus: getStatus(state),
    };
}
