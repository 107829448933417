import * as React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { values, sortBy, isEqual, uniq } from 'lodash';
import {
    Popup,
    CloseButton,
    LabeledInput,
    Input_redesign,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
} from 'sber-marketing-ui';

import { VirtualizedList } from '@common/VirtualizedList';
import { Utils } from '@common/Utils';

import { StoreState } from '@store';
import { getAppUsers, loadUsers } from '@store/appUsers';

import * as styles from './UsersDropdown.scss';
import classNames from 'classnames';

type UserObj = { id: number; organizationId: string };
type UserDescriptor = number | UserObj;
export type UserSelectStrategy = (userId: UserObj) => boolean;

function useUsersDropdown({ selectedUsers: initSelectedUsers, updateSelectedUsers, onClose }: Props) {
    const dispatch = useDispatch();

    const initSelectedUsersIds = !initSelectedUsers
        ? []
        : typeof initSelectedUsers === 'number'
        ? initSelectedUsers
        : initSelectedUsers.map((user: UserDescriptor) => (typeof user === 'number' ? user : user.id));
    const [value, setValue] = React.useState('');
    const [isFocused, setIsFocused] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState<number[]>(initSelectedUsersIds);

    const inputRef = React.useRef<Input_redesign>();

    function onUserToggle(userId: number) {
        setSelectedUsers(
            selectedUsers.includes(userId)
                ? selectedUsers.filter((selectedUser) => selectedUser !== userId)
                : [...selectedUsers, userId],
        );
        inputRef?.current?.focus();
    }

    function onSaveButtonClick() {
        if (!isEqual(selectedUsers, initSelectedUsers)) {
            updateSelectedUsers(selectedUsers);
        }
        onClose();
    }

    function onInputBlur() {
        if (isFocused) {
            inputRef.current?.focus();
        }
    }

    React.useEffect(() => {
        dispatch(loadUsers(null));
    }, []);

    React.useEffect(() => {
        if (isFocused) {
            inputRef.current?.focus();
        } else {
            inputRef.current?.blur();
        }
    }, [isFocused]);

    return {
        value,
        setValue,
        isFocused,
        setIsFocused,
        onInputBlur,
        selectedUsers,
        inputRef,
        onUserToggle,
        onSaveButtonClick,
    };
}

function useIndicatorData({
    selectedUsers,
    initSelectedUsers,
}: {
    selectedUsers: number[];
    initSelectedUsers: UserDescriptor[];
}): {
    toAddList: UserDescriptor[];
    toDeleteList: UserDescriptor[];
} {
    const users = useSelector((state: StoreState) => getAppUsers(state).entities);
    const initSelectedUsersIds = !initSelectedUsers
        ? []
        : typeof initSelectedUsers === 'number'
        ? initSelectedUsers
        : initSelectedUsers.map((user: UserDescriptor) => (typeof user === 'number' ? user : user.id));

    return {
        toAddList: selectedUsers
            .filter((item) => !initSelectedUsersIds.some((initItem: number) => initItem == item))
            .map((item) => users[String(item)]),
        toDeleteList: initSelectedUsersIds
            .filter((item: number) => !selectedUsers.includes(item))
            .map((item) => users[String(item)]),
    };
}

interface Props {
    selectedUsers: UserDescriptor[];
    usersToUseStrategy?: UserSelectStrategy;
    usersToRemoveStrategy?: UserSelectStrategy;
    updateSelectedUsers: (selectedUsers: number[]) => void;
    onClose: () => void;
}

export function UsersDropdown(props: Props) {
    const {
        value,
        setValue,
        isFocused,
        setIsFocused,
        onInputBlur,
        selectedUsers,
        inputRef,
        onUserToggle,
        onSaveButtonClick,
    } = useUsersDropdown(props);

    const { toAddList, toDeleteList } = useIndicatorData({
        selectedUsers,
        initSelectedUsers: props.selectedUsers,
    });

    return (
        <Popup onOutOfContentClick={props.onClose}>
            <div
                {...{
                    'qa-id': 'usersDropdown',
                }}
            >
                {isFocused && (
                    <div
                        className={styles.mask}
                        onClick={() => {
                            setIsFocused(false);
                            setValue('');
                        }}
                        {...{
                            'qa-id': 'usersDropdownMask',
                        }}
                    />
                )}

                <div className={styles.root}>
                    <div className={styles.title}>
                        Изменить участников
                        <CloseButton onClick={props.onClose} />
                    </div>

                    {!!toAddList.length && <div className={styles.usersBadgesHeader}>Будет добавлено</div>}
                    {!!toAddList.length && (
                        <div className={styles.usersBadgeContainer}>
                            {toAddList.map((item: any) => (
                                <div key={item.id} className={classNames(styles.usersBadge, styles.usersDiff_positive)}>
                                    <div className={styles.usersBadgeValue}>
                                        {item.firstName + ' ' + item.secondName}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {!!toDeleteList.length && <div className={styles.usersBadgesHeader}>Будет удалено</div>}
                    {!!toDeleteList.length && (
                        <div className={styles.usersBadgeContainer}>
                            {toDeleteList.map((item: any) => (
                                <div key={item.id} className={classNames(styles.usersBadge, styles.usersDiff_negative)}>
                                    <div className={styles.usersBadgeValue}>
                                        {item.firstName + ' ' + item.secondName}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className={styles.inputWrapper}>
                        <LabeledInput
                            qaId="usersDropdownInput"
                            title="Имя участника"
                            value={value}
                            onValueChange={setValue as any}
                            onFocus={() => setIsFocused(true)}
                            onBlur={onInputBlur}
                            inputComponentRef={inputRef}
                        />

                        {isFocused && (
                            <Dropdown
                                inputValue={value}
                                selectedUsers={selectedUsers}
                                usersToUseStrategy={props.usersToUseStrategy}
                                usersToRemoveStrategy={props.usersToRemoveStrategy}
                                onUserToggle={onUserToggle}
                            />
                        )}
                    </div>

                    <div className={styles.buttonWrapper}>
                        <Button
                            qaId="usersDropdownSaveButton"
                            theme={ButtonTheme.GhostRoundedBlack}
                            onClick={onSaveButtonClick}
                        >
                            <div className={styles.button}>
                                Сохранить
                                {!!toAddList.length && (
                                    <div className={classNames(styles.usersDiff, styles.usersDiff_positive)}>
                                        <div className={styles.usersDiffValue}>{toAddList.length}</div>
                                    </div>
                                )}
                                {!!toDeleteList.length && (
                                    <div className={classNames(styles.usersDiff, styles.usersDiff_negative)}>
                                        <div className={styles.usersDiffValue}>{toDeleteList.length}</div>
                                    </div>
                                )}
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </Popup>
    );
}

interface DropdownProps {
    inputValue: string;
    selectedUsers: number[];
    usersToUseStrategy?: UserSelectStrategy;
    usersToRemoveStrategy?: UserSelectStrategy;
    onUserToggle: (userId: number) => void;
}

function Dropdown({
    inputValue,
    selectedUsers,
    usersToUseStrategy,
    usersToRemoveStrategy,
    onUserToggle,
}: DropdownProps): JSX.Element {
    const users = useSelector((state: StoreState) => getAppUsers(state).entities);

    const filteredUsers = React.useMemo(
        () =>
            sortBy(
                values(users).filter((user) => {
                    const skipUser = usersToUseStrategy ? !usersToUseStrategy(user) : false;

                    if (!user.isActive || !user.department || skipUser) {
                        return false;
                    }

                    if (inputValue) {
                        return Utils.spacebarInsensitiveMatch(new RegExp(`${inputValue}`.toLowerCase()), user, (user) =>
                            `${user.secondName} ${user.firstName}`.toLowerCase(),
                        );
                    }

                    return true;
                }),
                (user) => `${selectedUsers.includes(user.id) ? '0' : '1'} ${user.firstName} ${user.secondName}`,
            ),
        [users, inputValue],
    );

    return (
        <div className={styles.dropdown}>
            <VirtualizedList
                items={filteredUsers}
                width={352}
                rowHeight={51}
                maxHeight={400}
                maxItems={8}
                itemRenderer={(user) => {
                    const isSelected = selectedUsers.includes(user.id);
                    const canRemove = isSelected && usersToRemoveStrategy ? usersToRemoveStrategy(user) : true;

                    const label = `${user.firstName} ${user.secondName}`;

                    return (
                        <div
                            className={classnames(
                                styles.dropdownItem,
                                canRemove ? styles.dropdownItemClickable : styles.dropdownItemUnclickable,
                            )}
                            onClick={() => {
                                const canToggle = isSelected ? canRemove : true;

                                if (canToggle) {
                                    onUserToggle(user.id);
                                }
                            }}
                            {...{
                                'qa-id': 'usersDropdownUserItem',
                                'qa-label': label,
                                'qa-is-selected': isSelected ? 'true' : 'false',
                                'qa-can-remove': canRemove ? 'true' : 'false',
                            }}
                        >
                            <div className={styles.dropdownItemInfo}>
                                <div className={styles.dropdownItemTitle}>{label}</div>

                                <div className={styles.dropdownItemSubtitle}>{user.department}</div>
                            </div>

                            <div
                                className={classnames(
                                    styles.dropdownItemMarker,
                                    isSelected && styles.dropdownItemMarkerActive,
                                )}
                            />
                        </div>
                    );
                }}
            />
        </div>
    );
}

export function makeIncludeUsersStrategy(users: UserDescriptor[]): UserSelectStrategy {
    const usersIds = uniq(users.map((user) => (typeof user === 'number' ? user : (user as UserObj).id)));

    return (targetUser: UserDescriptor): boolean => {
        const targetUserId = typeof targetUser === 'number' ? targetUser : (targetUser as UserObj).id;

        return usersIds.includes(targetUserId);
    };
}

export function makeExcludeUsersStrategy(users: UserDescriptor[]): UserSelectStrategy {
    const usersIds = uniq(users.map((user) => (typeof user === 'number' ? user : (user as UserObj).id)));

    return (targetUser: UserDescriptor): boolean => {
        const targetUserId = typeof targetUser === 'number' ? targetUser : (targetUser as UserObj).id;

        return !usersIds.includes(targetUserId);
    };
}
