import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { BudgetItem, BudgetItemListQueryParams } from '@mrm/budget';

import { OptionMultiple, OptionProps, FormSelector, FormSelectorProps } from '@common/components';
import { useGetBudgetItemsQuery } from '@api';
import { uniqBy } from 'lodash';
import { BudgetsData, BudgetsFetcher } from '@common/TaskEditor/WorkTypeExecutorEditor/BudgetEditor/BudgetsFetcher';

export interface BudgetsFormSelectorProps<M extends OptionMultiple>
    extends Omit<
            FormSelectorProps<M, OptionProps<BudgetItem>, BudgetItem, 'id', string, M extends true ? string[] : string>,
            'options'
        >,
        Omit<BudgetItemListQueryParams, 'budgetId'> {
    skip?: boolean;
    organizationId: string;
}

const CURR_YEAR = new Date().getFullYear();
const NO_BUDGET_ITEM_OPTION = { value: null, title: 'Нет ID источника' };

export function BudgetsFormSelector<M extends OptionMultiple>({
    sort,
    activityIds,
    filter,
    columns,
    limit,
    offset,
    authorIds,
    responsibleIds,
    organizationId,
    loading,
    skip,
    ...props
}: BudgetsFormSelectorProps<M>) {
    const { watch } = useFormContext();

    const params: Required<Omit<BudgetItemListQueryParams, 'budgetId'>> = {
        sort,
        activityIds,
        filter,
        columns,
        limit,
        offset,
        authorIds,
        responsibleIds,
    };
    const [budgetId, setBudgetId] = React.useState(null);
    const { isLoading: isDivisionsLoading, data: budgets = [] } = useGetBudgetItemsQuery(
        { budgetId, ...params },
        { skip: skip || !budgetId },
    );
    const setBudgetsData = (budgetsData: BudgetsData) => {
        if (budgetsData?.budgets?.length) {
            const budget = budgetsData.budgets.find((item) => CURR_YEAR === item.year);

            if (budget) {
                setBudgetId(budget.id);
            }
        }
    };

    const options: OptionProps<BudgetItem>[] = React.useMemo(
        () =>
            uniqBy(
                [...budgets]
                    .sort(({ serialNumber: a }, { serialNumber: b }) => (a > b ? 1 : a < b ? -1 : 0))
                    .map((item) => ({
                        value: item,
                        title: `${item.serialNumber} (${item.activity.name})`,
                    })),
                (budget) => budget.value,
            ),
        [budgets],
    );

    const fieldValue = watch(props.name);

    let optionsToRender: OptionProps<BudgetItem>[];
    if (!fieldValue?.length) {
        optionsToRender = [NO_BUDGET_ITEM_OPTION, ...options];
    } else if (fieldValue?.includes(null)) {
        optionsToRender = [NO_BUDGET_ITEM_OPTION];
    } else {
        optionsToRender = options;
    }

    return (
        <>
            <BudgetsFetcher forwardData={setBudgetsData} organizationIds={[organizationId]} />
            <FormSelector loading={isDivisionsLoading || loading} {...props} optionId="id" options={optionsToRender} />
        </>
    );
}
