// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-_styles__title{margin-bottom:7px;display:flex;justify-content:space-between;font-family:'Open Sans';font-weight:600;font-size:16px;color:#092A3A}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-_styles__download{margin-right:5px;font-size:12px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/Texts/List/styles.scss"],"names":[],"mappings":"AAAA,uGACI,iBAAkB,CAElB,YAAa,CACb,6BAA8B,CAE9B,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,aAAc,CACjB,0GAGG,gBAAiB,CACjB,cAAe","sourcesContent":[".title {\n    margin-bottom: 7px;\n\n    display: flex;\n    justify-content: space-between;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 16px;\n    color: #092A3A;\n}\n\n.download {\n    margin-right: 5px;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-_styles__title",
	"download": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-Texts-List-_styles__download"
};
export default ___CSS_LOADER_EXPORT___;
