import * as React from 'react';
import * as style from './StatusPromotionButton.scss';
import { CreativeRequest, CreativeRequestStatus } from '@api';
import {
    Icon,
    IconType,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    WithTooltip,
} from 'sber-marketing-ui';
import { SetStatus } from '../hooks/useGetCurrentStatus';
import { StatusesList } from '../consts';
import { useDispatch } from 'react-redux';
import { setSelectedStage } from '@store/creative';
import { TooltipContent } from './TooltipContent';
import { useGetParams } from '../hooks/useGetParams';
import { createCreativeRequestItem, validate } from './utils';
import { BackStatusPopup } from './BackStatusPopup';
import { CommentIcon } from './CommentIcon';

interface Props {
    currentStatus: CreativeRequestStatus;
    creativeRequest: CreativeRequest;
    availableStatuses: CreativeRequestStatus[];
    underRevision: boolean;
    quickApprovement: boolean;
    setStatus: SetStatus;
    reviewComment: string | null;
    setReviewComment: ({ commentId }: { commentId: string }) => void;
}

export const StatusPromotionButton = ({
    currentStatus,
    availableStatuses,
    creativeRequest,
    underRevision,
    quickApprovement,
    setStatus,
    reviewComment,
    setReviewComment,
}: Props): JSX.Element => {
    const dispatch = useDispatch();
    const statusItem = StatusesList.find(({ name }) => name === currentStatus);

    const validationData = useGetParams(creativeRequest);
    const validationResult = validate(validationData);

    const [isBackStatusPopup, setIsBackStatusPopup] = React.useState<boolean>(false);

    return (
        <div className={style.root}>
            <div className={style.leftSide}>
                {/* КНОПКА '<= ВЕРНУТЬ'  */}
                {availableStatuses?.includes(CreativeRequestStatus.fillingByAgency) &&
                    [CreativeRequestStatus.checkingByCustomer, CreativeRequestStatus.approvement].includes(
                        currentStatus,
                    ) && (
                        <div className={style.buttonBack}>
                            <Button theme={ButtonTheme.GhostRounded} onClick={() => setIsBackStatusPopup(true)}>
                                <div className={style.buttonBackContent}>
                                    <Icon type={IconType.ARROW16_STRAIGHT_LEFT} svgSize={14} />
                                    <div>Вернуть в агентство на доработку</div>
                                </div>
                            </Button>
                            {isBackStatusPopup && (
                                <BackStatusPopup
                                    onClose={() => setIsBackStatusPopup(false)}
                                    setStatus={setStatus}
                                    reviewComment={reviewComment}
                                    setReviewComment={setReviewComment}
                                    creativeRequest={creativeRequest}
                                />
                            )}
                        </div>
                    )}
                {/* ЗАГОЛОВОК */}
                <div className={style.headerWrapper}>
                    {currentStatus === CreativeRequestStatus.closed && (
                        <Icon type={IconType.ACCEPT_ICON_ROUND_ACTIVE} svgSize={20} />
                    )}

                    {reviewComment && underRevision && currentStatus === CreativeRequestStatus.fillingByAgency && (
                        <WithTooltip content={<div className={style.comment}>{reviewComment}</div>}>
                            <div className={style.commentIconWrapper}>
                                <CommentIcon />
                            </div>
                        </WithTooltip>
                    )}

                    <div className={style.header}>
                        {underRevision && currentStatus === CreativeRequestStatus.fillingByAgency
                            ? 'Заявка возвращена на доработку. Внесите изменения и отправьте на проверку заказчику'
                            : statusItem?.promtText}
                    </div>
                </div>
            </div>

            {/* КНОПКА 'NEXT =>' */}
            {(availableStatuses?.includes(statusItem?.nextStatus) ||
                availableStatuses.includes(CreativeRequestStatus.approvement)) && (
                <WithTooltip hidden={!validationResult.length} content={<TooltipContent params={validationResult} />}>
                    <div className={style.buttonNext}>
                        <Button
                            disabled={!!validationResult.length}
                            theme={ButtonTheme.MainRounded}
                            onClick={() => {
                                setStatus({
                                    status:
                                        underRevision && quickApprovement
                                            ? CreativeRequestStatus.approvement
                                            : statusItem?.nextStatus,
                                });

                                // Переключаем вьюху креатива на таблицы при переходе к статусу fillingByCustomer
                                // и добавляем строку в тарифы
                                if (statusItem?.nextStatus === CreativeRequestStatus.fillingByCustomer) {
                                    dispatch(setSelectedStage(1));
                                    createCreativeRequestItem(creativeRequest);
                                }
                            }}
                        >
                            {underRevision && quickApprovement
                                ? 'Отправить на согласование'
                                : statusItem?.nextButtonText}
                        </Button>
                    </div>
                </WithTooltip>
            )}
        </div>
    );
};
