import { useStore } from './useStore';

interface UseDownloadButton {
    (): UseDownloadButtonReturn;
}

interface UseDownloadButtonReturn {
    canDisplay: boolean;
}

export const useDownloadButton: UseDownloadButton = () => {
    const { contextTableData } = useStore();

    return {
        canDisplay: !!contextTableData?.length,
    };
};
