import * as React from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import * as styles from './NewExecutionIdModal.scss';

import { Button_redesign as Button, ButtonTheme, Icon, IconType, Popup } from 'sber-marketing-ui';
import { BudgetApi, useMrmClient } from '@api';
import { IncrementInput } from './IncrementInput';
import { BudgetStatus } from '@mrm/budget';

interface Props {
    onClose: () => void;
}

export const NewExecutionIdModal = ({ onClose }: Props): JSX.Element => {
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();
    const [params, setParams] = React.useState<any[]>([]);
    const [budgetId, setBudgetId] = React.useState<null | string>(null);

    const client = useMrmClient();

    const handleCreateButton = () => {
        const paramsIdRows = JSON.stringify(params.map(({ id, count }) => ({ id, count: +count })));
        window.open(
            `/budget/execution/new?budgetId=${budgetId}&creativeRequestId=${creativeRequestId}&paramsIdRows=${paramsIdRows}`,
            '_blank',
        );
        onClose();
    };

    const getData = async () => {
        const creativeRequest = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
        const params = await creativeRequest.model.getParams();

        params?.forEach(async (item) => {
            const [division, block, product, channel, segment] = await Promise.all([
                item.model.division,
                item.model.block,
                item.model.product,
                item.model.channel,
                item.model.segment,
            ]);

            setParams((value: any) =>
                [...value, { division, block, product, channel, segment, id: item.model.id }].map((value) => ({
                    ...value,
                    count: '1',
                })),
            );
        });

        const currentYear = new Date().getFullYear();
        const budgets = await BudgetApi.getBudgetList({ status: BudgetStatus.Execution });
        const budgetId = budgets?.find((item) => item.year === currentYear)?.id;
        setBudgetId(budgetId);
    };

    React.useEffect(() => {
        if (client) {
            getData();
        }
    }, [client]);

    return (
        <Popup onOutOfContentClick={onClose}>
            <div className={styles.popup}>
                <div className={styles.modalHeader}>
                    <div>Создать ID исполнения</div>
                    <Icon type={IconType.CROSS} color="#000" onClick={onClose} />
                </div>
                <div>
                    <div className={classNames(styles.checkboxRow, styles.checkboxHeader)}>
                        <div>Параметры</div>
                        <div>Число новых ID, шт</div>
                    </div>
                    {params?.map((item) => (
                        <div className={styles.checkboxRow} key={item.id}>
                            <div className={styles.params}>
                                <div className={styles.column}>
                                    <div className={styles.row}>
                                        <span>Блок: </span>
                                        <span className={styles.bold}>{item?.block?.value || '-'}</span>
                                    </div>
                                    <div className={styles.row}>
                                        <span>МВЗ/Дивизион: </span>
                                        <span className={styles.bold}>{item?.division?.value || '-'}</span>
                                    </div>
                                    <div className={styles.row}>
                                        <span>Продукт: </span>
                                        <span className={styles.bold}>{item?.product?.value || '-'}</span>
                                    </div>
                                </div>

                                <div className={styles.column}>
                                    <div className={styles.row}>
                                        <span>Канал: </span>
                                        <span className={styles.bold}>{item?.channel?.value || '-'}</span>
                                    </div>
                                    <div className={styles.row}>
                                        <span>Сегмент: </span>
                                        <span className={styles.bold}>{item?.segment?.value || '-'}</span>
                                    </div>
                                </div>
                            </div>

                            <IncrementInput
                                value={item.count}
                                onChange={(value) => {
                                    setParams((params) =>
                                        params.map((param) =>
                                            param.id === item.id ? { ...param, count: value } : param,
                                        ),
                                    );
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div className={styles.buttonGroup}>
                    <Button onClick={onClose} theme={ButtonTheme.SberOutlineLong}>
                        Отменить
                    </Button>
                    <Button
                        disabled={params?.map((item) => +item.count)?.reduce((acc, item) => acc + item, 0) <= 0}
                        onClick={handleCreateButton}
                        theme={ButtonTheme.SberPrimaryLong}
                    >
                        Создать
                    </Button>
                </div>
            </div>
        </Popup>
    );
};
