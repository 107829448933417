import * as React from 'react';
import classnames from 'classnames';
import { get, sum, values } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MonthValue } from '@mrm/budget';
import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getLoginUser, userHasPersonalDashboardAccess as userHasPersonalDashboardAccess_ } from '@store/user';

import { Utils, DatesFormatter, Money, MoneyFormatter, Captions } from '@common/Utils';

import { BudgetItemLink } from '../types';

import { LinkControlsData, LinkControlsFetcher } from './LinkControlsFetcher';
import { SAPCommentInput } from './SAPCommentInput';
import { WithTitle } from './WithTitle';
import { StatusBar, Color } from './StatusBar';

import { BudgetItemLinkCardPreloader } from './Preloader';

import * as styles from './BudgetItemLinkCard.scss';

interface Props {
    link: BudgetItemLink;
    refetchLinks: () => void;
}

function useBudgetItemLinkCard({ link: { id, project }, refetchLinks }: Props) {
    const [isDeletionInProgress, setIsDeletionInprogress] = React.useState(false);
    const [linkControlsData, setLinkControlsData] = React.useState<LinkControlsData>(null);
    const userHasPersonalDashboardAccess = useSelector((state: StoreState) =>
        userHasPersonalDashboardAccess_(getLoginUser(state)),
    );

    async function onDeleteButtonClick(event: React.MouseEvent): Promise<void> {
        event.stopPropagation();

        linkControlsData?.removeLink?.();
        setIsDeletionInprogress(true);
        refetchLinks();
    }

    function onSerialNumberLinkClick(event: React.MouseEvent): void {
        event.stopPropagation();
    }

    function onRootClick(): void {
        window.open(`/managerDashboard?activityId=${project.id}&requestId=${id}`);
    }

    return {
        linkControlsData,
        setLinkControlsData,
        userHasPersonalDashboardAccess,
        isDeletionInProgress,
        onDeleteButtonClick,
        onSerialNumberLinkClick,
        onRootClick,
    };
}

export function BudgetItemLinkCard(props: Props): JSX.Element {
    const {
        linkControlsData,
        setLinkControlsData,
        userHasPersonalDashboardAccess,
        isDeletionInProgress,
        onDeleteButtonClick,
        onSerialNumberLinkClick,
        onRootClick,
    } = useBudgetItemLinkCard(props);
    const {
        id,
        project,
        permissions,
        status,
        createdAt,
        approver,
        budgetItem: { serialNumber, budget, dictionary, plannedFunds, reservedFunds, factFunds, ...budgetItem },
    } = props.link;

    const isClickable = userHasPersonalDashboardAccess && permissions.read;
    const showDeleteButton = permissions.delete;

    let statusText;
    let statusColor: Color;
    switch (status) {
        case 'pending':
            statusText = 'На согласовании';
            statusColor = Color.Grey;
            break;
        case 'approved':
            statusText = 'Согласован';
            statusColor = Color.Green;
            break;
        case 'rejected':
            statusText = 'Отклонен';
            statusColor = Color.Red;
            break;
        default:
            statusText = '';
            statusColor = Color.Grey;
    }

    if (isDeletionInProgress) {
        return <BudgetItemLinkCardPreloader />;
    }

    return (
        <React.Fragment>
            <div
                className={classnames(styles.root, isClickable && styles.root_clickable)}
                onClick={isClickable ? onRootClick : null}
                {...{
                    'qa-id': 'serialNumberCard',
                }}
            >
                <div className={styles.generalInfoWrapper}>
                    <div className={styles.topRow}>
                        <SerialNumberLink
                            renderLink={budgetItem.permissions.read}
                            activityId={project.id}
                            serialNumber={serialNumber}
                            budgetId={budget.id}
                            onClick={onSerialNumberLinkClick}
                        />

                        {status && (
                            <div className={styles.status}>
                                <StatusBar color={statusColor} text={statusText} />
                            </div>
                        )}

                        {createdAt && (
                            <div
                                className={styles.date}
                                {...{
                                    'qa-id': 'serialNumberCardDate',
                                }}
                            >
                                <Icon type={IconType.CALENDAR_ICON} svgSize={16} className={styles.dateIcon} />

                                {DatesFormatter.ddMonthyy(createdAt)}
                            </div>
                        )}

                        {showDeleteButton && (
                            <div
                                className={styles.deleteButton}
                                {...{
                                    'qa-id': 'serialNumberCardDeleteButton',
                                }}
                            >
                                <Icon type={IconType.DELETE_V2} svgSize={20} onClick={onDeleteButtonClick} />
                            </div>
                        )}
                    </div>

                    <SAPCommentInput
                        canEdit={linkControlsData?.canEdit}
                        linkId={id}
                        budgetItemId={budgetItem.id}
                        budgetId={budget.id}
                        serialNumber={serialNumber}
                        expertId={approver ? approver.id : null}
                        isRequestApproved={status === 'approved'}
                    />

                    <div className={styles.withTitle}>
                        <WithTitle
                            qaId="serialNumberCardTool"
                            title="Инструмент"
                            text={Utils.withErrorHandler(() => Utils.getDictionaryValue(dictionary.tool)) || 'Не задан'}
                        />
                    </div>

                    <div className={styles.withTitle}>
                        <WithTitle
                            qaId="serialNumberCardProduct"
                            title="Продукт"
                            text={get(dictionary, 'product.value') || 'Не задан'}
                        />
                    </div>

                    <div className={styles.withTitle}>
                        <WithTitle
                            qaId="serialNumberCardSegment"
                            title="Сегмент"
                            text={get(dictionary, 'segment.value') || 'Не задан'}
                        />
                    </div>

                    {approver && (
                        <div className={styles.withTitle}>
                            <WithTitle
                                qaId="serialNumberCardExpert"
                                title="Согласующий эксперт"
                                text={`${approver.secondName} ${approver.firstName}`}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.budgetInfoWrapper}>
                    <BudgetInfo title="Итого план" budget={plannedFunds} />

                    <BudgetInfo title="Итого резерв" budget={reservedFunds} />

                    <BudgetInfo title="Итого факт" budget={factFunds} />
                </div>
            </div>

            <LinkControlsFetcher
                budgetId={budget.id}
                activityId={project.id}
                budgetItemId={budgetItem.id}
                userHasBudgetAccess
                forwardData={setLinkControlsData}
            />
        </React.Fragment>
    );
}

interface SerialNumberLinkProps {
    renderLink: boolean;
    activityId: number;
    serialNumber: number;
    budgetId: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

function SerialNumberLink({
    renderLink,
    activityId,
    serialNumber,
    budgetId,
    onClick,
}: SerialNumberLinkProps): JSX.Element {
    if (renderLink) {
        const url = `/budget/execution?activityId=${activityId}&filters=serialNumber:${serialNumber}&budgetId=${budgetId}`;

        return (
            <Link to={url} target="_blank" className={styles.idLink} onClick={onClick}>
                ID {serialNumber}
            </Link>
        );
    }

    return <div className={styles.id}>ID {serialNumber}</div>;
}

interface BudgetInfoProps {
    title: string;
    budget: MonthValue;
}

function BudgetInfo({ title, budget }: BudgetInfoProps): JSX.Element {
    const money = Money.fromCopecks(sum(values(budget).filter((budget) => typeof budget === 'number')));
    const thousandContent = MoneyFormatter.toThousands(money, { hideCaption: true });

    return (
        <div
            className={styles.budgetInfo}
            {...{
                'qa-id': 'serialNumberCardBudgetInfo',
                'qa-label': title,
                'qa-value': thousandContent,
            }}
        >
            <div className={styles.budgetInfoTitle}>
                {title}, {Captions.Thousands}
            </div>

            <WithTooltip content={MoneyFormatter.toRoubles(money)}>
                <div className={styles.budgetInfoSum}>{thousandContent}</div>
            </WithTooltip>
        </div>
    );
}
