import { includes, isEqual } from 'lodash';

import { Brief } from 'sber-marketing-types/frontend';
import { Brief as BriefView } from 'sber-marketing-types/frontend/Brief2';

import { store } from '@store';
import { getPlannedBudgetEditPageStateSaved } from '@store/plannedBudgetEdit/selectors';
import { BriefApi } from '@api';

interface ConstructorParams {
    briefId: string;
}

interface StoreProps {
    originalBrief: Brief;
    changedBrief: Brief;
}

const IMAGE_SERVICE_STORAGE_NAME = 'imageService';
const LOCAL_STORAGE_KEY = 'copiedBrief';

export class BriefSaver {
    private briefId: string;
    private currentBriefWasCopied: boolean;

    constructor({ briefId }: ConstructorParams) {
        this.briefId = briefId;
        this.currentBriefWasCopied = false;
    }

    public async saveBrief(): Promise<void> {
        const { originalBrief, changedBrief } = this.getStoreProps();

        const isBriefHasChanged = !isEqual(originalBrief, changedBrief);

        if (isBriefHasChanged) {
            await BriefApi.updateBrief(changedBrief);

            if (this.currentBriefWasCopied) {
                await this.updateBriefWithUpdateFiles();
            }
        }

        this.currentBriefWasCopied = false;
    }

    public async actualBrief(oldBrief: BriefView, actualBrief: BriefView) {
        const values = {};
        (oldBrief.blocks || []).forEach((block) => {
            (block.fields || []).forEach((field) => {
                values[field.properties?.parentFieldId || field.id] = field.value;
            });
        });
        const currentBrief = {
            ...oldBrief,
            schemeId: actualBrief.id,
            blocks: (actualBrief.blocks || []).map((block) => ({
                ...block,
                fields: (block.fields || []).map((field) => ({
                    ...field,
                    value: values[field.properties?.parentFieldId || field.id],
                })),
            })),
        };
        await BriefApi.updateBrief(currentBrief);
    }

    public async applyBriefScheme(schemeId: string) {
        await BriefApi.setBriefScheme(this.briefId, schemeId);
    }

    public setBriefWasCopied(value: boolean): void {
        this.currentBriefWasCopied = value;
    }

    public setBriefId(briefId: string): void {
        this.briefId = briefId;
    }

    private async updateBriefWithUpdateFiles(): Promise<void> {
        const { changedBrief } = this.getStoreProps();
        const copiedBrief = this.getCopiedBrief();
        const notImagesFilesIds: string[] = this.getNotImageFilesIdsFromBrief(changedBrief);

        if (copiedBrief && copiedBrief.id) {
            const filesParams = notImagesFilesIds.map((fileIid) => ({
                briefId: changedBrief.id,
                oldContainerName: `brief-${copiedBrief.id}`,
                oldFileName: fileIid,
                newContainerName: `brief-${changedBrief.id}`,
                newFileName: fileIid,
            }));

            await Promise.all(filesParams.map((params) => BriefApi.updateFileBrief(params)));
        }
    }

    private getNotImageFilesIdsFromBrief(brief: Brief): string[] {
        const notFilesImagesIds: string[] = [];
        const filesImagesIds: string[] = [];

        brief.blocks.forEach((block) => {
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    field.value.files.forEach((file) => {
                        const isFileImage = file.storage === IMAGE_SERVICE_STORAGE_NAME;

                        if (isFileImage) {
                            filesImagesIds.push(String(file.id));
                        }
                    });
                }
            });
        });

        brief.blocks.forEach((block) => {
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    field.value.files.forEach((file) => {
                        const notFileImage = !includes(filesImagesIds, String(file.id));

                        if (notFileImage) {
                            notFilesImagesIds.push(String(file.id));
                        }
                    });
                }
            });
        });

        return notFilesImagesIds;
    }

    private getCopiedBrief(): Brief {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { originalBriefs, changedBriefs } = getPlannedBudgetEditPageStateSaved(storeState);

        return {
            originalBrief: originalBriefs[this.briefId],
            changedBrief: changedBriefs[this.briefId],
        };
    }
}
