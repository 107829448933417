import * as React from 'react';
import * as lodash from 'lodash';

import * as styles from './CreateReserve.scss';

import { BudgetItem, Month } from '@mrm/budget';
import type { CreativeRequest, CreativeRequestItem } from '@api';

import {
    Button_redesign as Button,
    ButtonTheme,
    Icon,
    IconType,
    LabeledSelect_redesign as LabeledSelect,
    Popup,
} from 'sber-marketing-ui';
import { IdSelector } from './IdSelector';
import { IdCard } from './IdCard';
import { MonthItems } from './MonthItems';
import { MonthList } from './consts';
import { OriginalMounthList } from './OriginalMounthList';
import { Amount } from './Amount';
import { useMonthItem } from './hooks/useMonthItem';
import { formatAmount, getIdCardData, getResultAmount } from './utils';
import { BudgetItemApi } from '@api';

export enum Itemtype {
    CAPEX = 'capex',
    OPEX = 'opex',
}

export enum BudgetItemDirection {
    to = 'to',
    from = 'from',
}

interface Props {
    executorItemsList: BudgetItem[];
    initialExecutorItemId: string;
    creativeRequest: CreativeRequest;
    creativeRequestItems: CreativeRequestItem[];
    onSave: () => void;
    onClose: () => void;
}

export const CreateReserve = ({
    executorItemsList,
    initialExecutorItemId,
    creativeRequest,
    creativeRequestItems,
    onSave,
    onClose,
}: Props): JSX.Element => {
    const initialExecutorItem = executorItemsList.find((item) => item.id === initialExecutorItemId);

    const [creatinonLoading, setCreatinonLoading] = React.useState<boolean>(false);
    const [executorItem, setExecutorItem] = React.useState<BudgetItem | null>(initialExecutorItem || null);

    const monthIndex = new Date().getMonth();
    const [month, setMonth] = React.useState<Month>(MonthList[monthIndex === 11 ? 11 : monthIndex + 1].value);

    const { allMonthItemList, currentMonthItemList, editMothItem } = useMonthItem({
        budgetItem: executorItem,
        budgetItemsList: executorItemsList,
        month: month,
    });

    const agreedReserve = {
        withNDS: lodash.sumBy(creativeRequestItems, (item) => item.model.actualCostWithVat),
        withoutNDS: lodash.sumBy(creativeRequestItems, (item) => item.model.actualCostWithoutVat),
    };

    const handleCreateButton = async () => {
        const idList = executorItemsList.map((item) => item.id);

        let promises: Promise<string[]>[] = allMonthItemList
            .filter((item) => idList.includes(item?.id) && item.month === month)
            .map((monthItem) =>
                BudgetItemApi.updateBudgetItem({
                    id: monthItem.id,
                    reservedFunds: {
                        ...executorItemsList.find((budgetItem) => budgetItem.id === monthItem.id).reservedFunds,
                        [monthItem.month]: monthItem.value + monthItem.reserved,
                    },
                }),
            );

        setCreatinonLoading(true);
        try {
            const res = await Promise.all(promises);

            const correctionIds = lodash.compact(lodash.flatten(res));

            await creativeRequest.model.addCorrections({ correctionIds });

            onSave();
            onClose();
        } catch (err) {
            console.log('handleCreateButton', err);
            onClose();
        }
        setCreatinonLoading(false);
    };

    return (
        <Popup onOutOfContentClick={onClose}>
            <div className={styles.popup}>
                <div className={styles.modalHeader}>
                    <div>Внесение резерва на месяц</div>
                    <Icon type={IconType.CROSS} color="#000" onClick={onClose} />
                </div>

                <div className={styles.text}>
                    <span>Согласованный резерв (без НДС / с НДС):</span>
                    <span className={styles.blueBold}>{formatAmount(agreedReserve.withoutNDS)} ₽</span>
                    <span>/</span>
                    <span className={styles.blueBold}>{formatAmount(agreedReserve.withNDS)} ₽</span>
                </div>

                <IdSelector
                    title="ID исплнения"
                    IDsList={executorItemsList}
                    selectedItem={executorItem}
                    setID={setExecutorItem}
                />

                <IdCard {...getIdCardData({ budgetItem: executorItem })} />

                <div style={{ width: '50%' }}>
                    <LabeledSelect
                        title={'Резерв'}
                        items={MonthList}
                        selectedValue={month}
                        onSelection={(value) => setMonth(value as Month)}
                    />
                </div>

                <MonthItems sourceMonthItemList={currentMonthItemList} editMothItem={editMothItem} />

                <OriginalMounthList budgetItem={executorItem} />

                <Amount
                    capexAmount={getResultAmount({ allMonthItemList, month, itemType: Itemtype.CAPEX })}
                    opexAmount={getResultAmount({ allMonthItemList, month, itemType: Itemtype.OPEX })}
                />

                <div className={styles.buttonGroup}>
                    {/* <div className={styles.buttonFlex}> */}
                    <Button onClick={onClose} theme={ButtonTheme.SberOutlineLong}>
                        Отменить
                    </Button>
                    <Button onClick={handleCreateButton} theme={ButtonTheme.SberPrimaryLong}>
                        {creatinonLoading ? 'Подождите...' : 'Создать'}
                    </Button>
                    {/* </div> */}
                </div>
            </div>
        </Popup>
    );
};
