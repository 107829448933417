import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getTasksList } from '@store/tasksList';
import { getTaskFilters, setTaskFilters } from '@store/activityTasksPage';
import { getActivityStageState } from '@store/activityTasksPage/stages';

import * as styles from './TitleWithStagesFilter.scss';

function useInteractivity() {
    const dispatch = useDispatch();
    const tasksCount = useSelector((state: StoreState) => state.tasksList.tasksTotalCount);
    const stagesFilter = useSelector((state: StoreState) => getTaskFilters(state).activityStage);
    const stages = useSelector((state: StoreState) => getActivityStageState(state).existingStages.byId);

    const deleteFilterItem = React.useCallback(
        (id: string) =>
            dispatch(
                setTaskFilters({
                    activityStage: stagesFilter.filter((filterItem) => filterItem !== id),
                }),
            ),
        [dispatch, setTaskFilters, stagesFilter],
    );

    return {
        tasksCount,
        stagesFilter,
        stages,
        deleteFilterItem,
    };
}

export function TitleWithStagesFilter(): JSX.Element {
    const { tasksCount, stagesFilter, stages, deleteFilterItem } = useInteractivity();

    return (
        <div className={styles.root}>
            <span className={styles.title}>
                <span className={styles.titleBlack}>Задачи&nbsp;</span>
                {tasksCount || ''}
            </span>

            {stagesFilter.map((filterItem) => {
                let title;
                if (filterItem === null) {
                    title = 'Вне этапов';
                } else if (stages[filterItem] && stages[filterItem].name) {
                    title = stages[filterItem].name;
                } else {
                    title = 'Этап не найден';
                }

                return (
                    <FilterItem key={filterItem} id={filterItem} title={title} onDeleteButtonClick={deleteFilterItem} />
                );
            })}
        </div>
    );
}

interface FilterItemProps {
    id: string;
    title: string;
    onDeleteButtonClick: (id: string) => void;
}

function FilterItem({ id, title, onDeleteButtonClick }: FilterItemProps): JSX.Element {
    return (
        <div className={styles.filterItem}>
            {title}

            <svg
                className={styles.fitlerItemDeleteButton}
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => onDeleteButtonClick(id)}
            >
                <path
                    d="M1.53659 0.263604C1.18512 -0.087868 0.615271 -0.087868 0.263799 0.263604C-0.087673 0.615076 -0.087673 1.18492 0.263799 1.5364L2.1273 3.3999L0.263603 5.2636C-0.0878676 5.61508 -0.087868 6.18492 0.263603 6.5364C0.615075 6.88787 1.18492 6.88787 1.5364 6.5364L3.4001 4.6727L5.2638 6.5364C5.61527 6.88787 6.18512 6.88787 6.53659 6.5364C6.88806 6.18492 6.88806 5.61508 6.53659 5.2636L4.67289 3.3999L6.5364 1.5364C6.88787 1.18492 6.88787 0.615076 6.5364 0.263604C6.18492 -0.0878676 5.61508 -0.087868 5.2636 0.263604L3.4001 2.12711L1.53659 0.263604Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
}
