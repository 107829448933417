import * as React from 'react';
import classNames from 'classnames';

import { SelectInput } from '../../../../../../common';
import { useOptions } from './useOptions';

import * as styles from './styles.scss';

interface Props {
    className?: string;
}

export const GroupSpecSelector: React.FC<Props> = ({ className }) => {
    const { options, defaultValue, onChange } = useOptions();

    return (
        <div className={classNames(styles.root, className)}>
            <div>
                <div className={styles.title}>Тип креатива</div>

                <div className={styles.description}>Выберите тип креатива</div>
            </div>

            <div className={styles.input}>
                <SelectInput
                    defaultValue={defaultValue}
                    options={options}
                    placeholder={'Выберите тип креатива...'}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};
