import { includes } from 'lodash';
import { HandlerMap } from './types';

export const constraintHandlerMap: HandlerMap = {
    min: async () => {},
    max: async () => {},
    maxKb: async (maxKb, file) => {
        const message = file.size >= maxKb * 1000 ? 'Превышен максимальный размер файла' : undefined;
        return (
            message && {
                type: 'error',
                value: message,
            }
        );
    },
    allowedMimeTypes: async (allowedMimeTypes, file) => {
        const message = !includes(allowedMimeTypes, file.type) ? 'Недопустимый тип файла' : undefined;
        return (
            message && {
                type: 'error',
                value: message,
            }
        );
    },
    ratioW: async (ratioW, file) => {
        try {
            const video = await getVideo(file);
            const result =
                video.videoWidth / gcdTwoNumbers(video.videoWidth, video.videoHeight) !== ratioW
                    ? 'Соотношение по ширине не совпадает'
                    : undefined;
            await revokeVideo(video);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на соотношение по ширине',
            };
        }
    },
    ratioH: async (ratioH, file) => {
        try {
            const video = await getVideo(file);
            const result =
                video.videoHeight / gcdTwoNumbers(video.videoWidth, video.videoHeight) !== ratioH
                    ? 'Соотношение по высоте не совпадает'
                    : undefined;
            await revokeVideo(video);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на соотношение по высоте',
            };
        }
    },
    recommendedWidth: async () => {},
    recommendedHeight: async () => {},
    minWidth: async (minWidth, file) => {
        try {
            const image = await getVideo(file);
            const result = image.videoWidth < minWidth ? 'Превышена минимальная ширина видео' : undefined;
            await revokeVideo(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на минимальную ширину видео',
            };
        }
    },
    minHeight: async (minHeight, file) => {
        try {
            const image = await getVideo(file);
            const result = image.videoHeight < minHeight ? 'Превышена минимальная высота видео' : undefined;
            await revokeVideo(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на минимальную высоту видео',
            };
        }
    },
    maxWidth: async (maxWidth, file) => {
        try {
            const image = await getVideo(file);
            const result = image.videoWidth > maxWidth ? 'Превышена максимальная ширина видео' : undefined;
            await revokeVideo(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на максимальную ширину видео',
            };
        }
    },
    maxHeight: async (maxHeight, file) => {
        try {
            const image = await getVideo(file);
            const result = image.videoHeight > maxHeight ? 'Превышена максимальная высота видео' : undefined;
            await revokeVideo(image);
            return (
                result && {
                    type: 'error',
                    value: result,
                }
            );
        } catch (_) {
            return {
                type: 'warning',
                value: 'Тип файла не поддерживает проверку на максимальную высоту видео',
            };
        }
    },
};

const getVideo = (file: File): Promise<HTMLVideoElement> => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = window.URL.createObjectURL(file);
        video.addEventListener('loadedmetadata', () => resolve(video));
        video.addEventListener('error', reject);
    });
};

const revokeVideo = async (image: HTMLVideoElement): Promise<void> => {
    window.URL.revokeObjectURL(image.src);
};

const gcdTwoNumbers = (x: number, y: number) => {
    x = Math.abs(x);
    y = Math.abs(y);
    while (y) {
        let t = y;
        y = x % y;
        x = t;
    }
    return x;
};
