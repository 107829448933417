import * as React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import * as ExcelJS from 'exceljs';
import * as queryString from 'query-string';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { RouteComponentProps } from 'react-router-dom';
import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import { SwitchKeyActivityParams, TableType } from '@store/creative/types';
import type { HeaderView } from '@common/Page';

import { CreativePageTemplate, DownloadButton } from './CreativePageTemplate';
import { TableBehaviour } from './StageWidgets/TableWidget/Table/TableBehaviour';
import { CreativeRequestStatus } from '@api';
import { getStageByStatus } from './utils';
import type { StoreState } from '@store';
import { loadUserConfig } from '@store/userConfig/thunks';
import { setCurrentStage, setSelectedStage } from '@store/creative/actions';
import { getActivity, getCurrentStage, getSelectedStage } from '@store/creative/selectors';
import { getUserConfigState, UserConfigType, saveUserConfig, SaveUserConfig } from '@store/userConfig';
import { switchKeyActivity } from '@store/creative/thunks';
import { Utils } from '@common/Utils';
import { Loader } from './modules';

interface Props extends Partial<MapProps & DispatchProps & RouteComponentProps<RouteParams>> {
    creativeRequestStatus: CreativeRequestStatus;
    tableRef: React.MutableRefObject<TableBehaviour>;
    setHeaderView: (view: HeaderView) => void;
    setSubHeaderRightContent: (content: JSX.Element) => void;
    tableDescriptionDataForXLSX: {
        userRows: string[][];
        infoRous: string[][];
    };
}

interface MapProps {
    activity?: Activity;
    currentStage?: number;
    selectedStage?: number;
    isSidebarOpened: boolean;
}

interface DispatchProps {
    setCurrentStage: (stage: number) => void;
    setSelectedStage: (stage: number) => void;
    switchKeyActivity: (params: SwitchKeyActivityParams) => Promise<void>;
    saveUserConfig: (params: SaveUserConfig<UserConfigType.Creative>) => void;
    loadUserConfig: () => void;
}

interface RouteParams {
    activityId: string;
    creativeRequestId: string;
}

interface State {
    loading: boolean;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CreativePageBehaviour extends React.PureComponent<Props, State> {
    private activityId = Number(this.props.match.params.activityId);
    private creativeRequestId = this.props.match.params.creativeRequestId;
    private loader: Loader;

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
        };

        this.loader = Loader.getInstance();
    }

    public async componentDidMount() {
        this.props.loadUserConfig();

        this.initStageNumberFromQuery();
        await this.loader.init(this.activityId);

        const stage = getStageByStatus(this.props.creativeRequestStatus);

        // this.props.setCurrentStage(stage);
        // this.props.setSelectedStage(stage);

        this.updateHeader(stage > 0);

        this.setState({
            loading: false,
        });
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        const shouldUpdateStage = this.props.creativeRequestStatus !== prevProps.creativeRequestStatus;
        const selectedStageChanged = this.props.selectedStage !== prevProps.selectedStage;

        if (shouldUpdateStage) {
            const stage = getStageByStatus(this.props.creativeRequestStatus);

            // this.props.setCurrentStage(stage);
            // this.props.setSelectedStage(stage);
        }

        if (selectedStageChanged) {
            this.updateHeader(this.props.selectedStage > 0);
        }
    }

    public render(): JSX.Element {
        const { selectedStage, currentStage, isSidebarOpened } = this.props;
        const { loading } = this.state;

        return React.createElement(CreativePageTemplate, {
            loading,
            currentStage,
            selectedStage,
            activityId: this.activityId,
            creativeRequestId: this.creativeRequestId,
            openedSidebar: isSidebarOpened,
            onCloseSidebar: this.onCloseSidebar,
        });
    }

    @autobind
    private onCloseSidebar(): void {
        this.props.saveUserConfig({
            type: UserConfigType.Creative,
            payload: {
                sidebar: {
                    visibility: false,
                },
            },
        });
    }

    @autobind
    private async onDownloadXLSXButtonClick() {
        const [tariffTableData, productionTableData, akTableData] = await Promise.all([
            this.props.tableRef.current.getTableDataForXLSX(TableType.Tariff),
            this.props.tableRef.current.getTableDataForXLSX(TableType.Production),
            this.props.tableRef.current.getTableDataForXLSX(TableType.Ak),
        ]);

        const userTableData = {
            columnsWidths: [150, 150, 150],
            headers: ['Пользователь', 'Подразделение', 'Роль'],
            rows: [['', '', ''], ...this.props.tableDescriptionDataForXLSX?.userRows],
        };

        const infoTableData = {
            columnsWidths: [150, 150],
            headers: ['', ''],
            rows: this.props.tableDescriptionDataForXLSX?.infoRous,
        };

        addEmptyColumns(tariffTableData, 14, 4);
        addEmptyColumns(akTableData, 7, 2);
        addEmptyColumns(akTableData, 12, 6);

        const workbook = new ExcelJS.Workbook();

        const znkSheet = workbook.addWorksheet(`ЗНК`);
        const infoSheet = workbook.addWorksheet(`Информация`);
        const participantsSheet = workbook.addWorksheet(`Участники`);

        this.addTableDataToWorksheet(znkSheet, 'Тариф', tariffTableData);
        this.addTableDataToWorksheet(znkSheet, 'Производство', productionTableData);
        this.addTableDataToWorksheet(znkSheet, 'АК', akTableData);

        this.addTableDataToWorksheet(participantsSheet, 'Участники', userTableData);
        this.addTableDataToWorksheet(infoSheet, 'Информация', infoTableData);

        Utils.downloadAsXLSX((await workbook.xlsx.writeBuffer()) as Buffer, this.makeXLSXFileName());

        function addEmptyColumns(
            tableData: { columnsWidths: number[]; headers: string[]; rows: React.ReactText[][] },
            index: number,
            count: number,
        ) {
            tableData.columnsWidths.splice(index, 0, ...lodash.times(count, (index) => 0));
            tableData.headers.splice(index, 0, ...lodash.times(count, (index) => ''));

            tableData.rows.forEach((rowValues) => {
                rowValues.splice(index, 0, ...lodash.times(count, (index) => ''));
            });
        }
    }

    private addTableDataToWorksheet(
        sheet: ExcelJS.Worksheet,
        title: string,
        tableData: {
            columnsWidths: number[];
            headers: string[];
            rows: React.ReactText[][];
        },
    ) {
        let rowIndex = sheet.rowCount;

        addSeperatorRow();
        addTitleRow();
        addSeperatorRow();
        addHeadersRow();

        tableData.rows.forEach((rowValues) => {
            addTableRow(rowValues);
        });

        autosizeColumns();

        function addTitleRow() {
            rowIndex += 1;

            const titleRow = sheet.getRow(rowIndex);
            const titleCell = titleRow.getCell(1);

            titleCell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
            titleCell.value = title;
        }

        function addHeadersRow() {
            rowIndex += 1;

            const headersRow = sheet.getRow(rowIndex);

            tableData.headers.forEach((headerTitle, cellIndex) => {
                const cell = headersRow.getCell(cellIndex + 1);

                cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                cell.value = headerTitle;
            });
        }

        function addTableRow(rowValues: React.ReactText[]) {
            rowIndex += 1;

            const row = sheet.getRow(rowIndex);

            rowValues.forEach((value, cellIndex) => {
                const cell = row.getCell(cellIndex + 1);

                if (typeof value === 'number') {
                    cell.numFmt = '0.00';
                }

                if (value !== undefined) {
                    cell.value = value;
                    cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                }
            });
        }

        function addSeperatorRow() {
            rowIndex += 1;
            sheet.addRow({ id: rowIndex, value: rowIndex });
        }

        function autosizeColumns() {
            sheet.columns.forEach((column) => {
                const columnValues = column.values.map((item) => item.toString().length);

                let maxWidth = Math.max(...lodash.compact(columnValues));
                const padding = 4;

                if (maxWidth > 100) {
                    maxWidth = 100;
                }

                column.width = maxWidth + padding;
            });
        }
    }

    private makeXLSXFileName(): string {
        const date = moment().format('DD MMM YY_hh.mm');

        return `ЗНК_${date}`;
    }

    private updateHeader(displayDownloadButton: boolean) {
        const rightContent = displayDownloadButton
            ? React.createElement(DownloadButton, { onClick: this.onDownloadXLSXButtonClick })
            : null;

        this.props.setSubHeaderRightContent(rightContent);
    }

    private initStageNumberFromQuery() {
        const stageNumber = this.getStageNumberFromQuery();

        if (stageNumber) {
            this.props.setCurrentStage(stageNumber);
            this.removeQueryFromUrl();
        }
    }

    private getStageNumberFromQuery(): number {
        const stage = queryString.parse(this.props.location.search).stage as string;

        return stage ? parseInt(stage, 10) : null;
    }

    private removeQueryFromUrl() {
        this.props.history.push({
            search: '',
        });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        activity: getActivity(state),
        currentStage: getCurrentStage(state),
        selectedStage: getSelectedStage(state),
        isSidebarOpened: getUserConfigState(state)[UserConfigType.Creative].fields.sidebar.visibility,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        setCurrentStage: (stage) => dispatch(setCurrentStage(stage)),
        setSelectedStage: (stage) => dispatch(setSelectedStage(stage)),
        switchKeyActivity: (params) => dispatch(switchKeyActivity(params)),
        saveUserConfig: (params) => dispatch(saveUserConfig(params)),
        loadUserConfig: () => dispatch(loadUserConfig(UserConfigType.Creative)),
    };
}
