import * as React from 'react';

interface OverflowCheckResults {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
}

function makeDefaultResults(): OverflowCheckResults {
    return {
        top: false,
        right: false,
        bottom: false,
        left: false,
    };
}

export function calculateScreenOverflow(el: HTMLElement): OverflowCheckResults {
    const rect = el.getBoundingClientRect();

    return {
        top: rect.top < 0,
        right: rect.right > window.innerWidth,
        bottom: rect.bottom > window.innerHeight,
        left: rect.left < 0,
    };
}

export function useElementScreenOverflowCheck<E extends HTMLElement>(ref: React.RefObject<E>) {
    const [result, setResult] = React.useState<OverflowCheckResults>(makeDefaultResults());

    React.useEffect(
        function onRefUpdated() {
            setResult(ref.current ? calculateScreenOverflow(ref.current) : makeDefaultResults());
        },
        [ref.current],
    );

    return result;
}
