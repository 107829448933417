import { DragEventHandler, useEffect, useRef, useState } from 'react';

export interface UseDragAndDropProps<T = Element> {
    onDragOver?: DragEventHandler<T>;
    onDragLeave?: DragEventHandler<T>;
    onDragEnd?: DragEventHandler<T>;
    onDrop?: DragEventHandler<T>;
}

export interface UseDragAndDropResult<T = Element> {
    isDragOver: boolean;
    isDragOverWindow: boolean;
    handleDragOver: DragEventHandler<T>;
    handleDragLeave: DragEventHandler<T>;
    handleDragEnd: DragEventHandler<T>;
    handleDrop: DragEventHandler<T>;
}

export function useDragAndDrop<T = Element>(
    dropHandler: DragEventHandler<T>,
    { onDragOver, onDragLeave, onDragEnd, onDrop }: UseDragAndDropProps<T> = {},
): UseDragAndDropResult<T> {
    const [isDragOver, setIsDragOver] = useState(false);
    const [isDragOverWindow, setIsDragOverWindow] = useState(false);
    const refs = useRef({ leaving: undefined });

    useEffect(() => {
        let isShow = false;
        const addListener = (e: Event) => {
            e.preventDefault();

            if (!isShow) {
                isShow = true;
                setIsDragOverWindow(true);
            }
        };

        const removeListener = () => {
            if (isShow) {
                isShow = false;
                setIsDragOverWindow(false);
            }
        };

        document.addEventListener('dragover', addListener);
        document.addEventListener('dragend', removeListener);
        document.addEventListener('drop', removeListener);
        window.addEventListener('dragleave', removeListener);

        return () => {
            document.removeEventListener('dragover', addListener);
            document.removeEventListener('dragend', removeListener);
            document.removeEventListener('drop', removeListener);
            window.removeEventListener('dragleave', removeListener);
        };
    }, []);

    const handleDragOver: typeof onDragOver = (e) => {
        e.preventDefault();
        onDragOver?.(e);
        clearTimeout(refs.current.leaving);

        if (!isDragOver) {
            setIsDragOver(true);
        }
    };

    const handleDragLeave: typeof onDragLeave = (e) => {
        onDragLeave?.(e);
        clearTimeout(refs.current.leaving);
        refs.current.leaving = setTimeout(() => {
            setIsDragOver(false);
        }, 100);
    };

    const handleDragEnd: typeof onDragEnd = (e) => {
        onDragEnd?.(e);
        setIsDragOver(false);
    };

    const handleDrop: typeof onDrop = (e) => {
        e.preventDefault();
        dropHandler(e);
        onDrop?.(e);
        setIsDragOver(false);
    };

    return {
        handleDragOver,
        handleDragLeave,
        handleDragEnd,
        handleDrop,
        isDragOver,
        isDragOverWindow,
    };
}
