import * as React from 'react';
import * as style from './SummaryLine.scss';
import classnames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';
import { useGetAmounts } from './hooks/useGetAmounts';
import { Amount } from './Amount';
import { updateCreativeUserConfig } from '@store/userConfig/creative';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '@store/index';

export const SummaryLine = (): JSX.Element => {
    const dispatch = useDispatch();
    const amounts = useGetAmounts();
    const isFixed = useSelector((state: StoreState) => state.userConfig.creative.fields.summaryLineSticky);

    return (
        <div className={classnames(style.root, isFixed && style.sticky)}>
            <div className={style.emptySpace}>
                <div className={style.whiteBackground}>
                    <div
                        onClick={() => dispatch(updateCreativeUserConfig({ summaryLineSticky: !isFixed }))}
                        className={classnames(style.button, isFixed && style.buttonActive)}
                    >
                        <Icon type={IconType.PIN} svgSize={14} className={classnames(style.icon)} />
                        <span>{isFixed ? 'Открепить' : 'Закрепить'}</span>
                    </div>
                </div>
                <div className={style.grayBackground}></div>
            </div>

            <div className={style.totals}>
                {amounts.map(({ title, amount }, index) => (
                    <Amount key={title} title={title} amount={amount} first={index === 0} />
                ))}
            </div>
        </div>
    );
};
