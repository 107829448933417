import * as React from 'react';

import { ConstraintList } from '../ConstraintList';
import { Field } from '@store/autopilot/creativeForm';

import * as styles from './styles.scss';

interface Props {
    field: Field;
}

export const Header: React.FC<Props> = ({ field }) => {
    return (
        <div>
            <div className={styles.title}>
                {field.title}

                {field.required && (
                    <>
                        &nbsp;
                        <span className={styles.requiredIndicator}>*</span>
                    </>
                )}
            </div>

            {field.description && <div className={styles.description}>{field.description}</div>}
            {field.constraints && <ConstraintList constraints={field.constraints} />}
        </div>
    );
};
