import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import {
    CellPosition,
    ColumnWidths,
    // LineHeights,
    Line,
    LineId,
    TableHeaderCellParams,
    TableBodyCellParams,
} from './types';
import { TableEvent, ColumnName, TableType, LineType } from './types';
import type { BudgetItem } from '@mrm/budget';
import type {
    Project,
    CreativeRequest,
    CreativeRequestItem,
    // ProjectBudgetItem,
    CreativeRequestContract,
    CreativeRequestCorrectionEntry as CreativeRequestCorrection,
} from '@api';

import { CellsStorage, TableView, CellEvent } from 'sber-marketing-ui';
import { TableTemplate } from './TableTemplate';
import {
    MakeLeftFixedColumns,
    MakeRightFixedColumns,
    MakeTableColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
    AccessorParams,
    // CellType,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';

interface Props {
    capex?: boolean;
    project: Project;
    creativeRequest: CreativeRequest;
    creativeRequestItems: CreativeRequestItem[];
    budgetItems: BudgetItem[];
    corrections: CreativeRequestCorrection[];
    groupedCorrections: Record<'plan' | 'reserve', CreativeRequestCorrection[]>;
    contracts: CreativeRequestContract[];
    grouppedContracts: Record<'lot1' | 'lot2', CreativeRequestContract[]>;
    onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;
}

interface State {
    budgetItemsTableLinesIds: LineId[];
    totalTableLineIds: LineId[];
    columns: Record<'left' | 'center' | 'right', ColumnName[]>;
    isExpanded: boolean;
    expandedLineIds: LineId[];
}

export class TableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private budgetItemsTable: TableView;
    private totalTable: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private fixedWidthColumns: ColumnName[];
    private defaultColumnWidths: ColumnWidths = {};

    public constructor(props: Props) {
        super(props);

        this.state = {
            budgetItemsTableLinesIds: [],
            totalTableLineIds: ['total', 'creativeFactSum', 'delta', 'lineIdsWithoutExecutionId'],
            columns: this.makeColumns(),
            isExpanded: false,
            expandedLineIds: [],
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.getLine,
            getAllLines: this.getLines,
            getColumnsConfig: this.getColumnsConfig,
            getAccessorParamsData: this.getAccessorParamsData,
            getExpandedLineIds: this.getExpandedLineIds,
            onLineExpandToggle: this.onLineExpandToggle,
            onModalOpen: props.onModalOpen,
        });

        this.headerCellsStorage = new CellsStorage({
            makeCellParams: this.columnHeaderFactory.makeColumnHeaderParams,
        });

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateFixedWidthColumns();
    }

    public async componentDidMount() {
        this.updateLineIds();
        this.subscribeCreativeRequestItemsChanges(this.onCreativeRequestItemUpdate);
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        const budgetItemsChanged = this.props.budgetItems !== prevProps.budgetItems;
        const creativeRequestItemsChanged = this.props.creativeRequestItems !== prevProps.creativeRequestItems;
        const correctionsChanged =
            this.props.corrections !== prevProps.corrections ||
            this.props.groupedCorrections !== prevProps.groupedCorrections;

        if (budgetItemsChanged) {
            this.onBudgetItemsChanged();
        }

        if (correctionsChanged) {
            this.onCorrectionsChanged();
        }

        if (creativeRequestItemsChanged) {
            this.onCreativeRequestItemUpdate();
            this.subscribeCreativeRequestItemsChanges(this.onCreativeRequestItemUpdate);
        }
    }

    public render(): JSX.Element {
        const { budgetItemsTableLinesIds, totalTableLineIds: totalTableLineIds, isExpanded } = this.state;

        return React.createElement(TableTemplate, {
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            tableColumns: this.state.columns,
            fixedWidthColumns: this.fixedWidthColumns,
            budgetItemsTableLinesIds,
            totalTableLineIds,
            columnWidths: this.defaultColumnWidths,
            isExpanded,
            budgetItemsTableRef: this.budgetItemsTableRef,
            totalTableRef: this.totalTableRef,
            onCellEvent: this.onCellEvent,
            onExpandButtonClick: this.onExpandButtonClick,
            onColumnWidthsChange: this.onColumnWidthsChange,
        });
    }

    @autobind
    public async onLineUpdate(lineId: string) {
        await Promise.all([this.updateLineIds(), this.updateLineCells(lineId)]);
    }

    @autobind
    protected budgetItemsTableRef(component: TableView) {
        this.budgetItemsTable = component || null;

        if (this.budgetItemsTable) {
            this.budgetItemsTable.subscribeTableEvent(TableEvent.Scroll, (eventType: TableEvent) =>
                this.onTableEvent(eventType, TableType.BudgetItems),
            );
        }
    }

    @autobind
    protected totalTableRef(component: TableView) {
        this.totalTable = component || null;

        if (this.totalTable) {
            this.updateTotalTableColumnWidth();

            this.totalTable.subscribeTableEvent(TableEvent.Scroll, (eventType: TableEvent) =>
                this.onTableEvent(eventType, TableType.TotalTable),
            );
        }
    }

    @autobind
    protected onColumnWidthsChange(changedTableType: TableType, newColumnsWidth: ColumnWidths) {
        const updatedColumnsWidth = lodash.clone(newColumnsWidth);

        const tablesToUpdate = lodash.without([TableType.BudgetItems, TableType.TotalTable], changedTableType);

        lodash.forEach(this.state.columns, (columnsGroup, groupName: 'left' | 'center' | 'right') => {
            columnsGroup.forEach((columnName, columnIndex) => {
                tablesToUpdate.forEach((tableType) => {
                    const columnToUpdate = this.state.columns[groupName][columnIndex];

                    updatedColumnsWidth[columnToUpdate] = updatedColumnsWidth[columnName];
                });
            });
        });

        tablesToUpdate.forEach((tableType) => {
            const table = this.getTableByType(tableType);

            if (table) {
                table.setColumnWidths(updatedColumnsWidth);
            }
        });
    }

    @autobind
    protected onTableEvent(eventType: TableEvent, tableType: TableType) {
        switch (eventType) {
            case TableEvent.Scroll:
                const tablesToUpdate = lodash.without([TableType.BudgetItems, TableType.TotalTable], tableType);

                const scrollValue = this.getTableByType(tableType).getBodyScroll().x;

                tablesToUpdate.forEach((item) => {
                    const table = this.getTableByType(item);

                    if (!!table && table.getBodyScroll().x !== scrollValue) {
                        table.setHorizontalScroll(scrollValue);
                    }
                });

                break;

            case TableEvent.SizeChange:
                break;
        }
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.MouseSelection:
                await this.updateCell(position, true);
                break;

            case CellEvent.SelectionCancel:
                await this.updateCell(position, false);
                break;
        }
    }

    @autobind
    protected onBudgetItemsChanged() {
        this.updateLineIds();
        this.updateTableCells();
    }

    @autobind
    protected onCorrectionsChanged() {
        this.updateTableCells();
    }

    @autobind
    protected onCreativeRequestItemUpdate() {
        this.updateTableCells();
    }

    @autobind
    protected async onExpandButtonClick() {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    @autobind
    protected async onLineExpandToggle(lineId: LineId) {
        this.setState(
            {
                expandedLineIds: lodash.xor(this.state.expandedLineIds, [lineId]),
            },
            () => {
                this.updateLineIds();
                this.updateCell({ lineId, columnName: ColumnName.ExecutionId });

                const columns = this.makeColumns();

                this.setState({ columns });
            },
        );
    }

    private async updateHeaderCell(columnName: ColumnName) {
        const cellParams = await this.columnHeaderFactory.makeColumnHeaderParams(columnName);

        this.headerCellsStorage.setCellParams(columnName, cellParams);
    }

    private async updateCell(position: CellPosition, edit = false) {
        const cellParams = await this.cellsFactory.makeCellParams(position, edit);

        this.tableCellsStorage.setCellParams(position, cellParams);
    }

    private async updateLineCells(lineId: LineId) {
        if (lineId) {
            const tableType = await this.getTableTypeByLineId(lineId);
            const table = this.getTableByType(tableType);

            if (table) {
                const { left, center, right } = this.state.columns;

                const columnsToUpdate = [...left, ...center, ...right];

                columnsToUpdate.forEach(async (columnName) => {
                    const cellPosition = { lineId, columnName };

                    const cellEditStatus = table.getCellEditStatus(cellPosition);

                    this.updateCell(cellPosition, cellEditStatus);
                });
            }
        }
    }

    private updateTableCells() {
        const lineIds = [...this.state.budgetItemsTableLinesIds, ...this.state.totalTableLineIds];

        lineIds.forEach((lineId) => this.updateLineCells(lineId));
    }

    private updateDefaultColumnWidths() {
        const columnsConfig = this.getColumnsConfig();

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    private updateFixedWidthColumns() {
        const columnsConfig = this.getColumnsConfig();

        this.fixedWidthColumns = lodash
            .keys(columnsConfig)
            .filter((columnName) => columnsConfig[columnName].disableWidthChange) as ColumnName[];
    }

    private updateTotalTableColumnWidth() {
        if (this.totalTable) {
            const columnWidths = this.budgetItemsTable.getColumnWidths();

            this.totalTable.setColumnWidths(columnWidths);
        }
    }

    @autobind
    private updateLineIds() {
        const { budgetItems, corrections } = this.props;
        const { expandedLineIds } = this.state;

        let budgetItemsTableLinesIds: LineId[] = [];

        if (lodash.isEmpty(budgetItems)) {
            budgetItemsTableLinesIds = ['placeholderLine'];
        } else {
            budgetItems.forEach((budgetItem) => {
                budgetItemsTableLinesIds.push(budgetItem.id);

                const lineIsExpanded = expandedLineIds.includes(budgetItem.id);

                if (lineIsExpanded) {
                    const budgetItemCorrectionIds = corrections
                        .filter((item) => item.acceptorId === budgetItem.id)
                        .map((item) => item.id);

                    budgetItemsTableLinesIds.push(...budgetItemCorrectionIds);
                }
            });
        }

        if (!lodash.isEqual(budgetItemsTableLinesIds, this.state.budgetItemsTableLinesIds)) {
            this.setState({ budgetItemsTableLinesIds });
        }
    }

    @autobind
    private subscribeCreativeRequestItemsChanges(handler: () => void) {
        const { creativeRequestItems } = this.props;

        creativeRequestItems.forEach((item) => {
            item.events.onUpdated(handler);
        });
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    @autobind
    private getAccessorParamsData(): Partial<AccessorParams> {
        const { capex, project, creativeRequest, creativeRequestItems, corrections, groupedCorrections } = this.props;

        return {
            capex,
            project,
            creativeRequest,
            creativeRequestItems,
            corrections,
            groupedCorrections,
        };
    }

    @autobind
    private makeColumnsConfigParams(): ColumnsConfigParams {
        const displayCorrectionsColumns = !lodash.isEmpty(this.state?.expandedLineIds);

        return {
            displayCorrectionsColumns,
        };
    }

    @autobind
    private makeColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return {
            left: MakeLeftFixedColumns(columnsConfigParams) as ColumnName[],
            center: MakeTableColumns(columnsConfigParams) as ColumnName[],
            right: MakeRightFixedColumns(columnsConfigParams) as ColumnName[],
        };
    }

    @autobind
    private async getTableTypeByLineId(lineId: LineId): Promise<TableType> {
        const { budgetItemsTableLinesIds, totalTableLineIds: sumTableLineIds } = this.state;

        if (budgetItemsTableLinesIds.includes(lineId)) {
            return TableType.BudgetItems;
        }

        if (sumTableLineIds.includes(lineId)) {
            return TableType.TotalTable;
        }

        return null;
    }

    @autobind
    private getTableByType(tableType: TableType): TableView {
        const tableByType = {
            [TableType.BudgetItems]: this.budgetItemsTable,
            [TableType.TotalTable]: this.totalTable,
        };

        return tableByType[tableType];
    }

    @autobind
    private getLine(lineId: LineId): Line {
        return this.props.budgetItems.find((item) => item.id === lineId);
    }

    @autobind
    private getLines(): Line[] {
        return this.props.budgetItems;
    }

    @autobind
    private getExpandedLineIds(): LineId[] {
        return this.state.expandedLineIds;
    }
}
