import * as React from 'react';
import classNames from 'classnames';
import {
    Input_redesign as Input,
    InputTheme_redesign as InputTheme,
    WithTooltip,
    TooltipAnchor,
    TooltipThemes,
} from 'sber-marketing-ui';

import { CellValueType, ColumnName, CustomColumnColor } from '@store/budgetPlanning/types';

import { CellBackgroundColor } from '../../LayerManager';
import { getQaCellColor } from '../misc';

import * as style from './InputCell.scss';

interface Props {
    columnName: ColumnName;
    title: string;
    tooltip?: string;
    value: string;
    valueType: CellValueType;
    editMode: boolean;
    bgColor: CellBackgroundColor | CustomColumnColor;
    disabled: boolean;
    cellInfo?: string | JSX.Element;
    onRootClick: () => void;
    onInputBlur: () => void;
    onValueChange: (value: string) => void;
    onCellCopy: (event: React.ClipboardEvent<HTMLDivElement>) => void;
}

export const InputCell = ({
    columnName,
    title,
    tooltip,
    value,
    valueType,
    editMode,
    bgColor,
    disabled,
    cellInfo,
    onRootClick,
    onInputBlur,
    onValueChange,
    onCellCopy,
}: Props): JSX.Element => {
    const inputValueType =
        valueType == CellValueType.Number ? 'number' : valueType == CellValueType.Currency ? 'floatPositive' : null;

    return (
        <WithTooltip
            visibilityTrigger="static"
            className={style.wrapper}
            anchor={TooltipAnchor.TOP}
            theme={TooltipThemes.Red}
            content={cellInfo}
            hidden={!cellInfo || !editMode}
        >
            <div
                className={classNames(style.root, disabled && style.disabled)}
                onClick={onRootClick}
                style={{ backgroundColor: bgColor }}
                title={tooltip}
                {...{
                    'qa-id': 'tableCell',
                    'qa-index': columnName,
                    'qa-disabled': disabled ? 'true' : 'false',
                    ...getQaCellColor(bgColor),
                }}
                onCopy={onCellCopy}
            >
                {editMode && !disabled ? (
                    <div className={style.input}>
                        <Input
                            itemRef=""
                            qaId={'tableCellContent'}
                            value={value || ''}
                            placeholder={''}
                            valueType={inputValueType}
                            theme={InputTheme.BriefPage}
                            onInputChange={onValueChange}
                            onBlur={onInputBlur}
                            maxFracDigits={2}
                            autofocus
                        />
                    </div>
                ) : (
                    <div
                        className={style.title}
                        {...{
                            'qa-id': 'tableCellContent',
                        }}
                    >
                        {title || '–'}
                    </div>
                )}
            </div>
        </WithTooltip>
    );
};
