import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';

import type { Autopilot2, Autopilot2Placement } from 'sber-marketing-types/backend';

import { SelectCell, SelectCellEdit, TextCell, ActionsCell, SelectItem, CreativeCell } from './CellTypes';
import { store } from '@store';
import { setChangedCreativeStatuses } from '@store/autopilot/actions';

export const enum COLUMN_NAME {
    ChannelName = 'channelName',
    Format = 'format',
    ChannelId = 'channelId',
    StatusSelect = 'statusSelect',
    StatusText = 'statusText',
    CreativeType = 'creativeType',
    Creatives = 'creatives',
    Actions = 'actions',
}

export const enum ColumnType {
    Text = 'text',
    Select = 'select',
    Actions = 'actions',
    Creative = 'creative',
}

export interface AccessorParams {
    placement: Autopilot2Placement;
    autopilot: Autopilot2;
    changedCreativeStatuses: Record<string, string>;
    userHasAutopilotClientAccess: boolean;
    userHasAutopilotClientServiceAccess: boolean;
}

export interface ColumnParams {
    title: string;
    type: ColumnType;
    defaultWidth: number;
    readOnly?: boolean;
    getValue?: (params: AccessorParams) => any;
    setValue?: (params: AccessorParams, value: any) => Promise<void>;
    getItems?: (params: AccessorParams) => { title: React.ReactText; value: any }[];
}

export const CellComponentsByColumnType: Record<
    ColumnType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [ColumnType.Text]: {
        cell: TextCell,
    },
    [ColumnType.Select]: {
        cell: SelectCell,
        editCell: SelectCellEdit,
    },
    [ColumnType.Actions]: {
        cell: ActionsCell,
    },
    [ColumnType.Creative]: {
        cell: CreativeCell,
    },
};

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
    [COLUMN_NAME.ChannelName]: {
        title: 'Канал/Площадка',
        type: ColumnType.Text,
        defaultWidth: 200,
        readOnly: true,
        getValue: (params: AccessorParams) => params.placement.name,
    },
    [COLUMN_NAME.Format]: {
        title: 'Формат рекламного материала',
        type: ColumnType.Text,
        defaultWidth: 263,
        readOnly: true,
        getValue: (params: AccessorParams) => params.placement.format,
    },
    [COLUMN_NAME.CreativeType]: {
        title: 'Тип креатива',
        type: ColumnType.Text,
        defaultWidth: 263,
        getValue: (params: AccessorParams) => 'Тут будет что-то выбрано',
    },
    [COLUMN_NAME.Creatives]: {
        title: 'Креативы, тексты и ссылки',
        type: ColumnType.Creative,
        readOnly: true,
        defaultWidth: 263,
    },
    [COLUMN_NAME.StatusSelect]: {
        title: 'Статус',
        type: ColumnType.Select,
        defaultWidth: 395,
        getValue: (params: AccessorParams) => {
            const { placement, changedCreativeStatuses } = params;

            const changedCode = changedCreativeStatuses[placement.code];

            return changedCode || placement.creativeStatus;
        },
        setValue: async (params: AccessorParams, value: any) => {
            const dispatch = bindActionCreators(
                {
                    setChangedCreativeStatuses,
                },
                store.dispatch,
            );

            dispatch.setChangedCreativeStatuses({
                ...params.changedCreativeStatuses,
                [params.placement.code]: value,
            });
        },
        getItems: (params: AccessorParams) => {
            const { placement, userHasAutopilotClientServiceAccess } = params;

            return [
                {
                    title: 'Нет материалов',
                    value: 'NO_MATERIALS',
                    disabled: !userHasAutopilotClientServiceAccess || placement.creativeStatus === 'APPROVED',
                },
                {
                    title: 'На модерации',
                    value: 'MODERATION',
                    disabled: !userHasAutopilotClientServiceAccess || placement.creativeStatus === 'APPROVED',
                },
                {
                    title: 'Модерация пройдена',
                    value: 'MODERATION_SUCCESS',
                    disabled: !userHasAutopilotClientServiceAccess || placement.creativeStatus === 'APPROVED',
                },
                {
                    title: 'Не соответствует ТТ',
                    value: 'NO_MATCH_TT',
                    disabled: !userHasAutopilotClientServiceAccess || placement.creativeStatus === 'APPROVED',
                },
                {
                    title: 'Не согласовано Заказчиком',
                    value: 'NOT_APPROVED',
                    disabled: true,
                },
                {
                    title: 'Согласовано',
                    value: 'APPROVED',
                    disabled: true,
                },
            ] as SelectItem[];
        },
    },
    [COLUMN_NAME.StatusText]: {
        title: 'Статус',
        type: ColumnType.Text,
        defaultWidth: 480,
        readOnly: true,
        getValue: (params: AccessorParams) => {
            const { placement } = params;

            const titleByStatus = {
                ['NO_MATERIALS']: 'Нет материалов',
                ['MODERATION']: 'На модерации',
                ['MODERATION_SUCCESS']: 'Модерация пройдена',
                ['NO_MATCH_TT']: 'Не соответствует ТТ',
                ['NOT_APPROVED']: 'Не согласовано Заказчиком',
                ['APPROVED']: 'Согласовано',
            };

            return titleByStatus[placement.creativeStatus];
        },
    },
    [COLUMN_NAME.Actions]: {
        title: 'Действия',
        type: ColumnType.Actions,
        defaultWidth: 170,
        readOnly: true,
    },
};

const ReadOnlyColumnsConfig = lodash.cloneDeep(ColumnsConfig);
ReadOnlyColumnsConfig[COLUMN_NAME.Format].defaultWidth = 480;
lodash.forEach(ReadOnlyColumnsConfig, (item) => {
    item.readOnly = true;
});

export { ReadOnlyColumnsConfig };

export const tableColumns = [
    COLUMN_NAME.ChannelName,
    COLUMN_NAME.Format,
    COLUMN_NAME.Creatives,
    COLUMN_NAME.StatusSelect,
    COLUMN_NAME.Actions,
];

export const clientTableColumns = [
    COLUMN_NAME.ChannelName,
    COLUMN_NAME.Format,
    COLUMN_NAME.Creatives,
    COLUMN_NAME.StatusText,
    COLUMN_NAME.Actions,
];

export const readOnlyTableColumns = [COLUMN_NAME.ChannelName, COLUMN_NAME.Format, COLUMN_NAME.StatusText];

export const readOnlyColumns = lodash.keys(ColumnsConfig).filter((item) => ColumnsConfig[item].readOnly);
