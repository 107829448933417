import { HandlerMap } from './types';

export const constraintHandlerMap: HandlerMap = {
    charsMin: async (constraint, value) => {
        return value?.length < constraint ? 'Недопустимо малое количество символов' : null;
    },
    charsMax: async (constraint, value) => {
        return value?.length > constraint ? 'Недопустимо большое количество символов' : null;
    },
};
