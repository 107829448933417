import * as React from 'react';
import { Icon, IconType } from 'sber-marketing-ui';
import * as styles from './IncrementInput.scss';

interface Props {
    value?: string;
    onChange?: (value: string) => void;
}

export const IncrementInput = ({ value, onChange = () => {} }: Props): JSX.Element => {
    return (
        <form className={styles.root}>
            <Icon
                className={styles.arrow}
                type={IconType.ARROW16_RIGHT}
                onClick={() => onChange(String(value === '0' ? +value : +value - 1))}
            />
            <input
                className={styles.input}
                value={value}
                onChange={({ target }) => {
                    onChange(String(+target.value.replace('-', '')));
                }}
                type="number"
                min={0}
                max={100}
                step={1}
            />
            <Icon className={styles.arrow} type={IconType.ARROW16_RIGHT} onClick={() => onChange(String(+value + 1))} />
        </form>
    );
};
