import { useEffect, useState } from 'react';

import { BudgetItem, Month } from '@mrm/budget';
import { Itemtype } from '../CreateReserve';

import { CAPEX_ITEM_VALUE } from '../consts';

interface Props {
    budgetItem: BudgetItem;
    budgetItemsList: BudgetItem[];
    month?: null | Month;
}

export type EditMothItem = (params: { id: string; month: string; value: number }) => void;

export interface MonthItemType {
    id: string;
    month: string;
    monthName: string;
    itemType: Itemtype;
    planned: number;
    reserved: number;
    value: number;
}

interface Result {
    allMonthItemList: MonthItemType[];
    currentMonthItemList: MonthItemType[];
    editMothItem: EditMothItem;
}

export enum Months {
    apr = 'Апрель',
    aug = 'Август',
    dec = 'Декабрь',
    feb = 'Февраль',
    jan = 'Январь',
    jul = 'Июль',
    jun = 'Июнь',
    mar = 'Март',
    may = 'Май',
    nov = 'Ноябрь',
    oct = 'Октябрь',
    sept = 'Сентябрь',
}

export const useMonthItem = ({ budgetItem, budgetItemsList, month }: Props): Result => {
    const [allMonthItemList, setAllMonthItemList] = useState<MonthItemType[] | null>(null);
    const [currentMonthItemList, setCurrentMonthItemList] = useState<MonthItemType[]>(null);

    const editMothItem: EditMothItem = ({ id, month, value }) => {
        const index = allMonthItemList.findIndex((item) => item.id === id && item.month === month);
        const arr = [...allMonthItemList];
        const item = { ...allMonthItemList[index], value };
        arr[index] = item;
        setAllMonthItemList(arr);
    };

    useEffect(() => {
        const _allMonthItemList: MonthItemType[][] = [];

        budgetItemsList?.forEach((budgetItem) => {
            const mounths: MonthItemType[] = Object.entries(budgetItem.plannedFunds).map(([monthKey, monthValue]) => ({
                id: budgetItem.id,
                month: monthKey,
                monthName: Months[monthKey],
                itemType: budgetItem?.dictionary?.item?.value === CAPEX_ITEM_VALUE ? Itemtype.CAPEX : Itemtype.OPEX,
                planned: monthValue,
                reserved: budgetItem.reservedFunds[monthKey],
                value: 0,
            }));
            _allMonthItemList.push(mounths);
        });

        setAllMonthItemList(_allMonthItemList.flat());
    }, [budgetItemsList]);

    useEffect(() => {
        const _currentMonthItemList: MonthItemType[] = allMonthItemList?.filter(
            (item) => item.id === budgetItem.id && item.month === month,
        );
        setCurrentMonthItemList(_currentMonthItemList);
    }, [budgetItem, allMonthItemList, month]);

    return {
        allMonthItemList,
        currentMonthItemList,
        editMothItem,
    };
};
