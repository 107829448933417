import * as React from 'react';

const AccordionContext = React.createContext({
    isExpended: false,
    setExpansion: (isExpended: boolean) => {},
});

const useAccordionContext = () => {
    const { isExpended, setExpansion } = React.useContext(AccordionContext);

    return {
        isExpended,
        setExpansion,
    };
};

export { AccordionContext, useAccordionContext };
