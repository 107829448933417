// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-modals-CreateReserve-Amount-_Amount__root{margin-top:20px;padding:8px;background-color:#F1F5F7;color:#7E8681;font-family:\"Open Sans\";font-weight:400;font-size:12px;line-height:16px;min-width:252px;border-radius:3px}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-modals-CreateReserve-Amount-_Amount__bold{font-weight:600;color:#000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/ExecitionIdWidget/modals/CreateReserve/Amount/Amount.scss"],"names":[],"mappings":"AAAA,iIACI,eAAgB,CAChB,WAAY,CACZ,wBAAyB,CAEzB,aAAc,CACd,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,eAAgB,CAChB,iBAAkB,CACrB,iIAGG,eAAgB,CAChB,UAAW","sourcesContent":[".root {\n    margin-top: 20px;\n    padding: 8px;\n    background-color: #F1F5F7;\n\n    color: #7E8681;\n    font-family: \"Open Sans\";\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    min-width: 252px;\n    border-radius: 3px;\n}\n\n.bold {\n    font-weight: 600;\n    color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-modals-CreateReserve-Amount-_Amount__root",
	"bold": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-modals-CreateReserve-Amount-_Amount__bold"
};
export default ___CSS_LOADER_EXPORT___;
