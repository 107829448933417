import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';
import * as lodash from 'lodash';
import { Link } from 'react-router-dom';

import * as style from './Header.scss';

import type { Brief as BriefParams, UserResponseParams as User } from 'sber-marketing-types/frontend';
import type { Activity, Task } from '@store/brief/types';
import type { BudgetItem } from '@mrm/budget';

import { Icon, IconType, Preloader } from 'sber-marketing-ui';
import { ActivityDates } from './ActivityDates';
import { TaskDates } from './TaskDates';
import { SchemeSelectionForm } from './SchemeSelectionForm';
import { SubInfo, SubInfoIconWrapper } from './SubInfo';

interface Props {
    brief: BriefParams;
    task: Task;
    activity: Activity;
    users: User[];
    budgetItem: BudgetItem;
    editRight: boolean;
    isLoading: boolean;
    isBriefCopyDisabled: boolean;
    isBriefPasteDisabled: boolean;
    isBriefExportDisabled: boolean;
    isBriefReady: boolean;
    onBriefCopy: () => void;
    onBriefPaste: () => void;
    onBriefExport: () => void;
    onSchemeSelection: (schemeId: string) => void;
}

export const Header: React.FC<Props> = ({
    brief,
    task,
    activity,
    users,
    budgetItem,
    editRight,
    isLoading,
    isBriefCopyDisabled,
    isBriefPasteDisabled,
    isBriefExportDisabled,
    isBriefReady,
    onBriefCopy,
    onBriefPaste,
    onBriefExport,
    onSchemeSelection,
}) => {
    const briefPasteDisabled = isBriefPasteDisabled || !editRight;

    return (
        <div
            className={classNames(style.root, `section ${task ? task.id : activity ? activity.id : budgetItem.id}`)}
            data-section-id={`${task ? task.id : activity ? activity.id : budgetItem.id}`}
            {...{
                'qa-id': 'briefHeader',
            }}
        >
            <div className={style.header}>
                <div className={style.title}>{task ? `Бриф задачи` : activity ? 'Бриф проекта' : 'Бриф строки'}:</div>
                <div className={style.buttons}>
                    <div
                        className={classNames(style.button, isBriefCopyDisabled ? style.buttonDisabled : '')}
                        onClick={isBriefCopyDisabled ? null : onBriefCopy}
                        {...{
                            'qa-id': 'briefHeaderCopyButton',
                            'qa-is-disabled': isBriefCopyDisabled,
                        }}
                    >
                        <Icon type={IconType.COPY_BOLD} svgSize={14} />
                        Скопировать
                    </div>
                    <div
                        className={classNames(style.button, briefPasteDisabled && style.buttonDisabled)}
                        onClick={briefPasteDisabled ? null : onBriefPaste}
                        {...{
                            'qa-id': 'briefHeaderPasteButton',
                            'qa-is-disabled': briefPasteDisabled,
                        }}
                    >
                        <Icon type={IconType.PASTE} svgSize={14} />
                        Вставить
                    </div>
                    <div
                        className={classNames(style.button, isBriefExportDisabled ? style.buttonDisabled : '')}
                        onClick={isBriefExportDisabled ? null : onBriefExport}
                        {...{
                            'qa-id': 'briefHeaderExportButton',
                            'qa-is-disabled': isBriefExportDisabled,
                        }}
                    >
                        Выгрузить
                    </div>
                </div>
                {/*tslint:disable-next-line:max-line-length */}
                <div className={style.name}>{task ? task.title : activity ? activity.name : budgetItem.sapComment}</div>
                {/*tslint:disable-next-line:max-line-length */}
                {/*<div className={style.info} title={'Копирует этот бриф (даже если данная схема брифа недоступна Вам при созаднии) и все данные из заполненных полей. Затем можно переиспользовать, вставив в бриф другого проекта или задачи.'}>*/}
                {/*    <Icon type={IconType.INFO_CIRCLE} svgSize={13}/>*/}
                {/*</div>*/}
            </div>

            {activity && task && <Info activity={activity} task={task} brief={brief} users={users} />}

            <SchemeSelectionForm
                briefId={brief.id}
                isBriefReady={isBriefReady}
                editRight={editRight}
                onSchemeSelection={onSchemeSelection}
            />

            {task ? <TaskDates taskId={task.id} briefId={brief.id} /> : activity ? <ActivityDates /> : null}

            {isLoading && <Preloader />}
        </div>
    );
};

interface ActivityInfoProps {
    brief: BriefParams;
    activity: Activity;
    task: Task;
    users: User[];
}

// tslint:disable-next-line:cyclomatic-complexity
const Info: React.FC<ActivityInfoProps> = ({ brief, activity, task, users }) => {
    const usersMapById = lodash.keyBy(users, 'id');

    {
        /* tslint:disable-next-line:max-line-length */
    }
    // TODO brief.updateTime сожердит время, когда была прменена схема к брифу.
    const formattedUpdateTime = formatDate(moment(brief.updateTime));
    const haveBrief = !lodash.isNil(brief.schemeId);

    const executorTaskName =
        !lodash.isNil(task && task.executorId) && !lodash.isNil(usersMapById[task.executorId])
            ? `${usersMapById[task.executorId].firstName} ${usersMapById[task.executorId].secondName}`
            : '';

    const responsibleActivityName =
        !task && !lodash.isNil(activity.responsible)
            ? `${activity.responsible.firstName} ${activity.responsible.secondName}`
            : '';

    const authorName = task
        ? (!lodash.isNil(task.authorId) &&
              !lodash.isNil(usersMapById[task.authorId]) &&
              `${usersMapById[task.authorId].firstName} ${usersMapById[task.authorId].secondName}`) ||
          ''
        : (!lodash.isNil(activity.authorId) &&
              !lodash.isNil(usersMapById[activity.authorId]) &&
              `${usersMapById[activity.authorId].firstName} ${usersMapById[activity.authorId].secondName}`) ||
          '';

    return (
        <div className={style.activityInfo}>
            <div className={style.subInfoList}>
                <SubInfo tooltip={`Проект: ${activity.name}`} qaId="briefInfoActivityName" qaValue={activity.name}>
                    Проект:&nbsp;
                    <Link className={style.link} to={`/activity/${activity.id}`}>
                        {activity.name}
                    </Link>
                </SubInfo>

                {task && (
                    <SubInfo tooltip={`Задача: ${task.title}`} qaId="briefInfoTaskTitle" qaValue={task.title}>
                        Задача:&nbsp;
                        <Link className={style.link} to={`/activity/${activity.id}/task/${task.id}`}>
                            {task.title}
                        </Link>
                    </SubInfo>
                )}

                {authorName && (
                    <SubInfo tooltip={`Автор: ${authorName}`} qaId="briefInfoAuthor" qaValue={authorName}>
                        Автор:&nbsp;<span className={style.accent}>{authorName}</span>
                    </SubInfo>
                )}

                {responsibleActivityName && (
                    <SubInfo
                        tooltip={`Ответственный: ${responsibleActivityName}`}
                        qaId="briefInfoResponsible"
                        qaValue={responsibleActivityName}
                    >
                        Ответственный:&nbsp;<span className={style.accent}>{responsibleActivityName}</span>
                    </SubInfo>
                )}

                {executorTaskName && (
                    <SubInfo
                        tooltip={`Исполнитель: ${executorTaskName}`}
                        qaId="briefInfoExecutor"
                        qaValue={executorTaskName}
                    >
                        Исполнитель:&nbsp;<span className={style.accent}>{executorTaskName}</span>
                    </SubInfo>
                )}
            </div>

            <div className={style.subInfoList}>
                {haveBrief && (
                    <SubInfo
                        tooltip={`Бриф создан: ${formattedUpdateTime}`}
                        qaId="briefInfoUpdateTime"
                        qaValue={formattedUpdateTime}
                    >
                        <SubInfoIconWrapper>
                            <Icon type={IconType.CALENDAR_ICON} svgSize={16} />
                        </SubInfoIconWrapper>
                        Бриф создан:&nbsp;<span className={style.accent}>{formattedUpdateTime}</span>
                    </SubInfo>
                )}
            </div>
        </div>
    );
};

export const formatDate = (date: moment.Moment) => {
    return `${formatDay(date)} ${formatMonth(date)} ${formatYear(date)}, ${formatTime(date)}`;
};

const formatDay = (date: moment.Moment) => {
    const dayFormat = 'D';
    return `${date.format(dayFormat)}`;
};

const formatMonth = (date: moment.Moment) => {
    const monthFormat = 'MMM';
    return date.format(monthFormat);
};

const formatYear = (date: moment.Moment) => {
    const format = 'YYYY';
    return `${date.format(format)}`;
};

const formatTime = (date: moment.Moment) => {
    const format = 'HH:mm';
    return `${date.format(format)}`;
};
