// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StagesListWidget-Statuses-_Statuses__root{margin-top:12px;padding:8px 16px;height:108px;user-select:none;border-radius:6px;background:#ffffff;box-shadow:0 2px 6px rgba(0,0,0,0.1);display:grid;grid-template-columns:1fr 2px repeat(3, 1fr);grid-template-rows:100%;grid-column-gap:6px;grid-row-gap:0;overflow:hidden}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-_Statuses__divider{display:flex;align-items:center;width:100%}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-_Statuses__divider::after{content:\"\";width:2px;height:74%;background-color:#E6EDF1;border-radius:1px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StagesListWidget/Statuses/Statuses.scss"],"names":[],"mappings":"AAAA,sFACI,eAAgB,CAChB,gBAAiB,CACjB,YAAa,CACb,gBAAiB,CACjB,iBAAkB,CAClB,kBAAmB,CACnB,oCAAwC,CAExC,YAAa,CACb,4CAA6C,CAC7C,uBAAwB,CACxB,mBAAoB,CACpB,cAAe,CACf,eAAgB,CACnB,yFAGG,YAAa,CACb,kBAAmB,CACnB,UAAW,CAHf,gGAKQ,UAAW,CACX,SAAU,CACV,UAAW,CACX,wBAAyB,CACzB,iBAAkB","sourcesContent":[".root {\n    margin-top: 12px;\n    padding: 8px 16px;\n    height: 108px;\n    user-select: none;\n    border-radius: 6px;\n    background: #ffffff;\n    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);\n\n    display: grid;\n    grid-template-columns: 1fr 2px repeat(3, 1fr);\n    grid-template-rows: 100%;\n    grid-column-gap: 6px;\n    grid-row-gap: 0;\n    overflow: hidden;\n}\n\n.divider {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    &::after {\n        content: \"\";\n        width: 2px;\n        height: 74%;//80px;\n        background-color: #E6EDF1;\n        border-radius: 1px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-_Statuses__root",
	"divider": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-_Statuses__divider"
};
export default ___CSS_LOADER_EXPORT___;
