import { Month, BudgetItem } from '@mrm/budget';

export interface ImportFMPTableMenuState {
    componentState: ComponentState;
    dataFetchingInProgress: boolean;
    dataUploadingInProgress: boolean;
    errorMessage: string;
    workbookDescriptor: WorkbookDescriptor;
    fetchedData: FetchedData[];
    budgetItems: BudgetItemsStore;
    targetMonth: Month;
}

export const enum ComponentState {
    Hidden = 'Hidden',
    FileSelection = 'FileSelection',
    ReadyToLoad = 'ReadyToLoad',
}

export const enum ColumnNames {
    SerialNumber = 'ID',
    FactValue = 'FactValue',
    ZNS = 'ZNS',
}

// XLSX-to-MRM and MRM-to-XLSX columns maps
export const ColumnNamesToExcelColumnsMap: Record<ColumnNames, string> = {
    [ColumnNames.SerialNumber]: 'ID',
    [ColumnNames.FactValue]: `Сумма по приложению без НДС`,
    [ColumnNames.ZNS]: 'ЗНС',
};

export const ExcelColumnsToColumnNamesMap: Record<string, ColumnNames> = {
    [ColumnNamesToExcelColumnsMap[ColumnNames.SerialNumber]]: ColumnNames.SerialNumber,
    [ColumnNamesToExcelColumnsMap[ColumnNames.FactValue]]: ColumnNames.FactValue,
    [ColumnNamesToExcelColumnsMap[ColumnNames.ZNS]]: ColumnNames.ZNS,
};

export interface WorkbookDescriptor {
    file: File;
    columns: Record<ColumnNames, string>;
    dataStart: number;
    dataCount: number;
}

export interface FetchedData {
    [ColumnNames.SerialNumber]: number;
    [ColumnNames.FactValue]: number;
    [ColumnNames.ZNS]: string;
}

export interface LoadFMPTablePayload {
    budgetId: string;
    file: File;
}
export type LoadFMPTableResult = Pick<ImportFMPTableMenuState, 'workbookDescriptor' | 'fetchedData' | 'budgetItems'>;

export interface BudgetItemsStore {
    entities: BudgetItem[];
    byId: Record<string, BudgetItem>;
}
