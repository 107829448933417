import * as lodash from 'lodash';

import { useUrl, useProject, useProjectBudgetItems, useBudgetItems } from '../../hooks';

export function useBudgetUrl() {
    const { projectId } = useUrl();
    const { project } = useProject(projectId);
    const { budgetItems: projectBudgetItems } = useProjectBudgetItems(project);
    const { budgetItems } = useBudgetItems(projectBudgetItems);

    async function openBudgetUrl() {
        const url = makeUrl();

        openUrlInNewTab(url);
    }

    function makeUrl() {
        const serialNumbers = budgetItems.map((item) => item.serialNumber);
        const budgetId = lodash.first(budgetItems).budgetId;

        return `/budget/execution?filters=serialNumber:${serialNumbers}&budgetId=${budgetId}&preventConfigUpdate`;
    }

    function openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }

    return {
        openBudgetUrl,
    };
}
