import * as React from 'react';
import * as lodash from 'lodash';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { uniqBy } from 'lodash';
import { PlainDictionary } from '@mrm/dictionary';

import {
    LabeledSelect_redesign as LabeledSelect,
    LabeledTextarea_redesign as LabeledTextarea,
    WithTooltip,
    TooltipAnchor,
    StaticSkeleton,
} from 'sber-marketing-ui';
import { BudgetItemApi, DictionaryApi } from '@api';
import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import {
    getTaskEditorState,
    setBudgetDivision,
    setBudgetCustomer,
    setBudgetItemIds,
    setBudgetProduct,
    setBudgetSegment,
    setBudgetPeriod,
    setBudgetIsMedia,
    setBudgetMediaRequest,
    setBudgetIsNaming,
    setBudgetNaming,
    setBudgetProject,
    NO_BUDGET_ITEM_MARKER,
} from '@store/taskEditor2';
import { DictionaryType, DictionaryStatus } from '@mrm/dictionary';

import { BudgetsFetcher, BudgetsData } from './BudgetsFetcher';
import { RadiobuttonGroup } from './RadiobuttonGroup';

import * as styles from './BudgetEditor.scss';

const CURR_YEAR = new Date().getFullYear();

function useInteractivity() {
    const dispatch = useDispatch();
    const user = useSelector((state: StoreState) => getLoginUser(state));
    const values = useSelector((state: StoreState) => getTaskEditorState(state).values);
    const currentExecutionnBudget = useSelector(
        (state: StoreState) => getTaskEditorState(state).misc.currentExecutionBudget,
    );

    function saveBudgetDivision(division: string) {
        dispatch(setBudgetDivision(division));
    }

    function saveBudgetCustomer(customer: string) {
        dispatch(setBudgetCustomer(customer));
    }

    function saveBudgetItemIds(ids: string[]) {
        dispatch(setBudgetItemIds(ids));
    }

    function saveBudgetProduct(product: string) {
        dispatch(setBudgetProduct(product));
    }

    function saveBudgetSegment(segment: string) {
        dispatch(setBudgetSegment(segment));
    }

    function saveBudgetPeriod(segment: string) {
        dispatch(setBudgetPeriod(segment));
    }

    function saveBudgetIsMedia(isMedia: boolean) {
        dispatch(setBudgetIsMedia(isMedia));
        dispatch(setBudgetMediaRequest(null));
        dispatch(setBudgetIsNaming(null));
        dispatch(setBudgetNaming(null));
        dispatch(setBudgetProject(null));
    }

    function saveBudgetMediaRequest(request: string) {
        dispatch(setBudgetMediaRequest(request));
    }

    function saveBudgetIsNaming(isNaming: boolean) {
        dispatch(setBudgetIsNaming(isNaming));
        dispatch(setBudgetNaming(null));
        dispatch(setBudgetProject(null));
    }

    function saveBudgetNaming(naming: string) {
        dispatch(setBudgetNaming(naming));
    }

    function saveBudgetProject(project: string) {
        dispatch(setBudgetProject(project));
    }

    return {
        budgetApproval: values?.budgetApproval,
        saveBudgetDivision,
        saveBudgetCustomer,
        saveBudgetItemIds,
        saveBudgetProduct,
        saveBudgetSegment,
        saveBudgetPeriod,
        saveBudgetIsMedia,
        saveBudgetMediaRequest,
        saveBudgetIsNaming,
        saveBudgetNaming,
        saveBudgetProject,
        organizationId: user.attributes.organizationId,
        userId: user.attributes.id,
        currentExecutionnBudget,
    };
}

interface Props {
    workType: string;
    disabled: boolean;
    showRequiredMarker: boolean;
    saveWorkType: (workType: string) => void;
}

function mapDictionaryToItem(dictionaries: PlainDictionary[]): { value: string; label: string }[] {
    return lodash
        .sortBy(dictionaries, (dictionary) => dictionary.value)
        .map((dictionary) => ({
            value: dictionary.id,
            label: dictionary.value,
        }));
}

const NO_BUDGET_ITEM_DROPDOWN_ITEM = {
    value: NO_BUDGET_ITEM_MARKER,
    label: 'Нет ID источника',
    order: 0,
};

export function BudgetEditor({ disabled }: Props): JSX.Element {
    const editorProps = useInteractivity();

    const [budgetId, setBudgetId] = React.useState(null);
    const [divisions, setDivisions] = React.useState([]);
    const [existingBudgetItems, setExistingBudgetItems] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [segments, setSegment] = React.useState([]);

    React.useEffect(() => {
        async function worker() {
            const dictionaries = lodash.groupBy(
                (
                    await DictionaryApi.getDictionariesForBudget({
                        userId: editorProps.userId,
                        types: [DictionaryType.Division, DictionaryType.Product, DictionaryType.Segment],
                        organizationId: editorProps.currentExecutionnBudget?.dictionaryOrganizationId,
                        budgetId: editorProps.currentExecutionnBudget?.id,
                    })
                ).filter((dictionary) => dictionary.status !== DictionaryStatus.DELETED),
                (dictionary) => dictionary.type,
            );

            setDivisions(mapDictionaryToItem(dictionaries[DictionaryType.Division]));
            setProducts(mapDictionaryToItem(dictionaries[DictionaryType.Product]));
            setSegment(mapDictionaryToItem(dictionaries[DictionaryType.Segment]));
        }

        worker();
    }, [editorProps.organizationId, editorProps.currentExecutionnBudget]);

    React.useEffect(() => {
        if (budgetId && !existingBudgetItems.length) {
            BudgetItemApi.getBudgetItemList({
                budgetId,
                sort: [{ field: 'serialNumber', order: 'ASC' }],
            }).then((budgetItems) => {
                setExistingBudgetItems(
                    uniqBy(
                        budgetItems.map((item, i) => ({
                            value: item.id,
                            label: `${item.serialNumber} (${item.activity.name})`,
                            order: i + 1,
                        })),
                        (budget) => budget.value,
                    ),
                );
            });
        }
    }, [budgetId]);

    const setBudgetsData = (budgetsData: BudgetsData) => {
        if (budgetsData?.budgets?.length) {
            const budget = budgetsData.budgets.find((item) => CURR_YEAR === item.year);

            if (budget) {
                setBudgetId(budget.id);
            }
        }
    };

    let budgetItemsToUse;
    if (!editorProps.budgetApproval?.budgetItemIds?.length) {
        budgetItemsToUse = [NO_BUDGET_ITEM_DROPDOWN_ITEM, ...existingBudgetItems];
    } else if (editorProps.budgetApproval?.budgetItemIds?.includes(NO_BUDGET_ITEM_MARKER)) {
        budgetItemsToUse = [NO_BUDGET_ITEM_DROPDOWN_ITEM];
    } else {
        budgetItemsToUse = existingBudgetItems;
    }

    return (
        <div className={classnames(styles.root, disabled && styles.disabled)}>
            <BudgetsFetcher forwardData={setBudgetsData} organizationIds={[editorProps.organizationId]} />

            <div className={classnames(styles.wrapper)}>
                <WithTooltip
                    className={styles.tooltip}
                    anchor={TooltipAnchor.TOP}
                    content={'Если нужного значения не найдено, обратитесь в поддержку'}
                >
                    <div className={classnames(styles.label)}>
                        Подразделение-заказчик (МВЗ)
                        <span>&nbsp;*</span>
                    </div>
                </WithTooltip>
                <div className={classnames(styles.selectWrapper)}>
                    <LabeledSelect
                        qaId="taskEditorBudgetDivisionDropdown"
                        title=""
                        items={divisions}
                        selectedValue={editorProps.budgetApproval?.clientDivisionId}
                        onSelection={editorProps.saveBudgetDivision}
                        hideDisabledOptions
                        scrollMenuIntoView={false}
                        disabled={disabled || !divisions?.length}
                    />
                </div>
            </div>
            <WithTooltip
                className={styles.tooltip}
                anchor={TooltipAnchor.TOP}
                content={'Укажите ФИО представителя заказчика'}
            >
                <div className={classnames(styles.wrapper)}>
                    <div className={classnames(styles.label)}>
                        Представитель заказчика от бизнеса (ФИО)
                        <span>&nbsp;*</span>
                    </div>
                    <div className={classnames(styles.inputWrapper)}>
                        <LabeledTextarea
                            qaId="taskEditorTitleInput"
                            value={editorProps.budgetApproval?.clientName}
                            onValueChange={editorProps.saveBudgetCustomer}
                            title=""
                            disabled={disabled}
                        />
                    </div>
                </div>
            </WithTooltip>
            {existingBudgetItems?.length ? (
                <div className={classnames(styles.wrapper)}>
                    <WithTooltip
                        className={styles.tooltip}
                        anchor={TooltipAnchor.TOP}
                        content={'Укажите Id-источник связанного МВЗ '}
                    >
                        <div className={classnames(styles.label)}>
                            ID-источник из ID Исполнения
                            <span>&nbsp;*</span>
                        </div>
                    </WithTooltip>
                    <div className={classnames(styles.selectWrapper)}>
                        <LabeledSelect
                            showNullItem
                            allowMultipleItems
                            qaId="taskEditorBudgetDropdown"
                            title=""
                            items={budgetItemsToUse}
                            selectedValue={editorProps.budgetApproval?.budgetItemIds}
                            onSelection={editorProps.saveBudgetItemIds}
                            hideDisabledOptions
                            scrollMenuIntoView={false}
                            disabled={disabled || !budgetItemsToUse?.length}
                        />
                    </div>
                </div>
            ) : (
                <FieldPreloader />
            )}
            <div className={classnames(styles.wrapper)}>
                <div className={classnames(styles.label)}>
                    Продукт
                    <span>&nbsp;*</span>
                </div>
                <div className={classnames(styles.selectWrapper)}>
                    <LabeledSelect
                        qaId="taskEditorBudgetDropdown"
                        title=""
                        items={products}
                        selectedValue={editorProps.budgetApproval?.productId}
                        onSelection={editorProps.saveBudgetProduct}
                        hideDisabledOptions
                        scrollMenuIntoView={false}
                        disabled={disabled || !products?.length}
                    />
                </div>
            </div>
            <div className={classnames(styles.wrapper)}>
                <div className={classnames(styles.label)}>
                    Сегмент
                    <span>&nbsp;*</span>
                </div>
                <div className={classnames(styles.selectWrapper)}>
                    <LabeledSelect
                        qaId="taskEditorBudgetDropdown"
                        title=""
                        items={segments}
                        selectedValue={editorProps.budgetApproval?.segmentId}
                        onSelection={editorProps.saveBudgetSegment}
                        hideDisabledOptions
                        scrollMenuIntoView={false}
                        disabled={disabled || !segments?.length}
                    />
                </div>
            </div>
            <WithTooltip
                className={styles.tooltip}
                anchor={TooltipAnchor.TOP}
                content={'Укажите месяцы проведения РК. Пример ввода «март-май»'}
            >
                <div className={classnames(styles.wrapper)}>
                    <div className={classnames(styles.label)}>
                        Период РК (месяцы)
                        <span>&nbsp;*</span>
                    </div>
                    <div className={classnames(styles.inputWrapper)}>
                        <LabeledTextarea
                            qaId="taskEditorBudgetDescriptionInput"
                            title=""
                            value={editorProps.budgetApproval?.period}
                            onValueChange={editorProps.saveBudgetPeriod}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </WithTooltip>
            <div className={classnames(styles.wrapper)}>
                <div className={classnames(styles.label)}>
                    Компания относится к направлению Медиа/Диджитал
                    <span>&nbsp;*</span>
                </div>
                <RadiobuttonGroup
                    items={[
                        {
                            title: 'Да',
                            value: true,
                        },
                        {
                            title: 'Нет',
                            value: false,
                        },
                    ]}
                    selectedValue={editorProps.budgetApproval?.hasLot}
                    onSelection={editorProps.saveBudgetIsMedia}
                    disabled={disabled}
                />
            </div>
            {editorProps.budgetApproval?.hasLot && (
                <React.Fragment>
                    <div className={classnames(styles.wrapper)}>
                        <WithTooltip className={styles.tooltip} anchor={TooltipAnchor.TOP} content={'Выберите ЛОТ'}>
                            <div className={classnames(styles.label)}>
                                Если креатив по ЛОТу, то выберите ЛОТ
                                <span>&nbsp;*</span>
                            </div>
                        </WithTooltip>
                        <div className={classnames(styles.selectWrapper)}>
                            <LabeledSelect
                                qaId="taskEditorBudgetDropdown"
                                title=""
                                items={[
                                    {
                                        label: 'Да ЛОТ 1',
                                        value: '1',
                                    },
                                    {
                                        label: 'Да ЛОТ 2',
                                        value: '2',
                                    },
                                    {
                                        label: 'Нет креатива по ЛОТам',
                                        value: 'not',
                                    },
                                ]}
                                selectedValue={editorProps.budgetApproval?.mediaRequest}
                                onSelection={editorProps.saveBudgetMediaRequest}
                                hideDisabledOptions
                                scrollMenuIntoView={false}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className={classnames(styles.wrapper)}>
                        <div className={classnames(styles.label)}>
                            Есть ли у креатива согласованный в заявке/протоколе нейминг?
                            <span>&nbsp;*</span>
                        </div>
                        <RadiobuttonGroup
                            items={[
                                {
                                    title: 'Да',
                                    value: true,
                                },
                                {
                                    title: 'Нет',
                                    value: false,
                                },
                            ]}
                            selectedValue={editorProps.budgetApproval?.hasTitle}
                            onSelection={editorProps.saveBudgetIsNaming}
                            disabled={disabled}
                        />
                    </div>
                    {editorProps.budgetApproval?.hasTitle && (
                        <React.Fragment>
                            <div className={classnames(styles.wrapper)}>
                                <div className={classnames(styles.label)}>
                                    Укажите неймининг
                                    <span>&nbsp;*</span>
                                </div>
                                <div className={classnames(styles.inputWrapper)}>
                                    <LabeledTextarea
                                        qaId="taskEditorBudgetDescriptionInput"
                                        title=""
                                        value={editorProps.budgetApproval?.naming}
                                        onValueChange={editorProps.saveBudgetNaming}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className={classnames(styles.wrapper)}>
                                <WithTooltip
                                    className={styles.tooltip}
                                    anchor={TooltipAnchor.TOP}
                                    content={'Выберите на основании чего проводится РК? '}
                                >
                                    <div className={classnames(styles.label)}>
                                        РК на основании
                                        <span>&nbsp;*</span>
                                    </div>
                                </WithTooltip>
                                <div className={classnames(styles.selectWrapper)}>
                                    <LabeledSelect
                                        qaId="taskEditorBudgetDropdown"
                                        title=""
                                        items={[
                                            {
                                                label: 'КРБ',
                                                value: 'КРБ',
                                            },
                                            {
                                                label: 'ККБ',
                                                value: 'ККБ',
                                            },
                                            {
                                                label: 'Распоряжение',
                                                value: 'Распоряжение',
                                            },
                                        ]}
                                        selectedValue={editorProps.budgetApproval?.project}
                                        onSelection={editorProps.saveBudgetProject}
                                        hideDisabledOptions
                                        scrollMenuIntoView={false}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

function FieldPreloader(): JSX.Element {
    return (
        <div>
            <StaticSkeleton className={styles.itemPreloader1} />
            <StaticSkeleton className={styles.itemPreloader2} />
        </div>
    );
}
