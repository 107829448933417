import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import * as style from './Item.scss';

const STATUS_PARAMS_BY_STAGE = {
    draft: {
        title: 'Создание',
        className: style.gray,
    },
    fillingByCustomer: {
        title: 'Заполнение',
        className: style.orange,
    },
    fillingByAgency: {
        title: 'Заполнение',
        className: style.orange,
    },
    checkingByCustomer: {
        title: 'Проверка',
        className: style.gray,
    },
    approvement: {
        title: 'Согласование',
        className: style.green,
    },
    actClosing: {
        title: 'Согласование',
        className: style.green,
    },
    closed: {
        title: 'Закрыта',
        className: style.blue,
    },
};

export interface CreativeRequestItem {
    id: number;
    name: string;
    date: string;
    stage: number;
}

interface Props {
    item: CreativeRequestItem;
    activityId: number;
    onLinkClick: () => void;
}

export const Item: React.FC<Props> = ({ item, activityId, onLinkClick }) => {
    return (
        <Link onClick={onLinkClick} to={`/activity/${activityId}/creative/${item.id}`} className={style.root}>
            <div className={classNames(style.status, STATUS_PARAMS_BY_STAGE[item.stage].className)}>
                {STATUS_PARAMS_BY_STAGE[item.stage].title}
            </div>

            <div className={style.title}>{item.name}</div>

            <div className={style.date}>{item.date}</div>
        </Link>
    );
};
