import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getContextTableData } from '@store/autopilot/selectors';

interface UseStore {
    (): UseStoreReturn;
}

interface UseStoreReturn {
    contextTableData: string[][];
}

export const useStore: UseStore = () => {
    const contextTableData = useSelector((state: StoreState) => getContextTableData(state));

    return {
        contextTableData,
    };
};
