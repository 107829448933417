import autobind from 'autobind-decorator';

import type { TableHeaderCellParams, ColumnName } from '../../types';
import { ColumnHeaderType, ColumnParams } from '../../ColumnsConfig';

import * as HeaderCells from '../../ColumnHeaderTypes';

export const ColumnHeaderComponentsByType: Record<ColumnHeaderType, React.ClassType<any, any, any>> = {
    [ColumnHeaderType.Text]: HeaderCells.TextColumnHeader,
};

interface Props {
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
}

export class ColumnHeaderFactory {
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };

    public constructor(props: Props) {
        this.getColumnsConfig = props.getColumnsConfig;
    }

    @autobind
    public async makeColumnHeaderParams(columnName: ColumnName): Promise<TableHeaderCellParams> {
        return {
            component: this.getColumnHeaderComponent(columnName),
            cellProps: await this.makeColumnHeaderProps(columnName),
        };
    }

    private getColumnHeaderComponent(columnName: ColumnName): React.ClassType<any, any, any> {
        const columnType = this.getColumnsConfig()[columnName].headerType;

        return ColumnHeaderComponentsByType[columnType];
    }

    private async makeColumnHeaderProps(columnName: ColumnName): Promise<any> {
        const headerType = this.getColumnsConfig()[columnName].headerType;

        let cellProps: any;

        switch (headerType) {
            case ColumnHeaderType.Text:
                cellProps = await this.makeTextColumnHeaderProps(columnName as ColumnName);
                break;
        }

        return cellProps;
    }

    private async makeTextColumnHeaderProps(columnName: ColumnName): Promise<any> {
        return {
            title: this.getColumnsConfig()[columnName].title,
        };
    }
}
