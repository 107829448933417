import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { UserResponseParams as User } from 'sber-marketing-types/frontend';
import type { User as CurrentUser } from '@store/user/types';
import type { ActivityReferenceMenu as ActivityReferenceMenuParams } from './types';
import { NotificationMessage, NotificationType, NotificationActionType } from '@store/common/types';
import { LinkRequest } from './WithLinkRequests';

import { ActivityReferenceMenu } from './ActivityReferenceMenu';

interface Props {
    showPreloader: boolean;
    budgetId: string;
    currentUser: CurrentUser;
    selectedActivityId: number;
    existingLinks: LinkRequest[];
    selectedBudgetItemIds: string[];
    expertUsers: User[];
    selectedExpertId: number;
    triggerLinksUpdate: () => Promise<void>;
    setActivityReferenceMenu: (data: ActivityReferenceMenuParams) => void;
    setNotification: (requestNotification: NotificationMessage) => void;
    loadExperts: () => void;
    onClose: () => void;
    onFinish: () => void;
}

export class ActivityReferenceMenuContainer extends React.Component<Props> {
    public componentDidMount(): void {
        this.props.loadExperts();
    }

    public componentDidUpdate(prevProps: Props): void {
        const expertUsersLoaded = this.props.expertUsers !== prevProps.expertUsers;

        if (expertUsersLoaded) {
            const userIsExpert = this.props.expertUsers.some(
                (item) => item.id === this.props.currentUser.attributes.id,
            );

            if (userIsExpert) {
                this.onExpertSelection(this.props.currentUser.attributes.id);
            }
        }

        const links = this.props.existingLinks;
        const prevLinks = prevProps.existingLinks;

        if (!lodash.isEqual(links, prevLinks)) {
            const { budgetId } = this.props;

            const linksOfSelectedYear = links.filter((item) => item.budgetItem.budget.id === budgetId);

            this.props.setActivityReferenceMenu({
                selectedBudgetItemIds: linksOfSelectedYear.map((link) => link.budgetItem.id),
            });
        }
    }

    public render(): JSX.Element {
        const { showPreloader, budgetId, existingLinks, expertUsers, selectedActivityId, selectedExpertId } =
            this.props;

        return (
            <ActivityReferenceMenu
                showPreloader={showPreloader}
                budgetId={budgetId}
                existingLinks={existingLinks}
                linksAreChanged={this.checkLinksChanges()}
                expertUsers={expertUsers}
                selectedActivityId={selectedActivityId}
                selectedExpertId={selectedExpertId}
                onActivitySelection={this.onActivitySelection}
                onExpertSelection={this.onExpertSelection}
                onCancelButtonClick={this.onCancelButtonClick}
                onSaveButtonClick={this.onSaveButtonClick}
            />
        );
    }

    @autobind
    private onActivitySelection(selectedActivityId: number): void {
        this.props.setActivityReferenceMenu({
            selectedActivityId,
        });
    }

    @autobind
    private onExpertSelection(selectedExpertId: number): void {
        this.props.setActivityReferenceMenu({
            selectedExpertId,
        });
    }

    @autobind
    private onCancelButtonClick(): void {
        this.close();
    }

    @autobind
    private async onSaveButtonClick(): Promise<void> {
        try {
            await this.props.triggerLinksUpdate();

            this.props.setNotification({
                type: NotificationType.SUCCESS,
                typeAction: NotificationActionType.CHANGE_REFERENCE_OF_ACTIVITY_IN_REFERENCE_MENU,
                comment: 'Связи успешно изменены',
            });
        } finally {
            this.props.onFinish();
        }
    }

    private close(): void {
        this.props.onClose();
    }

    private checkLinksChanges(): boolean {
        const { budgetId, existingLinks, selectedBudgetItemIds } = this.props;
        const existingLinksOfSelectedYear = existingLinks.filter((item) => item.budgetItem.budget.id === budgetId);
        const linkedBudgetItemsIds = existingLinksOfSelectedYear.map((item) => item.budgetItem.id);

        return !lodash.isEqual(linkedBudgetItemsIds.sort(), selectedBudgetItemIds.sort());
    }
}
