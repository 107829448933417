import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './Table.scss';

import { TableType } from '@store/creative/types';
import type {
    CellPosition,
    ColumnName,
    ColumnWidths,
    LineHeights,
    LineId,
    TableHeaderCellParams,
    TableBodyCellParams,
} from './types';

import { Icon, IconType, OpacityTransition, TableView, CellsStorage, CellEvent } from 'sber-marketing-ui';
import { TableSettings } from './TableSettings';
import { Preloader } from './Preloader';

interface Props {
    loading: boolean;
    headerCellsStorage: Record<TableType, CellsStorage<string, TableHeaderCellParams>>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    tableColumns: Record<TableType, Record<'left' | 'center' | 'right', ColumnName[]>>;
    fixedWidthColumns: ColumnName[];
    enabledColumns: (ColumnName | string)[];
    tariffTablelines: LineId[];
    productionTablelines: LineId[];
    akTablelines: LineId[];
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    archivedFilterIsActive: boolean;
    tariffTableRef: (component: TableView) => void;
    productionTableRef: (component: TableView) => void;
    akTableRef: (component: TableView) => void;
    onAddButtonClick: (table: TableType) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onArchivedFilterClick: () => void;
    onEnabledColumnsChange: (enabledColumns: (ColumnName | string)[]) => void;
    onColumnWidthsChange: (tableType: TableType, columnWidths: ColumnWidths) => void;
    onLineHeightsChange: (lineHeights: LineHeights) => void;
}

export const TableTemplate = ({
    loading,
    headerCellsStorage,
    tableCellsStorage,
    tableColumns,
    fixedWidthColumns,
    enabledColumns,
    tariffTablelines,
    productionTablelines,
    akTablelines,
    columnWidths,
    lineHeights,
    archivedFilterIsActive,
    tariffTableRef,
    productionTableRef,
    akTableRef,
    onAddButtonClick,
    onCellEvent,
    onArchivedFilterClick,
    onEnabledColumnsChange,
    onColumnWidthsChange,
    onLineHeightsChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <OpacityTransition start={!loading}>
                <div className={style.title}>Таблица заявки на креатив</div>

                <div className={style.creativeRequestGroups}>
                    <CreativeRequestGroupTable
                        tableType={TableType.Tariff}
                        title={'Тариф'}
                        headerCellsStorage={headerCellsStorage[TableType.Tariff]}
                        tableCellsStorage={tableCellsStorage}
                        tableColumns={tableColumns}
                        fixedWidthColumns={fixedWidthColumns}
                        enabledColumns={enabledColumns}
                        tablelines={tariffTablelines}
                        columnWidths={columnWidths}
                        lineHeights={lineHeights}
                        archivedFilterIsActive={archivedFilterIsActive}
                        customBackgroundColor={'#f1f5f7'}
                        tableRef={tariffTableRef}
                        onAddButtonClick={onAddButtonClick}
                        onCellEvent={onCellEvent}
                        onArchivedFilterClick={onArchivedFilterClick}
                        onEnabledColumnsChange={onEnabledColumnsChange}
                        onColumnWidthsChange={onColumnWidthsChange}
                        onLineHeightsChange={onLineHeightsChange}
                    />

                    <CreativeRequestGroupTable
                        tableType={TableType.Production}
                        title={'Производство'}
                        headerCellsStorage={headerCellsStorage[TableType.Production]}
                        tableCellsStorage={tableCellsStorage}
                        tableColumns={tableColumns}
                        fixedWidthColumns={fixedWidthColumns}
                        enabledColumns={enabledColumns}
                        tablelines={productionTablelines}
                        columnWidths={columnWidths}
                        lineHeights={lineHeights}
                        archivedFilterIsActive={archivedFilterIsActive}
                        customBackgroundColor={'#f1f5f7'}
                        tableRef={productionTableRef}
                        onAddButtonClick={onAddButtonClick}
                        onCellEvent={onCellEvent}
                        onArchivedFilterClick={onArchivedFilterClick}
                        onEnabledColumnsChange={onEnabledColumnsChange}
                        onColumnWidthsChange={onColumnWidthsChange}
                        onLineHeightsChange={onLineHeightsChange}
                    />

                    <CreativeRequestGroupTable
                        tableType={TableType.Ak}
                        title={'АК'}
                        headerCellsStorage={headerCellsStorage[TableType.Ak]}
                        tableCellsStorage={tableCellsStorage}
                        tableColumns={tableColumns}
                        fixedWidthColumns={fixedWidthColumns}
                        enabledColumns={enabledColumns}
                        tablelines={akTablelines}
                        columnWidths={columnWidths}
                        lineHeights={lineHeights}
                        archivedFilterIsActive={archivedFilterIsActive}
                        customBackgroundColor={'#f1f5f7'}
                        tableRef={akTableRef}
                        onCellEvent={onCellEvent}
                        onArchivedFilterClick={onArchivedFilterClick}
                        onEnabledColumnsChange={onEnabledColumnsChange}
                        onColumnWidthsChange={onColumnWidthsChange}
                        onLineHeightsChange={onLineHeightsChange}
                    />
                </div>
            </OpacityTransition>

            {loading && (
                <div className={style.preloader}>
                    <Preloader />
                </div>
            )}
        </div>
    );
};

interface CreativeRequestGroupTableProps {
    tableType: TableType;
    title: string;
    headerCellsStorage: CellsStorage<ColumnName, TableHeaderCellParams>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    tableColumns: Record<TableType, Record<'left' | 'center' | 'right', ColumnName[]>>;
    fixedWidthColumns: ColumnName[];
    enabledColumns: (ColumnName | string)[];
    tablelines: LineId[];
    columnWidths: ColumnWidths;
    lineHeights: LineHeights;
    archivedFilterIsActive?: boolean;
    customBackgroundColor?: string;
    tableRef: (component: TableView) => void;
    onAddButtonClick?: (table: TableType) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onArchivedFilterClick?: () => void;
    onEnabledColumnsChange?: (enabledColumns: (ColumnName | string)[]) => void;
    onColumnWidthsChange: (tableType: TableType, columnWidths: ColumnWidths) => void;
    onLineHeightsChange: (lineHeights: LineHeights) => void;
}

function CreativeRequestGroupTable({
    tableType,
    title,
    headerCellsStorage,
    tableCellsStorage,
    tableColumns,
    fixedWidthColumns,
    enabledColumns,
    tablelines,
    columnWidths,
    lineHeights,
    archivedFilterIsActive,
    customBackgroundColor,
    tableRef,
    onAddButtonClick,
    onCellEvent,
    onArchivedFilterClick,
    onEnabledColumnsChange,
    onColumnWidthsChange,
    onLineHeightsChange,
}: CreativeRequestGroupTableProps): JSX.Element {
    const visibleColumns = lodash.flatMap(enabledColumns, (item) => item.split('|')) as ColumnName[];

    return (
        <>
            <div className={style.topLine}>
                <div className={style.tableTitle}>{title}</div>

                {onAddButtonClick && (
                    <div className={style.addButton} onClick={() => onAddButtonClick(tableType)}>
                        <div className={style.addButtonIcon}>
                            <Icon type={IconType.PLUS_20} svgSize={18} />
                        </div>
                        Добавить новую строку
                    </div>
                )}

                {onArchivedFilterClick && (
                    <div className={style.archivedFilterButton} onClick={onArchivedFilterClick}>
                        <div className={style.archivedfilterButtonIcon}>
                            <Icon
                                type={archivedFilterIsActive ? IconType.EYE_OPENED : IconType.EYE_CLOSED}
                                svgSize={24}
                            />
                        </div>

                        {archivedFilterIsActive ? 'Показать удаленные строки' : 'Скрыть удаленные строки'}
                    </div>
                )}

                {!!onEnabledColumnsChange && (
                    <div className={style.tableSettings}>
                        <TableSettings
                            columns={tableColumns}
                            enabledColumns={enabledColumns}
                            onEnabledColumnsChange={onEnabledColumnsChange}
                        />
                    </div>
                )}
            </div>

            <div className={style.table}>
                <TableView
                    ref={tableRef}
                    headerCellsStorage={headerCellsStorage}
                    tableCellsStorage={tableCellsStorage}
                    columns={
                        lodash.isEmpty(visibleColumns)
                            ? tableColumns[tableType].center
                            : lodash.intersection(tableColumns[tableType].center, visibleColumns)
                    }
                    leftFixedColumns={
                        lodash.isEmpty(visibleColumns)
                            ? tableColumns[tableType].left
                            : lodash.intersection(tableColumns[tableType].left, visibleColumns)
                    }
                    rightFixedColumns={
                        lodash.isEmpty(visibleColumns)
                            ? tableColumns[tableType].right
                            : lodash.intersection(tableColumns[tableType].right, visibleColumns)
                    }
                    fixedWidthColumns={fixedWidthColumns}
                    lines={tablelines}
                    columnWidths={columnWidths}
                    lineHeights={lineHeights}
                    customBackgroundColor={customBackgroundColor}
                    onCellEvent={onCellEvent}
                    onColumnWidthsChange={(columnWidths) => onColumnWidthsChange(tableType, columnWidths)}
                    onLineHeightsChange={onLineHeightsChange}
                    displayLineHeaders
                    isStickyHeader={false}
                />
            </div>
        </>
    );
}
