import * as React from 'react';
import classnames from 'classnames';

import * as styles from './index.scss';

import type { EditMothItem, MonthItemType } from '../hooks';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';
import { formatAmount } from '../utils';

interface Props {
    monthItem: MonthItemType;
    editMothItem: EditMothItem;
}

export const MonthItem = ({ monthItem, editMothItem }: Props): JSX.Element => {
    const { id, month, monthName, planned, reserved, value } = monthItem;

    const delta = planned - reserved;
    const isMaxSumm = value === delta;

    const onMaxSummClick = () => {
        editMothItem({ id, month, value: delta });
    };
    return (
        <div className={styles.monthItem}>
            <div className={styles.column}>
                <div className={styles.row}>
                    <div className={styles.string}>
                        <span className={styles.greyText}>План:</span>
                        <span className={styles.valueText}>{formatAmount(planned)}</span>
                    </div>
                    <div className={styles.month}>{monthName}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.string}>
                        <span className={styles.greyText}>План-Резерв</span>
                        <span className={styles.valueText}>{formatAmount(delta)}</span>
                    </div>
                </div>
            </div>
            <div className={classnames(styles.column, styles.depositColumn)}>
                <div className={styles.row}>
                    <span className={styles.greyText}>Введите сумму переноса</span>
                    <span
                        className={isMaxSumm ? styles.maxSumm : styles.allSumm}
                        onClick={isMaxSumm ? () => null : onMaxSummClick}
                    >
                        {isMaxSumm ? 'Максимальная' : 'Вся доступная сумма'}
                    </span>
                </div>
                <div className={styles.row}>
                    <Input
                        value={value / 100}
                        valueType="floatPositive"
                        placeholder="Введите сумму"
                        theme={InputTheme.BudgetTransferMenu}
                        onInputChange={(value) => {
                            editMothItem({ id, month, value: +value * 100 });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
