// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-layout-Resize-_Resize__root{height:2px;min-height:2px;position:relative;cursor:ns-resize;user-select:none;border-top:1px solid var(--color-blue-0)}.src-client-modules-common-components-layout-Resize-_Resize__root:before{content:'';position:absolute;left:0;right:0;top:-8px;bottom:-8px}.src-client-modules-common-components-layout-Resize-_Resize__revert{border-top:none;border-bottom:1px solid var(--color-blue-0)}.src-client-modules-common-components-layout-Resize-_Resize__icon{position:absolute;right:0;bottom:100%}.src-client-modules-common-components-layout-Resize-_Resize__revert>.src-client-modules-common-components-layout-Resize-_Resize__icon{top:100%;bottom:auto;transform:rotateZ(-90deg)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/layout/Resize/Resize.scss"],"names":[],"mappings":"AAAA,kEACE,UAAW,CACX,cAAe,CACf,iBAAkB,CAClB,gBAAiB,CACjB,gBAAiB,CACjB,wCAAyC,CAN3C,yEASI,UAAW,CACX,iBAAkB,CAClB,MAAO,CACP,OAAQ,CACR,QAAS,CACT,WAAY,CACb,oEAID,eAAgB,CAChB,2CAA4C,CAC7C,kEAGC,iBAAkB,CAClB,OAAQ,CACR,WAAY,CAEZ,sIACE,QAAS,CACT,WAAY,CACZ,yBAA0B","sourcesContent":[".root {\n  height: 2px;\n  min-height: 2px;\n  position: relative;\n  cursor: ns-resize;\n  user-select: none;\n  border-top: 1px solid var(--color-blue-0);\n\n  &:before {\n    content: '';\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: -8px;\n    bottom: -8px;\n  }\n}\n\n.revert {\n  border-top: none;\n  border-bottom: 1px solid var(--color-blue-0);\n}\n\n.icon {\n  position: absolute;\n  right: 0;\n  bottom: 100%;\n\n  .revert > & {\n    top: 100%;\n    bottom: auto;\n    transform: rotateZ(-90deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-common-components-layout-Resize-_Resize__root",
	"revert": "src-client-modules-common-components-layout-Resize-_Resize__revert",
	"icon": "src-client-modules-common-components-layout-Resize-_Resize__icon"
};
export default ___CSS_LOADER_EXPORT___;
