import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreativeRequest, CreativeRequestItemComment } from '@api';
import { StoreState } from '@store';
import { getCreativeSidebarState, setWatchCommentsByItemAndColumn } from '@store/creative/sidebar';
import { useCreativeRequestItems } from './useCreativeRequestItems';
import { useUsersForMentionOrganizationIds } from './useUsersForMentionOrganizationIds';

interface Props {
    creativeRequest: CreativeRequest;
}

interface CommentToReplayTo {
    commentId: string;
    columnName: string;
}

export function useComments({ creativeRequest }: Props) {
    const dispatch = useDispatch();

    const creativeRequestItems = useCreativeRequestItems(creativeRequest);
    const watchParams = useSelector((state: StoreState) => getCreativeSidebarState(state).watchComentsByItemAndColumn);
    const usersForMentionOrganizationIds = useUsersForMentionOrganizationIds(creativeRequest);

    const getActiveCreativeRequestItem = () => {
        const activeCreativeRequestItem = creativeRequestItems?.find(
            (creativeRequestItem) => creativeRequestItem.dto.model.id === activeCreativeRequestItemId,
        );
        return activeCreativeRequestItem;
    };

    const [activeCreativeRequestItemId, setActiveCreativeRequestItemId] = React.useState<string>(null);
    const [activeCreativeRequestColumn, setActiveCreativeRequestColumn] = React.useState<string>(null);
    const [activeCreativeRequestItem, setActiveCreativeRequestItem] = React.useState<any>(
        getActiveCreativeRequestItem(),
    );
    const [comments, setComments] = React.useState<CreativeRequestItemComment[]>(null);
    const [commentToReplyTo, setCommentToReplyTo] = React.useState<CommentToReplayTo>(null);

    // ================= Получение коментариев по строке ======================================
    async function fetchComments() {
        const comments = await activeCreativeRequestItem?.dto?.model.getComments();
        setComments([...comments]);
    }

    React.useEffect(() => {
        activeCreativeRequestItem?.dto && fetchComments();

        const onCommentsHandler = (payload: any) => {
            if (payload.property === 'comments') {
                fetchComments();
            }
        };

        activeCreativeRequestItem?.dto?.events.onUpdated(onCommentsHandler);

        return function resetListeners() {
            activeCreativeRequestItem?.dto?.events.offUpdated(onCommentsHandler);
        };
    }, [activeCreativeRequestItem]);

    // ============== Получение текущей строки =============================================
    React.useEffect(() => {
        const item = getActiveCreativeRequestItem();
        setActiveCreativeRequestItem(item);
        setCommentToReplyTo(null);
    }, [activeCreativeRequestItemId]);

    React.useEffect(
        function applyWatchCommentsByItemAndColumn() {
            const applySavedParams =
                creativeRequestItems.length && watchParams.creativeRequestItemId && watchParams.column;

            if (applySavedParams) {
                setActiveCreativeRequestColumn(watchParams.column);
                setActiveCreativeRequestItemId(watchParams.creativeRequestItemId);
            }
        },
        [creativeRequestItems, watchParams],
    );

    React.useEffect(function onMount() {
        return function onUnmount() {
            dispatch(
                setWatchCommentsByItemAndColumn({
                    creativeRequestItemId: null,
                    column: null,
                }),
            );
        };
    }, []);

    // =============== Колбэки ===============
    function closeActiveCreativeRequestColumn() {
        setActiveCreativeRequestColumn(null);
    }

    function onCommmentCreated(commentId: string) {
        if (!activeCreativeRequestItem) {
            creativeRequest.model.addComment({ commentId });
        } else {
            activeCreativeRequestItem?.dto?.model?.addComment({
                creativeRequestId: creativeRequest.model.id,
                commentId,
                column: activeCreativeRequestColumn || commentToReplyTo?.columnName,
            });
        }
    }

    return {
        usersForMentionOrganizationIds,
        creativeRequestItems,
        activeCreativeRequestItem,
        activeCreativeRequestColumn,
        activeCreativeRequestItemId,
        setActiveCreativeRequestItemId,
        commentToReplyTo,
        setCommentToReplyTo,
        onCommmentCreated,
        closeActiveCreativeRequestColumn,
        comments,
        refetchComments: fetchComments,
    };
}
