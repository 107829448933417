import * as lodash from 'lodash';

import type {
    Autopilot2MediaplanPlanDataItem as MediaplanItem,
    Autopilot2Placement as Placement,
    PredictionDigital,
} from 'sber-marketing-types/backend';

const PLACEMENT_STATUS_TITLE = {
    ['NO_MATERIALS']: 'Нет материалов',
    ['MODERATION']: 'На модерации',
    ['MODERATION_SUCCESS']: 'Модерация пройдена',
    ['NO_MATCH_TT']: 'Не соответствует ТТ',
    ['NOT_APPROVED']: 'Не согласовано Заказчиком',
    ['APPROVED']: 'Согласовано',
};

export const enum COLUMN_NAME {
    LineName = 'lineName',
    Targeting = 'targeting',
    Format = 'format',
    Budget = 'budget',
    ReachTech = 'reachTech',
    Visits = 'visits',
    Conversions = 'conversions',
    Cpa = 'cpa',
    Status = 'status',
    Prediction = 'prediction',
    KpiCost = 'kpiCost',
}

export const totalColumnsOfQa = [
    COLUMN_NAME.LineName,
    COLUMN_NAME.Targeting,
    COLUMN_NAME.Format,
    COLUMN_NAME.Budget,
    COLUMN_NAME.ReachTech,
    COLUMN_NAME.Visits,
    COLUMN_NAME.Conversions,
    COLUMN_NAME.Cpa,
    COLUMN_NAME.Prediction,
    COLUMN_NAME.KpiCost,
];

export const totalColumnsOfNonQa = [
    COLUMN_NAME.LineName,
    COLUMN_NAME.Targeting,
    COLUMN_NAME.Format,
    COLUMN_NAME.Budget,
    COLUMN_NAME.ReachTech,
    COLUMN_NAME.Visits,
    COLUMN_NAME.Conversions,
    COLUMN_NAME.Cpa,
    COLUMN_NAME.Prediction,
    COLUMN_NAME.KpiCost,
];

export const tableColumnsOfQa = [
    COLUMN_NAME.LineName,
    COLUMN_NAME.Targeting,
    COLUMN_NAME.Format,
    COLUMN_NAME.Budget,
    COLUMN_NAME.ReachTech,
    COLUMN_NAME.Visits,
    COLUMN_NAME.Conversions,
    COLUMN_NAME.Cpa,
];

export const tableColumnsOfNonQa = [
    COLUMN_NAME.LineName,
    COLUMN_NAME.Format,
    COLUMN_NAME.Budget,
    COLUMN_NAME.ReachTech,
    COLUMN_NAME.Visits,
    COLUMN_NAME.Conversions,
    COLUMN_NAME.Cpa,
];

export const totalColumns = IS_QA ? totalColumnsOfQa : totalColumnsOfNonQa;
export const tableColumns = IS_QA ? tableColumnsOfQa : tableColumnsOfNonQa;

export const totalColumnsWithStatus = [...totalColumns, COLUMN_NAME.Status];
export const tableColumnsWithStatus = [...tableColumns, COLUMN_NAME.Status];

export interface AccessorParams {
    mediaplanItem?: MediaplanItem;
    allMediaplanItems?: MediaplanItem[];
    prediction?: PredictionDigital;
    placement?: Placement;
    allPlacements?: Placement[];
}

export interface ColumnParams {
    title: string | ((params: AccessorParams) => string);
    accessor: (params: AccessorParams) => React.ReactText;
    totalLineAccessor: (params: AccessorParams) => React.ReactText;
}

export const columnsConfig: { [columnName: string]: ColumnParams } = {
    [COLUMN_NAME.LineName]: {
        title: 'Канал/ Площадка',
        accessor: (params) => params.mediaplanItem.name,
        totalLineAccessor: () => '',
    },
    [COLUMN_NAME.Targeting]: {
        title: 'Таргетинг',
        accessor: (params) => params.mediaplanItem?.targeting || null,
        totalLineAccessor: () => '',
    },
    [COLUMN_NAME.Format]: {
        title: 'Формат рекламного материала',
        accessor: (params) => params.mediaplanItem.format,
        totalLineAccessor: () => 'Итого',
    },
    [COLUMN_NAME.Budget]: {
        title: 'Бюджет со скидкой (без НДС)',
        accessor: (params) => formatCurrencyValue(roundNumber(params.mediaplanItem.budget, 2)),
        totalLineAccessor: (params) => {
            const sum = lodash.sumBy(params.allMediaplanItems, (item) => item.budget);
            return formatCurrencyValue(roundNumber(sum, 2));
        },
    },
    [COLUMN_NAME.ReachTech]: {
        title: 'Охваты',
        accessor: (params) => Math.round(params.mediaplanItem.reach_tech),
        totalLineAccessor: (params) => {
            const sum = lodash.sumBy(params.allMediaplanItems, (item) => item.reach_tech);

            return Math.round(sum * 0.7);
        },
    },
    [COLUMN_NAME.Visits]: {
        title: 'Визиты',
        accessor: (params) => Math.round(params.mediaplanItem.visits),
        totalLineAccessor: (params) => Math.round(lodash.sumBy(params.allMediaplanItems, (item) => item.visits)),
    },
    [COLUMN_NAME.Conversions]: {
        title: 'Конверсии',
        accessor: (params) => Math.floor(params.mediaplanItem.conversions),
        totalLineAccessor: (params) => Math.floor(lodash.sumBy(params.allMediaplanItems, (item) => item.conversions)),
    },
    [COLUMN_NAME.Cpa]: {
        title: 'CPA',
        accessor: (params) => formatCurrencyValue(roundNumber(params.mediaplanItem.cpa, 2)),
        totalLineAccessor: (params) => {
            const budgetSum = lodash.sumBy(params.allMediaplanItems, (item) => item.budget);
            const conversionsSum = lodash.sumBy(params.allMediaplanItems, (item) => item.conversions);

            const result = budgetSum / conversionsSum;

            return formatCurrencyValue(roundNumber(lodash.isFinite(result) ? result : 0, 2));
        },
    },
    [COLUMN_NAME.Status]: {
        title: 'Статус',
        accessor: (params) => PLACEMENT_STATUS_TITLE[params.placement.creativeStatus],
        totalLineAccessor: (params) => {
            const itemsCount = params.allMediaplanItems.length;
            const approvedItemsCount = params.allPlacements.filter((item) => item.creativeStatus === 'APPROVED').length;

            return `${approvedItemsCount}/${itemsCount} креативов согласовано`;
        },
    },
    [COLUMN_NAME.Prediction]: {
        title: (params: AccessorParams) => params.prediction?.kpi,
        accessor: (params) => null,
        totalLineAccessor: (params) =>
            Math.round(lodash.sumBy(params.prediction?.value || [], (item) => item.value) || 0),
    },
    [COLUMN_NAME.KpiCost]: {
        title: 'Стоимость за KPI',
        accessor: (params) => null,
        totalLineAccessor: (params) => {
            const prediction = lodash.sumBy(params.prediction?.value || [], (item) => item.value) || 0;
            const budgetSum = lodash.sumBy(params.allMediaplanItems, (item) => item.budget);

            return prediction !== 0 ? formatCurrencyValue(roundNumber(budgetSum / prediction, 2)) : 0;
        },
    },
};

function roundNumber(value: number, digitsAfterComma: number): string {
    const roundedValue = Math.round(value * 100) / 100;
    const formatedValue = roundedValue.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

function formatCurrencyValue(value: number | string): string {
    const [decimalPart, fractionPart] = value.toString().split('.');

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

    return `${splittedDecimal}${fractionPart ? `.${fractionPart}` : ''} ₽`;
}
