import * as React from 'react';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Autopilot2 } from 'sber-marketing-types/backend';
import type { StoreState } from '@store';
import type { CellParams, CellPosition, ColumnHeaderParams, ColumnName, ColumnWidths, LineId } from './types';
import { CellEvent } from './types';
import type { LinesGroup } from './TableTemplate';
import { TableTemplate } from './TableTemplate';

import { IconType } from 'sber-marketing-ui';
import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';
import { getAutopilot, getChangedCreativeStatuses } from '@store/autopilot/selectors';
import { getLoginUser, User } from '@store/user';
import {
    AccessorParams,
    CellComponentsByColumnType,
    clientTableColumns,
    COLUMN_NAME,
    ColumnParams,
    ColumnsConfig,
    ColumnType,
    readOnlyColumns,
    ReadOnlyColumnsConfig,
    readOnlyTableColumns,
    tableColumns,
} from './ColumnsConfig';
import { Loader, Saver } from '../../../modules';

const IconByStatus: Record<string, { type: IconType; color: string; size: number }> = {
    ['NO_MATERIALS']: { type: IconType.FOLDER, color: '#7e8681', size: 14 },
    ['MODERATION']: { type: IconType.DIALOG_WARNING, color: '#d2953d', size: 16 },
    ['MODERATION_SUCCESS']: { type: IconType.DIALOG_SUCCESS, color: '#6fcf97', size: 16 },
    ['NO_MATCH_TT']: { type: IconType.COPY_ERROR, color: '#e63900', size: 15 },
    ['NOT_APPROVED']: { type: IconType.REJECTED_ICON, color: '#e63900', size: 14 },
    ['APPROVED']: { type: IconType.CHECK28, color: '#6fcf97', size: 12 },
};

interface Props extends Partial<MapProps> {
    readOnly?: boolean;
}

interface MapProps {
    autopilot: Autopilot2;
    changedCreativeStatuses: Record<string, string>;
    user: User;
}

interface State {
    lineGroups: LinesGroup[];
}

@(connect(mapStateToProps, null) as any)
export class TableBehaviour extends React.PureComponent<Props, State> {
    private loader: Loader;
    private saver: Saver;
    private viewModel: TableViewModel;
    private tables: Record<string, TableView> = {};
    private columnsConfig: { [columnName: string]: ColumnParams };
    private tableColumns: string[];
    private columnWidths: ColumnWidths;

    public constructor(props: Props) {
        super(props);

        this.state = {
            lineGroups: [],
        };

        this.viewModel = new TableViewModel({
            makeColumnHeaderParams: this.makeColumnHeaderParams,
            makeCellParams: this.makeCellParams,
        });

        this.loader = Loader.getInstance();
        this.saver = Saver.getInstance();

        this.columnsConfig = props.readOnly ? ReadOnlyColumnsConfig : ColumnsConfig;
        this.tableColumns = props.readOnly
            ? readOnlyTableColumns
            : this.checkUserAutopilotClientAccess()
            ? clientTableColumns
            : tableColumns;
        this.columnWidths = lodash.mapValues(this.columnsConfig, (item) => item.defaultWidth);
    }

    public componentDidMount() {
        this.updateLineIds();
    }

    public componentDidUpdate(prevProps: Props) {
        const placementsChanged = this.props.autopilot?.placements !== prevProps.autopilot?.placements;
        const creativesChanged = this.props.autopilot?.creatives !== prevProps.autopilot?.creatives;

        if (placementsChanged || creativesChanged) {
            this.updateLineIds();
            this.updateLines();
        }

        const changedCreativeStatusesChanged = this.props.changedCreativeStatuses !== prevProps.changedCreativeStatuses;

        if (changedCreativeStatusesChanged) {
            const oldIds = lodash.keys(prevProps.changedCreativeStatuses);
            const newIds = lodash.keys(this.props.changedCreativeStatuses);

            const idsToUpdate = lodash.uniq([
                ...lodash.without(oldIds, ...newIds),
                ...lodash.without(newIds, ...oldIds),
            ]);

            idsToUpdate.forEach((lineId) => {
                this.updateLine(lineId);
            });
        }
    }

    public render(): JSX.Element {
        const { readOnly } = this.props;
        const { lineGroups } = this.state;

        return React.createElement(TableTemplate, {
            viewModel: this.viewModel,
            tableColumns: this.tableColumns,
            lineGroups,
            readOnlyColumns: readOnly ? lodash.keys(ReadOnlyColumnsConfig) : readOnlyColumns,
            columnWidths: this.columnWidths,
            tableRef: this.tableRef,
            onCellEvent: this.onCellEvent,
        });
    }

    @autobind
    protected tableRef(component: TableView, groupName: string) {
        this.tables[groupName] = component ? (component as any).getInstance() : null;
    }

    @autobind
    protected async onSaveButtonClick(lineId: LineId) {
        await this.saver.savePlacementChanges(lineId);
        await this.loader.loadAutopilot();
        this.saver.clearPlacementChanges(lineId);
    }

    @autobind
    protected onCancelEditButtonClick(lineId: LineId) {
        this.saver.clearPlacementChanges(lineId);
    }

    @autobind
    protected async onApproveButtonClick(lineId: LineId) {
        await this.saver.setPlacementStatus(lineId, 'APPROVED');
        await this.loader.loadAutopilot();
    }

    @autobind
    protected async onRejectButtonClick(lineId: LineId) {
        await this.saver.setPlacementStatus(lineId, 'NOT_APPROVED');
        await this.loader.loadAutopilot();
    }

    @autobind
    protected getCellValue(cellPosition: CellPosition) {
        const { columnName } = cellPosition;

        const accessorParams = this.makeAccessorParams(cellPosition);

        return this.columnsConfig[columnName].getValue(accessorParams);
    }

    @autobind
    protected getCellItems(cellPosition: CellPosition): { title: React.ReactText; value: any }[] {
        const { columnName } = cellPosition;

        const accessorParams = this.makeAccessorParams(cellPosition);

        return this.columnsConfig[columnName].getItems(accessorParams) || [];
    }

    @autobind
    protected makeValueChangeHandler(cellPosition: CellPosition, closeEditorOnChange: boolean) {
        return async (value: any) => {
            const { lineId, columnName } = cellPosition;

            const accessorParams = this.makeAccessorParams(cellPosition);

            await this.columnsConfig[columnName].setValue(accessorParams, value);
            this.updateLine(lineId);
        };
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.EditStart:
                this.updateCell(position, true);
                break;

            case CellEvent.EditEnd:
                this.updateCell(position, false);
                break;

            case CellEvent.Selection:
                this.updateCell(position, false);
                break;
        }
    }

    @autobind
    private makeColumnHeaderParams(columnName: ColumnName): ColumnHeaderParams {
        return {
            title: this.columnsConfig[columnName].title,
        };
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { autopilot, changedCreativeStatuses } = this.props;
        const { lineId } = cellPosition;

        const placement = autopilot.placements.find((item) => item.code === lineId);

        return {
            placement,
            autopilot,
            changedCreativeStatuses,
            userHasAutopilotClientAccess: this.checkUserAutopilotClientAccess(),
            userHasAutopilotClientServiceAccess: this.checkUserAutopilotClientServiceAccess(),
        };
    }

    @autobind
    private updateLines() {
        this.props.autopilot.placements.forEach((item) => {
            this.updateLine(item.code);
        });
    }

    @autobind
    private updateLine(lineId: LineId) {
        const lineGroup = this.state.lineGroups.find((item) => item.lines.includes(lineId));

        this.tableColumns.forEach((columnName) => {
            const cellPosition = { lineId, columnName };

            const cellEditStatus = this.tables[lineGroup.name].getCellEditStatus(cellPosition);

            this.updateCell(cellPosition, cellEditStatus);
        });
    }

    private updateCell(position: CellPosition, edit: boolean) {
        this.viewModel.setCellParams(position, this.makeCellParams(position, edit));
    }

    @autobind
    private makeCellParams(cellPosition: CellPosition, edit: boolean): CellParams {
        return {
            component: this.getCellComponent(cellPosition, edit),
            cellProps: this.makeCellProps(cellPosition, edit),
        };
    }

    private getCellComponent(cellPosition: CellPosition, edit: boolean): React.ClassType<any, any, any> {
        const { columnName } = cellPosition;

        const columnType = this.columnsConfig[columnName].type;

        return edit ? CellComponentsByColumnType[columnType].editCell : CellComponentsByColumnType[columnType].cell;
    }

    private makeCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { columnName } = cellPosition;

        const columnType = this.columnsConfig[columnName].type;

        let cellProps: any;

        switch (columnType) {
            case ColumnType.Text:
                cellProps = this.makeTextCellProps(cellPosition);
                break;

            case ColumnType.Select:
                cellProps = this.makeSelectCellProps(cellPosition, edit);
                break;

            case ColumnType.Actions:
                cellProps = this.makeActionsCellProps(cellPosition);
                break;
            case ColumnType.Creative:
                cellProps = this.makeCreativeCellProps(cellPosition);
                break;
        }

        return cellProps;
    }

    private makeTextCellProps(cellPosition: CellPosition): any {
        const { autopilot } = this.props;
        const { columnName, lineId } = cellPosition;

        const cellProps: any = {
            title: this.getCellValue(cellPosition) || '—',
        };

        const line = autopilot?.placements.find((item) => item.code === lineId);

        if (line.ilId !== undefined) {
            cellProps.backgroundColor = '#e7fff0';
        }

        if (columnName === COLUMN_NAME.StatusText) {
            const selectValue = this.getCellValue({ columnName: COLUMN_NAME.StatusSelect, lineId });

            cellProps.icon = IconByStatus[selectValue];
        }

        return cellProps;
    }

    private makeSelectCellProps(cellPosition: CellPosition, edit: boolean): any {
        const { autopilot } = this.props;
        const { lineId } = cellPosition;

        const value = this.getCellValue(cellPosition);
        const items = this.getCellItems(cellPosition);

        const title =
            value && items.some((item) => item.value === value)
                ? items.find((item) => item.value === value).title
                : `—`;

        const cellProps: any = edit
            ? {
                  title,
                  items: items,
                  selectedValue: value,
                  icon: IconByStatus[value],
                  onValueChange: this.makeValueChangeHandler(cellPosition, true),
              }
            : {
                  title,
                  icon: IconByStatus[value],
              };

        const line = autopilot?.placements.find((item) => item.code === lineId);

        if (line.ilId !== undefined) {
            cellProps.backgroundColor = '#e7fff0';
        }

        return cellProps;
    }

    private makeActionsCellProps(cellPosition: CellPosition): any {
        const { autopilot, changedCreativeStatuses } = this.props;
        const { lineId } = cellPosition;

        const placement = autopilot.placements.find((item) => item.code === lineId);

        return {
            userHasAutopilotClientAccess: this.checkUserAutopilotClientAccess(),
            lineStatus: placement.creativeStatus,
            lineHasChanges: changedCreativeStatuses[lineId] !== undefined,
            onSaveButtonClick: () => this.onSaveButtonClick(lineId),
            onCancelEditButtonClick: () => this.onCancelEditButtonClick(lineId),
            onApproveButtonClick: () => this.onApproveButtonClick(lineId),
            onRejectButtonClick: () => this.onRejectButtonClick(lineId),
        };
    }

    private makeCreativeCellProps(cellPosition: CellPosition): any {
        const { autopilot } = this.props;
        const { lineId } = cellPosition;

        const placement = autopilot.placements.find((item) => item.code === lineId);
        const specId = placement?.ilId;
        const isCreativeSomeFilled = autopilot?.creatives?.creativeGroups
            .filter(({ engine }) => engine === specId)
            .some(({ creatives }) => creatives.some(({ props }) => props.some(({ value }) => value)));

        return {
            specId,
            isCreativeSomeFilled,
        };
    }

    private updateLineIds() {
        const { autopilot } = this.props;

        const groupedLines = lodash.groupBy(autopilot.placements, (item) => item.channel);

        let updatedLineGroups = lodash.flatMap(groupedLines, (lines, key) => ({
            name: key,
            lines: lines.map((item) => item.code),
        }));

        updatedLineGroups = lodash.sortBy(updatedLineGroups, (item) => item.name);

        this.setState({
            lineGroups: updatedLineGroups,
        });
    }

    private checkUserAutopilotClientAccess(): boolean {
        // check if user has role === Autopilot Client
        return this.props.user.attributes.roles.map(({ id }) => id).includes(22);
    }

    private checkUserAutopilotClientServiceAccess(): boolean {
        // check if user has role === Autopilot Client Service
        return this.props.user.attributes.roles.map(({ id }) => id).includes(24);
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        autopilot: getAutopilot(state),
        changedCreativeStatuses: getChangedCreativeStatuses(state),
        user: getLoginUser(state),
    };
}
