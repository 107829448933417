import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { StoreState } from '@store';
import { getContextTitlesTableData, getContextDescriptionsTableData } from '@store/autopilot/selectors';

import * as styles from './styles.scss';
import { List } from './List';

interface Props {
    className?: string;
}

export const Texts: React.FC<Props> = ({ className }) => {
    const contextTitlesTableData = useSelector((state: StoreState) => getContextTitlesTableData(state));
    const contextDescriptionsTableData = useSelector((state: StoreState) => getContextDescriptionsTableData(state));

    return (
        <div className={classNames(styles.root, className)}>
            {!!contextTitlesTableData.length && <List title={'Заголовки'} data={contextTitlesTableData} />}
            {!!contextDescriptionsTableData.length && <List title={'Описания'} data={contextDescriptionsTableData} />}
        </div>
    );
};
