import { HandlerMap } from './types';

export const constraintHandlerMap: HandlerMap = {
    charsMin: (charsMin, value) => {
        return value.length < charsMin ? 'Недопустимо меньшее количество символов' : undefined;
    },
    charsMax: (charsMax, value) => {
        return value.length > charsMax ? 'Недопустимо большее количество символов' : undefined;
    },
};
