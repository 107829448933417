// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-CreativeModal-Footer-_styles__root{margin-top:20px;display:flex;justify-content:end}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/CreativeStatusesStageWidget/Table/CreativeModal/Footer/styles.scss"],"names":[],"mappings":"AAAA,+HACI,eAAgB,CAEhB,YAAa,CACb,mBAAoB","sourcesContent":[".root {\n    margin-top: 20px;\n\n    display: flex;\n    justify-content: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-StageWidgets-CreativeStatusesStageWidget-Table-CreativeModal-Footer-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
