import * as React from 'react';
import classNames from 'classnames';

import * as style from './ExecutionIdButton.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { CellEditorWrapper } from './CellEditorWrapper';
import { ExecutionIdMenu } from '../ExecutionIdMenu';

interface Props {
    onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;
}

export const ExecutionIdButton: React.FC<Props> = ({ onModalOpen }: Props) => {
    const [displayMenu, setDisplayMenu] = React.useState(false);

    function onOpenerClick() {
        setDisplayMenu(true);
    }

    function onMenuClose() {
        setDisplayMenu(false);
    }

    return (
        <div className={style.root}>
            <div className={classNames(style.opener, displayMenu && style.opened)} onClick={onOpenerClick}>
                <Icon className={style.openerIcon} type={IconType.PLUS_SIGN} svgSize={10} />
                Добавить ID
            </div>

            {displayMenu && (
                <>
                    <CellEditorWrapper>
                        <ExecutionIdMenu displayBudgetButton onModalOpen={onModalOpen} onItemClick={onMenuClose} />
                    </CellEditorWrapper>

                    <div className={style.mask} onClick={onMenuClose} />
                </>
            )}
        </div>
    );
};
