import autobind from 'autobind-decorator';
import { PureComponent, createElement } from 'react';
import { isFunction, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { GetPreviewResponse } from '@mrm/videoPreview';

import { Utils } from '@common/Utils';

import { FileApi, FileApiUploadParams } from '@api';

import { StoreState } from '@store';
import { FileAsset, FileAssetTarget } from '@store/commonTypes';
import { documentWasEditedInR7 } from '@store/R7';
import { LoadPreviewPaylaod, loadPreview, getPreviewByItemId } from '@store/videoPreviews';

import { FileAssetColor, FileAssetWithPreviewProps as OwnProps, FileAssetWithPreviewState as State } from './types';
import { FileAssetWithPreviewTemplate } from './FileAssetWithPreviewTemplate';

/** "FileAssetWithPreview" container component */

interface MapProps {
    videoPreview: GetPreviewResponse;
    documentWasEditedInR7: boolean;
    isR7Editable: boolean;
}

interface DispatchProps {
    loadPreview: (payload: LoadPreviewPaylaod) => void;
}

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class FileAssetWithPreviewContainer extends PureComponent<Props, State> {
    public static readonly displayName: string = 'FileAssetWithPreviewContainer';

    public state: State = {
        borderColor: FileAssetColor.TRANSPARENT,
        removeOpacity: 0,
        removeBackgroundColor: FileAssetColor.GREY,
        previewShadowOpacity: 0,
        openGalleryShadowColor: FileAssetColor.GREEN,
        downloadFileShadowColor: FileAssetColor.GREEN,
        openGalleryShadowOpacity: 0,
        downloadFileShadowOpacity: 0,
        isRemovePopupOpen: false,
        galleryDisplay: 'none',
        downloadDisplay: 'none',
        galleryOpacity: 0.8,
        downloadOpacity: 0.8,
        nameColor: FileAssetColor.GREEN,
        microTypeMarkColor: FileAssetColor.BLACK_TRANSLUCENT,
        previewWasLoaded: false,
    };

    public componentDidMount(): void {
        this.loadPreviewInNecessary();
    }

    public componentDidUpdate(prevProps: Props): void {
        this.loadPreviewInNecessary();
    }

    public render(): JSX.Element {
        const {
            className,
            canDelete,
            disableDownload,
            useR7Controls,
            videoPreview,
            documentWasEditedInR7,
            isR7Editable,
        } = this.props;
        return createElement(FileAssetWithPreviewTemplate, {
            ...this.originAsset,
            ...this.state,
            className,
            canDelete,
            disableDownload,
            useR7Controls,
            videoPreview,
            documentWasEditedInR7,
            isR7Editable,
            getUploadParams: this.getUploadParams,
            onRemoveOpenClick: this.onRemoveOpenClick,
            onGalleryOpenClick: this.onGalleryOpenClick,
            onDownloadClick: this.onDownloadClick,
            onRemoveSubmitClick: this.onRemoveSubmitClick,
            onRemoveCancelClick: this.onRemoveCancelClick,
            onPreviewMouseOver: this.onPreviewMouseOver,
            onPreviewMouseLeave: this.revertStyles,
            onRemoveMouseOver: this.onRemoveMouseOver,
            onRemoveMouseLeave: this.revertStyles,
            onGalleryMouseOver: this.onGalleryMouseOver,
            onGalleryMouseLeave: this.revertStyles,
            onDownloadMouseOver: this.onDownloadMouseOver,
            onDownloadMouseLeave: this.revertStyles,
        });
    }

    private get originAsset(): FileAsset {
        const {
            name,
            originName,
            createdAt,
            type,
            targetType,
            targetId,
            parentTargetId,
            size,
            storage,
            uploadedBy,
            file,
            previewUrl,
            fullSizeUrl,
            isLoading,
            loadingProgress,
        } = this.props;
        return {
            name,
            originName,
            createdAt,
            type,
            targetType,
            targetId,
            parentTargetId,
            size,
            storage,
            uploadedBy,
            file,
            previewUrl,
            fullSizeUrl,
            isLoading,
            loadingProgress,
        };
    }

    /** Remove popup open click handler */
    @autobind
    private onRemoveOpenClick(): void {
        this.setState(() => ({ isRemovePopupOpen: true }));
    }

    /** Gallery open click handler */
    @autobind
    private onGalleryOpenClick(): void {
        if (isFunction(this.props.onGalleryClick)) {
            this.props.onGalleryClick(this.originAsset);
        }
    }

    /** Download click handler */
    @autobind
    private async onDownloadClick(): Promise<void> {
        const { disableDownload, name } = this.props;
        if (!disableDownload) {
            const params = this.getUploadParams();

            if (!isEmpty(params)) {
                await FileApi.downloadFile(params, name);
            }
        }
    }

    /** Remove submit click handler */
    @autobind
    private onRemoveSubmitClick(): void {
        this.setState(
            () => ({ isRemovePopupOpen: false }),
            () => {
                if (isFunction(this.props.onRemove)) {
                    this.props.onRemove(this.originAsset);
                }
            },
        );
    }

    /** Remove cancel click handler */
    @autobind
    private onRemoveCancelClick(): void {
        this.setState(() => ({ isRemovePopupOpen: false }));
    }

    /** Preview mouse over handler */
    @autobind
    private onPreviewMouseOver() {
        this.setState(() => ({
            borderColor: FileAssetColor.GREY,
            removeOpacity: 1,
            removeBackgroundColor: FileAssetColor.GREY,
            previewShadowOpacity: 0.5,
            microTypeMarkColor: FileAssetColor.TRANSPARENT,
        }));
    }

    /** Remove mouse over handler */
    @autobind
    private onRemoveMouseOver(): void {
        this.setState(() => ({
            borderColor: FileAssetColor.PINK,
            removeOpacity: 1,
            removeBackgroundColor: FileAssetColor.PINK,
            previewShadowOpacity: 0.5,
            openGalleryShadowColor: FileAssetColor.PINK,
            downloadFileShadowColor: FileAssetColor.PINK,
            openGalleryShadowOpacity: 0.5,
            downloadFileShadowOpacity: 0.5,
            galleryDisplay: 'block',
            downloadDisplay: 'block',
            nameColor: FileAssetColor.PINK,
            microTypeMarkColor: FileAssetColor.TRANSPARENT,
        }));
    }

    /** Gallery mouse over handler */
    @autobind
    private onGalleryMouseOver(): void {
        this.setState(() => ({
            borderColor: FileAssetColor.GREEN,
            removeOpacity: 1,
            removeBackgroundColor: FileAssetColor.GREY,
            previewShadowOpacity: 0.5,
            openGalleryShadowOpacity: 0.7,
            galleryDisplay: 'block',
            downloadDisplay: 'block',
            galleryOpacity: 1,
            nameColor: FileAssetColor.GREEN,
            microTypeMarkColor: FileAssetColor.TRANSPARENT,
        }));
    }

    /** Download mouse over handler */
    @autobind
    private onDownloadMouseOver(): void {
        this.setState(() => ({
            borderColor: FileAssetColor.GREEN,
            removeOpacity: 1,
            removeBackgroundColor: FileAssetColor.GREY,
            previewShadowOpacity: 0.5,
            downloadFileShadowOpacity: 0.7,
            galleryDisplay: 'block',
            downloadDisplay: 'block',
            downloadOpacity: 1,
            microTypeMarkColor: FileAssetColor.TRANSPARENT,
        }));
    }

    @autobind
    private revertStyles(): void {
        this.setState(() => ({
            borderColor: FileAssetColor.TRANSPARENT,
            removeOpacity: 0,
            removeBackgroundColor: FileAssetColor.GREY,
            previewShadowOpacity: 0,
            openGalleryShadowOpacity: 0,
            downloadFileShadowOpacity: 0,
            galleryDisplay: 'none',
            downloadDisplay: 'none',
            galleryOpacity: 0.8,
            downloadOpacity: 0.8,
            nameColor: FileAssetColor.GREEN,
            openGalleryShadowColor: FileAssetColor.GREEN,
            downloadFileShadowColor: FileAssetColor.GREEN,
            microTypeMarkColor: FileAssetColor.BLACK_TRANSLUCENT,
        }));
    }

    @autobind
    private getUploadParams(): FileApiUploadParams {
        const { targetId, parentTargetId, briefId, uniqId, parentUniqId, targetType, originName, type } = this.props;

        switch (targetType) {
            case FileAssetTarget.BRIEF:
                return {
                    activityId: parentTargetId,
                    briefId: briefId,
                    uniqId,
                    parentUniqId,
                    fieldId: targetId,
                    originName,
                    type,
                };
            case FileAssetTarget.COMMENTARY:
                return {
                    taskId: parentTargetId,
                    commentId: targetId,
                    originName,
                    type,
                };
            case FileAssetTarget.TASK:
                return {
                    taskId: targetId,
                    originName,
                    type,
                };
            default:
                return null;
        }
    }

    private loadPreviewInNecessary(): void {
        const { id, originName, type, isLoading, disableDownload } = this.props;

        if (!(this.state.previewWasLoaded || isLoading || disableDownload)) {
            this.setState(
                {
                    previewWasLoaded: true,
                },
                () => {
                    this.props.loadPreview({
                        uploadParams: this.getUploadParams(),
                        id,
                        name: originName,
                        extension: type,
                    });
                },
            );
        }
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { id, type } = ownProps;

    return {
        isR7Editable: Utils.fileValidForR7(type),
        videoPreview: getPreviewByItemId(state, id),
        documentWasEditedInR7: documentWasEditedInR7(state, id),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        loadPreview: (payload: LoadPreviewPaylaod) => dispatch(loadPreview(payload)),
    };
}
