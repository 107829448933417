import * as React from 'react';

import { Description, Flex, Text } from '@sbermarketing/mrm-ui';

import { useGetAuthUserQuery } from '@api';

import { onboardingItems } from '@modules/onboarding/constants';

import { OnboardingGroupCard } from '@modules/onboarding/components';

import { useSetOnboarding } from '@modules/onboarding/hooks';

import * as styles from './OnboardingPage.scss';

export function OnboardingPage() {
    const { data: authUser, isLoading: isAuthUserLoading } = useGetAuthUserQuery();
    const setOnboarding = useSetOnboarding();

    React.useEffect(() => {
        setOnboarding(undefined);
    }, []);

    const firstName = authUser?.user.attributes.firstName;
    const secondName = authUser?.user.attributes.secondName;

    return (
        <Flex className={styles.root} justify="center" padding={[40, 32, 0, 80]}>
            <Flex gap={24} flex vertical className={styles.wrapper}>
                <Flex align="flex-start" vertical gap={8}>
                    <Text loading={isAuthUserLoading} size={20}>
                        {firstName} {secondName}, добро пожаловать!
                    </Text>
                    <Description loading={isAuthUserLoading} size={14}>
                        С чего вы хотите начать?
                    </Description>
                </Flex>
                <Flex vertical gap={20}>
                    {onboardingItems.map((group) => (
                        <OnboardingGroupCard key={group.title} onboardingGroup={group} />
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
}
