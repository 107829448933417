import * as React from 'react';

import { FileDescription } from '@store/autopilot/creativeForm';
import { FileAsset } from './FileAsset';

import * as styles from './styles.scss';

interface Props {
    files: (FileDescription & { error: string } & { warning: string })[];
    onRemove: (fileId: string) => void;
}

export const FileAssetList: React.FC<Props> = ({ files, onRemove }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>Загруженные файлы</div>
            <div className={styles.list}>
                {files.map((file) => (
                    <FileAsset
                        key={file.id}
                        file={file}
                        error={file.error}
                        warning={file.warning}
                        onRemove={() => onRemove(file.id)}
                    />
                ))}
            </div>
        </div>
    );
};
