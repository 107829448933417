import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getFormSpecs, setSelectedSpecId, getSelectedFormSpecId } from '@store/autopilot/creativeForm';
import type { Option, OnChange } from '../../../../../../common';

interface UseOptions {
    (): Return;
}

interface Return {
    defaultValue: Option;
    options: Option[];
    onChange: OnChange;
}

export const useOptions: UseOptions = () => {
    const dispatch = useDispatch();

    const formSpecs = useSelector((state: StoreState) => getFormSpecs(state));
    const selectedFormSpecId = useSelector((state: StoreState) => getSelectedFormSpecId(state));

    const onChange: OnChange = React.useCallback(({ value }: Option) => {
        dispatch(setSelectedSpecId({ selectedSpecId: value }));
    }, []);

    const options = formSpecs.map(({ meta: { creativeGroupSpecId }, title }) => ({
        value: creativeGroupSpecId,
        label: title,
    }));

    const defaultValue = selectedFormSpecId
        ? {
              value: selectedFormSpecId,
              label: formSpecs.find(({ meta: { creativeGroupSpecId } }) => creativeGroupSpecId === selectedFormSpecId)
                  ?.title,
          }
        : null;

    return {
        options,
        defaultValue,
        onChange,
    };
};
