import * as React from 'react';
import { isEmpty } from 'lodash';

import { Icon, IconType } from 'sber-marketing-ui';
import { Textarea } from '../Textarea';

import * as styles from './styles.scss';

interface Props {
    value?: string;
    error?: string;
    placeholder?: string;
    maxLength?: number;
    autoFocus?: boolean;
    onChange?: (value: string) => void;
    onFocus?: (value: string) => void;
    onBlur?: (value: string) => void;
}

export const TextInput: React.FC<Props> = ({
    value: outsideValue = '',
    error = '',
    placeholder,
    maxLength,
    autoFocus = false,
    onChange: outsideChangeHandler = () => {},
    onFocus: outsideFocusHandler = () => {},
    onBlur: outsideBlurHandler = () => {},
}) => {
    const [isEdit, setEdit] = React.useState(false);
    const [value, setValue] = React.useState(outsideValue);

    const onClearButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setValue('');
        outsideChangeHandler('');
    }, [outsideChangeHandler]);

    const onFocus = React.useCallback(() => {
        setEdit(true);
        outsideFocusHandler(value.trim());
        setValue(value.trim());
    }, [value, outsideFocusHandler]);

    const onBlur = React.useCallback(() => {
        setEdit(false);
        setValue(value.trim());
        outsideBlurHandler(value.trim());
    }, [value, outsideBlurHandler]);

    const onChange = React.useCallback(
        (value: string) => {
            setValue(value);
            outsideChangeHandler(value);
        },
        [outsideChangeHandler],
    );

    return (
        <div>
            <div className={styles.root}>
                <Textarea
                    value={value}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {!isEdit && (
                    <div className={styles.actions}>
                        <div className={styles.button} onClick={onClearButtonClick}>
                            <Icon type={IconType.ERASER} />
                        </div>
                    </div>
                )}
            </div>

            {!isEmpty(error) && !isEdit && <div className={styles.error}>{error}</div>}
        </div>
    );
};
