// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-Table-_Table__root{border-radius:6px;box-shadow:0px 2px 6px 0px rgba(0,0,0,0.1);background-color:#e6edf1}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-Table-_Table__expandButton{display:flex;align-items:center;width:100%;height:20px;padding:0 8px;color:#446fbc;font-family:'Open Sans';font-weight:600;font-size:10px;line-height:26px;user-select:none;cursor:pointer}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-Table-_Table__expandButtonIcon{display:flex;align-items:center;justify-content:center;width:16px;height:16px;margin-left:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/ExecitionIdWidget/ExecitionIdTable/Table/Table.scss"],"names":[],"mappings":"AAAA,2HACI,iBAAkB,CAClB,0CAA+C,CAC/C,wBAAyB,CAC5B,mIAGG,YAAa,CACb,kBAAmB,CACnB,UAAW,CACX,WAAY,CACZ,aAAc,CACd,aAAc,CACd,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,gBAAiB,CACjB,cAAe,CAClB,uIAGG,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,UAAW,CACX,WAAY,CACZ,eAAgB","sourcesContent":[".root {\n    border-radius: 6px;\n    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);\n    background-color: #e6edf1;\n}\n\n.expandButton {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 20px;\n    padding: 0 8px;\n    color: #446fbc;\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 10px;\n    line-height: 26px;\n    user-select: none;\n    cursor: pointer;\n}\n\n.expandButtonIcon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 16px;\n    height: 16px;\n    margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-Table-_Table__root",
	"expandButton": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-Table-_Table__expandButton",
	"expandButtonIcon": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-Table-_Table__expandButtonIcon"
};
export default ___CSS_LOADER_EXPORT___;
