import * as React from 'react';
import { values } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { UserCard } from 'sber-marketing-types/frontend';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { RoleId } from 'sber-marketing-types/backend';

import { DepartmentApi } from '../../../../../api/DepartmentApiNew';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import { getAppUsers } from '@store/appUsers';
import { loadUserOrganizations, getUserOrganizationsIds } from '@store/userOrganizations';

import { UsersTab, UserItem } from '@common/SidebarWithTabs/common/UsersTab';
import { UserSelectStrategy } from '@common/UsersDropdown';

interface Props {
    taskContent: OneTaskResponseParams;
    taskRefetchInProgress: boolean;
    updateParticipants: (particiopantIds: number[]) => Promise<void>;
}

function useUsersToRemoveCheck(taskContent: OneTaskResponseParams) {
    const appUsers = useSelector((state: StoreState) => getAppUsers(state).entities);

    const loginnedUser = useSelector((state: StoreState) => getLoginUser(state));
    const isLoginUserSupervisor = useSelector((state: StoreState) =>
        getLoginUser(state).attributes.roles.some((role) => role.id === RoleId.Supervisor),
    );
    const isLoginUserDirector = useSelector((state: StoreState) =>
        getLoginUser(state).attributes.roles.some((role) => role.id === RoleId.Director),
    );

    const [allowSupervisorToDeleteParticipant, setAllowSupervisorToDeleteParticipant] = React.useState(false);

    const taskAuthor = taskContent?.authorId;
    const taskExecutor = taskContent?.executorId;

    React.useEffect(() => {
        async function worker() {
            if (isLoginUserSupervisor) {
                const users = values(appUsers);

                const subdepartmentIds = (
                    await DepartmentApi.getListByParent(loginnedUser.attributes.departmentId)
                ).map((department) => department.id);
                const usersBySubdepartment = users
                    .filter((user) => subdepartmentIds.includes(user.departmentId))
                    .map((user) => user.id);

                setAllowSupervisorToDeleteParticipant(
                    usersBySubdepartment.some((user) => user === taskAuthor || user === taskExecutor),
                );
            }
        }

        worker();
    }, []);

    const canRemoveUser = React.useCallback(
        (user: UserCard): boolean => {
            const userId = user.id;
            const loginnedUserId = loginnedUser.attributes.id;
            const participant = taskContent?.participants?.find((participant) => participant.userId === userId);

            if (!participant) {
                return true;
            } else if (participant.userId === taskAuthor || participant.userId === taskExecutor) {
                return false;
            } else if (isLoginUserDirector) {
                return true;
            }

            return (
                allowSupervisorToDeleteParticipant ||
                loginnedUserId === taskAuthor ||
                loginnedUserId === taskExecutor ||
                loginnedUserId === participant.invitingUserId
            );
        },
        [taskContent, allowSupervisorToDeleteParticipant],
    );

    return canRemoveUser;
}

function useUsersDropdownStrategy({ taskContent }: Props) {
    const userOrganizationsIds = useSelector(getUserOrganizationsIds);

    const usersDropdownStrategy = React.useMemo((): UserSelectStrategy => {
        return (user) => {
            return (
                user.id !== taskContent?.author?.id &&
                user.id !== taskContent?.executorId &&
                userOrganizationsIds.includes(user.organizationId)
            );
        };
    }, [taskContent?.author, taskContent?.executorId, userOrganizationsIds]);

    return usersDropdownStrategy;
}

function useParticipantsTab(props: Props) {
    const dispatch = useDispatch();

    const { taskContent } = props;

    const items: UserItem[] = React.useMemo(
        () => taskContent?.participants?.map((participant) => ({ id: participant.userId })) || [],
        [taskContent],
    );

    const usersToUseStrategy = useUsersDropdownStrategy(props);
    const canRemoveUser = useUsersToRemoveCheck(taskContent);
    const usersToRemoveStrategy = React.useCallback((user: UserCard) => canRemoveUser(user), [taskContent]);

    React.useEffect(function onMoutned() {
        dispatch(loadUserOrganizations(null));
    }, []);

    return {
        items,
        usersToUseStrategy,
        usersToRemoveStrategy,
    };
}

export function ParticipantsTab(props: Props): JSX.Element {
    const { items, usersToUseStrategy, usersToRemoveStrategy } = useParticipantsTab(props);
    const { taskContent, taskRefetchInProgress, updateParticipants } = props;

    const showPreloader = !taskContent || taskRefetchInProgress;

    return (
        <React.Fragment>
            <UsersTab
                qaId="taskSidebarParticipantsTab"
                showAddUsersButton={!showPreloader}
                showPreloader={showPreloader}
                items={items}
                usersToUseStrategy={usersToUseStrategy}
                usersToRemoveStrategy={usersToRemoveStrategy}
                updateUsers={updateParticipants}
            />
        </React.Fragment>
    );
}
