import * as React from 'react';

import * as style from './AddExecutionIdCell.scss';

import { ExecutionIdMenu } from '../../../ExecutionIdMenu';
import { CellEditorWrapper } from '../CellEditorWrapper';

interface Props {
    title: string;
    customStyle?: any;
    onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;
}

export const AddExecutionIdCell = ({ title, customStyle, onModalOpen }: Props): JSX.Element => {
    const [displayMenu, setDisplayMenu] = React.useState(false);

    function onOpenerClick() {
        setDisplayMenu(true);
    }

    function onMenuClose() {
        setDisplayMenu(false);
    }

    return (
        <div className={style.root} style={{ ...customStyle }}>
            <div className={style.opener} onClick={onOpenerClick}>
                Привязать ID исполнения
            </div>

            {displayMenu && (
                <>
                    <CellEditorWrapper>
                        <div className={style.menu}>
                            <ExecutionIdMenu onModalOpen={onModalOpen} onItemClick={onMenuClose} />
                        </div>
                    </CellEditorWrapper>

                    <div className={style.mask} onClick={onMenuClose} />
                </>
            )}
        </div>
    );
};
