import * as React from 'react';

import { CreativeCard } from './CreativeCard/CreativeCard';
import * as style from './CreativeCardList.scss';
import { CreativeRequestByBudgetEntry } from '@sbermarketing/mrm-metacom-client';

interface Props {
    isLoading: boolean;
    data: CreativeRequestByBudgetEntry[] | null;
}

export const CreativeCardList: React.FC<Partial<Props>> = ({ isLoading, data }) => {
    return (
        <div>
            {isLoading && (
                <div className={style.activityCardWrapper}>
                    <CreativeCard
                        isLoading={isLoading}
                        data={{
                            projectName: '',
                            createdAt: null,
                            customer: '',
                            acceptors: [],
                            donors: [],
                            description: '',
                            projectId: '',
                        }}
                    />
                </div>
            )}

            {!isLoading &&
                data.map((item) => (
                    <div key={item.id} className={style.activityCardWrapper}>
                        <CreativeCard isLoading={isLoading} data={item} />
                    </div>
                ))}
        </div>
    );
};
