import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '@store';
import { getLoading, LoadingStatus, load, save, isValidForm, reset } from '@store/autopilot/creativeForm';
import { getAutopilot } from '@store/autopilot/selectors';
import { TypeCreative } from 'sber-marketing-types/backend';

import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { SaveButton } from './SaveButton';
import { Popup, ClosePopupButton } from '../../../../common';

interface Props {
    specId: string;
    onClose: () => void;
    onSave: (creative: TypeCreative) => void;
}

export const CreativeModal: React.FC<Props> = ({ specId, onSave, onClose }) => {
    const dispatch = useDispatch();

    const loading = useSelector((state: StoreState) => getLoading(state));
    const disabled = useSelector((state: StoreState) => !isValidForm(state));
    const { id: autopilotId } = useSelector((state: StoreState) => getAutopilot(state));

    const onSaveButtonClick = React.useCallback(() => {
        dispatch(save({ autopilotId, placementId: specId })).then((creative: TypeCreative) => {
            onSave(creative);
            onClose();
        });
    }, [autopilotId]);

    React.useEffect(() => {
        dispatch(load({ specId }));
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <Popup loading={loading === LoadingStatus.LOADING} onClose={onClose}>
            <Header closeButtonElement={<ClosePopupButton onClose={onClose} />} />
            {loading === LoadingStatus.LOADED && <Content />}
            <Footer saveButtonElement={<SaveButton disabled={disabled} onClick={onSaveButtonClick} />} />
        </Popup>
    );
};
