import * as React from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, IconType } from 'sber-marketing-ui';
import { TaskStatus as ApiTaskStatus } from 'sber-marketing-types/frontend';

import { useGetActivityQuery, useGetStagesQuery, useGetTaskBriefQuery, useGetTaskQuery } from '@api';

import { DatesFormatter } from '@common/Utils';

import { useTaskStatusButtonShow } from '@modules/task/hooks';

import { checkIfTaskStatusAvailableChange } from '@modules/task/utils';

import { Details, Field, Fieldset, Scrollbar, Tag } from '@common/components';
import {
    AddTaskTagSelector,
    TaskBudgetDetails,
    TaskFilesDetails,
    TaskTags,
    TaskTitleTag,
} from '@modules/task/components';
import { ActivityTaskPageParams } from '@modules/task/pages';
import {
    TaskBrief,
    TaskDeadline,
    TaskDescription,
    TaskExecutor,
    TaskStageTag,
    TaskStatus,
    TaskInProgressButton,
    TaskSubscriptionToggle,
    TaskTelegramToggle,
} from '@modules/task/components';

import * as styles from './TaskPageInfo.scss';

export function TaskPageInfo() {
    const { taskId, activityId: activityIdString } = useParams<ActivityTaskPageParams>();
    const activityId = Number(activityIdString);
    const { data: activity, isLoading: isActivityLoading } = useGetActivityQuery({ id: activityId });
    const { isLoading: isBriefLoading } = useGetTaskBriefQuery({ id: taskId });
    const { data: task, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const { isLoading: isStagesLoading, data: stages } = useGetStagesQuery(
        { activityIds: [activityId] },
        { refetchOnMountOrArgChange: true },
    );
    const isTaskHelperShow = useTaskStatusButtonShow(taskId);
    const [askRequired, setAskRequired] = React.useState(false);
    const isShowStage = Boolean(activity?.needStages) && Boolean(stages?.[0]?.stages);
    const isLoading = isTaskLoading || isActivityLoading || isBriefLoading || isStagesLoading;
    const isDeadlineRequired = !task?.task.deadline;
    const isExecutorRequired =
        task?.task.status === ApiTaskStatus.Draft &&
        !checkIfTaskStatusAvailableChange(ApiTaskStatus.InProgress, task?.task);

    const stagesElement = isShowStage && !isLoading && (
        <Field label="Этап проекта">
            <TaskStageTag className={styles.stage} ghost taskId={taskId} activityId={activityId} />
        </Field>
    );

    const downElements = !isLoading && (
        <>
            <Field loading={isLoading}>
                <TaskFilesDetails className={styles.filesDetails} taskId={taskId} />
            </Field>
            <Field loading={isLoading} vertical label="Теги">
                <div className={styles.tags}>
                    <TaskTags taskId={taskId} />
                    <AddTaskTagSelector autoPlace="vertical" placeholder="+ Добавить" ghost taskId={taskId} />
                </div>
            </Field>
        </>
    );

    return (
        <Scrollbar overflow="hidden auto" gutter="stable" className={styles.root}>
            <TaskTitleTag
                inputClassName={styles.taskTitleInput}
                className={styles.taskTitle}
                loading={isLoading}
                aligned
                taskId={taskId}
                ghost
            />
            <Fieldset className={styles.fieldset}>
                {stagesElement}
                <Field
                    loading={isLoading}
                    className={styles.executor}
                    relative
                    label="Исполнитель"
                    required={isExecutorRequired}
                >
                    <TaskExecutor
                        className={classNames(askRequired && isExecutorRequired && styles.askRequired)}
                        absolute
                        stretch
                        taskId={taskId}
                        ghost
                        wrap
                        flat
                    />
                </Field>
                <TaskBudgetDetails defaultOpen taskId={taskId} organizationId={activity?.organizationId} />
                <Field loading={isLoading} className={styles.deadline} label="Дедлайн" required={isDeadlineRequired}>
                    <TaskDeadline
                        flat
                        className={classNames(askRequired && isDeadlineRequired && styles.askRequired)}
                        taskId={taskId}
                    />
                </Field>
                <Field loading={isLoading} className={styles.statusField} label="Статус">
                    <div className={styles.status}>
                        <TaskStatus flat onAskRequired={() => setAskRequired(true)} taskId={taskId} />
                        {isTaskHelperShow && (
                            <>
                                <Icon className={styles.arrow} svgSize={16} type={IconType.ARROW16_STRAIGHT_LEFT} />
                                <TaskInProgressButton onAskRequired={() => setAskRequired(true)} taskId={taskId} />
                            </>
                        )}
                    </div>
                </Field>
                <Field className={styles.briefField} loading={isLoading} label="Бриф задачи">
                    <TaskBrief aligned taskId={taskId} />
                </Field>
                <Field className={styles.notifications} loading={isLoading} label="Уведомления">
                    <TaskSubscriptionToggle taskId={taskId}>Почта</TaskSubscriptionToggle>
                    <TaskTelegramToggle taskId={taskId}>Telegram</TaskTelegramToggle>
                </Field>
                <Field className={styles.createDate} loading={isLoading} label="Дата создания">
                    <Tag data-qa-id="TaskCreationDate" ghost aligned>
                        {task && DatesFormatter.ddMonthyyyy(task.task.createTime)}
                    </Tag>
                </Field>
                <Field
                    loading={isLoading}
                    className={classNames(styles.descriptionField, isLoading && styles.descriptionFieldLoading)}
                >
                    <Details
                        data-qa-id="TaskPageInfo__description"
                        summary="Описание"
                        defaultOpen
                        className={styles.descriptionDetails}
                    >
                        <TaskDescription className={styles.description} ghost taskId={taskId} />
                    </Details>
                </Field>
                {downElements}
            </Fieldset>
        </Scrollbar>
    );
}
