import * as React from 'react';

import { DeleteIcon, BaseIconProps } from '@sbermarketing/mrm-ui';

import { RemoveTaskParticipantModal } from '@modules/task/components';

export interface DeleteTaskParticipantIconProps extends Partial<BaseIconProps> {
    taskId: string;
    userId: number;
    onRemove?: () => void;
}

export function DeleteTaskParticipantIcon({
    taskId,
    onClick,
    userId,
    onRemove,
    ...props
}: DeleteTaskParticipantIconProps) {
    const [show, setShow] = React.useState(false);

    const handleClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
    };

    const handleShow: React.MouseEventHandler<SVGSVGElement> = (e) => {
        handleClick(e);
        setShow(true);
    };

    const handleHide = () => {
        setShow(false);
    };

    const handleRemove = () => {
        handleHide();
        onRemove?.();
    };

    return (
        <>
            <DeleteIcon
                data-qa-id="DeleteTaskParticipantIcon"
                color="var(--color-gray-50)"
                danger
                {...props}
                onClick={handleShow}
            />
            <RemoveTaskParticipantModal
                onClick={handleClick}
                hide={!show}
                taskId={taskId}
                userId={userId}
                onRemove={handleRemove}
                onCancel={handleHide}
            />
        </>
    );
}
