// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__root{display:block}.src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__title{font-family:'Open Sans';font-weight:600;font-size:14px;line-height:19px;color:#000000;user-select:none}.src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__requiredIndicator{color:#e63900}.src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__description{font-family:'Open Sans';font-size:12px;line-height:16px;color:#7e8681;letter-spacing:0.15px;margin-top:4px}.src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__input{margin-top:16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/PlacementList/Fields/StringIdField/styles.scss"],"names":[],"mappings":"AAAA,8FACI,aAAc,CACjB,+FAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACd,gBAAiB,CACpB,2GAGG,aAAc,CACjB,qGAGG,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACd,qBAAsB,CACtB,cAAe,CAClB,+FAGG,eAAgB","sourcesContent":[".root {\n    display: block;\n}\n\n.title {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 19px;\n    color: #000000;\n    user-select: none;\n}\n\n.requiredIndicator {\n    color: #e63900;\n}\n\n.description {\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    color: #7e8681;\n    letter-spacing: 0.15px;\n    margin-top: 4px;\n}\n\n.input {\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__root",
	"title": "src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__title",
	"requiredIndicator": "src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__requiredIndicator",
	"description": "src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__description",
	"input": "src-client-modules-activity-pages-autopilot-PlacementList-Fields-StringIdField-_styles__input"
};
export default ___CSS_LOADER_EXPORT___;
