import * as React from 'react';
import classNames from 'classnames';

import { MultiReferenceDictionaryApi } from '@api';

import * as style from './BudgetList.scss';

import { FormData } from '@store/plannedBudgetEdit/types';

import { BudgetCard } from './BudgetCard';

export interface BudgetListProps {
    activityBudgetId: string;
    budgetId: string;
    forms: FormData[];
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    onFinishPasteCopiedBrief: () => Promise<void>;
}

export const BudgetList = ({
    activityBudgetId,
    budgetId,
    forms,
    multiReferenceDictionaryApi,
    onFinishPasteCopiedBrief,
}: BudgetListProps): JSX.Element => {
    return (
        <div className={style.root}>
            {forms.map((item, index) => (
                <div key={item.id} className={classNames(style.budgetCard, 'planningSection', `budget${index}`)}>
                    <BudgetCard
                        activityBudgetId={activityBudgetId}
                        budgetId={budgetId}
                        id={item.id}
                        index={index}
                        fields={item.fields}
                        collapsed={item.collapsed}
                        deleted={item.deleted}
                        isNew={item.isNew}
                        canBeCollapsed={!item.deleted}
                        canBeDeleted={forms.length > 1}
                        multiReferenceDictionaryApi={multiReferenceDictionaryApi}
                        onFinishPasteCopiedBrief={onFinishPasteCopiedBrief}
                    />
                </div>
            ))}
        </div>
    );
};
