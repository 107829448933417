import * as React from 'react';
import classNames from 'classnames';

import * as style from './ExpandableCell.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    title: string;
    isExpanded: boolean;
    customStyle?: any;
    onClick: () => void;
}

export const ExpandableCell = ({ title, isExpanded, customStyle, onClick }: Props): JSX.Element => {
    return (
        <div
            className={classNames(style.root, !!onClick && style.clickable)}
            style={{ ...customStyle }}
            onClick={onClick}
        >
            <div className={style.title}>{title}</div>

            {!!onClick && (
                <div className={style.expandButton}>
                    <Icon type={isExpanded ? IconType.ARROW8_UP : IconType.ARROW8_DOWN} svgSize={8} />
                </div>
            )}
        </div>
    );
};
