import * as React from 'react';

import { TaskCommentTransferObject } from 'sber-marketing-types/backend';
import { UserConfigType } from 'sber-marketing-types/openid';

import { useGetUserConfigQuery } from '@api';

import { DatesFormatter } from '@common/Utils';

import { Divider, Flex } from '@common/components';

import { useGetTaskChannel } from '@modules/task/hooks';

import { Comment } from '@modules/comment/components';

export interface TaskChatContentProps {
    taskId: string;
    channelId: number;
    favourite?: boolean;
    onEditComment?: (comment: TaskCommentTransferObject) => void;
    onReplay?: () => void;
}

export function ChatContent({ taskId, channelId, favourite, onEditComment, onReplay }: TaskChatContentProps) {
    const { data: chatConfig } = useGetUserConfigQuery(UserConfigType.Chat);
    const { channel } = useGetTaskChannel(taskId, channelId);
    const comments = channel?.comments || [];
    const config = chatConfig?.config.configData ? JSON.parse(chatConfig?.config.configData) : {};
    const invert = Boolean(config.invert);
    const replayDeps = [comments, invert, favourite];
    const replayMap: Record<string, TaskCommentTransferObject[]> = React.useMemo(() => ({}), replayDeps);
    let dividerDate: string;

    const commentsByDate: { comments: TaskCommentTransferObject[]; date: string }[] = React.useMemo(() => {
        const result = comments.reduce((result, comment) => {
            const date = DatesFormatter.ddMonthyyyy(comment.createTime, {
                withTodayMarker: true,
            });

            if (comment.replyId) {
                if (comment.replyId in replayMap) {
                    replayMap[comment.replyId].push(comment);
                } else {
                    replayMap[comment.replyId] = [comment];
                }
            } else {
                if (favourite && !comment.isFavorite) {
                    return result;
                }

                if (date !== dividerDate) {
                    result.push({ comments: [comment], date });
                    dividerDate = date;
                } else {
                    result[result.length - 1].comments.push(comment);
                }
            }

            return result;
        }, []);

        return invert ? result.reverse() : result;
    }, replayDeps);

    return (
        <>
            {commentsByDate.map(({ comments, date }) => (
                <Flex vertical reverse key={date}>
                    <Flex reverse={invert} vertical gap={8} padding={[0, 12]}>
                        {comments.map((comment) => (
                            <Comment
                                reverse={invert}
                                clickable
                                key={comment.id}
                                comment={comment}
                                subComments={replayMap[comment.id]}
                                onEdit={onEditComment}
                                onReplay={onReplay}
                            />
                        ))}
                    </Flex>
                    <Divider legendProps={{ 'data-qa-id': 'ChatContent__date' } as any} accent sticky>
                        {date}
                    </Divider>
                </Flex>
            ))}
        </>
    );
}
