import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import * as style from './MediaplanSettingsStageWidget.scss';

import type { StoreState } from '@store';

import { LabeledSelect } from 'sber-marketing-ui';
import { RadiobuttonGroup } from './RadiobuttonGroup';
import { RelativeConversionRate } from './RelativeConversionRate';
import { BudgetAllocation } from './BudgetAllocation';
import { setMediaplanSettingsFormValues } from '@store/autopilot/actions';
import { getBriefStageForm, getMediaplanSettingsForm } from '@store/autopilot/selectors';
import { Money, MoneyFormatter } from '@common/Utils';

interface Props {
    readOnly?: boolean;
}

export const MediaplanSettingsStageWidget = ({ readOnly }: Props): JSX.Element => {
    const mediaplanForm = useSelector((state: StoreState) => getMediaplanSettingsForm(state));
    const briefForm = useSelector((state: StoreState) => getBriefStageForm(state));

    const dispatch = useDispatch();

    const onYearCompaniesChange = React.useCallback((value: boolean) => {
        dispatch(setMediaplanSettingsFormValues({ hasYearCompanies: value }));
    }, []);

    const onConversionRateChange = React.useCallback((value: number) => {
        dispatch(setMediaplanSettingsFormValues({ conversion: value }));
    }, []);

    const onManuallySetAllocationChange = React.useCallback((value: boolean) => {
        dispatch(setMediaplanSettingsFormValues({ isManuallySet: value }));
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>Параметры расчёта</div>

            <div className={style.form}>
                <div className={classNames(style.field, style.line)}>
                    <LabeledSelect
                        title={'Регион'}
                        items={[
                            {
                                label: 'Вся Россия',
                                value: 1,
                            },
                        ]}
                        selectedValue={1}
                        onSelection={() => {}}
                        readOnly={readOnly}
                        disabled
                    />
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>По данному продукту есть годовые рекламные кампании?</div>

                    <RadiobuttonGroup
                        items={[
                            {
                                title: 'Есть годовые кампании',
                                value: true,
                            },
                            {
                                title: 'Нет годовых кампаний',
                                value: false,
                            },
                        ]}
                        selectedValue={mediaplanForm.hasYearCompanies}
                        onSelection={onYearCompaniesChange}
                        disabled={readOnly}
                    />
                </div>

                <div className={style.line}>
                    <div className={style.smallField}>
                        <RelativeConversionRate
                            value={mediaplanForm.conversion}
                            minValue={-100}
                            maxValue={200}
                            onValueChange={onConversionRateChange}
                            disabled={readOnly}
                        />
                    </div>

                    <div className={style.smallField}>
                        <LabeledSelect
                            title={'Указано значение относительно кампании'}
                            items={[
                                {
                                    label: 'Среднее на исторических данных',
                                    value: 1,
                                },
                            ]}
                            selectedValue={1}
                            onSelection={() => {}}
                            readOnly={readOnly}
                            disabled
                        />
                    </div>
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Рассчет медиаплана от общего бюджета</div>

                    <div className={style.budgetValue}>
                        {formatMoney(briefForm.budget)}
                        <span className={style.budgetCurrency}>₽</span>
                    </div>
                </div>

                <div className={classNames(style.field, style.line)}>
                    <div className={style.fieldTitle}>Распределение бюджета</div>

                    <RadiobuttonGroup
                        items={[
                            {
                                title: 'Справочные значения',
                                value: false,
                            },
                            {
                                title: 'Настроить вручную',
                                value: true,
                            },
                        ]}
                        selectedValue={mediaplanForm.isManuallySet}
                        onSelection={onManuallySetAllocationChange}
                        disabled={readOnly}
                    />
                </div>

                <div className={classNames(style.field, style.line)}>
                    <BudgetAllocation readOnly={readOnly} />
                </div>
            </div>
        </div>
    );
};

function formatMoney(value: string): string {
    return MoneyFormatter.toRoubles(Money.fromRoubles(parseInt(value, 10)), { hideCaption: true });
}
