import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './FiltersColumnHeader.scss';

import type {
    ColumnName,
    Filter,
    // SortingParams
} from '../../types';

import { Icon, IconType, BodyPortal, FilterItem, WithTooltip } from 'sber-marketing-ui';
import { ColumnHeaderEditorWrapper } from '../ColumnHeaderEditorWrapper';
// import { SortingMenu } from './SortingMenu';
import { ColumnFixButton } from './ColumnFixButton';
import { Filters } from './Filters';
import { LineType } from '../../ColumnsConfig';

interface Props {
    lot: 1 | 2;
    title: string;
    columnName: ColumnName;
    disableSorting?: boolean;
    getFixedColumns: () => Record<'lot1' | 'lot2', ColumnName[]>;
    setFixedColumns: (fixedColumns: ColumnName[], lot: 1 | 2) => void;
    // getSortingParams: () => SortingParams;
    // setTableSorting: (sortingParams: SortingParams) => void;
    makeFilterItems: () => Promise<Record<LineType, FilterItem[]>>;
    getFilters: () => Filter[];
    setFilters: (filters: Filter[]) => void;
}

export const FiltersColumnHeader = ({
    lot,
    title,
    columnName,
    disableSorting,
    getFixedColumns,
    setFixedColumns,
    // getSortingParams,
    // setTableSorting,
    makeFilterItems,
    getFilters,
    setFilters,
}: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);
    const [filterItems, setFilterItems] = React.useState({} as Record<LineType, FilterItem[]>);

    const filters = getFilters() || [];

    React.useEffect(() => {
        if (isOpened) {
            updateFilterItems();
        }
    }, [isOpened]);

    async function updateFilterItems() {
        const newFilterItems = await makeFilterItems();

        setFilterItems(newFilterItems);
    }

    const onOpenerClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
    }, [isOpened]);

    const onMaskClick = React.useCallback(() => {
        setOpenedStatus(false);
    }, []);

    const fixedColumns = getFixedColumns()[`lot${lot}`] || [];

    const isFixed = fixedColumns.includes(columnName);

    const onFixButtonClick = React.useCallback(() => {
        const updatedFixedColumns = lodash.xor(fixedColumns, [columnName]);

        setFixedColumns(updatedFixedColumns, lot);
    }, [fixedColumns]);

    const isFiltered = lodash.some(getFilters(), (filter) => filter.columnName === columnName);

    return (
        <div className={classNames(style.root, isOpened && style.opened, isFiltered && style.filtered)}>
            <div className={style.opener} onClick={onOpenerClick}>
                <WithTooltip className={style.openerContent} content={title}>
                    <>
                        <div className={style.openerTitle}>{title}</div>

                        <div className={style.openerIcon}>
                            <Icon type={isOpened ? IconType.ARROW8_UP : IconType.ARROW8_DOWN} />
                        </div>
                    </>
                </WithTooltip>
            </div>

            {isOpened && (
                <ColumnHeaderEditorWrapper>
                    <div className={style.content}>
                        <div className={style.title}>{title}</div>

                        <div className={style.fixButton}>
                            <ColumnFixButton isColumnFixed={isFixed} onClick={onFixButtonClick} />
                        </div>

                        <div className={style.separator} />

                        {/* {!disableSorting &&
                            <>
                                <SortingMenu
                                    columnName={columnName}
                                    getSortingParams={getSortingParams}
                                    setTableSorting={setTableSorting}
                                />

                                <div className={style.separator} />
                            </>
                        } */}

                        {!lodash.isEmpty(filterItems[LineType.Creative]) && (
                            <div className={style.filters}>
                                <Filters
                                    title={'Фильтр строк ЗНК'}
                                    columnName={columnName}
                                    items={alphabetSorting(filterItems[LineType.Creative])}
                                    filters={filters}
                                    setFilters={setFilters}
                                    isExpanded={lodash.isEmpty(filterItems[LineType.Assignment])}
                                />
                            </div>
                        )}

                        {!lodash.isEmpty(filterItems[LineType.Assignment]) && (
                            <div className={style.filters}>
                                <Filters
                                    title={'Фильтр строк поручений'}
                                    columnName={columnName}
                                    items={alphabetSorting(filterItems[LineType.Assignment])}
                                    filters={filters}
                                    setFilters={setFilters}
                                    isExpanded={lodash.isEmpty(filterItems[LineType.Creative])}
                                />
                            </div>
                        )}
                    </div>
                </ColumnHeaderEditorWrapper>
            )}

            {isOpened && (
                <BodyPortal>
                    <div className={style.mask} onClick={onMaskClick} />
                </BodyPortal>
            )}
        </div>
    );
};

const alphabetSorting = (array: FilterItem[]) => {
    return array.sort(function (a, b) {
        if (a.title > b.title) {
            return 1;
        }
        if (a.title < b.title) {
            return -1;
        }
        return 0;
    });
};
