// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-buttons-CreateButton-_styles__text{font-family:'Open Sans';font-weight:600;font-size:14px;letter-spacing:0.15px;color:#000000}.src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-buttons-CreateButton-_styles__icon{font-size:18px;margin-top:-3px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/StageWidgets/ContextStageWidget/buttons/CreateButton/styles.scss"],"names":[],"mappings":"AAAA,gHACI,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,qBAAsB,CACtB,aAAc,CACjB,gHAGG,cAAe,CACf,eAAgB","sourcesContent":[".text {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    letter-spacing: 0.15px;\n    color: #000000;\n}\n\n.icon {\n    font-size: 18px;\n    margin-top: -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-buttons-CreateButton-_styles__text",
	"icon": "src-client-modules-activity-pages-autopilot-StageWidgets-ContextStageWidget-buttons-CreateButton-_styles__icon"
};
export default ___CSS_LOADER_EXPORT___;
