import * as moment from 'moment';

const buildLinkParamsFromTableData = (data: string[][]): { href: string; download: string } => {
    return {
        href: buildHrefCSVFromData(data),
        download: `контекстная_реклама_${moment().format('MM/DD/YYYY')}`,
    };
};

const buildHrefCSVFromData = (data: string[][]) => {
    return URL.createObjectURL(buildBlobCSVFromData(data));
};

const buildBlobCSVFromData = (data: string[][]) => {
    const content = builtContentCSVFromData(data);
    return new Blob([content], { type: 'text/csv' });
};

const builtContentCSVFromData = (data: string[][]) => {
    return data.reduce((result, line) => {
        const contentLine = line.reduce((result, cell, index) => {
            return `${result}${index ? ',' : ''}"${cell.replace(/"/g, '""')}"`;
        }, '');
        return `${result}${contentLine}\n`;
    }, '');
};

export { buildLinkParamsFromTableData };
