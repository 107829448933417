import * as React from 'react';

import * as styles from './Amount.scss';

import { formatAmount } from '../utils';

interface Props {
    capexAmount: number | null;
    opexAmount: number;
}

export const Amount = ({ capexAmount, opexAmount }: Props): JSX.Element => {
    return (
        <div className={styles.root}>
            <div className={styles.header}>Вносимый резерв (без НДС / с НДС):</div>
            <div className={styles.amounts}>
                <span className={styles.bold}>{formatAmount(opexAmount)}</span> ₽ /{' '}
                <span className={styles.bold}>{formatAmount(capexAmount)}</span> ₽
            </div>
        </div>
    );
};
