import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './ExecitionIdTable.scss';

import type { ClientData } from '../hooks';

import { EmptySourseLinesMessage } from './EmptySourseLinesMessage';
import { ExecutionIdButton } from './ExecutionIdButton';
import { Table } from './Table';

interface Props {
    capex?: boolean;
    clientData: ClientData;
    userRoles: number[];
    onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;
}

export const ExecitionIdTable: React.FC<Props> = ({ capex, clientData, userRoles, onModalOpen }) => {
    const {
        creativeRequest,
        creativeRequestItems,
        creativeRequestBudgetItems,
        groupedCreativeRequestBudgetItems,
        projectBudgetItems,
        groupedProjectBudgetItems,
        corrections,
        groupedCorrections,
        project,
        contracts,
        grouppedContracts,
    } = clientData;

    const userHasRights = [29, 31].some((roleId) => userRoles.includes(roleId));

    const sourseLines = groupedCreativeRequestBudgetItems;
    const executionLines = groupedProjectBudgetItems;

    const sourseOpexIsEmpty = lodash.isEmpty(sourseLines['opex']);
    const sourseCapexIsEmpty = lodash.isEmpty(sourseLines['capex']);
    const executionOpexIsEmpty = lodash.isEmpty(executionLines['opex']);
    const executionCapexIsEmpty = lodash.isEmpty(executionLines['capex']);

    const sourseIsEmpty = lodash.isEmpty(creativeRequestBudgetItems);
    const executionIsEmpty = lodash.isEmpty(projectBudgetItems);

    if (!capex && sourseIsEmpty && executionIsEmpty) {
        return <EmptySourseLinesMessage />;
    }

    const displayTable =
        userHasRights &&
        (capex ? !sourseCapexIsEmpty || !executionCapexIsEmpty : !sourseOpexIsEmpty || !executionOpexIsEmpty);

    if (!displayTable) {
        return null;
    }

    const budgetItems = groupedProjectBudgetItems[capex ? 'capex' : 'opex'];

    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div className={style.title}>ID исполнения</div>

                {capex && <div className={style.capexIndicator}>CAPEX</div>}

                {!lodash.isEmpty(budgetItems) && (
                    <div className={style.addIdButton}>
                        <ExecutionIdButton onModalOpen={onModalOpen} />
                    </div>
                )}
            </div>

            <Table
                capex={capex}
                creativeRequest={creativeRequest}
                creativeRequestItems={creativeRequestItems}
                budgetItems={budgetItems}
                corrections={corrections}
                groupedCorrections={groupedCorrections}
                project={project}
                contracts={contracts}
                grouppedContracts={grouppedContracts}
                onModalOpen={onModalOpen}
            />
        </div>
    );
};
