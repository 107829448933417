// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot_legacy-StageActions-BriefStageActions-Button-_styles__root{position:relative;width:164px;height:32px;flex:none}.src-client-modules-activity-pages-autopilot_legacy-StageActions-BriefStageActions-Button-_styles__preloaderContainer{position:absolute;top:0;left:0;bottom:0;right:0;background-color:rgba(255,255,255,0.7)}.src-client-modules-activity-pages-autopilot_legacy-StageActions-BriefStageActions-Button-_styles__preloader{background-color:transparent;transform:scale(0.6)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot_legacy/StageActions/BriefStageActions/Button/styles.scss"],"names":[],"mappings":"AAAA,wGACI,iBAAkB,CAElB,WAAY,CACZ,WAAY,CACZ,SAAU,CACb,sHAGG,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,QAAS,CACT,OAAQ,CACR,sCAAyC,CAC5C,6GAGG,4BAA6B,CAC7B,oBAAqB","sourcesContent":[".root {\n    position: relative;\n\n    width: 164px;\n    height: 32px;\n    flex: none;\n}\n\n.preloaderContainer {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background-color: rgba(256, 256, 356, .7);\n}\n\n.preloader {\n    background-color: transparent;\n    transform: scale(0.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot_legacy-StageActions-BriefStageActions-Button-_styles__root",
	"preloaderContainer": "src-client-modules-activity-pages-autopilot_legacy-StageActions-BriefStageActions-Button-_styles__preloaderContainer",
	"preloader": "src-client-modules-activity-pages-autopilot_legacy-StageActions-BriefStageActions-Button-_styles__preloader"
};
export default ___CSS_LOADER_EXPORT___;
