import * as React from 'react';

import * as style from './FundsWithButtonCell.scss';

import { ContextMenu } from '../ContextMenu';

interface Props {
    title: string;
    buttonTitle?: string;
    customStyle?: any;
    onButtonClick: () => void;
}

export const FundsWithButtonCell = ({ title, buttonTitle, customStyle, onButtonClick }: Props): JSX.Element => {
    async function onCopyValueClick() {
        const formattedValue = title.replace(/ /g, '');

        await navigator.clipboard.writeText(`${formattedValue}`);
    }

    function onCopy(event: React.ClipboardEvent<HTMLSpanElement>) {
        const formattedValue = document.getSelection().toString().replace(/ /g, '');

        event.clipboardData.setData('text/plain', formattedValue);

        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <>
            <ContextMenu items={[{ title: 'Копировать', onClick: onCopyValueClick }]} />

            <div className={style.root} style={{ ...customStyle }}>
                <div className={style.title}>
                    <span className={style.titleCopyContent} onCopy={onCopy}>
                        {title}
                    </span>

                    <div className={style.currencySign}>₽</div>
                </div>

                {buttonTitle && (
                    <div className={style.button} onClick={onButtonClick}>
                        {buttonTitle}
                    </div>
                )}
            </div>
        </>
    );
};
