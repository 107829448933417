import * as qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { ParsedQuery } from 'query-string';
import { useCallback, useMemo } from 'react';

export function useSearch<D extends ParsedQuery>(): [D, (search: D) => void] {
    const history = useHistory();
    const locationSearch = useLocation().search;

    const search = useMemo(() => qs.parse(locationSearch) as D, [locationSearch]);

    const setSearch = useCallback(
        (search) => {
            history.push(`?${qs.stringify(search)}`);
        },
        [history],
    );

    return [search, setSearch];
}
