import * as React from 'react';

import * as style from './AddSourseCell.scss';

import { AddSourseButton } from '../../../AddSourseButton';

interface Props {
    title: string;
    customStyle?: any;
}

export const AddSourseCell = ({ title, customStyle }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            <AddSourseButton title={title} />
        </div>
    );
};
