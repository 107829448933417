import { CreativeRequest } from '@api';
import { useEffect, useState } from 'react';
import * as lodash from 'lodash';

export interface useGetParamsResult {
    contractId: string;
    params: {
        division: any;
        block: any;
        product: any;
        channel: any;
        segment: any;
        createdAt: any;
        id: any;
    }[];
}

export const useGetParams = (creativeRequest: CreativeRequest): useGetParamsResult => {
    const [params, setParams] = useState([]);
    const [data, setData] = useState([]);
    const [contractId, setContractId] = useState<string | null>(null);

    useEffect(() => {
        if (!creativeRequest) return;

        const updateContractId = () => {
            setContractId(creativeRequest.model.contractId);
        };

        const getData = async () => {
            const params = await creativeRequest.model.getParams();
            setParams([...params]);
        };

        getData();
        updateContractId();

        creativeRequest.events.onContractIdUpdated(updateContractId);
        creativeRequest.events.onParamsAdded(getData);
        creativeRequest.events.onParamsRemoved(getData);

        return () => {
            creativeRequest?.events.offContractIdUpdated(updateContractId);
            creativeRequest?.events.offParamsAdded(getData);
            creativeRequest?.events.offParamsRemoved(getData);
        };
    }, [creativeRequest]);

    useEffect(() => {
        const getData = async () => {
            const paramsPromises = params?.map((el) => {
                return Promise.all([
                    el.model.division,
                    el.model.block,
                    el.model.product,
                    el.model.channel,
                    el.model.segment,
                    el.model.createdAt,
                    el.model.id,
                ]);
            });

            const result = (await Promise.all(paramsPromises)).map((el) => {
                const [division, block, product, channel, segment, createdAt, id] = el;
                return {
                    division,
                    block,
                    product,
                    channel,
                    segment,
                    createdAt,
                    id,
                };
            });

            setData(lodash.sortBy(result, 'createdAt'));
        };

        getData();

        params.forEach(({ events }) => {
            events.onBlockUpdated(getData);
            events.onDivisionUpdated(getData);
            events.onSegmentUpdated(getData);
            events.onProductUpdated(getData);
            events.onChannelUpdated(getData);
        });

        return () => {
            params.forEach(({ events }) => {
                events?.offBlockUpdated(getData);
                events?.offDivisionUpdated(getData);
                events?.offSegmentUpdated(getData);
                events?.offProductUpdated(getData);
                events?.offChannelUpdated(getData);
            });
        };
    }, [params]);

    return {
        contractId,
        params: data,
    };
};
