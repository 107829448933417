import { useParams } from 'react-router-dom';

interface RouteParams {
    creativeRequestId: string;
    activityId: string;
}

export const useUrl = () => {
    const params = useParams<RouteParams>();

    const { activityId, creativeRequestId } = params;

    const projectId = parseInt(activityId, 10);

    return {
        projectId,
        creativeRequestId,
    };
};
