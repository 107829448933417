import * as React from 'react';
import classNames from 'classnames';

import * as style from './AddSubLineCell.scss';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';
import { Utils } from '@common/Utils';

interface Props {
    subLinesCount: number;
    disabled?: boolean;
    customStyle?: any;
    onButtonClick: () => void;
}

export const AddSubLineCell = ({ subLinesCount, disabled, customStyle, onButtonClick }: Props): JSX.Element => {
    return (
        <div className={style.root} style={{ ...customStyle }}>
            {subLinesCount > 0 ? (
                `${subLinesCount} ${Utils.getDeclensionByNumber(subLinesCount, ['строка', 'строки', 'строк'])}`
            ) : (
                <span className={style.noSubLinesMessage}>Нет строк</span>
            )}

            {!!onButtonClick && (
                <div
                    className={classNames(style.button, disabled && style.disabled)}
                    onClick={!disabled ? onButtonClick : null}
                >
                    {disabled ? (
                        <Icon type={IconType.PLUS_SIGN} svgSize={8} />
                    ) : (
                        <WithTooltip content={'Добавить поручение'}>
                            <Icon type={IconType.PLUS_SIGN} svgSize={8} />
                        </WithTooltip>
                    )}
                </div>
            )}
        </div>
    );
};
