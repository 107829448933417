import { FileData } from '@modules/files/types';
import { getImageFile } from '@modules/files/utils';
import { useGetDownloadLinkQuery } from '@api';

export interface UseDownloadLinkReturn {
    isLoading: boolean;
    link?: string;
}

export function useDownloadLink(file?: FileData, options?: Record<string, any>): UseDownloadLinkReturn {
    const img = file ? getImageFile(file) : undefined;

    const { data, isLoading } = useGetDownloadLinkQuery(
        {
            containerName: file?.containerName,
            fileName: file?.id,
            originName: file?.originName,
            storageEngine: undefined,
            ...options,
        },
        { skip: Boolean(img) || !file },
    );

    if (!file) {
        return {
            isLoading: false,
        };
    }

    if (img) {
        return {
            link: img,
            isLoading: false,
        };
    }

    return { link: data, isLoading };
}
