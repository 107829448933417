import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './InputCell.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';
import { CellEditorWrapper } from '../CellEditorWrapper';
import { SnapshotTooltip } from '../SnapshotTooltip';
import { CommentsButton } from '../CommentsButton';
import { ContextMenu } from '../ContextMenu';

interface Props {
    edit: boolean;
    title: string;
    customStyle?: any;
    placeholder: string;
    suggestItems?: string[];
    snapshot?: any;
    commentButtonProps?: { hasNewComments: boolean; onClick: () => void };
    validateValue?: (value: string) => boolean;
    onValueChange: (value: string) => void;
}

export const InputCell = ({
    edit,
    title,
    customStyle,
    placeholder,
    suggestItems,
    snapshot,
    commentButtonProps,
    validateValue,
    onValueChange,
}: Props): JSX.Element => {
    const [isHovered, setHoveredStatus] = React.useState(false);
    const [hoveredItem, setHoveredItem] = React.useState<string>(null);
    const [editedTitle, setEditedTitle] = React.useState(title);

    const inputRef = React.useRef<Input>();
    const lastClickedCharacterIndexRef = React.useRef(null);

    React.useEffect(() => {
        setEditedTitle(title);
    }, [edit, title]);

    React.useEffect(() => {
        if (edit) {
            const index = lastClickedCharacterIndexRef.current || -1;

            const inputElement = inputRef.current.getTextElement();

            inputElement.setSelectionRange(index, index);
            inputElement.focus();
        } else {
            lastClickedCharacterIndexRef.current = null;
        }
    }, [edit]);

    function onMouseEnter() {
        setHoveredStatus(true);
    }

    function onMouseLeave() {
        setHoveredStatus(false);
    }

    function onCharacterClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        const spanElement = event.target as HTMLSpanElement;

        const index = lodash.findIndex(spanElement.parentElement.childNodes, spanElement);

        lastClickedCharacterIndexRef.current = index + 1;
    }

    const onInputBlur = React.useCallback(
        (event, value) => {
            const newValue = hoveredItem !== null ? hoveredItem : (value as string);

            onValueChange(newValue);
            setHoveredItem(null);
        },
        [hoveredItem],
    );

    function onBeforeInput(event: React.CompositionEvent<HTMLInputElement>) {
        if (validateValue) {
            const newValue = getNextValue(event);

            const newValueIsValid = validateValue(newValue);

            if (!newValueIsValid) {
                event.preventDefault();
            }
        }
    }

    function getNextValue(event: React.CompositionEvent<HTMLInputElement>) {
        const str = event.currentTarget.value;
        const sub = event.data;
        const posStart = event.currentTarget.selectionStart || 0;
        const posEnd = event.currentTarget.selectionEnd || posStart;

        return `${str.slice(0, posStart)}${sub}${str.slice(posEnd)}`;
    }

    async function onCopyValueClick() {
        await navigator.clipboard.writeText(title);
    }

    async function onCutValueClick() {
        await navigator.clipboard.writeText(title);

        onValueChange('');
    }

    async function onPasteValueClick() {
        const newValue = await navigator.clipboard.readText();

        onValueChange(newValue);
    }

    function onClearValueClick() {
        onValueChange('');
    }

    return (
        <>
            <CommentsButton {...commentButtonProps} />
            {(lodash.isEmpty(suggestItems) || !edit) && <SnapshotTooltip snapshot={snapshot} />}
            <ContextMenu
                items={[
                    { title: 'Копировать', onClick: onCopyValueClick },
                    { title: 'Вырезать', onClick: onCutValueClick },
                    { title: 'Вставить', onClick: onPasteValueClick },
                    { title: 'Смотреть комментарии', onClick: commentButtonProps?.onClick },
                    { title: 'Очистить', onClick: onClearValueClick },
                ]}
            />

            <div className={style.root} title={title} style={{ ...customStyle }}>
                {!edit && (
                    <div className={style.title} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        {!isHovered && (title || '—')}

                        {isHovered &&
                            title
                                .toString()
                                .split('')
                                .map((item, index) => (
                                    <span key={index} onClick={onCharacterClick}>
                                        {item}
                                    </span>
                                ))}
                    </div>
                )}

                {edit && (
                    <>
                        <Input
                            ref={inputRef}
                            value={editedTitle}
                            placeholder={placeholder}
                            theme={InputTheme.Borderless}
                            onInputChange={setEditedTitle}
                            onBeforeInput={onBeforeInput}
                            onBlur={onInputBlur}
                        />

                        {!lodash.isEmpty(suggestItems) && (
                            <CellEditorWrapper>
                                <div className={style.content}>
                                    {suggestItems.map((item) => (
                                        <div
                                            key={item}
                                            className={style.item}
                                            onMouseEnter={() => setHoveredItem(item)}
                                            onMouseLeave={() => setHoveredItem(null)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            </CellEditorWrapper>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
