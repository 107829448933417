import { useMemo } from 'react';
import { StatusesList } from '../../consts';
import { GroupCards, StatusesListType } from '../../consts';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedStage } from '@store/creative';
import { StoreState } from '@store/index';
import { CreativeRequestStatus } from '@api';

interface Props {
    currentStatus: CreativeRequestStatus;
}

interface Result {
    statusesList: StatusesListType[];
    isApplicationView: boolean;
    currentGroupCardName: GroupCards;
    setIsApplicationView: (value: boolean) => void;
}

export const useStatuses = ({ currentStatus }: Props): Result => {
    const dispatch = useDispatch();
    const selectedStage = useSelector((state: StoreState) => state.creativePage.rest.selectedStage);

    const statusesList = useMemo(() => {
        const statusIndex = StatusesList.findIndex((item) => item.name === currentStatus);
        return StatusesList.map((item, index) => {
            if (item.name === CreativeRequestStatus.closed && currentStatus === CreativeRequestStatus.closed) {
                return { ...item, isDone: true };
            } else if (index < statusIndex) {
                return { ...item, isDone: true };
            } else if (index === statusIndex) {
                return { ...item, isCurrent: true };
            } else {
                return item;
            }
        });
    }, [currentStatus]);

    const _setIsApplicationView = (value: boolean) => {
        dispatch(setSelectedStage(value ? 0 : 1));
    };

    const currentGroupCardName = StatusesList.find((item: StatusesListType) => item.name === currentStatus)?.type;

    return {
        statusesList,
        currentGroupCardName,
        isApplicationView: selectedStage === 0,
        setIsApplicationView: _setIsApplicationView,
    };
};
