import { Month } from '@mrm/budget';

interface MonthListType {
    value: Month;
    label: string;
}

export const MonthList: MonthListType[] = [
    { value: Month.Jan, label: 'Январь' },
    { value: Month.Feb, label: 'Февраль' },
    { value: Month.Mar, label: 'Март' },
    { value: Month.Apr, label: 'Апрель' },
    { value: Month.May, label: 'Май' },
    { value: Month.Jun, label: 'Июнь' },
    { value: Month.Jul, label: 'Июль' },
    { value: Month.Aug, label: 'Август' },
    { value: Month.Sept, label: 'Сентябрь' },
    { value: Month.Oct, label: 'Октябрь' },
    { value: Month.Nov, label: 'Ноябрь' },
    { value: Month.Dec, label: 'Декабрь' },
];
