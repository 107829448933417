import * as React from 'react';

import * as style from './AddNewExecutionIdButton.scss';

interface Props {
    children: string | JSX.Element | JSX.Element[];
    onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;
}

export const AddNewExecutionIdButton = ({ children, onModalOpen }: Props): JSX.Element => {
    return (
        <div className={style.root} onClick={() => onModalOpen('createId')}>
            {children}
        </div>
    );
};
