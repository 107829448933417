import * as React from 'react';
import { CreativeRequest, CreativeRequestComment as CreativeRequestCommentDTO } from '@api';
import { useCreativeRequestData } from '../../../hooks';
import { CreativeRequestComment, GroupedComments } from '../GroupedComments';
import { useCommentsReplyChain } from '../hooks/useCommentsReplyChain';
import { CommentAtTheStartOfReplyChain } from '../CommentAtTheStartOfReplyChain/CommentAtTheStartOfReplyChain';
import * as styles from './CreativeRequestComments.scss';

export function useCreativeRequestComments(creativeRequest: CreativeRequest) {
    let setCommentsBounded: (comment: CreativeRequestCommentDTO) => void;

    const comments = useCreativeRequestData(
        creativeRequest,
        async (creativeRequest) => [...(await creativeRequest.model.getComments())],
        (creativeRequest, setComments) => {
            setCommentsBounded = (comment: CreativeRequestCommentDTO) =>
                setComments((comments) => [...comments, comment]);

            creativeRequest.events.onCommentsAdded(setCommentsBounded);
        },
        (creativeRequest) => creativeRequest.events.offCommentsAdded(setCommentsBounded),
    );

    return comments;
}

interface CommentToReplayTo {
    commentId: string;
    columnName: string;
}
interface CreativeRequestCommentsProps {
    creativeRequest: CreativeRequest;
    commentIdToReplyTo: string;
    setCommentToReplyTo: (commentToReplyTo: CommentToReplayTo) => void;
}

export function CreativeRequestComments({
    creativeRequest,
    commentIdToReplyTo = null,
    setCommentToReplyTo,
}: CreativeRequestCommentsProps): JSX.Element {
    const comments = useCreativeRequestComments(creativeRequest);
    const { commentsByReplyId, commentToReplyTo, commentsToDisplay } = useCommentsReplyChain(
        comments,
        commentIdToReplyTo,
    );

    function Comment({ comment }: { comment: CreativeRequestCommentDTO }): JSX.Element {
        return (
            <CreativeRequestComment
                addReplyButton={!commentIdToReplyTo}
                author={comment.model.author}
                text={comment.model.text}
                files={comment.model.files}
                createdAt={comment.model.createdAt}
                childrenCommentsCount={commentsByReplyId[comment.model.id]?.length}
                onReplyButtonClick={() =>
                    setCommentToReplyTo({
                        commentId: comment.model.id,
                        columnName: comment.model.column,
                    })
                }
            />
        );
    }

    return (
        <div className={styles.commentsGroup}>
            {commentToReplyTo ? (
                <CommentAtTheStartOfReplyChain
                    comment={<Comment comment={commentToReplyTo} />}
                    setCommentToReplyTo={setCommentToReplyTo}
                />
            ) : null}

            <GroupedComments comments={commentsToDisplay || []} commentRenderer={Comment} />
        </div>
    );
}
