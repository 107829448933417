import * as React from 'react';

import type { CreativeRequest } from '@api';

import { useMrmClient } from '@api';

export function useCreativeRequest(creativeRequestsId: string) {
    const [creativeRequest, setCreativeRequest] = React.useState<CreativeRequest>(null);
    const [loading, setLoading] = React.useState(true);

    const client = useMrmClient();

    React.useEffect(() => {
        async function loadCreativeRequest() {
            const creativeRequest = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestsId });
            setCreativeRequest(creativeRequest);
            setLoading(false);

            creativeRequest?.events.onReloaded(onCreativeRequestReload);
        }

        function onCreativeRequestReload(newCreativeRequest: CreativeRequest) {
            setCreativeRequest(newCreativeRequest);

            newCreativeRequest?.events.onReloaded(onCreativeRequestReload);
        }

        if (client) {
            loadCreativeRequest();
        }

        return () => {
            creativeRequest?.events.offReloaded(onCreativeRequestReload);
        };
    }, [client, creativeRequestsId]);

    return {
        loading,
        creativeRequest,
    };
}
