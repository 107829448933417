import * as React from 'react';

import * as style from './Table.scss';

import type {
    CellPosition,
    ColumnName,
    ColumnWidths,
    LineId,
    TableHeaderCellParams,
    TableBodyCellParams,
} from './types';

import { TableView, CellsStorage, CellEvent, Icon, IconType } from 'sber-marketing-ui';

interface Props {
    headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    tableColumns: Record<'left' | 'center' | 'right', ColumnName[]>;
    fixedWidthColumns: ColumnName[];
    totalTableLinesIds: LineId[];
    sourseLineIds: LineId[];
    columnWidths: ColumnWidths;
    displaySourseTable: boolean;
    isExpanded: boolean;
    totalTableRef: (component: TableView) => void;
    sourseTableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onExpandButtonClick: () => void;
}

export const TableTemplate = ({
    headerCellsStorage,
    tableCellsStorage,
    tableColumns,
    fixedWidthColumns,
    totalTableLinesIds,
    sourseLineIds,
    columnWidths,
    displaySourseTable,
    isExpanded,
    totalTableRef,
    sourseTableRef,
    onCellEvent,
    onExpandButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.totalTable}>
                <TableView
                    ref={totalTableRef}
                    headerCellsStorage={headerCellsStorage}
                    tableCellsStorage={tableCellsStorage}
                    columns={tableColumns.center}
                    leftFixedColumns={tableColumns.left}
                    rightFixedColumns={tableColumns.right}
                    fixedWidthColumns={fixedWidthColumns}
                    lines={totalTableLinesIds}
                    columnWidths={columnWidths}
                    lineHeight={40}
                    onCellEvent={onCellEvent}
                    hideBorders
                />
            </div>

            {displaySourseTable && (
                <>
                    <div className={style.expandButton} onClick={onExpandButtonClick}>
                        {isExpanded ? 'Свернуть' : 'Развернуть'}

                        <div className={style.expandButtonIcon}>
                            <Icon type={isExpanded ? IconType.ARROW8_UP : IconType.ARROW8_DOWN} svgSize={8} />
                        </div>
                    </div>

                    {isExpanded && (
                        <div className={style.sourseTable}>
                            <TableView
                                ref={sourseTableRef}
                                tableCellsStorage={tableCellsStorage}
                                columns={tableColumns.center}
                                leftFixedColumns={tableColumns.left}
                                rightFixedColumns={tableColumns.right}
                                fixedWidthColumns={fixedWidthColumns}
                                lines={sourseLineIds}
                                columnWidths={columnWidths}
                                lineHeight={20}
                                onCellEvent={onCellEvent}
                                hideBorders
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
