import * as React from 'react';
import classNames from 'classnames';

import * as styles from './Resize.scss';

export interface ResizeProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onResize'> {
    /**
     * Текущая высота элемента
     * */
    height?: number;

    /**
     * Максимальная высота элемента
     * */
    maxHeight?: number;

    /**
     * Минимальная высота элемента
     * */
    minHeight?: number;

    /**
     * Событие устанавливающее высоту элемента
     * */
    onResize?: (value: number) => void;

    /**
     * Событие срабатывающее по началу изменения размера
     * */
    onResizeStart?: () => void;

    /**
     * Событие срабатывающее по окончанию изменения размера
     * */
    onResizeEnd?: () => void;

    /**
     * Меняет направление изменения размера
     * */
    revert?: boolean;
}

/**
 * Компонент позволяет управлять высотой элементов
 * */
export function Resize({
    className,
    height = 0,
    maxHeight = Infinity,
    minHeight = 0,
    onMouseDown,
    onMouseUp,
    onResize,
    onResizeStart,
    onResizeEnd,
    revert,
    ...props
}: ResizeProps) {
    const [resize, setResize] = React.useState<null | number>(null);
    const isIdle = resize === null;

    React.useEffect(() => {
        if (isIdle) return () => {};

        const mouseUpListener = () => {
            setResize(null);
            onResizeEnd?.();
        };

        const mouseMoveListener = (e: MouseEvent) => {
            const val = revert ? resize - e.pageY : -(resize - e.pageY);
            onResize?.(Math.min(Math.max(height + val, minHeight), maxHeight));
        };

        document.addEventListener('mouseup', mouseUpListener);
        document.addEventListener('mousemove', mouseMoveListener);

        return () => {
            document.removeEventListener('mouseup', mouseUpListener);
            document.removeEventListener('mousemove', mouseMoveListener);
        };
    }, [isIdle]);

    const handleMouseDown: typeof onMouseDown = (e) => {
        e.preventDefault();
        setResize(e.pageY);
        onMouseDown?.(e);
        onResizeStart?.();
    };

    return (
        <div
            {...props}
            className={classNames(styles.root, revert && styles.revert, className)}
            onMouseDown={handleMouseDown}
        >
            <svg
                className={styles.icon}
                fill="none"
                height="8"
                viewBox="0 0 8 8"
                width="8"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M4.5 7.5L7.5 4.5M0.5 7.5L7.5 0.5" stroke="currentColor" />
            </svg>
        </div>
    );
}
