// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-pages-TaskPage-components-TaskPageFiles-_TaskPageFiles__root{display:flex;flex-direction:column;flex:1;min-height:0;gap:16px}.src-client-modules-task-pages-TaskPage-components-TaskPageFiles-_TaskPageFiles__header{font-size:12px;padding:16px 24px 0;color:var(--color-gray-50);display:flex;justify-content:space-between}.src-client-modules-task-pages-TaskPage-components-TaskPageFiles-_TaskPageFiles__files{display:flex;flex-direction:column;gap:6px;align-items:stretch;padding:0 24px 24px;padding-right:calc(24px - var(--ui-scrollbar-size))}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/pages/TaskPage/components/TaskPageFiles/TaskPageFiles.scss"],"names":[],"mappings":"AAAA,sFACE,YAAa,CACb,qBAAsB,CACtB,MAAO,CACP,YAAa,CACb,QAAS,CACV,wFAGC,cAAe,CACf,mBAAoB,CACpB,0BAA2B,CAC3B,YAAa,CACb,6BAA8B,CAC/B,uFAOC,YAAa,CACb,qBAAsB,CACtB,OAAQ,CACR,mBAAoB,CACpB,mBAAoB,CACpB,mDAAoD","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  min-height: 0;\n  gap: 16px;\n}\n\n.header {\n  font-size: 12px;\n  padding: 16px 24px 0;\n  color: var(--color-gray-50);\n  display: flex;\n  justify-content: space-between;\n}\n\n.filesCount {\n\n}\n\n.files {\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n  align-items: stretch;\n  padding: 0 24px 24px;\n  padding-right: calc(24px - var(--ui-scrollbar-size));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-pages-TaskPage-components-TaskPageFiles-_TaskPageFiles__root",
	"header": "src-client-modules-task-pages-TaskPage-components-TaskPageFiles-_TaskPageFiles__header",
	"files": "src-client-modules-task-pages-TaskPage-components-TaskPageFiles-_TaskPageFiles__files"
};
export default ___CSS_LOADER_EXPORT___;
