// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__root{display:flex;justify-content:space-between;height:100%}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__content{display:flex;flex-direction:column;gap:4px}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__buttonWrapper{display:flex;align-items:center}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__button{display:flex;height:16.34px;padding-bottom:37px;gap:5px;cursor:pointer;color:#417EC6;font-family:\"Open Sans\";font-size:12px;font-weight:400;line-height:16.34px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StagesListWidget/Statuses/WithButtonLayout/WithButtonLayout.scss"],"names":[],"mappings":"AAAA,+GACI,YAAa,CACb,6BAA8B,CAC9B,WAAY,CACf,kHAGG,YAAa,CACb,qBAAsB,CACtB,OAAQ,CACX,wHAGG,YAAa,CACb,kBAAmB,CACtB,iHAGG,YAAa,CACb,cAAe,CACf,mBAAoB,CACpB,OAAQ,CACR,cAAe,CACf,aAAc,CACd,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,mBAAoB","sourcesContent":[".root {\n    display: flex;\n    justify-content: space-between;\n    height: 100%;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n\n.buttonWrapper {\n    display: flex;\n    align-items: center;\n}\n\n.button {\n    display: flex;\n    height: 16.34px;\n    padding-bottom: 37px;\n    gap: 5px;\n    cursor: pointer;\n    color: #417EC6;\n    font-family: \"Open Sans\";\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16.34px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__root",
	"content": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__content",
	"buttonWrapper": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__buttonWrapper",
	"button": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-WithButtonLayout-_WithButtonLayout__button"
};
export default ___CSS_LOADER_EXPORT___;
