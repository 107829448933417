import * as React from 'react';

import type { FieldValueLongIdArr } from '@store/autopilot/creativeForm';
import type { CommonProps } from '../types';

import { SelectInput, useLoadOptions, Option } from '../../../common';
import { Header } from '../common';

import * as style from './styles.scss';

interface Props extends CommonProps<FieldValueLongIdArr> {}

export const LongIdArrField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }) => {
    const { loadOptions } = useLoadOptions({ referenceId: field.referenceId });

    const onChange = React.useCallback(
        (selectedOptions: Option[]) => {
            outsideChangeHandler({
                fieldId: field.id,
                fieldError: '',
                fieldValue: {
                    ...field.value.current,
                    value: selectedOptions.map(({ value }) => value),
                },
            });
        },
        [field, outsideChangeHandler],
    );

    const defaultValue = field.value?.current?.value?.map((value) => ({
        value,
        label: value,
    }));

    return (
        <div className={style.root}>
            <Header field={field} />

            <div className={style.input}>
                <SelectInput defaultValue={defaultValue} loadOptions={loadOptions} onChange={onChange} isMulti />
            </div>
        </div>
    );
};
