import * as React from 'react';

import * as styles from './Error.scss';
import classnames from 'classnames';

export type ErrorProps = React.HTMLProps<HTMLSpanElement>;

export function Error({ className, ...props }: ErrorProps) {
    return <span {...props} className={classnames(styles.root, className)} />;
}
