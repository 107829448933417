import * as React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Textarea } from '../Textarea';

import * as styles from './styles.scss';
import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    value?: string;
    error?: string;
    placeholder?: string;
    autoFocus?: boolean;
    onChange?: (value: string) => void;
    onFocus?: (value: string) => void;
    onBlur?: (value: string) => void;
}

export const HrefInput: React.FC<Props> = ({
    value: initValue = '',
    error,
    autoFocus = false,
    placeholder,
    onFocus = () => {},
    onBlur = () => {},
}) => {
    const [value, setValue] = React.useState(initValue);
    const [isEdit, setEdit] = React.useState(autoFocus);

    React.useEffect(() => {}, []);

    const onClearButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setValue('');
        setEdit(true);
    }, []);

    const onEditButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setEdit(true);
    }, []);

    const onTextareaFocus = React.useCallback(() => {
        setEdit(true);
        onFocus(value);
    }, [value, onFocus]);

    const onTextareaBlur = React.useCallback(() => {
        setEdit(false);
        setValue(value.trim());
        onBlur(value.trim());
    }, [value, onBlur]);

    const onPlaceholderClick = React.useCallback(() => {
        setEdit(true);
    }, []);

    const onTextareaChange = React.useCallback((value: string) => {
        setValue(value);
    }, []);

    return (
        <div>
            <div className={styles.root}>
                {isEdit ? (
                    <Textarea
                        value={value}
                        autoFocus
                        onChange={onTextareaChange}
                        onFocus={onTextareaFocus}
                        onBlur={onTextareaBlur}
                    />
                ) : value.length ? (
                    <Link className={styles.link} to={value}>
                        {value}
                    </Link>
                ) : (
                    <div className={styles.placeholder} onClick={onPlaceholderClick}>
                        {placeholder}
                    </div>
                )}

                {!isEdit && (
                    <div className={styles.actions}>
                        <div className={classNames(styles.button)} onClick={onEditButtonClick}>
                            <Icon type={IconType.NOTEPAD} />
                        </div>
                        <div className={classNames(styles.button)} onClick={onClearButtonClick}>
                            <Icon type={IconType.ERASER} />
                        </div>
                    </div>
                )}
            </div>

            {!isEmpty(error) && !isEdit && <div className={styles.error}>{error}</div>}
        </div>
    );
};
