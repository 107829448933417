import * as React from 'react';
import classnames from 'classnames';
import * as commonStyles from '../../../CommonStyles.scss';
import * as styles from './CommentAtTheStartOfReplyChain.scss';

interface CommentToReplayTo {
    commentId: string;
    columnName: string;
}

interface CommentAtTheStartOfReplyChainProps {
    comment: JSX.Element;
    setCommentToReplyTo: (params: CommentToReplayTo) => void;
}

export function CommentAtTheStartOfReplyChain({
    comment,
    setCommentToReplyTo,
}: CommentAtTheStartOfReplyChainProps): JSX.Element {
    return (
        <div>
            <div
                className={classnames(styles.commentAtTheStartOfReplyChainGoBackButton, commonStyles.sidePaddings)}
                onClick={() => setCommentToReplyTo(null)}
            >
                <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.42 3.48C2.172 3.184 1.912 2.928 1.64 2.712C1.376 2.488 1.096 2.304 0.8 2.16V1.644C1.392 1.34 1.932 0.896 2.42 0.312H3.344C3.256 0.544 3.152 0.764 3.032 0.972C2.92 1.172 2.8 1.364 2.672 1.548V2.256C2.8 2.424 2.92 2.612 3.032 2.82C3.152 3.02 3.256 3.24 3.344 3.48H2.42ZM2.6 2.388L2.612 1.404H9.2V2.388H2.6Z"
                        fill="#7E8681"
                    />
                </svg>
                Обсуждение
            </div>

            <div className={styles.commentAtTheStartOfReplyChainCommentWrapper}>{comment}</div>
        </div>
    );
}
