import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import * as styles from './styles.scss';

interface Props {
    onClose: () => void;
}

export const CloseButton: React.FC<Props> = ({ onClose = () => {} }) => {
    return (
        <div className={styles.root} onClick={onClose}>
            <Icon type={IconType.CROSS} />
        </div>
    );
};
