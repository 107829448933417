import * as React from 'react';

import type { FieldValueLong } from '@store/autopilot/creativeForm';
import type { CommonProps } from '../types';

import { IntInput } from '../../../common';
import { Header } from '../common';
import { validate } from './validator';

import * as style from './styles.scss';

interface Props extends CommonProps<FieldValueLong> {}

export const LongField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }) => {
    const onChange = React.useCallback(
        async (value: string) => {
            outsideChangeHandler({
                fieldId: field.id,
                fieldError: null,
                fieldValue: {
                    ...field.value.current,
                    value,
                    error: value.length
                        ? (await validate({ constraints: field.constraints, value }))
                              .map((errorMessage) => `• ${errorMessage}`)
                              .join('\n')
                        : '',
                },
            });
        },
        [field, outsideChangeHandler],
    );

    return (
        <div className={style.root}>
            <Header field={field} />

            <div className={style.input}>
                <IntInput
                    value={field.value.current.value?.toString()}
                    error={field.value.current.error}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};
