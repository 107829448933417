import * as React from 'react';

import type { UserCard } from 'sber-marketing-types/frontend';

import { useUserOptions, UseUserOptions } from '@modules/user/hooks';

import { OptionMultiple, OptionProps, SearchOptionProps, SearchOptions } from '@common/components';
import { UserOption } from '@modules/user/components';

export type IUserOptionsProps<M extends OptionMultiple> = Omit<
    SearchOptionProps<M, OptionProps<UserCard>, UserCard, 'id', number, M extends true ? number[] : number>,
    'options' | 'optionId'
>;

export interface UserOptionsProps<M extends OptionMultiple>
    extends Omit<IUserOptionsProps<M>, 'options' | 'optionId'>,
        UseUserOptions {}

export function UserOptions<M extends OptionMultiple>({
    ids,
    active,
    sortingOrder,
    embed,
    departmentIds,
    roleIds,
    organizationIds,
    hasWorkTypes,
    optionsLoading,
    ...props
}: UserOptionsProps<M>) {
    const params: Required<UseUserOptions> = {
        ids,
        active,
        sortingOrder,
        embed,
        departmentIds,
        roleIds,
        organizationIds,
        hasWorkTypes,
    };

    const [options, isLoading] = useUserOptions(params);

    return (
        <SearchOptions
            renderOption={(props) => <UserOption {...props} />}
            {...props}
            optionsLoading={isLoading || optionsLoading}
            optionId="id"
            options={options}
        />
    );
}
