import * as React from 'react';
import * as style from './SummaryLine.scss';
import classnames from 'classnames';

interface Props {
    first?: boolean;
    title: string;
    amount: number;
}

export const Amount = ({ first, title, amount }: Props): JSX.Element => {
    return (
        <div className={style.amountRoot}>
            <div className={classnames(style.title, style.whiteBackground, first && style.firstTitle)}>{title}</div>
            <div className={classnames(style.amount, style.grayBackground)}>
                <span>{formatAmount(amount)}</span>
                <span className={style.currency}>₽</span>
            </div>
        </div>
    );
};

export const formatAmount = (value: number) =>
    new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value / 100);
