import * as React from 'react';
import type { UserCard, UsersCardQuery } from 'sber-marketing-types/frontend';

import { useGetUserCardsQuery, UserListFilters } from '@api';

import { OptionProps } from '@common/components';
import { useAuthUserOrganizationIds } from '@modules/auth/hooks';

export interface UseUserOptions extends UsersCardQuery, UserListFilters {
    active?: boolean;
}

export function useUserOptions({
    organizationIds: ids,
    active,
    ...options
}: UseUserOptions): [OptionProps<UserCard>[], boolean] {
    const { isLoading: isOrganizationsLoading, organizationIds = ids } = useAuthUserOrganizationIds(Boolean(ids));

    const { isLoading: isUsersLoading, data: users } = useGetUserCardsQuery(
        { organizationIds, ...options },
        { skip: !organizationIds.length },
    );
    const isLoading = isUsersLoading || isOrganizationsLoading;

    return [
        React.useMemo<OptionProps<UserCard>[]>(() => {
            const filteredUsers =
                active === undefined
                    ? users?.users || []
                    : users?.users?.filter(
                          ({ isActive, departmentId }) => active === isActive && Boolean(departmentId),
                      ) || [];

            return filteredUsers.map((value) => ({
                value,
                title: `${value.secondName} ${value.firstName}`,
                subtitle: value.department,
            }));
        }, [users, isLoading, active]),
        isLoading,
    ];
}
