// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-common-components-main-Input-_Input__clickIcon{cursor:pointer;transition:color .3s;user-select:none}.src-client-modules-common-components-main-Input-_Input__clickIcon:hover{color:#1ABA4F}.src-client-modules-common-components-main-Input-_Input__beforeIcon{color:var(--color-gray-50)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/components/main/Input/Input.scss"],"names":[],"mappings":"AAAA,mEACE,cAAe,CACf,oBAAqB,CACrB,gBAAiB,CAHnB,yEAMI,aAAc,CACf,oEAID,0BAA2B","sourcesContent":[".clickIcon {\n  cursor: pointer;\n  transition: color .3s;\n  user-select: none;\n\n  &:hover {\n    color: #1ABA4F;\n  }\n}\n\n.beforeIcon {\n  color: var(--color-gray-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickIcon": "src-client-modules-common-components-main-Input-_Input__clickIcon",
	"beforeIcon": "src-client-modules-common-components-main-Input-_Input__beforeIcon"
};
export default ___CSS_LOADER_EXPORT___;
