import * as React from 'react';
import classNames from 'classnames';

import { Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import * as styles from './CopyTextIconButton.scss';

export interface CopyTextIconButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'content' | 'type'> {
    text: string;
    icon?: IconType;
    iconSize?: number;
    title?: string;
    success?: string;
}

export const CopyTextIconButton: React.FC<CopyTextIconButtonProps> = ({
    text,
    title = 'Скопировать в буфер',
    success = 'Скопировано',
    icon = IconType.COPY_CURRENT_COLOR,
    iconSize = 16,
    className,
    onClick,
    onMouseLeave,
    ...props
}) => {
    const [content, setContent] = React.useState(title);

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        navigator.clipboard?.writeText(text);
        setContent(success);
        onClick?.(e);
    };

    const handleMouseLeave: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        setContent(title);
        onMouseLeave?.(e);
    };

    return (
        <WithTooltip className={classNames(styles.root, className)} content={content}>
            <button {...props} onMouseLeave={handleMouseLeave} className={styles.button} onClick={handleClick}>
                <Icon className={styles.icon} type={icon} svgSize={iconSize} />
            </button>
        </WithTooltip>
    );
};
