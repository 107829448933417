import { CreativeRequest, MrmClient } from '@api';
import { TableType } from '@store/creative/types';
import { useGetParamsResult } from '../hooks/useGetParams';
import { v4 } from 'uuid';

type Result = {
    id: string;
    title: string;
    fields: { name: string }[];
}[];

export const validate = (validationData: useGetParamsResult): Result => {
    const dictionary = [
        { key: 'division', name: 'Дивизион' },
        { key: 'block', name: 'Блок' },
        { key: 'product', name: 'Продукт' },
        { key: 'segment', name: 'Сегмент' },
        { key: 'channel', name: 'Канал' },
    ];

    const contractIdValidated: Result = [];

    if (!validationData.contractId) {
        contractIdValidated.push({
            id: 'Основна информация',
            title: 'Основна информация',
            fields: [{ name: 'Контракт' }],
        });
    }

    const paramsValidated = validationData.params
        ?.filter(({ division, block, product, channel, segment }) =>
            [division, block, product, channel, segment].some((item) => !item),
        )
        .map((item) => ({
            id: item.id,
            title: 'Параметры',
            fields: dictionary
                .map(({ key, name }) => ({ name, isFilled: !!item[key] }))
                .filter((item) => !item.isFilled)
                .map(({ name }) => ({ name })),
        }));

    return [...contractIdValidated, ...paramsValidated];
};

export const createCreativeRequestItem = async (creativeRequestDomain: CreativeRequest) => {
    const client = await MrmClient.getInstance();

    const dictionaries = await client.Dictionary.getByType('creativeRequestGroup');

    const { id: dictionaryId } = dictionaries.find(({ value }) => value === TableType.Tariff);

    return await creativeRequestDomain.model.addItem({ itemId: v4(), groupId: dictionaryId });
};
