import * as React from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';
import { Flex, FlexProps, Resize, Scrollbar, Text } from '@sbermarketing/mrm-ui';

import { IconType } from 'sber-marketing-ui';
import type { FileResponse, TaskCommentTransferObject } from 'sber-marketing-types/backend';

import {
    useAddCommentFileMutation,
    useEditCommentMutation,
    useGetCommentDraftQuery,
    useGetUserQuery,
    useRemoveCommentFileMutation,
    useSendCommentMutation,
    useSetCommentDraftMutation,
} from '@api';

import { useDragAndDrop, useSearch, useWindowSize } from '@common/hooks';
import { DatesFormatter } from '@common/Utils';

import { getFilesCountText, parseFileName } from '@modules/files/utils';
import { useAddFiles } from '@modules/files/hooks';

import { IconTag, Tag, TagClose } from '@common/components';
import { SelectParticipantEventData } from '@modules/task/pages';

import { CommentTextarea } from '@modules/task/components';
import { FileThumbnail, SimpleFileThumbnail } from '@modules/files/components';

import * as styles from './CommentForm.scss';

export interface CommentFormProps extends FlexProps {
    taskId: string;
    channelId: number;
    maxHeight?: number;
    comment?: TaskCommentTransferObject;
    replyComment?: TaskCommentTransferObject;
    invert?: boolean;
    onSubmit?: () => void;
    onCancel?: () => void;
}

const COMMENTS_OFFSET_HEIGHT = 150;
const AUTOSIZE_PEACE_OF_SCREEN = 3;
const DEFAULT_SIZE = 81;
const COMMENT_FORM_CONTENT_OFFSET = 37;

export function CommentForm({
    taskId,
    channelId,
    comment,
    replyComment,
    maxHeight,
    invert,
    className,
    style,
    onFocus,
    onSubmit,
    onCancel,
    ...props
}: CommentFormProps) {
    const textWrapperRef = React.useRef<HTMLDivElement>();
    const commentId = React.useMemo(() => comment?.id || v4(), [comment]);
    const [search, setSearch] = useSearch();
    const [commentText, setCommentText] = React.useState('');
    const [height, setHeight] = React.useState(DEFAULT_SIZE);
    const [autoHeight, setAutoHeight] = React.useState(true);
    const windowSize = useWindowSize();
    const [heightChanging, setHeightChanging] = React.useState(false);
    const { handleDragOver, handleDragEnd, handleDragLeave, handleDrop, isDragOver, isDragOverWindow } = useDragAndDrop(
        (e) => {
            if (e.dataTransfer.files.length) {
                handleAddFiles(Array.from(e.dataTransfer.files));
            }
        },
        props as any,
    );
    const maxAutoHeight = (windowSize.height - COMMENTS_OFFSET_HEIGHT) / AUTOSIZE_PEACE_OF_SCREEN;

    const { data: replyCommentAuthor, isLoading: isAuthorLoading } = useGetUserQuery(
        { id: replyComment?.authorId },
        { skip: !replyComment },
    );

    const [focus, setFocus] = React.useState(false);
    const [files, setFiles] = React.useState<FileResponse[]>([]);
    const [removableFiles, setRemovableFiles] = React.useState<string[]>([]);
    const [addableFiles, setAddableFiles] = React.useState<FileResponse[]>([]);
    const [newFiles, setNewFiles] = React.useState<File[]>([]);
    const [addFiles, { isAddFileLoading, progress }] = useAddFiles({ taskId, commentId });
    const pasteHandlerRef = React.useRef<(rawFiles: File[]) => Promise<void>>();
    const { data: draft } = useGetCommentDraftQuery(taskId);
    const [setDraft] = useSetCommentDraftMutation();
    const [sendComment, { isLoading: isCommentSending }] = useSendCommentMutation();
    const [editComment] = useEditCommentMutation();
    const [addFile, { isLoading: isAddingFile }] = useAddCommentFileMutation();
    const [removeFile] = useRemoveCommentFileMutation();
    const filesLength = newFiles.length + files.length;

    const isActive =
        Boolean(commentText) ||
        Boolean(filesLength) ||
        Boolean(comment) ||
        Boolean(replyComment) ||
        focus ||
        isAddFileLoading ||
        isCommentSending ||
        isDragOver ||
        isDragOverWindow;

    const isLoading =
        isAuthorLoading ||
        isAddingFile ||
        isCommentSending ||
        isDragOverWindow ||
        isDragOver ||
        (!comment && isAddFileLoading);

    React.useEffect(() => {
        setCommentText(comment?.text || '');
        setFiles(comment?.files || []);
        setNewFiles([]);
    }, [comment]);

    React.useEffect(() => {
        if (!comment && draft?.newComment) {
            setCommentText(draft.newComment);
        }
    }, [!draft, comment]);

    React.useEffect(() => {
        if (!autoHeight) return;
        const wrapper = textWrapperRef.current;

        if (!commentText) {
            setHeight(DEFAULT_SIZE);
            return;
        }

        if (wrapper) {
            wrapper.style.maxHeight = '0';
            const newHeight = wrapper.scrollHeight + COMMENT_FORM_CONTENT_OFFSET;
            wrapper.style.maxHeight = '';

            setHeight(Math.min(maxAutoHeight, newHeight));
        }
    }, [autoHeight, commentText]);

    React.useEffect(() => {
        const listener = (e: CustomEvent<SelectParticipantEventData>) => {
            const {
                id,
                user: {
                    value: { firstName, secondName },
                },
            } = e.detail;
            setCommentText(commentText + `[@${secondName} ${firstName}#${id}]`);
        };
        document.addEventListener('clickParticipant', listener);

        return () => {
            document.removeEventListener('clickParticipant', listener);
        };
    }, [commentText]);

    const reset = () => {
        setFocus(false);
        setCommentText('');
        setFiles([]);
        setRemovableFiles([]);
        setAddableFiles([]);
        setNewFiles([]);
        setSearch({ ...search, commentId: undefined });
        setAutoHeight(true);
    };

    const handleResizeStart = () => {
        setHeightChanging(true);
    };

    const handleResizeEnd = () => {
        setHeightChanging(false);
    };

    const handleDownloadFiles = async (files: File[], commentId?: string) =>
        (await addFiles(files, commentId && { commentId, taskId })).map(
            ({ name, storage, originName, type, size, createdAt }) => ({
                id: name,
                type,
                originName,
                name,
                createTime: createdAt,
                size,
                storage,
            }),
        );

    const handleAddFiles = async (rawFiles: File[]) => {
        if (comment) {
            const newFiles: FileResponse[] = await handleDownloadFiles(rawFiles);

            setAddableFiles([...addableFiles, ...newFiles]);
            setFiles([...newFiles, ...files]);
            return;
        }

        setNewFiles([...newFiles, ...rawFiles]);
    };

    pasteHandlerRef.current = handleAddFiles;

    const handleRemoveNewFile = (file: File) => {
        setNewFiles(newFiles.filter((f) => f !== file));
    };

    const handleRemoveFile = (fileId: string) => {
        if (comment) {
            setRemovableFiles([...removableFiles, fileId]);
            setAddableFiles(addableFiles.filter(({ id }) => id !== fileId));
        }

        setFiles(files.filter(({ id }) => id !== fileId));
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = () => {
        if (!comment && commentText !== draft.newComment) {
            setDraft({
                id: taskId,
                newComment: commentText,
            });
        }

        setFocus(false);
    };

    const handleSendComment = async () => {
        try {
            onSubmit?.();
            if (comment) {
                if (addableFiles.length) {
                    for (const addableFile of addableFiles) {
                        await addFile({
                            ...addableFile,
                            taskId,
                            commentId,
                        });
                    }
                }

                if (removableFiles.length) {
                    for (const fileId of removableFiles) {
                        await removeFile({
                            fileId,
                            taskId,
                            commentId,
                        });
                    }
                }

                await editComment({
                    taskId,
                    commentId,
                    text: commentText,
                });

                reset();
            } else {
                const id = v4();

                await sendComment({
                    id,
                    taskId,
                    chanelId: channelId,
                    text: commentText,
                    files,
                    replyId: search.commentId ? String(search.commentId) : undefined,
                });

                const downloadedFiles: FileResponse[] = await handleDownloadFiles(newFiles, id);

                for (const downloadedFile of downloadedFiles) {
                    await addFile({
                        ...downloadedFile,
                        taskId,
                        commentId: id,
                    });
                }

                reset();

                if (draft?.newComment) {
                    setDraft({
                        id: taskId,
                        newComment: '',
                    });
                }
            }
        } catch (e) {
            setCommentText(commentText);
            setFiles(files);
        }
    };

    const handleCancel = () => {
        reset();
        onCancel?.();
    };

    const handleAddFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        handleAddFiles(Array.from(e.target.files));
        e.target.value = '';
    };

    const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = (e) => {
        const { files } = e.clipboardData;
        if (files.length) {
            e.preventDefault();
            pasteHandlerRef.current(Array.from(files));
        }
    };

    const handleResize = (newHeight: number) => {
        setAutoHeight(false);
        setHeight(newHeight);
    };

    const preventEvent: React.MouseEventHandler = (e) => {
        e.preventDefault();
    };

    const resizeElement = (
        <Resize
            className={styles.resize}
            minHeight={DEFAULT_SIZE}
            maxHeight={maxHeight}
            height={height}
            onResizeStart={handleResizeStart}
            onResizeEnd={handleResizeEnd}
            onResize={handleResize}
            revert={!invert}
        />
    );

    return (
        <Flex
            vertical
            justify="flex-end"
            loading={isLoading}
            data-skeleton-content={
                isDragOver
                    ? 'Отпустите, чтобы добавить файлы'
                    : isDragOverWindow
                    ? 'Переместите файлы сюда, чтобы добавить'
                    : progress
                    ? `${progress | 0}%`
                    : undefined
            }
            {...props}
            style={
                {
                    '--comment-form-height': `${height}px`,
                    ...style,
                } as any
            }
            className={classNames(
                styles.root,
                isActive && styles.active,
                invert && styles.invert,
                heightChanging && styles.heightChanging,
                className,
            )}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {(comment || replyComment) && (
                <Flex justify="space-between" className={styles.editHeader} gap={8} padding={[9, 20]}>
                    <IconTag aligned ghost icon={replyComment ? IconType.REPLAY : IconType.PROJECT_STAGES_EDIT_ICON}>
                        {replyComment ? (
                            <Flex gap={4}>
                                <Text weight="bold" color="var(--color-gray-100)">
                                    {replyCommentAuthor?.firstName} {replyCommentAuthor?.secondName}
                                </Text>
                                <span>{replyCommentAuthor?.departmentName},</span>
                                <span>{DatesFormatter.hhmm(replyComment.createTime)}</span>
                            </Flex>
                        ) : (
                            'Редактирование сообщения'
                        )}
                    </IconTag>
                    <IconTag
                        data-qa-id="CommentForm__cancelEdit"
                        icon={IconType.REJECTED_ICON}
                        reverse
                        ghost
                        aligned
                        onClick={handleCancel}
                    >
                        отменить
                    </IconTag>
                </Flex>
            )}
            {!invert && resizeElement}
            <Flex className={styles.editorWrapper} flex padding={[8, 20, 0]} align="stretch" gap={12}>
                <CommentTextarea
                    textWrapperRef={(element) => {
                        textWrapperRef.current = element?.querySelector('div > div:nth-child(2) > div');
                    }}
                    className={styles.editor}
                    dropdownPlacement={invert || windowSize.height / 2 < height ? 'bottom' : 'top'}
                    value={commentText}
                    taskId={taskId}
                    channelId={channelId}
                    shortLexical
                    onValueChange={setCommentText}
                    onPaste={handlePaste}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <Flex gap={12} align="flex-start">
                    <IconTag
                        data-qa-id="CommentForm__attachFile"
                        className={styles.attach}
                        loading={isAddFileLoading}
                        element="label"
                        ghost
                        icon={IconType.ATTACHMENT_ICON}
                        clickable
                        relative
                        data-skeleton-content={progress ? `${progress | 0}%` : undefined}
                        onMouseDown={preventEvent}
                    >
                        <input type="file" multiple onChange={handleAddFile} className={styles.file} />
                    </IconTag>
                    <IconTag
                        data-qa-id="CommentForm__send"
                        relative
                        disabled={isAddFileLoading || (!commentText && !filesLength)}
                        ghost
                        icon={IconType.SEND_ICON}
                        onClick={handleSendComment}
                    />
                </Flex>
            </Flex>
            {filesLength ? (
                <Scrollbar className={styles.files} maxHeight={180} align="center" wrap gap={8} padding={[0, 20, 14]}>
                    {getFilesCountText(filesLength)}:
                    {files.map((file) => (
                        <Tag data-qa-id="CommentForm__file" padding={[3, 7, 3, 3]} gap={8} key={file.id}>
                            <FileThumbnail size={24} file={{ ...file, containerName: commentId, parent: 'comment' }} />
                            {file.originName}
                            <TagClose onClick={() => handleRemoveFile(file.id)} />
                        </Tag>
                    ))}
                    {newFiles.map((file, index) => (
                        <Tag
                            data-qa-id="CommentForm__addFile"
                            padding={[3, 7, 3, 3]}
                            gap={8}
                            key={`${file.name}$${index}$`}
                        >
                            <SimpleFileThumbnail size={24} fileType={parseFileName(file.name).type} />
                            {parseFileName(file.name).name}
                            <TagClose onClick={() => handleRemoveNewFile(file)} />
                        </Tag>
                    ))}
                </Scrollbar>
            ) : null}
            {invert && resizeElement}
        </Flex>
    );
}
