import * as React from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { CreativeFieldList } from './CreativeFieldList';
import { GroupSpecSelector } from './GroupSpecSelector';
import type { ChangeParams } from './CreativeFieldList';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../../../common';

import { StoreState } from '@store';
import {
    getSelectedFormSpecCreatives,
    getFormFields,
    changeSpecFieldValue,
    changeFieldValue,
} from '@store/autopilot/creativeForm';

import * as styles from './styles.scss';

interface Props {}

export const Content: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const selectedFormCreatives = useSelector((state: StoreState) => getSelectedFormSpecCreatives(state));
    const formFields = useSelector((state: StoreState) => getFormFields(state));

    const onSpecChange = React.useCallback(
        (specId: string) =>
            ({ fieldId, fieldError, fieldValue }: ChangeParams) => {
                dispatch(
                    changeSpecFieldValue({
                        specId,
                        fieldId,
                        fieldError,
                        value: fieldValue,
                    }),
                );
            },
        [],
    );

    const onFieldChange = React.useCallback(({ fieldId, fieldError, fieldValue }: ChangeParams) => {
        dispatch(
            changeFieldValue({
                fieldId,
                fieldError,
                value: fieldValue,
            }),
        );
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.accordions}>
                {!isEmpty(formFields) && (
                    <>
                        <CreativeFieldList fields={formFields} onChange={onFieldChange} />
                        <div className={styles.separator} />
                    </>
                )}

                <GroupSpecSelector />

                {!isEmpty(selectedFormCreatives) && (
                    <div className={styles.creatives}>
                        {selectedFormCreatives.map(({ id, groupCreativeSpecId, fields }) => (
                            <Accordion key={id}>
                                <AccordionSummary>
                                    <div className={styles.accordionSummary}>
                                        <div className={styles.accordionSummaryTitle}>Креатив</div>
                                        {/*<div className={styles.accordionSummaryRemoveButton}>Удалить креатив</div>*/}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={styles.accordionDetails}>
                                        <CreativeFieldList
                                            fields={fields}
                                            onChange={onSpecChange(groupCreativeSpecId)}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
