import * as React from 'react';
import { keys } from 'lodash';
import classNames from 'classnames';

import type { FiledConstraints } from '@store/autopilot/creativeForm';
import { getTextBuilder } from './textMap';

import * as styles from './styles.scss';

interface Props {
    constraints: FiledConstraints;
    className?: string;
}

export const ConstraintList: React.FC<Props> = ({ className, constraints }) => {
    return (
        <div className={classNames(styles.root, className)}>
            {keys(constraints).map((constraintName) => {
                const buildText = getTextBuilder(constraintName as keyof FiledConstraints);
                const text = buildText(constraints[constraintName]);
                return <div key={constraintName}>• {text}</div>;
            })}
        </div>
    );
};
