import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { values, omit } from 'lodash';

import { Field, FieldValueTextArr } from '@store/autopilot/creativeForm';
import { TextInput } from '../../../common';
import { Header } from '../common';
import { validate } from './validator';
import type { ChangeParams } from '../types';

import * as style from './styles.scss';

interface Props {
    field: Field<FieldValueTextArr>;
    onChange: (params: ChangeParams) => void;
}

export const TextArrField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }) => {
    const onChange = React.useCallback(
        (id: string) => async (value: string) => {
            if (value.length) {
                outsideChangeHandler({
                    fieldId: field.id,
                    fieldValue: {
                        ...field.value.current,
                        [id]: {
                            ...field.value.current[id],
                            value,
                            error: value.length
                                ? (await validate({ constraints: field.constraints, value }))
                                      .map((errorMessage) => `• ${errorMessage}`)
                                      .join('\n')
                                : '',
                        },
                    } as FieldValueTextArr,
                    fieldError: '',
                });
            } else {
                outsideChangeHandler({
                    fieldId: field.id,
                    fieldValue: omit(field.value.current, id) as FieldValueTextArr,
                    fieldError: '',
                });
            }
        },
        [field, outsideChangeHandler],
    );

    const onCreate = React.useCallback(
        async (value: string) => {
            if (value.length) {
                const id = uuidv4();
                outsideChangeHandler({
                    fieldId: field.id,
                    fieldValue: {
                        ...field.value.current,
                        [id]: {
                            id,
                            value,
                            error: value.length
                                ? (await validate({ constraints: field.constraints, value }))
                                      .map((errorMessage) => `• ${errorMessage}`)
                                      .join('\n')
                                : '',
                        },
                    } as FieldValueTextArr,
                    fieldError: '',
                });
            }
        },
        [field, outsideChangeHandler],
    );

    return (
        <div className={style.root}>
            <Header field={field} />

            <div>
                {values(field.value.current).map(({ id, value, error }) => (
                    <div className={style.input} key={id}>
                        <TextInput
                            value={value}
                            error={error}
                            maxLength={field?.constraints?.charsMax}
                            onBlur={onChange(id)}
                        />
                    </div>
                ))}

                <div className={style.input} key={values(field.value.current).length}>
                    <TextInput
                        placeholder={'Вставьте текст...'}
                        maxLength={field?.constraints?.charsMax}
                        autoFocus
                        onBlur={onCreate}
                    />
                </div>
            </div>
        </div>
    );
};
