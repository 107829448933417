// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-common-Popup-CloseButton-_styles__root{width:fit-content;padding:8px;margin:-8px;display:flex;align-items:center;justify-content:center;font-size:15px;transition:40ms ease-out;border-radius:50%;cursor:pointer;user-select:none}.src-client-modules-activity-pages-autopilot-common-Popup-CloseButton-_styles__root:hover{background-color:#f8f8f8}.src-client-modules-activity-pages-autopilot-common-Popup-CloseButton-_styles__root:active{background-color:#efefef}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/common/Popup/CloseButton/styles.scss"],"names":[],"mappings":"AAAA,oFACI,iBAAkB,CAClB,WAAY,CACZ,WAAY,CAEZ,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CAEvB,cAAe,CAEf,wBAAyB,CACzB,iBAAkB,CAClB,cAAe,CACf,gBAAiB,CAdrB,0FAiBQ,wBAAyB,CAjBjC,2FAqBQ,wBAAyB","sourcesContent":[".root {\n    width: fit-content;\n    padding: 8px;\n    margin: -8px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    font-size: 15px;\n\n    transition: 40ms ease-out;\n    border-radius: 50%;\n    cursor: pointer;\n    user-select: none;\n\n    &:hover {\n        background-color: #f8f8f8;\n    }\n\n    &:active {\n        background-color: #efefef;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-common-Popup-CloseButton-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
