import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import { AccountStatus, UserConfigType } from 'sber-marketing-types/openid';
import * as queryString from 'query-string';
import { FormField } from 'sber-marketing-ui';

import { ActivityBudget, BudgetItem, BudgetStatus, CreateActivityBudgetForm, CreateBudgetItemForm } from '@mrm/budget';
import { PlainDictionary } from '@mrm/dictionary';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import type { HeaderView } from '@common/Page';
import { FormData, GroupedDictionaries, PageState } from '@store/executionBudgetEdit/types';
import { NotificationActionType, NotificationMessage, NotificationType, PageOptions } from '@store/common/types';
import { User } from '@store/user/types';

import {
    ActivityBudgetCreateMessage,
    BudgetItemSaveMessage,
    EditExecutionBudgetPage,
    HeaderTop,
} from './EditExecutionBudgetPage';
import { makeFormFields as makeActivityFormFields } from './ActivityBudgetCard';
import { makeFormFields as makeBudgetFormFields } from './BudgetList/BudgetForm/BudgetFormData';
import { StoreState } from '@store';
import { setNotification, setRequestInProgress, updatePageOptions } from '@store/common/actions';
import { getPageOptions } from '@store/common/selectors';
import { ExecutionTableUserConfig, LoadingStatus } from '@store/budgetExecution';
import {
    addBudgetForm,
    loadEditBudgetPage,
    resetEditBudgetPage,
    setActivityInputFocus,
    setNameInputFocus,
    updateActivityForm,
    updateActivityFormValidation,
    updateBudgetFormsValidation,
} from '@store/executionBudgetEdit/actions';
import {
    checkActivityFormValidation,
    checkBudgetItemFormsValidation,
    getUnvalidFieldsNames,
    makeActivityBudgetParams,
    makeBudgetsItems,
} from '@store/executionBudgetEdit/selectors';
import { getBudgetUserConfig, getBudgetUserConfigState } from '@store/userConfig/budget';
import { getLoginUser } from '@store/user/selector';
import {
    ActivityBudgetApi,
    BudgetApi,
    BudgetItemApi,
    DictionaryApi,
    MultiReferenceDictionaryApi,
    MrmClient,
    UserApi,
    UserConfigApi,
} from '@api';

const DEFAULT_ID = 'new';
const EXECUTION_TABLE_URL = '/budget/execution';

const uuidRegexp = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

interface Props extends Partial<MapProps>, Partial<DispatchProps>, RouteComponentProps<RouteParams> {}

interface MapProps {
    activityBudget: ActivityBudget;
    budgetItems: BudgetItem[];
    budgetItemForms: FormData[];
    currentActivityBudget: CreateActivityBudgetForm;
    currentBudgetsItems: CreateBudgetItemForm[];
    user: User;
    users: UserResponseParams[];
    activityBudgetIsValid: boolean;
    budgetsItemsAreValid: boolean;
    headerTitle: string;
    unvalidFieldNames: string[];
    budgetId: string;
    budgetIdStatus: LoadingStatus;
}

interface DispatchProps {
    setRequestInProgress: (requestStatus: boolean) => void;
    setHeaderView?: (view: HeaderView) => void;
    loadEditBudgetPage: (pageData: PageState) => void;
    resetEditBudgetPage: () => void;
    updateActivityForm: (form: FormData) => void;
    updateActivityFormValidation: () => void;
    updateBudgetFormsValidation: () => void;
    updatePageOptions: (options: PageOptions) => void;
    setNotification: (notification: NotificationMessage) => void;
    setNameInputFocus: (isFocused: boolean) => void;
    setActivityInputFocus: (params: { budgetItemId: string; isFocused: boolean }) => void;
    addBudgetForm: (form: FormData) => void;
}

interface State {
    dataLoaded: boolean;
    localPreloader: boolean;
}

interface RouteParams {
    activityBudgetId: string;
    action: string;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditExecutionBudgetPageContainer extends React.Component<Props, State> {
    private multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    private budgetId: string;
    private pageIsInCopyMode: boolean;
    private activityBudgetIsNew: boolean;
    private queryBudgetItemId: string;
    private addLine: boolean;
    private isComponentMounted: boolean;

    constructor(props: Props) {
        super(props);

        const { action, activityBudgetId } = this.props.match.params;

        this.multiReferenceDictionaryApi = new MultiReferenceDictionaryApi();

        this.activityBudgetIsNew = activityBudgetId === DEFAULT_ID;
        this.pageIsInCopyMode = action === 'copy';

        this.budgetId = (queryString.parse(props.location.search).budgetId as string) || this.props.budgetId;
        this.queryBudgetItemId = queryString.parse(props.location.search).budgetItemId as string;
        this.addLine = Boolean(queryString.parse(props.location.search).addLine);
        this.isComponentMounted = false;

        this.state = {
            dataLoaded: false,
            localPreloader: false,
        };
    }

    public componentDidMount() {
        this.isComponentMounted = true;
        this.props.setRequestInProgress(true);

        if (this.props.budgetIdStatus === LoadingStatus.LOADED) {
            this.initPage();
        }
    }

    public componentWillUnmount() {
        this.isComponentMounted = false;
        this.props.resetEditBudgetPage();
    }

    public componentDidUpdate(prevProps: Props): void {
        const headerTitleChanged = this.props.headerTitle !== prevProps.headerTitle;
        const unvalidFieldNamesChanged = this.props.unvalidFieldNames !== prevProps.unvalidFieldNames;

        if (prevProps.budgetIdStatus !== LoadingStatus.LOADED && this.props.budgetIdStatus === LoadingStatus.LOADED) {
            this.initPage();
        }

        if (headerTitleChanged || unvalidFieldNamesChanged) {
            this.updateHeader(this.props.headerTitle);
        }
    }

    public render(): JSX.Element {
        const showActivitySuggest = this.pageIsInCopyMode || this.activityBudgetIsNew;

        return React.createElement(EditExecutionBudgetPage, {
            budgetId: this.budgetId,
            showActivitySuggest,
            dataLoaded: this.state.dataLoaded,
            localPreloader: this.state.localPreloader,
            multiReferenceDictionaryApi: this.multiReferenceDictionaryApi,
            isSaveButtonDisabled: this.checkSaveButtonIsDisabled(),
            onCreateButtonClick: this.onCreateButtonClick,
        });
    }

    @autobind
    protected async onSaveButtonClick() {
        const { activityBudgetIsValid, budgetsItemsAreValid, currentBudgetsItems } = this.props;

        if (activityBudgetIsValid && budgetsItemsAreValid) {
            let params = new URL(String(window.location)).searchParams;
            const paramsIdRows = params.get('paramsIdRows');

            if (paramsIdRows) {
                // если есть данные для автозаполнения
                const creativeRequestId = params.get('creativeRequestId');
                const client = await MrmClient.getInstance();
                const creativeRequestDomain = await client.domain.creativeRequests.getCreativeRequest({
                    id: creativeRequestId,
                });
                const projectId = creativeRequestDomain.model.projectId;
                const project = await client.domain.projects.getProject({ id: +projectId });

                await this.save();

                await Promise.all(
                    currentBudgetsItems.map(async ({ id }) => {
                        await project.model.addBudgetItem({ budgetItemId: id });
                    }),
                );

                window.close();
            } else {
                await this.save();
                this.openUrlInSameTab(EXECUTION_TABLE_URL);
            }
        } else {
            this.props.updateActivityFormValidation();
            this.props.updateBudgetFormsValidation();
        }
    }

    @autobind
    protected onCreateButtonClick() {
        const { budgetItemForms } = this.props;

        const lastForm: FormData = lodash.last(budgetItemForms);

        const newForm = this.cloneBudgetItemForm(lastForm);

        this.props.addBudgetForm(newForm);
    }

    private async save() {
        const { budgetItems, budgetItemForms, currentActivityBudget, currentBudgetsItems } = this.props;

        const budgetIdToUse = this.budgetId;

        if (!budgetIdToUse || !budgetIdToUse.match(uuidRegexp)) {
            throw new Error('Invalid budget id');
        }

        const budgetItemsIds = budgetItems.map((budgetItem) => budgetItem.id);

        const newBudgetItems = currentBudgetsItems.filter((item) => !lodash.includes(budgetItemsIds, item.id));

        const budgetItemsToTransfer = budgetItemForms.filter((item) => item.transferDestinationId);

        this.setState({ localPreloader: true });

        if (this.activityBudgetIsNew || this.pageIsInCopyMode) {
            await this.createActivityBudget(currentActivityBudget, budgetIdToUse);

            this.notifyAboutCreateActivity(budgetIdToUse, currentActivityBudget.id, currentActivityBudget.name);
        }

        if (!lodash.isEmpty(newBudgetItems)) {
            await this.createBudgetItems(currentActivityBudget.id, this.budgetId, newBudgetItems);

            const createdBudgetItems = await BudgetItemApi.getBudgetItemList({
                budgetId: budgetIdToUse,
                activityIds: [currentActivityBudget.id],
            });

            this.updateExecutionTableUserConfig(budgetIdToUse, newBudgetItems);

            createdBudgetItems.forEach((item) => {
                this.notifyAboutBudgetItemSave(budgetIdToUse, item.activity.id, item.serialNumber, item.sapComment);
            });
        }

        if (!lodash.isEmpty(budgetItemsToTransfer)) {
            await this.transferBudgetItems(budgetItemsToTransfer);

            const transferedBudgetItems = await BudgetItemApi.getBudgetItemList({
                budgetId: budgetIdToUse,
                activityIds: [currentActivityBudget.id],
            });

            transferedBudgetItems.forEach((item) => {
                this.notifyAboutBudgetItemSave(budgetIdToUse, item.activity.id, item.serialNumber, item.sapComment);
            });
        }

        this.setState({ localPreloader: false });
    }

    private async createActivityBudget(
        currentActivityBudget: CreateActivityBudgetForm,
        budgetId: string,
    ): Promise<void> {
        return await ActivityBudgetApi.createActivityBudget({
            ...currentActivityBudget,
            budgetId,
        });
    }

    private async createBudgetItems(
        activityId: string,
        budgetId: string,
        budgetItems: CreateBudgetItemForm[],
    ): Promise<void[]> {
        return await Promise.all(
            budgetItems.map((item) =>
                BudgetItemApi.createBudgetItem({
                    ...item,
                    activityId,
                    budgetId,
                }),
            ),
        );
    }

    private async transferBudgetItems(budgetItemForms: FormData[]) {
        return await Promise.all(
            budgetItemForms.map((item) =>
                BudgetItemApi.updateBudgetItem({
                    id: item.id,
                    activityId: item.transferDestinationId,
                }),
            ),
        );
    }

    @autobind
    private compareBySameFields(obj1: Object, obj2: Object): boolean {
        if (lodash.isPlainObject(obj1) && lodash.isPlainObject(obj2)) {
            const keys1 = lodash.keys(obj1);
            const keys2 = lodash.keys(obj2);

            const keys = lodash.intersection(keys1, keys2);

            return keys.every((item) => this.compareBySameFields(obj1[item], obj2[item]));
        }

        if (lodash.isArray(obj1) && lodash.isArray(obj2)) {
            return obj1.length == obj2.length
                ? obj1.every((item, index) => this.compareBySameFields(obj1[index], obj2[index]))
                : false;
        }

        return lodash.isEqual(obj1, obj2);
    }

    private async initPage() {
        if (!this.budgetId) {
            this.budgetId = this.props.budgetId;
        }

        const { user } = this.props;
        const { activityBudgetId } = this.props.match.params;

        const userOrganizationId = user.attributes.organizationId;

        let currentLabel = 'Новая активность';

        const targetBudgetsStatuses = [BudgetStatus.Execution, BudgetStatus.ArchiveExecution];

        const [currentBudgets, archiveBudgets] = await Promise.all(
            targetBudgetsStatuses.map((budgetStatus) => BudgetApi.getBudgetList({ status: budgetStatus })),
        );

        if (!this.budgetId) {
            const currentYear = new Date().getFullYear();
            this.budgetId = (currentBudgets.find(({ year }) => year === currentYear) ?? currentBudgets[0]).id;
        }

        const targetBudget = [...currentBudgets, ...archiveBudgets].find((budget) => budget.id === this.budgetId);

        const [users, dictionaries, budget] = await Promise.all([
            UserApi.getUserListFiltered({ organizationIds: [userOrganizationId] }),
            DictionaryApi.getDictionariesForBudget({
                organizationId: targetBudget.dictionaryOrganizationId,
                budgetId: this.budgetId,
                userId: user.attributes.id,
                treeview: true,
            }),
            BudgetApi.getBudget(this.budgetId),
        ]);

        let pageData: PageState = {
            budget,
            availableDictionaries: this.groupDictionaries(dictionaries),
            users: users.filter((user) => user.status === AccountStatus.ACTIVE),
        };

        this.multiReferenceDictionaryApi.init(pageData.availableDictionaries);

        if (!this.activityBudgetIsNew) {
            const activityBudget = await ActivityBudgetApi.getActivityBudget(activityBudgetId);

            let budgetItems = await BudgetItemApi.getBudgetItemList({
                budgetId: activityBudget.budgetId,
                activityIds: [activityBudgetId],
            });

            budgetItems = lodash.sortBy(budgetItems, (item) => item.serialNumber);

            currentLabel = this.pageIsInCopyMode ? 'Копирование активности' : activityBudget.name;

            pageData.activityBudget = activityBudget;
            pageData.budgetItems = budgetItems;

            if (budgetItems.length) {
                const usedDictionaryIds = lodash.uniq(
                    lodash
                        .flatMap(budgetItems, (budgetItem) => lodash.values(budgetItem.dictionary))
                        .map((dictionary) => dictionary.id),
                );

                // const usedDictionaries = (
                //     await DictionaryApi.getDictionaryList({
                //         organizationId: targetBudget.dictionaryOrganizationId,
                //     })
                // ).filter((dictionary) => usedDictionaryIds.includes(dictionary.id));

                const usedDictionaries = await DictionaryApi.getDictionaryList({
                    organizationId: targetBudget.dictionaryOrganizationId,
                    ids: usedDictionaryIds,
                    treeview: true,
                });

                pageData.usedDictionaries = this.groupDictionaries(usedDictionaries);
            }
        }

        currentLabel = `${currentLabel} (${budget.year} год)`;

        this.props.loadEditBudgetPage(pageData);

        this.props.updatePageOptions({
            previousUrl: EXECUTION_TABLE_URL,
            previousLabel: 'Таблица исполнения бюджета',
            currentLabel,
            withoutFooter: true,
        });

        this.initActivityBudgetForm();
        this.initBudgetItemForms();

        this.setState({ dataLoaded: true });

        this.props.setRequestInProgress(false);

        this.props.updateActivityFormValidation();
        this.props.updateBudgetFormsValidation();
    }

    private initActivityBudgetForm() {
        const { activityBudget } = this.props;
        const pageIsInCopyMode = this.pageIsInCopyMode;

        this.props.updateActivityForm({
            id: activityBudget && !pageIsInCopyMode ? activityBudget.id : uuid(),
            fields: makeActivityFormFields({
                activityBudget,
                pageIsInCopyMode,
                onNameInputFocus: this.onNameInputFocus,
                onNameInputBlur: this.onNameInputBlur,
            }),
            collapsed: false,
            isNew: activityBudget && !pageIsInCopyMode,
        });
    }

    private initBudgetItemForms() {
        const { budgetItems } = this.props;
        let budgetItemForms: FormData[];

        const singleBudgetItemToCopy =
            this.pageIsInCopyMode &&
            this.queryBudgetItemId &&
            budgetItems.find((budgetItem) => budgetItem.id === this.queryBudgetItemId);
        const makeOnlyOneBudgetForm = singleBudgetItemToCopy || lodash.isEmpty(budgetItems);

        if (makeOnlyOneBudgetForm) {
            const id = uuid();
            const tagsEditorId = uuid();
            const isNew = true;

            budgetItemForms = [
                {
                    id,
                    tagsEditorId,
                    fields: this.makeBudgetFormFields(id, singleBudgetItemToCopy, tagsEditorId, isNew),
                    collapsed: false,
                    isNew,
                },
            ];
        } else {
            budgetItemForms = budgetItems.map((item) => {
                const budgetItemId = this.pageIsInCopyMode ? uuid() : item.id;
                const tagsEditorId = uuid();
                const isNew = this.pageIsInCopyMode;

                return {
                    id: budgetItemId,
                    tagsEditorId,
                    fields: this.makeBudgetFormFields(budgetItemId, item, tagsEditorId, isNew),
                    collapsed: budgetItems.length > 1,
                    isNew,
                };
            });
        }

        if (this.addLine) {
            budgetItemForms.unshift(this.cloneBudgetItemForm(lodash.last(budgetItemForms)));
        }

        this.props.loadEditBudgetPage({
            budgetItemForms,
        });
    }

    private cloneBudgetItemForm(budgetItemForm: FormData): FormData {
        const newForm: FormData = lodash.cloneDeep(budgetItemForm);

        newForm.id = uuid();
        newForm.tagsEditorId = uuid();
        newForm.collapsed = false;
        newForm.isNew = true;

        const newFormFields = this.makeBudgetFormFields(newForm.id, null, newForm.tagsEditorId, true);
        newFormFields.forEach((field, i) => {
            const oldField = newForm.fields[i];

            const updItemsCanUseOldValue = field.items?.some((item) => item.value === oldField.value);

            if (updItemsCanUseOldValue) {
                field.value = oldField.value;
            }
        });
        newForm.fields = newFormFields;

        return newForm;
    }

    private makeBudgetFormFields(
        budgetItemId: string,
        budgetItem: BudgetItem,
        tagsEditorId: string,
        isNew: boolean,
    ): FormField[] {
        const { activityBudget, users } = this.props;

        const pageIsInCopyMode = this.pageIsInCopyMode;
        const canBeTransfered = !pageIsInCopyMode && !!activityBudget && !!lodash.get(budgetItem, 'actions.canEdit');

        return makeBudgetFormFields({
            tagsEditorId,
            activityBudget,
            budgetItem,
            budgetItemId,
            users,
            pageIsInCopyMode,
            canBeTransfered,
            onActivityInputFocus: this.onActivityInputFocus,
            onActivityInputBlur: this.onActivityInputBlur,
        });
    }

    private groupDictionaries(dictionaries: PlainDictionary[]): GroupedDictionaries {
        const byId = dictionaries.reduce(
            (acc, dicitonary) => ({
                ...acc,
                [dicitonary.id]: dicitonary,
            }),
            {},
        );
        const byType = lodash.groupBy(dictionaries, (item) => item.type);

        return {
            byId,
            byType,
        };
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }

    private checkSaveButtonIsDisabled(): boolean {
        return false;
    }

    private notifyAboutCreateActivity(budgetId: string, activityId: string, activityName: string): void {
        const message = ActivityBudgetCreateMessage({
            activityId,
            budgetId,
            name: activityName,
        });

        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.ACTIVITY_CREATED_ON_EXECUTION_BUDGET_PAGE,
            message,
        );
    }

    private notifyAboutBudgetItemSave(
        budgetId: string,
        activityId: string,
        serialNumber: number,
        sapComment: string,
    ): void {
        const message = BudgetItemSaveMessage({
            activityId,
            budgetId,
            serialNumber,
            sapComment,
        });

        this.setNotificationMessage(
            NotificationType.SUCCESS,
            NotificationActionType.CHANGE_SAVE_ON_EXECUTION_BUDGET_PAGE,
            message,
        );
    }

    private setNotificationMessage(
        type: NotificationType,
        typeAction: NotificationActionType,
        comment: JSX.Element | string,
    ): void {
        this.props.setNotification({ type, typeAction, comment });
    }

    @autobind
    private onNameInputFocus(): void {
        this.props.setNameInputFocus(true);
    }

    @autobind
    private onNameInputBlur(): void {
        setTimeout(() => {
            if (this.isComponentMounted) {
                this.props.setNameInputFocus(false);
            }
        }, 150);
    }

    @autobind
    private onActivityInputFocus(budgetItemId: string): void {
        this.props.setActivityInputFocus({ budgetItemId, isFocused: true });
    }

    @autobind
    private onActivityInputBlur(budgetItemId: string): void {
        setTimeout(() => {
            if (this.isComponentMounted) {
                this.props.setActivityInputFocus({ budgetItemId, isFocused: false });
            }
        }, 150);
    }

    private updateHeader(title: string) {
        this.props.setHeaderView({
            firstLine: HeaderTop({
                title,
                unvalidFieldNames: this.props.unvalidFieldNames,
                onSaveButtonClick: this.onSaveButtonClick,
            }),
        });
    }

    private async updateExecutionTableUserConfig(budgetId: string, budgetItems: CreateBudgetItemForm[]): Promise<void> {
        const userConfig = (await UserConfigApi.getPageConfig(
            UserConfigType.BudgetExecution,
        )) as ExecutionTableUserConfig;
        const budgetUserConfig = userConfig[budgetId];

        if (!budgetUserConfig) {
            console.warn(`Missing userConfig for budget width id ${budgetId}`);
        } else {
            budgetUserConfig.budgetItemsToIgnoreFilters = lodash.uniq([
                ...budgetItems.map((budgetItem) => budgetItem.id),
                ...budgetUserConfig.budgetItemsToIgnoreFilters,
            ]);

            const updUserConfig = {
                ...userConfig,
                [budgetId]: budgetUserConfig,
            };

            await UserConfigApi.savePageConfig(UserConfigType.BudgetExecution, updUserConfig);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { activityBudget, budgetItems, users, budgetItemForms } = state.executionBudgetEditPage;
    const { currentLabel } = getPageOptions(state);

    return {
        activityBudget,
        budgetItems,
        budgetItemForms,
        currentActivityBudget: makeActivityBudgetParams(state),
        currentBudgetsItems: makeBudgetsItems(state),
        user: getLoginUser(state),
        users,
        activityBudgetIsValid: checkActivityFormValidation(state),
        budgetsItemsAreValid: checkBudgetItemFormsValidation(state),
        headerTitle: currentLabel,
        unvalidFieldNames: getUnvalidFieldsNames(state),
        budgetId: getBudgetUserConfig(state).budgetStatus[BudgetStatus.Execution].budgetId,
        budgetIdStatus: getBudgetUserConfigState(state).status,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setRequestInProgress,
            loadEditBudgetPage,
            resetEditBudgetPage,
            updateActivityForm,
            updateActivityFormValidation,
            updateBudgetFormsValidation,
            updatePageOptions,
            setNotification,
            setNameInputFocus,
            setActivityInputFocus,
            addBudgetForm,
        },
        dispatch,
    );
}
