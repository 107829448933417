import * as React from 'react';
import { CreativeRequestItemComment, GroupedComments } from '../GroupedComments';
import { useCommentsReplyChain } from '../hooks/useCommentsReplyChain';
import { CommentAtTheStartOfReplyChain } from '../CommentAtTheStartOfReplyChain/CommentAtTheStartOfReplyChain';
import * as styles from './CreativeRequestItemComments.scss';
import { CreativeRequestItem, CreativeRequestItemComment as CreativeRequestItemCommentDTO } from '@api';

interface CommentToReplayTo {
    commentId: string;
    columnName: string;
}

interface CreativeRequestItemCommentsProps {
    creativeRequestItem: CreativeRequestItem;
    commentIdToReplyTo: string;
    activeCreativeRequestColumn: string;
    setCommentToReplyTo: (commentToReplyTo: CommentToReplayTo) => void;
    comments: CreativeRequestItemCommentDTO[];
    refetchComments: (value?: any) => Promise<void>;
}

export function CreativeRequestItemComments({
    creativeRequestItem,
    commentIdToReplyTo = null,
    activeCreativeRequestColumn,
    setCommentToReplyTo,
    comments,
    refetchComments,
}: CreativeRequestItemCommentsProps): JSX.Element {
    const { commentsByReplyId, commentToReplyTo, commentsToDisplay } = useCommentsReplyChain(
        comments,
        commentIdToReplyTo,
    );

    function Comment({ comment }: { comment: CreativeRequestItemCommentDTO }): JSX.Element {
        return (
            <CreativeRequestItemComment
                comment={comment}
                addReplyButton={!commentIdToReplyTo}
                chilrenCommentsCount={commentsByReplyId[comment.model.id]?.length}
                activeCreativeRequestItemId={creativeRequestItem.model.id}
                activeCreativeRequestColumn={activeCreativeRequestColumn}
                refetchComments={refetchComments}
                onReplyButtonClick={() =>
                    setCommentToReplyTo({
                        commentId: comment.model.id,
                        columnName: comment.model.column,
                    })
                }
            />
        );
    }

    return (
        <div className={styles.commentsGroup}>
            {commentToReplyTo ? (
                <CommentAtTheStartOfReplyChain
                    comment={<Comment comment={commentToReplyTo} />}
                    setCommentToReplyTo={setCommentToReplyTo}
                />
            ) : null}

            <GroupedComments comments={commentsToDisplay || []} commentRenderer={Comment} />
        </div>
    );
}
