import * as React from 'react';

import * as style from './UnknownField.scss';

import { CommonProps } from '../types';

interface Props extends CommonProps {}

export const UnknownField: React.FC<Props> = () => {
    return <div className={style.root}>UnknownField</div>;
};
