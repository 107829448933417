import * as React from 'react';

import type { Project } from '@api';

import { useMrmClient } from '@api';

export function useProject(projectId: number) {
    const [project, setProject] = React.useState<Project>(null);
    const [loading, setLoading] = React.useState(true);

    const client = useMrmClient();

    React.useEffect(() => {
        async function loadProject() {
            const project = await client.domain.projects.getProject({ id: projectId });
            setProject(project);
            setLoading(false);
        }

        if (client) {
            loadProject();
        }
    }, [client, projectId]);

    return {
        loading,
        project,
    };
}
