import * as React from 'react';
import { useSelector } from 'react-redux';

import type { StoreState } from '@store';

import { ExecutorBudgetTableButtonTemplate } from './ExecutorBudgetTableButtonTemplate';
import { WithBudgets } from './WithBudgets';
import { WithFilters } from './WithFilters';
import { getLoginUser } from '@store/user/selector';
import { getActivity } from '@store/creative/selectors';

interface Props {
    onClick: () => void;
}

export const ExecutorBudgetTableButtonConnector = ({ onClick }: Props): JSX.Element => {
    const currentUser = useSelector((state: StoreState) => getLoginUser(state));
    const activity = useSelector((state: StoreState) => getActivity(state));

    const userOrganizationId = currentUser.attributes.organizationId;

    return (
        <WithBudgets organizationIds={[userOrganizationId]}>
            {({ budgets }) => (
                <WithFilters>
                    {({ filters }) =>
                        React.createElement(ExecutorBudgetTableButtonTemplate, {
                            activityId: activity.id,
                            budgets,
                            filters,
                            onClick,
                        })
                    }
                </WithFilters>
            )}
        </WithBudgets>
    );
};
