import * as React from 'react';
import classNames from 'classnames';

import * as styles from './styles.scss';

interface Props {
    data: string[][];
}

export const Table: React.FC<Props> = ({ data }) => {
    const [headerLine, ...bodyLines] = data;
    return (
        <>
            <table className={styles.root}>
                <thead>
                    <tr className={classNames(styles.row, styles.headerRow)}>
                        {headerLine.map((cell, index) => (
                            <td
                                key={index}
                                className={classNames(styles.cell, !index ? styles.firstCell : '', styles.headerCell)}
                            >
                                {cell}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {bodyLines.map((line, index) => (
                        <tr key={index} className={styles.row}>
                            {line.map((cell, index) => (
                                <td key={index} className={classNames(styles.cell, !index ? styles.firstCell : '')}>
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};
