// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-common-AccordionDatails-_styles__root{height:0;overflow:hidden;transition:height 250ms ease}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/common/AccordionDatails/styles.scss"],"names":[],"mappings":"AAAA,mFACI,QAAS,CACT,eAAgB,CAChB,4BAA6B","sourcesContent":[".root {\n    height: 0;\n    overflow: hidden;\n    transition: height 250ms ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-common-AccordionDatails-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
