import * as React from 'react';
import classNames from 'classnames';
import { List } from 'react-virtualized';
import { reduce } from 'lodash';

import { useDefaultState } from '@common/hooks';

import { Option, OptionProps } from '../Option';
import { Scrollbar } from '../../layout';

import { defaultOptionsLoader } from './defaultOptionsLoader';

import { OptionMultiple, OptionsProps, OptionsRowHeight, OptionValues } from './types';

import * as styles from './Options.scss';

export const SINGLE_OPTION_HEIGHT = 30;
export const MULTIPLE_OPTION_HEIGHT = 48;
export const DEFAULT_OPTIONS_HEIGHT = 200;

export function getOptionValue<ID extends string | number | symbol, O extends OptionValues | Record<ID, OptionValues>>(
    value: O,
    optionId: ID,
): O extends OptionValues ? O : ID extends keyof O ? O[ID] : never {
    return typeof optionId === 'string' && value && typeof value === 'object' && optionId in value
        ? value[optionId as any]
        : value;
}

function getOptionHeight(option?: OptionProps<any>, optionHeight?: OptionsRowHeight<any>): number {
    if (option?.height !== undefined) return option.height;
    if (typeof optionHeight === 'function') return optionHeight(option);
    if (optionHeight !== undefined) return optionHeight;

    return option.subtitle || option.subtitleContent ? MULTIPLE_OPTION_HEIGHT : SINGLE_OPTION_HEIGHT;
}

export function Options<
    M extends OptionMultiple,
    OP extends OptionProps<any>,
    O extends OP extends OptionProps<infer O1> ? O1 : never,
    ID extends O extends OptionValues ? never : keyof O,
    V extends O extends OptionValues ? O : ID extends keyof O ? O[ID] : never,
    SV extends M extends true ? V[] : V,
>({
    multiple,
    maxMultipleCount,
    selected: selectedProp,
    defaultSelected = multiple ? ([] as SV) : undefined,
    renderOption = (props: any) => <Option {...props} />,
    onSelect,
    preventDefaultSelect,
    className,
    optionsHeight = DEFAULT_OPTIONS_HEIGHT,
    optionsGap = 4,
    optionHeight,
    optionsLoading,
    optionsLoader = defaultOptionsLoader,
    options: optionsProp,
    width: widthProp = 0,
    children,
    optionsBefore,
    optionsAfter,
    optionId,
    onScroll,
    ...props
}: OptionsProps<M, OP, O, ID, V, SV>): React.ReactElement {
    const [selected, setSelected] = useDefaultState(selectedProp, onSelect, defaultSelected, preventDefaultSelect);
    const [width, setWidth] = React.useState<number>(widthProp);
    const listRef = React.useRef<List>();
    const rootRef = React.useRef<HTMLDivElement>();
    const scrollbarRef = React.useRef<HTMLDivElement>();
    const options = optionsLoading ? optionsLoader : optionsProp;
    const currentHeight = reduce(
        options,
        (height: number, option: OptionProps<any>) =>
            height + getOptionHeight(option, optionHeight) + (height ? optionsGap : 0),
        0,
    );
    const [height, setHeight] = React.useState<number>(currentHeight);
    const showOptions = Boolean(options.length);

    const maxOptionsHeight = Math.min(optionsHeight ?? Infinity, currentHeight, Math.max(height, currentHeight));

    React.useEffect(() => {
        if (scrollbarRef.current) {
            const listener = () => {
                if (scrollbarRef.current) {
                    const { scrollHeight, clientWidth } = scrollbarRef.current;
                    setHeight(scrollHeight);
                    setWidth(clientWidth);
                }
            };

            const resizeObserver = new ResizeObserver(listener);
            resizeObserver.observe(scrollbarRef.current);

            listener();

            return () => {
                resizeObserver.disconnect();
            };
        }
        return () => {};
    }, [showOptions]);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
        listRef.current.Grid.handleScrollEvent({
            scrollTop: scrollbarRef.current.scrollTop,
            scrollLeft: scrollbarRef.current.scrollLeft,
        });

        onScroll?.(e);
    };

    function getKey(value: any, index: number) {
        let val = value;
        if (optionId) {
            val = value?.[val];
        }

        if (typeof val === 'object' || val === undefined) {
            return `#${index}#`;
        }
        return String(val);
    }

    return (
        <div {...props} ref={rootRef} className={classNames(styles.root, className)}>
            {optionsBefore}
            {showOptions && (
                <Scrollbar
                    onScroll={handleScroll}
                    maxHeight={optionsHeight === Infinity ? 'auto' : optionsHeight}
                    rootRef={scrollbarRef}
                >
                    <List
                        ref={listRef}
                        className={styles.virtualizedList}
                        height={maxOptionsHeight}
                        rowCount={options.length}
                        rowHeight={({ index }) => {
                            const option = options[index] as OP;
                            const isLast = options.length === index + 1;
                            const currentGap = isLast ? 0 : optionsGap;

                            return getOptionHeight(option, optionHeight) + currentGap;
                        }}
                        rowRenderer={({ index, style }) => {
                            const option = options[index] as OP;
                            const isLast = options.length === index + 1;
                            const currentGap = isLast ? 0 : optionsGap;
                            const value = getOptionValue(option.value, optionId);

                            const isSelected =
                                selected === undefined
                                    ? value === undefined
                                    : multiple
                                    ? selected.includes(value)
                                    : selected === value;

                            return renderOption({
                                ...option,
                                key: getKey(option.value, index),
                                selected: isSelected,
                                style: {
                                    ...option.style,
                                    ...style,
                                    animation: 'none',
                                    height: Number(style.height) - currentGap,
                                },
                                onSelect: () => {
                                    if (!multiple) {
                                        setSelected(value, option);
                                    } else if (isSelected) {
                                        setSelected(
                                            selected.filter((selectValue: V) => selectValue !== value),
                                            option,
                                        );
                                    } else {
                                        const updSelected = [...selected, value];
                                        const valueToSet =
                                            maxMultipleCount && updSelected.length > maxMultipleCount
                                                ? selected
                                                : updSelected;

                                        setSelected(valueToSet as SV, option);
                                    }

                                    option.onSelect?.(value);
                                },
                            });
                        }}
                        width={width}
                    />
                </Scrollbar>
            )}
            {children}
            {optionsAfter}
        </div>
    );
}
