import * as React from 'react';
import { isEmpty } from 'lodash';

import { Icon, IconType } from 'sber-marketing-ui';
import { Textarea } from '../Textarea';

import * as styles from './styles.scss';

interface Props {
    value: string;
    error: string;
    onChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const MoneyInput: React.FC<Props> = ({
    value,
    error,
    onChange,
    onFocus: outsideFocusHandler = () => {},
    onBlur: outsideBlurHandler = () => {},
}) => {
    const [isEdit, setEdit] = React.useState(false);

    const onClearButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        onChange('');
    }, [onChange]);

    const onTextareaChange = React.useCallback(
        (value: string) => {
            onChange(value.replace(/\D/gm, ''));
        },
        [onChange],
    );

    const onFocus = React.useCallback(() => {
        setEdit(true);
        outsideFocusHandler();
    }, [outsideFocusHandler]);

    const onBlur = React.useCallback(() => {
        setEdit(false);
        outsideBlurHandler();
    }, [outsideBlurHandler]);

    return (
        <div>
            <div className={styles.root}>
                <Textarea
                    value={value?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    placeholder={'Введите сумму...'}
                    onChange={onTextareaChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {!isEdit && (
                    <div className={styles.actions}>
                        <div className={styles.button} onClick={onClearButtonClick}>
                            <Icon type={IconType.ERASER} />
                        </div>
                    </div>
                )}
            </div>

            {!isEmpty(error) && !isEdit && <div className={styles.error}>{error}</div>}
        </div>
    );
};
