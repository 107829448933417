import * as React from 'react';
import classNames from 'classnames';
import { WithTooltip, Skeleton } from 'sber-marketing-ui';

import * as style from './CardTitle.scss';

interface Props {
    loading?: boolean;
    link?: string;
}

export const CardTitle: React.FC<Props> = ({ children, loading, link = '' }) => (
    <WithTooltip content={`ЗНК: ${children}`}>
        <div className={classNames(style.root, loading && style.loading)} {...{ 'qa-id': 'cardTitle' }}>
            <Skeleton isReady={!loading}>
                <a href={link} target="_blank">
                    {children}
                </a>
            </Skeleton>
        </div>
    </WithTooltip>
);
