import * as React from 'react';

import * as style from './PlacementList.scss';

import { FieldTypeEnum } from '@store/autopilot/creativeForm';
import type { CommonProps as FieldCommonProps, ChangeHandler, ChangeParams } from './Fields/types';

import * as fields from './Fields';

const FIELD_COMPONENTS_BY_FIELD_TYPE = {
    [FieldTypeEnum.Text]: fields.TextField,
    [FieldTypeEnum.TextArr]: fields.TextArrField,
    [FieldTypeEnum.Href]: fields.HrefField,
    [FieldTypeEnum.HrefArr]: fields.HrefArrField,
    [FieldTypeEnum.Boolean]: fields.BooleanField,
    [FieldTypeEnum.Long]: fields.LongField,
    [FieldTypeEnum.LongId]: fields.LongIdField,
    [FieldTypeEnum.LongIdArr]: fields.LongIdArrField,
    [FieldTypeEnum.StringId]: fields.StringIdField,
    [FieldTypeEnum.StringIdArr]: fields.StringIdArrField,
    [FieldTypeEnum.Image]: fields.ImageField,
    [FieldTypeEnum.ImageArr]: fields.ImageArrField,
    [FieldTypeEnum.Video]: fields.VideoField,
    [FieldTypeEnum.VideoArr]: fields.VideoArrField,
    [FieldTypeEnum.Bundle]: fields.BundleField,
    [FieldTypeEnum.Int]: fields.IntField,
    [FieldTypeEnum.Float]: fields.FloatField,
    [FieldTypeEnum.Money]: fields.MoneyField,
    [FieldTypeEnum.Unknown]: fields.UnknownField,
};

export interface CreativeGroupFieldProps extends FieldCommonProps {}

export const CreativeGroupField: React.FC<CreativeGroupFieldProps> = (props) => {
    const fieldComponent = FIELD_COMPONENTS_BY_FIELD_TYPE[props.field.type];
    return <div className={style.field}>{fieldComponent ? React.createElement(fieldComponent, props) : null}</div>;
};

export { ChangeHandler, ChangeParams };
