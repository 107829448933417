import * as React from 'react';

import { UserResponseParams } from 'sber-marketing-types/frontend';

import { Option, OptionMultiple, OptionProps, SearchOptionProps, SearchOptions } from '@common/components';
import { useGetTaskParticipantsQuery, useGetTaskQuery } from '@api';
import { TaskParticipantOption } from '@modules/task/components';

export type ITaskParticipantOptionsProps<M extends OptionMultiple> = Omit<
    SearchOptionProps<
        M,
        OptionProps<UserResponseParams>,
        UserResponseParams,
        'id',
        number,
        M extends true ? number[] : number
    >,
    'options' | 'optionId'
>;

export interface TaskParticipantOptionsProps<M extends OptionMultiple>
    extends Omit<ITaskParticipantOptionsProps<M>, 'options' | 'optionId'> {
    taskId: string;
    excludeUserIds?: number[];
    deletable?: boolean;
    onRemove?: (userId: UserResponseParams) => void;
}

export function TaskParticipantOptions<M extends OptionMultiple>({
    taskId,
    optionsLoading,
    deletable,
    excludeUserIds,
    emptyFallback,
    searchFallback,
    onRemove,
    ...props
}: TaskParticipantOptionsProps<M>) {
    const { data: participantsRaw = [], isLoading } = useGetTaskParticipantsQuery({ taskId });
    const { data: { task } = {} } = useGetTaskQuery({ id: taskId });

    const participants = React.useMemo(
        (): typeof participantsRaw =>
            task
                ? [
                      task.author as any, // FIXME: `any` usage
                      ...(task.executor && task.author.id !== task.executor.id ? ([task.executor] as any[]) : []), // FIXME: `any` usage
                      ...participantsRaw.filter(
                          (participant) => ![task.authorId, task.executorId].includes(participant.id),
                      ),
                  ]
                : participantsRaw,
        [participantsRaw, task],
    );

    const options: OptionProps<typeof participants[number]>[] =
        participants.map((value) => ({ value, title: `${value.firstName} ${value.secondName}` })) || [];
    const filteredOptions: OptionProps<typeof participants[number]>[] = excludeUserIds
        ? options.filter(({ value }) => !excludeUserIds.includes(value.id))
        : options;

    return (
        <SearchOptions
            data-qa-id="TaskParticipantOptions"
            {...props}
            optionId="id"
            preventSubtitleSearch
            optionHeight={48}
            emptyFallback={emptyFallback || 'Нет доступных участников'}
            searchFallback={searchFallback || 'Нет участников подходящих для данного поискового запроса'}
            optionsLoading={isLoading || optionsLoading}
            renderOption={(props) =>
                isLoading ? (
                    <Option {...props} />
                ) : (
                    <TaskParticipantOption
                        {...props}
                        onRemove={() => onRemove?.(props.value)}
                        deletable={deletable}
                        taskId={taskId}
                    />
                )
            }
            options={filteredOptions}
        />
    );
}
