import * as React from 'react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';

import * as style from './Dropdown.scss';

import { DictionaryType, DictionaryStatus } from '@mrm/dictionary';
import { DictionaryApi } from '@api';

import type { FieldValue, FieldProperties, SelectDictionaryType } from 'sber-marketing-types/frontend';
import type { FieldInput } from '@store/brief/types';
import type { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { LabeledSelect, Icon, IconType, WithTooltip } from 'sber-marketing-ui';

import { HistoryWidget } from '../common/HistoryWidget';

const DEFAULT_VALIDATION_MESSAGE = 'Обязательное поле';

interface MapProps {
    organizationId: string;
}

interface Props extends FieldProperties {
    id: string;
    uniqId?: number;
    parentUniqId?: number;
    index?: string;
    organizationId?: string;
    isDictionary?: boolean;
    dictionaryType?: SelectDictionaryType;
    value?: FieldValue;
    displayError?: boolean;
    disabled?: boolean;
    addHistoryWidget?: boolean;
    onValueChange: (value: FieldInput) => void;
}

export function DropdownComponent({
    index,
    name,
    tooltipMessage,
    organizationId,
    isDictionary,
    dictionaryType,
    selectContent = [],
    value = {},
    validationMessage,
    displayError,
    onValueChange,
    disabled,
    id,
    uniqId,
    parentUniqId,
    addHistoryWidget,
}: Props): JSX.Element {
    const [dictionary, setDictionary] = React.useState([]);
    const hasTooltip = !!tooltipMessage;

    React.useEffect(() => {
        if (isDictionary && dictionaryType === 'divisions') {
            DictionaryApi.getDictionaryList({
                types: [DictionaryType.Division],
                organizationId: organizationId,
            }).then((dictionary) => {
                setDictionary(
                    sortBy(
                        dictionary.filter((item) => item.status !== DictionaryStatus.DELETED),
                        'value',
                    ),
                );
            });
        }
    }, [isDictionary, organizationId]);

    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'briefBlockField',
                'qa-index': index,
                'qa-field-type': 'Dropdown',
            }}
        >
            <LabeledSelect
                qaId="briefBlockDropdownFieldDropdown"
                title={name || 'Новый дропдаун'}
                items={
                    isDictionary
                        ? dictionary.map((item) => ({
                              value: item.id,
                              label: item.value,
                          }))
                        : selectContent.map((item) => ({
                              value: item.id,
                              label: item.name,
                          }))
                }
                selectedValue={value.selected}
                errorMessage={validationMessage || DEFAULT_VALIDATION_MESSAGE}
                displayError={displayError}
                onSelection={(value) => onValueChange(value as string)}
                disabled={disabled}
            />

            {hasTooltip && (
                <WithTooltip
                    content={<div className={style.tooltipMessage}>{tooltipMessage}</div>}
                    className={style.iconTooltip}
                >
                    <Icon type={IconType.INFO_CIRCLE} svgSize="16" />
                </WithTooltip>
            )}

            {addHistoryWidget && (
                <HistoryWidget
                    id={id}
                    uniqId={uniqId}
                    parentUniqId={parentUniqId}
                    type={'dropdown'}
                    selectContent={selectContent}
                />
            )}
        </div>
    );
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        organizationId: getLoginUser(state).attributes.organizationId,
    };
}

export const Dropdown = connect(mapStateToProps)(DropdownComponent);
