import * as React from 'react';

import { CreationForm } from './CreationForm';
import { CreateButton, DownloadButton, useDownloadButton } from './buttons';
import { Texts } from './Texts';

import * as style from './ContextStageWidget.scss';

interface Props {
    readOnly?: boolean;
}

export const ContextStageWidget = ({ readOnly }: Props): JSX.Element => {
    const [formVisibility, setFormVisibility] = React.useState(false);
    const { canDisplay: canDispalyDownloadButton } = useDownloadButton();

    const onCreateButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setFormVisibility(true);
    }, []);

    const onCreationFormClose = React.useCallback(() => {
        setFormVisibility(false);
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>Контекстная реклама</div>

            <div className={style.form}>
                {formVisibility ? (
                    <CreationForm onClose={onCreationFormClose} />
                ) : (
                    <div className={style.buttons}>
                        <CreateButton onClick={onCreateButtonClick} />
                        {canDispalyDownloadButton && <DownloadButton />}
                    </div>
                )}
            </div>

            <Texts className={style.texts} />
        </div>
    );
};
