import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { OrganizationGroups } from '@store/calendar/types';

import { Sidebar } from './Sidebar';
import type { StoreState } from '@store';
import { setExpandedGroupsIds, setExpandedActivitiesIds } from '@store/calendar/actions';
import { getCalendarPageState, getFilters } from '@store/calendar/selectors';
import { getLoginUser } from '@store/user/selector';
import { GroupsFilter, Virtualizer } from '../modules';
import { Loader } from '../../modules';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    expandedGroupsIds: string[];
    expandedActivitiesIds: number[];
    displayOrganizationNames: boolean;
}

interface DispatchProps {
    setExpandedGroupsIds: (ids: string[]) => void;
    setExpandedActivitiesIds: (ids: number[]) => void;
}

interface State {
    organizationGroups: OrganizationGroups[];
    visibleLinesIds: React.ReactText[];
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class SidebarContainer extends React.PureComponent<Props, State> {
    private pageContent: HTMLDivElement;
    private groupsFilter: GroupsFilter;
    private virtualizer: Virtualizer;
    private loader: Loader;

    constructor(props: Props) {
        super(props);

        this.groupsFilter = GroupsFilter.getInstance();
        this.virtualizer = Virtualizer.getInstance();
        this.loader = Loader.getInstance();

        this.groupsFilter.register('sidebar', this.onActivityGroupsChange);

        const organizationGroups = this.groupsFilter.getFilteredGroups();

        this.state = {
            organizationGroups,
            visibleLinesIds: this.virtualizer.getVisibleLineIds(organizationGroups),
        };
    }

    public componentDidMount() {
        this.pageContent = document.getElementById('pageContent') as HTMLDivElement;

        if (this.pageContent) {
            this.pageContent.addEventListener('scroll', this.onPageScroll, { passive: true });
        }
    }

    public componentWillUnmount() {
        if (this.pageContent) {
            this.pageContent.removeEventListener('scroll', this.onPageScroll);
        }
    }

    public render(): JSX.Element {
        return React.createElement(Sidebar, {
            organizationGroups: this.state.organizationGroups,
            displayOrganizationNames: this.props.displayOrganizationNames,
            visibleLinesIds: this.state.visibleLinesIds,
            onGroupTitleClick: this.onGroupTitleClick,
            onActivityTitleClick: this.onActivityTitleClick,
        });
    }

    @autobind
    protected onGroupTitleClick(groupId: string) {
        this.loader.loadStagesByCalendarGroupId(groupId);

        const ids = lodash.xor(this.props.expandedGroupsIds, [groupId]);

        this.props.setExpandedGroupsIds(ids);
    }

    @autobind
    protected onActivityTitleClick(activityId: number) {
        const ids = lodash.xor(this.props.expandedActivitiesIds, [activityId]);

        this.props.setExpandedActivitiesIds(ids);
    }

    @autobind
    protected onActivityGroupsChange(activityGroups: OrganizationGroups[]) {
        const visibleLinesIds = this.virtualizer.getVisibleLineIds(activityGroups);

        const visibleLinesIdsChanged = !lodash.isEqual(visibleLinesIds, this.state.visibleLinesIds);

        this.setState({
            organizationGroups: activityGroups,
            visibleLinesIds: visibleLinesIdsChanged ? visibleLinesIds : this.state.visibleLinesIds,
        });
    }

    @autobind
    protected onPageScroll() {
        this.updateVisibleLinesIds(this.state.organizationGroups);
    }

    private updateVisibleLinesIds(organizationGroups: OrganizationGroups[]) {
        const visibleLinesIds = this.virtualizer.getVisibleLineIds(organizationGroups);

        const visibleLinesIdsChanged = !lodash.isEqual(visibleLinesIds, this.state.visibleLinesIds);

        if (visibleLinesIdsChanged) {
            this.setState({
                visibleLinesIds,
            });
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { expandedGroupsIds, expandedActivitiesIds } = getCalendarPageState(state);
    const { organizationIds } = getFilters(state);

    const user = getLoginUser(state);
    const userOrganizationId = user.attributes.organizationId;

    return {
        expandedGroupsIds,
        expandedActivitiesIds,
        displayOrganizationNames: organizationIds.length > 1 || lodash.first(organizationIds) !== userOrganizationId,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return bindActionCreators(
        {
            setExpandedGroupsIds,
            setExpandedActivitiesIds,
        },
        dispatch,
    );
}
