import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import { ColumnName, ColumnsVisiblityFilter, Filters, ColumnFilters, CellValueType } from '@store/budgetPlanning/types';

import * as style from './TableHeader.scss';

import { HeaderDropdownCell } from '../CellTypes';
import { ColumnsList } from '../../ColumnsConfig';

const STATUS_COLUMN_WIDTH = 78;
const LINE_MENU_WIDTH = 184;

interface Props {
    columnsVisiblityFilter: ColumnsVisiblityFilter;
    columnsWidth: { [columnName: string]: number };
    filters: Filters;
    fixedColumnsNames: ColumnName[];
    hoveredColumnName: ColumnName;
    draggedEdgeColumnName: ColumnName;
    isStatusColumnVisible: boolean;
    isResizingColumn: boolean;
    headerFixedColumnsRef: (element: HTMLDivElement) => void;
    onCellMouseEnter: (columnName: ColumnName) => void;
    onCellMouseLeave: () => void;
    onDropdownClick: (columnName: ColumnName, dropdownContent: JSX.Element) => void;
    onFiltersSelection: (columnName: ColumnName, filters: ColumnFilters) => void;
    onColumnEdgeMousedown: (columnName: ColumnName, mouseDownX: number) => void;
    onApplyFiltersButtonClick: () => void;
}

export const TableHeader = ({
    columnsVisiblityFilter,
    columnsWidth,
    filters,
    fixedColumnsNames = [],
    hoveredColumnName,
    draggedEdgeColumnName,
    isStatusColumnVisible,
    isResizingColumn,
    headerFixedColumnsRef,
    onCellMouseEnter,
    onCellMouseLeave,
    onDropdownClick,
    onFiltersSelection,
    onColumnEdgeMousedown,
    onApplyFiltersButtonClick,
}: Props): JSX.Element => {
    const allColumnsAreHidden = lodash.every(columnsVisiblityFilter, (item) => item === false);

    const visibleColumns = allColumnsAreHidden
        ? ColumnsList
        : ColumnsList.filter((column) => columnsVisiblityFilter[column.name]);

    const nonfixedColumns = visibleColumns.filter((item) => !lodash.includes(fixedColumnsNames, item.name));

    const fixedColumns = fixedColumnsNames
        .filter((item) => visibleColumns.some((column) => column.name == item))
        .map((item) => visibleColumns.find((column) => column.name == item));

    const fixedColumnsWidthSum = fixedColumns.reduce((acc, item) => acc + columnsWidth[item.name], 0);

    return (
        <div
            className={style.root}
            style={{
                paddingLeft: fixedColumnsWidthSum,
                paddingRight: LINE_MENU_WIDTH + (isStatusColumnVisible && STATUS_COLUMN_WIDTH),
            }}
        >
            {nonfixedColumns.map((column) => (
                <React.Fragment key={column.name}>
                    <div
                        className={classNames(
                            style.cell,
                            column.name == hoveredColumnName && style.hover,
                            column.name == draggedEdgeColumnName && style.dragged,
                        )}
                        style={{ width: columnsWidth[column.name] }}
                        onMouseEnter={() => onCellMouseEnter(column.name)}
                        onMouseLeave={onCellMouseLeave}
                    >
                        <HeaderDropdownCell
                            columnName={column.name}
                            title={column.title}
                            filters={filters[column.name]}
                            columnValueType={column.valueType}
                            currencySearch={column.valueType === CellValueType.Currency}
                            isResizingColumn={isResizingColumn}
                            onOpenStatusChange={(content) => onDropdownClick(column.name, content)}
                            onSelectionChange={(filters) => onFiltersSelection(column.name, filters)}
                            onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                        />
                    </div>

                    <div className={style.dragzoneWrapper}>
                        <div
                            className={style.dragzone}
                            onMouseDown={(event) => onColumnEdgeMousedown(column.name, event.clientX)}
                        />
                    </div>
                </React.Fragment>
            ))}

            <div className={style.fixedColumnsCells} ref={headerFixedColumnsRef}>
                {fixedColumns.map((column) => (
                    <React.Fragment key={column.name}>
                        <div
                            className={classNames(
                                style.cell,
                                column.name == hoveredColumnName && style.hover,
                                column.name == draggedEdgeColumnName && style.dragged,
                            )}
                            style={{ width: columnsWidth[column.name] }}
                            onMouseEnter={() => onCellMouseEnter(column.name)}
                            onMouseLeave={onCellMouseLeave}
                        >
                            <HeaderDropdownCell
                                columnName={column.name}
                                title={column.title}
                                filters={filters[column.name]}
                                columnValueType={column.valueType}
                                isResizingColumn={isResizingColumn}
                                currencySearch={column.valueType === CellValueType.Currency}
                                onOpenStatusChange={(content) => onDropdownClick(column.name, content)}
                                onSelectionChange={(filters) => onFiltersSelection(column.name, filters)}
                                onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                            />
                        </div>

                        <div className={style.dragzoneWrapper}>
                            <div
                                className={style.dragzone}
                                onMouseDown={(event) => onColumnEdgeMousedown(column.name, event.clientX)}
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
