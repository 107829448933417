import * as React from 'react';

import * as style from './styles.scss';

import type { BudgetProps } from './WithBudgets';
import type { Filters } from './WithFilters';
import * as moment from 'moment';

interface Props {
    activityId: number;
    budgets: BudgetProps[];
    filters: Filters;
    onClick: () => void;
}

export const ExecutorBudgetTableButtonTemplate = ({
    activityId,
    budgets,
    filters,
    onClick: externalOnClick,
}: Props): JSX.Element => {
    function onClick() {
        try {
            const url = makeBudgetTableUrl();
            openUrlInNewTab(url.href);
            externalOnClick();
        } catch (error) {
            console.error(error);
        }
    }

    function makeBudgetTableUrl(): URL {
        const currentYear = moment().year();
        const currentBudget = budgets.find(({ year }) => year === currentYear);

        if (activityId && currentBudget?.id) {
            const url = new URL(`${window.location.origin}/budget/execution`);

            url.searchParams.append('activityId', activityId.toString());
            url.searchParams.append('budgetId', currentBudget.id);
            url.searchParams.append('activityReferenceFilters', JSON.stringify(filters));

            return url;
        }

        throw new Error('activityId or currentBudgetId is empty');
    }

    function openUrlInNewTab(url: string): void {
        window.open(url, '_blank');
    }

    return (
        <div className={style.root} onClick={onClick}>
            Прикрепить ID бюджета
        </div>
    );
};
