import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Description, Highlight } from '@sbermarketing/mrm-ui';
import { Icon, IconType, BodyPortal } from 'sber-marketing-ui';

import { OrderType, ColumnFilters, CellValueType, ColumnName } from '@store/budgetExecution/types';
import { isRequestInProgress } from '@store/common';

import { useOnboarding } from '@modules/onboarding/hooks';

import { FilterMenu } from '../../../FilterMenu';

import * as style from './HeaderDropdownCell.scss';

export interface HeaderDropdownCellProps {
    name: ColumnName;
    title: string;
    isHovered: boolean;
    isOpened: boolean;
    orderType: OrderType;
    isFiltered: boolean;
    isResizingColumn: boolean;
    isColumnFixed: boolean;
    onColumnFixChange: () => void;
    onOpenerClick: () => void;
    onMaskClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export const HeaderDropdownCell = ({
    name,
    title,
    isHovered,
    isOpened,
    orderType,
    isFiltered,
    isResizingColumn,
    isColumnFixed,
    onColumnFixChange,
    onOpenerClick,
    onMaskClick,
    onMouseEnter,
    onMouseLeave,
}: HeaderDropdownCellProps): JSX.Element => {
    const { id, cancel } = useOnboarding();
    const isLoading = useSelector(isRequestInProgress);
    const isOnboarding = !isLoading && name === 'id' && id === 'searchBudget';
    const rootRef = React.useRef<HTMLDivElement>();

    const handleClick = () => {
        onOpenerClick();
        cancel();
    };

    return (
        <div
            ref={rootRef}
            title={title}
            className={classNames(
                style.root,
                isOpened && style.opened,
                !!orderType && style.sorted,
                isFiltered && style.filtered,
            )}
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
        >
            <div
                className={classNames(style.opener, isResizingColumn && style.openerResizingColumn)}
                onClick={handleClick}
                {...{
                    'qa-id': 'tableHeaderOpenButton',
                    'qa-column-label': title,
                    'qa-column-id': name,
                }}
            >
                <div className={style.title}>{title}</div>

                <div className={style.arrowIcon}>
                    <Icon type={isOpened ? IconType.ARROW20_UP : IconType.ARROW20_DOWN} />
                </div>
            </div>

            {isOpened && (
                <BodyPortal>
                    <div className={style.mask} onClick={onMaskClick} />
                </BodyPortal>
            )}

            {isOnboarding && (
                <Highlight target={rootRef}>
                    <Description weight="bold">Выберите нужный ID строки</Description>
                </Highlight>
            )}
        </div>
    );
};

export interface DropdownContentProps {
    columnName: ColumnName;
    filters: ColumnFilters;
    currencySearch: boolean;
    columnValueType: CellValueType;
    orderType: OrderType;
    isColumnFixed: boolean;
    onSortingChange: (sortingOrder: OrderType) => void;
    onSelectionChange: (filters: ColumnFilters) => void;
    onColumnFixChange: (checked: boolean) => void;
    onApplyFiltersButtonClick: () => void;
}

export const DropdownContent = ({
    columnName,
    filters,
    currencySearch,
    columnValueType,
    orderType,
    isColumnFixed,
    onSortingChange,
    onSelectionChange,
    onColumnFixChange,
    onApplyFiltersButtonClick,
}: DropdownContentProps): JSX.Element => {
    const filterNamesAreNumeral = columnValueType == CellValueType.Number || columnValueType == CellValueType.Currency;

    return (
        <div className={style.filterMenu}>
            <FilterMenu
                userConfigFilters={filters}
                columnName={columnName}
                filterNamesAreNumeral={filterNamesAreNumeral}
                sortingOrder={orderType}
                isColumnFixed={isColumnFixed}
                onSortingChange={onSortingChange}
                onSelectionChange={onSelectionChange}
                onColumnFixChange={onColumnFixChange}
                onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                currencySearch={currencySearch}
            />
        </div>
    );
};
