import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import { FileAsset as FileAssetUI } from '@common/FileAssetRedesign';
import { FileDescription } from '@store/autopilot/creativeForm';

import { download, Utils } from '@common/Utils';

import * as styles from './styles.scss';

interface Props {
    file: FileDescription;
    error: string;
    warning: string;
    onRemove?: () => void;
}

export const FileAsset: React.FC<Props> = ({ file, error, warning, onRemove }) => {
    const onDownloadButtonClick = React.useCallback(() => {
        download(file.downloadLink, file.name);
    }, [file]);

    return (
        <div>
            <div className={styles.root}>
                <FileAssetUI
                    className={styles.fileAsset}
                    id={file.name}
                    name={file.name}
                    originName={file.name}
                    type={file.type}
                    showDownloadButton={!!file?.downloadLink?.length}
                    onDownloadButtonClick={onDownloadButtonClick}
                />
                <div className={styles.summary}>
                    <div>
                        <div className={styles.name} title={file.name}>
                            {file.name}
                        </div>
                        <div className={styles.size} title={file.size.toString()}>
                            {Utils.formatFileSize(file.size)}
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    <div className={styles.author}>Загрузил: автор файла</div>*/}
                    {/*</div>*/}
                </div>
                {!!onRemove && (
                    <div className={styles.removeButton} onClick={onRemove}>
                        <Icon type={IconType.CROSS} />
                    </div>
                )}
            </div>
            {error && <div className={styles.error}>{error}</div>}
            {warning && <div className={styles.warning}>{warning}</div>}
        </div>
    );
};
