import * as React from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { FilterButton, FilterButtonProps } from '@common/FilterButton';
import { ActivityPageTaskFilterMenu } from '@common/TaskFiltersMenu';
import { getActivityTasksFilterCount, fillTaskFilters } from '@store/activityTasksPage';
import { saveActivityTasksUserConfig } from '@store/userConfig/activityTasks';
import { emptyTaskFilter } from '@store/userConfig/dashboard';

export interface ActivityTasksFilterProps extends Partial<Omit<FilterButtonProps, 'count'>> {
    activityId: number;
}

export const ActivityTasksFilter: React.FC<ActivityTasksFilterProps> = ({ activityId, ...props }) => {
    const location = useLocation();
    const count = useSelector(getActivityTasksFilterCount);
    const dispatch = useDispatch();
    const delayLoading = queryString.parse(location.search).delayLoading === 'true';

    const handleReset = () => {
        dispatch(
            saveActivityTasksUserConfig({
                activityId,
                filters: emptyTaskFilter(),
            }),
        );
    };
    const handleShow = () => {
        dispatch(fillTaskFilters(null));
    };

    return (
        <FilterButton {...props} count={count} onReset={handleReset} onShow={handleShow}>
            <ActivityPageTaskFilterMenu delayLoading={delayLoading} activityId={activityId} />
        </FilterButton>
    );
};
