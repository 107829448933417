import * as React from 'react';

import * as style from './ProgrammaticStageWidget.scss';

interface Props {
    readOnly?: boolean;
}

export const ProgrammaticStageWidget = ({ readOnly }: Props): JSX.Element => {
    return <div className={style.root}>Programmatic</div>;
};
