import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import { Button } from '../Button';

import { useStore } from './useStore';
import { buildLinkParamsFromTableData } from '../../utils';

import * as styles from './styles.scss';

interface Props {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const DownloadButton: React.FC<Props> = () => {
    const linkRef = React.useRef<HTMLAnchorElement>(null);
    const { contextTableData } = useStore();

    React.useEffect(() => {
        if (linkRef.current) {
            const { href, download } = buildLinkParamsFromTableData([['Канал/Площадка', 'Текст'], ...contextTableData]);
            linkRef.current.href = href;
            linkRef.current.download = download;
        }
    }, [linkRef.current]);

    return (
        <a ref={linkRef} className={styles.root}>
            <Button>
                <div className={styles.icon}>
                    <Icon type={IconType.DOWNLOAD_FILE} />
                </div>
                <div className={styles.text}>Скачать</div>
            </Button>
        </a>
    );
};
