import * as React from 'react';
import * as lodash from 'lodash';
import { ActivityApi, CreativeRequest, MrmClient } from '@api';
import * as queryString from 'query-string';
import { FormData } from '@store/executionBudgetEdit/types';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '@store/index';
import { setBudgetForms, updateActivityForm } from '@store/executionBudgetEdit/actions';
import * as moment from 'moment';

export const useAutocomplete = () => {
    const dispatch = useDispatch();
    const urlParams = queryString.parse(location.search);
    const budgetItemForms = useSelector((state: StoreState) => state.executionBudgetEditPage.budgetItemForms);
    const activityForm = useSelector((state: StoreState) => state.executionBudgetEditPage.activityForm);

    const [autocompleteLoading, setAutocompleteLoading] = React.useState<boolean>(true);

    const [params, setParams] = React.useState<any[]>([]);
    const [activity, setActivity] = React.useState<any>(null);

    const getData = async (creativeRequestId: string, paramsIdRows: any) => {
        const client = await MrmClient.getInstance();
        const creativeRequest: CreativeRequest = await client.domain.creativeRequests.getCreativeRequest({
            id: creativeRequestId,
        });
        const activity = await ActivityApi.getActivity(+creativeRequest.model.projectId);
        setActivity(activity);

        const activityName = activity.name;
        const znkName = creativeRequest.model.name;
        const author = (await creativeRequest.model.getAuthor())?.id;
        const caConst = 'd6c54e60-da4f-412e-bd3b-9bcfd297e549';
        const customerName = creativeRequest.model.customer;
        const start = activity.realizationStart;
        const end = activity.realizationEnd;

        const params = await creativeRequest.model.getParams();

        params?.forEach(async (el) => {
            const division = await el.model.division;
            const block = await el.model.block;
            const product = await el.model.product;
            const channel = await el.model.channel;
            const segment = await el.model.segment;

            setParams((value: any) =>
                [
                    ...value,
                    {
                        division,
                        block,
                        product,
                        channel,
                        segment,
                        activityName,
                        znkName,
                        author,
                        caConst,
                        customerName,
                        start,
                        end,
                        id: el.model.id,
                    },
                ]
                    .map((item) => ({
                        ...item,
                        count: paramsIdRows.find((paramIdRow: any) => paramIdRow.id === item.id)?.count || 0,
                    }))
                    .filter((item) => item.count > 0),
            );
        });
    };

    const cloneBudgetItemForm = (budgetItemForm: FormData, paramsItem: any): FormData => {
        const newForm: FormData = lodash.cloneDeep(budgetItemForm);

        newForm.id = uuid();
        newForm.tagsEditorId = uuid();

        newForm.fields.forEach((item) => {
            const fieldIsActivityName = item.name === 'activityBudget';

            item.id = uuid();
            item.disabled = fieldIsActivityName;
            item.readOnly = false;
            item.errorMessage = '';

            switch (item.name) {
                case 'division':
                    item.value = paramsItem.division.id;
                    break;
                case 'block':
                    item.value = paramsItem.block.id;
                    break;
                case 'channel':
                    item.value = paramsItem.channel.id;
                    break;
                case 'product':
                    item.value = paramsItem.product.id || null;
                    break;
                case 'segment':
                    item.value = paramsItem.segment.id || null;
                    break;
                case 'activityBudget':
                    item.value = paramsItem.activityName;
                    break;
                case 'sapComment':
                    item.value = paramsItem.znkName;
                    break;
                case 'responsibles':
                    item.value = [paramsItem.author];
                    break;
                case 'regionality':
                    item.value = paramsItem.caConst;
                    break;
                case 'customerName':
                    item.value = paramsItem.customerName;
                    break;
                case 'startDate':
                    item.value = moment(paramsItem.start);
                    break;
                case 'endDate':
                    item.value = moment(paramsItem.end);
                    break;
            }
        });

        newForm.collapsed = false;
        newForm.isNew = true;

        return newForm;
    };

    React.useEffect(() => {
        if (urlParams?.creativeRequestId && urlParams?.paramsIdRows) {
            getData(urlParams.creativeRequestId as string, JSON.parse(urlParams.paramsIdRows as string));
        }
    }, []);

    const lockInitRef = React.useRef(null); //locked after initBudgetItemForms from container

    React.useEffect(() => {
        if (!urlParams?.paramsIdRows) {
            setAutocompleteLoading(false);
        }

        if (!urlParams?.paramsIdRows || !budgetItemForms.length || lockInitRef.current) return;

        const paramsIdRowsLength = JSON.parse(urlParams.paramsIdRows as string).filter(
            (item: any) => item.count > 0,
        ).length;

        // then all params are loaded
        if (params.length === paramsIdRowsLength) {
            lockInitRef.current = true;

            // set ActivitiName
            const cloneActivityForm = lodash.cloneDeep(activityForm);
            cloneActivityForm.fields[0].value = activity.name;
            cloneActivityForm.fields[0].errorMessage = '';
            dispatch(updateActivityForm(cloneActivityForm));

            const newBudgetItemForms: FormData[] = [];
            params.forEach((param) => {
                for (let i = 0; i < param.count; i++) {
                    newBudgetItemForms.push(cloneBudgetItemForm(budgetItemForms[0], param));
                }
            });

            dispatch(setBudgetForms(newBudgetItemForms));

            setAutocompleteLoading(false);
        }
    }, [params, budgetItemForms]);

    return {
        autocompleteLoading,
    };
};
