import * as React from 'react';
import { useSelector } from 'react-redux';

import type { StoreState } from '@store';

import { AddExistingExecutionIdButtonTemplate } from './AddExistingExecutionIdButtonTemplate';
import { WithBudgets } from './WithBudgets';
import { WithFilters } from './WithFilters';
import { getLoginUser } from '@store/user/selector';
import { useUrl } from '../../../hooks';

interface Props {
    children: string | JSX.Element | JSX.Element[];
}

export const AddExistingExecutionIdButtonConnector = ({ children }: Props): JSX.Element => {
    const { projectId: activityId } = useUrl();
    const currentUser = useSelector((state: StoreState) => getLoginUser(state));

    const userOrganizationId = currentUser.attributes.organizationId;

    return (
        <WithBudgets organizationIds={[userOrganizationId]}>
            {({ budgets }) => (
                <WithFilters>
                    {({ filters }) =>
                        React.createElement(AddExistingExecutionIdButtonTemplate, {
                            children,
                            activityId,
                            budgets,
                            filters,
                        })
                    }
                </WithFilters>
            )}
        </WithBudgets>
    );
};
