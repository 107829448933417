import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import type { Autopilot2 } from 'sber-marketing-types/backend';
import type { LinkedBudgetItem } from './WithLinkedBudgetItems/types';

import { FinalStageActionsTemplate } from './FinalStageActionsTemplate';
import { Saver } from '../../modules';
import { setPreloaderStatus } from '@store/autopilot/actions';

interface Props extends Partial<DispatchProps & RouteComponentProps> {
    loading: boolean;
    canEdit: boolean;
    autopilot: Autopilot2;
    linkedBudgetItems: LinkedBudgetItem[];
}

interface DispatchProps {
    setPreloaderStatus: (status: boolean) => void;
}

@(withRouter as any)
@(connect(null, mapDispatchToProps) as any)
export class FinalStageActionsBehaviour extends React.PureComponent<Props> {
    private saver: Saver;

    public constructor(props: Props) {
        super(props);

        this.saver = Saver.getInstance();
    }

    public render(): JSX.Element {
        return React.createElement(FinalStageActionsTemplate, {
            socialMediaIsLaunched: this.checkPlacementsLaunchedStatus('social media'),
            programmaticIsLaunched: this.checkPlacementsLaunchedStatus('programmatic'),
            contextIsLaunched: this.checkPlacementsLaunchedStatus('sea'),
            creativesIsLaunched: this.checkPlacementsLaunchedStatus('creative'),
            canLaunchSocialMedia: this.canLaunchPlacements('social media'),
            canLaunchProgrammatic: this.canLaunchPlacements('programmatic'),
            canLaunchContext: this.canLaunchPlacements('sea'),
            canLaunchCreatives: this.canLaunchPlacements('creative'),
            canStopSocialMedia: this.canStopPlacements('social media'),
            canStopProgrammatic: this.canStopPlacements('programmatic'),
            canStopContext: this.canStopPlacements('sea'),
            canStopCreatives: this.canStopPlacements('creative'),
            onPlacementsLaunch: this.onPlacementsLaunch,
            onPlacementsStop: this.onPlacementsStop,
        });
    }

    @autobind
    protected async onPlacementsLaunch(uiType: 'social media' | 'programmatic' | 'sea') {
        this.props.setPreloaderStatus(true);
        await this.saver.launchPlacements(uiType);
        this.props.setPreloaderStatus(false);
        this.redirectToProjectPage();
    }

    @autobind
    protected async onPlacementsStop(uiType: 'social media' | 'programmatic' | 'sea') {
        this.props.setPreloaderStatus(true);
        await this.saver.stopPlacements(uiType);
        this.props.setPreloaderStatus(false);
        this.redirectToProjectPage();
    }

    private checkPlacementsLaunchedStatus(uiType: 'social media' | 'programmatic' | 'sea' | 'creative'): boolean {
        const { autopilot } = this.props;

        if (!autopilot) {
            return false;
        }

        const placements = autopilot.placements.filter((item) => item.uiType === uiType);

        return !lodash.isEmpty(placements) && placements.every((item) => item.deployStatus !== 'OFF');
    }

    private canLaunchPlacements(uiType: 'social media' | 'programmatic' | 'sea' | 'creative'): boolean {
        const { canEdit, autopilot } = this.props;

        if (!autopilot) {
            return false;
        }

        const placements = autopilot.placements.filter((item) => item.uiType === uiType);

        const canLaunch =
            !lodash.isEmpty(placements) &&
            placements.every((item) => item.creativeStatus === 'APPROVED') &&
            placements.every((item) => !item.launcherId);

        return canEdit && canLaunch;
        // return canEdit && canLaunch && this.checkStagesStatus();
    }

    private canStopPlacements(uiType: 'social media' | 'programmatic' | 'sea' | 'creative'): boolean {
        const { canEdit, autopilot } = this.props;

        if (!autopilot) {
            return false;
        }

        const placements = autopilot.placements.filter((item) => item.uiType === uiType);

        const canLaunch = !lodash.isEmpty(placements) && placements.every((item) => item.deployStatus !== 'OFF');

        return canEdit && canLaunch;
        // return canEdit && canLaunch && this.checkStagesStatus();
    }

    // private checkStagesStatus(): boolean {
    //     const { autopilot, linkedBudgetItems, loading } = this.props;

    //     if (!autopilot || loading) {
    //         return false;
    //     }

    //     const { brief, mediaplan, budget, social } = autopilot;

    //     const briefIsFinished = brief ? [
    //         brief.block,
    //         brief.product,
    //         brief.budget,
    //         brief.dateStart,
    //         brief.dateEnd,
    //         brief.ageFrom,
    //         brief.ageTo
    //     ].every(item => typeof item == 'number' ? item !== null : !lodash.isEmpty(item)) : false;

    //     const mediaplanIsFinished = mediaplan ? !lodash.isEmpty(mediaplan.url) : false;

    //     const autopilotBudgetItemIds = lodash.get(budget, 'budgetIds') || [];

    //     const budgetIsFinished = linkedBudgetItems.some(item =>
    //         autopilotBudgetItemIds.includes(item.budgetItem.id)
    //         && item.status == 'approved'
    //     );

    //     const creativeIsFinished = social ? [
    //         social.vk,
    //         social.ok
    //     ].every(item => !lodash.isEmpty(item)) : false;

    //     return briefIsFinished && mediaplanIsFinished && budgetIsFinished && creativeIsFinished;
    // }

    private redirectToProjectPage() {
        const { autopilot } = this.props;

        this.openUrlInSameTab(`/activity/${autopilot.activityId}`);
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            setPreloaderStatus,
        },
        dispatch,
    );
}
