import * as React from 'react';
import classnames from 'classnames';

import * as styles from './OriginalMounthList.scss';

import type { BudgetItem } from '@mrm/budget';

import { MonthList } from '../consts';
import { formatAmount } from '../utils';

interface Props {
    budgetItem: BudgetItem;
}

export const OriginalMounthList = ({ budgetItem }: Props): JSX.Element => {
    const [isOpened, setIsOpened] = React.useState<boolean>(true);
    return (
        <div className={styles.root}>
            <div className={styles.toggleButton} onClick={() => setIsOpened((value) => !value)}>
                {isOpened ? 'Свернуть все месяце' : 'Показать все месяцы'}
            </div>

            {isOpened && (
                <div className={styles.table}>
                    <div className={styles.column}>
                        <div className={styles.header}>Месяц:</div>
                        <div className={classnames(styles.cells, styles.leftRadius)}>
                            {MonthList?.map(({ label }) => (
                                <div className={styles.cell} key={label}>
                                    <span className={styles.month}>{label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.header}>Резерв, ₽:</div>
                        <div className={styles.cells}>
                            {MonthList?.map(({ value }, index) => (
                                <div className={classnames(styles.cell, styles.amount)} key={index}>
                                    {formatAmount(budgetItem.reservedFunds[value])}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.header}>Остаток по месяцу, ₽:</div>
                        <div className={classnames(styles.cells, styles.rightRadius)}>
                            {MonthList?.map(({ value }, index) => (
                                <div className={classnames(styles.cell, styles.amount)} key={index}>
                                    {formatAmount(budgetItem.plannedFunds[value] - budgetItem.reservedFunds[value])}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
