import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType, MentionItem } from 'sber-marketing-ui';

import { FileInput } from '@common/FileInput';
import { DragNDrop } from '@common/DragNDrop';
import { LexicalTextarea } from '@common/LexicalTextarea';

import { FileAsset } from './FileAsset';

import * as styles from './CommentInput.scss';

interface Props {
    text: string;
    disabled: boolean;
    isFocused: boolean;
    fileInputRef: React.RefObject<FileInput>;
    files: File[];
    mentionableUsers: MentionItem[];
    uploadedFiles: File[];
    isFileUploadInProgress: boolean;
    isRequestInProgress: boolean;
    onWrapperMouseOver: () => void;
    onWrapperMouseOut: () => void;
    onInputChange: (value: string) => void;
    onInputFocus: () => void;
    onInputBlur: () => void;
    onAttachmentIconClick: () => void;
    onAttachmentIconMouseOver: () => void;
    onAttachmentIconMouseOut: () => void;
    onSendIconClick: () => void;
    onSendIconMouseOver: () => void;
    onSendIconMouseOut: () => void;
    onFileInput: (files: File[]) => void;
    deleteFile: (file: File) => void;
}

export function CommentInputLexicalTemplate({
    text,
    disabled,
    isFocused,
    fileInputRef,
    files,
    mentionableUsers,
    uploadedFiles,
    isFileUploadInProgress,
    isRequestInProgress,
    onWrapperMouseOver,
    onWrapperMouseOut,
    onInputChange,
    onInputFocus,
    onInputBlur,
    onAttachmentIconClick,
    onAttachmentIconMouseOver,
    onAttachmentIconMouseOut,
    onSendIconClick,
    onSendIconMouseOver,
    onSendIconMouseOut,
    onFileInput,
    deleteFile,
}: Props): JSX.Element {
    const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = (e) => {
        const { files } = e.clipboardData;
        if (files.length) {
            e.preventDefault();
            onFileInput(Array.from(files));
        }
    };

    return (
        <div className={classnames(styles.root, isRequestInProgress && styles.rootRequestInProgress)}>
            <div
                className={classnames(styles.inputWrapper, isFocused && styles.inputWrapperFocused)}
                onMouseOver={onWrapperMouseOver}
                onMouseOut={onWrapperMouseOut}
            >
                <div className={styles.inputLexical}>
                    <LexicalTextarea
                        qaId="commentInputLexicalTextarea"
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        value={text}
                        onValueChange={onInputChange}
                        onPaste={handlePaste}
                        title="Напишите комментарий…"
                        focusOnValueChange
                        shortLexical
                        disabled={disabled}
                        mentions={mentionableUsers}
                    />
                </div>

                {!disabled ? (
                    <React.Fragment>
                        <div
                            className={classnames(styles.icon, styles.attachmentIcon)}
                            {...{
                                'qa-id': 'commentInputAttachFilesIcon',
                            }}
                        >
                            <Icon
                                type={IconType.ATTACHMENT_ICON}
                                svgSize={24}
                                onClick={onAttachmentIconClick}
                                onMouseOver={onAttachmentIconMouseOver}
                                onMouseOut={onAttachmentIconMouseOut}
                            />
                        </div>

                        <div
                            className={classnames(styles.icon, styles.sendIcon)}
                            {...{
                                'qa-id': 'commentInputSendIcon',
                            }}
                        >
                            <Icon
                                type={IconType.SEND_ICON}
                                svgSize={24}
                                onClick={onSendIconClick}
                                onMouseOver={onSendIconMouseOver}
                                onMouseOut={onSendIconMouseOut}
                            />
                        </div>

                        <FileInput ref={fileInputRef} onChange={onFileInput} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div
                            className={styles.attachmentIcon}
                            {...{
                                'qa-id': 'commentInputAttachFilesIcon',
                            }}
                        >
                            <Icon type={IconType.ATTACHMENT_ICON} svgSize={24} />
                        </div>

                        <div
                            className={styles.sendIcon}
                            {...{
                                'qa-id': 'commentInputSendIcon',
                            }}
                        >
                            <Icon type={IconType.SEND_ICON} svgSize={24} />
                        </div>
                    </React.Fragment>
                )}
            </div>

            {!!files.length && (
                <div className={styles.filesWrapper}>
                    {files.map((file, i) => (
                        <div key={`file-${i}`} className={styles.file}>
                            <FileAsset
                                file={file}
                                preloader={isFileUploadInProgress && !uploadedFiles.includes(file)}
                                deleteFile={deleteFile}
                            />
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.dragNDrop}>{!disabled && <DragNDrop onFileInput={onFileInput} />}</div>
        </div>
    );
}
