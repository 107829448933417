import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as moment from 'moment';
import * as lodash from 'lodash';

import type { CreativeRequestItem } from './Content/Item';

import { ListTemplate } from './ListTemplate';
import { getStageByStatus } from '../../../pages/creative/utils';
import { MrmClient, DictionaryType } from '@api';

interface Props extends Partial<RouteComponentProps> {
    activityId: number;
    activityName: string;
    closeList: () => void;
    canCreateCreativeRequest: boolean;
}

interface State {
    items: CreativeRequestItem[];
}

const TARGET_LOT_DICTIONARY_NAME = 'ЛОТ 1';

@(withRouter as any)
export class ListBehaviour extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    public async componentDidMount() {
        await this.updateList();
    }

    public render(): JSX.Element {
        const { canCreateCreativeRequest } = this.props;

        return React.createElement(ListTemplate, {
            activityId: this.props.activityId,
            items: this.state.items,
            closeList: this.props.closeList,
            displayCreateButton: canCreateCreativeRequest,
            onCreateButtonClick: this.onCreateButtonClick,
        });
    }

    @autobind
    private async onCreateButtonClick() {
        const id = await this.createCreativeRequest();
        this.redirectToCreativeRequest(id);
    }

    private async updateList() {
        const list = await this.getCreativeRequestList();
        const sortedList = lodash.sortBy(list, (item) => item.createdAt);

        this.setState({
            items: sortedList.map((item) => ({
                id: item.id,
                name: item.name || 'Заявка на креатив',
                date: this.formatDate(item.createdAt),
                stage: item.status as any, //getStageByStatus(item.status as any),
            })),
        });
    }

    private async getCreativeRequestList() {
        const client = await MrmClient.getInstance();

        return await client.api.creativeRequests.getCreativeRequests({ projectId: this.props.activityId });
    }

    private async createCreativeRequest() {
        const { activityId, activityName } = this.props;

        const client = await MrmClient.getInstance();
        const dictionary = await client.Dictionary.getByType(DictionaryType.Lot);
        const lotId = dictionary.find((dictionary) => TARGET_LOT_DICTIONARY_NAME === dictionary.value)?.id || null;

        const creativeRequestName = `${activityName} - ЛОТ 1`;

        const res = await client.api.creativeRequests.createCreativeRequest({
            projectId: activityId,
            name: creativeRequestName,
            lotId,
        });

        return res.id;
    }

    private formatDate(date: Date): string {
        if (!date) {
            return '';
        }

        return moment(date).format('DD.MM.YYYY');
    }

    private redirectToCreativeRequest(id: string) {
        const { activityId } = this.props;

        this.openUrlInSameTab(`/activity/${activityId}/creative/${id}`);
    }

    private openUrlInSameTab(url: string) {
        this.props.history.push(url);
    }
}
