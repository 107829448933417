import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { head } from 'lodash';

import * as styles from './styles.scss';

import type { FieldValueBundle } from '@store/autopilot/creativeForm';
import type { CommonProps } from '../types';

import { FileInput } from '@common/FileInput';
import { UploadButton } from './UploadButton';
import { FileAssetList } from '../../../common';
import { Header } from '../common';
import { validate } from './validator';

interface Props extends CommonProps<FieldValueBundle> {}

export const BundleField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }) => {
    const fileInputRef = React.useRef<FileInput>(null);

    const setFile = React.useCallback(
        (value: { file: File; error: string }) => {
            const id = uuidv4();
            outsideChangeHandler({
                fieldId: field.id,
                fieldError: '',
                fieldValue: {
                    id,
                    file: {
                        description: {
                            id,
                            name: value.file.name,
                            size: value.file.size,
                            type: value.file.type,
                        },
                        entity: value.file,
                    },
                    error: value.error,
                    warning: '',
                },
            });
        },
        [field, outsideChangeHandler],
    );

    const onChange = React.useCallback(
        async (files: File[]) => {
            const file = head(files);

            if (field.constraints) {
                setFile({
                    file,
                    error: (await validate({ constraints: field.constraints, value: file }))
                        .map((errorMessage) => `• ${errorMessage}`)
                        .join('\n'),
                });
            } else {
                setFile({
                    file,
                    error: '',
                });
            }
        },
        [field],
    );

    const onAttachClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        fileInputRef?.current?.triggerOpen();
    }, []);

    const onRemove = React.useCallback(() => {
        outsideChangeHandler({
            fieldId: field.id,
            fieldError: '',
            fieldValue: null,
        });
    }, [field, outsideChangeHandler]);

    return (
        <div className={styles.root}>
            <Header field={field} />

            <div className={styles.input}>
                <UploadButton onClick={onAttachClick} />
                <FileInput
                    accept={field?.constraints?.allowedMimeTypes?.join(', ')}
                    ref={fileInputRef}
                    onChange={onChange}
                    multiple={true}
                />
                {!!field.value?.current?.file?.description && (
                    <FileAssetList
                        files={[
                            {
                                ...field.value.current.file.description,
                                error: field.value.current.error,
                                warning: null,
                            },
                        ]}
                        onRemove={onRemove}
                    />
                )}
            </div>
        </div>
    );
};
