import * as moment from 'moment';
import * as lodash from 'lodash';

import type { ActivityParams as Activity, TaskResponseParams as Task, Brief } from 'sber-marketing-types/frontend';

import { store } from '@store';
import { getBriefStateSaved } from '@store/brief/selectors';
import { ActivityApi, TaskApi, BriefApi } from '@api';

interface ConstructorParams {
    briefId: string;
    activityId: number;
    taskId: string;
}

interface StoreProps {
    initialActivity: Activity;
    changedActivity: {
        preparationDate?: moment.Moment;
        realizationStart?: moment.Moment;
        realizationEnd?: moment.Moment;
        debriefingDate?: moment.Moment;
    };
    initialTask: Task;
    changedTask: {
        deadline?: moment.Moment;
    };
    brief: Brief;
    currentBrief: Brief;
}

const IMAGE_SERVICE_STORAGE_NAME = 'imageService';
const LOCAL_STORAGE_KEY = 'copiedBrief';

export class CommonBriefSaver {
    private briefId: string;
    private activityId: number;
    private taskId: string;
    private currentBriefWasCopied: boolean;

    constructor({ briefId, activityId, taskId }: ConstructorParams) {
        this.briefId = briefId;
        this.activityId = activityId;
        this.taskId = taskId;
        this.currentBriefWasCopied = false;
    }

    public async saveBrief(): Promise<void> {
        if (this.taskId) {
            await Promise.all([this.saveTaskBrief(), this.saveTaskChanges()]);
        } else {
            await Promise.all([this.saveActivityBrief(), this.saveActivityChanges()]);
        }

        this.currentBriefWasCopied = false;
    }

    public async applyBriefScheme(schemeId: string) {
        await BriefApi.setBriefScheme(this.briefId, schemeId);
    }

    public setBriefWasCopied(value: boolean): void {
        this.currentBriefWasCopied = value;
    }

    private async saveActivityBrief() {
        const { brief, currentBrief } = this.getStoreProps();

        const isBriefHasChanged = !lodash.isEqual(brief, currentBrief);

        if (isBriefHasChanged) {
            await ActivityApi.updateActivityBrief(this.activityId, currentBrief);

            if (this.currentBriefWasCopied) {
                await this.updateBriefWithUpdateFiles();
            }
        }
    }

    private async saveTaskBrief() {
        const { brief, currentBrief } = this.getStoreProps();

        const isBriefHasChanged = !lodash.isEqual(brief, currentBrief);

        if (isBriefHasChanged) {
            await TaskApi.updateTaskBrief(this.taskId, currentBrief);

            if (this.currentBriefWasCopied) {
                await this.updateBriefWithUpdateFiles();
            }
        }
    }

    public async actualBrief(oldBrief: Brief, actualBrief: Brief) {
        const values = {};
        (oldBrief.blocks || []).forEach((block) => {
            (block.fields || []).forEach((field) => {
                values[field.properties?.parentFieldId || field.id] = field.value;
            });
        });
        const currentBrief = {
            ...oldBrief,
            schemeId: actualBrief.id,
            actualSchemeId: actualBrief.actualSchemeId,
            blocks: (actualBrief.blocks || []).map((block) => ({
                ...block,
                fields: (block.fields || []).map((field) => ({
                    ...field,
                    value: values[field.properties?.parentFieldId || field.id],
                })),
            })),
        };

        if (this.taskId) {
            await TaskApi.updateTaskBrief(this.taskId, currentBrief);
        } else {
            await ActivityApi.updateActivityBrief(this.activityId, currentBrief);
        }
    }

    private async saveActivityChanges() {
        const { initialActivity, changedActivity } = this.getStoreProps();

        const initialActivityFieldsForComparison = {
            preparationDate: moment(initialActivity.preparationDate).toISOString(),
            realizationStart: moment(initialActivity.realizationStart).toISOString(),
            realizationEnd: moment(initialActivity.realizationEnd).toISOString(),
            debriefingDate: moment(initialActivity.debriefingDate).toISOString(),
        };

        const changedActivityFieldsForComparison = {
            preparationDate: changedActivity.preparationDate.toISOString(),
            realizationStart: changedActivity.realizationStart.toISOString(),
            realizationEnd: changedActivity.realizationEnd.toISOString(),
            debriefingDate: changedActivity.debriefingDate.toISOString(),
        };

        const isActivityFieldsHasChanged = !lodash.isEqual(
            initialActivityFieldsForComparison,
            changedActivityFieldsForComparison,
        );

        if (isActivityFieldsHasChanged) {
            await ActivityApi.editActivity(this.activityId, {
                preparationDate: changedActivity.preparationDate.toISOString(),
                realizationStart: changedActivity.realizationStart.toISOString(),
                realizationEnd: changedActivity.realizationEnd.toISOString(),
                debriefingDate: changedActivity.debriefingDate.toISOString(),
            });
        }
    }

    private async saveTaskChanges() {
        const { initialTask, changedTask } = this.getStoreProps();

        const isTaskHasChanged = !lodash.isEqual(
            moment(initialTask.deadline).toISOString(),
            changedTask.deadline.toISOString(),
        );

        if (isTaskHasChanged) {
            await TaskApi.editTask(this.taskId, {
                deadline: changedTask.deadline.toISOString(),
            });
        }
    }

    private async updateBriefWithUpdateFiles(): Promise<void> {
        const { currentBrief } = this.getStoreProps();
        const copiedBrief = this.getCopiedBrief();
        const notImagesFilesIds: string[] = this.getNotImageFilesIdsFromBrief(currentBrief);

        if (copiedBrief && copiedBrief.id) {
            const filesParams = notImagesFilesIds.map((fileIid) => ({
                briefId: currentBrief.id,
                oldContainerName: `brief-${copiedBrief.id}`,
                oldFileName: fileIid,
                newContainerName: `brief-${currentBrief.id}`,
                newFileName: fileIid,
            }));

            await Promise.all(filesParams.map((params) => BriefApi.updateFileBrief(params)));
        }
    }

    private getNotImageFilesIdsFromBrief(brief: Brief): string[] {
        const notFilesImagesIds: string[] = [];
        const filesImagesIds: string[] = [];

        brief.blocks.forEach((block) => {
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    field.value.files.forEach((file) => {
                        const isFileImage = file.storage === IMAGE_SERVICE_STORAGE_NAME;

                        if (isFileImage) {
                            filesImagesIds.push(String(file.id));
                        }
                    });
                }
            });
        });

        brief.blocks.forEach((block) => {
            block.fields.forEach((field) => {
                if (field.value && field.value.files) {
                    field.value.files.forEach((file) => {
                        const notFileImage = !lodash.includes(filesImagesIds, String(file.id));

                        if (notFileImage) {
                            notFilesImagesIds.push(String(file.id));
                        }
                    });
                }
            });
        });

        return notFilesImagesIds;
    }

    private getCopiedBrief(): Brief {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { initialActivity, changedActivity, initialTasks, changedTasks, briefs, currentBriefs } =
            getBriefStateSaved(storeState);

        return {
            initialActivity,
            changedActivity,
            initialTask: initialTasks[this.briefId],
            changedTask: changedTasks[this.briefId],
            brief: briefs[this.briefId],
            currentBrief: currentBriefs[this.briefId],
        };
    }
}
