// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-autopilot-PlacementList-Fields-common-ConstraintList-_styles__root{font-family:'Open Sans';font-size:12px;line-height:16px;color:#7e8681;letter-spacing:0.15px;margin-top:4px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/autopilot/PlacementList/Fields/common/ConstraintList/styles.scss"],"names":[],"mappings":"AAAA,sGACI,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACd,qBAAsB,CACtB,cAAe","sourcesContent":[".root {\n    font-family: 'Open Sans';\n    font-size: 12px;\n    line-height: 16px;\n    color: #7e8681;\n    letter-spacing: 0.15px;\n    margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-autopilot-PlacementList-Fields-common-ConstraintList-_styles__root"
};
export default ___CSS_LOADER_EXPORT___;
