import * as lodash from 'lodash';

import type { AutopilotDOOH } from 'sber-marketing-types/backend';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum LineType {
    Line = 'line',
    Total = 'total',
}

export const enum ColumnName {
    BriefBudget = 'briefBudget',
    BudgetNet = 'budgetNet',
    Ots = 'ots',
    Reach20 = 'reach20',
    SideQuantity = 'sideQuantity',
    AvgCpt = 'avgCpt',
    Timings = 'timings',
    Prediction = 'prediction',
    KpiCost = 'kpiCost',
}

export const enum CellType {
    Text = 'text',
    Input = 'input',
    FundsInput = 'fundsInput',
}

export interface AccessorParams {
    id?: string;
    line?: AutopilotDOOH;
    allLines?: any[];
}

export type Accessor<T> = (params: AccessorParams) => T;
export type AccessorWithValue<T> = (params: AccessorParams, value: any) => T;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;

export interface ColumnParams {
    title: string | [string, string] | Accessor<string | [string, string]>;
    headerType: ColumnHeaderType;
    type: Record<LineType, CellType>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, boolean>>;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>>;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Record<LineType, ValueAccessor>;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>>;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>>;
}

export interface ColumnsConfigParams {
    displayPrediction: boolean;
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.BriefBudget]: {
        title: 'Бюджет из брифа до НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.brief.budget,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.BudgetNet]: {
        title: 'Бюджет расчетный до НДС',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line?.mediaplan?.total?.budget?.net,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Ots]: {
        title: 'OTS, тыс.',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => Math.round(params.line?.mediaplan?.total?.ots),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Reach20]: {
        title: 'Reach 20+, %',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => roundNumber(params.line.mediaplan.total.campaignReach20Plus),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.SideQuantity]: {
        title: 'Количество сторон',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => params.line.mediaplan.total.sideQuantity,
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.AvgCpt]: {
        title: 'СРТ',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => roundNumber(params.line.mediaplan.total.avgCpt),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Timings]: {
        title: 'Хронометражи в блоках',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                let timings: React.ReactText[] = params.line.mediaplan.total.timings;

                if (params.line.mediaplan.total.timings.includes('STATIC')) {
                    timings = lodash.without(timings, 'STATIC');
                }

                timings = timings.map((item) => `${item} сек`);

                if (params.line.mediaplan.total.timings.includes('STATIC')) {
                    timings.push('статика');
                }

                return timings.join(', ');
            },
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.Prediction]: {
        title: (params: AccessorParams) => params.line.mediaplan.total.prediction?.kpi,
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.Text,
            [LineType.Total]: null,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) =>
                Math.round(
                    lodash.sumBy(params.line.mediaplan.total.prediction?.value || [], (item) => item?.value || 0),
                ),
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
    [ColumnName.KpiCost]: {
        title: 'Стоимость за KPI',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Line]: CellType.FundsInput,
            [LineType.Total]: null,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Line]: true,
            [LineType.Total]: true,
        },
        getValue: {
            [LineType.Line]: (params: AccessorParams) => {
                const prediction = lodash.sumBy(
                    params.line.mediaplan.total.prediction?.value || [],
                    (item) => item?.value || 0,
                );
                const budgetSum = params.line.mediaplan.total.budget.net || 0;

                return prediction !== 0 ? Math.round(budgetSum / prediction) : 0;
            },
            [LineType.Total]: (params: AccessorParams) => null,
        },
    },
});

export const MakeTableColumns: (params: ColumnsConfigParams) => string[] = (params) => [
    ColumnName.BriefBudget,
    ColumnName.BudgetNet,
    ColumnName.Ots,
    ColumnName.Reach20,
    ColumnName.SideQuantity,
    ColumnName.AvgCpt,
    ColumnName.Timings,
    ...(params.displayPrediction ? [ColumnName.Prediction, ColumnName.KpiCost] : []),
];

export const leftFixedColumns: ColumnName[] = [];

export const rightFixedColumns: ColumnName[] = [];

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (lodash.isNil(value)) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}
