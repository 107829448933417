import * as React from 'react';
import classnames from 'classnames';
import * as lodash from 'lodash';

import * as styles from './PlanTransferModal.scss';

import { BudgetItem, Month } from '@mrm/budget';
import type { CreativeRequest, CreativeRequestItem } from '@api';

import {
    Button_redesign as Button,
    ButtonTheme,
    Icon,
    IconType,
    LabeledSelect_redesign as LabeledSelect,
    Popup,
} from 'sber-marketing-ui';
import { IdSelector } from './IdSelector';
import { IdCard } from './IdCard';
import { Amount } from './Amount';
import { formatAmount, getIdCardData } from './utils';
import { Months, useMonthItem, useAmount } from './hooks';
import { MonthItems } from './MonthItems/MonthItems';
import { MonthList } from './consts';
import { BudgetItemApi } from '@api';

export enum Itemtype {
    CAPEX = 'capex',
    OPEX = 'opex',
}

export enum BudgetItemDirection {
    to = 'to',
    from = 'from',
}

interface Props {
    sourceBudgetItems?: BudgetItem[];
    executionBudgetItems?: BudgetItem[];
    initialExecutorItemId?: string;
    creativeRequest: CreativeRequest;
    creativeRequestItems: CreativeRequestItem[];
    budgetItemType: Itemtype;
    onSave: () => void;
    onClose: () => void;
}

export const PlanTransferModal = ({
    sourceBudgetItems,
    executionBudgetItems,
    initialExecutorItemId,
    creativeRequest,
    creativeRequestItems,
    budgetItemType,
    onSave,
    onClose,
}: Props): JSX.Element => {
    const initialSourseItem = sourceBudgetItems[0];
    const initialExecutorItem = executionBudgetItems.find((item) => item.id === initialExecutorItemId);

    const [creationLoading, setCreationLoading] = React.useState<boolean>(false);
    const [sourceItem, setSourceItem] = React.useState<BudgetItem | null>(initialSourseItem || null);
    const [executorItem, setExecutorItem] = React.useState<BudgetItem | null>(initialExecutorItem || null);

    const monthIndex = new Date().getMonth();
    const [mounth, setMounth] = React.useState<Month>(MonthList[monthIndex === 11 ? 11 : monthIndex + 1].value);

    const factAmount = lodash.sumBy(creativeRequestItems, (item) =>
        budgetItemType === Itemtype.CAPEX ? item.model.actualCostWithVat : item.model.actualCostWithVat,
    );

    const { allMonthItemList, sourceMonthItemList, editMothItem } = useMonthItem({
        sourceItem,
        sourceBudgetItems,
    });

    const { amountFrom, amountTo, willAmountSource, wilAmountExecutor } = useAmount({
        currentSourseItem: initialSourseItem,
        currentExecutorItem: initialExecutorItem,
        allMonthItemList,
        sourceMonthItemList,
    });

    const handleCreateButton = async () => {
        const promises: Promise<any>[] = allMonthItemList
            .filter((item) => item?.value > 0)
            .map((item) =>
                BudgetItemApi.transferPlanFunds({
                    acceptorId: executorItem.id,
                    acceptorMonth: mounth,
                    comment: '',
                    donorId: sourceItem.id,
                    donorMonth: item.month as Month,
                    expertId: null,
                    value: item.value,
                }),
            );

        setCreationLoading(true);

        try {
            const correctionIds = await Promise.all(promises);

            await creativeRequest.model.addCorrections({ correctionIds });

            onSave();
            onClose();
        } catch (err) {
            console.log('handleCreateButton', err);
            onClose();
        }

        setCreationLoading(false);
    };

    return (
        sourceItem &&
        executorItem && (
            <Popup onOutOfContentClick={onClose}>
                <div className={styles.popup}>
                    <div className={styles.modalHeader}>
                        <div>Внесение плана в ID исполнения для заявки на креатив</div>
                        <Icon type={IconType.CROSS} color="#000" onClick={onClose} />
                    </div>

                    <div className={styles.fromToSectionsContainer}>
                        <div className={styles.section}>
                            <IdSelector
                                title="ID источник"
                                IDsList={sourceBudgetItems}
                                selectedItem={sourceItem}
                                setID={setSourceItem}
                                directionType={BudgetItemDirection.from}
                                budgetItemType={budgetItemType}
                            />
                        </div>
                        <div className={styles.section}>
                            <IdSelector
                                title="ID исполнениня"
                                selectedItem={executorItem}
                                directionType={BudgetItemDirection.to}
                                budgetItemType={budgetItemType}
                            />
                        </div>
                    </div>

                    <div className={styles.fromToSectionsContainer}>
                        <div className={styles.section}>
                            <IdCard
                                data={getIdCardData({
                                    budgetItem: sourceItem,
                                    directionType: BudgetItemDirection.from,
                                })}
                                directionType={BudgetItemDirection.from}
                            />
                        </div>
                        <div className={styles.section}>
                            <IdCard
                                data={getIdCardData({
                                    budgetItem: executorItem,
                                    directionType: BudgetItemDirection.to,
                                })}
                                directionType={BudgetItemDirection.to}
                            />
                            <LabeledSelect
                                title={'Месяц'}
                                items={MonthList}
                                selectedValue={mounth}
                                onSelection={(value) => setMounth(value as Month)}
                            />
                        </div>
                    </div>

                    <Amount
                        source={sourceItem.serialNumber}
                        executor={executorItem.serialNumber}
                        amountFrom={amountFrom}
                        amountTo={amountTo}
                        willAmountSource={willAmountSource}
                        wilAmountExecutor={wilAmountExecutor}
                        month={Months[mounth]}
                    />

                    <div className={classnames(styles.monthInfoContainer, styles.text)}>
                        <span>
                            Для переноса бюджета из{' '}
                            <span className={styles.boldGrey}>ID {sourceItem?.serialNumber}</span> доступно ячеек:{' '}
                            <span className={styles.boldGrey}>{sourceMonthItemList?.length}</span>
                        </span>
                        <span>
                            Фактическая стоимость {budgetItemType === Itemtype.CAPEX ? 'c' : 'без'} НДС:{' '}
                            <span className={styles.blueText}>{formatAmount(factAmount)}</span>
                        </span>
                    </div>

                    <MonthItems sourceMonthItemList={sourceMonthItemList} editMothItem={editMothItem} />

                    <div className={styles.buttonGroup}>
                        <Button theme={ButtonTheme.SberOutlineLong} onClick={onClose}>
                            Отменить
                        </Button>
                        <Button
                            theme={ButtonTheme.SberPrimaryLong}
                            disabled={creationLoading}
                            onClick={handleCreateButton}
                        >
                            {creationLoading ? 'Подождите...' : 'Создать'}
                        </Button>
                    </div>
                </div>
            </Popup>
        )
    );
};
