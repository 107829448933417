import * as React from 'react';

import * as styles from './IdCard.scss';

import { formatAmount } from '../utils';

interface Props {
    division: string;
    tool: string;
    direction: string;
    item: string;
    planByYear: number;
    reserveByYear: number;
}

export enum IdCardTypes {
    to = 'to',
    from = 'from',
}

export const IdCard = ({ division, tool, direction, item, planByYear, reserveByYear }: Props): JSX.Element => {
    return (
        <div className={styles.root}>
            <div className={styles.column}>
                <div className={styles.row}>
                    <span>МВЗ/Дивизион: </span>
                    <span className={styles.bold}>{division || '-'}</span>
                </div>
                <div className={styles.row}>
                    <span>Инструмент: </span>
                    <span className={styles.bold}>{tool || '-'}</span>
                </div>
                <div className={styles.row}>
                    <span>Направление: </span>
                    <span className={styles.bold}>{direction || '-'}</span>
                </div>
                <div className={styles.row}>
                    <span>Статья: </span>
                    <span className={styles.bold}>{item || '-'}</span>
                </div>
            </div>
            <div className={styles.column}>
                <div className={styles.row}>
                    <span>План за год: </span>
                    <span className={styles.bold}>{formatAmount(planByYear)}</span>
                    <span>₽</span>
                </div>
                <div className={styles.row}>
                    <span>Резерв зв год: </span>
                    <span className={styles.bold}>{formatAmount(reserveByYear)}</span>
                    <span>₽</span>
                </div>
                <div className={styles.row}>
                    <span>Остаток за год: </span>
                    <span className={styles.bold}>{formatAmount(planByYear - reserveByYear)}</span>
                    <span>₽</span>
                </div>
            </div>
        </div>
    );
};
