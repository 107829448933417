import { bindActionCreators } from 'redux';
import * as lodash from 'lodash';
import * as url from 'url';
import * as querystring from 'querystring';

import type { Activity } from '@mrm/activity';
import { DictionaryType } from '@mrm/dictionary';
import { ActivityStatus, TaskPageSortBy } from 'sber-marketing-types/frontend';
import type {
    DepartmentAttributes as Department,
    OrganizationView as Organization,
    TaskAttributes as Task,
    UserResponseParams as User,
} from 'sber-marketing-types/frontend';
import { UserConfigType } from 'sber-marketing-types/openid';
import type { User as CurrentUser } from '@store/user/types';
import type {
    PageData,
    CalendarUserConfig,
    ResponsibleUser,
    GroupedTasks,
    Filters,
    Stage,
} from '@store/calendar/types';

import { store } from '@store';
import { getLoginUser } from '@store/user/selector';
import { loadPageData, setFilters } from '@store/calendar/actions';
import { getFilters, getPageData } from '@store/calendar/selectors';
import {
    ActivityApi,
    CalendarApi,
    DepartmentApi,
    OrganizationApi,
    TaskApi,
    UserApi,
    UserConfigApi,
    DictionaryApi,
    StageApi,
} from '@api';

const PREFIX_DIVISION_ID = 'block';

interface StoreProps {
    user: CurrentUser;
    filters: Filters;
    pageData: PageData;
}

export class Loader {
    private static instance: Loader;

    private dispatch = bindActionCreators(
        {
            loadPageData,
            setFilters,
        },
        store.dispatch,
    );

    public static getInstance(): Loader {
        if (!Loader.instance) {
            Loader.instance = new Loader();
        }
        return Loader.instance;
    }

    public async init() {
        await this.initPageData();

        const query = this.getUrlQuery();
        const shouldInitFromUserConfig = lodash.isEmpty(query);

        if (shouldInitFromUserConfig) {
            await this.initFromUserConfig();
        } else {
            this.initPageFilters();
        }
    }

    public async initPageData() {
        const pageData = await this.getPageData();
        this.dispatch.loadPageData(pageData);
    }

    public initPageFilters() {
        const { user } = this.getStoreProps();

        const userOrganizationId = user.attributes.organizationId;

        this.dispatch.setFilters({
            organizationIds: [userOrganizationId],
        });
    }

    public async initFromUserConfig() {
        const userConfig = (await UserConfigApi.getPageConfig(UserConfigType.Calendar)) as CalendarUserConfig;

        const filters = userConfig.filters || {};
        const updatedFilters = this.mergeUserConfigFilters(filters);

        this.dispatch.setFilters(updatedFilters);
    }

    public async loadStagesByCalendarGroupId(organizationId: string): Promise<void> {
        const { stages, activities } = this.getStoreProps().pageData;

        const activityIds = activities
            .filter((activity) => activity.calendarGroupId === organizationId)
            .map((activity) => activity.id);
        const activityIdsToLoadDataFor = activityIds.filter(
            (activityId) => !stages.some((stage) => stage.activityId === activityId),
        );

        if (activityIdsToLoadDataFor.length) {
            const updStages: Stage[] = (await StageApi.graphqlGetActivityStages(activityIdsToLoadDataFor)).reduce(
                (acc, activityWithStage) => {
                    if (activityWithStage.stages) {
                        activityWithStage.stages.forEach((stage) => {
                            if (stage.isActive) {
                                acc.push({
                                    id: stage.id,
                                    name: stage.dictionary?.name,
                                    activityId: +activityWithStage.id,
                                    start: stage.start,
                                    end: stage.end,
                                    done: stage.isDone,
                                    weight: stage.dictionary?.weight,
                                });
                            }
                        });
                    }

                    return acc;
                },
                [...stages],
            );

            this.dispatch.loadPageData({
                stages: updStages,
            });
        }
    }

    private async getPageData(): Promise<PageData> {
        const { user } = this.getStoreProps();

        const userOrganizationId = user.attributes.organizationId;

        const { activities, sharedActivities, hasAccess } = await CalendarApi.getCalendar();

        const allActivities = lodash.uniqBy([...activities, ...sharedActivities], (item) => item.id);
        const filteredActivities = this.filterActivities(allActivities); // с датами и не в драфте и плане

        const userIds = lodash.uniq(lodash.flatMap(filteredActivities, (item) => [item.responsibleId, item.authorId]));
        const users = await UserApi.getUserList(userIds);

        const organizationIds = lodash.uniq([
            userOrganizationId,
            ...lodash.uniq(sharedActivities.map((item) => item.organizationId)),
            ...users.map((item) => item.organizationId),
        ]);

        const activityIds = filteredActivities.map((item) => item.id);
        const activityTypeIds = lodash.uniq(filteredActivities.map((item) => item.typeId));
        const productIds = lodash.compact(lodash.uniq(filteredActivities.map((item) => item.productId)));

        const activityAuthors = users.filter((user) => filteredActivities.some((item) => item.authorId == user.id));
        const responsibleUsers = users.filter((user) =>
            filteredActivities.some((item) => item.responsibleId == user.id),
        );

        const stageTemplates = await DictionaryApi.getDictionaryList({
            organizationId: userOrganizationId,
            types: [DictionaryType.StageTemplate],
            treeview: true,
        });

        const [organizations, activityTypes, tasks, products, departments] = await Promise.all([
            OrganizationApi.getOrganization({ ids: organizationIds }),
            ActivityApi.getActivityTypes({ ids: activityTypeIds }),
            this.getTasksByActivityIds(activityIds),
            !lodash.isEmpty(productIds) ? DictionaryApi.getDictionariesByIds({ ids: productIds }) : [],
            DepartmentApi.getList(),
        ]);

        const divisionIds = lodash.uniq(
            filteredActivities.filter((item) => item.divisionId).map((item) => item.divisionId),
        );

        const blocks = await DictionaryApi.getDictionariesByIds({ ids: divisionIds });

        const calendarGroupIds = lodash.uniq(
            filteredActivities.filter((item) => item.calendarGroupId).map((item) => item.calendarGroupId),
        );
        const calendarGroups = await DictionaryApi.getDictionariesByIds({ ids: calendarGroupIds });
        const calendarGroupsNames = calendarGroups.reduce((acc, item) => ({ ...acc, [item.id]: item.value }), {});

        const departmentIds = lodash.compact(lodash.uniq(activityAuthors.map((item) => item.departmentId)));

        return {
            organizations: this.sortOrganizations(organizations, userOrganizationId),
            activities: this.sortActivitiesByDate(filteredActivities),
            accessibleActivitiesIds: hasAccess,
            tasks: this.groupTasks(tasks),
            activityTypes,
            responsibleUsers: this.formatUsers(responsibleUsers),
            blocks,
            calendarGroups: calendarGroups.sort((a, b) => (a.value < b.value ? -1 : 1)),
            calendarGroupsNames,
            departments: this.filterDepartments(departments, departmentIds),
            products,
            activityAuthors,
            stageTemplates,
        };
    }

    private mergeUserConfigFilters(userConfigFilters: Filters): Filters {
        const { organizations, products, activityTypes, responsibleUsers, departments, divisions } =
            this.getStoreProps().pageData;

        const organizationIds = organizations.map((organization) => organization.id);
        const productIds = products.map((products) => products.id);
        const activityTypeIds = activityTypes.map((activityType) => activityType.id);
        const responsibleUserIds = responsibleUsers.map((responsibleUser) => responsibleUser.id);
        const departmentIds = departments.map((department) => department.id);
        const divisionIds = divisions.map((division) => division.id);

        const filteredOrganizationIds = userConfigFilters.organizationIds
            ? this.filterIdsByRelevantIds(userConfigFilters.organizationIds, organizationIds)
            : [];

        const filteredProductIds = userConfigFilters.productIds
            ? this.filterIdsByRelevantIds(userConfigFilters.productIds, productIds)
            : [];

        const filteredActivityTypesIds = userConfigFilters.activityTypeIds
            ? this.filterIdsByRelevantIds(userConfigFilters.activityTypeIds, activityTypeIds)
            : [];

        const filteredResponsibleUsersIds = userConfigFilters.responsibleUserIds
            ? this.filterIdsByRelevantIds(userConfigFilters.responsibleUserIds, responsibleUserIds)
            : [];

        const filteredDepartmentIds = userConfigFilters.departmentIds
            ? this.filterIdsByRelevantIds(userConfigFilters.departmentIds, departmentIds)
            : [];

        const filteredDivisionIds = userConfigFilters.divisionIds
            ? this.mergeFilterDivisionIds(userConfigFilters.divisionIds, divisionIds)
            : [];

        return {
            ...userConfigFilters,
            organizationIds: filteredOrganizationIds,
            productIds: filteredProductIds,
            activityTypeIds: filteredActivityTypesIds,
            responsibleUserIds: filteredResponsibleUsersIds,
            departmentIds: filteredDepartmentIds,
            divisionIds: filteredDivisionIds,
        };
    }

    private mergeFilterDivisionIds(divisionIds: string[], relevantDivisionIds: string[]): string[] {
        const blockIds = this.getBlockIdsFromDivisionIds(divisionIds);
        const allDivisionIds = this.getAllDivisionIds(divisionIds);

        const filteredAllDivisionIds = this.filterIdsByRelevantIds(allDivisionIds, relevantDivisionIds);
        const filteredBlockIds = this.filterIdsByRelevantIds(blockIds, filteredAllDivisionIds);

        return [...filteredAllDivisionIds, ...this.addBlockPrefixesInDivisionIds(filteredBlockIds)];
    }

    private getBlockIdsFromDivisionIds(ids: string[]): string[] {
        const divisionIdsWithBlockPrefix = lodash.uniq(this.getDivisionIdsWithBlockPrefix(ids));
        return this.removeBlockPrefixesOfDivisionIds(divisionIdsWithBlockPrefix);
    }

    private getAllDivisionIds(ids: string[]): string[] {
        return lodash.uniq(this.removeBlockPrefixesOfDivisionIds(ids));
    }

    private filterIdsByRelevantIds<T>(ids: T[], relevantIds: T[]): T[] {
        return ids.filter((id) => lodash.includes(relevantIds, id));
    }

    private getDivisionIdsWithBlockPrefix(divisionIds: string[]): string[] {
        return divisionIds.filter((divisionId) => {
            return new RegExp(`^${PREFIX_DIVISION_ID}`).test(divisionId);
        });
    }

    private removeBlockPrefixesOfDivisionIds(divisionIds: string[]): string[] {
        return divisionIds.map((divisionId) => this.removeBlockPrefix(divisionId));
    }

    private addBlockPrefixesInDivisionIds(divisionIds: string[]): string[] {
        return divisionIds.map((divisionId) => this.addBlockPrefix(divisionId));
    }

    private removeBlockPrefix(str: string): string {
        return str.replace(new RegExp(`^${PREFIX_DIVISION_ID}`), '');
    }

    private addBlockPrefix(str: string): string {
        return `${PREFIX_DIVISION_ID}${str}`;
    }

    private async getTasksByActivityIds(activityIds: number[]): Promise<Task[]> {
        const TASKS_PER_PAGE = 10000;

        const tasks = await TaskApi.getTaskList({
            activityIds,
            sortBy: TaskPageSortBy.DEADLINE,
            offset: 0,
            limit: TASKS_PER_PAGE,
        });

        return tasks;
    }

    private filterActivities(activities: Activity[]): Activity[] {
        return activities.filter(
            (activity) =>
                activity.realizationStart &&
                activity.realizationEnd &&
                activity.status !== ActivityStatus.Draft &&
                activity.status !== ActivityStatus.Planned,
        );
    }

    private sortOrganizations(organizations: Organization[], userOrganizationId: string): Organization[] {
        const userOrganization = organizations.find((item) => item.id == userOrganizationId);

        let result = lodash.without(organizations, userOrganization);

        result = lodash.sortBy(result, (item) => item.name);

        result.unshift(userOrganization);

        return result;
    }

    private sortActivitiesByDate(activities: Activity[]): Activity[] {
        return activities.sort(
            (a, b) => new Date(a.realizationStart).getTime() - new Date(b.realizationStart).getTime(),
        );
    }

    private groupTasks(tasks: Task[]): GroupedTasks {
        const sortedTasks = tasks.sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime());

        return lodash.groupBy(sortedTasks, (item) => item.activityId);
    }

    private formatUsers(users: User[]): ResponsibleUser[] {
        return users.map((item) => ({
            id: item.id,
            name: `${item.secondName} ${item.firstName}`,
            organizationId: item.organizationId,
        }));
    }

    // private getActivityStages(activityStageIds: string[]): Promise<ActivityStage[]> {
    //     return ActivityStageApi.listActivityStage(activityStageIds);
    // }

    private filterDepartments(departments: Department[], departmentIds: string[]): Department[] {
        const departmentsById = lodash.keyBy(departments, (item) => item.id);

        const filteredIds = [...departmentIds, ...lodash.flatMap(departmentIds, (id) => getParentsIds(id))];

        function getParentsIds(id: string): string[] {
            const { parentDepartmentId } = departmentsById[id];

            if (parentDepartmentId == null) {
                return [];
            }

            const parent = departmentsById[parentDepartmentId];

            return [parent.id, ...getParentsIds(parent.id)];
        }

        return lodash.uniq(filteredIds).map((id) => departmentsById[id]);
    }

    private getUrlQuery(): Object {
        const { query } = url.parse(window.location.href);

        return querystring.parse(query);
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        return {
            user: getLoginUser(storeState),
            filters: getFilters(storeState),
            pageData: getPageData(storeState),
        };
    }
}
