import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Description, Highlight } from '@sbermarketing/mrm-ui';
import { Icon, IconType, WithTooltip, TooltipAnchor } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getSearchState, setVisibility as setSearchVisibility } from '@store/search';
import { ComponentState, getProfileMenuState, openProfileMenu } from '@store/profileMenu';

import { useOnboarding } from '@modules/onboarding/hooks';

import * as styles from './QuickAccessIcons.scss';

export function QuickAccessIcons(): JSX.Element {
    return (
        <div className={styles.root}>
            <SearchButton />
            <ProfileButton />
        </div>
    );
}

function useSearchButtonStore() {
    const dispatch = useDispatch();
    const { id, cancel } = useOnboarding();
    const isOnboarding = id === 'searchActivity';

    const isActive = useSelector((state: StoreState) => getSearchState(state).isVisible);

    function onClick() {
        cancel();
        dispatch(setSearchVisibility(true));
    }

    return { isOnboarding, isActive, onClick };
}

export function SearchButton(): JSX.Element {
    const { isOnboarding, ...buttonProps } = useSearchButtonStore();
    const buttonRef = React.useRef<HTMLDivElement>();

    return (
        <>
            <Button {...buttonProps} rootRef={buttonRef} tooltip="Поиск" qaId="searchButton" icon={IconType.SEARCH} />
            <Highlight placement="left" hide={!isOnboarding} target={buttonRef}>
                <Description weight="bold">Найти проект, задачу, комментарий</Description>
            </Highlight>
        </>
    );
}

function useProfileButtonStore() {
    const dispatch = useDispatch();

    const isActive = useSelector(
        (state: StoreState) => getProfileMenuState(state).componentState !== ComponentState.Closed,
    );

    function onClick() {
        dispatch(openProfileMenu());
    }

    return { isActive, onClick };
}

function ProfileButton(): JSX.Element {
    const storeProps = useProfileButtonStore();

    return (
        <div className={styles.profileMenu}>
            <Button {...storeProps} tooltip="Профиль" qaId="profileButton" icon={IconType.USER} iconSize={21} />
        </div>
    );
}

interface ButtonProps {
    rootRef?: React.MutableRefObject<HTMLDivElement>;
    tooltip: string;
    qaId: string;
    icon: IconType;
    iconSize?: number;
    isActive: boolean;
    onClick: () => void;
}

function Button({ rootRef, tooltip, qaId, icon, iconSize = 18, isActive, onClick }: ButtonProps): JSX.Element {
    return (
        <WithTooltip content={tooltip} anchor={TooltipAnchor.BOTTOM}>
            <div
                ref={rootRef}
                className={styles.buttonWrapper}
                {...{
                    'qa-id': qaId,
                }}
            >
                <Icon
                    type={icon}
                    className={classNames(styles.button, isActive && styles.button_active)}
                    onClick={onClick}
                    svgSize={iconSize}
                />
            </div>
        </WithTooltip>
    );
}
