import * as React from 'react';
import classNames from 'classnames';

import * as style from './TimeDistributionTable.scss';

import type { CellEvent, CellPosition, ColumnName, ColumnWidths, LineId } from './types';

import { TableViewModel } from './TableViewModel';
import { TableView } from './TableView';

interface Props {
    viewModel: TableViewModel;
    columns: ColumnName[];
    lineIds: LineId[];
    columnWidths: ColumnWidths;
    isDistributeByConfigButtonDisabled: boolean;
    tableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onColumnWidthsChange: (columnWidths: ColumnWidths) => void;
    onClearButtonClick: () => void;
    onDistributeEvenlyButtonClick: () => void;
    onDistributeByConfigButtonClick: () => void;
}

export const TimeDistributionTableTemplate = ({
    viewModel,
    columns,
    lineIds,
    columnWidths,
    isDistributeByConfigButtonDisabled,
    tableRef,
    onCellEvent,
    onColumnWidthsChange,
    onClearButtonClick,
    onDistributeEvenlyButtonClick,
    onDistributeByConfigButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div className={style.title}>Распределение хронометражей по неделям</div>

                <div className={style.buttons}>
                    <div className={classNames(style.button, style.gray)} onClick={onClearButtonClick}>
                        Очистить
                    </div>

                    <div
                        className={classNames(
                            style.button,
                            style.blue,
                            isDistributeByConfigButtonDisabled && style.disabled,
                        )}
                        onClick={isDistributeByConfigButtonDisabled ? () => {} : onDistributeByConfigButtonClick}
                    >
                        Уменьшение среднего
                    </div>

                    <div className={classNames(style.button, style.blue)} onClick={onDistributeEvenlyButtonClick}>
                        Равномерно
                    </div>
                </div>
            </div>

            <div className={style.table}>
                <TableView
                    ref={tableRef}
                    viewModel={viewModel}
                    columns={columns}
                    leftFixedColumns={[]}
                    rightFixedColumns={[]}
                    lines={lineIds}
                    columnWidths={columnWidths}
                    onCellEvent={onCellEvent}
                    onColumnWidthsChange={onColumnWidthsChange}
                />
            </div>
        </div>
    );
};
