import * as React from 'react';
// import * as classnames from 'classnames';

import * as style from './BriefStageActions.scss';

import { Icon, IconType } from 'sber-marketing-ui';
import { Button } from './Button';

interface Props {
    canEdit: boolean;
    hasErrors: boolean;
    isButtonLoading: boolean;
    filledFieldsCount: number;
    onButtonClick: () => void;
}

const FIELDS_COUNT = 7;

export const BriefStageActionsTemplate = ({
    canEdit,
    hasErrors,
    isButtonLoading,
    filledFieldsCount,
    onButtonClick,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <div className={style.title}>
                Заполните бриф
                {filledFieldsCount === FIELDS_COUNT && !hasErrors && (
                    <div className={style.titleIcon}>
                        <Icon type={IconType.ACCEPT_ACTIVE} svgSize={24} />
                    </div>
                )}
                {(filledFieldsCount < 7 || hasErrors) && (
                    <>
                        &nbsp;
                        <span className={style.counter}>
                            ({filledFieldsCount}/{FIELDS_COUNT})
                        </span>
                    </>
                )}
            </div>

            <Button
                disabled={!canEdit || filledFieldsCount < FIELDS_COUNT || hasErrors}
                loading={isButtonLoading}
                onClick={onButtonClick}
            >
                Рассчитать медиаплан
            </Button>
        </div>
    );
};
