// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-EmptySourseLinesMessage-_EmptySourseLinesMessage__root{display:block}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-EmptySourseLinesMessage-_EmptySourseLinesMessage__title{font-family:'Open Sans';font-weight:600;font-size:14px;line-height:20px;color:#092a3a;user-select:none;margin-bottom:8px}.src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-EmptySourseLinesMessage-_EmptySourseLinesMessage__message{height:80px;width:100%;display:flex;align-items:center;justify-content:center;font-family:'Open Sans';font-size:12px;color:#7e8681;border-radius:6px;background-color:#e6edf1}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/ExecitionIdWidget/ExecitionIdTable/EmptySourseLinesMessage/EmptySourseLinesMessage.scss"],"names":[],"mappings":"AAAA,+JACI,aAAc,CACjB,gKAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CACd,gBAAiB,CACjB,iBAAkB,CACrB,kKAGG,WAAY,CACZ,UAAW,CACX,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,uBAAwB,CACxB,cAAe,CACf,aAAc,CACd,iBAAkB,CAClB,wBAAyB","sourcesContent":[".root {\n    display: block;\n}\n\n.title {\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 20px;\n    color: #092a3a;\n    user-select: none;\n    margin-bottom: 8px;\n}\n\n.message {\n    height: 80px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-family: 'Open Sans';\n    font-size: 12px;\n    color: #7e8681;\n    border-radius: 6px;\n    background-color: #e6edf1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-EmptySourseLinesMessage-_EmptySourseLinesMessage__root",
	"title": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-EmptySourseLinesMessage-_EmptySourseLinesMessage__title",
	"message": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-ExecitionIdTable-EmptySourseLinesMessage-_EmptySourseLinesMessage__message"
};
export default ___CSS_LOADER_EXPORT___;
