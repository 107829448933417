import * as React from 'react';
import classnames from 'classnames';

import * as styles from './index.scss';

import { Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';
import { EditMothItem, MonthItemType } from '../hooks/useMonthItem';
import { formatAmount } from '../utils';

interface Props {
    monthItem: MonthItemType;
    editMothItem: EditMothItem;
}

export const MonthItem = ({ monthItem, editMothItem }: Props): JSX.Element => {
    const { id, month, monthName, planned, reserved, value } = monthItem;

    const delta = planned - reserved;

    // const onMaxSummClick = () => {
    //     editMothItem({id, month, value: delta})
    // }
    return (
        <div className={styles.monthItem}>
            <div className={styles.column}>
                <div className={styles.row}>
                    <div className={styles.string}>
                        <span className={styles.greyText}>Резерв:</span>
                        <span className={styles.valueText}>{formatAmount(reserved)}</span>
                    </div>
                    {/* <div className={styles.month}>
                        {monthName}
                    </div> */}
                </div>
                <div className={styles.row}>
                    <div className={styles.string}>
                        <span className={styles.greyText}>Остаток по месцу:</span>
                        <span className={styles.valueText}>{formatAmount(delta)}</span>
                    </div>
                </div>
            </div>
            <div className={classnames(styles.column, styles.depositColumn)}>
                <div className={styles.row}>
                    <span className={styles.greyText}>Введите сумму</span>
                </div>
                <div className={styles.row}>
                    <Input
                        value={value / 100}
                        valueType="floatPositive"
                        placeholder="Введите сумму"
                        theme={InputTheme.BudgetTransferMenu}
                        onInputChange={(value) => {
                            editMothItem({ id, month, value: +value * 100 });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
