import * as React from 'react';

import * as styles from './FileInput.scss';

interface Props {
    multiple: boolean;
    accept?: string;
    inputRef: React.RefObject<HTMLInputElement>;
    onChange: () => void;
}

export function FileInput({ multiple, accept, inputRef, onChange }: Props): JSX.Element {
    return (
        <input
            className={styles.root}
            accept={accept}
            type="file"
            multiple={multiple}
            ref={inputRef}
            onChange={onChange}
        />
    );
}
