// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__root{display:flex;gap:5px}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__title{font-family:\"Open Sans\";font-size:12px;line-height:16px;font-weight:400;color:#7E8681}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__current{color:#000000}.src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__link{cursor:pointer;color:#417EC6}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StagesListWidget/Statuses/Status/Status.scss"],"names":[],"mappings":"AAAA,2FACI,YAAa,CACb,OAAQ,CACX,4FAGG,uBAAwB,CACxB,cAAe,CACf,gBAAiB,CACjB,eAAgB,CAChB,aAAc,CACjB,8FAGG,aAAc,CACjB,2FAGG,cAAe,CACf,aAAc","sourcesContent":[".root {\n    display: flex;\n    gap: 5px;\n}\n\n.title {\n    font-family: \"Open Sans\";\n    font-size: 12px;\n    line-height: 16px;\n    font-weight: 400;\n    color: #7E8681;\n}\n\n.current {\n    color: #000000;\n}\n\n.link {\n    cursor: pointer;\n    color: #417EC6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__root",
	"title": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__title",
	"current": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__current",
	"link": "src-client-modules-activity-pages-creative-StagesListWidget-Statuses-Status-_Status__link"
};
export default ___CSS_LOADER_EXPORT___;
