// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-_ExecitionIdWidget__root{display:grid;grid-template-columns:470px minmax(auto, 1000px);gap:32px 16px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/StageWidgets/TableWidget/ExecitionIdWidget/ExecitionIdWidget.scss"],"names":[],"mappings":"AAAA,gHACI,YAAa,CACb,gDAAiD,CAGjD,aAAc","sourcesContent":[".root {\n    display: grid;\n    grid-template-columns: 470px minmax(auto, 1000px);\n    // grid-template-rows: auto auto;\n    // grid-auto-flow: column;\n    gap: 32px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-pages-creative-StageWidgets-TableWidget-ExecitionIdWidget-_ExecitionIdWidget__root"
};
export default ___CSS_LOADER_EXPORT___;
