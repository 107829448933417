import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import { TableBodyCellParams, CellPosition, LineId, Line, LineType } from '../../types';
import {
    ValueAccessor,
    DescriptionAccessor,
    CustomStyleAccessor,
    ReadOnlyAccessor,
    ValidationAccessor,
    AccessorParams,
    CellType,
    ColumnParams,
    ValueSetter,
} from '../../ColumnsConfig';

import * as Cells from '../../CellTypes';
import { CAPEX_ITEM_VALUE } from '../../../../consts';

export const CellComponentsByCellType: Record<
    CellType,
    {
        cell: React.ClassType<any, any, any>;
        editCell?: React.ClassType<any, any, any>;
    }
> = {
    [CellType.Text]: {
        cell: Cells.TextCell,
    },
    [CellType.AddExecutionId]: {
        cell: Cells.AddExecutionIdCell,
    },
    [CellType.Expandable]: {
        cell: Cells.ExpandableCell,
    },
    [CellType.FundsInput]: {
        cell: Cells.FundsInputCell,
        editCell: Cells.FundsInputCellEdit,
    },
    [CellType.FundsWithButton]: {
        cell: Cells.FundsWithButtonCell,
    },
};

const BACKGROUND_COLOR_BY_LINE_TYPE = {
    // [LineType.BudgetItemLine]: '#ffffff',
    [LineType.Total]: '#f6f6f6',
    [LineType.CreativeFactSum]: '#f6f6f6',
    [LineType.Delta]: '#f6f6f6',
    [LineType.LinesWithoutExecutionId]: '#f6f6f6',
};

interface Props {
    getLine: (lineId: LineId) => Line;
    getAllLines: () => Line[];
    getColumnsConfig: () => { [columnName: string]: ColumnParams };
    getAccessorParamsData: () => Partial<AccessorParams>;
    getExpandedLineIds: () => LineId[];
    onLineExpandToggle: (lineId: LineId) => void;
    onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;
}

export class CellsFactory {
    private getLine: (lineId: LineId) => Line;
    private getAllLines: () => Line[];
    private getColumnsConfig: () => { [columnName: string]: ColumnParams };
    private getAccessorParamsData: () => Partial<AccessorParams>;
    private getExpandedLineIds: () => LineId[];
    private onLineExpandToggle: (lineId: LineId) => void;
    private onModalOpen: (
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) => void;

    public constructor(props: Props) {
        this.getLine = props.getLine;
        this.getAllLines = props.getAllLines;
        this.getColumnsConfig = props.getColumnsConfig;
        this.getAccessorParamsData = props.getAccessorParamsData;
        this.getExpandedLineIds = props.getExpandedLineIds;
        this.onLineExpandToggle = props.onLineExpandToggle;
        this.onModalOpen = props.onModalOpen;
    }

    @autobind
    public async makeCellParams(cellPosition: CellPosition, edit = false): Promise<TableBodyCellParams> {
        const value = await this.getCellValue(cellPosition);

        return {
            component: value !== undefined ? this.getCellComponent(cellPosition, edit) : null,
            cellProps: value !== undefined ? await this.makeCellProps(cellPosition, edit) : null,
            readOnly: value !== undefined ? await this.checkReadOnlyStatus(cellPosition, edit) : true,
            сellBackgroundColor: this.getCellBackgroundColor(cellPosition),
            preventCloseOnClick: this.checkPreventCloseOnClickStatus(cellPosition, edit),
        };
    }

    @autobind
    public getCellComponent(cellPosition: CellPosition, edit = false): React.ClassType<any, any, any> {
        const { lineId, columnName } = cellPosition;

        const cellTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        if (!cellTypeField) {
            return null;
        }

        const lineType = this.getLineType(lineId);

        const cellType: CellType = lodash.isString(cellTypeField) ? cellTypeField : cellTypeField[lineType];

        return edit ? CellComponentsByCellType[cellType].editCell : CellComponentsByCellType[cellType].cell;
    }

    @autobind
    public async makeCellProps(cellPosition: CellPosition, edit = false): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const cellTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        if (!cellTypeField) {
            return null;
        }

        const lineType = this.getLineType(lineId);

        const cellType: CellType = lodash.isString(cellTypeField) ? cellTypeField : cellTypeField[lineType];

        let cellProps: any;

        switch (cellType) {
            case CellType.Text:
                cellProps = await this.makeTextCellProps(cellPosition);
                break;

            case CellType.AddExecutionId:
                cellProps = await this.makeAddExecutionIdCellProps(cellPosition);
                break;

            case CellType.Expandable:
                cellProps = await this.makeExpandableCellProps(cellPosition);
                break;

            case CellType.FundsInput:
                cellProps = await this.makeFundsInputCellProps(cellPosition, edit);
                break;

            case CellType.FundsWithButton:
                cellProps = await this.makeFundsWithButtonCellProps(cellPosition, edit);
                break;
        }

        cellProps = await this.applyCustomStyles(cellPosition, cellProps);

        return cellProps;
    }

    private checkPreventCloseOnClickStatus(cellPosition: CellPosition, edit = false): boolean {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const columnTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        const columnType = lodash.isString(columnTypeField) ? columnTypeField : columnTypeField[lineType];

        if (!columnType) {
            return false;
        }

        return edit && [CellType.FundsInput].includes(columnType);
    }

    private async makeTextCellProps(cellPosition: CellPosition): Promise<any> {
        const value = await this.getCellValue(cellPosition);

        return {
            title: value || '—',
        };
    }

    private async makeAddExecutionIdCellProps(cellPosition: CellPosition): Promise<any> {
        const value = await this.getCellValue(cellPosition);

        return {
            title: value || '—',
            onModalOpen: this.onModalOpen,
        };
    }

    private async makeExpandableCellProps(cellPosition: CellPosition): Promise<any> {
        const { lineId } = cellPosition;
        const { corrections } = this.getAccessorParamsData();

        const value = await this.getCellValue(cellPosition);
        const expandedLineIds = this.getExpandedLineIds();
        const lineCanBeExpanded = corrections.some((item) => item.acceptorId === lineId);

        return {
            title: value || '—',
            isExpanded: expandedLineIds.includes(lineId),
            onClick: lineCanBeExpanded ? () => this.onLineExpandToggle(lineId) : null,
        };
    }

    private async makeFundsInputCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const value = await this.getCellValue(cellPosition);
        const description = await this.getCellDescription(cellPosition);
        const validationAccessor = this.getCellValidation(cellPosition);

        const params = this.makeAccessorParams(cellPosition);

        return edit
            ? {
                  value,
                  placeholder: '',
                  validateValue: validationAccessor ? (value: string) => validationAccessor(params, value) : null,
                  onValueChange: this.makeValueChangeHandler(cellPosition),
              }
            : {
                  title: value !== null ? this.formatCurrencyValue(this.roundNumber(value)) || '—' : '—',
                  value,
                  description,
              };
    }

    private async makeFundsWithButtonCellProps(cellPosition: CellPosition, edit: boolean): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const line = this.getLine(lineId);

        const lineIsCapex = line.dictionary.item?.value === CAPEX_ITEM_VALUE;

        const value = await this.getCellValue(cellPosition);
        const description = await this.getCellDescription(cellPosition);

        return {
            title: value !== null ? this.formatCurrencyValue(this.roundNumber(value)) || '—' : '—',
            buttonTitle: description,
            onButtonClick: () =>
                this.onModalOpen(columnName === 'plan' ? 'planModal' : 'reserveModal', { lineId, capex: lineIsCapex }),
        };
    }

    private getLineType(lineId: LineId): LineType {
        let lineType: LineType;

        switch (lineId) {
            case 'placeholderLine':
                lineType = LineType.Placeholder;
                break;

            case 'total':
                lineType = LineType.Total;
                break;

            case 'creativeFactSum':
                lineType = LineType.CreativeFactSum;
                break;

            case 'delta':
                lineType = LineType.Delta;
                break;

            case 'lineIdsWithoutExecutionId':
                lineType = LineType.LinesWithoutExecutionId;
                break;

            default:
                const { groupedCorrections } = this.getAccessorParamsData();

                if (groupedCorrections.plan.some((item) => item.id === lineId)) {
                    lineType = LineType.PlanCorrection;
                } else if (groupedCorrections.reserve.some((item) => item.id === lineId)) {
                    lineType = LineType.ReserveCorrection;
                } else {
                    lineType = LineType.BudgetItemLine;
                }

                break;
        }

        return lineType;
    }

    private makeAccessorParams(cellPosition: CellPosition): AccessorParams {
        const { lineId } = cellPosition;

        const { capex, project, creativeRequest, creativeRequestItems, corrections, groupedCorrections } =
            this.getAccessorParamsData();

        return {
            lineId,
            line: this.getLine(lineId),
            allLines: this.getAllLines(),
            capex,
            project,
            creativeRequest,
            creativeRequestItems,
            corrections,
            groupedCorrections,
        };
    }

    private async getCellValue(cellPosition: CellPosition): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const accessorField = lodash.get(this.getColumnsConfig(), [columnName, 'getValue']);

        const accessor: ValueAccessor = lodash.isFunction(accessorField) ? accessorField : accessorField[lineType];

        if (!accessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return accessor(params);
    }

    private async getCellDescription(cellPosition: CellPosition): Promise<string> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const descriptionAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'getDescription']);

        if (!descriptionAccessorField) {
            return undefined;
        }

        const descriptionAccessor: DescriptionAccessor = lodash.isFunction(descriptionAccessorField)
            ? descriptionAccessorField
            : descriptionAccessorField[lineType];

        if (!descriptionAccessor) {
            return undefined;
        }

        const params = this.makeAccessorParams(cellPosition);

        return descriptionAccessor(params);
    }

    private getCellValidation(cellPosition: CellPosition): ValidationAccessor {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const validationAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'validateValue']);

        if (!validationAccessorField) {
            return undefined;
        }

        const validationAccessor: ValidationAccessor = lodash.isFunction(validationAccessorField)
            ? validationAccessorField
            : validationAccessorField[lineType];

        return validationAccessor;
    }

    @autobind
    private async checkReadOnlyStatus(cellPosition: CellPosition, edit: boolean): Promise<boolean> {
        const { lineId, columnName } = cellPosition;

        const cellComponent = this.getCellComponent(cellPosition, edit);

        if (!cellComponent) {
            return true;
        }

        const lineType = this.getLineType(lineId);

        const readOnlyStatusField: boolean | ReadOnlyAccessor = lodash.get(this.getColumnsConfig(), [
            columnName,
            'readOnly',
            lineType,
        ])
            ? lodash.get(this.getColumnsConfig(), [columnName, 'readOnly', lineType])
            : lodash.get(this.getColumnsConfig(), [columnName, 'readOnly']);

        if (readOnlyStatusField === undefined) {
            return false;
        }

        const readOnlyStatus: boolean | ReadOnlyAccessor = lodash.isFunction(readOnlyStatusField)
            ? await readOnlyStatusField(this.makeAccessorParams(cellPosition))
            : readOnlyStatusField;

        return readOnlyStatus || false;
    }

    private getCellBackgroundColor(cellPosition: CellPosition): string {
        const { lineId } = cellPosition;

        const lineType = this.getLineType(lineId);

        return BACKGROUND_COLOR_BY_LINE_TYPE[lineType] || null;
    }

    private makeValueChangeHandler(cellPosition: CellPosition) {
        return async (value: any) => {
            const cellValue = await this.getCellValue(cellPosition);

            const valueChanged = this.compareValues(value, cellValue);

            if (valueChanged) {
                const { lineId, columnName } = cellPosition;

                // this.onCellEditorClose();

                const lineType = this.getLineType(lineId);
                const params = this.makeAccessorParams(cellPosition);

                const valueSetterField = lodash.get(this.getColumnsConfig(), [columnName, 'setValue']);

                const valueSetter: ValueSetter = lodash.isFunction(valueSetterField)
                    ? valueSetterField
                    : valueSetterField[lineType];

                await valueSetter(params, value);
            }
        };
    }

    private getCellType(cellPosition: CellPosition): CellType {
        const { lineId, columnName } = cellPosition;

        const cellTypeField = lodash.get(this.getColumnsConfig(), [columnName, 'type']);

        if (!cellTypeField) {
            return null;
        }

        const lineType = this.getLineType(lineId);

        return lodash.isString(cellTypeField) ? cellTypeField : cellTypeField[lineType];
    }

    private async applyCustomStyles(cellPosition: CellPosition, cellProps: any): Promise<any> {
        const { lineId, columnName } = cellPosition;

        const lineType = this.getLineType(lineId);

        const customStyleAccessorField = lodash.get(this.getColumnsConfig(), [columnName, 'customStyle']);

        if (customStyleAccessorField) {
            const customStyleAccessor: CustomStyleAccessor = lodash.isFunction(customStyleAccessorField)
                ? customStyleAccessorField
                : customStyleAccessorField[lineType];

            if (customStyleAccessor) {
                const params = this.makeAccessorParams(cellPosition);

                const customStyle = await customStyleAccessor(params);

                cellProps = {
                    ...cellProps,
                    customStyle: { ...cellProps.customStyle, ...customStyle },
                };
            }
        }

        cellProps.customStyle = {
            ...cellProps.customStyle,
            backgroundColor: this.getCellBackgroundColor(cellPosition),
        };

        return cellProps;
    }

    private roundNumber(value: number, digitsAfterComma = 2): string {
        const roundedValue = Math.round(value * 100) / 100;
        const formatedValue = roundedValue.toFixed(digitsAfterComma);

        const [decimalPart, fractionPart] = formatedValue.split('.');

        return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
    }

    private formatCurrencyValue(value: number | string): string {
        let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

        let sign = '';

        if (lodash.first(decimalPart) === '-') {
            decimalPart = decimalPart.substring(1);
            sign = '-';
        }

        const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

        return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''}`;
    }

    private compareValues(valueA: any = null, valueB: any = null): boolean {
        return lodash.isNumber(valueA) || lodash.isNumber(valueB)
            ? parseFloat(valueA) !== parseFloat(valueB)
            : (valueA || null) !== (valueB || null);
    }
}
