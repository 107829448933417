import * as lodash from 'lodash';

import type { Funds } from '@mrm/budget';
import type {
    Project,
    CreativeRequest,
    CreativeRequestItem,
    CreativeRequestCorrectionEntry as CreativeRequestCorrection,
} from '@api';
import { ColumnName, LineType, Line } from './types';
import { TableType as CreativeTableType, TableNumberByType } from '@store/creative/types';
import { CAPEX_ITEM_VALUE, MONTH_NAMES } from '../../consts';

export const enum ColumnHeaderType {
    Text = 'text',
}

export const enum CellType {
    Text = 'text',
    AddExecutionId = 'addExecutionId',
    Expandable = 'expandable',
    FundsInput = 'funds_input',
    FundsWithButton = 'funds_with_button',
}

export interface AccessorParams {
    lineId: string;
    line: Line;
    allLines: Line[];
    capex: boolean;
    project: Project;
    creativeRequest: CreativeRequest;
    creativeRequestItems: CreativeRequestItem[];
    corrections: CreativeRequestCorrection[];
    groupedCorrections: Record<'plan' | 'reserve', CreativeRequestCorrection[]>;
}

type Accessor<T> = (params: AccessorParams) => Promise<T>;
type AccessorWithValue<T> = (params: AccessorParams, value: any) => Promise<T>;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type ReadOnlyAccessor = Accessor<boolean>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;
export type SuggestItemsAccessor = Accessor<string[]>;
export type ValidationAccessor = AccessorWithValue<boolean>;

export interface ColumnParams {
    title: string | [string, string];
    headerType: ColumnHeaderType;
    type: Partial<Record<LineType, CellType>> | CellType;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, ReadOnlyAccessor | boolean>> | ReadOnlyAccessor | boolean;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>> | CustomStyleAccessor;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    validateValue?: Partial<Record<LineType, ValidationAccessor>> | ValidationAccessor;
    getTitle?: Partial<Record<LineType, TitleAccessor>> | TitleAccessor;
    getValue: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
    setValue?: Partial<Record<LineType, ValueSetter>> | ValueSetter;
    getItems?: Partial<Record<LineType, ItemsAccessor>> | ItemsAccessor;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>> | DescriptionAccessor;
}

export interface ColumnsConfigParams {
    displayCorrectionsColumns: boolean;
}

export const MakeColumnsConfig: (columnsConfigParams: ColumnsConfigParams) => { [columnName: string]: ColumnParams } = (
    columnsConfigParams: ColumnsConfigParams,
) => ({
    [ColumnName.ExecutionId]: {
        title: 'ID исполнения',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Placeholder]: CellType.AddExecutionId,
            [LineType.BudgetItemLine]: CellType.Expandable,
            [LineType.PlanCorrection]: CellType.Text,
            [LineType.ReserveCorrection]: CellType.Text,
            [LineType.Total]: CellType.Text,
            [LineType.CreativeFactSum]: CellType.Text,
            [LineType.Delta]: CellType.Text,
            [LineType.LinesWithoutExecutionId]: CellType.Text,
        },
        defaultWidth: 230,
        readOnly: true,
        customStyle: {
            [LineType.BudgetItemLine]: async (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Total]: async (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.CreativeFactSum]: async (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.Delta]: async (params: AccessorParams) => ({ fontWeight: 600 }),
            [LineType.LinesWithoutExecutionId]: async (params: AccessorParams) => ({ fontWeight: 600 }),
        },
        getValue: {
            [LineType.Placeholder]: async (params: AccessorParams) => 'Привязать\nID исполнения',
            [LineType.BudgetItemLine]: async (params: AccessorParams) => params.line.serialNumber,
            [LineType.PlanCorrection]: async (params: AccessorParams) => 'План внесен',
            [LineType.ReserveCorrection]: async (params: AccessorParams) => 'Резерв внесен',
            [LineType.Total]: async (params: AccessorParams) => 'Итог ID исполнения',
            [LineType.CreativeFactSum]: async (params: AccessorParams) => 'Согласов. сумма',
            [LineType.Delta]: async (params: AccessorParams) => 'Дельта',
            [LineType.LinesWithoutExecutionId]: async (params: AccessorParams) => 'Строки без ID исполнения',
        },
    },
    [ColumnName.Plan]: {
        title: 'План',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Placeholder]: CellType.FundsInput,
            [LineType.BudgetItemLine]: CellType.FundsWithButton,
            [LineType.PlanCorrection]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
            [LineType.CreativeFactSum]: CellType.FundsInput,
            [LineType.Delta]: CellType.FundsInput,
            [LineType.LinesWithoutExecutionId]: CellType.Text,
        },
        defaultWidth: 230,
        readOnly: true,
        customStyle: {
            [LineType.Delta]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const budgetItemsPlan = lodash.sumBy(params.allLines, (item) => getFundsSum(item.plannedFunds));
                const budgetItemsReserve = lodash.sumBy(params.allLines, (item) => getFundsSum(item.reservedFunds));

                const planCorrections = params.groupedCorrections.plan || [];
                const reserveCorrections = params.groupedCorrections.reserve || [];

                const planCorrectionsSum = lodash.sumBy(
                    planCorrections,
                    (item) => getFundsSum(item.acceptorFollowingPlanned) - getFundsSum(item.acceptorPreviousPlanned),
                );

                const correctionsReserveSum = lodash.sumBy(
                    reserveCorrections,
                    (item) => getFundsSum(item.acceptorFollowingReserved) - getFundsSum(item.acceptorPreviousReserved),
                );

                const surplus = budgetItemsPlan - planCorrectionsSum - (budgetItemsReserve - correctionsReserveSum);

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                const delta = surplus + planCorrectionsSum - actualCostSum;

                return delta < 0 ? { color: '#e63900' } : null;
            },
        },
        getValue: {
            [LineType.Placeholder]: async (params: AccessorParams) => null,
            [LineType.BudgetItemLine]: async (params: AccessorParams) =>
                formatMoney(getFundsSum(params.line.plannedFunds)),
            [LineType.PlanCorrection]: async (params: AccessorParams) => {
                const correction = params.groupedCorrections.plan.find((item) => item.id === params.lineId);

                const { acceptorPreviousPlanned, acceptorFollowingPlanned } = correction;

                return formatMoney(getFundsSum(acceptorFollowingPlanned) - getFundsSum(acceptorPreviousPlanned));
            },
            [LineType.Total]: async (params: AccessorParams) => {
                const budgetItemsIds = params.allLines
                    .filter((budgetItem) =>
                        params.capex
                            ? budgetItem.dictionary.item?.value === CAPEX_ITEM_VALUE
                            : budgetItem.dictionary.item?.value !== CAPEX_ITEM_VALUE,
                    )
                    .map((item) => item.id);

                const planCorrections = (params.groupedCorrections.plan || []).filter((item) =>
                    budgetItemsIds.includes(item.acceptorId),
                );

                const planCorrectionsSum = lodash.sumBy(
                    planCorrections,
                    (item) => getFundsSum(item.acceptorFollowingPlanned) - getFundsSum(item.acceptorPreviousPlanned),
                );

                return formatMoney(planCorrectionsSum);
            },
            [LineType.CreativeFactSum]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                return formatMoney(actualCostSum);
            },
            [LineType.Delta]: async (params: AccessorParams) => {
                const budgetItemsIds = params.allLines
                    .filter((budgetItem) => {
                        const itemValue = budgetItem.dictionary.item?.value;

                        return params.capex ? itemValue === CAPEX_ITEM_VALUE : itemValue !== CAPEX_ITEM_VALUE;
                    })
                    .map((item) => item.id);

                const budgetItemsPlan = lodash.sumBy(params.allLines, (item) => getFundsSum(item.plannedFunds));
                const budgetItemsReserve = lodash.sumBy(params.allLines, (item) => getFundsSum(item.reservedFunds));

                const planCorrections = (params.groupedCorrections.plan || []).filter((item) =>
                    budgetItemsIds.includes(item.acceptorId),
                );
                const reserveCorrections = (params.groupedCorrections.reserve || []).filter((item) =>
                    budgetItemsIds.includes(item.acceptorId),
                );

                const planCorrectionsSum = lodash.sumBy(
                    planCorrections,
                    (item) => getFundsSum(item.acceptorFollowingPlanned) - getFundsSum(item.acceptorPreviousPlanned),
                );

                const correctionsReserveSum = lodash.sumBy(
                    reserveCorrections,
                    (item) => getFundsSum(item.acceptorFollowingReserved) - getFundsSum(item.acceptorPreviousReserved),
                );

                const surplus = budgetItemsPlan - planCorrectionsSum - (budgetItemsReserve - correctionsReserveSum);

                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                return formatMoney(surplus + planCorrectionsSum - actualCostSum);
            },
            [LineType.LinesWithoutExecutionId]: async (params: AccessorParams) => {
                const itemsWithoutAcceptors = params.creativeRequestItems.filter((item) =>
                    lodash.isEmpty(item.model.acceptors),
                );

                const tableTypeByItemId = lodash.zipObject(
                    itemsWithoutAcceptors.map((item) => item.model.id),
                    await Promise.all(itemsWithoutAcceptors.map((item) => item.model.creativeRequestGroup)),
                );

                const serialNumbers = itemsWithoutAcceptors.map((item) => {
                    const tableType = tableTypeByItemId[item.model.id].value as CreativeTableType;

                    return `${TableNumberByType[tableType]}.${item.model.number}`;
                });

                return lodash
                    .sortBy(serialNumbers, (item) => {
                        const [a, b] = item.split('.');

                        return [parseInt(a, 10), parseInt(b, 10)];
                    })
                    .join(', ');
            },
        },
        getDescription: {
            [LineType.BudgetItemLine]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const sourceIdsAreEmpty = !creativeRequestBudgetItems.some((item) =>
                    params.capex
                        ? itemValueByBudgetItemId[item.model.id] === CAPEX_ITEM_VALUE
                        : itemValueByBudgetItemId[item.model.id] !== CAPEX_ITEM_VALUE,
                );

                if (sourceIdsAreEmpty) {
                    return null;
                }

                const budgetItemsIds = params.allLines
                    .filter((budgetItem) => {
                        const itemValue = budgetItem.dictionary.item?.value;

                        return params.capex ? itemValue === CAPEX_ITEM_VALUE : itemValue !== CAPEX_ITEM_VALUE;
                    })
                    .map((item) => item.id);

                const budgetItemsPlan = lodash.sumBy(params.allLines, (item) => getFundsSum(item.plannedFunds));
                const budgetItemsReserve = lodash.sumBy(params.allLines, (item) => getFundsSum(item.reservedFunds));

                const planCorrections = (params.groupedCorrections.plan || []).filter((item) =>
                    budgetItemsIds.includes(item.acceptorId),
                );
                const reserveCorrections = (params.groupedCorrections.reserve || []).filter((item) =>
                    budgetItemsIds.includes(item.acceptorId),
                );

                const planCorrectionsSum = lodash.sumBy(
                    planCorrections,
                    (item) => getFundsSum(item.acceptorFollowingPlanned) - getFundsSum(item.acceptorPreviousPlanned),
                );

                const correctionsReserveSum = lodash.sumBy(
                    reserveCorrections,
                    (item) => getFundsSum(item.acceptorFollowingReserved) - getFundsSum(item.acceptorPreviousReserved),
                );

                const surplus = budgetItemsPlan - planCorrectionsSum - (budgetItemsReserve - correctionsReserveSum);

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                const delta = formatMoney(actualCostSum - surplus - planCorrectionsSum);

                return delta > 0 ? `Внести ${formatCurrencyValue(roundNumber(delta))}` : null;
            },
        },
    },
    [ColumnName.Reserve]: {
        title: 'Резерв',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Placeholder]: CellType.FundsInput,
            [LineType.BudgetItemLine]: CellType.FundsWithButton,
            [LineType.ReserveCorrection]: CellType.FundsInput,
            [LineType.Total]: CellType.FundsInput,
            [LineType.CreativeFactSum]: CellType.FundsInput,
            [LineType.Delta]: CellType.FundsInput,
            [LineType.LinesWithoutExecutionId]: CellType.Text,
        },
        defaultWidth: 230,
        readOnly: true,
        customStyle: {
            [LineType.Delta]: async (params: AccessorParams) => {
                const reserveCorrections = params.groupedCorrections.reserve || [];

                const correctionsReserveSum = lodash.sumBy(
                    reserveCorrections,
                    (item) => getFundsSum(item.acceptorFollowingReserved) - getFundsSum(item.acceptorPreviousReserved),
                );

                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                const delta = correctionsReserveSum - actualCostSum;

                return delta < 0 ? { color: '#e63900' } : null;
            },
        },
        getValue: {
            [LineType.Placeholder]: async (params: AccessorParams) => null,
            [LineType.BudgetItemLine]: async (params: AccessorParams) =>
                formatMoney(getFundsSum(params.line.reservedFunds)),
            [LineType.ReserveCorrection]: async (params: AccessorParams) => {
                const correction = params.groupedCorrections.reserve.find((item) => item.id === params.lineId);

                const { acceptorPreviousReserved, acceptorFollowingReserved } = correction;

                return formatMoney(getFundsSum(acceptorFollowingReserved) - getFundsSum(acceptorPreviousReserved));
            },
            [LineType.Total]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const reserveCorrections = params.groupedCorrections.reserve || [];

                const correctionsReserveSum = lodash
                    .chain(reserveCorrections)
                    .filter((item) =>
                        params.capex
                            ? itemValueByBudgetItemId[item.acceptorId] === CAPEX_ITEM_VALUE
                            : itemValueByBudgetItemId[item.acceptorId] !== CAPEX_ITEM_VALUE,
                    )
                    .sumBy(
                        (item) =>
                            getFundsSum(item.acceptorFollowingReserved) - getFundsSum(item.acceptorPreviousReserved),
                    )
                    .value();

                return formatMoney(correctionsReserveSum);
            },
            [LineType.CreativeFactSum]: async (params: AccessorParams) => {
                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                return formatMoney(actualCostSum);
            },
            [LineType.Delta]: async (params: AccessorParams) => {
                const reserveCorrections = params.groupedCorrections.reserve || [];

                const correctionsReserveSum = lodash.sumBy(
                    reserveCorrections,
                    (item) => getFundsSum(item.acceptorFollowingReserved) - getFundsSum(item.acceptorPreviousReserved),
                );

                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                return formatMoney(correctionsReserveSum - actualCostSum);
            },
            [LineType.LinesWithoutExecutionId]: async (params: AccessorParams) => {
                const itemsWithoutAcceptors = params.creativeRequestItems.filter((item) =>
                    lodash.isEmpty(item.model.acceptors),
                );

                const tableTypeByItemId = lodash.zipObject(
                    itemsWithoutAcceptors.map((item) => item.model.id),
                    await Promise.all(itemsWithoutAcceptors.map((item) => item.model.creativeRequestGroup)),
                );

                const serialNumbers = itemsWithoutAcceptors.map((item) => {
                    const tableType = tableTypeByItemId[item.model.id].value as CreativeTableType;

                    return `${TableNumberByType[tableType]}.${item.model.number}`;
                });

                return serialNumbers.join(', ');
            },
        },
        getDescription: {
            [LineType.BudgetItemLine]: async (params: AccessorParams) => {
                const reserveCorrections = params.groupedCorrections.reserve || [];

                const correctionsReserveSum = lodash.sumBy(
                    reserveCorrections,
                    (item) => getFundsSum(item.acceptorFollowingReserved) - getFundsSum(item.acceptorPreviousReserved),
                );

                const creativeRequestBudgetItems = await params.creativeRequest.model.getBudgetItems();
                const projectBudgetItems = await params.project.model.getBudgetItems();

                const budgetItems = [...creativeRequestBudgetItems, ...projectBudgetItems];

                const itemValueByBudgetItemId = lodash.zipObject(
                    budgetItems.map((item) => item.model.id),
                    await Promise.all(budgetItems.map(async (item) => (await item.model.item)?.value)),
                );

                const actualCostSum = lodash
                    .chain(params.creativeRequestItems)
                    .filter((item) => {
                        const lineIsApproved = item.model.status === 'approved';
                        const lineIsCapex = [...item.model.donors, ...item.model.acceptors].some(
                            (item) => itemValueByBudgetItemId[item.id] === CAPEX_ITEM_VALUE,
                        );

                        return lineIsApproved && (params.capex ? lineIsCapex : !lineIsCapex);
                    })
                    .sumBy((item) => (params.capex ? item.model.actualCostWithVat : item.model.actualCostWithoutVat))
                    .value();

                const delta = formatMoney(actualCostSum - correctionsReserveSum);

                return delta > 0 ? `Внести ${formatCurrencyValue(roundNumber(delta))}` : null;
            },
        },
    },
    [ColumnName.SourceId]: {
        title: 'ID источника',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.PlanCorrection]: CellType.Text,
        },
        defaultWidth: 80,
        readOnly: true,
        getValue: {
            [LineType.PlanCorrection]: async (params: AccessorParams) => {
                const correction = params.groupedCorrections.plan.find((item) => item.id === params.lineId);

                return correction.donorSerialNumber;
            },
        },
    },
    [ColumnName.Date]: {
        title: 'Месяц и год',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.PlanCorrection]: CellType.Text,
            [LineType.ReserveCorrection]: CellType.Text,
        },
        defaultWidth: 80,
        readOnly: true,
        getValue: {
            [LineType.PlanCorrection]: async (params: AccessorParams) => {
                const correction = params.groupedCorrections.plan.find((item) => item.id === params.lineId);

                const month = lodash.findKey(
                    correction.acceptorPreviousPlanned,
                    (item, key) => item !== correction.acceptorFollowingPlanned[key],
                );

                return `${MONTH_NAMES[month]} ${correction.acceptorBudgetYear}`;
            },
            [LineType.ReserveCorrection]: async (params: AccessorParams) => {
                const correction = params.groupedCorrections.reserve.find((item) => item.id === params.lineId);

                const { acceptorPreviousReserved, acceptorFollowingReserved } = correction;

                const months = lodash
                    .reduce(
                        acceptorPreviousReserved,
                        (acc, item, key) => {
                            if (item !== acceptorFollowingReserved[key]) {
                                acc.push(MONTH_NAMES[key]);
                            }

                            return acc;
                        },
                        [],
                    )
                    .join(', ');

                return `${months} ${correction.acceptorBudgetYear}`;
            },
        },
    },
});

export const MakeTableColumns: (params: ColumnsConfigParams) => string[] = (params) => [
    ColumnName.ExecutionId,
    ColumnName.Plan,
    ColumnName.Reserve,
    ...(params.displayCorrectionsColumns ? [ColumnName.SourceId, ColumnName.Date] : []),
];

export const MakeLeftFixedColumns: (params: ColumnsConfigParams) => string[] = (params) => [];

export const MakeRightFixedColumns: (params: ColumnsConfigParams) => string[] = (params) => [];

function formatMoney(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    return parsedValue / 100;
}

function formatCurrencyValue(value: React.ReactText): string {
    if (!value) {
        return null;
    }

    let [decimalPart, fractionPart] = value.toString().split(/[.,]/);

    let sign = '';

    if (lodash.first(decimalPart) === '-') {
        decimalPart = decimalPart.substring(1);
        sign = '-';
    }

    const splittedDecimal = decimalPart.split(/(?=(?:...)*$)/).join(' ');

    return `${sign}${splittedDecimal}${fractionPart ? `,${fractionPart}` : ''} ₽`;
}

function roundNumber(value: number, digitsAfterComma = 2): string {
    if (value === undefined) {
        return null;
    }

    const formatedValue = value.toFixed(digitsAfterComma);

    const [decimalPart, fractionPart] = formatedValue.split('.');

    return `${decimalPart}${fractionPart ? `.${fractionPart}` : ''}`;
}

function getFundsSum(funds: Funds): number {
    return lodash.sum(lodash.values(funds));
}

export type { ColumnName };
