import * as React from 'react';
import * as lodash from 'lodash';
import { useSelector } from 'react-redux';
import {
    Icon,
    IconType,
    WithTooltip,
    TooltipAnchor,
    WithGlobalPopup,
    RegularPositionCalculator,
    AbsoluteLikePositionCalculator,
    CloseButton,
    CustomScrollbar_new as CustomScrollbar,
    CustomScrollbarTheme,
} from 'sber-marketing-ui';
import {
    BriefHistoryDiffItem,
    BriefHistoryDiffItemContent,
    SwitchItem,
    SelectItem,
} from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getBriefState } from '@store/brief/selectors';

import { DatesFormatter } from '@common/Utils';
import { calculateScreenOverflow } from '@common/hooks';

import * as styles from './HistoryWidget.scss';

type FieldType =
    | 'text'
    | 'calendar'
    | 'range'
    | 'switchGroupCheckbox'
    | 'switchGroupRadio'
    | 'dropdown'
    | 'unlockableInput';

interface Props {
    id: string;
    uniqId?: number;
    parentUniqId?: number;
    type: FieldType;
    switches?: SwitchItem[];
    selectContent?: SelectItem[];
}

export function HistoryWidget({ id, uniqId, parentUniqId, type, switches, selectContent }: Props): JSX.Element {
    const historyItem = useSelector(
        (state: StoreState) => getBriefState(state).briefHistory?.history?.[`${id}_${parentUniqId}_${uniqId}`],
    );

    const containerRef = React.useRef<HTMLDivElement>();
    const widgetRef = React.useRef<HTMLDivElement>();
    const widgetContentRef = React.useRef();
    const [isHovered, setIsHovered] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [contentPosition, setContentPosition] = React.useState({});

    let iconType: IconType;
    if (isActive) {
        iconType = IconType.BRIEF_HISTORY_ICON_ACTIVE;
    } else {
        iconType = isHovered ? IconType.BRIEF_HISTORY_ICON_HOVER : IconType.BRIEF_HISTORY_ICON;
    }

    const groupedDiffItems = lodash.groupBy(historyItem?.diff, (diff) =>
        DatesFormatter.ddMonthyyyy(diff.updateTime, { withTodayMarker: true }),
    );

    if (!historyItem) {
        console.warn(`Missing briefHistory item for ${JSON.stringify({ id, uniqId, parentUniqId })}`);
        return null;
    }

    React.useEffect(
        function onActiveChanged() {
            const bottomOverflow = widgetContentRef.current
                ? calculateScreenOverflow(widgetContentRef.current).bottom
                : false;

            const contentPosition = bottomOverflow
                ? { bottom: 0, right: -340, positionCalculator: RegularPositionCalculator }
                : { top: 0, right: -40, positionCalculator: AbsoluteLikePositionCalculator };

            setContentPosition(contentPosition);
        },
        [isActive],
    );

    return (
        <React.Fragment>
            <div
                className={styles.historyWidget}
                ref={containerRef}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                    setIsHovered(false);
                    setIsActive(true);
                }}
                {...{
                    'qa-id': 'briefHistoryWidget',
                }}
            >
                <WithTooltip
                    anchor={TooltipAnchor.RIGHT}
                    content="Посмотреть историю изменений поля"
                    visibilityTrigger="static"
                    hidden={!isHovered}
                >
                    <Icon type={iconType} svgSize={36} />
                </WithTooltip>
            </div>

            {isActive && (
                <WithGlobalPopup container={containerRef} {...contentPosition} onMaskClick={() => setIsActive(false)}>
                    <div
                        ref={widgetRef}
                        className={styles.historyWidgetContent}
                        {...{
                            'qa-id': 'briefHistoryWidgetContent',
                        }}
                    >
                        <div className={styles.historyWidgetContentTitleRow}>
                            <div className={styles.historyWidgetContentTitle}>
                                История изменений поля
                                <div
                                    className={styles.historyWidgetContentTitleCounter}
                                    {...{
                                        'qa-id': 'briefHistoryWidgetCounter',
                                    }}
                                >
                                    {historyItem.diff?.length || 0}
                                </div>
                            </div>

                            <CloseButton
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();

                                    setIsActive(false);
                                }}
                            />
                        </div>

                        <div className={styles.historyWidgetContentDivider} />

                        <CustomScrollbar maxHeight={450} theme={CustomScrollbarTheme.GrayRounded}>
                            {Object.keys(groupedDiffItems).map((diffItemGroupTitle) => (
                                <div
                                    key={diffItemGroupTitle}
                                    {...{
                                        'qa-id': 'briefHistoryWidgetContentItem',
                                        'qa-date': diffItemGroupTitle,
                                    }}
                                >
                                    <HistoryWidgetDateMarker date={diffItemGroupTitle} />

                                    {groupedDiffItems[diffItemGroupTitle].map((diff) => (
                                        <HistoryWidgetDifference
                                            key={diff.updateTime}
                                            type={type}
                                            selectContent={selectContent}
                                            switches={switches}
                                            {...diff}
                                        />
                                    ))}
                                </div>
                            ))}

                            <div ref={widgetContentRef} />
                        </CustomScrollbar>
                    </div>
                </WithGlobalPopup>
            )}
        </React.Fragment>
    );
}

interface HistoryWidgetDateMarkerProps {
    date: string;
}

function HistoryWidgetDateMarker({ date }: HistoryWidgetDateMarkerProps): JSX.Element {
    return (
        <div
            className={styles.historyWidgetDateMarker}
            {...{
                'qa-id': 'briefHistoryWidgetDate',
            }}
        >
            <div className={styles.historyWidgetDateMarkerDate}>{date}</div>

            <div className={styles.historyWidgetDateMarkerBar} />
        </div>
    );
}

interface HistoryWidgetDifferenceProps extends BriefHistoryDiffItem {
    type: FieldType;
    switches?: SwitchItem[];
    selectContent?: SelectItem[];
}

function HistoryWidgetDifference({
    user,
    updateTime,
    diff,
    type,
    switches,
    selectContent,
}: HistoryWidgetDifferenceProps): JSX.Element {
    const userFormatted = `${user?.firstName} ${user?.secondName}`;
    const dateFormatted = DatesFormatter.hhmm(updateTime);

    const formatterParams = { type, switches, selectContent };
    const beforeFormatted = diffValueFormatter(diff?.before, formatterParams);
    const afterFormatted = diffValueFormatter(diff?.after, formatterParams);

    return (
        <div
            className={styles.historyWidgetDifference}
            {...{
                'qa-id': 'briefHistoryWidgetDifference',
            }}
        >
            <div
                className={styles.historyWidgetDifferenceAuthor}
                {...{
                    'qa-id': 'briefHistoryWidgetDifferenceAuthor',
                }}
            >
                {userFormatted} <span className={styles.historyWidgetDifferenceAuthorGray}>{dateFormatted}</span>
            </div>

            <div className={styles.historyWidgetDifferenceContent}>
                <span
                    className={styles.historyWidgetDifferenceContentBefore}
                    {...{
                        'qa-id': 'briefHistoryWidgetDifferenceBefore',
                    }}
                >
                    {beforeFormatted}
                </span>

                <span className={styles.historyWidgetDifferenceContentArrow}>
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.3708 0.121796L12.4404 0.181942L15.8192 3.56075C16.0398 3.78129 16.0598 4.12639 15.8794 4.36957L15.8192 4.43925L12.4404 7.81806C12.1978 8.06065 11.8045 8.06065 11.5619 7.81806C11.3414 7.59752 11.3214 7.25242 11.5018 7.00924L11.5619 6.93957L13.85 4.65H0.65C0.291015 4.65 0 4.35898 0 4C0 3.67365 0.240508 3.40347 0.553948 3.35705L0.65 3.35L13.851 3.349L11.5619 1.06043C11.3414 0.839898 11.3214 0.494796 11.5018 0.251613L11.5619 0.181942C11.7825 -0.0385936 12.1276 -0.0586423 12.3708 0.121796Z"
                            fill="#7E8681"
                        />
                    </svg>
                </span>

                <span
                    {...{
                        'qa-id': 'briefHistoryWidgetDifferenceAfter',
                    }}
                >
                    {afterFormatted}
                </span>
            </div>
        </div>
    );
}

function diffValueFormatter(
    diff: BriefHistoryDiffItemContent,
    params: {
        type: FieldType;
        switches?: SwitchItem[];
        selectContent?: SelectItem[];
    },
): string {
    const NO_DATA_MARKER = 'Не задано';

    let value: string;

    if (diff) {
        switch (params.type) {
            case 'text':
            case 'unlockableInput':
                value = diff.text;
                break;
            case 'calendar':
                value = diff.date ? DatesFormatter.ddMonth(diff.date) : null;
                break;
            case 'range':
                if (diff.from || diff.to) {
                    const before = diff.from || NO_DATA_MARKER;
                    const after = diff.to || NO_DATA_MARKER;
                    value = `${before} - ${after}`;
                }
                break;
            case 'dropdown':
                value = params.selectContent?.find((dropdownItem) => diff.selected === dropdownItem.id)?.name;
                break;
            case 'switchGroupCheckbox':
                value = diff.switches
                    ?.map(
                        (switchItem1) => params.switches.find((switchItem2) => switchItem1.id === switchItem2.id)?.name,
                    )
                    ?.join(', ');
                break;
            case 'switchGroupRadio':
                value = params.switches?.find((switchItem) => diff.selected === switchItem.id)?.name;
                break;
        }
    }

    return value || NO_DATA_MARKER;
}
