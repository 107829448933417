import * as React from 'react';
import classnames from 'classnames';
import { Icon, IconType } from 'sber-marketing-ui';
import { GetPreviewResponse } from '@mrm/videoPreview';

import { isPreviewValid } from '@store/videoPreviews';

import * as styles from './FileAsset.scss';

interface Props {
    className: string;
    type: string;
    isHovered: boolean;
    imagePreview: string;
    videoPreview: GetPreviewResponse;
    useGallery: boolean;
    preventDownload: boolean;
    showDownloadButton: boolean;
    documentWasEditedInR7: boolean;
    onDownloadButtonClick: () => void;
    onGalleryButtonClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

const ICON_DEFAULT_SIZE = 21;
const ICON_SCALE_FACTOR = 0.5;

export function FileAsset({
    className,
    type,
    isHovered,
    imagePreview,
    videoPreview,
    useGallery,
    preventDownload,
    showDownloadButton,
    documentWasEditedInR7,
    onDownloadButtonClick,
    onGalleryButtonClick,
    onMouseEnter,
    onMouseLeave,
}: Props): JSX.Element {
    const showButtons = isHovered && (useGallery || !preventDownload);

    const ref = React.useRef<HTMLDivElement>();
    const iconSize = React.useMemo(() => {
        if (!ref.current) {
            return ICON_DEFAULT_SIZE;
        }
        const containerSize = Math.min(ref.current.clientWidth, ref.current.clientHeight);

        return Math.min(containerSize * ICON_SCALE_FACTOR, ICON_DEFAULT_SIZE);
    }, [ref.current]);

    return (
        <div
            ref={ref}
            className={classnames(styles.root, isHovered && showButtons && styles.root_hovered, className)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...{
                'qa-id': 'fileAsset',
            }}
        >
            {showButtons && (
                <React.Fragment>
                    <div className={styles.mask} />

                    {showDownloadButton && (
                        <DownloadButton
                            preventDownload={preventDownload}
                            useGallery={useGallery}
                            iconSize={iconSize}
                            onDownloadButtonClick={onDownloadButtonClick}
                        />
                    )}

                    <GalleryButton
                        preventDownload={preventDownload}
                        useGallery={useGallery}
                        iconSize={iconSize}
                        onGalleryButtonClick={onGalleryButtonClick}
                    />
                </React.Fragment>
            )}

            <Preview type={type} imagePreview={imagePreview} videoPreview={videoPreview} />

            {documentWasEditedInR7 && !isHovered && <div className={styles.documentWasEditedInR7Mark}>изм</div>}
        </div>
    );
}

interface DownloadButtonProps extends Pick<Props, 'preventDownload' | 'useGallery' | 'onDownloadButtonClick'> {
    iconSize: number;
}

function DownloadButton({
    preventDownload,
    useGallery,
    iconSize,
    onDownloadButtonClick,
}: DownloadButtonProps): JSX.Element {
    if (preventDownload) {
        return null;
    }

    if (useGallery) {
        return (
            <div className={styles.buttonHalfBottom} onClick={onDownloadButtonClick}>
                <Icon type={IconType.DOWNLOAD} svgSize={iconSize} />
            </div>
        );
    }

    return (
        <div className={styles.buttonFull} onClick={onDownloadButtonClick}>
            <Icon type={IconType.DOWNLOAD} svgSize={iconSize} />
        </div>
    );
}

interface GalleryButtonProps extends Pick<Props, 'preventDownload' | 'useGallery' | 'onGalleryButtonClick'> {
    iconSize: number;
}

function GalleryButton({
    preventDownload,
    useGallery,
    iconSize,
    onGalleryButtonClick,
}: GalleryButtonProps): JSX.Element {
    if (!useGallery) {
        return null;
    }

    if (!preventDownload) {
        return (
            <div className={styles.buttonHalfTop} onClick={onGalleryButtonClick}>
                <Icon type={IconType.VIEW_ASSET_EYE} svgSize={iconSize} />
            </div>
        );
    }

    return (
        <div className={styles.buttonFull} onClick={onGalleryButtonClick}>
            <Icon type={IconType.VIEW_ASSET_EYE} svgSize={iconSize} />
        </div>
    );
}

type PreviewProps = Pick<Props, 'imagePreview' | 'videoPreview' | 'type'>;

function Preview({ imagePreview, videoPreview, type }: PreviewProps): JSX.Element {
    if (imagePreview) {
        return (
            <div
                className={styles.imagePreview}
                style={{
                    backgroundImage: `url("${imagePreview}")`,
                }}
            />
        );
    }

    if (isPreviewValid(videoPreview)) {
        return (
            <div
                className={styles.imagePreview}
                style={{
                    backgroundImage: `url("${videoPreview?.previews?.[0]?.url}")`,
                }}
            />
        );
    }

    return <div className={styles.fileType}>{type}</div>;
}
