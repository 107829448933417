import * as React from 'react';

export const CommentIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.9643 4C16.6036 4 20.4286 6.97499 20.4286 10.75C20.4286 12.1041 19.9304 13.3922 19.0251 14.4814L18.76 14.7896L12.3961 21.1535C11.9485 21.6011 11.1999 21.3244 11.1223 20.726L11.1158 20.6233L11.1158 17.4666C6.85677 17.1298 3.5 14.2824 3.5 10.75C3.5 6.97499 7.32498 4 11.9643 4ZM11.9643 5.5C8.08263 5.5 5 7.8976 5 10.75C5 13.3825 7.63163 15.6412 11.1154 15.9612L11.1152 15.8319C11.1152 15.4177 11.4509 15.0819 11.8651 15.0818C12.2416 15.0818 12.5534 15.3592 12.607 15.7209L12.6152 15.8317L12.6148 18.8121L17.5874 13.8387C18.4585 12.9409 18.9286 11.8686 18.9286 10.75C18.9286 7.8976 15.8459 5.5 11.9643 5.5ZM11.9671 9.65177C12.5746 9.65177 13.0671 10.1443 13.0671 10.7518C13.0671 11.3593 12.5746 11.8518 11.9671 11.8518C11.3596 11.8518 10.8671 11.3593 10.8671 10.7518C10.8671 10.1443 11.3596 9.65177 11.9671 9.65177ZM8.46708 9.65177C9.07459 9.65177 9.56708 10.1443 9.56708 10.7518C9.56708 11.3593 9.07459 11.8518 8.46708 11.8518C7.85956 11.8518 7.36708 11.3593 7.36708 10.7518C7.36708 10.1443 7.85956 9.65177 8.46708 9.65177ZM15.4671 9.65177C16.0746 9.65177 16.5671 10.1443 16.5671 10.7518C16.5671 11.3593 16.0746 11.8518 15.4671 11.8518C14.8596 11.8518 14.3671 11.3593 14.3671 10.7518C14.3671 10.1443 14.8596 9.65177 15.4671 9.65177Z"
            fill="#7E8681"
            stroke="#7E8681"
            stroke-width="0.3"
        />
        <circle cx="18.7" cy="8" r="2" fill="#E63900" />
    </svg>
);
