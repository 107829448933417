import * as React from 'react';

import { Popup as PopupUI, Preloader } from 'sber-marketing-ui';

import * as styles from './styles.scss';

interface Props {
    loading?: boolean;
    onClose: () => void;
}

export const Popup: React.FC<Props> = ({ children, loading = false, onClose = () => {} }) => {
    return (
        <PopupUI onCloseIconClick={onClose} onOutOfContentClick={onClose}>
            <div className={styles.root}>
                {children}
                {loading && <Preloader />}
            </div>
        </PopupUI>
    );
};
