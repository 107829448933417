import * as React from 'react';
import { useSelector } from 'react-redux';

import * as style from './TableWidget.scss';

import { OpacityTransition } from 'sber-marketing-ui';
import { ExecitionIdWidget } from './ExecitionIdWidget';
import { Table } from './Table';
import { SummaryLine } from './SummaryLine';
import { getUserRoles } from '@store/user/selector';

interface Props {
    creativeRequestId: string;
    displayStatusColumns: boolean;
}

export const TableWidgetTemplate = ({ creativeRequestId, displayStatusColumns }: Props): JSX.Element => {
    const userRoles = useSelector(getUserRoles);

    const userHasWidgetAccess = [29, 31, 32].some((roleId) => userRoles.includes(roleId));

    return (
        <OpacityTransition start={true}>
            <div className={style.root}>
                {userHasWidgetAccess && (
                    <>
                        <div className={style.title}>Сводные суммы</div>

                        <div className={style.execitionIdWidget}>
                            <ExecitionIdWidget />
                        </div>
                    </>
                )}

                <div className={style.table}>
                    <Table creativeRequestId={creativeRequestId} displayStatusColumns={displayStatusColumns} />
                </div>

                <SummaryLine />
            </div>
        </OpacityTransition>
    );
};
