import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';
import { PlanPermissionsSysNames } from 'sber-marketing-types/frontend';
import { BudgetItem } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';
import {
    getBudgetExecutionPageState,
    calculateCorrectedPlanReserveBudget,
    calculateCorrectedPlanFactBudget,
} from '@store/budgetExecution';

import { Tooltip, TooltipDirection } from './Tooltip';

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    lineId: string;
    direction: TooltipDirection;
}

interface MapProps {
    pageBudgetItems: BudgetItem[];
    userHasCorrectionsToApprove: boolean;
    correctedPlanReserveBudget: { before: number; after: number };
    correctedPlanFactBudget: { before: number; after: number };
}

@(connect(mapStateToProps, null) as any)
export class TooltipContainer extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const {
            lineId,
            pageBudgetItems,
            correctedPlanReserveBudget,
            correctedPlanFactBudget,
            userHasCorrectionsToApprove,
        } = this.props;

        const budgetItem = pageBudgetItems.find((item) => item.id == lineId);

        const sameActivityBudgetItems = pageBudgetItems.filter((item) => item.activity.id == budgetItem.activity.id);

        const activityType = this.getDictionaryNameByType(budgetItem, DictionaryType.ActivityType);
        const product = this.getDictionaryNameByType(budgetItem, DictionaryType.Product);
        const segment = this.getDictionaryNameByType(budgetItem, DictionaryType.Segment);

        const directions = lodash.uniq(
            sameActivityBudgetItems.map((item) => this.getDictionaryNameByType(item, DictionaryType.Direction)),
        );

        const budgetsByTools: { [toolName: string]: number } = sameActivityBudgetItems.reduce((acc, budgetItem) => {
            const toolName = this.getDictionaryNameByType(budgetItem, DictionaryType.Tool);
            const budgetItemPlan = this.getBudgetItemPlanSum(budgetItem);

            if (toolName) {
                if (acc[toolName] == undefined) {
                    acc[toolName] = 0;
                }

                acc[toolName] += budgetItemPlan;
            }

            return acc;
        }, {});

        const formatedBudgetsByTools = lodash
            .sortBy(
                lodash.map(budgetsByTools, (value, key) => ({ name: key, value })),
                (item) => item.value,
            )
            .reverse();

        return React.createElement(Tooltip, {
            activityName: budgetItem.activity.name,
            serialNumber: budgetItem.serialNumber,
            correctedPlanReserveBudget,
            correctedPlanFactBudget,
            userHasCorrectionsToApprove,
            activityType,
            product,
            segment,
            directions,
            budgetsByTools: formatedBudgetsByTools,
            activityStart: budgetItem.realizationStart ? formatDate(new Date(budgetItem.realizationStart)) : null,
            activityEnd: budgetItem.realizationEnd ? formatDate(new Date(budgetItem.realizationEnd)) : null,
            direction: this.props.direction,
        });
    }

    private getDictionaryNameByType(budgetItem: BudgetItem, dictionaryType: DictionaryType): string {
        return budgetItem.dictionary && budgetItem.dictionary[dictionaryType]
            ? budgetItem.dictionary[dictionaryType].value
            : null;
    }

    private getBudgetItemPlanSum(budgetItem: BudgetItem): number {
        const planValues = lodash.values(budgetItem.plannedFunds);

        return lodash.sum(planValues);
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const {
        pageData,
        computedData: { pageBudgetItems },
    } = getBudgetExecutionPageState(state);
    const user = getLoginUser(state);
    const {
        corrections: { planCorrections, reserveCorrections, factCorrections },
    } = pageData;
    const { lineId } = ownProps;
    const budgetItem = pageBudgetItems.find((budgetItem) => budgetItem.id === ownProps.lineId);

    const correctedPlanReserveBudget = calculateCorrectedPlanReserveBudget(pageData, budgetItem);
    const correctedPlanFactBudget = calculateCorrectedPlanFactBudget(pageData, budgetItem);

    const userIsBudgetExpert = lodash.includes(user.attributes.permissions, PlanPermissionsSysNames.OrganizationAccess);
    const userHasCorrectionsToApprove =
        userIsBudgetExpert && !!(planCorrections[lineId] || reserveCorrections[lineId] || factCorrections[lineId]);

    return {
        pageBudgetItems,
        correctedPlanReserveBudget,
        correctedPlanFactBudget,
        userHasCorrectionsToApprove,
    };
}

function formatDate(date: Date): string {
    return date.toLocaleString('ru', { day: 'numeric', month: 'numeric', year: 'numeric' });
}
