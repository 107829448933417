// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-CardTitle-_CardTitle__root{display:flex;letter-spacing:0.5px;font-family:\"Open Sans\";font-size:16px;font-weight:600;align-items:center;justify-content:flex-start;cursor:pointer}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-CardTitle-_CardTitle__root a:active,.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-CardTitle-_CardTitle__root a:hover,.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-CardTitle-_CardTitle__root a{color:#417ec6;text-decoration:none}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-CardTitle-_CardTitle__loading{max-width:320px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/RelatedCreative/CreativeCardList/CreativeCard/CardTitle/CardTitle.scss"],"names":[],"mappings":"AAAA,iJACI,YAAa,CAEb,oBAAqB,CAErB,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAEhB,kBAAmB,CACnB,0BAA2B,CAC3B,cAAe,CAXnB,scAaQ,aAAc,CACd,oBAAqB,CACxB,oJAID,eAAgB","sourcesContent":[".root {\n    display: flex;\n\n    letter-spacing: 0.5px;\n\n    font-family: \"Open Sans\";\n    font-size: 16px;\n    font-weight: 600;\n\n    align-items: center;\n    justify-content: flex-start;\n    cursor: pointer;\n    a:active, a:hover, a {\n        color: #417ec6;\n        text-decoration: none;  \n    }\n}\n\n.loading {\n    max-width: 320px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-CardTitle-_CardTitle__root",
	"loading": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-CardTitle-_CardTitle__loading"
};
export default ___CSS_LOADER_EXPORT___;
