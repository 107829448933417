import * as React from 'react';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';

import {
    CellPosition,
    ColumnWidths,
    // LineHeights,
    Line,
    LineId,
    TableHeaderCellParams,
    TableBodyCellParams,
} from './types';
import { TableEvent, ColumnName, TableType, LineType } from './types';
import type { BudgetItem } from '@mrm/budget';
import type {
    Project,
    CreativeRequest,
    CreativeRequestItem,
    // CreativeRequestBudgetItem,
    CreativeRequestContract,
} from '@api';

import { CellsStorage, TableView, CellEvent } from 'sber-marketing-ui';
import { TableTemplate } from './TableTemplate';
import {
    MakeLeftFixedColumns,
    MakeRightFixedColumns,
    MakeTableColumns,
    MakeColumnsConfig,
    ColumnParams,
    ColumnsConfigParams,
    AccessorParams,
    // CellType,
} from './ColumnsConfig';
import { ColumnHeaderFactory, CellsFactory } from './modules';

interface Props {
    capex?: boolean;
    project: Project;
    creativeRequest: CreativeRequest;
    creativeRequestItems: CreativeRequestItem[];
    budgetItems: BudgetItem[];
    contracts: CreativeRequestContract[];
    grouppedContracts: Record<'lot1' | 'lot2', CreativeRequestContract[]>;
}

interface State {
    totalTableLinesIds: LineId[];
    sourseLineIds: LineId[];
    columns: Record<'left' | 'center' | 'right', ColumnName[]>;
    isExpanded: boolean;
}

export class TableBehaviour extends React.PureComponent<Props, State> {
    private headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    private tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    private totalTable: TableView;
    private sourseTable: TableView;
    private columnHeaderFactory: ColumnHeaderFactory;
    private cellsFactory: CellsFactory;
    private fixedWidthColumns: ColumnName[];
    private defaultColumnWidths: ColumnWidths = {};

    public constructor(props: Props) {
        super(props);

        const columns = this.makeDefaultColumns();

        this.state = {
            totalTableLinesIds: [],
            sourseLineIds: [],
            columns,
            isExpanded: false,
        };

        this.columnHeaderFactory = new ColumnHeaderFactory({
            getColumnsConfig: this.getColumnsConfig,
        });

        this.cellsFactory = new CellsFactory({
            getLine: this.getLine,
            getAllLines: this.getLines,
            getColumnsConfig: this.getColumnsConfig,
            getAccessorParamsData: this.getAccessorParamsData,
        });

        this.headerCellsStorage = new CellsStorage({
            makeCellParams: this.columnHeaderFactory.makeColumnHeaderParams,
        });

        this.tableCellsStorage = new CellsStorage({
            makeCellParams: this.cellsFactory.makeCellParams,
        });

        this.updateDefaultColumnWidths();
        this.updateFixedWidthColumns();
    }

    public async componentDidMount() {
        this.updateTableLineIds();
        this.subscribeCreativeRequestItemsChanges();
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        const budgetItemsChanged = this.props.budgetItems !== prevProps.budgetItems;
        const creativeRequestItemsChanged = this.props.creativeRequestItems !== prevProps.creativeRequestItems;

        if (budgetItemsChanged) {
            this.onBudgetItemsChanged();
        }

        if (creativeRequestItemsChanged) {
            this.subscribeCreativeRequestItemsChanges();
        }
    }

    public render(): JSX.Element {
        const { budgetItems } = this.props;
        const { totalTableLinesIds, sourseLineIds, isExpanded } = this.state;

        const displaySourseTable = budgetItems.length > 1;

        return React.createElement(TableTemplate, {
            headerCellsStorage: this.headerCellsStorage,
            tableCellsStorage: this.tableCellsStorage,
            tableColumns: this.state.columns,
            fixedWidthColumns: this.fixedWidthColumns,
            totalTableLinesIds,
            sourseLineIds,
            columnWidths: this.defaultColumnWidths,
            displaySourseTable,
            isExpanded,
            totalTableRef: this.totalTableRef,
            sourseTableRef: this.sourseTableRef,
            onCellEvent: this.onCellEvent,
            onExpandButtonClick: this.onExpandButtonClick,
        });
    }

    @autobind
    public async onLineUpdate(lineId: string) {
        await Promise.all([this.updateTableLineIds(), this.updateLineCells(lineId)]);
    }

    @autobind
    protected totalTableRef(component: TableView) {
        this.totalTable = component || null;
    }

    @autobind
    protected sourseTableRef(component: TableView) {
        this.sourseTable = component || null;
    }

    @autobind
    protected onTableEvent(eventType: TableEvent) {
        switch (eventType) {
            case TableEvent.Scroll:
                break;

            case TableEvent.SizeChange:
                break;
        }
    }

    @autobind
    protected async onCellEvent(eventType: CellEvent, position: CellPosition) {
        switch (eventType) {
            case CellEvent.MouseSelection:
                await this.updateCell(position, true);
                break;

            case CellEvent.SelectionCancel:
                await this.updateCell(position, false);
                break;
        }
    }

    @autobind
    protected async onBudgetItemsChanged() {
        this.updateTableLineIds();
        this.updateLineCells('totalLine');
    }

    @autobind
    protected async onCreativeRequestItemUpdate() {
        this.updateLineCells('placeholderLine');
        this.updateLineCells('totalLine');
    }

    @autobind
    protected async onExpandButtonClick() {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    private async updateHeaderCell(columnName: ColumnName) {
        const cellParams = await this.columnHeaderFactory.makeColumnHeaderParams(columnName);

        this.headerCellsStorage.setCellParams(columnName, cellParams);
    }

    private async updateCell(position: CellPosition, edit = false) {
        const cellParams = await this.cellsFactory.makeCellParams(position, edit);

        this.tableCellsStorage.setCellParams(position, cellParams);
    }

    private async updateLineCells(lineId: LineId) {
        if (lineId) {
            const tableType = await this.getTableTypeByLineId(lineId);
            const table = this.getTableByType(tableType);

            if (table) {
                const { left, center, right } = this.state.columns;

                const columnsToUpdate = [...left, ...center, ...right];

                columnsToUpdate.forEach(async (columnName) => {
                    const cellPosition = { lineId, columnName };

                    const cellEditStatus = table.getCellEditStatus(cellPosition);

                    this.updateCell(cellPosition, cellEditStatus);
                });
            }
        }
    }

    private updateDefaultColumnWidths() {
        const columnsConfig = this.getColumnsConfig();

        this.defaultColumnWidths = lodash.mapValues(columnsConfig, (item) => item.defaultWidth);
    }

    private updateFixedWidthColumns() {
        const columnsConfig = this.getColumnsConfig();

        this.fixedWidthColumns = lodash
            .keys(columnsConfig)
            .filter((columnName) => columnsConfig[columnName].disableWidthChange) as ColumnName[];
    }

    @autobind
    private updateTableLineIds() {
        const { budgetItems } = this.props;

        let totalTableLinesIds = lodash.isEmpty(budgetItems) ? ['placeholderLine'] : ['totalLine'];
        let sourseLineIds = this.props.budgetItems.map((item) => item.id);

        if (!lodash.isEqual(totalTableLinesIds, this.state.totalTableLinesIds)) {
            this.setState({ totalTableLinesIds });
        }

        if (!lodash.isEqual(sourseLineIds, this.state.sourseLineIds)) {
            this.setState({ sourseLineIds });
        }
    }

    @autobind
    private subscribeCreativeRequestItemsChanges() {
        const { creativeRequestItems } = this.props;

        creativeRequestItems.forEach((item) => {
            item.events.onUpdated(this.onCreativeRequestItemUpdate);
        });
    }

    @autobind
    private getColumnsConfig(): { [columnName: string]: ColumnParams } {
        const columnsConfigParams = this.makeColumnsConfigParams();

        return MakeColumnsConfig(columnsConfigParams);
    }

    @autobind
    private getAccessorParamsData(): Partial<AccessorParams> {
        const { capex = false, project, creativeRequest, creativeRequestItems } = this.props;

        return {
            capex,
            project,
            creativeRequest,
            creativeRequestItems,
        };
    }

    @autobind
    private makeColumnsConfigParams(): ColumnsConfigParams {
        const { capex } = this.props;

        return {
            capex,
        };
    }

    @autobind
    private makeDefaultColumns() {
        const columnsConfigParams = this.makeColumnsConfigParams();

        let defaultLeftColumns = MakeLeftFixedColumns(columnsConfigParams) as ColumnName[];
        let defaultRightColumns = MakeRightFixedColumns(columnsConfigParams) as ColumnName[];

        return {
            left: defaultLeftColumns,
            center: MakeTableColumns(columnsConfigParams) as ColumnName[],
            right: defaultRightColumns,
        };
    }

    @autobind
    private async getTableTypeByLineId(lineId: LineId): Promise<TableType> {
        return lineId === 'placeholderLine' || lineId === 'totalLine' ? TableType.Total : TableType.Sourse;
    }

    @autobind
    private getTableByType(tableType: TableType): TableView {
        const tableByType = {
            [TableType.Total]: this.totalTable,
            [TableType.Sourse]: this.sourseTable,
        };

        return tableByType[tableType];
    }

    @autobind
    private getLine(lineId: LineId): Line {
        return this.props.budgetItems.find((item) => item.id === lineId);
    }

    @autobind
    private getLines(): Line[] {
        return this.props.budgetItems;
    }
}
