import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { TaskPageSortBy } from 'sber-marketing-types/frontend';

import { StoreState } from '@store';
import { getActivityTasksUserConfig, saveActivityTasksUserConfig } from '@store/userConfig/activityTasks';

import { DashboardSortingMenuContainer, Props } from './DashboardSortingMenuContainer';

interface OwnProps {
    activityId: number;
}

interface MapProps {
    selectedOptionId: React.ReactText;
}

interface DispatchProps {
    onSelect: (selectedOptionId: TaskPageSortBy) => void;
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    return {
        selectedOptionId: getActivityTasksUserConfig(state).entries[ownProps.activityId]?.filters?.sorting,
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>, ownProps: OwnProps): DispatchProps {
    return {
        onSelect: (selectedOptionId: TaskPageSortBy) =>
            dispatch(
                saveActivityTasksUserConfig({
                    activityId: ownProps.activityId,
                    filters: {
                        sorting: selectedOptionId,
                    },
                }),
            ),
    };
}

function mergeProps(mapProps: MapProps, dispatchProps: DispatchProps, ownProps: OwnProps): Props {
    return {
        options: [
            {
                id: TaskPageSortBy.UNRESOLVED_FIRST,
                title: 'Сначала непросмотренные',
            },
            {
                id: TaskPageSortBy.UPDATING_DATE,
                title: 'По дате обновления',
            },
            {
                id: TaskPageSortBy.CREATION_DATE,
                title: 'По дате создания',
            },
            {
                id: TaskPageSortBy.WORK_TYPE,
                title: 'По виду работ',
            },
            {
                id: TaskPageSortBy.DEADLINE,
                title: 'По дедлайну',
            },
        ],
        activityId: ownProps.activityId,
        selectedOptionId: mapProps.selectedOptionId,
        onSelect: dispatchProps.onSelect,
    };
}

export const ActivityPageSortingMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(DashboardSortingMenuContainer);
