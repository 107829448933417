import * as React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { Textarea } from '../Textarea';

import * as styles from './styles.scss';
import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    value?: string;
    error?: string;
    placeholder?: string;
    autoFocus?: boolean;
    readOnly?: boolean;
    onChange?: (value: string) => void;
    onFocus?: (value: string) => void;
    onBlur?: (value: string) => void;
}

const PROTOCOL_REGEX = /^(https?:\/\/)/i;
const HREF_REGEX = /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/;

export const HrefInput: React.FC<Props> = ({
    value: initValue = '',
    autoFocus = false,
    readOnly = false,
    placeholder,
    onFocus = () => {},
    onBlur = () => {},
}) => {
    const [value, setValue] = React.useState(initValue);
    const [error, setError] = React.useState('');
    const [isEdit, setEdit] = React.useState(autoFocus);

    React.useEffect(() => {}, []);

    const onClearButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setValue('');
        setEdit(true);
    }, []);

    const onEditButtonClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setEdit(true);
    }, []);

    const onTextareaFocus = React.useCallback(() => {
        setEdit(true);
        onFocus('');
    }, [value, onFocus]);

    const onTextareaBlur = React.useCallback(() => {
        setEdit(false);
        setValue(value.trim());

        if (HREF_REGEX.test(value.trim())) {
            onBlur(value.trim());
            setError('');
        } else {
            onBlur('');
            setError('Некорректная ссылка');
        }
    }, [value, onBlur]);

    const onPlaceholderClick = React.useCallback(() => {
        setEdit(true);
    }, []);

    const onRootClick = React.useCallback(() => {
        setEdit(true);
    }, []);

    const onTextareaChange = React.useCallback((value: string) => {
        setValue(value);
    }, []);

    const link = PROTOCOL_REGEX.test(value) ? value : `https://${value}`;

    return (
        <div onClick={!readOnly ? onRootClick : undefined}>
            <div className={styles.root}>
                {isEdit ? (
                    <Textarea
                        value={value}
                        autoFocus
                        onChange={onTextareaChange}
                        onFocus={onTextareaFocus}
                        onBlur={onTextareaBlur}
                    />
                ) : value.length ? (
                    <a className={styles.link} href={link} target="_blank">
                        {value}
                    </a>
                ) : (
                    <div className={styles.placeholder} onClick={!readOnly ? onPlaceholderClick : undefined}>
                        {placeholder}
                    </div>
                )}

                {!isEdit && !readOnly && (
                    <div className={styles.actions}>
                        <div className={classNames(styles.button)} onClick={onEditButtonClick}>
                            <Icon type={IconType.NOTEPAD} />
                        </div>
                        <div className={classNames(styles.button)} onClick={onClearButtonClick}>
                            <Icon type={IconType.ERASER} />
                        </div>
                    </div>
                )}
            </div>

            {!isEmpty(error) && !isEdit && !!value?.length && <div className={styles.error}>{error}</div>}
        </div>
    );
};
