import { createSelector } from 'reselect';
import * as lodash from 'lodash';

import type { Brief, BriefBlock, BriefField, BriefDraftField } from 'sber-marketing-types/frontend';
import { FieldType } from 'sber-marketing-types/frontend';
import type { PageState, ActivityReferenceData } from './types';
import type { StoreState } from '../';

export const getBriefState = (state: StoreState): PageState => state.briefPage;

export const getBriefStateSaved = (state: StoreState): PageState => {
    const currentBriefs = lodash.cloneDeep(state.briefPage.currentBriefs);

    return {
        ...state.briefPage,
        currentBriefs: lodash.mapValues(currentBriefs, (brief) => {
            return {
                ...brief,
                blocks: brief.blocks.map((block) => {
                    return {
                        ...block,
                        fields: block.fields.map((field) => {
                            if (field.properties?.switchPropertyId) {
                                return {
                                    ...field,
                                    value: block.fields.find(
                                        (switchField) =>
                                            switchField.value?.switches?.find(
                                                (value) => value.id === field.properties?.switchPropertyId,
                                            ) || switchField.value?.selected === field.properties?.switchPropertyId,
                                    )
                                        ? field.value
                                        : undefined,
                                };
                            } else {
                                return field;
                            }
                        }),
                    };
                }),
            };
        }),
    };
};

export const getFieldById = createSelector(
    getBriefState,
    (
        state: StoreState,
        params: { id: string; uniqId: number; parentUniqId: number; briefId: string },
    ): { id: string; uniqId: number; parentUniqId: number; briefId: string } => params,
    (
        briefState: PageState,
        {
            id,
            uniqId = 0,
            parentUniqId = 0,
            briefId,
        }: { id: string; uniqId: number; parentUniqId: number; briefId: string },
    ): BriefField => {
        const { currentBriefs } = briefState;
        const blocks = (currentBriefs[briefId].blocks as BriefBlock[]) || [];

        const fields = lodash.flatMap(blocks, (item) => item.fields);
        const field = fields.find(
            (item) => item.id == id && (item.uniqId || 0) === uniqId && (item.parentUniqId || 0) === parentUniqId,
        );

        if ((uniqId || parentUniqId) && !field) {
            const fieldOrigin = fields.find((item) => item.id == id);

            return {
                ...fieldOrigin,
                value: {},
            };
        } else {
            return field;
        }
    },
);
export const getFieldsById = createSelector(
    getBriefState,
    (
        state: StoreState,
        params: { id: string; uniqId: number; parentUniqId: number; briefId: string },
    ): { id: string; uniqId: number; parentUniqId: number; briefId: string } => params,
    (
        briefState: PageState,
        {
            id,
            uniqId = 0,
            parentUniqId = 0,
            briefId,
        }: { id: string; uniqId: number; parentUniqId: number; briefId: string },
    ): { field: BriefField; fields: BriefField[] } => {
        const { currentBriefs } = briefState;
        const blocks = (currentBriefs[briefId].blocks as BriefBlock[]) || [];

        const fields = lodash.flatMap(blocks, (item) => item.fields);
        const field = fields.find(
            (item) => item.id == id && (item.uniqId || 0) === uniqId && (item.parentUniqId || 0) === parentUniqId,
        );

        if ((uniqId || parentUniqId) && !field) {
            const fieldOrigin = fields.find((item) => item.id == id);

            return {
                field: {
                    ...fieldOrigin,
                    value: {},
                },
                fields,
            };
        } else {
            return { field, fields };
        }
    },
);

export const checkFieldValidityById = createSelector(
    getFieldsById,
    ({ field, fields }: { field: BriefField; fields: BriefField[] }): boolean => {
        return checkFieldValidity(field, fields);
    },
);

export const getBriefDraft = createSelector(getBriefState, (briefState: PageState): BriefDraftField[] => {
    const { briefs, currentBriefs } = briefState;

    const briefFields = getBriefFields(briefs['briefId']);
    const currentBriefFields = getBriefFields(currentBriefs['briefId']);

    return getChangedFields(briefFields, currentBriefFields);
});

export const getActivityReferenceData = createSelector(
    getBriefState,
    (state: PageState): ActivityReferenceData => state.activityReferenceData,
);

function getBriefFields(brief: Brief): BriefField[] {
    return brief ? lodash.flatMap(brief.blocks, (item) => item.fields) : null;
}

function getChangedFields(oldFields: BriefField[], newFields: BriefField[]): BriefDraftField[] {
    if (!oldFields || !newFields) {
        return null;
    }

    const changedFields = newFields.filter((field) => {
        const foundItem = oldFields.find(
            (item) => item.id == field.id && item.uniqId === field.uniqId && item.parentUniqId === field.parentUniqId,
        );

        const newValue = checkFieldValue(field) ? field.value : {};
        const oldValue = checkFieldValue(foundItem) ? foundItem.value : {};

        const valueChanged = !lodash.isEqual(newValue, oldValue);
        const fieldIsFile = field.type == FieldType.FILE;

        return valueChanged && !fieldIsFile;
    });

    return changedFields.map((item) => ({
        id: item.id,
        value: item.value,
    }));
}

export function checkFieldValidity(field: BriefField, fields: BriefField[]): boolean {
    const isRequired = lodash.get(field, 'properties.isRequired');
    const switchPropertyId = lodash.get(field, 'properties.switchPropertyId');
    const isRequiredField = switchPropertyId
        ? isRequired && fields.some((parent) => parent.value?.switches?.some((item) => item.id === switchPropertyId))
        : isRequired;

    return (isRequiredField ? checkFieldValue(field) : true) && checkFieldFormat(field);
}

export function checkFieldValue(field: BriefField): boolean {
    const { value, type } = field;

    if (!value) {
        return false;
    }

    let fieldHasValue = true;

    switch (type) {
        case FieldType.TEXT:
            fieldHasValue = !!(value.text || value.date || value.from || value.to || value.values);
            break;

        case FieldType.DROPDOWN:
            fieldHasValue = !!value.selected;
            break;

        case FieldType.SWITCH_GROUP:
            fieldHasValue = (value.switches && value.switches.some((item) => item.value)) || !!value.selected;
            break;

        case FieldType.UNLOCKABLE_INPUT:
            fieldHasValue = value.unlocked && !!value.text;
            break;

        case FieldType.FILE:
            fieldHasValue = !lodash.isEmpty(value.files);
            break;
    }

    return fieldHasValue;
}

export function checkFieldFormat(field: BriefField): boolean {
    const isUrl = lodash.get(field, 'properties.isUrl');
    const { value, type } = field;

    switch (type) {
        case FieldType.TEXT:
            if (value && value.text && isUrl) {
                const linkRegExp =
                    /\b(((http(s)?:\/\/)([\w-]{1,32}(\.|\:)[\w-]{1,32}))|([\w-]{1,32}(\@)[\w-]{1,32}(\.)[\w-]{1,32})|([\w-]{1,32}(\.)[A-Za-z]{1,32}))\b/gi;
                return linkRegExp.test(value.text);
            }
            break;
    }

    return true;
}
