import * as React from 'react';
import classNames from 'classnames';

import * as style from './LineHeader.scss';

import { Icon, IconType, BodyPortal } from 'sber-marketing-ui';
import { ContextMenuWrapper } from './ContextMenuWrapper';
import { ContextMenu } from './ContextMenu';

interface Props {
    lot: 1 | 2;
    title: string;
    contractId: string;
    creativeRequestId: string;
    displayMenuButton: boolean;
    lineCanBeTransfered: boolean;
    lineIsArchived: boolean;
    lineCanBeRestored: boolean;
    isExpanded: boolean;
    customStyle?: any;
    onCreativeRequestInfoDisplay: (creativeRequestId: string) => void;
    onLineTransfer: (contractId: string) => void;
    onLineArchive: () => void;
    onLineRestore: () => void;
    onTariffTotalExpansionToggle: () => void;
}

export const LineHeader = ({
    lot,
    title,
    contractId,
    creativeRequestId,
    displayMenuButton,
    lineCanBeTransfered,
    lineIsArchived,
    lineCanBeRestored,
    isExpanded,
    customStyle,
    onCreativeRequestInfoDisplay,
    onLineTransfer,
    onLineArchive,
    onLineRestore,
    onTariffTotalExpansionToggle,
}: Props): JSX.Element => {
    const [isOpened, setOpenedStatus] = React.useState(false);

    const onOpenerButtonClick = React.useCallback(() => {
        setOpenedStatus(!isOpened);
    }, [isOpened]);

    function close() {
        setOpenedStatus(false);
    }

    return (
        <div className={classNames(style.root, isOpened && style.opened)} style={{ ...customStyle }}>
            {!!onTariffTotalExpansionToggle && (
                <div className={style.expandButton} onClick={onTariffTotalExpansionToggle}>
                    <Icon type={isExpanded ? IconType.TRIANGLE8_UP : IconType.TRIANGLE8_DOWN} svgSize={8} />
                </div>
            )}

            <div className={style.title}>{title}</div>

            {displayMenuButton && (
                <div className={style.openerButton} onClick={onOpenerButtonClick}>
                    <Icon type={IconType.KEBAB} svgSize={9} />
                </div>
            )}

            {isOpened && (
                <>
                    <ContextMenuWrapper>
                        <ContextMenu
                            lot={lot}
                            contractId={contractId}
                            creativeRequestId={creativeRequestId}
                            lineCanBeTransfered={lineCanBeTransfered}
                            lineIsArchived={lineIsArchived}
                            lineCanBeRestored={lineCanBeRestored}
                            customStyle={customStyle}
                            onButtonClick={close}
                            onCreativeRequestInfoDisplay={onCreativeRequestInfoDisplay}
                            onLineTransfer={onLineTransfer}
                            onLineArchive={onLineArchive}
                            onLineRestore={onLineRestore}
                        />
                    </ContextMenuWrapper>

                    <BodyPortal>
                        <div className={style.mask} onClick={close} />
                    </BodyPortal>
                </>
            )}
        </div>
    );
};
