// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-tags-TaskDescription-_TaskDescription__root,.src-client-modules-task-components-tags-TaskDescription-_TaskDescription__root>.src-client-modules-task-components-tags-TaskDescription-_TaskDescription__textarea{font-size:14px}.src-client-modules-task-components-tags-TaskDescription-_TaskDescription__onlyRead{white-space:pre-wrap;word-break:break-word}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/tags/TaskDescription/TaskDescription.scss"],"names":[],"mappings":"AAAA,oPAEE,cAAe,CAChB,oFAGC,oBAAqB,CACrB,qBAAsB","sourcesContent":[".root,\n.root > .textarea {\n  font-size: 14px;\n}\n\n.onlyRead {\n  white-space: pre-wrap;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-tags-TaskDescription-_TaskDescription__root",
	"textarea": "src-client-modules-task-components-tags-TaskDescription-_TaskDescription__textarea",
	"onlyRead": "src-client-modules-task-components-tags-TaskDescription-_TaskDescription__onlyRead"
};
export default ___CSS_LOADER_EXPORT___;
