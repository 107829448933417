import * as React from 'react';
import classnames from 'classnames';
import { orderBy } from 'lodash';
import { CloseButton } from 'sber-marketing-ui';
import { CreativeRequest } from '@api';
import { CreateCommentForm as CommonCreateCommentForm } from '@common/CreateCommentForm';
import { ColumnMarker } from './ColumnMarker';
import * as styles from './Comments.scss';
import * as commonStyles from '../../CommonStyles.scss';
import { useComments } from './hooks/useComments';
import { ItemCard } from './ItemCard/ItemCard';
import { CreativeRequestComments } from './CreativeRequestComments/CreativeRequestComments';
import { CreativeRequestItemComments } from './CreativeRequestItemComments/CreativeRequestItemComments';

interface Props {
    creativeRequest: CreativeRequest;
}

export function Comments(props: Props): JSX.Element {
    const {
        usersForMentionOrganizationIds,
        creativeRequestItems,
        activeCreativeRequestItem,
        activeCreativeRequestColumn,
        activeCreativeRequestItemId,
        setActiveCreativeRequestItemId,
        commentToReplyTo,
        setCommentToReplyTo,
        onCommmentCreated,
        closeActiveCreativeRequestColumn,
        comments,
        refetchComments,
    } = useComments(props);
    const { creativeRequest } = props;

    const renderColumnMarker = activeCreativeRequestItemId && activeCreativeRequestColumn;

    let content: JSX.Element = null;
    let shouldRenderCreateCommentForm: boolean;

    // Общие комментарии
    if (!activeCreativeRequestItemId) {
        content = (
            <CreativeRequestComments
                creativeRequest={creativeRequest}
                commentIdToReplyTo={commentToReplyTo?.commentId}
                setCommentToReplyTo={setCommentToReplyTo}
            />
        );
        shouldRenderCreateCommentForm = true;

        // Коментарии по строке
    } else {
        content = activeCreativeRequestItem ? (
            <CreativeRequestItemComments
                creativeRequestItem={activeCreativeRequestItem.dto}
                commentIdToReplyTo={commentToReplyTo?.commentId}
                activeCreativeRequestColumn={activeCreativeRequestColumn}
                setCommentToReplyTo={setCommentToReplyTo}
                comments={comments}
                refetchComments={refetchComments}
            />
        ) : null;
        shouldRenderCreateCommentForm = !!(commentToReplyTo?.commentId || activeCreativeRequestColumn);
    }

    return (
        <React.Fragment>
            <div className={styles.root}>
                {/* ======= Карточка "общие" ============ */}
                <ItemCard
                    creativeRequestItem={null}
                    activeCreativeRequestItemId={activeCreativeRequestItemId}
                    setActiveCreativeRequestItemId={setActiveCreativeRequestItemId}
                />

                {/* ======= Карточки строк ============ */}
                {orderBy(creativeRequestItems, (item) => parseInt((item.title.match(/d/g) || []).join('')))?.map(
                    (creativeRequestItem) => (
                        <ItemCard
                            key={creativeRequestItem.dto.model.id}
                            creativeRequestItem={creativeRequestItem}
                            activeCreativeRequestItemId={activeCreativeRequestItemId}
                            setActiveCreativeRequestItemId={setActiveCreativeRequestItemId}
                        />
                    ),
                )}
            </div>

            {/* ======= Комментарии ============ */}
            {content}

            {/* ======= Формы создания комментариев ============ */}
            {shouldRenderCreateCommentForm && (
                <div className={classnames(styles.createCommentForm, commonStyles.sidePaddings)}>
                    <CommonCreateCommentForm
                        usersOrganizationsForMention={usersForMentionOrganizationIds}
                        parrentCommentId={commentToReplyTo?.commentId}
                        onCommentCreated={onCommmentCreated}
                    />

                    {renderColumnMarker && (
                        <div className={styles.columnMarker}>
                            <ColumnMarker
                                creativeRequestItemId={activeCreativeRequestItemId}
                                column={activeCreativeRequestColumn}
                            />

                            <CloseButton onClick={closeActiveCreativeRequestColumn} size={16} />
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
