import * as React from 'react';
import * as styles from './styles.scss';

import { Icon, IconType } from 'sber-marketing-ui';

interface Props {
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const UploadButton: React.FC<Props> = ({ onClick }) => {
    return (
        <div className={styles.root} onClick={onClick}>
            <div className={styles.icon}>
                <Icon type={IconType.PAPERCLIP} />
            </div>
            <div className={styles.text}>Загрузить файл</div>
        </div>
    );
};
