import * as React from 'react';
import * as style from './Statuses.scss';
import GroupCard from './GroupCard/GroupCard';
import Status from './Status/Status';
import { GroupCards } from '../consts';
import { useStatuses } from './hooks/useStatuses';
import { RightGroupCards } from '../consts';
import WithButtonLayout from './WithButtonLayout/WithButtonLayout';
import { CreativeRequestStatus } from '@api';
import { useStatusesScroll } from './hooks/useStatusesScroll';

interface Props {
    currentStatus: CreativeRequestStatus;
}

export const Statuses = ({ currentStatus }: Props): JSX.Element => {
    const { statusesList, isApplicationView, setIsApplicationView, currentGroupCardName } = useStatuses({
        currentStatus,
    });

    const statusesRootRef = React.useRef(null);

    useStatusesScroll({ statusesRootRef });

    return (
        <div className={style.root} ref={statusesRootRef}>
            <GroupCard
                title="Создание"
                isActive={currentGroupCardName === GroupCards.APPLICATOIN || isApplicationView}
                onClick={
                    isApplicationView
                        ? null
                        : () => {
                              setIsApplicationView(true);
                          }
                }
            >
                <WithButtonLayout
                    showButton={isApplicationView && currentGroupCardName !== GroupCards.APPLICATOIN}
                    buttonAction={() => setIsApplicationView(false)}
                >
                    {statusesList
                        .filter(({ type }) => type === GroupCards.APPLICATOIN)
                        .map(({ title, isCurrent, isDone }) => (
                            <Status key={title} title={title} isLink={true} isCurrent={isCurrent} isDone={isDone} />
                        ))}
                </WithButtonLayout>
            </GroupCard>

            {/* ============== DIVIDER ============= */}
            <div className={style.divider}></div>

            {RightGroupCards.map(({ cardTitle, cardType }) => (
                <GroupCard
                    key={cardTitle}
                    title={cardTitle}
                    isActive={currentGroupCardName === cardType && !isApplicationView}
                >
                    {statusesList
                        .filter(({ type }) => type === cardType)
                        .map(({ title, isCurrent, isDone }) => (
                            <Status key={title} title={title} isCurrent={isCurrent} isDone={isDone} />
                        ))}
                </GroupCard>
            ))}
        </div>
    );
};
