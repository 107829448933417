// tslint:disable:max-file-line-count
import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import * as style from './BudgetExecution.scss';

import { StoreState } from '@store';
import {
    ColumnName,
    CORRECTION_FILTER_TYPE,
    getBudgetExecutionPageState,
    ActivityReferenceMenuVisibility,
    CreativeReferenceMenuVisibility,
    userCanEditOrCreateBudgetData as userCanEditOrCreateBudgetData_,
    getBudgetItemsWithCorrectedPlanOverrun,
    getBudgetItemsWithPlanOverrunedByReserve,
    getFilteredTableLines,
    getLineModalState,
    setShowOnlyLinesWithNegativeBalance,
    setShowOnlyLinesWithNegativePlanFactDiff,
    setShowOnlyLinesWithPlanBudget,
    setShowOnlyLinesWithoutPlan,
    setUseLinesWithoutPlanInSorting,
    setCorrectionsToDisplay,
    setColumnsVisiblityFilter,
    DownloadTableAsXLSXPaylaod,
} from '@store/budgetExecution';

import {
    OpacityTransition,
    Icon,
    IconType,
    DynamicIcon,
    Preloader,
    FilterMenu,
    FilterCheckbox,
    SelectedCountColor,
    Dropdown,
    CustomScrollbar_new as CustomScrollbar,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    WithTooltip,
    WithGlobalPopup,
    AbsoluteLikePositionCalculator,
} from 'sber-marketing-ui';

import { BudgetExpertsPopup } from '@common/BudgetExpertsPopup';

import { Table } from './Table';
import { ActivityReferenceMenu } from './ActivityReferenceMenu';
import { CreativeReferenceMenu } from './CreativeReferenceMenu';
import { BudgetTransferMenu } from './BudgetTransferMenu';
import { LineModal } from './LineModal';
import { FiltersColumnsTagsBar } from './FiltersColumnsTagsBar';
import { TransferBudgetItemsToPlanningMenu } from './TransferBudgetItemsToPlanningMenu';
import { FMPTableImportMenu } from './FMPTableImportMenu';
import { UserConfigEffect } from './Effects';
import { ColumnsList } from './ColumnsConfig';

interface Props {
    budgetId: string;
    isRequestInProgress: boolean;
    pageContentHeight: number;
    tableOffsetTop: number;
    scrollLeftColumn: ColumnName;
    maxTableHeight: number;
    activityReferenceMenuVisibility: ActivityReferenceMenuVisibility;
    creativeReferenceMenuVisibility: CreativeReferenceMenuVisibility;
    sendButtonIsDisabled: boolean;
    transferFormIsVisible: boolean;
    userIsBudgetExpert: boolean;
    rootRef: (element: HTMLDivElement) => void;
    tableRef: (element: HTMLDivElement) => void;
    tableContentRef: (element: Table) => void;
    onResetPageFiltersButtonClick: () => void;
    onResetPageViewSettingsButtonClick: () => void;
    onTransferSendButtonClick: () => void;
    onActivityReferenceButtonClick: () => void;
    onSendButtonClick: () => void;
    onDownloadXLSXButtonClick: (paylaod: DownloadTableAsXLSXPaylaod) => void;
    onDownloadFMPTableButtonClick: () => void;
    onUndoButtonClick: () => void;
    disableUndoButton: boolean;
    onRedoButtonClick: () => void;
    disableRedoButton: boolean;
    onResetChangesHistoryClick: () => void;
    disableResetChangesHistoryButton: boolean;
    showBudgetExpertsPopup: boolean;
    onCloseBudgetExpertsPopupButtonClick: () => void;
    onSubmitBudgetExpertsPopupButtonClick: (expertId: number) => void;
    // areFiltersApplied: boolean;
    onApplyFiltersButtonClick: () => void;
    isTransferBudgetItemsToPlanningOpened: boolean;
    onActivityReferenceMenuClose: () => void;
    onActivityReferenceMenuFinish: () => void;
    onCreativeReferenceMenuClose: () => void;
    pageDataRefetchInProgress: boolean;
    userConfigEffectRef: React.RefObject<UserConfigEffect>;
}

export function BudgetExecution({
    budgetId,
    isRequestInProgress,
    pageContentHeight,
    tableOffsetTop,
    maxTableHeight,
    activityReferenceMenuVisibility,
    creativeReferenceMenuVisibility,
    scrollLeftColumn,
    sendButtonIsDisabled,
    transferFormIsVisible,
    userIsBudgetExpert,
    rootRef,
    tableRef,
    tableContentRef,
    onResetPageFiltersButtonClick,
    onResetPageViewSettingsButtonClick,
    onTransferSendButtonClick,
    onActivityReferenceButtonClick,
    onSendButtonClick,
    onDownloadXLSXButtonClick,
    onDownloadFMPTableButtonClick,
    onUndoButtonClick,
    disableUndoButton,
    onRedoButtonClick,
    disableRedoButton,
    onResetChangesHistoryClick,
    disableResetChangesHistoryButton,
    showBudgetExpertsPopup,
    onCloseBudgetExpertsPopupButtonClick,
    onSubmitBudgetExpertsPopupButtonClick,
    onApplyFiltersButtonClick,
    isTransferBudgetItemsToPlanningOpened,
    onActivityReferenceMenuClose,
    onActivityReferenceMenuFinish,
    onCreativeReferenceMenuClose,
    pageDataRefetchInProgress,
    userConfigEffectRef,
}: Props): JSX.Element {
    return (
        <OpacityTransition start={!isRequestInProgress}>
            <div className={style.root} ref={rootRef}>
                <div className={style.topLine}>
                    <LeftColumn
                        budgetId={budgetId}
                        onUndoButtonClick={onUndoButtonClick}
                        disableUndoButton={disableUndoButton}
                        onRedoButtonClick={onRedoButtonClick}
                        disableRedoButton={disableRedoButton}
                        onTransferSendButtonClick={onTransferSendButtonClick}
                        onActivityReferenceButtonClick={onActivityReferenceButtonClick}
                        onDownloadXLSXButtonClick={onDownloadXLSXButtonClick}
                        onDownloadFMPTableButtonClick={onDownloadFMPTableButtonClick}
                        onResetChangesHistoryClick={onResetChangesHistoryClick}
                        disableResetChangesHistoryButton={disableResetChangesHistoryButton}
                        onResetPageFiltersButtonClick={onResetPageFiltersButtonClick}
                        onResetPageViewSettingsButtonClick={onResetPageViewSettingsButtonClick}
                        onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                        isTransferBudgetItemsToPlanningOpened={isTransferBudgetItemsToPlanningOpened}
                        userIsBudgetExpert={userIsBudgetExpert}
                    />

                    <RightColumn sendButtonIsDisabled={sendButtonIsDisabled} onSendButtonClick={onSendButtonClick} />
                </div>

                <div className={style.table} ref={tableRef}>
                    <Table
                        ref={tableContentRef}
                        maxHeight={maxTableHeight}
                        pageContentHeight={pageContentHeight}
                        tableOffsetTop={tableOffsetTop}
                        initColumnScroll={scrollLeftColumn}
                        onApplyFiltersButtonClick={onApplyFiltersButtonClick}
                    />
                </div>

                {transferFormIsVisible && <BudgetTransferMenu />}

                {activityReferenceMenuVisibility.visibility && (
                    <ActivityReferenceMenu
                        selectedActivityId={activityReferenceMenuVisibility.selectedActivityId}
                        filters={activityReferenceMenuVisibility.filters}
                        onClose={onActivityReferenceMenuClose}
                        onFinish={onActivityReferenceMenuFinish}
                    />
                )}

                {creativeReferenceMenuVisibility.visibility && (
                    <CreativeReferenceMenu
                        creativeRequestId={creativeReferenceMenuVisibility.creativeRequestId}
                        onClose={onCreativeReferenceMenuClose}
                    />
                )}

                <TablePreloader />

                {showBudgetExpertsPopup && (
                    <BudgetExpertsPopup
                        closePopup={onCloseBudgetExpertsPopupButtonClick}
                        submitPopup={onSubmitBudgetExpertsPopupButtonClick}
                    />
                )}

                <TableLineModal />

                <TransferBudgetItemsToPlanningMenu />
                <FMPTableImportMenu budgetId={budgetId} />

                <UserConfigEffect ref={userConfigEffectRef} pageDataRefetchInProgress={pageDataRefetchInProgress} />
            </div>
        </OpacityTransition>
    );
}

interface LeftColumnProps {
    budgetId: string;
    onUndoButtonClick: () => void;
    disableUndoButton: boolean;
    onRedoButtonClick: () => void;
    disableRedoButton: boolean;
    onApplyFiltersButtonClick: () => void;
    onTransferSendButtonClick: () => void;
    onActivityReferenceButtonClick: () => void;
    onDownloadXLSXButtonClick: (paylaod: DownloadTableAsXLSXPaylaod) => void;
    onDownloadFMPTableButtonClick: () => void;
    onResetChangesHistoryClick: () => void;
    disableResetChangesHistoryButton: boolean;
    onResetPageFiltersButtonClick: () => void;
    onResetPageViewSettingsButtonClick: () => void;
    // selectedColumnsFilterItems: string[];
    isTransferBudgetItemsToPlanningOpened: boolean;
    userIsBudgetExpert: boolean;
}

function LeftColumn({
    budgetId,
    onUndoButtonClick,
    disableUndoButton,
    onRedoButtonClick,
    disableRedoButton,
    onTransferSendButtonClick,
    onActivityReferenceButtonClick,
    onDownloadXLSXButtonClick,
    onDownloadFMPTableButtonClick,
    onResetChangesHistoryClick,
    disableResetChangesHistoryButton,
    onResetPageFiltersButtonClick,
    onResetPageViewSettingsButtonClick,
    // selectedColumnsFilterItems,
    onApplyFiltersButtonClick,
    isTransferBudgetItemsToPlanningOpened,
    userIsBudgetExpert,
}: LeftColumnProps): JSX.Element {
    const dispatch = useDispatch();

    const columnsVisiblityFilter = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).pageFilters.columnsVisiblityFilter,
    );
    function onColumnsFilterItemSelect(selectedItemIds: string[]) {
        dispatch(
            setColumnsVisiblityFilter(
                ColumnsList.reduce(
                    (acc, column) => ({
                        ...acc,
                        [column.name]: selectedItemIds.includes(column.name),
                    }),
                    {},
                ),
            ),
        );
    }

    const showOnlyLinesWithoutPlan = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).pageFilters.showOnlyLinesWithoutPlan,
    );
    function onShowOnlyLinesWithoutPlanCheckboxToggle() {
        dispatch(setShowOnlyLinesWithoutPlan(!showOnlyLinesWithoutPlan));
    }

    const showOnlyLinesWithNegativeBalance = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).pageFilters.showOnlyLinesWithNegativeBalance,
    );
    function onShowOnlyLinesWithNegativeBalanceCheckboxToggle() {
        dispatch(setShowOnlyLinesWithNegativeBalance(!showOnlyLinesWithNegativeBalance));
    }

    const correctionsToDisplay = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).pageFilters.correctionsToDisplay,
    );
    function onCorrectionsToDisplayChange(correctionsToDisplayIds: string[]) {
        dispatch(
            setCorrectionsToDisplay({
                [CORRECTION_FILTER_TYPE.PLAN_CORRECTION]: correctionsToDisplayIds.includes(
                    CORRECTION_FILTER_TYPE.PLAN_CORRECTION,
                ),
                [CORRECTION_FILTER_TYPE.RESERVE_CORRECTION]: correctionsToDisplayIds.includes(
                    CORRECTION_FILTER_TYPE.RESERVE_CORRECTION,
                ),
                [CORRECTION_FILTER_TYPE.BUDGET_ITEM_CORRECTION]: correctionsToDisplayIds.includes(
                    CORRECTION_FILTER_TYPE.BUDGET_ITEM_CORRECTION,
                ),
                [CORRECTION_FILTER_TYPE.ACTIVITY_CORRECTION]: correctionsToDisplayIds.includes(
                    CORRECTION_FILTER_TYPE.ACTIVITY_CORRECTION,
                ),
                [CORRECTION_FILTER_TYPE.NO_CORRECTIONS]: correctionsToDisplayIds.includes(
                    CORRECTION_FILTER_TYPE.NO_CORRECTIONS,
                ),
            }),
        );
    }

    const useLinesWithoutPlanInSorting = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).pageFilters.useLinesWithoutPlanInSorting,
    );
    function onUseLinesWithoutPlanInSortingToggle() {
        dispatch(setUseLinesWithoutPlanInSorting(!useLinesWithoutPlanInSorting));
    }

    const showOnlyLinesWithPlanBudget = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).pageFilters.showOnlyLinesWithPlanBudget,
    );
    function onShowOnlyLinesWithPlanBudgetToggle() {
        dispatch(setShowOnlyLinesWithPlanBudget(!showOnlyLinesWithPlanBudget));
    }

    const showOnlyLinesWithNegativePlanFactDiff = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).pageFilters.showOnlyLinesWithNegativePlanFactDiff,
    );
    function onShowOnlyLinesWIthNegativePlanFactDiffToggle() {
        dispatch(setShowOnlyLinesWithNegativePlanFactDiff(!showOnlyLinesWithNegativePlanFactDiff));
    }

    const selectedColumnsFilterItems = Object.keys(columnsVisiblityFilter).filter(
        (column) => columnsVisiblityFilter[column],
    );
    const areFiltersApplied =
        showOnlyLinesWithoutPlan ||
        Object.keys(correctionsToDisplay).some((filter) => correctionsToDisplay[filter]) ||
        !!selectedColumnsFilterItems.length;

    const userCanEditOrCreateBudgetData = useSelector(userCanEditOrCreateBudgetData_);

    const filteredLines = useSelector(getFilteredTableLines);
    const totalLinesCount = filteredLines.length;
    const totalLinesWithoutPlanCount = filteredLines.filter((line) => !line.planValue).length;

    const budgetItemsWithCorrectedPlanOverrun = useSelector(getBudgetItemsWithCorrectedPlanOverrun);
    const budgetItemsWithPlanOverrunedByReserve = useSelector(getBudgetItemsWithPlanOverrunedByReserve);

    const linesWithNegativeBalanceCount = [
        ...budgetItemsWithCorrectedPlanOverrun,
        ...budgetItemsWithPlanOverrunedByReserve,
    ].filter((id) => filteredLines.some((line) => line.id === id)).length;

    const isColumnFilterTagBarShow = useSelector(
        (state: StoreState) => !!getBudgetExecutionPageState(state).pageFilters.appliedFiltersNames.length,
    );

    return (
        <div className={style.topLineGroup}>
            {userCanEditOrCreateBudgetData && (
                <React.Fragment>
                    <WithTooltip spanWrapper content="Отменить изменения">
                        <div
                            className={style.undoButton}
                            onClick={onUndoButtonClick}
                            {...{
                                'qa-id': 'budgetExecutionUndoButton',
                                disabled: disableUndoButton,
                            }}
                        >
                            <Icon
                                type={IconType.CURLY_ARROW_BACK}
                                className={classNames([style.icon, disableUndoButton && style.iconDisabled])}
                            />
                        </div>
                    </WithTooltip>

                    <WithTooltip spanWrapper content="Вернуть изменения">
                        <div
                            className={style.redoButton}
                            onClick={onRedoButtonClick}
                            {...{
                                'qa-id': 'budgetExecutionRedoButton',
                                disabled: disableRedoButton,
                            }}
                        >
                            <Icon
                                type={IconType.CURLY_ARROW_FORWARD}
                                className={classNames([style.icon, disableRedoButton && style.iconDisabled])}
                            />
                        </div>
                    </WithTooltip>
                </React.Fragment>
            )}

            <WithTooltip spanWrapper content="Сбросить параметры отображения">
                <div
                    className={classNames(style.resetViewSettingsButton, style.strokeIcon)}
                    onClick={onResetPageViewSettingsButtonClick}
                    {...{
                        'qa-id': 'budgetExecutionResetPageViewSettingsButton',
                    }}
                >
                    <Icon type={IconType.RESET_VIEW} />
                </div>
            </WithTooltip>

            <div className={style.verticalBar} />

            {userCanEditOrCreateBudgetData && (
                <React.Fragment>
                    <WithTooltip spanWrapper content="Создать активность">
                        <div
                            {...{
                                'qa-id': 'budgetExecutionCreateProjectLink',
                            }}
                        >
                            <Link
                                to={`/budget/execution/new?budgetId=${budgetId}`}
                                className={style.createProjectButton}
                            >
                                <Icon type={IconType.ADD_PROJECT} className={style.icon} />
                            </Link>
                        </div>
                    </WithTooltip>

                    <WithTooltip spanWrapper content="Перенос планового бюджета">
                        <div
                            onClick={isTransferBudgetItemsToPlanningOpened ? null : onTransferSendButtonClick}
                            className={style.transferBudgetButton}
                            {...{
                                'qa-id': 'budgetExecutionTransferMenuButton',
                            }}
                        >
                            <Icon
                                type={IconType.TURN_ARROW}
                                className={classNames(
                                    style.icon,
                                    isTransferBudgetItemsToPlanningOpened && style.iconDisabled,
                                )}
                            />
                        </div>
                    </WithTooltip>

                    <WithTooltip spanWrapper content="Привязать строки бюджета к проектам">
                        <div
                            onClick={onActivityReferenceButtonClick}
                            className={style.activityReferenceButton}
                            {...{
                                'qa-id': 'budgetExecutionActivityReferenceButton',
                            }}
                        >
                            <DynamicIcon
                                common={{ svgSize: 24 }}
                                normal={{ type: IconType.CHAIN }}
                                hover={{ type: IconType.CHAIN_HOVER }}
                            />
                        </div>
                    </WithTooltip>
                </React.Fragment>
            )}

            <DownloadTableAsXLSXButton
                userIsBudgetExpert={userIsBudgetExpert}
                onDownloadXLSXButtonClick={onDownloadXLSXButtonClick}
                onDownloadFMPTableButtonClick={onDownloadFMPTableButtonClick}
            />

            {userCanEditOrCreateBudgetData && (
                <React.Fragment>
                    <div className={style.verticalBar} />

                    <WithTooltip spanWrapper content="Очистить изменения">
                        <div
                            className={style.clearUnsavedChangesButton}
                            onClick={onResetChangesHistoryClick}
                            {...{
                                'qa-id': 'budgetExecutionResetChangesHistoryButton',
                                disabled: disableResetChangesHistoryButton,
                            }}
                        >
                            <Icon
                                type={IconType.ERASER}
                                className={classNames([
                                    style.eraserIcon,
                                    disableResetChangesHistoryButton && style.eraserIconDisabled,
                                ])}
                            />
                        </div>
                    </WithTooltip>
                </React.Fragment>
            )}

            <div className={style.filtersWrapper}>
                <Dropdown
                    title={(isOpened) => (
                        <div
                            className={classNames(
                                style.filterOpener,
                                isOpened && style.filterOpenerOpened,
                                !isOpened && areFiltersApplied && style.filterOpenerFiltersApplied,
                            )}
                            {...{
                                'qa-id': 'budgetExecutionFiltersButton',
                            }}
                        >
                            {!!totalLinesWithoutPlanCount && <div className={style.linesWithoutPlanMarker} />}

                            <div className={style.filterOpenerIcon}>
                                <Icon type={IconType.FILTERS} />
                            </div>

                            <div className={style.filterOpenerTitle}>Фильтр</div>
                        </div>
                    )}
                    content={
                        <div className={style.filterContent}>
                            <CustomScrollbar maxHeight={484}>
                                <div className={style.scrollbarContent}>
                                    <FilterCheckbox
                                        qaId="budgetExecutionLinesWithourPlanCheckbox"
                                        title="Только строки без бюджета"
                                        tooltip="Только строки без бюджета"
                                        selectedCount={totalLinesWithoutPlanCount}
                                        selectedCountColor={SelectedCountColor.RED}
                                        isChecked={showOnlyLinesWithoutPlan}
                                        totalCount={totalLinesCount}
                                        onClick={onShowOnlyLinesWithoutPlanCheckboxToggle}
                                    />

                                    <FilterCheckbox
                                        qaId="budgetExecutionShowOnlyLinesWithPlanBudgetCheckbox"
                                        title="Только строки с бюджетом"
                                        tooltip="Только строки с бюджетом"
                                        isChecked={showOnlyLinesWithPlanBudget}
                                        onClick={onShowOnlyLinesWithPlanBudgetToggle}
                                    />

                                    <FilterCheckbox
                                        qaId="budgetExecutionLinesWithNegativeBalanceCheckbox"
                                        title="Только строки с отрицательным остатком по резерву"
                                        tooltip="Только строки с отрицательным остатком по резерву"
                                        selectedCount={linesWithNegativeBalanceCount}
                                        selectedCountColor={SelectedCountColor.RED}
                                        isChecked={showOnlyLinesWithNegativeBalance}
                                        totalCount={totalLinesCount}
                                        onClick={onShowOnlyLinesWithNegativeBalanceCheckboxToggle}
                                    />

                                    <FilterCheckbox
                                        qaId="budgetExeutionShowOnlyLinesWithNegativePlanFactDiffCheckbox"
                                        title="Только строки с отрицательным остатком по факту"
                                        tooltip="Только строки с отрицательным остатком по факту"
                                        isChecked={showOnlyLinesWithNegativePlanFactDiff}
                                        onClick={onShowOnlyLinesWIthNegativePlanFactDiffToggle}
                                    />

                                    <FilterCheckbox
                                        qaId="budgetExecutionUseLinesWithoutPlanInSortingCheckbox"
                                        title="Сортировать строки с отрицательным планом"
                                        tooltip="Сортировать строки с отрицательным планом"
                                        isChecked={useLinesWithoutPlanInSorting}
                                        onClick={onUseLinesWithoutPlanInSortingToggle}
                                    />

                                    <FilterMenu
                                        qaId="budgetExecutionCorrectionsToDisplayFilter"
                                        filterTitle="Отображаемые корректировки"
                                        items={[
                                            {
                                                id: CORRECTION_FILTER_TYPE.ACTIVITY_CORRECTION,
                                                title: 'Корректировки параметров активности',
                                            },
                                            {
                                                id: CORRECTION_FILTER_TYPE.BUDGET_ITEM_CORRECTION,
                                                title: 'Корректировки параметров строки',
                                            },
                                            {
                                                id: CORRECTION_FILTER_TYPE.PLAN_CORRECTION,
                                                title: 'Корректировки плана',
                                            },
                                            {
                                                id: CORRECTION_FILTER_TYPE.RESERVE_CORRECTION,
                                                title: 'Корректировки резерва',
                                            },
                                            {
                                                id: CORRECTION_FILTER_TYPE.NO_CORRECTIONS,
                                                title: 'Без корректировок',
                                            },
                                        ]}
                                        checkedItemsIds={Object.keys(correctionsToDisplay).filter(
                                            (status) => correctionsToDisplay[status],
                                        )}
                                        isExpanded
                                        onItemSelection={onCorrectionsToDisplayChange}
                                    />

                                    <FilterMenu
                                        qaId="budgetExecutionSelectedColumnsFilter"
                                        filterTitle="Отображаемые колонки"
                                        items={Object.keys(columnsVisiblityFilter).map((column, index) => ({
                                            id: column,
                                            title:
                                                ColumnsList.find((item) => item.name == column)?.title ||
                                                'Колонка не найдена',
                                            value: columnsVisiblityFilter[column],
                                            order: index,
                                        }))}
                                        checkedItemsIds={selectedColumnsFilterItems}
                                        onItemSelection={onColumnsFilterItemSelect}
                                        isExpanded
                                    />
                                </div>
                            </CustomScrollbar>
                        </div>
                    }
                    disableScroll
                />
            </div>

            <WithTooltip spanWrapper content="Сбросить параметры фильтров">
                <div
                    onClick={onResetPageFiltersButtonClick}
                    {...{
                        'qa-id': 'budgetExecutionResetPageFiltersButton',
                    }}
                >
                    <Icon type={IconType.RESET_USER_CONFIG} className={style.resetUserConfigIcon} />
                </div>
            </WithTooltip>

            {isColumnFilterTagBarShow && (
                <div className={style.filtersColumnsTagsBarWrapper}>
                    <FiltersColumnsTagsBar onApplyFiltersButtonClick={onApplyFiltersButtonClick} />
                </div>
            )}

            <ApplyFiltersButton onClick={onApplyFiltersButtonClick} />
        </div>
    );
}

interface DownloadTableAsXLSXButtonProps {
    userIsBudgetExpert: boolean;
    onDownloadFMPTableButtonClick: () => void;
    onDownloadXLSXButtonClick: (paylaod: DownloadTableAsXLSXPaylaod) => void;
}

function DownloadTableAsXLSXButton({
    userIsBudgetExpert,
    onDownloadFMPTableButtonClick,
    onDownloadXLSXButtonClick,
}: DownloadTableAsXLSXButtonProps): JSX.Element {
    const buttonRef = React.useRef();

    const [isMenuVisible, setIsMenuVisible] = React.useState(false);

    function downloadWithFilters() {
        setIsMenuVisible(false);
        onDownloadXLSXButtonClick({ useFilters: true });
    }

    function downloadWithoutFilters() {
        setIsMenuVisible(false);
        onDownloadXLSXButtonClick({ useFilters: false });
    }

    function onDownloadFMPTableButtonClickHandler() {
        setIsMenuVisible(false);
        onDownloadFMPTableButtonClick();
    }

    return (
        <React.Fragment>
            <WithTooltip spanWrapper content="Скачать таблицу (Excel)">
                <div
                    ref={buttonRef}
                    className={style.downloaAsXlsxButton}
                    {...{
                        'qa-id': 'budgetExecutionXLSXDownloadButton',
                    }}
                    onClick={() => setIsMenuVisible(true)}
                >
                    <Icon type={IconType.EXPORT_TABLE} className={style.icon} />
                </div>
            </WithTooltip>

            {isMenuVisible && (
                <WithGlobalPopup
                    container={buttonRef}
                    positionCalculator={AbsoluteLikePositionCalculator}
                    bottom={-6}
                    left={-9}
                    onMaskClick={() => setIsMenuVisible(false)}
                >
                    <div className={style.xlsxImportExportMenu}>
                        <div className={style.xlsxImportExportMenuTitle}>Скачать</div>

                        <div
                            className={style.xlsxImportExportMenuButton}
                            onClick={downloadWithFilters}
                            {...{
                                'qa-id': 'budgetPlanningDownloadXLSXButton',
                            }}
                        >
                            С учетом фильтров
                        </div>

                        <div
                            className={style.xlsxImportExportMenuButton}
                            onClick={downloadWithoutFilters}
                            {...{
                                'qa-id': 'budgetPlanningDownloadXLSXTemplateButton',
                            }}
                        >
                            Без учета фильтров
                        </div>

                        {userIsBudgetExpert && (
                            <React.Fragment>
                                <div className={style.xlsxImportExportMenuSeparator} />

                                <div
                                    className={style.xlsxImportExportMenuButton}
                                    onClick={onDownloadFMPTableButtonClickHandler}
                                    {...{
                                        'qa-id': 'budgetPlanningDownloadXLSXTemplateButton',
                                    }}
                                >
                                    Загрузить таблицу ФМП
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </WithGlobalPopup>
            )}
        </React.Fragment>
    );
}

interface RightColumnProps {
    onSendButtonClick: () => void;
    sendButtonIsDisabled: boolean;
}

function RightColumn({ onSendButtonClick, sendButtonIsDisabled }: RightColumnProps): JSX.Element {
    const userCanEditOrCreateBudgetData = useSelector(userCanEditOrCreateBudgetData_);

    return (
        <div className={classNames(style.topLineGroup, style.rightColumn)}>
            {userCanEditOrCreateBudgetData && (
                <div className={style.sendButton}>
                    <Button
                        theme={ButtonTheme.GhostRounded}
                        onClick={onSendButtonClick}
                        disabled={sendButtonIsDisabled}
                        qaId="budgetExecutionSendButton"
                    >
                        Отправить на согласование
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ApplyFiltersButtonProps {
    onClick: () => void;
}

function ApplyFiltersButton({ onClick }: ApplyFiltersButtonProps): JSX.Element {
    const showTagsHaveChangedMarker = useSelector(
        (state: StoreState) => getBudgetExecutionPageState(state).showTagsHaveChangedMarker,
    );
    const shouldRender = useSelector((state: StoreState) => {
        const {
            columnFilters: { filters },
            previouslyLoadedFilters,
        } = getBudgetExecutionPageState(state);

        return showTagsHaveChangedMarker || !isEqual(previouslyLoadedFilters, filters);
    });

    const title = showTagsHaveChangedMarker ? 'Изменены теги. Нажмите, чтобы обновить таблицу' : 'Применить фильтры';

    return shouldRender ? (
        <WithTooltip spanWrapper content={title}>
            <div
                className={classNames(
                    style.applyFiltersButton,
                    showTagsHaveChangedMarker && style.applyFiltersButtonNeedUpdateMarker,
                )}
                onClick={onClick}
            >
                <Icon type={IconType.PLAY} />
            </div>
        </WithTooltip>
    ) : null;
}

function TablePreloader(): JSX.Element {
    const preloader = useSelector((state: StoreState) => getBudgetExecutionPageState(state).preloader);

    return preloader ? <Preloader /> : null;
}

function TableLineModal(): JSX.Element {
    const preloader = useSelector((state: StoreState) => getBudgetExecutionPageState(state).preloader);
    const isLineModalVisible = useSelector((state: StoreState) => getLineModalState(state).show);

    return isLineModalVisible && !preloader ? <LineModal /> : null;
}
