import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as style from './MediaplanSettingsStageActions.scss';

import { AutopilotStage } from '@store/autopilotRadio/types';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';
import { Loader, Saver } from '../../modules';
import { setPreloaderStatus, setCurrentStage } from '@store/autopilotRadio/actions';

interface Props {
    canEdit: boolean;
}

export const MediaplanSettingsStageActions = ({ canEdit }: Props): JSX.Element => {
    const saver = Saver.getInstance();
    const loader = Loader.getInstance();
    const dispatch = useDispatch();

    const onButtonClick = React.useCallback(async () => {
        dispatch(setPreloaderStatus(true));

        await saver.saveBriefForm();
        await saver.createMediaplan();

        await Promise.all([loader.loadAutopilot(), loader.loadMediaplan()]);

        dispatch(setPreloaderStatus(false));
        dispatch(setCurrentStage(AutopilotStage.Mediaplan));
    }, []);

    return (
        <div className={style.root}>
            <div className={style.title}>Настройте параметры для расчёта медиаплана</div>

            <div className={style.button}>
                <Button theme={ButtonTheme.MainRounded} disabled={!canEdit} onClick={onButtonClick}>
                    Рассчитать медиаплан
                </Button>
            </div>
        </div>
    );
};
