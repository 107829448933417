import * as React from 'react';

import type { UserCard } from 'sber-marketing-types/frontend';

import { UseWorkTypeOptions } from '@modules/workType/hooks';
import { useUserVacation } from '@modules/user/hooks';

import { DropdownPortalOption, DropdownPortalOptionProps, Option } from '@common/components';
import { WorkTypeOptions } from '@modules/workType/components';

export interface WorkTypeUserOptionProps
    extends DropdownPortalOptionProps<string>,
        Omit<UseWorkTypeOptions, 'departmentId'> {
    portal: React.MutableRefObject<HTMLElement>;
    user: UserCard;
    isLoading?: boolean;
}

export function WorkTypeUserOption({
    value,
    onSelect,
    portal,
    user,
    isLoading = !user,
    ids,
    sortingOrder,
    embed,
    organizationId,
    isArchived,
    enable_rates,
    userId,
    useUsers,
    title,
    titleContent,
    ...props
}: WorkTypeUserOptionProps) {
    const { isLoading: isVacationLoading, isVacation, description } = useUserVacation(user.id);

    const params: Required<UseWorkTypeOptions> = {
        ids,
        sortingOrder,
        embed,
        organizationId,
        isArchived,
        enable_rates,
        departmentIds: [user?.departmentId],
        userId,
        useUsers,
    };

    const normTitleContent = titleContent ?? (
        <span title={description}>
            {isVacation && '🌴 '}
            {title}
        </span>
    );

    return isLoading || isVacationLoading ? (
        <Option value={value} {...props} />
    ) : user.workTypeIds.length < 2 ? (
        <Option
            value={value}
            {...props}
            title={title}
            titleContent={normTitleContent}
            onSelect={() => onSelect(user.workTypeIds[0])}
        />
    ) : (
        <DropdownPortalOption
            {...props}
            title={title}
            titleContent={normTitleContent}
            value={user}
            params={{ options: { trigger: 'hover', placement: 'leftTop', stretch: true } }}
            portal={portal}
        >
            <WorkTypeOptions {...params} selected={value} onSelect={onSelect} />
        </DropdownPortalOption>
    );
}
