import * as React from 'react';

import type { FieldValueBoolean } from '@store/autopilot/creativeForm';
import type { CommonProps } from '../types';
import { Toggle, TogglePosition, ToggleThemes } from 'sber-marketing-ui';
import { Header } from '../common';

import * as style from './BooleanField.scss';

interface Props extends CommonProps<FieldValueBoolean> {}

export const BooleanField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }): JSX.Element => {
    const onClick = React.useCallback(() => {
        outsideChangeHandler({
            fieldId: field.id,
            fieldValue: !field.value.current,
            fieldError: '',
        });
    }, [field, outsideChangeHandler]);

    return (
        <div className={style.root}>
            <Header field={field} />

            <div className={style.input}>
                <Toggle
                    position={field.value.current ? TogglePosition.RIGHT : TogglePosition.LEFT}
                    theme={ToggleThemes.GREY_AND_GREEN}
                    onClick={onClick}
                />
            </div>
        </div>
    );
};
