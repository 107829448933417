import * as React from 'react';

import { Button_redesign as Button, ButtonTheme_redesign as ButtonTheme } from 'sber-marketing-ui';

interface Props {
    disabled: boolean;
    onClick: () => void;
}

export const SaveButton: React.FC<Props> = ({ disabled, onClick }) => {
    return (
        <Button
            disabled={disabled}
            theme={ButtonTheme.MainRounded}
            style={{
                paddingLeft: 20,
                paddingRight: 20,
                height: 32,
                width: 'max-content',
            }}
            onClick={onClick}
        >
            Сохранить
        </Button>
    );
};
