import * as React from 'react';
import * as lodash from 'lodash';

import * as style from './SourceIdTable.scss';

import type { ClientData } from '../hooks';

import { Icon, IconType } from 'sber-marketing-ui';
import { AddSourseButton } from './AddSourseButton';
import { Table } from './Table';

interface Props {
    capex?: boolean;
    clientData: ClientData;
    userRoles: number[];
}

export const SourceIdTable: React.FC<Props> = ({ capex, clientData, userRoles }) => {
    const {
        creativeRequest,
        creativeRequestItems,
        creativeRequestBudgetItems,
        groupedCreativeRequestBudgetItems,
        projectBudgetItems,
        groupedProjectBudgetItems,
        project,
        contracts,
        grouppedContracts,
    } = clientData;

    const budgetItems = groupedCreativeRequestBudgetItems[capex ? 'capex' : 'opex'];

    const userHasRights = [29, 31, 32].some((roleId) => userRoles.includes(roleId));

    const sourseLines = groupedCreativeRequestBudgetItems;
    const executionLines = groupedProjectBudgetItems;

    const sourseOpexIsEmpty = lodash.isEmpty(sourseLines['opex']);
    const sourseCapexIsEmpty = lodash.isEmpty(sourseLines['capex']);
    const executionOpexIsEmpty = lodash.isEmpty(executionLines['opex']);
    const executionCapexIsEmpty = lodash.isEmpty(executionLines['capex']);

    const sourseIsEmpty = lodash.isEmpty(creativeRequestBudgetItems);
    const executionIsEmpty = lodash.isEmpty(projectBudgetItems);

    const displayTable =
        userHasRights &&
        (capex
            ? !sourseCapexIsEmpty || !executionCapexIsEmpty
            : !sourseOpexIsEmpty || !executionOpexIsEmpty || (sourseIsEmpty && executionIsEmpty));

    if (!displayTable) {
        return null;
    }

    return (
        <div className={style.root}>
            <div className={style.topLine}>
                <div className={style.title}>ID источника</div>

                {capex && <div className={style.capexIndicator}>CAPEX</div>}

                {!lodash.isEmpty(budgetItems) && (
                    <div className={style.addIdButton}>
                        <AddSourseButton
                            title={
                                <>
                                    <Icon className={style.addIdButtonIcon} type={IconType.PLUS_SIGN} svgSize={10} />
                                    Добавить ID
                                </>
                            }
                        />
                    </div>
                )}
            </div>

            <Table
                capex={capex}
                creativeRequest={creativeRequest}
                creativeRequestItems={creativeRequestItems}
                budgetItems={budgetItems}
                project={project}
                contracts={contracts}
                grouppedContracts={grouppedContracts}
            />
        </div>
    );
};
