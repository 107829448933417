// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-task-components-details-TaskBudgetDetails-_TaskBudgetDetails__root{font-size:14px}.src-client-modules-task-components-details-TaskBudgetDetails-_TaskBudgetDetails__label{font-size:12px;font-weight:600;color:var(--color-gray-100)}\n", "",{"version":3,"sources":["webpack://./src/client/modules/task/components/details/TaskBudgetDetails/TaskBudgetDetails.scss"],"names":[],"mappings":"AAAA,uFACE,cAAe,CAChB,wFAGC,cAAe,CACf,eAAgB,CAChB,2BAA4B","sourcesContent":[".root {\n  font-size: 14px;\n}\n\n.label {\n  font-size: 12px;\n  font-weight: 600;\n  color: var(--color-gray-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-task-components-details-TaskBudgetDetails-_TaskBudgetDetails__root",
	"label": "src-client-modules-task-components-details-TaskBudgetDetails-_TaskBudgetDetails__label"
};
export default ___CSS_LOADER_EXPORT___;
