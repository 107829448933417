import * as React from 'react';

import { Button, Card, Text } from '@sbermarketing/mrm-ui';

import * as styles from './TaskNotFound.scss';

export function TaskNotFound() {
    return (
        <div className={styles.root}>
            <Card gap={16} vertical padding={16}>
                <Text size={16} weight="bold">
                    Задача недоступна
                </Text>
                <Text size={14}>Задача была удалена или у вас больше нет к ней доступа</Text>
                <Button view="accent" element="a" to="/">
                    На главную
                </Button>
            </Card>
        </div>
    );
}
