import * as React from 'react';

import { IconType } from 'sber-marketing-ui';
import { TaskStatus } from 'sber-marketing-types/frontend';

import { useAddTaskParticipantsMutation, useGetTaskParticipantsQuery, useGetTaskQuery } from '@api';

import { Button, Buttons, Error, Flex } from '@common/components';
import { UserSelector, UserSelectorProps } from '@modules/user/components';
import { useDefaultRef } from '@common/hooks';
import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';

export interface AddTaskParticipantProps extends UserSelectorProps<true> {
    taskId: string;
    activityId?: number;
    isDropdownShow?: boolean;
    onShowChangeDropdown?: (show: boolean) => void;
}

export function AddTaskParticipant({
    taskId,
    activityId,
    children,
    dropdownRef,
    onClick,
    ...props
}: AddTaskParticipantProps) {
    const dropdown = useDefaultRef(dropdownRef);
    const { data: { task } = {}, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const [participants, setParticipants] = React.useState<number[]>([]);
    const [search, setSearch] = React.useState<string>('');
    const [addTaskParticipants, { isLoading: isAdding }] = useAddTaskParticipantsMutation();
    const { data: taskParticipants = [] } = useGetTaskParticipantsQuery({ taskId });
    const [error, setError] = React.useState('');
    const {
        isLoading,
        access: [canAdd],
    } = useTaskAccess([TaskAccessAction.addParticipant], taskId, activityId);

    if (isTaskLoading || task.status === TaskStatus.Draft || !canAdd || isLoading) {
        return null;
    }

    const taskParticipantIds = taskParticipants.map(({ id }) => id);

    const handleSelect = (userIds: number[]) => {
        setParticipants(userIds);
        setError('');
    };

    const handleClear = () => {
        setSearch('');
        setError('');
    };

    const handleCancel = () => {
        setParticipants([]);
        handleClear();
        dropdown.current.close();
    };

    const handleAdd = async () => {
        if (!participants.length) {
            setError('Выберите участника из списка');
            return;
        }

        await addTaskParticipants({ taskId, userIds: participants });
        handleCancel();
    };

    return (
        <UserSelector
            iconBefore={IconType.CREATIVE_ADD_USER_BUTTON}
            placeholder="Добавить участников"
            data-qa-id="AddTaskParticipant"
            active
            {...props}
            dropdownRef={dropdown}
            multiple
            selected={participants}
            onSelect={handleSelect}
            exact
            searchable
            search={search}
            onSearch={setSearch}
            ghost
            embed={['vacation']}
            exclude={taskParticipantIds}
            onShowChangeDropdown={(show) => !show && handleClear()}
        >
            <Buttons>
                <Button data-qa-id="AddTaskParticipant__cancel" loading={isAdding} flex onClick={handleCancel}>
                    Отменить
                </Button>
                <Button data-qa-id="AddTaskParticipant__add" loading={isAdding} flex view="primary" onClick={handleAdd}>
                    Добавить
                </Button>
            </Buttons>
            {error && (
                <Flex justify="flex-end">
                    <Error>{error}</Error>
                </Flex>
            )}
        </UserSelector>
    );
}
