import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 } from 'uuid';
import * as moment from 'moment';
import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { ActivityParams } from 'sber-marketing-types/frontend';
import { SelectItem } from 'sber-marketing-ui';

import { HeaderView } from '@common/Page';

import { StoreState } from '@store';
import { User, getLoginUser } from '@store/user';
import { PageOptions, updatePageOptions } from '@store/common';
import {
    InstanceActionPayload,
    TagsEditorInstanceDescriptor,
    initInstance as initTagsEditorInstance,
    dropInstance as dropTagsEditorInstance,
} from '@store/tagsEditor';
import {
    PageValues,
    ValidationMode,
    SaveActivityPayload,
    getNewActivityPageData,
    setActivityPrivacy,
    setValidationMode,
    setValues,
    loadPageData,
    resetPageState,
    saveActivity,
} from '@store/newActivity';

import { EditOrCreateProjectHeader, EditOrCreateProjectPage } from './EditOrCreateProjectPage';

interface Props extends Partial<MapProps & DispatchProps>, RouteComponentProps<RouteProps> {
    setHeaderView?: (view: HeaderView) => void;
}

interface RouteProps {
    activityId: string;
}

interface MapProps {
    activity: ActivityParams;
    user: User;
    preload: boolean;
    blocks: SelectItem[];
    productsByBlock: {
        [key: string]: SelectItem[];
    };
    calendarGroupsByBlock: {
        [key: string]: SelectItem[];
    };
    activityTypes: SelectItem[];
    responsibles: SelectItem[];
    validationMode: ValidationMode;
    isPrivate: boolean;
    values: PageValues;
    bpmWasInit: boolean;
}

interface DispatchProps {
    loadPageData: (activityId: number) => Promise<void>;
    resetPageState: () => void;
    saveActivity: (payload: SaveActivityPayload) => Promise<number>;
    updatePageOptions: (data: PageOptions) => void;
    setActivityPrivacy: (isPrivate: boolean) => void;
    setValidationMode: (validationMode: ValidationMode) => void;
    setValues: (values: Partial<PageValues>) => void;
    initTagsEditorInstance: (payload: InstanceActionPayload<TagsEditorInstanceDescriptor>) => void;
    dropTagsEditorInstance: (payload: string) => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditOrCreateProjectPageContainer extends React.Component<Props> {
    private activityId: number;
    private tagsEditorId: string = v4();

    public constructor(props: Props) {
        super(props);

        this.activityId = Number(this.props.match.params.activityId) || null;
    }

    public componentDidMount() {
        this.renderHeader();
        this.props.loadPageData(this.activityId);
        this.props.initTagsEditorInstance({
            id: this.tagsEditorId,
            payload: { activityId: this.activityId },
        });
    }

    public componentDidUpdate(prevProps: Props) {
        const hasAccessToPage = this.hasAccessToPage();

        if (!lodash.isNull(hasAccessToPage) && !hasAccessToPage) {
            this.redirectUserToPrevPage();
        }

        if (this.props.preload !== prevProps.preload) {
            this.renderHeader();
        }
    }

    public componentWillUnmount() {
        this.props.resetPageState();
        this.props.dropTagsEditorInstance(this.tagsEditorId);
    }

    public render(): JSX.Element {
        const {
            preload,
            blocks,
            productsByBlock,
            calendarGroupsByBlock,
            activityTypes,
            responsibles,
            validationMode,
            isPrivate,
            values,
            bpmWasInit,
        } = this.props;

        return (
            <EditOrCreateProjectPage
                activityId={this.activityId}
                preload={preload}
                validationMode={validationMode}
                values={values}
                isPrivate={isPrivate}
                blocks={blocks}
                productsByBlock={productsByBlock}
                calendarGroupsByBlock={calendarGroupsByBlock}
                activityTypes={activityTypes}
                responsibles={responsibles}
                bpmWasInit={bpmWasInit}
                tagsEditorId={this.tagsEditorId}
                onProductSelect={this.onProductSelect}
                onCalendarGroupSelect={this.onCalendarGroupSelect}
                onActivityTypeSelect={this.onActivityTypeSelect}
                onResponsibleSelect={this.onResponsibleSelect}
                onTitleChange={this.onTitleChange}
                onDescriptionChange={this.onDescriptionChange}
                onBlockSelection={this.onDivisionSelect}
                onPreparationDateChange={this.onPreparationDateChange}
                onRealizationStartDateChange={this.onRealizationStartDateChange}
                onRealizationEndDateChange={this.onRealizationEndDateChange}
                onDebriefingDateChange={this.onDebriefingDateChange}
                onAutoCreateTasksToggle={this.onAutoCreateTasksToggle}
            />
        );
    }

    @autobind
    private onTitleChange(name: string) {
        this.props.setValues({ name });
    }

    @autobind
    private onDescriptionChange(description: string) {
        this.props.setValues({ description });
    }

    @autobind
    private onDivisionSelect(divisionId: string) {
        this.props.setValues({
            divisionId,
            productId: null,
            calendarGroupId: null,
        });
    }

    @autobind
    private onRealizationStartDateChange(realizationStart: moment.Moment) {
        this.props.setValues(this.validateDates({ realizationStart }));
    }

    @autobind
    private onRealizationEndDateChange(realizationEnd: moment.Moment) {
        this.props.setValues(this.validateDates({ realizationEnd }));
    }

    @autobind
    private onPreparationDateChange(preparationDate: moment.Moment) {
        this.props.setValues(this.validateDates({ preparationDate }));
    }

    @autobind
    private onDebriefingDateChange(debriefingDate: moment.Moment) {
        this.props.setValues(this.validateDates({ debriefingDate }));
    }

    @autobind
    private onActivityTypeSelect(activityTypeId: string) {
        this.props.setValues({ activityTypeId });
    }

    @autobind
    private onProductSelect(productId: string) {
        this.props.setValues({ productId });
    }

    @autobind
    private onCalendarGroupSelect(calendarGroupId: string) {
        this.props.setValues({ calendarGroupId });
    }

    @autobind
    private onResponsibleSelect(responsibleId: number) {
        this.props.setValues({ responsibleId });
    }

    @autobind
    private onAutoCreateTasksToggle(): void {
        this.props.setValues({
            autoCreateTasks: !this.props.values.autoCreateTasks,
        });
    }

    @autobind
    private onStartActivityButtonClick() {
        if (this.checkValidation()) {
            this.saveActivity();
        } else {
            this.props.setValidationMode(ValidationMode.All);
        }
    }

    private async saveActivity() {
        let activityId = Number(this.props.match.params.activityId) || null;

        activityId = await this.props.saveActivity({
            activityId,
            isDraft: false,
            tagsEditorId: this.tagsEditorId,
        });

        if (this.needLinkActivityWithBudgetItem) {
            this.goToBudgetExecutionPage(activityId);
        } else {
            this.goToProjectPage(activityId, this.props.values.autoCreateTasks);
        }
    }

    private checkValidation(): boolean {
        const {
            isPrivate,
            values: { autoCreateTasks, bpmWasInit, ...values },
        } = this.props;

        return isPrivate ? !!values.name : lodash.every(values, (value) => !!value);
    }

    private get needLinkActivityWithBudgetItem(): boolean {
        return Boolean(this.budgetItemIdToLink);
    }

    private get budgetItemIdToLink(): string | undefined {
        return lodash.first(new URLSearchParams(this.props.location.search).getAll('budgetItemIdToLink'));
    }

    private goToProjectPage(activityId: number, addDelay: boolean) {
        this.props.history.push(`/activity/${activityId}/tasks/${addDelay ? '?delayLoading=true' : ''}`);
    }

    private goToBudgetExecutionPage(activityId: number): void {
        this.props.history.push(
            `/budget/execution?activityIdToLink=${activityId}&budgetItemIdToLink=${this.budgetItemIdToLink}`,
        );
    }

    private hasAccessToPage(): boolean {
        const { activity, user } = this.props;
        const canUserEditActivity = activity && activity.canEdit;
        const organizationIdOfActivity = activity && activity.organizationId;
        const organizationIdOfUser = user && user.attributes && user.attributes.organizationId;

        const haveAllForCheckAccess =
            !lodash.isNil(canUserEditActivity) && organizationIdOfActivity && organizationIdOfUser;

        return haveAllForCheckAccess ? canUserEditActivity && organizationIdOfActivity === organizationIdOfUser : null;
    }

    private redirectUserToPrevPage(): void {
        this.props.history.replace('/');
    }

    private renderHeader() {
        const {
            location,
            match: {
                params: { activityId },
            },
            preload,
        } = this.props;

        if (activityId) {
            this.props.updatePageOptions({
                previousLabel: ' ',
                previousUrl: `/activity/${activityId}`,
                withoutFooter: true,
            });
        }

        this.props.setHeaderView({
            firstLine: (
                <EditOrCreateProjectHeader
                    activityIsNew={!activityId}
                    location={location.pathname}
                    preload={preload}
                    onStartActivityButtonClick={this.onStartActivityButtonClick}
                />
            ),
        });
    }

    private validateDates(updatedState: Partial<PageValues>): Partial<PageValues> {
        const mergedState = {
            ...this.props.values,
            ...updatedState,
        };
        const changedDate = updatedState[Object.keys(updatedState)[0]] || null;

        const {
            preparationDate = null,
            realizationStart = null,
            realizationEnd = null,
            debriefingDate = null,
        } = mergedState;
        const dates = [preparationDate, realizationStart, realizationEnd, debriefingDate];
        const nChangedDate = dates.indexOf(changedDate) || 0;

        let sortedDates: moment.Moment[];

        // skip check if user deleted date
        if (dates[nChangedDate]) {
            sortedDates = [];

            for (let i = 0; i !== dates.length; i++) {
                if (i < nChangedDate && dates[i] > dates[nChangedDate]) {
                    sortedDates[i] = null;
                } else if (i > nChangedDate && dates[i] < dates[nChangedDate]) {
                    sortedDates[i] = null;
                } else {
                    sortedDates[i] = dates[i];
                }
            }
        } else {
            sortedDates = [...dates];
            sortedDates[nChangedDate] = null;
        }

        return {
            preparationDate: sortedDates[0],
            realizationStart: sortedDates[1],
            realizationEnd: sortedDates[2],
            debriefingDate: sortedDates[3],
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const {
        activity,
        activityTypes,
        productsByBlock,
        calendarGroupsByBlock,
        blocks,
        responsibles,
        isPrivate,
        validationMode,
        values,
        bpmWasInit,
    } = getNewActivityPageData(state);
    const user = getLoginUser(state);

    return {
        activity,
        user,
        preload: state.common.isRequestInProgress,
        activityTypes,
        productsByBlock,
        calendarGroupsByBlock,
        blocks,
        responsibles,
        isPrivate,
        validationMode,
        values,
        bpmWasInit,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return bindActionCreators(
        {
            loadPageData,
            resetPageState,
            saveActivity,
            updatePageOptions,
            setActivityPrivacy,
            setValidationMode,
            setValues,
            initTagsEditorInstance,
            dropTagsEditorInstance,
        },
        dispatch,
    );
}
