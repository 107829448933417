// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__root{font-family:'Open Sans', sans-serif;background:none;padding:0;border:none;line-height:inherit;float:left;display:flex;-webkit-font-smoothing:inherit;-moz-osx-font-smoothing:inherit;-webkit-appearance:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/components/ActivityFavorite/ActivityFavorite.scss"],"names":[],"mappings":"AAAA,iFACE,mCAAoC,CACpC,eAAgB,CAChB,SAAU,CACV,WAAY,CACZ,mBAAoB,CACpB,UAAW,CACX,YAAa,CAGb,8BAA+B,CAC/B,+BAAgC,CAGhC,uBAAwB","sourcesContent":[".root {\n  font-family: 'Open Sans', sans-serif;\n  background: none;\n  padding: 0;\n  border: none;\n  line-height: inherit;\n  float: left;\n  display: flex;\n\n  /* Corrects font smoothing for webkit */\n  -webkit-font-smoothing: inherit;\n  -moz-osx-font-smoothing: inherit;\n\n  /* Corrects inability to style clickable `input` types in iOS */\n  -webkit-appearance: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-activity-components-ActivityFavorite-_ActivityFavorite__root"
};
export default ___CSS_LOADER_EXPORT___;
