import { BudgetItem } from '@mrm/budget';
import { useEffect, useState } from 'react';

interface Props {
    sourceItem: BudgetItem;
    sourceBudgetItems: BudgetItem[];
}

export type EditMothItem = (params: { id: string; month: string; value: number }) => void;

export interface MonthItemType {
    id: string;
    month: string;
    monthName: string;
    planned: number;
    reserved: number;
    value: number;
}

interface Result {
    allMonthItemList: MonthItemType[];
    sourceMonthItemList: MonthItemType[];
    editMothItem: EditMothItem;
}

export enum Months {
    apr = 'Апрель',
    aug = 'Август',
    dec = 'Декабрь',
    feb = 'Февраль',
    jan = 'Январь',
    jul = 'Июль',
    jun = 'Июнь',
    mar = 'Март',
    may = 'Май',
    nov = 'Ноябрь',
    oct = 'Октябрь',
    sept = 'Сентябрь',
}

export const useMonthItem = ({ sourceItem, sourceBudgetItems }: Props): Result => {
    const [allMonthItemList, setAllMonthItemList] = useState<MonthItemType[] | null>(null);
    const [sourceMonthItemList, setSourceMonthItemList] = useState<MonthItemType[]>(null);

    const editMothItem: EditMothItem = ({ id, month, value }) => {
        const index = allMonthItemList.findIndex((item) => item.id === id && item.month === month);
        const arr = [...allMonthItemList];
        const item = { ...allMonthItemList[index], value };
        arr[index] = item;
        setAllMonthItemList(arr);
    };

    useEffect(() => {
        const _allMonthItemList: MonthItemType[][] = [];

        sourceBudgetItems?.forEach((budgetItem) => {
            const mounths: MonthItemType[] = Object.entries(budgetItem.plannedFunds).map(([monthKey, monthValue]) => ({
                id: budgetItem.id,
                month: monthKey,
                monthName: Months[monthKey],
                planned: monthValue,
                reserved: budgetItem.reservedFunds[monthKey],
                value: 0,
            }));
            _allMonthItemList.push(mounths.filter((item) => item.planned - item.reserved > 0));
        });

        setAllMonthItemList(_allMonthItemList.flat());
    }, [sourceBudgetItems]);

    useEffect(() => {
        const _sourceMonthItemList: MonthItemType[] = allMonthItemList?.filter((item) => item.id === sourceItem.id);
        setSourceMonthItemList(_sourceMonthItemList);
    }, [sourceItem, allMonthItemList]);

    return {
        allMonthItemList,
        sourceMonthItemList,
        editMothItem,
    };
};
