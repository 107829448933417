import * as React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import * as styles from './styles.scss';

interface Props {
    value: string;
    placeholder?: string;
    maxLength?: number;
    autoFocus?: boolean;
    onChange: (value: Value) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

type Value = string;

export const Textarea: React.FC<Props> = (props) => {
    const valueRef = React.useRef<HTMLDivElement>(null);
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    const [isModeEdit, setModeEdit] = React.useState<boolean>(props.autoFocus || false);

    React.useEffect(() => {
        if (valueRef.current && textareaRef.current) {
            textareaRef.current.style.height = `${valueRef.current.scrollHeight}px`;
        }
    }, [props.value]);

    React.useEffect(() => {
        if (isModeEdit) {
            if (valueRef.current && textareaRef.current) {
                textareaRef.current.style.height = `${valueRef.current.scrollHeight}px`;
            }
        }
    }, [isModeEdit]);

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
        (event) => {
            props.onChange(event.target.value.replace(/[\r\n]/gm, ''));
        },
        [props?.onChange],
    );

    const onValueClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        setModeEdit(true);
    }, []);

    const onTextareaBlur: React.FocusEventHandler<HTMLTextAreaElement> = React.useCallback(() => {
        setModeEdit(false);
        props?.onBlur();
    }, [props?.onBlur]);

    const onTextareaFocus: React.FocusEventHandler<HTMLTextAreaElement> = React.useCallback(
        (event) => {
            props?.onFocus();
            event.currentTarget.select();
        },
        [props?.onFocus],
    );

    const onTextareaKeyPress: React.KeyboardEventHandler<HTMLTextAreaElement> = React.useCallback(
        (event) => {
            if (event.key === 'Enter') {
                props?.onBlur();
                textareaRef?.current?.blur();
            }
        },
        [textareaRef.current, props?.onBlur],
    );

    return (
        <div className={styles.root}>
            <div
                className={classNames(
                    styles.common,
                    isModeEdit && styles.edit,
                    isEmpty(props.value) && styles.placeholder,
                )}
                ref={valueRef}
                onClick={onValueClick}
            >
                {props.value || props.placeholder}
            </div>

            {isModeEdit && (
                <textarea
                    autoFocus
                    className={classNames(styles.textarea, styles.common)}
                    maxLength={props.maxLength}
                    ref={textareaRef}
                    value={props.value}
                    onChange={onChange}
                    onFocus={onTextareaFocus}
                    onBlur={onTextareaBlur}
                    onKeyPress={onTextareaKeyPress}
                />
            )}
        </div>
    );
};
