import * as React from 'react';
import { MrmClient, CreativeRequest, UserConfigApi } from '@api';
import { useParams } from 'react-router-dom';
import { useCreativeRequestItems } from '../../ExecitionIdWidget/hooks';

interface AmountsValue {
    totalWithoutVat: number;
    amountVat: number;
    totalWithVat: number;
}

interface UseGetAmounts {
    amount: number;
    title: string;
}

export const useGetAmounts = (): UseGetAmounts[] => {
    const [creativeRequest, setCreativeRequest] = React.useState<CreativeRequest>(null);
    const { creativeRequestId } = useParams();

    const [amounts, setAmounts] = React.useState<AmountsValue>({ totalWithoutVat: 0, amountVat: 0, totalWithVat: 0 });

    React.useEffect(() => {
        const detData = async () => {
            const client = await MrmClient.getInstance();
            const creativeRequest = await client.domain.creativeRequests.getCreativeRequest({ id: creativeRequestId });
            setCreativeRequest(creativeRequest);
        };
        detData();
    }, [creativeRequestId]);

    const { creativeRequestItems } = useCreativeRequestItems(creativeRequest);

    const onCreativeRequestItemsUpdated = () => {
        const amounts = { totalWithoutVat: 0, amountVat: 0, totalWithVat: 0 };
        creativeRequestItems.forEach((item) => {
            amounts.totalWithoutVat +=
                (item.model?.actualCostWithoutVat || 0) + (item.model?.commissionWithoutVat || 0);
            amounts.totalWithVat += (item.model?.actualCostWithVat || 0) + (item.model?.commissionWithVat || 0);
            amounts.amountVat +=
                (((item.model?.actualCostWithoutVat || 0) + (item.model?.commissionWithoutVat || 0)) *
                    (item.model?.vat || 0)) /
                100;
        });
        setAmounts(amounts);
    };

    React.useEffect(() => {
        onCreativeRequestItemsUpdated();

        creativeRequestItems.forEach((item) => {
            item.events.onUpdated(onCreativeRequestItemsUpdated);
        });

        return () => {
            creativeRequestItems.forEach((item) => {
                item.events.offUpdated(onCreativeRequestItemsUpdated);
            });
        };
    }, [creativeRequestItems]);

    return [
        {
            title: 'Итог без НДС',
            amount: amounts.totalWithoutVat,
        },
        {
            title: 'Сумма НДС',
            amount: amounts.amountVat,
        },
        {
            title: 'Итог с НДС',
            amount: amounts.totalWithVat,
        },
    ];
};
