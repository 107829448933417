import * as React from 'react';

import { buildLinkParamsFromTableData } from '../../../utils';
import * as styles from './styles.scss';

interface Props {
    data: string[][];
}

export const DownloadLink: React.FC<Props> = ({ data }) => {
    const linkRef = React.useRef<HTMLAnchorElement>(null);

    React.useEffect(() => {
        if (linkRef.current) {
            const { href, download } = buildLinkParamsFromTableData(data);

            linkRef.current.href = href;
            linkRef.current.download = download;
        }
    }, [linkRef, data]);

    return (
        <a ref={linkRef} className={styles.root}>
            Скачать
        </a>
    );
};
