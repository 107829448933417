import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import type { StoreState } from '@store';

import { AddSourseButtonTemplate } from './AddSourseButtonTemplate';
import { WithBudgets } from './WithBudgets';
import { getLoginUser } from '@store/user/selector';

interface Props {
    title: string | JSX.Element;
    onClick?: () => void;
}

export const AddSourseButtonConnector = ({ title, onClick }: Props): JSX.Element => {
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();
    const currentUser = useSelector((state: StoreState) => getLoginUser(state));

    const userOrganizationId = currentUser.attributes.organizationId;

    return (
        <WithBudgets organizationIds={[userOrganizationId]}>
            {({ budgets }) =>
                React.createElement(AddSourseButtonTemplate, {
                    title,
                    creativeRequestId,
                    budgets,
                    onClick,
                })
            }
        </WithBudgets>
    );
};
