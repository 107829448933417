import { BudgetItem } from '@mrm/budget';
import { MonthItemType } from './useMonthItem';
import { useEffect, useState } from 'react';
import { FundsFilds, getSummFromBudgetItem, getZeroIfMinus } from '../utils';

interface Props {
    currentExecutorItem: BudgetItem;
    currentSourseItem: BudgetItem;
    allMonthItemList: MonthItemType[];
    sourceMonthItemList: MonthItemType[];
}

interface Result {
    amountFrom: number;
    amountTo: number;
    willAmountSource: number;
    wilAmountExecutor: number;
}

export const useAmount = ({
    currentSourseItem,
    currentExecutorItem,
    allMonthItemList,
    sourceMonthItemList,
}: Props): Result => {
    const [amountFrom, setAmountFrom] = useState<number>(0);
    const [amountTo, setAmountTo] = useState<number>(0);
    const [willAmountSource, setWillAmountSource] = useState<number>(0);
    const [wilAmountExecutor, setWilAmountExecutor] = useState<number>(0);

    const getSumm = (field: 'value' | 'planned' | 'reserved', list: MonthItemType[]): number => {
        return list?.map((item) => item[field])?.reduce((accum, item) => accum + item, 0) || 0;
    };

    useEffect(() => {
        if (!sourceMonthItemList) return;
        const valueSumm = getSumm('value', sourceMonthItemList);

        const freeSumm =
            getSummFromBudgetItem(currentSourseItem, FundsFilds.plannedFunds) -
            getSummFromBudgetItem(currentSourseItem, FundsFilds.reservedFunds);

        setAmountFrom(valueSumm);
        setWillAmountSource(freeSumm - valueSumm);
    }, [sourceMonthItemList]);

    useEffect(() => {
        if (!allMonthItemList) return;
        const valueSumm = getSumm('value', allMonthItemList);

        const neededSumm =
            getSummFromBudgetItem(currentExecutorItem, FundsFilds.reservedFunds) -
            getSummFromBudgetItem(currentExecutorItem, FundsFilds.plannedFunds);

        setAmountTo(valueSumm);
        setWilAmountExecutor(-neededSumm + valueSumm);
    }, [allMonthItemList]);

    return {
        amountFrom,
        amountTo,
        willAmountSource,
        wilAmountExecutor,
    };
};
