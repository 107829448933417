import * as React from 'react';
import classnames from 'classnames';

import * as styles from './IdSelector.scss';

import type { BudgetItem } from '@mrm/budget';

import { CAPEX_ITEM_VALUE } from '../consts';

interface Props {
    title: string;
    IDsList?: BudgetItem[] | null;
    selectedItem: BudgetItem;
    setID?: (ID: BudgetItem) => void;
}

export const IdSelector = ({ title, IDsList, selectedItem, setID }: Props): JSX.Element => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>{title}</div>
            <div className={styles.itemsList}>
                {IDsList?.map((item) => (
                    <div
                        key={item.id}
                        className={classnames(styles.item, selectedItem.id === item.id && styles.selectedItem)}
                        onClick={() => setID(item)}
                    >
                        {item.serialNumber + (item.dictionary.item?.value === CAPEX_ITEM_VALUE ? ' CAPEX' : '')}
                    </div>
                ))}
            </div>
        </div>
    );
};
