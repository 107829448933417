import * as React from 'react';
import classNames from 'classnames';

import * as style from './RelatedCreative.scss';
import { TitleWithCountIndicator } from '../common';
import { useRelatedCreative } from './useRelatedCreative';
import { CreativeCardList } from './CreativeCardList/CreativeCardList';

interface Props {
    lineId: string;
}

export const RelatedCreative: React.FC<Props> = ({ lineId }) => {
    const activitiesCount = 6;

    const [hasOpenList, setHasOpenList] = React.useState<boolean>(true);

    const { data, isLoading } = useRelatedCreative({ budgetItemId: lineId });

    return (
        <div
            className={classNames(style.root, style.blockWrapper, style.bottomBorder)}
            {...{
                'qa-id': 'budgetExecutionLineModalRelatedActivities',
            }}
        >
            <div className={style.headerWrapper}>
                <TitleWithCountIndicator title={'Связанные заявки на креатив'} count={data?.length || 0} />
                {activitiesCount > 1 && (
                    <div className={style.headerOpenButton} onClick={() => setHasOpenList((value) => !value)}>
                        {hasOpenList ? 'Свернуть' : 'Развернуть'}
                    </div>
                )}
            </div>
            {(Boolean(data?.length) || isLoading) && hasOpenList && (
                <div className={style.activityCardListWrapper}>
                    <CreativeCardList data={data} isLoading={isLoading} />
                </div>
            )}
        </div>
    );
};
