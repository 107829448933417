import * as React from 'react';
import { Module } from 'sber-marketing-types/backend';

import { User, userHasPersonalDashboardAccess, userHasLeaderDashboardAccess } from '@store/user';

import { TaskPage } from '@modules/task/pages';

import { BriefPage } from '../../brief';
import { BudgetPage } from '../../budget/BudgetPage';
import { CalendarPage } from '../../calendar';
// import { ExecutionCalendarPage } from '../../executionCalendar';
import { EditExecutionBudgetPage } from '../../budget/EditExecutionBudgetPage';
import { EditPlannedBudgetPage } from '../../budget/EditPlannedBudgetPage';
import { DashboardPage } from '../../dashboard';
import { DashboardsPage } from '../../dashboards';
import { EditOrCreateProjectPage } from '../../new_activity';
import { ActivityPage } from '../../activity';
import { ReleaseNotesPage } from '../../release_notes';
import { TmRegistryPage } from '../../tmRegistry';
// import { TaskPage } from '../../activity/pages/task';
// import { ActivityTasksPage } from '../../activity/pages/activityTasks';
// import { AutopilotPage } from '../../activity/autopilot';
// import { CreativePage } from '../../activity/creative';
import { LeaderDashboard } from '../../leaderDashboard';
import { PivotTable } from '../../pivotTable';
import { TagsPage } from '@modules/tags/TagsPage';
import { OnboardingPage } from '@modules/onboarding/pages';

export interface Location {
    url: string;
    Component?: React.ComponentType<any>;
    hasAccess?: (user: User) => boolean;
}

function userHasModule(user: User, module: Module): boolean {
    return user?.attributes?.modules?.includes(module);
}

export const locations: Location[] = [
    {
        url: '/onboarding',
        Component: OnboardingPage,
    },
    {
        url: '/managerDashboard',
        Component: DashboardPage,
        hasAccess: (user) => userHasPersonalDashboardAccess(user),
    },
    {
        url: '/dashboard',
        Component: DashboardsPage,
        hasAccess: (user) => userHasModule(user, Module.Dashboard),
    },
    {
        url: '/leaderDashboard/:mode',
        Component: LeaderDashboard,
        hasAccess: (user) => userHasPersonalDashboardAccess(user) && userHasLeaderDashboardAccess(user),
    },
    {
        url: '/pivotTable',
        Component: PivotTable,
    },
    {
        url: '/pivotTable/:lot',
        Component: PivotTable,
    },
    {
        url: '/activity/new',
        Component: EditOrCreateProjectPage,
    },
    {
        url: '/activity/:activityId/edit',
        Component: EditOrCreateProjectPage,
    },
    {
        url: '/activity/:activityId',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/brief',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/tasks',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/task/:taskId',
        Component: TaskPage,
    },
    {
        url: '/activity/:activityId/task/:taskId/brief',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/autopilotDOOH',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/autopilotRadio',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/autopilotTv',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/autopilot',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/autopilot_v2',
        Component: ActivityPage,
    },
    {
        url: '/activity/:activityId/creative/:creativeRequestId',
        Component: ActivityPage,
    },
    // {
    //     url: '/activity/:activityId/brief',
    //     Component: BriefPage
    // },
    // {
    //     url: '/activity/:activityId/tasks',
    //     Component: ActivityTasksPage
    // },
    // {
    //     url: '/activity/:activityId/task/:taskId',
    //     Component: TaskPage
    // },
    // {
    //     url: '/activity/:activityId/task/:taskId/brief',
    //     Component: BriefPage
    // },
    // {
    //     url: '/activity/:activityId/creative/:creativeRequestId',
    //     Component: CreativePage
    // },
    // {
    //     url: '/activity/:activityId/autopilot',
    //     Component: AutopilotPage
    // },
    {
        url: '/budget/:pageName',
        Component: BudgetPage,
        hasAccess: (user) => userHasModule(user, Module.Budget),
    },
    {
        url: '/budget/execution/:activityBudgetId',
        Component: EditExecutionBudgetPage,
        hasAccess: (user) => userHasModule(user, Module.Budget),
    },
    {
        url: '/budget/execution/:activityBudgetId/:action(copy)',
        Component: EditExecutionBudgetPage,
        hasAccess: (user) => userHasModule(user, Module.Budget),
    },
    {
        url: '/budget/planning/:activityBudgetId',
        Component: EditPlannedBudgetPage,
        hasAccess: (user) => userHasModule(user, Module.Budget),
    },
    {
        url: '/budget/planning/:activityBudgetId/:action(copy)',
        Component: EditPlannedBudgetPage,
        hasAccess: (user) => userHasModule(user, Module.Budget),
    },
    {
        url: '/budget/planning/:activityBudgetId/:action(copyFromExecution)',
        Component: EditPlannedBudgetPage,
        hasAccess: (user) => userHasModule(user, Module.Budget),
    },
    {
        url: '/budget/planning/budgetItem/:budgetItemId/brief',
        Component: BriefPage,
    },
    {
        url: '/budget/planning/budgetItem/:budgetItemId/brief/:action(create)',
        Component: BriefPage,
    },
    {
        url: '/calendar/:pageName',
        Component: CalendarPage,
        hasAccess: (user) => userHasModule(user, Module.Calendar),
    },
    // {
    //     url: '/executionCalendar',
    //     Component: ExecutionCalendarPage
    // },
    {
        url: '/release-notes',
        Component: ReleaseNotesPage,
    },
    {
        url: '/tm/registry',
        Component: TmRegistryPage,
    },
    {
        url: '/tags',
        Component: TagsPage,
    },
];
