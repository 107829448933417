import { HandlerMap } from './types';

export const constraintHandlerMap: HandlerMap = {
    min: async (constraint, value) => {
        return Number(value) < constraint ? 'Недопустимо малоя величина' : null;
    },
    max: async (constraint, value) => {
        return Number(value) > constraint ? 'Недопустимо большоя велечина' : null;
    },
    charsMin: async (constraint, value) => {
        return value?.length < constraint ? 'Недопустимо малое количество символов' : null;
    },
    charsMax: async (constraint, value) => {
        return value?.length > constraint ? 'Недопустимо большое количество символов' : null;
    },
};
