// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__wrapper{display:flex;gap:3px}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__label{color:#7e8681}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__link{cursor:pointer}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__link a:active,.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__link a:hover,.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__link a{color:#417ec6;text-decoration:none}.src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__notLink{pointer-events:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/LineModal/Content/RelatedCreative/CreativeCardList/CreativeCard/RowWithLinks/RowWithLinks.scss"],"names":[],"mappings":"AAAA,0JACI,YAAa,CACb,OAAQ,CACX,wJAGG,aAAc,CACjB,uJAGG,cAAe,CADnB,wdAGQ,aAAc,CACd,oBAAqB,CACxB,0JAID,mBAAoB","sourcesContent":[".wrapper {\n    display: flex;\n    gap: 3px;\n}\n\n.label {\n    color: #7e8681;\n}\n\n.link {\n    cursor: pointer;\n    a:active, a:hover, a {\n        color: #417ec6;\n        text-decoration: none;  \n    }\n}\n\n.notLink {\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__wrapper",
	"label": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__label",
	"link": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__link",
	"notLink": "src-client-modules-budget-BudgetPage-BudgetExecution-LineModal-Content-RelatedCreative-CreativeCardList-CreativeCard-RowWithLinks-_RowWithLinks__notLink"
};
export default ___CSS_LOADER_EXPORT___;
