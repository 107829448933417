import * as React from 'react';
import { head } from 'lodash';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { HeadingNode, HeadingTagType, SerializedHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import {
    NodeKey,
    ParagraphNode,
    TextNode,
    SerializedTextNode,
    DOMConversion,
    DOMConversionMap,
    DOMConversionOutput,
    $isTextNode,
    $insertNodes,
    $getRoot,
    $setSelection,
    BLUR_COMMAND,
    FOCUS_COMMAND,
    COMMAND_PRIORITY_LOW,
    $createParagraphNode,
    $createTextNode,
} from 'lexical';

import { $createMentionNode } from '../MentionsPlugin';

interface Props {
    initialHtml?: string;
    onHtmlChanged: (html: string) => void;
    onBlur: () => void;
    onFocus: () => void;
    focusOnValueChange?: boolean;
}

export const HtmlPlugin = ({ initialHtml, onHtmlChanged, onFocus, onBlur }: Props) => {
    const [value, setValue] = React.useState(initialHtml);

    const [editor] = useLexicalComposerContext();

    const [isFirstRender, setIsFirstRender] = React.useState(true);

    const reset = () => {
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialHtml || '', 'text/html');

            const nodes = $generateNodesFromDOM(editor, dom);

            $setSelection(null);

            $getRoot()
                .clear()
                .append(...nodes);

            const html = $generateHtmlFromNodes(editor);

            onHtmlChanged(html);
            setValue(html);
        });
    };

    React.useEffect(() => {
        if (initialHtml === value) return;

        if (!initialHtml) {
            setValue('');

            editor.update(() => {
                onHtmlChanged('');
                $getRoot().clear().append($createParagraphNode());
                $setSelection(null);
            });
            return;
        }

        const mentionSearchRegex = /(\[@[^[\]#]+#\d+])$/g;
        const currentMentions = initialHtml.match(mentionSearchRegex);

        if (currentMentions && currentMentions.length) {
            editor.update(() => {
                currentMentions.forEach((mention) => {
                    const mentionName = head(mention.match(/[а-яА-ЯёЁ\w \-()\"\'\.]+(?=#)/g));
                    const mentionId = head(mention.match(/[0-9]+/g));

                    const node = $createParagraphNode();
                    node.append($createMentionNode({ name: mentionName, id: mentionId }));
                    node.append($createTextNode(' '));

                    $insertNodes([node]);
                });

                const html = $generateHtmlFromNodes(editor);

                onHtmlChanged(html);
                setValue(html);
            });
        } else {
            reset();
        }
    }, [initialHtml]);

    React.useEffect(() => {
        if (!initialHtml || !isFirstRender) return;

        setIsFirstRender(false);

        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialHtml || '', 'text/html');

            const nodes = $generateNodesFromDOM(editor, dom);
            $insertNodes(nodes);
            onHtmlChanged($generateHtmlFromNodes(editor));
        });
    }, []);

    React.useEffect(() => {
        editor.registerCommand(FOCUS_COMMAND, onFocus, COMMAND_PRIORITY_LOW);
        editor.registerCommand(BLUR_COMMAND, onBlur, COMMAND_PRIORITY_LOW);
    }, []);

    return (
        <OnChangePlugin
            onChange={(editorState) => {
                editorState.read(() => {
                    const nodes = Array.from(editorState._nodeMap);
                    const html = $generateHtmlFromNodes(editor);

                    let empty = true;
                    for (const [, node] of nodes) {
                        if (node instanceof ParagraphNode || $isTextNode(node) || $isHeadingNode(node)) {
                            const content = node.getTextContent().trim();

                            if (content) {
                                empty = false;
                                break;
                            }
                        }
                    }

                    setValue(empty ? '' : html);
                    onHtmlChanged(empty ? '' : html);
                });
            }}
        />
    );
};

function patchStyleExport(LexicalNode: any) {
    const originalExportDOM = LexicalNode.prototype.exportDOM;
    LexicalNode.prototype.exportDOM = function exportDOM(editor: any) {
        const result = originalExportDOM.apply(this, [editor]);
        const element = result.element;
        if (element) {
            const formatType = this.getFormatType();
            element.style.textAlign = formatType;

            const direction = this.getDirection();
            if (direction) {
                element.dir = direction;
            }
            const indent = this.getIndent();
            if (indent > 0) {
                element.style.textIndent = `${indent * 20}px`;
            }
        }
        return result;
    };
}
patchStyleExport(HeadingNode);

export class ExtendedHeadingNode extends HeadingNode {
    constructor(tag: HeadingTagType, key?: NodeKey) {
        super(tag, key);
    }

    static getType(): string {
        return 'extended-heading';
    }

    static clone(node: ExtendedHeadingNode): ExtendedHeadingNode {
        return new ExtendedHeadingNode(node.__tag, node.__key);
    }

    static importDOM(): DOMConversionMap | null {
        const importers = HeadingNode.importDOM();

        return {
            ...importers,
            h1: () => ({
                conversion: patchStyleConversion(importers?.h1),
                priority: 1,
            }),
            h2: () => ({
                conversion: patchStyleConversion(importers?.h2),
                priority: 1,
            }),
            h3: () => ({
                conversion: patchStyleConversion(importers?.h3),
                priority: 1,
            }),
        };
    }

    static importJSON(serializedNode: SerializedHeadingNode): HeadingNode {
        return HeadingNode.importJSON(serializedNode);
    }
}

export class ExtendedTextNode extends TextNode {
    constructor(text: string, key?: NodeKey) {
        super(text, key);
    }

    static getType(): string {
        return 'extended-text';
    }

    static clone(node: ExtendedTextNode): ExtendedTextNode {
        return new ExtendedTextNode(node.__text, node.__key);
    }

    static importDOM(): DOMConversionMap | null {
        const importers = TextNode.importDOM();

        return {
            ...importers,
            code: () => ({
                conversion: patchStyleConversion(importers?.code),
                priority: 1,
            }),
            em: () => ({
                conversion: patchStyleConversion(importers?.em),
                priority: 1,
            }),
            span: () => ({
                conversion: patchStyleConversion(importers?.span),
                priority: 1,
            }),
            strong: () => ({
                conversion: patchStyleConversion(importers?.strong),
                priority: 1,
            }),
            sub: () => ({
                conversion: patchStyleConversion(importers?.sub),
                priority: 1,
            }),
            sup: () => ({
                conversion: patchStyleConversion(importers?.sup),
                priority: 1,
            }),
        };
    }

    static importJSON(serializedNode: SerializedTextNode): TextNode {
        return TextNode.importJSON(serializedNode);
    }
}

function patchStyleConversion(
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
    return (node) => {
        const original = originalDOMConverter?.(node);
        if (!original) {
            return null;
        }
        const originalOutput = original.conversion(node);

        if (!originalOutput) {
            return originalOutput;
        }

        const backgroundColor = node.style.backgroundColor;
        const color = node.style.color;
        const fontFamily = node.style.fontFamily;
        const fontWeight = node.style.fontWeight;
        const fontSize = node.style.fontSize;
        const textDecoration = node.style.textDecoration;
        const textAlign = node.style.textAlign;

        return {
            ...originalOutput,
            forChild: (lexicalNode, parent) => {
                const originalForChild = originalOutput?.forChild ?? ((x) => x);
                let result = originalForChild(lexicalNode, parent);

                if ($isTextNode(result) || $isHeadingNode(result)) {
                    const style = [
                        backgroundColor ? `background-color: ${backgroundColor}` : null,
                        color ? `color: ${color}` : null,
                        fontFamily ? `font-family: ${fontFamily}` : null,
                        fontWeight ? `font-weight: ${fontWeight}` : null,
                        fontSize ? `font-size: ${fontSize}` : null,
                        textDecoration ? `text-decoration: ${textDecoration}` : null,
                        textAlign ? `text-align: ${textDecoration}` : null,
                    ]
                        .filter((value) => value != null)
                        .join('; ');

                    if (style.length) {
                        return textAlign ? result.setStyle(style).setFormat(textAlign) : result.setStyle(style);
                    }

                    if (textAlign) {
                        return result.setFormat(textAlign);
                    }
                }
                return result;
            },
        };
    };
}
