import * as React from 'react';
import classnames from 'classnames';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    REDO_COMMAND,
    UNDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    FORMAT_TEXT_COMMAND,
    FORMAT_ELEMENT_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    $getSelection,
    $isRangeSelection,
    $createParagraphNode,
    TextFormatType,
} from 'lexical';
import {
    $wrapNodes,
    $patchStyleText,
    $isParentElementRTL,
    $getSelectionStyleValueForProperty,
} from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
    INSERT_CHECK_LIST_COMMAND,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
    $isListNode,
    ListNode,
} from '@lexical/list';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from '@lexical/rich-text';

import DropDown, { DropDownItem, LexicalDropdownPlacement } from './ui/DropDown';
import DropdownColorPicker from './ui/DropdownColorPicker';

import * as style from './ToolbarPlugin.scss';

const LowPriority = 1;

const formats: TextFormatType[] = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'highlight',
    'code',
    'superscript',
    'subscript',
];

export function ToolbarPlugin({
    shortLexical,
    dropdownPlacement,
}: {
    shortLexical?: boolean;
    dropdownPlacement?: LexicalDropdownPlacement;
}) {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = React.useRef(null);
    const [blockType, setBlockType] = React.useState('paragraph');
    const [, setSelectedElementKey] = React.useState(null);
    const [canUndo, setCanUndo] = React.useState(false);
    const [canRedo, setCanRedo] = React.useState(false);
    const [fontColor, setFontColor] = React.useState<string>('#000');
    const [bgColor, setBgColor] = React.useState<string>('#fff');
    const [isBold, setIsBold] = React.useState(false);
    const [isItalic, setIsItalic] = React.useState(false);
    const [isUnderline, setIsUnderline] = React.useState(false);
    const [isStrikethrough, setIsStrikethrough] = React.useState(false);
    const [isSelected, setIsSelected] = React.useState(false);
    const [, setIsRTL] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(() => editor.isEditable());

    const updateToolbar = React.useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    setBlockType(type);
                }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setIsRTL($isParentElementRTL(selection));

            setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));

            setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));

            setIsSelected(!selection.isCollapsed());
        } else {
            setIsSelected(false);
        }
    }, [editor]);

    const applyStyleText = React.useCallback(
        (styles: Record<string, string>) => {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, styles);
                }
            });
        },
        [editor],
    );

    React.useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar();
                    return false;
                },
                LowPriority,
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
            ),
        );
    }, [editor, updateToolbar]);

    const onFontColorSelect = React.useCallback(
        (value: string) => {
            applyStyleText({ color: value });
        },
        [applyStyleText],
    );

    const onBgColorSelect = React.useCallback(
        (value: string) => {
            applyStyleText({ 'background-color': value });
        },
        [applyStyleText],
    );
    const formatParagraph = () => {
        if (blockType !== 'paragraph') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createParagraphNode());
                }
            });
        }
    };

    const formatLargeHeading = () => {
        if (blockType !== 'h1') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode('h1'));
                }
            });
        }
    };

    const formatSmallHeading = () => {
        if (blockType !== 'h2') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode('h2'));
                }
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatCheckList = () => {
        if (blockType !== 'check') {
            editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatQuote = () => {
        if (blockType !== 'quote') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createQuoteNode());
                }
            });
        }
    };

    const clearFormat = () => {
        formatParagraph();

        onFontColorSelect('');
        onBgColorSelect('');

        formats.forEach((format) => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
        });

        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                formats.forEach((format) => {
                    if (selection.hasFormat(format)) {
                        selection.formatText(format);
                    }
                });
                $patchStyleText(selection, {
                    'font-size': '',
                    'font-family': '',
                    'font-weight': '',
                });
            }
        });
    };

    let blockTypeToStyle: string;
    switch (blockType) {
        case 'h1':
            blockTypeToStyle = style.h1;
            break;
        case 'h2':
            blockTypeToStyle = style.h2;
            break;
        case 'ol':
            blockTypeToStyle = style.ol;
            break;
        case 'ul':
            blockTypeToStyle = style.ul;
            break;
        case 'check':
            blockTypeToStyle = style.checkList;
            break;
        case 'quote':
            blockTypeToStyle = style.quote;
            break;
        case 'paragraph':
        default:
            blockTypeToStyle = style.paragraph;
    }

    return (
        <div className={style.toolbar} ref={toolbarRef}>
            <>
                <button
                    disabled={!canUndo || !isEditable}
                    onClick={() => {
                        editor.dispatchCommand(UNDO_COMMAND, undefined);
                    }}
                    title={'Вернуть'}
                    className={classnames(style.toolbarItem, style.spaced)}
                    aria-label="Undo"
                >
                    <i className={classnames(style.format, style.undo)} />
                </button>
                <button
                    disabled={!canRedo || !isEditable}
                    onClick={() => {
                        editor.dispatchCommand(REDO_COMMAND, undefined);
                    }}
                    title={'Назад'}
                    className={style.toolbarItem}
                    aria-label="Redo"
                >
                    <i className={classnames(style.format, style.redo)} />
                </button>
            </>
            <>
                <DropDown
                    dropdownPlacement={dropdownPlacement}
                    short={shortLexical}
                    disabled={!isEditable}
                    buttonIconClassName={classnames(style.icon, blockTypeToStyle)}
                    buttonClassName={classnames(style.toolbarItem, style.blockControls)}
                    buttonAriaLabel="Formatting options for text alignment"
                >
                    <DropDownItem
                        onClick={formatParagraph}
                        className={classnames(style.item, blockType === 'paragraph' && style.active)}
                    >
                        <i className={classnames(style.icon, style.paragraph)} />
                        <span className={style.text}>Обычный текст</span>
                        {blockType === 'paragraph' && <span className={style.active} />}
                    </DropDownItem>
                    <DropDownItem
                        onClick={formatLargeHeading}
                        className={classnames(style.item, blockType === 'h1' && style.active)}
                    >
                        <i className={classnames(style.icon, style.largeHeading)} />
                        <span className={style.text}>Заголовок Тип 1</span>
                        {blockType === 'h1' && <span className={style.active} />}
                    </DropDownItem>
                    <DropDownItem
                        onClick={formatSmallHeading}
                        className={classnames(style.item, blockType === 'h2' && style.active)}
                    >
                        <i className={classnames(style.icon, style.smallHeading)} />
                        <span className={style.text}>Заголовок Тип 2</span>
                        {blockType === 'h2' && <span className={style.active} />}
                    </DropDownItem>
                    <DropDownItem
                        onClick={formatBulletList}
                        className={classnames(style.item, blockType === 'ul' && style.active)}
                    >
                        <i className={classnames(style.icon, style.bulletList)} />
                        <span className={style.text}>Ненумерованный список</span>
                        {blockType === 'ul' && <span className={style.active} />}
                    </DropDownItem>
                    <DropDownItem
                        onClick={formatNumberedList}
                        className={classnames(style.item, blockType === 'ol' && style.active)}
                    >
                        <i className={classnames(style.icon, style.numberedList)} />
                        <span className={style.text}>Нумерованный список</span>
                        {blockType === 'ol' && <span className={style.active} />}
                    </DropDownItem>
                    <DropDownItem
                        onClick={formatCheckList}
                        className={classnames(style.item, blockType === 'check' && style.active)}
                    >
                        <i className={classnames(style.icon, style.checkList)} />
                        <span className={style.text}>Cписок чек-лист</span>
                        {blockType === 'check' && <span className={style.active} />}
                    </DropDownItem>
                    <DropDownItem
                        onClick={formatQuote}
                        className={classnames(style.item, blockType === 'quote' && style.active)}
                    >
                        <i className={classnames(style.icon, style.quote)} />
                        <span className={style.text}>Цитирование</span>
                        {blockType === 'quote' && <span className={style.active} />}
                    </DropDownItem>
                </DropDown>
            </>
            <>
                <>
                    <button
                        disabled={!isEditable}
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                        }}
                        className={classnames(style.toolbarItem, style.spaced, isBold && style.active)}
                        aria-label="Format Bold"
                    >
                        <i className={classnames(style.format, style.bold)} />
                    </button>
                    <button
                        disabled={!isEditable}
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                        }}
                        className={classnames(style.toolbarItem, style.spaced, isItalic && style.active)}
                        aria-label="Format Italics"
                    >
                        <i className={classnames(style.format, style.italic)} />
                    </button>
                    <button
                        disabled={!isEditable}
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                        }}
                        className={classnames(style.toolbarItem, style.spaced, isUnderline && style.active)}
                        aria-label="Format Underline"
                    >
                        <i className={classnames(style.format, style.underline)} />
                    </button>
                    <button
                        disabled={!isEditable}
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                        }}
                        className={classnames(style.toolbarItem, style.spaced, isStrikethrough && style.active)}
                        aria-label="Format Strikethrough"
                    >
                        <i className={classnames(style.format, style.strikethrough)} />
                    </button>
                    <button
                        disabled={!isEditable || !isSelected}
                        onClick={clearFormat}
                        className={classnames(style.toolbarItem, style.spaced)}
                        aria-label="Очистить форматирование"
                    >
                        <i className={classnames(style.format, style.clearFormat)} />
                    </button>
                </>
                <>
                    <DropdownColorPicker
                        dropdownPlacement={dropdownPlacement}
                        short={shortLexical}
                        disabled={!isEditable}
                        buttonClassName={classnames(style.toolbarItem, style.colorPicker)}
                        buttonAriaLabel="Formatting text color"
                        buttonIconClassName={classnames(style.icon, style.fontColor)}
                        color={fontColor}
                        onChange={onFontColorSelect}
                        title="text color"
                    />
                    <DropdownColorPicker
                        dropdownPlacement={dropdownPlacement}
                        short={shortLexical}
                        disabled={!isEditable}
                        buttonClassName={classnames(style.toolbarItem, style.colorPicker)}
                        buttonAriaLabel="Formatting background color"
                        buttonIconClassName={classnames(style.icon, style.bgColor)}
                        color={bgColor}
                        onChange={onBgColorSelect}
                        title="bg color"
                    />
                    <DropDown
                        dropdownPlacement={dropdownPlacement}
                        short={shortLexical}
                        disabled={!isEditable}
                        buttonIconClassName={classnames(style.icon, style.leftAlign)}
                        buttonClassName={classnames(style.toolbarItem, style.spaced, style.alignment)}
                        buttonAriaLabel="Formatting options for text alignment"
                    >
                        <DropDownItem
                            onClick={() => {
                                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
                            }}
                            className={style.item}
                        >
                            <i className={classnames(style.icon, style.leftAlign)} />
                            <span className={style.text}>По левому краю</span>
                        </DropDownItem>
                        <DropDownItem
                            onClick={() => {
                                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
                            }}
                            className={style.item}
                        >
                            <i className={classnames(style.icon, style.centerAlign)} />
                            <span className={style.text}>По центру</span>
                        </DropDownItem>
                        <DropDownItem
                            onClick={() => {
                                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
                            }}
                            className={style.item}
                        >
                            <i className={classnames(style.icon, style.rightAlign)} />
                            <span className={style.text}>По правому краю</span>
                        </DropDownItem>
                        <DropDownItem
                            onClick={() => {
                                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
                            }}
                            className={style.item}
                        >
                            <i className={classnames(style.icon, style.justifyAlign)} />
                            <span className={style.text}>По ширине</span>
                        </DropDownItem>
                    </DropDown>
                </>
            </>
        </div>
    );
}
