import * as React from 'react';
import { values, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import type { FieldValueHrefArr } from '@store/autopilot/creativeForm';
import type { CommonProps } from '../types';

import { HrefInput } from '@modules/activity/pages/autopilot/common';
import { Header } from '../common';
import { validate } from './validator';

import * as style from './HrefArrField.scss';

interface Props extends CommonProps<FieldValueHrefArr> {}

export const HrefArrField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }): JSX.Element => {
    const onChange = React.useCallback(
        ({ id, value }) => {
            if (value) {
                outsideChangeHandler({
                    fieldId: field.id,
                    fieldError: '',
                    fieldValue: {
                        ...field.value.current,
                        [id]: {
                            id,
                            value,
                            error: validate({ constraints: field.constraints, value })
                                .map((errorMessage) => `• ${errorMessage}`)
                                .join('\n'),
                        },
                    },
                });
            } else {
                outsideChangeHandler({
                    fieldId: field.id,
                    fieldError: '',
                    fieldValue: omit(field.value.current, id),
                });
            }
        },
        [field, outsideChangeHandler],
    );

    const onCreate = React.useCallback(
        ({ value }) => {
            if (value) {
                const id = uuidv4();

                outsideChangeHandler({
                    fieldId: field.id,
                    fieldError: '',
                    fieldValue: {
                        ...field.value.current,
                        [id]: {
                            id,
                            value,
                            error: validate({ constraints: field.constraints, value })
                                .map((errorMessage) => `• ${errorMessage}`)
                                .join('\n'),
                        },
                    },
                });
            }
        },
        [field, outsideChangeHandler],
    );

    return (
        <div className={style.root}>
            <Header field={field} />

            <div className={style.inputs}>
                {values(field.value.current).map(({ id, value, error }) => (
                    <div key={id} className={style.input}>
                        <HrefInput
                            value={value}
                            error={error}
                            placeholder={'Введите ссылку...'}
                            onBlur={(value) => onChange({ id, value })}
                        />
                    </div>
                ))}

                <div className={style.input} key={`${values(field.value.current).length}`}>
                    <HrefInput
                        autoFocus
                        placeholder={'Введите ссылку...'}
                        onBlur={(value) => onCreate({ value, isValid: true })}
                    />
                </div>
            </div>
        </div>
    );
};
