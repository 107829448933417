import { FiledConstraints } from '@store/autopilot/creativeForm';

interface BuildText<V = FiledConstraints[keyof FiledConstraints]> {
    (value: V): string;
}

type TextBuilderMap = {
    [P in keyof FiledConstraints]: BuildText<FiledConstraints[P]>;
};

const textBuilderMap: TextBuilderMap = {
    min: (value) => `Минимальное количестов: ${value}`,
    max: (value) => `Максимальное количестов: ${value}`,
    multiline: (value) => `Множественная выборка: ${value ? 'Да' : 'Нет'}`,
    charsMin: (value) => `Минимальное количество символо: ${value}`,
    charsMax: (value) => `Максимальное количество символо: ${value}`,
    maxLength: (value) => `Максимальное допустимое количество символо: ${value}`,
    maxKb: (value) => `Максимальный вес файла: ${value}Kb`,
    allowedMimeTypes: (values) => `Допустимые типы: ${values.join(', ')}`,
    ratioW: (value) => `Соотношение ширины: ${value} px`,
    ratioH: (value) => `Соотношение высоты: ${value} px`,
    recommendedWidth: (value) => `Рекомендуемая ширина: ${value} px`,
    recommendedHeight: (value) => `Рекомендуемая высота: ${value} px`,
    minWidth: (value) => `Минимальная ширина: ${value} px`,
    minHeight: (value) => `Минимальная высота: ${value} px`,
    maxWidth: (value) => `Максимальная ширина: ${value} px`,
    maxHeight: (value) => `Максимальная высота: ${value} px`,
};

export const getTextBuilder = (constraintsName: keyof FiledConstraints): BuildText => {
    const defaultTextBuilder: BuildText<number> = (value) => `${constraintsName}: ${value}`;
    return textBuilderMap[constraintsName] || defaultTextBuilder;
};
