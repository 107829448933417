import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import type { StoreState } from '@store';

import { DonorBudgetTableButtonTemplate } from './DonorBudgetTableButtonTemplate';
import { WithBudgets } from './WithBudgets';
import { getLoginUser } from '@store/user/selector';
import { getActivity } from '@store/creative/selectors';

interface Props {
    onClick: () => void;
}

export const DonorBudgetTableButtonConnector = ({ onClick }: Props): JSX.Element => {
    const { creativeRequestId } = useParams<{ creativeRequestId: string }>();
    const currentUser = useSelector((state: StoreState) => getLoginUser(state));
    const activity = useSelector((state: StoreState) => getActivity(state));

    const userOrganizationId = currentUser.attributes.organizationId;

    return (
        <WithBudgets organizationIds={[userOrganizationId]}>
            {({ budgets }) =>
                React.createElement(DonorBudgetTableButtonTemplate, {
                    creativeRequestId,
                    activityId: activity.id,
                    budgets,
                    onClick,
                })
            }
        </WithBudgets>
    );
};
