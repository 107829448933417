import * as React from 'react';
import * as lodash from 'lodash';

import type { CreativeRequest, CreativeRequestItem } from '@api';

export function useCreativeRequestItems(creativeRequest: CreativeRequest) {
    const [items, setItems] = React.useState<CreativeRequestItem[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function loadItems() {
            const items = lodash.clone(await creativeRequest?.model.getItems()) || [];
            setItems(items);
            setLoading(false);

            items.forEach((item) => {
                item.events.onReloaded(onItemReloaded);
            });
        }

        function onItemReloaded(newItem: CreativeRequestItem) {
            setItems((items: CreativeRequestItem[]) => [
                ...items.filter((item) => item.model.id !== newItem.model.id),
                newItem,
            ]);

            newItem.events.onReloaded(onItemReloaded);
        }

        function onItemAdded(newItem: CreativeRequestItem) {
            setItems((items: CreativeRequestItem[]) => [...items, newItem]);

            newItem.events.onReloaded(onItemReloaded);
        }

        function onItemsRemoved(removedItem: CreativeRequestItem) {
            setItems((items: CreativeRequestItem[]) => lodash.without(items, removedItem));
        }

        loadItems();

        creativeRequest?.events.onItemsAdded(onItemAdded);
        creativeRequest?.events.onItemsRemoved(onItemsRemoved);

        return () => {
            creativeRequest?.events.offItemsAdded(onItemAdded);
            creativeRequest?.events.offItemsRemoved(onItemsRemoved);
        };
    }, [creativeRequest]);

    return {
        loading,
        creativeRequestItems: items,
    };
}
