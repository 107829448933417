// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-CreativeRequestComments-_CreativeRequestComments__commentsGroup{flex:1;overflow:hidden;display:flex;flex-direction:column}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/widgets/Comments/CreativeRequestComments/CreativeRequestComments.scss"],"names":[],"mappings":"AAAA,qIACI,MAAO,CACP,eAAgB,CAChB,YAAa,CACb,qBAAsB","sourcesContent":[".commentsGroup {\n    flex: 1;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentsGroup": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-CreativeRequestComments-_CreativeRequestComments__commentsGroup"
};
export default ___CSS_LOADER_EXPORT___;
