import * as React from 'react';
import * as lodash from 'lodash';
import classNames from 'classnames';

import type { BriefField, BriefBlock, BriefCost } from 'sber-marketing-types/frontend';

import { Icon, IconType, Toggle, TogglePosition, ToggleThemes, WithTooltip } from 'sber-marketing-ui';
import { Field } from './Field';
import { BlockContainer } from './BlockContainer';

import * as style from './Block.scss';

interface Props {
    id: string;
    activityId: number;
    divisionId?: string;
    departmentId?: string;
    parentId?: string;
    briefId: string;
    uniqId?: number;
    name: string;
    tooltipMessage?: string;
    fields: BriefField[];
    block: BriefBlock;
    blocks: BriefBlock[];
    blocksById: BriefBlock[];
    briefCost?: BriefCost;
    isOpened: boolean;
    isRequired: boolean;
    isOriginal: boolean;
    editRight: boolean;
    isCopyLoading: boolean;
    onClickToggle: () => void;
    onBlockDoubleCreate: () => void;
    onBlockDoubleRemove: () => void;
}

export const Block: React.FC<Props> = ({
    id,
    activityId,
    divisionId,
    departmentId,
    parentId,
    briefId,
    name,
    tooltipMessage,
    fields,
    block,
    blocks,
    blocksById,
    briefCost,
    isRequired,
    isOriginal,
    isOpened,
    editRight,
    isCopyLoading,
    onClickToggle,
    onBlockDoubleCreate,
    onBlockDoubleRemove,
}) => {
    const hasTooltip = !!tooltipMessage;

    return block.isClientHide ? null : (
        <div
            className={classNames(style.root, 'section', `section ${briefId}-${id}`)}
            {...{
                'qa-id': 'briefBlock',
            }}
            data-section-id={`${briefId}-${id}`}
        >
            <div className={style.header}>
                {!isRequired && editRight && (
                    <div className={style.toggle}>
                        <Toggle
                            theme={ToggleThemes.GREY_AND_GREEN_LARGE}
                            position={isOpened ? TogglePosition.RIGHT : TogglePosition.LEFT}
                            onClick={onClickToggle}
                        />
                    </div>
                )}
                <div className={classNames(style.title, hasTooltip && style.titleWithTooltip)}>
                    {editRight &&
                        (isRequired || isOpened) &&
                        !isCopyLoading &&
                        (!isOriginal ? (
                            <div className={style.double} onClick={() => onBlockDoubleRemove()}>
                                <Icon type={IconType.DELETE_V2} svgSize={14} />
                                Удалить копию
                            </div>
                        ) : (
                            <div className={style.double} onClick={onBlockDoubleCreate}>
                                <Icon type={IconType.COPY_BOLD} svgSize={14} />
                                Дублировать блок
                            </div>
                        ))}
                    {name || 'Новый блок'}
                    {!isOriginal ? <span className={style.doubleLabel}> Копия</span> : ''}

                    {hasTooltip && (
                        <WithTooltip
                            content={<div className={style.tooltipMessage}>{tooltipMessage}</div>}
                            className={style.iconTooltip}
                        >
                            <Icon type={IconType.INFO_CIRCLE} svgSize="16" />
                        </WithTooltip>
                    )}
                </div>
            </div>

            {!isRequired ? (
                isOpened && (
                    <Fields
                        activityId={activityId}
                        departmentId={departmentId}
                        divisionId={divisionId}
                        briefId={briefId}
                        fields={fields}
                        briefCost={briefCost}
                        blockId={id}
                    />
                )
            ) : (
                <Fields
                    departmentId={departmentId}
                    divisionId={divisionId}
                    activityId={activityId}
                    briefId={briefId}
                    fields={fields}
                    briefCost={briefCost}
                    blockId={id}
                />
            )}

            {blocksById.map((block, index) =>
                block.isClientHide ? null : (
                    <div key={`sub block ${block.id} ${index}`} className={style.blocks}>
                        <BlockContainer
                            activityId={activityId}
                            departmentId={departmentId}
                            divisionId={divisionId}
                            briefId={briefId}
                            blockId={block.id}
                            blockParentId={block.briefBlockId}
                            block={block}
                            key={block.id}
                            name={block.name}
                            fields={block.fields}
                            blocks={blocks}
                            briefCost={briefCost}
                            isRequired={block.isRequired}
                            isOriginal={block.isOriginal}
                            editRight={editRight}
                        />
                    </div>
                ),
            )}
        </div>
    );
};

interface FieldsProps {
    activityId: number;
    divisionId: string;
    departmentId: string;
    briefId: string;
    blockId: string;
    fields: BriefField[];
    briefCost?: BriefCost;
}

const Fields: React.FC<FieldsProps> = ({
    activityId,
    departmentId,
    divisionId,
    briefId,
    blockId,
    fields,
    briefCost,
}) => (
    <div className={style.fields}>
        {lodash
            .sortBy(fields, (item) => item.order)
            .filter((field) => !field.properties.switchPropertyId && !field.properties.isClientHide)
            .map((field, index) => (
                <div className={style.field} key={`field ${field.id}`}>
                    <Field
                        index={index}
                        activityId={activityId}
                        departmentId={departmentId}
                        divisionId={divisionId}
                        id={field.id}
                        uniqId={field.uniqId}
                        parentUniqId={field.parentUniqId}
                        briefId={briefId}
                        blockId={blockId}
                        briefCost={briefCost}
                        fields={fields}
                    />
                </div>
            ))}
    </div>
);
