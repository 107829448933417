import { v4 } from 'uuid';
import * as lodash from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { CommonState, PageOptions, NotificationMessage, SetFileUploadStatusPayload } from './types';

import * as actions from './actions';

const initialState: CommonState = {
    pageOptions: {
        '/managerDashboard': {
            currentLabel: 'Персональный дашборд',
            withoutFooter: true,
        },
        '/dashboard': {
            currentLabel: ' ',
            withoutFooter: true,
        },
        '/leaderDashboard/:mode': {
            currentLabel: 'Дашборд руководителя',
            withoutFooter: true,
        },
        '/pivotTable': {
            currentLabel: 'Сводная таблица ЗНК',
            withoutFooter: true,
        },
        '/pivotTable/:lot': {
            currentLabel: 'Сводная таблица ЗНК',
            withoutFooter: true,
        },
        '/activity/new': {
            currentLabel: 'Новый проект',
            previousLabel: ' ',
            previousUrl: '/dashboard?currentPage=myProjects',
            withoutFooter: true,
        },
        '/activity/:activityId': {
            previousLabel: '',
            previousUrl: '',
            withoutFooter: true,
        },
        '/activity/:activityId/tasks': {
            previousLabel: '',
            previousUrl: '',
            withoutFooter: true,
        },
        '/activity/:activityId/edit': {
            previousLabel: ' ',
            previousUrl: '/dashboard?currentPage=myProjects',
            withoutFooter: true,
        },
        '/activity/:activityId/brief': {
            currentLabel: 'Брифы',
            previousLabel: 'Проект',
            withoutFooter: true,
        },
        '/activity/:activityId/creative/:creativeRequestId': {
            currentLabel: 'Заявка на креатив',
            withoutFooter: true,
        },
        '/activity/:activityId/budget': {
            currentLabel: 'Бюджет',
            withoutFooter: true,
        },
        '/activity/:activityId/autopilotDOOH': {
            currentLabel: 'Автопилот',
            previousLabel: 'Проект',
            withoutFooter: true,
        },
        '/activity/:activityId/autopilotRadio': {
            currentLabel: 'Автопилот',
            previousLabel: 'Проект',
            withoutFooter: true,
        },
        '/activity/:activityId/autopilotTv': {
            currentLabel: 'Автопилот',
            previousLabel: 'Проект',
            withoutFooter: true,
        },
        '/activity/:activityId/autopilot_v2': {
            currentLabel: 'Автопилот',
            previousLabel: 'Проект',
            withoutFooter: true,
        },
        '/activity/:activityId/autopilot': {
            currentLabel: 'Автопилот',
            previousLabel: 'Проект',
            withoutFooter: true,
        },
        '/activity/:activityId/new-task': {
            currentLabel: 'Новая задача',
            previousLabel: 'Задачи',
            withoutFooter: true,
        },
        '/activity/:activityId/task/:taskId': {
            previousLabel: 'Задачи',
            previousUrl: '/dashboard?currentPage=myTasks',
            withoutFooter: true,
        },
        '/activity/:activityId/task/:taskId/brief': {
            currentLabel: 'Брифы',
            previousLabel: 'Задача',
            withoutFooter: true,
        },
        '/task/new-task': {
            currentLabel: 'Новая задача',
            previousLabel: 'Задачи',
            withoutFooter: true,
        },
        '/task/:taskId': {
            previousLabel: 'Задачи',
            previousUrl: '/dashboard?currentPage=myTasks',
            withoutFooter: true,
            withoutDownPadding: true,
        },
        '/budget': {
            currentLabel: 'Бюджетирование',
            withoutFooter: true,
        },
        '/budget/:pageName': {
            currentLabel: 'Бюджетирование',
            withoutFooter: true,
        },
        '/budget/new': {
            currentLabel: 'Создание активности',
            previousLabel: 'Планирование',
            previousUrl: '/budget',
            withoutFooter: true,
        },
        '/budget/:activityId': {
            previousLabel: 'Планирование',
            previousUrl: '/budget',
            withoutFooter: true,
        },
        '/calendar/:pageName': {
            currentLabel: 'Календарь',
            withoutFooter: true,
        },
        '/executionCalendar': {
            currentLabel: 'Календарь',
            withoutFooter: true,
        },
        '/search': {
            currentLabel: 'Поиск',
            withoutFooter: true,
        },
        '/release-notes': {
            currentLabel: 'История изменений',
            withoutFooter: true,
            withoutDownPadding: true,
        },
        '/tm/registry': {
            currentLabel: 'Реестр ТЗ',
            withoutHeader: false,
            withoutNavMenu: false,
            withoutFooter: true,
            withoutDownPadding: true,
        },
        '/tags': {
            currentLabel: 'Теги',
            withoutFooter: true,
        },
        '/onboarding': {
            withoutHeader: true,
            withoutFooter: true,
        },
    },
    isRequestInProgress: false,
    errorCode: null,
    errorMessage: null,
    currentPath: '',
    headerHeight: 49,
    disableRequestErrors: false,
    notificationList: {},
    filesToUploadStatus: {},
};

class Reducer {
    public static updatePageOptions(state: CommonState, pageOptions: PageOptions) {
        return {
            ...state,
            pageOptions: {
                ...state.pageOptions,
                [state.currentPath]: { ...state.pageOptions[state.currentPath], ...pageOptions },
            },
        };
    }

    public static updateCurrentPath(state: CommonState, currentPath: string): CommonState {
        return {
            ...state,
            previousPath: state.currentPath,
            currentPath,
        };
    }

    public static setRequestInProgress(state: CommonState, isRequestInProgress: boolean) {
        return { ...state, isRequestInProgress };
    }

    public static updateError(state: CommonState, errorCode: string) {
        return state.disableRequestErrors
            ? state
            : { ...state, errorCode, isRequestInProgress: errorCode ? state.isRequestInProgress : false };
    }

    public static updateErrorMessage(state: CommonState, errorMessage: string): CommonState {
        return { ...state, errorMessage };
    }

    public static resetErrorState(state: CommonState): CommonState {
        return {
            ...state,
            errorCode: null,
            errorMessage: null,
        };
    }

    public static setHeaderHeight(state: CommonState, headerHeight: number) {
        return { ...state, headerHeight };
    }

    public static updateDisableRequestErrors(state: CommonState, disableRequestErrors: boolean) {
        return { ...state, disableRequestErrors };
    }

    public static setNotification(state: CommonState, notification: NotificationMessage): CommonState {
        const hasSameTypeActions = lodash.some(
            state.notificationList,
            (item) => item.typeAction === notification.typeAction,
        );

        return hasSameTypeActions
            ? {
                  ...state,
                  notificationList: {
                      [v4()]: notification,
                  },
              }
            : {
                  ...state,
                  notificationList: {
                      ...state.notificationList,
                      [v4()]: notification,
                  },
              };
    }

    public static clearAllNotifications(state: CommonState) {
        return {
            ...state,
            notificationList: {},
        };
    }

    public static resetNotification(state: CommonState, targetKey: string): CommonState {
        return {
            ...state,
            notificationList: {
                ...lodash.omit(state.notificationList, [targetKey]),
            },
        };
    }

    public static setFileUploadStatus(state: CommonState, payload: SetFileUploadStatusPayload): CommonState {
        const { fileName, status } = payload;

        return {
            ...state,
            filesToUploadStatus: {
                ...state.filesToUploadStatus,
                [fileName]: status,
            },
        };
    }

    public static resetFilesUploadStatus(state: CommonState): CommonState {
        return { ...state, filesToUploadStatus: {} };
    }
}

export const commonReducer = reducerWithInitialState(initialState)
    .case(actions.updateCurrentPath, Reducer.updateCurrentPath)
    .case(actions.updatePageOptions, Reducer.updatePageOptions)
    .case(actions.setRequestInProgress, Reducer.setRequestInProgress)
    .case(actions.updateError, Reducer.updateError)
    .case(actions.updateErrorMessage, Reducer.updateErrorMessage)
    .case(actions.resetErrorState, Reducer.resetErrorState)
    .case(actions.setHeaderHeight, Reducer.setHeaderHeight)
    .case(actions.updateDisableRequestErrors, Reducer.updateDisableRequestErrors)
    .case(actions.setNotification, Reducer.setNotification)
    .case(actions.resetNotification, Reducer.resetNotification)
    .case(actions.clearAllNotifications, Reducer.clearAllNotifications)
    .case(actions.setFileUploadStatus, Reducer.setFileUploadStatus)
    .case(actions.resetFilesUploadStatus, Reducer.resetFilesUploadStatus);
