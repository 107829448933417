import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Module, RoleAttributes, RoleId } from 'sber-marketing-types/backend';
import { compact } from 'lodash';
import { NavigationMenu, NavigationLinkProps, IconType, ProjectLinkProps } from 'sber-marketing-ui';

import { AuthApi, StorageApi } from '@api';

import { organisations } from '@common/constants';

import { StoreState } from '@store';
import { getLoginUser, userHasLeaderDashboardAccess } from '@store/user';

interface NavigationItem extends NavigationLinkProps {
    organisations?: string[];
    role?: number;
}

const NAV_MENU_ITEMS: NavigationItem[] = [
    {
        qaId: 'navigationMenuPersonalDashboardLink',
        title: 'Персональный дашборд',
        url: '/managerDashboard',
        additionalUrls: ['/pivotTable', '/pivotTable/lot1', '/pivotTable/lot2'],
        icon: IconType.DASHBOARD_LINK_UNCOLORED,
        iconColor: '#999999',
        activeIcon: IconType.DASHBOARD_LINK,
        activeIconColor: '#19bb4f',
        module: Module.Dashboard,
    },
    {
        qaId: 'navigationMenuDashboardLink',
        title: 'Проекты',
        url: '/dashboard',
        additionalUrls: [
            '/activity/new',
            '/activity/:id',
            '/activity/:id/edit',
            '/activity/:id/tasks',
            '/activity/:id/brief',
            '/activity/:id/creative/:id',
            '/activity/:id/autopilot',
            '/activity/:id/autopilot_v2',
            '/activity/:id/autopilotTv',
            '/activity/:id/autopilotRadio',
            '/activity/:id/autopilotDOOH',
            '/activity/:id/task/:id',
            '/activity/:id/task/:id/brief',
        ],
        icon: IconType.PROJECTS_LINK_UNCOLORED,
        iconColor: 'transparent',
        activeIcon: IconType.PROJECTS_LINK,
        activeIconColor: 'transparent',
        module: Module.Dashboard,
    },
    {
        qaId: 'navigationMenuCalendarLink',
        title: 'Календарь',
        url: '/calendar/chart',
        additionalUrls: ['/calendar/week'],
        icon: IconType.CALENDAR_LINK,
        iconColor: '#999999',
        activeIcon: IconType.CALENDAR_LINK_UNCOLORED,
        activeIconColor: '#19bb4f',
        module: Module.Calendar,
    },
    // {
    //     qaId: 'navigationMenuExecutionCalendarLink',
    //     title: 'План активностей',
    //     url: '/executionCalendar',
    //     icon: IconType.CALENDAR_LINK,
    //     iconColor: '#999999',
    //     activeIcon: IconType.CALENDAR_LINK_UNCOLORED,
    //     activeIconColor: '#19bb4f',
    //     module: Module.Budget
    // },
    {
        qaId: 'navigationMenuBudgetLink',
        title: 'Бюджетирование',
        url: '/budget/execution',
        additionalUrls: [
            '/budget/planning',
            '/budget/planning/new',
            '/budget/planning/:id',
            '/budget/planning/budgetItem/:budgetItemId/brief',
            '/budget/planning/budgetItem/:budgetItemId/brief/:action(create)',
            '/budget/execution/new',
            '/budget/execution/:id',
            '/budget/corrections',
        ],
        icon: IconType.BUDGET_LINK,
        iconColor: '#999999',
        activeIcon: IconType.BUDGET_LINK,
        activeIconColor: '#19bb4f',
        module: Module.Budget,
    },
    {
        title: 'Реестр ТЗ',
        url: '/tm/registry',
        icon: IconType.TM,
        iconColor: '#999999',
        activeIcon: IconType.TM,
        activeIconColor: '#19bb4f',
        organisations: [organisations.sberBank],
        role: RoleId.TMManager,
        module: Module.TM,
    },
];

interface Props extends Partial<MapProps> {}

interface MapProps {
    userModules: Module[];
    userOrganizationId: string;
    userHasLeaderDashboardAccess: boolean;
    userRoles: RoleAttributes[];
}

interface State {
    availableStorages: {
        title: string;
        url: string;
    }[];
}

@(withRouter as any)
@(connect(mapStateToProps) as any)
export class NavMenuContainer extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            availableStorages: [],
        };
    }

    public async componentDidMount(): Promise<void> {
        if (this.props.userOrganizationId) {
            const availableStorages = await StorageApi.getAvailableStorages();

            this.setState({
                availableStorages: Object.keys(availableStorages).reduce(
                    (acc, title) => [
                        ...acc,
                        {
                            title,
                            url: availableStorages[title],
                        },
                    ],
                    [],
                ),
            });
        }
    }

    public render(): JSX.Element {
        const items = this.getItems();
        const projectItems = this.getProjectItems();

        return (
            <NavigationMenu
                bottomItems={[
                    {
                        title: 'Обучение',
                        qaId: 'onboardingLink',
                        url: '/onboarding',
                        iconColor: '#979797',
                        activeIconColor: '#16BA4F',
                        icon: IconType.ONBOARDING,
                        activeIcon: IconType.ONBOARDING,
                    },
                ]}
                items={items}
                projectItems={projectItems}
                onLogoutButtonClick={AuthApi.logout}
            />
        );
    }

    private getItems(): NavigationLinkProps[] {
        const { userOrganizationId, userHasLeaderDashboardAccess, userModules, userRoles } = this.props;
        const userRoleIds = userRoles.map(({ id }) => id);

        if (!userOrganizationId) {
            return [];
        }

        const result = NAV_MENU_ITEMS.filter(
            ({ organisations }) => !organisations || organisations.includes(userOrganizationId),
        ).filter(({ role }) => !role || userRoleIds.includes(role));

        if (userHasLeaderDashboardAccess) {
            result.unshift({
                qaId: 'navigationMenuLeaderDashboardLink',
                title: 'Дашборд руководителя',
                url: '/leaderDashboard/activities',
                additionalUrls: ['/leaderDashboard/activities', '/leaderDashboard/tasks'],
                icon: IconType.LEADER_DASHBOARD_UNCOLORED,
                iconColor: 'transparent',
                activeIcon: IconType.LEADER_DASHBOARD,
                activeIconColor: 'transparent',
                module: Module.Dashboard,
            });
        }

        return result.filter((item) => userModules.includes(item.module as Module));
    }

    private getProjectItems(): ProjectLinkProps[] {
        const { userOrganizationId } = this.props;

        if (!userOrganizationId) {
            return [];
        }

        const damLink = this.checkLink(
            {
                qaId: 'navigationMenuDAMLink',
                title: 'DAM',
                url:
                    userOrganizationId === organisations.sberMarketing
                        ? DAM_SBERMARKETING_LINK
                        : DAM_SBERBANK_TERBANK_LINK,
                icon: IconType.DAM_PROJECT,
            },
            [organisations.sberBank, organisations.terBank, organisations.sberMarketing],
        );

        const brandbookLink = this.checkLink(
            {
                qaId: 'brandbookLink',
                title: 'Брендбук',
                url: BRANDBOOK_LINK,
                icon: IconType.BRANDBOOK,
            },
            [
                organisations.terBank,
                organisations.dud,
                organisations.sberMarketing,
                organisations.mediaseller,
                organisations.sberBank,
            ],
        );

        const archiveLink = this.checkLink(
            {
                qaId: 'archiveLink',
                title: 'Архив',
                icon: IconType.ARCHIVE_ICON,
                submenuContent: {
                    title: 'Переход в пространство',
                    items: this.state.availableStorages,
                },
            },
            [organisations.sberBank],
        );

        const qlikLink = this.checkLink(
            {
                qaId: 'qlikLink',
                title: 'Qlik Sense',
                icon: IconType.QLIK_SENSE_ICON,
                url: '/api/qlik/redirect',
            },
            [organisations.sberBank, organisations.sberMarketing],
            [RoleId.Qlik],
        );

        const platinaLink = this.checkLink(
            {
                qaId: 'platinaLink',
                title: 'Platina',
                icon: IconType.QLIK_SENSE_ICON,
                url: 'https://platina.sbermarketing.ru',
            },
            [organisations.sberBank, organisations.sberMarketing],
            [RoleId.Platina],
        );

        return compact([damLink, brandbookLink, archiveLink, qlikLink, platinaLink]);
    }

    private checkLink(link: ProjectLinkProps, organizations: string[], roles?: RoleId[]): ProjectLinkProps {
        let useLink = organizations.includes(this.props.userOrganizationId);
        if (roles && roles.length) {
            useLink = useLink && this.props.userRoles.some((role) => roles.includes(role.id));
        }

        return useLink ? link : null;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const user = getLoginUser(state);

    if (!user.attributes) {
        return {
            userHasLeaderDashboardAccess: userHasLeaderDashboardAccess(user),
            userOrganizationId: null,
            userModules: [],
            userRoles: [],
        };
    }

    return {
        userOrganizationId: user.attributes.organizationId,
        userModules: user.attributes.modules,
        userRoles: user.attributes.roles,
        userHasLeaderDashboardAccess: userHasLeaderDashboardAccess(user),
    };
}
