import * as React from 'react';
import * as lodash from 'lodash';

import type { Project, ProjectBudgetItem } from '@api';

export function useProjectBudgetItems(project: Project) {
    const [projectBudgetItems, setBudgetItems] = React.useState<ProjectBudgetItem[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function loadBudgetItems() {
            const projectBudgetItems = lodash.clone(await project?.model.getBudgetItems()) || [];
            setBudgetItems(projectBudgetItems);
            setLoading(false);
        }

        loadBudgetItems();

        project?.events.onBudgetItemsAdded(loadBudgetItems);
        project?.events.onBudgetItemsRemoved(loadBudgetItems);

        return () => {
            project?.events.offBudgetItemsAdded(loadBudgetItems);
            project?.events.offBudgetItemsRemoved(loadBudgetItems);
        };
    }, [project]);

    return {
        loading,
        budgetItems: projectBudgetItems,
    };
}
