import * as React from 'react';

import * as style from './Table.scss';

import {
    CellPosition,
    ColumnName,
    ColumnWidths,
    LineId,
    TableHeaderCellParams,
    TableBodyCellParams,
    TableType,
} from './types';

import { TableView, CellsStorage, CellEvent, Icon, IconType, CustomScrollbarTheme } from 'sber-marketing-ui';

interface Props {
    headerCellsStorage: CellsStorage<string, TableHeaderCellParams>;
    tableCellsStorage: CellsStorage<CellPosition, TableBodyCellParams>;
    tableColumns: Record<'left' | 'center' | 'right', ColumnName[]>;
    fixedWidthColumns: ColumnName[];
    budgetItemsTableLinesIds: LineId[];
    totalTableLineIds: LineId[];
    columnWidths: ColumnWidths;
    isExpanded: boolean;
    budgetItemsTableRef: (component: TableView) => void;
    totalTableRef: (component: TableView) => void;
    onCellEvent: (eventType: CellEvent, position: CellPosition) => void;
    onExpandButtonClick: () => void;
    onColumnWidthsChange: (tableType: TableType, columnWidths: ColumnWidths) => void;
}

export const TableTemplate = ({
    headerCellsStorage,
    tableCellsStorage,
    tableColumns,
    fixedWidthColumns,
    budgetItemsTableLinesIds,
    totalTableLineIds,
    columnWidths,
    isExpanded,
    budgetItemsTableRef,
    totalTableRef,
    onCellEvent,
    onExpandButtonClick,
    onColumnWidthsChange,
}: Props): JSX.Element => {
    return (
        <div className={style.root}>
            <TableView
                ref={budgetItemsTableRef}
                headerCellsStorage={headerCellsStorage}
                tableCellsStorage={tableCellsStorage}
                columns={tableColumns.center}
                leftFixedColumns={tableColumns.left}
                rightFixedColumns={tableColumns.right}
                fixedWidthColumns={fixedWidthColumns}
                lines={budgetItemsTableLinesIds}
                columnWidths={columnWidths}
                lineHeight={40}
                scrollbarTheme={[CustomScrollbarTheme.GrayRounded, CustomScrollbarTheme.Small]}
                onCellEvent={onCellEvent}
                onColumnWidthsChange={(columnWidths) => onColumnWidthsChange(TableType.BudgetItems, columnWidths)}
                hideBorders
            />

            <div className={style.expandButton} onClick={onExpandButtonClick}>
                {isExpanded ? 'Свернуть' : 'Развернуть'}

                <div className={style.expandButtonIcon}>
                    <Icon type={isExpanded ? IconType.ARROW8_UP : IconType.ARROW8_DOWN} svgSize={8} />
                </div>
            </div>

            {isExpanded && (
                <TableView
                    ref={totalTableRef}
                    tableCellsStorage={tableCellsStorage}
                    columns={tableColumns.center}
                    leftFixedColumns={tableColumns.left}
                    rightFixedColumns={tableColumns.right}
                    fixedWidthColumns={fixedWidthColumns}
                    lines={totalTableLineIds}
                    columnWidths={columnWidths}
                    lineHeight={20}
                    scrollbarTheme={[CustomScrollbarTheme.GrayRounded, CustomScrollbarTheme.Small]}
                    onCellEvent={onCellEvent}
                    onColumnWidthsChange={(columnWidths) => onColumnWidthsChange(TableType.TotalTable, columnWidths)}
                    hideBorders
                />
            )}
        </div>
    );
};
