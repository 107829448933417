import * as React from 'react';

import { Field, FieldValueText } from '@store/autopilot/creativeForm';
import { TextInput } from '../../../common';
import { Header } from '../common';
import { validate } from './validator';
import type { ChangeParams } from '../types';

import * as style from './TextField.scss';

interface Props {
    field: Field<FieldValueText>;
    onChange: (params: ChangeParams) => void;
}

export const TextField: React.FC<Props> = ({ field, onChange: outsideChangeHandler }) => {
    const onBlur = React.useCallback(
        async (value: string) => {
            outsideChangeHandler({
                fieldId: field.id,
                fieldValue: {
                    ...field.value.current,
                    value,
                    error: value.length
                        ? (await validate({ constraints: field.constraints, value }))
                              .map((errorMessage) => `• ${errorMessage}`)
                              .join('\n')
                        : '',
                } as FieldValueText,
                fieldError: '',
            });
        },
        [field, outsideChangeHandler],
    );

    return (
        <div className={style.root}>
            <Header field={field} />

            <div className={style.input}>
                <TextInput
                    value={field.value.current.value}
                    error={field.value.current.error}
                    placeholder={'Вставьте текст...'}
                    onBlur={onBlur}
                />
            </div>
        </div>
    );
};
