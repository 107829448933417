import * as React from 'react';
import classNames from 'classnames';

import { Space, Card, CardProps, Flex, Scrollbar, Chip, FavouriteIcon } from '@sbermarketing/mrm-ui';
import { Icon, IconType } from 'sber-marketing-ui';

import { TaskCommentTransferObject } from 'sber-marketing-types/backend';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { UserConfigType } from 'sber-marketing-types/openid';

import { useGetTaskQuery, useGetUserConfigQuery, useSetUserConfigMutation } from '@api';

import { useSearch } from '@common/hooks';
import { useGetChannelParticipantsCountText } from '@modules/task/hooks';

import { ChatContent, CommentForm } from '@modules/comment/components';

import * as styles from './ChatCard.scss';

export interface ChatCardProps extends CardProps {
    taskId: string;
    channelId: number;
}

export function ChatCard({ taskId, channelId, loading, className, children, ...props }: ChatCardProps) {
    const contentRef = React.useRef<HTMLDivElement>();
    const [maxHeight, setMaxHeight] = React.useState(0);
    const { data: task, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const { data: chatConfig, isLoading: isChatConfigLoading } = useGetUserConfigQuery(UserConfigType.Chat);
    const [setConfig] = useSetUserConfigMutation();
    const [favourite, setFavourite] = React.useState(false);
    const [search, setSearch] = useSearch();
    const { text: participantsCount, isLoading: isParticipantsCountLoading } = useGetChannelParticipantsCountText(
        taskId,
        channelId,
    );
    const [editComment, setEditComment] = React.useState<TaskCommentTransferObject>();
    const comment = search.commentId
        ? task?.task.chanels.find(({ id }) => id === channelId)?.comments?.find(({ id }) => id === search.commentId)
        : null;
    const isLoading = isTaskLoading || isParticipantsCountLoading || isChatConfigLoading;
    const isActive = task && task.task.status === TaskStatus.InProgress;
    const isDraft = task && task.task.status === TaskStatus.Draft;
    const config = chatConfig?.config.configData ? JSON.parse(chatConfig?.config.configData) : {};
    const invert = Boolean(config.invert);

    React.useEffect(() => {
        setMaxHeight(contentRef.current.clientHeight - 32);
    }, [isLoading, channelId]);

    const handleFinish = () => {
        setEditComment(undefined);
    };

    const handleCancel = () => {
        if (comment) {
            setSearch({ ...search, commentId: undefined });
            return;
        }

        handleFinish();
    };

    const handleInvert = () => {
        setConfig({ type: UserConfigType.Chat, configData: JSON.stringify({ ...config, invert: !invert }) });
    };

    const handleSwitchFavourite = () => {
        setFavourite(!favourite);
    };

    const textareaElement = isActive && (!favourite || !!comment || !!editComment) && (
        <CommentForm
            maxHeight={maxHeight}
            replyComment={comment}
            comment={editComment}
            onSubmit={handleFinish}
            onCancel={handleCancel}
            taskId={taskId}
            channelId={channelId}
            invert={invert}
            className={invert ? styles.textareaInvert : styles.textarea}
        />
    );

    const contentElement = isDraft ? (
        <Flex flex vertical align="center" justify="center">
            Чат недоступен для задач в статусе «Черновик»
        </Flex>
    ) : (
        <Flex flex vertical justify="flex-end">
            <ChatContent favourite={favourite} onEditComment={setEditComment} taskId={taskId} channelId={channelId} />
        </Flex>
    );

    return (
        <Card
            flex
            vertical
            reverse
            {...props}
            loading={isLoading || loading}
            className={classNames(styles.root, isDraft && styles.draft, className)}
        >
            <Flex rootRef={contentRef} reverse={invert} vertical flex className={styles.content}>
                <Scrollbar key={channelId} reverse={!invert} flex padding={[0, 0, 18]} vertical>
                    {contentElement}
                </Scrollbar>
                {textareaElement}
            </Flex>
            <Flex align="center" gap={16} padding={[4, 20]} className={styles.header}>
                <Flex data-qa-id="ChatCard__participantsCount" gap={6} align="center">
                    <Icon type={IconType.USER} />
                    {participantsCount}
                </Flex>
                <Space />
                <Chip ghost flat onClick={handleSwitchFavourite}>
                    <FavouriteIcon filled color={favourite ? 'var(--color-orange-40)' : 'var(--color-gray-20)'} />
                    Избранное
                </Chip>
                <Chip ghost flat onClick={handleInvert}>
                    <Icon type={IconType.TRANSFER} />
                    {invert ? 'от новых к старым' : 'от старых к новым'}
                </Chip>
            </Flex>
        </Card>
    );
}
