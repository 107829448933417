import * as React from 'react';

import { Button_redesign as ButtonUI, ButtonTheme_redesign as ButtonTheme, Preloader } from 'sber-marketing-ui';
import * as styles from './styles.scss';

interface Props {
    disabled: boolean;
    loading: boolean;
    onClick: () => void;
    children: string;
}

export const Button: React.FC<Props> = ({ disabled, loading, children, onClick }) => {
    return (
        <div className={styles.root}>
            <ButtonUI disabled={disabled} theme={ButtonTheme.MainRounded} onClick={onClick}>
                {children}
            </ButtonUI>
            {loading && (
                <div className={styles.preloaderContainer}>
                    <Preloader className={styles.preloader} />
                </div>
            )}
        </div>
    );
};
