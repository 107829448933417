import * as React from 'react';

import type { WorkType } from 'sber-marketing-types/frontend';

import { DropdownPortalOption, DropdownPortalOptionProps, Option } from '@common/components';
import { UserOptions } from '@modules/user/components';
import { UseUserOptions } from '@modules/user/hooks';

export interface UserWorkTypeOptionProps
    extends DropdownPortalOptionProps<number>,
        Omit<UseUserOptions, 'departmentIds'> {
    portal: React.MutableRefObject<HTMLElement>;
    workType: WorkType.Data;
    isLoading?: boolean;
}

export function UserWorkTypeOption({
    value,
    onSelect,
    portal,
    workType,
    isLoading,
    active,
    ids,
    sortingOrder,
    embed,
    roleIds,
    organizationIds,
    hasWorkTypes,
    ...props
}: UserWorkTypeOptionProps) {
    const userParams: Required<UseUserOptions> = {
        ids,
        active,
        sortingOrder,
        embed,
        departmentIds: workType.departmentIds,
        roleIds,
        organizationIds,
        hasWorkTypes,
    };

    return isLoading ? (
        <Option value={value} {...props} />
    ) : workType.usersCount < 2 ? (
        <Option value={value} {...props} onSelect={onSelect} />
    ) : (
        <DropdownPortalOption
            {...props}
            value={workType}
            params={{ options: { trigger: 'hover', placement: 'rightTop', stretch: true } }}
            portal={portal}
        >
            <UserOptions {...userParams} selected={value} onSelect={onSelect} />
        </DropdownPortalOption>
    );
}
