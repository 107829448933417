import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { sanitize } from 'dompurify';
import { values, groupBy, uniq, compact } from 'lodash';
import { TaskCommentTransferObject, FileResponse } from 'sber-marketing-types/frontend';
import { OneTaskResponseParams, TaskStatus } from 'sber-marketing-types/backend';
import { ChanelObject } from 'sber-marketing-types/backend';
import { AnimatedEllipsis, WithTooltip, TooltipAnchor, StaticSkeleton } from 'sber-marketing-ui';

import { FileApiUploadParams, FileApi, useGetCommentDraftQuery, useSetCommentDraftMutation } from '@api';

import { StoreState } from '@store';
import { FileAsset } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import { getUserById, getAppUsers } from '@store/appUsers';

import { parseMessage } from '@common/lib';
import { DatesFormatter, Utils } from '@common/Utils';
import { ExpandFilesButton } from '@common/ExpandFilesButton';
import { FileCardsList } from '@common/FileCardsList';
import { SidebarContext } from '@common/SidebarWithTabs';
import { NoResultsMarker } from '@common/SidebarWithTabs/common/NoResultsMarker';
import { MakeFileUploadParams, CommentInputLexical } from '@common/CreateCommentForm/CommentInput';
import { CommentReactionForm } from '@common/CommentReactionForm';
import { useTextOverflowCheck } from '@common/hooks';

import * as styles from './CommentsTab.scss';

function getCommentsDeclension(n: number) {
    return Utils.getDeclensionByNumber(n, ['комментарий', 'комментария', 'комментариев']);
}

type ToggleCommentReaction = (
    params: { commentId: string; taskId: string; channelId: number },
    reaction: string,
) => Promise<void>;

interface Props {
    taskContent: OneTaskResponseParams;
    taskRefetchInProgress: boolean;
    channelId: number;
    sendComment: (
        channelId: number,
        commentId: string,
        text: string,
        files: FileAsset[],
        replyId: string,
    ) => Promise<void>;
    toggleCommentReaction: ToggleCommentReaction;
}

function useCreateComment({ taskContent, channelId, sendComment }: Props) {
    const [isCommentCreationInProgress, setIsCommentCreationInProgress] = React.useState(false);
    const [newCommentId, setNewCommentId] = React.useState(v4());
    const [commentIdToReplyTo, _setCommentIdToReplyTo] = React.useState<string>(null);
    const [prevScroll, setPrevScroll] = React.useState(null);

    const { contentRef, scrollToBotom, scrollTo, getCurrentScroll } = React.useContext(SidebarContext);

    const taskId = taskContent?.id;
    const showPreloader = !taskContent;

    function onCommentCreationStart() {
        setIsCommentCreationInProgress(true);
    }

    async function createComment(text: string, files: FileAsset[]) {
        setIsCommentCreationInProgress(true);

        await sendComment(channelId, newCommentId, text, files, commentIdToReplyTo);

        setNewCommentId(v4());

        setIsCommentCreationInProgress(false);
    }

    function setCommentIdToReplyTo(commentIdToReplyTo: string) {
        if (commentIdToReplyTo) {
            setPrevScroll(getCurrentScroll());
        }
        _setCommentIdToReplyTo(commentIdToReplyTo);
    }

    const makeFileUploadParams = React.useMemo<MakeFileUploadParams>(() => {
        const res: MakeFileUploadParams = () => [{ taskId, commentId: newCommentId }, true];

        return res;
    }, [taskId, newCommentId]);

    React.useEffect(
        function resetCommentIdToReplyToAfterChannelChange() {
            setCommentIdToReplyTo(null);
        },
        [channelId],
    );

    React.useEffect(
        function scrollToBottomAfterLoading() {
            scrollToBotom?.();
        },
        [isCommentCreationInProgress, showPreloader],
    );

    React.useLayoutEffect(
        function resetScrollAFterReplyCHainIsClosed() {
            scrollTo(commentIdToReplyTo ? null : prevScroll);
        },
        [commentIdToReplyTo],
    );

    return {
        isCommentCreationInProgress,
        showPreloader,
        createComment,
        makeFileUploadParams,
        onCommentCreationStart,
        commentIdToReplyTo,
        setCommentIdToReplyTo,
        contentRef,
    };
}

function useCommentsTab(props: Props) {
    const {
        isCommentCreationInProgress,
        showPreloader,
        createComment,
        makeFileUploadParams,
        onCommentCreationStart,
        commentIdToReplyTo,
        setCommentIdToReplyTo,
        contentRef,
    } = useCreateComment(props);
    const { taskContent, channelId } = props;

    const channel = taskContent?.chanels?.find?.((channel) => channel.id === channelId);

    const loginnedUserId = useSelector((state: StoreState) => getLoginUser(state).attributes.id);
    const userIdsForMention = React.useMemo(
        () =>
            uniq(
                compact(
                    [
                        taskContent?.authorId,
                        taskContent?.executorId,
                        ...(taskContent?.participants?.map((participant) => participant.userId) || []),
                    ].filter((userId) => userId !== loginnedUserId),
                ),
            ),
        [taskContent],
    );

    return {
        channel,
        isCommentCreationInProgress,
        createComment,
        makeFileUploadParams,
        onCommentCreationStart,
        commentIdToReplyTo,
        showPreloader,
        userIdsForMention,
        setCommentIdToReplyTo,
        contentRef,
    };
}

export function CommentsTab(props: Props): JSX.Element {
    const {
        channel,
        isCommentCreationInProgress,
        createComment,
        makeFileUploadParams,
        onCommentCreationStart,
        commentIdToReplyTo,
        showPreloader,
        userIdsForMention,
        setCommentIdToReplyTo,
        contentRef,
    } = useCommentsTab(props);
    const taskId = props.taskContent?.id;
    const { data: draft, isFetching: isDraftFetching } = useGetCommentDraftQuery(taskId, { skip: !taskId });
    const [setDraft] = useSetCommentDraftMutation();
    const [commentText, setCommentText] = React.useState('');
    const changeCommentTextRef = React.useRef<(value: string) => void>();

    const handleCreateComment: typeof createComment = async (text, files) => {
        const result = await createComment(text, files);

        if (draft?.newComment) {
            setDraft({
                id: taskId,
                newComment: '',
            });
        }

        return result;
    };

    const handleBlur = () => {
        if (!commentIdToReplyTo && !isDraftFetching && commentText !== draft.newComment) {
            setDraft({
                id: taskId,
                newComment: commentText,
            });
        }
    };

    const handleChange = (value: string) => {
        setCommentText(value);
    };

    React.useEffect(() => {
        if (!isDraftFetching && !commentIdToReplyTo && draft?.newComment) {
            changeCommentTextRef.current(draft.newComment);
        }
    }, [!draft, !commentIdToReplyTo, taskId, isDraftFetching]);

    const { taskContent, taskRefetchInProgress, toggleCommentReaction } = props;

    const showNewCommentPreloader = taskRefetchInProgress || isCommentCreationInProgress;

    const showNoResultsMarker = !channel?.comments?.length && !showNewCommentPreloader;

    if (showPreloader) {
        return <Preloader />;
    }

    const showCommentInput = contentRef?.current && taskContent?.status !== TaskStatus.Draft;
    const disableCommentInput = contentRef?.current && taskContent?.status === TaskStatus.Closed;

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'taskSidebarCommentsTab',
            }}
        >
            <div>
                {showNoResultsMarker ? (
                    <NoResultsMarker text="Нет комментариев" />
                ) : (
                    <Channel
                        {...channel}
                        commentIdToReplyTo={commentIdToReplyTo}
                        setCommentIdToReplyTo={setCommentIdToReplyTo}
                        toggleCommentReaction={toggleCommentReaction}
                    />
                )}

                {showNewCommentPreloader && <NewCommentPreloader />}
            </div>
            {showCommentInput &&
                ReactDOM.createPortal(
                    <div className={styles.createCommentForm}>
                        <CommentInputLexical
                            usersIdsForMention={userIdsForMention}
                            createComment={handleCreateComment}
                            onCommentCreationStart={onCommentCreationStart}
                            makeFileUploadParams={makeFileUploadParams}
                            disabled={disableCommentInput}
                            changeTextRef={changeCommentTextRef}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                    </div>,
                    contentRef.current,
                )}
        </div>
    );
}

interface ChannelProps extends ChanelObject {
    commentIdToReplyTo: string;
    setCommentIdToReplyTo: (commentIdToReplyTo: string) => void;
    toggleCommentReaction: ToggleCommentReaction;
}

function Channel({
    id,
    comments,
    commentIdToReplyTo,
    setCommentIdToReplyTo,
    toggleCommentReaction,
}: ChannelProps): JSX.Element {
    const commentsByReplyId = groupBy(comments, (comment) => comment.replyId);
    const commentAtTheStartOfReplyChain = comments?.find((comment) => comment.id === commentIdToReplyTo);

    const commentsByCreateTime = groupBy(commentsByReplyId[commentIdToReplyTo] || [], (comment) =>
        DatesFormatter.yymmdd(comment.createTime),
    );
    const sortedCommentsDates = Object.keys(commentsByCreateTime).sort(
        (a, b) =>
            new Date(commentsByCreateTime[a][0].createTime).getTime() -
            new Date(commentsByCreateTime[b][0].createTime).getTime(),
    );

    return (
        <React.Fragment>
            {commentAtTheStartOfReplyChain ? (
                <CommentAtTheStartOfReplyChain
                    {...commentAtTheStartOfReplyChain}
                    channelId={id}
                    childrenCommentsCount={commentsByReplyId[commentIdToReplyTo]?.length || 0}
                    commentIdToReplyTo={commentIdToReplyTo}
                    setCommentIdToReplyTo={setCommentIdToReplyTo}
                    toggleCommentReaction={toggleCommentReaction}
                />
            ) : null}

            {sortedCommentsDates.map((commentDate) => {
                const formattedDate = DatesFormatter.ddMonthyyyy(commentsByCreateTime[commentDate]?.[0]?.createTime, {
                    withTodayMarker: true,
                });

                return (
                    <div
                        className={styles.commentByDateGroup}
                        key={commentDate}
                        {...{
                            'qa-id': 'taskSiddebarCommentsTabCommentGroup',
                            'qa-value': formattedDate,
                        }}
                    >
                        <div className={styles.commentByDateGroupTitleWrapper}>
                            <div className={styles.commentByDateGroupTitle}>{formattedDate}</div>
                        </div>

                        {commentsByCreateTime[commentDate]?.map((comment) => (
                            <Comment
                                key={comment.id}
                                {...comment}
                                channelId={id}
                                childrenCommentsCount={commentsByReplyId[comment.id]?.length || 0}
                                commentIdToReplyTo={commentIdToReplyTo}
                                setCommentIdToReplyTo={setCommentIdToReplyTo}
                                toggleCommentReaction={toggleCommentReaction}
                            />
                        ))}
                    </div>
                );
            })}
        </React.Fragment>
    );
}

interface CommentProps extends TaskCommentTransferObject {
    channelId: number;
    childrenCommentsCount: number;
    commentIdToReplyTo: string;
    setCommentIdToReplyTo: (commentId: string) => void;
    toggleCommentReaction: ToggleCommentReaction;
}

function CommentAtTheStartOfReplyChain({ ...props }: CommentProps): JSX.Element {
    return (
        <React.Fragment>
            <div
                className={styles.commentAtTheStartOfReplyChainTitle}
                onClick={() => props.setCommentIdToReplyTo(null)}
            >
                <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.42 3.48C2.172 3.184 1.912 2.928 1.64 2.712C1.376 2.488 1.096 2.304 0.8 2.16V1.644C1.392 1.34 1.932 0.896 2.42 0.312H3.344C3.256 0.544 3.152 0.764 3.032 0.972C2.92 1.172 2.8 1.364 2.672 1.548V2.256C2.8 2.424 2.92 2.612 3.032 2.82C3.152 3.02 3.256 3.24 3.344 3.48H2.42ZM2.6 2.388L2.612 1.404H9.2V2.388H2.6Z"
                        fill="#7E8681"
                    />
                </svg>
                Обсуждение
            </div>

            <Comment {...props} />
        </React.Fragment>
    );
}

function Comment({
    id,
    authorId,
    text,
    createTime,
    files,
    childrenCommentsCount,
    commentIdToReplyTo,
    reactions,
    taskId,
    channelId,
    setCommentIdToReplyTo,
    toggleCommentReaction: toggleCommentReactionAction,
}: CommentProps): JSX.Element {
    const loginnedUserIdAuthor = useSelector((state: StoreState) => getLoginUser(state).attributes.id === authorId);
    const user = useSelector((state: StoreState) => getUserById(state, authorId));

    const nameRef = React.useRef<HTMLSpanElement>();
    const departmentRef = React.useRef<HTMLSpanElement>();

    const [showDepartment, setShowDepartment] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);

    const { horizontal: nameOverflow } = useTextOverflowCheck(nameRef);
    const { horizontal: departmentOverflow } = useTextOverflowCheck(departmentRef);
    const overflow = nameOverflow || departmentOverflow;

    const tooltipContent = `${user?.firstName} ${user?.secondName} (${user?.department})`;

    const showChildrenCommentsButton = !!childrenCommentsCount && (!commentIdToReplyTo || commentIdToReplyTo === id);

    React.useEffect(() => {
        if (departmentOverflow) {
            setShowDepartment(false);
        }
    }, [departmentOverflow]);

    function onReplyButtonClickHandler() {
        setCommentIdToReplyTo(id);
    }

    function toggleCommentReaction(params: { taskId: string; commentId: string }, reaction: string) {
        toggleCommentReactionAction({ ...params, channelId }, reaction);
    }

    return (
        <div
            {...{
                'qa-id': 'taskSidebarCommentsTabComment',
            }}
            className={styles.comment}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <WithTooltip hidden={!overflow} content={tooltipContent} anchor={TooltipAnchor.LEFT}>
                <div className={styles.commentTitle}>
                    <span
                        ref={nameRef}
                        className={styles.commentAuthor}
                        {...{
                            'qa-id': 'taskSidebarCommentsTabCommentAuthor',
                        }}
                    >
                        {user?.firstName} {user?.secondName}
                    </span>
                    <span className={styles.separator}>•</span>

                    {showDepartment && (
                        <>
                            <span
                                ref={departmentRef}
                                className={styles.commentAuthorDepartment}
                                {...{
                                    'qa-id': 'taskSidebarCommentsTabCommentAuthorDepartment',
                                }}
                            >
                                {user?.department}
                            </span>
                            <span className={styles.separator}>•</span>
                        </>
                    )}

                    <span
                        className={styles.commentTime}
                        {...{
                            'qa-id': 'taskSidebarCommentsTabCommentCreateTime',
                        }}
                    >
                        {DatesFormatter.hhmm(createTime)}
                    </span>
                </div>
            </WithTooltip>

            <CommentText text={text} />

            {!!files?.length && (
                <div className={styles.commentFiles}>
                    <CommentFiles
                        loginnedUserIsAuthor={loginnedUserIdAuthor}
                        commentId={id}
                        taskId={taskId}
                        files={files}
                    />
                </div>
            )}

            {showChildrenCommentsButton && (
                <div className={styles.childrenCommentsButton} onClick={onReplyButtonClickHandler}>
                    {childrenCommentsCount} {getCommentsDeclension(childrenCommentsCount)}
                </div>
            )}

            <div className={styles.commentReactionForm}>
                <CommentReactionForm
                    canInteract
                    taskId={taskId}
                    commentId={id}
                    reactions={reactions}
                    addReactionFormIsActive={isHovered}
                    addReactionFormClassName={styles.commentReactionFormAddReaction}
                    onReplyButtonClick={!commentIdToReplyTo ? onReplyButtonClickHandler : null}
                    toggleCommentReaction={toggleCommentReaction}
                />
            </div>
        </div>
    );
}

interface CommentTextProps {
    text: string;
}

function CommentText({ text }: CommentTextProps): JSX.Element {
    const appUsers = useSelector((state: StoreState) => getAppUsers(state).entities);
    const parsedText = parseMessage(text, styles.commentMention, values(appUsers));

    return (
        <div
            {...{
                'qa-id': 'taskSidebarCommentsTabCommentText',
            }}
            className={styles.commentText}
            dangerouslySetInnerHTML={{
                __html: sanitize(parsedText, {
                    ALLOWED_TAGS: [
                        'a',
                        'hr',
                        'br',
                        'span',
                        'b',
                        'strong',
                        'i',
                        'em',
                        'color',
                        'ul',
                        'li',
                        'ol',
                        'div',
                        'p',
                        'h1',
                        'h2',
                        'h3',
                    ],
                    ALLOWED_ATTR: ['target', 'href', 'class', 'title', 'style', 'color'],
                }),
            }}
        />
    );
}

interface CommentFilesProps {
    loginnedUserIsAuthor: boolean;
    taskId: string;
    commentId: string;
    files: FileResponse[];
}

function useCommentFiles({ taskId, commentId, files }: CommentFilesProps) {
    const renderControls = files.length > 1;

    const [isExpanded, setIsExpanded] = React.useState(!renderControls);
    const [downloadAllInProgress, setDownloadAllInProgress] = React.useState(false);

    async function onDownloadAllButtonClick() {
        setDownloadAllInProgress(true);

        await FileApi.downloadMultipleFilesAsZip(
            `Комментарий вложения`,
            files.map((file) => ({
                id: file.id,
                name: file.originName,
                type: file.type,
                params: { fileId: file.id, taskId, commentId },
            })),
        );

        setDownloadAllInProgress(false);
    }

    const getFileDownloadParams = React.useMemo(
        () =>
            (file: FileResponse): FileApiUploadParams => ({ taskId, commentId, fileId: file.id }),
        [],
    );

    return {
        renderControls,
        isExpanded,
        setIsExpanded,
        downloadAllInProgress,
        onDownloadAllButtonClick,
        getFileDownloadParams,
    };
}

function CommentFiles(props: CommentFilesProps): JSX.Element {
    const {
        renderControls,
        isExpanded,
        setIsExpanded,
        downloadAllInProgress,
        onDownloadAllButtonClick,
        getFileDownloadParams,
    } = useCommentFiles(props);
    const { files, loginnedUserIsAuthor, commentId } = props;

    return (
        <React.Fragment>
            {renderControls && (
                <div className={styles.commentFilesControls}>
                    <ExpandFilesButton
                        isExpanded={isExpanded}
                        onClick={() => setIsExpanded(!isExpanded)}
                        filesCount={files.length}
                    />

                    {downloadAllInProgress ? (
                        <div
                            className={styles.downloadAllFilesButton}
                            {...{
                                'qa-id': 'taskSidebarCommentsTabCommentDownloadAllInProgressMarker',
                            }}
                        >
                            <AnimatedEllipsis text="Загрузка" />
                        </div>
                    ) : (
                        <div
                            className={styles.downloadAllFilesButtonClickable}
                            onClick={onDownloadAllButtonClick}
                            {...{
                                'qa-id': 'taskSidebarCommentsTAbCommentDownloadAllFilesButton',
                            }}
                        >
                            скачать все файлы
                        </div>
                    )}
                </div>
            )}

            {isExpanded && (
                <div className={styles.commentFilesList}>
                    <FileCardsList
                        canEditInR7={loginnedUserIsAuthor}
                        files={files.map((file) => ({ ...file, containerName: commentId, parent: 'comment' }))}
                        getFileDownloadParams={getFileDownloadParams}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

function Preloader(): JSX.Element {
    return (
        <div className={styles.commentByDateGroup}>
            <div className={styles.commentByDateGroupTitleWrapper}>
                <div className={styles.commentByDateGroupTitle}>
                    <StaticSkeleton loadingClassName={styles.commentByDateGroupTitlePreloader} />
                </div>
            </div>

            <CommentPreloader />
            <CommentPreloader />
            <CommentPreloader />
        </div>
    );
}

function CommentPreloader(): JSX.Element {
    return (
        <div className={styles.comment}>
            <div className={styles.commentTitle}>
                <StaticSkeleton loadingClassName={styles.commentTitleFirstPrealoader} />
                <StaticSkeleton loadingClassName={styles.commentTitleSecondPreloader} />
                <StaticSkeleton loadingClassName={styles.commentTitleThirdPrelaoder} />
            </div>

            <div className={styles.commentText}>
                <StaticSkeleton loadingClassName={styles.commentTextFullLengthPreloader} />
                <StaticSkeleton loadingClassName={styles.commentTextFullLengthPreloader} />
                <StaticSkeleton loadingClassName={styles.commentTextHalfLengthPrelaoder} />
            </div>
        </div>
    );
}

function NewCommentPreloader(): JSX.Element {
    return (
        <div className={styles.newCommentPreloader}>
            <CommentPreloader />
        </div>
    );
}
