import * as lodash from 'lodash';
import * as moment from 'moment';

import {
    CreativeRequestTableItemInterface,
    CreativeRequestTableItemTaskInterface,
    CreativeRequestTableItemStatus,
    Dictionary,
    DictionaryType,
} from '@api';
import { TableNumberByType, TableType } from '@store/creative/types';
import { IconType } from 'sber-marketing-ui';
import { CreativeRequestStatus } from '@api';

export const enum ColumnHeaderType {
    Text = 'text',
    Filters = 'filters',
}

export const enum LineType {
    Creative = 'creative',
    Assignment = 'assignment',
    TariffTotal = 'tariffTotal',
}

export const enum ColumnName {
    SerialNumber = 'serialNumber',
    BitrixNumber = 'bitrixNumber',
    BriefCampaign = 'briefCampaign',
    CustomerName = 'customerName',
    CreativeName = 'creativeName',
    ExecutionId = 'executionId',
    Year = 'year',
    Block = 'block',
    ProtocolStatus = 'protocolStatus',
    ProtocolLimit = 'protocolLimit',
    Status = 'status',
    // Reserve = 'reserve',
    ApprovedFact = 'approvedFact',
    Fact = 'fact',
    ReserveFactDelta = 'reserveFactDelta',
    DocumentType = 'documentType',
    WorkEndDate = 'workEndDate',
    FactWithVat = 'factWithVat',
    AgencyName = 'agencyName',
    // SendingClosedActsDocumentsDate = 'sendingClosedActsDocumentsDate',
    Comment = 'comment',
    // ActsStatus = 'actsStatus',
    // Note = 'note',
    Initiator = 'initiator',
    Author = 'author',
    ConsolidatedName = 'consolidatedName',
    Preparation = 'preparation',
    Iteration = 'iteration',
    OnSign = 'onSign',
    InPayment = 'inPayment',
    Paid = 'paid',
}

export const enum CellType {
    LineHeader = 'lineHeader',
    Text = 'text',
    Input = 'input',
    Textarea = 'textarea',
    TextareaWithLink = 'textareaWithLink',
    FundsInput = 'funds_input',
    Select = 'select',
    FundsSelect = 'fundsSelect',
    Datepicker = 'datepicker',
    AddSubLine = 'addSubLine',
}

export interface AccessorParams {
    id: string;
    line: CreativeRequestTableItemInterface;
    subLine: CreativeRequestTableItemTaskInterface;
    allSubLines: CreativeRequestTableItemTaskInterface[];
    dictionariesByType: Partial<Record<DictionaryType, Dictionary[]>>;
}

type Accessor<T> = (params: AccessorParams) => Promise<T>;
type AccessorWithValue<T> = (params: AccessorParams, value: any) => Promise<T>;

export type ValueAccessor = Accessor<any>;
export type TitleAccessor = Accessor<React.ReactText>;
export type ValueSetter = AccessorWithValue<void>;
export type ItemsAccessor = Accessor<any[]>;
export type DescriptionAccessor = Accessor<string>;
export type ReadOnlyAccessor = Accessor<boolean>;
export type CustomStyleAccessor = Accessor<React.CSSProperties>;
export type ValidationAccessor = AccessorWithValue<boolean>;
export type SuggestItemsAccessor = Accessor<string[]>;
export type IconAccessor = Accessor<{ type: IconType; color: string; size: number }>;

export interface ColumnParams {
    title: string;
    headerType: ColumnHeaderType;
    type: Partial<Record<LineType, CellType>>;
    disableFilters?: Partial<Record<LineType, boolean>>;
    defaultWidth: number;
    disableWidthChange?: boolean;
    readOnly?: Partial<Record<LineType, ReadOnlyAccessor | boolean>> | ReadOnlyAccessor | boolean;
    customStyle?: Partial<Record<LineType, CustomStyleAccessor>> | CustomStyleAccessor;
    linkedColumns?: Partial<Record<LineType, ColumnName[]>>;
    getTitle?: Partial<Record<LineType, TitleAccessor>>;
    getValue: Partial<Record<LineType, ValueAccessor>> | ValueAccessor;
    setValue?: Partial<Record<LineType, ValueSetter>>;
    getItems?: Partial<Record<LineType, ItemsAccessor>> | ItemsAccessor;
    getSuggestItems?: Partial<Record<LineType, SuggestItemsAccessor>> | SuggestItemsAccessor;
    getDescription?: Partial<Record<LineType, DescriptionAccessor>> | DescriptionAccessor;
    getIcon?: Partial<Record<LineType, IconAccessor>> | IconAccessor;
}

export const ColumnsConfig: { [columnName: string]: ColumnParams } = {
    [ColumnName.SerialNumber]: {
        title: '№',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.LineHeader,
            [LineType.Assignment]: CellType.LineHeader,
            [LineType.TariffTotal]: CellType.LineHeader,
        },
        customStyle: {
            [LineType.Creative]: async (params: AccessorParams) => ({
                fontWeight: 600,
            }),
        },
        defaultWidth: 65,
        disableWidthChange: true,
        readOnly: true,
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => `${params.line.number}`,
            [LineType.Assignment]: async (params: AccessorParams) => {
                const lineIsManual = !params.line.creativeRequestId;

                if (lineIsManual) {
                    return `${params.line.number}.${params.subLine.number}`;
                }

                const tableType = (await params.subLine.creativeRequestGroup).value;
                const serialNumber = lineIsManual ? params.subLine.number : params.subLine.creativeRequestItemNumber;

                return `${params.line.number}.${TableNumberByType[tableType]}.${serialNumber}`;
            },
            [LineType.TariffTotal]: async (params: AccessorParams) =>
                `${params.line.number}.${TableNumberByType[TableType.Tariff]}`,
        },
    },
    [ColumnName.BitrixNumber]: {
        title: '№ проекта в Битрикс',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.AddSubLine,
            [LineType.Assignment]: CellType.Input,
        },
        disableFilters: {
            [LineType.Creative]: true,
        },
        defaultWidth: 85,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setBitrixProject,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => null,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.bitrixProject?.number || null,
        },
        setValue: {
            [LineType.Creative]: null,
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                params.subLine.setBitrixProject({ bitrixProject: { number: value, link: '' } });
            },
        },
    },
    [ColumnName.BriefCampaign]: {
        title: '№ Кампании при рассылке брифа',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Input,
            [LineType.Assignment]: CellType.Input,
        },
        defaultWidth: 105,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setBriefCampaign,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setBriefCampaign,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.briefCampaign,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.briefCampaign,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setBriefCampaign({ briefCampaign: value });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setBriefCampaign({ briefCampaign: value });
            },
        },
    },
    [ColumnName.CustomerName]: {
        title: 'Инициатор кампании',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Input,
            [LineType.Assignment]: CellType.Input,
        },
        defaultWidth: 180,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setCustomer,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setCustomer,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.customer,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.customer,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setCustomer({ customer: value });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setCustomer({ customer: value });
            },
        },
    },
    [ColumnName.CreativeName]: {
        title: 'Название заявки на креатив / поручения',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.TextareaWithLink,
            [LineType.Assignment]: CellType.TextareaWithLink,
        },
        defaultWidth: 200,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setName,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setName,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.name,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.name,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setName({ name: value });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setName({ name: value });
            },
        },
    },
    [ColumnName.ExecutionId]: {
        title: 'ID исп.',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Input,
            [LineType.Assignment]: CellType.Input,
            [LineType.TariffTotal]: CellType.Text,
        },
        defaultWidth: 90,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setExecutionId,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setExecutionId,
            [LineType.TariffTotal]: true,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.executionId,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.executionId,
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                const creativeRequestGroups = await Promise.all(
                    params.allSubLines.map((item) => item.creativeRequestGroup),
                );

                const tariffSubLines = params.allSubLines.filter(
                    (item, index) =>
                        item.itemId === params.line.id && creativeRequestGroups[index]?.value === TableType.Tariff,
                );

                const executionIds = lodash.uniq(tariffSubLines.map((item) => item.executionId));
                const sortedExecutionIds = lodash.sortBy(executionIds, (item) => parseInt(item, 10));

                return lodash.compact(sortedExecutionIds).join(', ');
            },
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setExecutionId({ executionId: value });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setExecutionId({ executionId: value });
            },
        },
    },
    [ColumnName.Year]: {
        title: 'Год',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Select,
            [LineType.Assignment]: CellType.Select,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setYear,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setYear,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.year,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.year,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setYear({ year: value });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setYear({ year: value });
            },
        },
        getItems: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const selectedYear: number = null;

                const currentYear = new Date(Date.now()).getFullYear();

                const years = lodash
                    .compact(lodash.uniq([selectedYear, currentYear, currentYear - 1, currentYear + 1]))
                    .sort();

                return years.map((item) => ({
                    title: item,
                    value: item,
                }));
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const selectedYear: number = null;

                const currentYear = new Date(Date.now()).getFullYear();

                const years = lodash
                    .compact(lodash.uniq([selectedYear, currentYear, currentYear - 1, currentYear + 1]))
                    .sort();

                return years.map((item) => ({
                    title: item,
                    value: item,
                }));
            },
        },
    },
    [ColumnName.Block]: {
        title: 'Блок',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Select,
            [LineType.Assignment]: CellType.Select,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setBlock,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setBlock,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => (await params.line.block)?.id || null,
            [LineType.Assignment]: async (params: AccessorParams) => (await params.subLine.block)?.id || null,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                const dictionaries = params.dictionariesByType[DictionaryType.Block];
                const dictionary = dictionaries.find((item) => item.id == value);

                await params.line.setBlock({ block: dictionary });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                const dictionaries = params.dictionariesByType[DictionaryType.Block];
                const dictionary = dictionaries.find((item) => item.id == value);

                await params.subLine.setBlock({ block: dictionary });
            },
        },
        getItems: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const dictionaries = params.dictionariesByType[DictionaryType.Block];

                return dictionaries.map((item) => ({
                    title: item.value,
                    value: item.id,
                }));
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const dictionaries = params.dictionariesByType[DictionaryType.Block];

                return dictionaries.map((item) => ({
                    title: item.value,
                    value: item.id,
                }));
            },
        },
    },
    [ColumnName.ProtocolStatus]: {
        title: 'Статус протокола',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Select,
            [LineType.Assignment]: null,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setProtocolStatus,
            [LineType.Assignment]: async (params: AccessorParams) => true,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.protocolStatus,
            [LineType.Assignment]: null,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setProtocolStatus({ protocolStatus: value });
            },
            [LineType.Assignment]: null,
        },
        getItems: {
            [LineType.Creative]: async (params: AccessorParams) => {
                return [
                    { title: 'Согласован', value: 'Согласован' },
                    { title: 'Не согласован', value: 'Не согласован' },
                ];
            },
            [LineType.Assignment]: null,
        },
    },
    [ColumnName.ProtocolLimit]: {
        title: 'Лимит по протоколу',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: null,
        },
        disableFilters: {
            [LineType.Assignment]: true,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setProtocolLimit,
            [LineType.Assignment]: async (params: AccessorParams) => true,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => formatMoney(params.line.protocolLimit),
            [LineType.Assignment]: null,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setProtocolLimit({ protocolLimit: formatMoneyInput(value) });
            },
            [LineType.Assignment]: null,
        },
    },
    [ColumnName.Status]: {
        title: 'Статус ЗНК и поручений',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Select,
            [LineType.Assignment]: CellType.Select,
        },
        defaultWidth: 160,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setStatus,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setStatus,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.status?.value || null,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.status?.value || null,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setStatus({
                    status: {
                        value,
                        date: new Date().toISOString(),
                    },
                });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setStatus({
                    status: {
                        value,
                        date: new Date().toISOString(),
                    },
                });
            },
        },
        getItems: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const additionalStatus = [];
                if (!params?.line?.creativeRequestId) {
                    additionalStatus.push({
                        title: 'Правки к ЗНК',
                        value: 'returnedForRework',
                    });
                }

                return [
                    { title: 'Создание заявки', value: CreativeRequestStatus.draft },
                    { title: 'Заполнение на стороне заказчика', value: CreativeRequestStatus.fillingByCustomer },
                    { title: 'Заполнение на стороне агентства', value: CreativeRequestStatus.fillingByAgency },
                    { title: 'Проверка заказчиком', value: CreativeRequestStatus.checkingByCustomer },
                    ...additionalStatus,
                    { title: 'Согласование строк', value: CreativeRequestStatus.approvement },
                    { title: 'Согласование актов', value: CreativeRequestStatus.actClosing },
                    { title: 'Заявка закрыта', value: CreativeRequestStatus.closed },
                ];
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                return [
                    {
                        title: 'Сбор информации в Агентстве',
                        value: 'actInfoCollecting',
                    },
                    {
                        title: 'Подготовка документов в Агентстве',
                        value: 'actDocsPreparation',
                    },
                    {
                        title: 'Отправлен в ПАО',
                        value: 'actSentToSb',
                    },
                    {
                        title: 'Доработка по комментарию',
                        value: 'actRevision',
                    },
                    {
                        title: 'На подписи',
                        value: 'actSignAwaited',
                    },
                    {
                        title: 'В оплате',
                        value: 'actPaymentAwaited',
                    },
                    {
                        title: 'Оплачено',
                        value: 'actPaid',
                    },
                ];
            },
        },
        getDescription: {
            [LineType.Creative]: async (params: AccessorParams) => formatDate(params.line.status?.date),
        },
    },
    // [ColumnName.Reserve]: {
    //     title: 'Сумма резерва по ID исполнения (без НДС)',
    //     headerType: ColumnHeaderType.Filters,
    //     type: {
    //         [LineType.Creative]: CellType.FundsInput,
    //         [LineType.Assignment]: null,
    //     },
    //     disableFilters: {
    //         [LineType.Assignment]: true,
    //     },
    //     defaultWidth: 140,
    //     readOnly: {
    //         [LineType.Creative]: async (params: AccessorParams) => !params.line.setReserve,
    //         [LineType.Assignment]: true,
    //     },
    //     getValue: {
    //         [LineType.Creative]: async (params: AccessorParams) => formatMoney(params.line.reserve),
    //         [LineType.Assignment]: null,
    //     },
    //     setValue: {
    //         [LineType.Creative]: async (params: AccessorParams, value: any) => {
    //             await params.line.setReserve({ reserve: formatMoneyInput(value) });
    //         },
    //         [LineType.Assignment]: null,
    //     },
    // },
    [ColumnName.ApprovedFact]: {
        title: 'Согласованная факт. ст-сть ЗНК',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        disableFilters: {
            [LineType.Assignment]: true,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setActualCostApproved,
            [LineType.Assignment]: true,
            [LineType.TariffTotal]: true,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => formatMoney(params.line.actualCostApproved),
            [LineType.Assignment]: async (params: AccessorParams) => {
                const lineIsManual = !params.line.creativeRequestId;

                if (lineIsManual) {
                    return undefined;
                }

                return formatMoney(params.subLine.actualCostApproved);
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                return lodash.sumBy(tariffSubLines, (item) => formatMoney(item.actualCostApproved));
            },
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setActualCostApproved({ actualCostApproved: formatMoneyInput(value) });
            },
            [LineType.Assignment]: null,
        },
    },
    [ColumnName.Fact]: {
        title: 'Сумма факта по ID исполнения (без НДС)',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setFact,
            [LineType.TariffTotal]: true,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                return lodash.sumBy(subLines, (item) => formatMoney(item.fact));
            },
            [LineType.Assignment]: async (params: AccessorParams) => formatMoney(params.subLine.fact),
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                return lodash.sumBy(tariffSubLines, (item) => formatMoney(item.fact));
            },
        },
        setValue: {
            [LineType.Creative]: null,
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setFact({ fact: formatMoneyInput(value) });
            },
        },
        getIcon: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const lotDictionary = params.line.dictionary.lot;

                const lot = parseInt(lodash.last(lotDictionary.value.split(' ')), 10);

                if (lot === 2) {
                    const subLines = params.allSubLines.filter((item) => !item.isArchived);

                    const fact = lodash.sumBy(subLines, (item) => item.fact);
                    const protocolLimit = params.line.protocolLimit;

                    return fact > protocolLimit ? { type: IconType.EXPIRED_TASK, color: '#e63900', size: 16 } : null;
                }

                return null;
            },
        },
    },
    [ColumnName.ReserveFactDelta]: {
        title: 'Дельта (Согл. ст-сть – Факт, без НДС)',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        disableFilters: {
            [LineType.Assignment]: true,
        },
        defaultWidth: 150,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.TariffTotal]: true,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const actualCostApproved = formatMoney(params.line.actualCostApproved) || 0;

                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                const fact = lodash.sumBy(subLines, (item) => formatMoney(item.fact)) || 0;
                const roundedFact = parseFloat(fact.toFixed(2));

                return actualCostApproved - roundedFact;
            },
            [LineType.Assignment]: null,
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);
                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                const actualCostApproved =
                    lodash.sumBy(tariffSubLines, (item) => formatMoney(item.actualCostApproved)) || 0;
                const fact = lodash.sumBy(tariffSubLines, (item) => formatMoney(item.fact)) || 0;
                const roundedFact = parseFloat(fact.toFixed(2));

                return actualCostApproved - roundedFact;
            },
        },
        getIcon: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const actualCostApproved = formatMoney(params.line.actualCostApproved) || 0;

                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                const fact = lodash.sumBy(subLines, (item) => formatMoney(item.fact)) || 0;
                const roundedFact = parseFloat(fact.toFixed(2));

                const value = actualCostApproved - roundedFact;

                return value < 0 ? { type: IconType.EXPIRED_TASK, color: '#e63900', size: 16 } : null;
            },
        },
    },
    [ColumnName.DocumentType]: {
        title: 'Тип документа',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: null,
            [LineType.Assignment]: CellType.Input,
        },
        disableFilters: {
            [LineType.Creative]: true,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setDocument,
        },
        getValue: {
            [LineType.Creative]: null,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.document,
        },
        setValue: {
            [LineType.Creative]: null,
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setDocument({ document: value });
            },
        },
        getSuggestItems: {
            [LineType.Assignment]: async (params: AccessorParams) => ['Тарифы', 'Дополнение', 'Поручение'],
        },
    },
    [ColumnName.WorkEndDate]: {
        title: 'Срок окончания работ',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: null,
            [LineType.Assignment]: CellType.Datepicker,
        },
        disableFilters: {
            [LineType.Creative]: true,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setWorkEndDate,
        },
        getValue: {
            [LineType.Creative]: null,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.workEndDate,
        },
        setValue: {
            [LineType.Creative]: null,
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setWorkEndDate({ workEndDate: value });
            },
        },
    },
    [ColumnName.FactWithVat]: {
        title: 'Сумма факта (с НДС)',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsSelect,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        disableFilters: {
            [LineType.Creative]: true,
        },
        defaultWidth: 140,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setVat,
        },
        getTitle: {
            [LineType.Creative]: async (params: AccessorParams) => null,
            [LineType.Assignment]: async (params: AccessorParams) => {
                const vat = params.subLine.vat !== null ? params.subLine.vat : null;

                return formatMoney(params.subLine.fact * (1 + vat / 100));
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => null,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                return lodash.sumBy(subLines, (item) => {
                    const vat = item.vat !== null ? item.vat : null;

                    return formatMoney(item.fact * (1 + vat / 100));
                });
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                return params.subLine.vat !== null ? params.subLine.vat : null;
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                return lodash.sumBy(tariffSubLines, (item) => {
                    const vat = item.vat !== null ? item.vat : null;

                    return formatMoney(item.fact * (1 + vat / 100));
                });
            },
        },
        getDescription: {
            [LineType.Assignment]: async (params: AccessorParams) => {
                const vat = params.subLine.vat !== null ? params.subLine.vat : null;

                return vat !== null ? `НДС ${vat}%` : null;
            },
        },
        setValue: {
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setVat({ vat: value });
            },
        },
        getItems: {
            [LineType.Assignment]: async (params: AccessorParams) => [
                { title: '0%', value: 0 },
                { title: '20%', value: 20 },
            ],
        },
    },
    [ColumnName.AgencyName]: {
        title: 'Название агентства',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Input,
            [LineType.Assignment]: CellType.Input,
        },
        defaultWidth: 180,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setAgency,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setAgency,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.agency,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.agency,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setAgency({ agency: value });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setAgency({ agency: value });
            },
        },
    },
    // [ColumnName.SendingClosedActsDocumentsDate]: {
    //     title: 'Дата отправки документов на закрытие актов',
    //     headerType: ColumnHeaderType.Filters,
    //     type: {
    //         [LineType.Creative]: null,
    //         [LineType.Assignment]: CellType.Datepicker,
    //     },
    //     disableFilters: {
    //         [LineType.Creative]: true,
    //     },
    //     defaultWidth: 120,
    //     getValue: {
    //         [LineType.Creative]: null,
    //         [LineType.Assignment]: async (params: AccessorParams) => params.subLine.sendingClosedActsDocumentsDate,
    //     },
    //     setValue: {
    //         [LineType.Creative]: null,
    //         [LineType.Assignment]: async (params: AccessorParams, value: any) => {
    //             await params.subLine.setSendingClosedActsDocumentsDate({ sendingClosedActsDocumentsDate: value });
    //         },
    //     },
    // },
    [ColumnName.Comment]: {
        title: 'Комментарий к статусу ЗНК / закрывающих документов',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Input,
            [LineType.Assignment]: CellType.Input,
        },
        defaultWidth: 240,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setComment,
            [LineType.Assignment]: async (params: AccessorParams) => !params.subLine.setComment,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.comment,
            [LineType.Assignment]: async (params: AccessorParams) => params.subLine.comment,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setComment({ comment: value });
            },
            [LineType.Assignment]: async (params: AccessorParams, value: any) => {
                await params.subLine.setComment({ comment: value });
            },
        },
    },
    // [ColumnName.ActsStatus]: {
    //     title: 'Статус актов по поручению',
    //     headerType: ColumnHeaderType.Filters,
    //     type: {
    //         [LineType.Creative]: null,
    //         [LineType.Assignment]: CellType.Input,
    //     },
    //     disableFilters: {
    //         [LineType.Creative]: true,
    //     },
    //     defaultWidth: 120,
    //     getValue: {
    //         [LineType.Creative]: null,
    //         [LineType.Assignment]: async (params: AccessorParams) => params.subLine.actsStatus,
    //     },
    //     setValue: {
    //         [LineType.Creative]: null,
    //         [LineType.Assignment]: async (params: AccessorParams, value: any) => {
    //             await params.subLine.setActsStatus({ actsStatus: value });
    //         },
    //     },
    // },
    // [ColumnName.Note]: {
    //     title: 'Примечание',
    //     headerType: ColumnHeaderType.Text,
    //     type: {
    //         [LineType.Creative]: CellType.Input,
    //         [LineType.Assignment]: CellType.Input,
    //     },
    //     defaultWidth: 120,
    //     getValue: {
    //         [LineType.Creative]: async (params: AccessorParams) => params.line.note,
    //         [LineType.Assignment]: async (params: AccessorParams) => params.subLine.note,
    //     },
    //     setValue: {
    //         [LineType.Creative]: async (params: AccessorParams, value: any) => {
    //             await params.line.setNote({ note: value });
    //         },
    //         [LineType.Assignment]: async (params: AccessorParams, value: any) => {
    //             await params.subLine.setNote({ note: value });
    //         },
    //     },
    // },
    [ColumnName.Initiator]: {
        title: 'Инициатор ЗНК',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Input,
            [LineType.Assignment]: null,
        },
        disableFilters: {
            [LineType.Assignment]: true,
        },
        defaultWidth: 120,
        readOnly: {
            [LineType.Creative]: async (params: AccessorParams) => !params.line.setInitiator,
            [LineType.Assignment]: async (params: AccessorParams) => true,
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => params.line.initiator,
            [LineType.Assignment]: null,
        },
        setValue: {
            [LineType.Creative]: async (params: AccessorParams, value: any) => {
                await params.line.setInitiator({ initiator: value });
            },
            [LineType.Assignment]: null,
        },
    },
    [ColumnName.Author]: {
        title: 'Автор строки ЗНК / поручения',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Text,
            [LineType.Assignment]: CellType.Text,
        },
        defaultWidth: 140,
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const { author } = params.line;

                return author ? `${author?.secondName} ${author?.firstName}` : null;
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const { author } = params.subLine;

                return author ? `${author?.secondName} ${author?.firstName}` : null;
            },
        },
        getDescription: {
            [LineType.Creative]: async (params: AccessorParams) => formatDate(params.line.createdAt),
            [LineType.Assignment]: async (params: AccessorParams) => formatDate(params.subLine.createdAt),
        },
    },
    [ColumnName.ConsolidatedName]: {
        title: 'Сводное название',
        headerType: ColumnHeaderType.Filters,
        type: {
            [LineType.Creative]: CellType.Text,
            [LineType.Assignment]: CellType.Text,
        },
        defaultWidth: 250,
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const { briefCampaign, customer, name } = params.line;

                return lodash.compact([briefCampaign, customer, name]).join(' ');
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const { briefCampaign, customer, name } = params.subLine;

                return lodash.compact([briefCampaign, customer, name]).join(' ');
            },
        },
    },
    [ColumnName.Preparation]: {
        title: 'Подготовка',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        defaultWidth: 115,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: true,
        },
        customStyle: {
            [LineType.Creative]: async (params: AccessorParams) => ({
                fontWeight: 600,
            }),
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actInfoCollecting' || status === 'actDocsPreparation'
                        ? formatMoney(item.fact)
                        : 0;
                });

                return sum || undefined;
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const status = params.subLine.status?.value;

                if (status === 'actInfoCollecting' || status === 'actDocsPreparation') {
                    return formatMoney(params.subLine.fact);
                }

                return undefined;
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(tariffSubLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actInfoCollecting' || status === 'actDocsPreparation'
                        ? formatMoney(item.fact)
                        : 0;
                });

                return sum || undefined;
            },
        },
        getDescription: {
            [LineType.Assignment]: async (params: AccessorParams) => formatDate(params.line.status?.date),
        },
    },
    [ColumnName.Iteration]: {
        title: 'Итеррирование',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        defaultWidth: 115,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: true,
        },
        customStyle: {
            [LineType.Creative]: async (params: AccessorParams) => ({
                fontWeight: 600,
            }),
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actSentToSb' || status === 'actRevision' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const status = params.subLine.status?.value;

                if (status === 'actSentToSb' || status === 'actRevision') {
                    return formatMoney(params.subLine.fact);
                }

                return undefined;
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(tariffSubLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actSentToSb' || status === 'actRevision' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
        },
        getDescription: {
            [LineType.Assignment]: async (params: AccessorParams) => formatDate(params.line.status?.date),
        },
    },
    [ColumnName.OnSign]: {
        title: 'На подписи',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        defaultWidth: 115,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: true,
        },
        customStyle: {
            [LineType.Creative]: async (params: AccessorParams) => ({
                fontWeight: 600,
            }),
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actSignAwaited' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const status = params.subLine.status?.value;

                if (status === 'actSignAwaited') {
                    return formatMoney(params.subLine.fact);
                }

                return undefined;
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(tariffSubLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actSignAwaited' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
        },
        getDescription: {
            [LineType.Assignment]: async (params: AccessorParams) => formatDate(params.line.status?.date),
        },
    },
    [ColumnName.InPayment]: {
        title: 'В оплате',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        defaultWidth: 115,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: true,
        },
        customStyle: {
            [LineType.Creative]: async (params: AccessorParams) => ({
                fontWeight: 600,
            }),
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actPaymentAwaited' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const status = params.subLine.status?.value;

                if (status === 'actPaymentAwaited') {
                    return formatMoney(params.subLine.fact);
                }

                return undefined;
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(tariffSubLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actPaymentAwaited' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
        },
        getDescription: {
            [LineType.Assignment]: async (params: AccessorParams) => formatDate(params.line.status?.date),
        },
    },
    [ColumnName.Paid]: {
        title: 'Оплачено',
        headerType: ColumnHeaderType.Text,
        type: {
            [LineType.Creative]: CellType.FundsInput,
            [LineType.Assignment]: CellType.FundsInput,
            [LineType.TariffTotal]: CellType.FundsInput,
        },
        defaultWidth: 115,
        readOnly: {
            [LineType.Creative]: true,
            [LineType.Assignment]: true,
        },
        customStyle: {
            [LineType.Creative]: async (params: AccessorParams) => ({
                fontWeight: 600,
            }),
        },
        getValue: {
            [LineType.Creative]: async (params: AccessorParams) => {
                const subLines = params.allSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(subLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actPaid' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
            [LineType.Assignment]: async (params: AccessorParams) => {
                const status = params.subLine.status?.value;

                if (status === 'actPaid') {
                    return formatMoney(params.subLine.fact);
                }

                return undefined;
            },
            [LineType.TariffTotal]: async (params: AccessorParams) => {
                let tariffSubLines = await filterSubLinesByTableType(params.allSubLines, TableType.Tariff);

                tariffSubLines = tariffSubLines.filter((item) => !item.isArchived);

                const sum = lodash.sumBy(tariffSubLines, (item) => {
                    const status = item.status?.value;

                    return status === 'actPaid' ? formatMoney(item.fact) : 0;
                });

                return sum || undefined;
            },
        },
        getDescription: {
            [LineType.Assignment]: async (params: AccessorParams) => formatDate(params.line.status?.date),
        },
    },
};

export const lot1LeftFixedColumns: ColumnName[] = [
    ColumnName.SerialNumber,
    ColumnName.BitrixNumber,
    ColumnName.CreativeName,
];

export const lot1tableColumns: ColumnName[] = [
    ColumnName.ExecutionId,
    ColumnName.Year,
    ColumnName.Status,
    ColumnName.ApprovedFact,
    ColumnName.Fact,
    ColumnName.ReserveFactDelta,
    ColumnName.DocumentType,
    ColumnName.WorkEndDate,
    // ColumnName.FactWithVat,
    // ColumnName.CustomerName,
    // ColumnName.SendingClosedActsDocumentsDate,
    ColumnName.Comment,
    // ColumnName.ActsStatus,
    // ColumnName.Note,
    ColumnName.Initiator,
    ColumnName.Author,
    ColumnName.FactWithVat,
    ColumnName.Preparation,
    ColumnName.Iteration,
    ColumnName.OnSign,
    ColumnName.InPayment,
    ColumnName.Paid,
];

export const lot2LeftFixedColumns: ColumnName[] = [
    ColumnName.SerialNumber,
    ColumnName.BitrixNumber,
    ColumnName.BriefCampaign,
    ColumnName.CustomerName,
    ColumnName.CreativeName,
];

export const lot2tableColumns: ColumnName[] = [
    ColumnName.ExecutionId,
    ColumnName.Year,
    ColumnName.Block,
    ColumnName.ProtocolStatus,
    ColumnName.ProtocolLimit,
    ColumnName.Status,
    ColumnName.ApprovedFact,
    ColumnName.ReserveFactDelta,
    ColumnName.Fact,
    ColumnName.DocumentType,
    ColumnName.WorkEndDate,
    ColumnName.FactWithVat,
    ColumnName.AgencyName,
    // ColumnName.SendingClosedActsDocumentsDate,
    ColumnName.Comment,
    // ColumnName.ActsStatus,
    // ColumnName.Note,
    ColumnName.Initiator,
    ColumnName.Author,
    ColumnName.ConsolidatedName,
    ColumnName.Preparation,
    ColumnName.Iteration,
    ColumnName.OnSign,
    ColumnName.InPayment,
    ColumnName.Paid,
];

export const rightFixedColumns: ColumnName[] = [];

async function filterSubLinesByTableType(subLines: CreativeRequestTableItemTaskInterface[], tableType: TableType) {
    const creativeRequestGroupsBySubLineId = lodash.zipObject(
        subLines.map((item) => item.id),
        await Promise.all(subLines.map((item) => item.creativeRequestGroup)),
    );

    return subLines.filter((item) => creativeRequestGroupsBySubLineId[item.id]?.value === tableType);
}

function formatDate(date: string | Date): string {
    if (!date) {
        return null;
    }

    return moment(date).format('DD.MM.YYYY');
}

function formatMoney(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    return parsedValue / 100;
}

function formatMoneyInput(value: React.ReactText): number {
    if (!value) {
        return 0;
    }

    const parsedValue = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;

    return Math.round(parsedValue * 100);
}
