// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-ActionsMenu-Dropdown-DropdownContent-List-_styles__icon{color:#7E8681}.src-client-modules-activity-pages-creative-ActionsMenu-Dropdown-DropdownContent-List-_styles__title{font-family:'Open Sans';font-weight:400;font-size:12px;line-height:16px;color:#000000}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/ActionsMenu/Dropdown/DropdownContent/List/styles.scss"],"names":[],"mappings":"AAEA,oGACI,aAAc,CACjB,qGAGG,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc","sourcesContent":[".root {}\n\n.icon {\n    color: #7E8681;\n}\n\n.title {\n    font-family: 'Open Sans';\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "src-client-modules-activity-pages-creative-ActionsMenu-Dropdown-DropdownContent-List-_styles__icon",
	"title": "src-client-modules-activity-pages-creative-ActionsMenu-Dropdown-DropdownContent-List-_styles__title"
};
export default ___CSS_LOADER_EXPORT___;
