import * as React from 'react';
import { useSelector } from 'react-redux';

import * as style from './ExecitionIdWidget.scss';

import { SourceIdTable } from './SourceIdTable';
import { ExecitionIdTable } from './ExecitionIdTable';
import { Preloader } from './Preloader';
import { getUserRoles } from '@store/user/selector';
import { useClientData } from './hooks';
import { NewExecutionIdModal, PlanTransferModal, CreateReserve, Itemtype } from './modals';

export const ExecitionIdWidget: React.FC = () => {
    const [visibleMenu, setVisibleMenu] = React.useState<'createId' | 'planModal' | 'reserveModal'>(null);
    const [modalInitParams, setModalInitParams] = React.useState<{ lineId: string; capex: boolean }>(null);

    const userRoles = useSelector(getUserRoles);

    const clientData = useClientData();

    const {
        loading,
        creativeRequest,
        creativeRequestItems,
        groupedCreativeRequestBudgetItems,
        refetchCreativeRequestBudgetItems,
        projectBudgetItems,
        groupedProjectBudgetItems,
        refetchProjectBudgetItems,
        refetchCorrections,
    } = clientData;

    function onModalOpen(
        modalType: 'createId' | 'planModal' | 'reserveModal',
        modalInitParams?: { lineId: string; capex: boolean },
    ) {
        setVisibleMenu(modalType);
        setModalInitParams(modalInitParams);
    }

    function onModalClose() {
        setVisibleMenu(null);
        setModalInitParams(null);
    }

    function onModalSave() {
        refetchCorrections();
        refetchCreativeRequestBudgetItems();
        refetchProjectBudgetItems();
    }

    return (
        <div className={style.root}>
            {!loading && (
                <>
                    <SourceIdTable clientData={clientData} userRoles={userRoles} />
                    <ExecitionIdTable clientData={clientData} userRoles={userRoles} onModalOpen={onModalOpen} />
                    <SourceIdTable capex clientData={clientData} userRoles={userRoles} />
                    <ExecitionIdTable capex clientData={clientData} userRoles={userRoles} onModalOpen={onModalOpen} />

                    {visibleMenu === 'createId' && <NewExecutionIdModal onClose={onModalClose} />}

                    {visibleMenu === 'planModal' && (
                        <PlanTransferModal
                            sourceBudgetItems={
                                groupedCreativeRequestBudgetItems[modalInitParams.capex ? 'capex' : 'opex']
                            }
                            executionBudgetItems={groupedProjectBudgetItems[modalInitParams.capex ? 'capex' : 'opex']}
                            initialExecutorItemId={modalInitParams.lineId}
                            creativeRequest={creativeRequest}
                            creativeRequestItems={creativeRequestItems}
                            budgetItemType={modalInitParams.capex ? Itemtype.CAPEX : Itemtype.OPEX}
                            onSave={onModalSave}
                            onClose={onModalClose}
                        />
                    )}

                    {visibleMenu === 'reserveModal' && (
                        <CreateReserve
                            executorItemsList={projectBudgetItems}
                            initialExecutorItemId={modalInitParams?.lineId}
                            creativeRequest={creativeRequest}
                            creativeRequestItems={creativeRequestItems}
                            onSave={onModalSave}
                            onClose={onModalClose}
                        />
                    )}
                </>
            )}

            {loading && <Preloader />}
        </div>
    );
};
