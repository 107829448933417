// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-ErrorMessage-_ErrorMessage__root{margin-top:16px;padding:12px;width:100%;display:flex;align-items:center;justify-content:center;gap:8px;border-radius:4px;font-family:'Open Sans';font-size:14px;font-weight:400;line-height:19px;color:black;background:#FFEBEE}.src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-ErrorMessage-_ErrorMessage__icon{color:#E63900}\n", "",{"version":3,"sources":["webpack://./src/client/modules/budget/BudgetPage/BudgetExecution/FMPTableImportMenu/ErrorMessage/ErrorMessage.scss"],"names":[],"mappings":"AAAA,0GACI,eAAgB,CAChB,YAAa,CACb,UAAW,CAEX,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,OAAQ,CAER,iBAAkB,CAElB,uBAAwB,CACxB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,WAAY,CAEZ,kBAAmB,CACtB,0GAGG,aAAc","sourcesContent":[".root {\n    margin-top: 16px;\n    padding: 12px;\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n\n    border-radius: 4px;\n\n    font-family: 'Open Sans';\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 19px;\n    color: black;\n\n    background: #FFEBEE;\n}\n\n.icon {\n    color: #E63900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-ErrorMessage-_ErrorMessage__root",
	"icon": "src-client-modules-budget-BudgetPage-BudgetExecution-FMPTableImportMenu-ErrorMessage-_ErrorMessage__icon"
};
export default ___CSS_LOADER_EXPORT___;
