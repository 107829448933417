import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import type { UserCard } from 'sber-marketing-types/frontend';

import { useGetUserCardsQuery, useGetWorkTypesQuery } from '@api';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { useDefaultState } from '@common/hooks';
import { useUserVacation } from '@modules/user/hooks';

import { InputRenderProps, OptionProps, useFormField } from '@common/components';
import { UserWorkTypeChip } from '@modules/workType/components';
import { UserSelectorProps, WorkTypeUserOption } from '@modules/user/components';

import { UserSelector } from '../UserSelector';

import * as styles from './WorkTypeUserFormSelector.scss';

export interface WorkTypeUserSelectorProps
    extends Omit<UserSelectorProps<false>, 'renderOption' | 'selected' | 'onSelect'> {
    selected?: [string, number];
    workTypeFieldName?: string;
    userFieldName?: string;
    onSelect?: (workTypeId: string, userId: number) => void;
}

export const WorkTypeUserFormSelector: React.FC<WorkTypeUserSelectorProps> = ({
    selected,
    onSelect,
    className,
    children,
    workTypeFieldName,
    userFieldName,
    organizationIds,
    error,
    inputRef,
    required,
    search: searchProp,
    clearable: clearableProp,
    loading,
    onClear,
    onSearch,
    ...props
}) => {
    const organizationId = useSelector((state: StoreState) => getLoginUser(state).attributes.organizationId);

    const [focus, setFocus] = React.useState(false);
    const [clearable, setClearable] = useDefaultState(
        clearableProp,
        (clearable: boolean) => {
            if (clearable) {
                onClear?.();
            }
        },
        false,
    );
    const { isLoading: isVacationLoading, isVacation, description } = useUserVacation(selected[1], !selected[1]);
    const [search, setSearch] = useDefaultState(searchProp, onSearch, '');
    const usersDropdownDivRef = React.useRef<HTMLDivElement>();
    const isLoading = loading || isVacationLoading;

    useGetUserCardsQuery({ hasWorkTypes: true });
    useGetWorkTypesQuery({ isArchived: false });

    React.useEffect(() => {
        setClearable(Boolean(selected[0] || selected[1]));
    }, [selected]);

    const { errorMessage: userErrorMessage, inputRef: userInputRef } = useFormField({
        name: userFieldName,
        value: selected[1],
        inputRef,
        required,
    });

    const { errorMessage: workTypeErrorMessage } = useFormField({
        name: workTypeFieldName,
        value: selected[0],
        inputRef: userInputRef,
        required,
    });

    const handleSelect = (workTypeId: string, userId: number) => {
        onSelect?.(workTypeId, userId);
    };

    const handleClear = () => {
        onSelect?.(null, null);
        setSearch('');
        userInputRef.current?.focus();
    };

    const renderOption = ({ value, ...props }: OptionProps<UserCard>) => (
        <WorkTypeUserOption
            {...props}
            organizationId={organizationIds?.[0]}
            isArchived={false}
            onSelect={(workTypeId) => {
                handleSelect(workTypeId, value.id);
                props.onSelect?.(value);
            }}
            user={value}
            portal={usersDropdownDivRef}
            value={selected?.[0]}
        />
    );

    const renderInput = ({ onFocus, onBlur, ...props }: InputRenderProps<any, any>) => {
        const isShowVacation = isVacation && Boolean(selected[1]) && !focus;

        const handleFocus: typeof onFocus = (e) => {
            setFocus(true);
            onFocus?.(e);
        };

        const handleBlur: typeof onBlur = (e) => {
            setFocus(false);
            onBlur?.(e);
        };

        return (
            <>
                {isShowVacation && <span title={description}>🌴</span>}
                <input {...props} onFocus={handleFocus} onBlur={handleBlur} title={description} />
            </>
        );
    };

    const inputContent = (
        <UserWorkTypeChip
            userParams={{ active: true }}
            organizationId={organizationId}
            isArchived={false}
            onSelect={handleSelect}
            selected={selected}
            searchable
            type="soft"
            className={styles.workTypesChip}
            placement="bottomLeft"
            stretch
            dropdownClassName={styles.workTypesPopup}
        >
            Тип работ:
        </UserWorkTypeChip>
    );

    return (
        <div className={classNames(styles.root, className)}>
            <UserSelector
                active
                {...props}
                loading={isLoading}
                organizationIds={organizationIds}
                search={search}
                onSearch={setSearch}
                required={required}
                inputRequired={false}
                inputRef={userInputRef}
                error={error || userErrorMessage || workTypeErrorMessage}
                name={userFieldName}
                placement="bottomRight"
                optionsAfter={<div className={styles.portal} ref={usersDropdownDivRef} />}
                renderOption={renderOption}
                selected={selected?.[1]}
                clearable={clearable}
                onClear={handleClear}
                hasWorkTypes
                dropdownClassName={styles.usersPopup}
                placeholder="Имя исполнителя"
                renderInput={renderInput}
                inputContent={inputContent}
            />
        </div>
    );
};
