import * as React from 'react';
import autobind from 'autobind-decorator';
import * as queryString from 'query-string';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { getBudgetState } from '@store/budgetPage';
import { getTagsState } from '@store/tags';
import { getTableTotalBudget as getExecutionTableTotalBudget } from '@store/budgetExecution';
import { getTableTotalBudget as getPlanningTableTotalBudget } from '@store/budgetPlanning';
import { updatePageOptions } from '@store/common/actions';
import { loadOrganizations, StoreTypes } from '@store/organizations';
import { PageOptions } from '@store/common/types';
import {
    SaveBudgetByStatusUserConfigPayload,
    saveBudgetByStatusUserConfig,
    getBudgetByStatusUserConfig,
} from '@store/userConfig/budget';

import { HeaderView } from '@common/Page';

import { BudgetListPage, HeaderBottom, HeaderTop, PageMode, PageUrl } from './BudgetPage';
import { LoadingStatus } from '@store/commonTypes';

const PAGE_MODE_NAMES_BY_URL = {
    [PageUrl.PlanTable]: PageMode.PlanTable,
    [PageUrl.ExecutionTable]: PageMode.ExecutionTable,
    [PageUrl.CorrectionsList]: PageMode.CorrectionsList,
    [PageUrl.ArchivePage]: PageMode.ArchivePage,
};

interface Props extends MapProps, DispatchProps, RouteComponentProps<RouteParams> {
    pageLabel: string;
    setHeaderView?: (view: HeaderView) => void;
}

interface RouteParams {
    pageName: string;
}

interface MapProps {
    selectedPlanBudgetId: string;
    selectedExecutionBudgetId: string;
    canRenderPlanPage: boolean;
    canRenderExecutionPage: boolean;
    executionTotalBudget: number;
    planningTotalBudget: number;
}

interface DispatchProps {
    loadOrganizations: () => void;
    updatePageOptions: (options: PageOptions) => void;
    saveUserConfig: (payload: SaveBudgetByStatusUserConfigPayload) => void;
}

interface State {
    currentPageMode: PageMode;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class BudgetPageContainer extends React.Component<Props, State> {
    private scrollableContainer: HTMLDivElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            currentPageMode: this.getCurrentPageMode(),
        };

        this.updateHeader();
    }

    public componentDidMount(): void {
        document.body.style.overscrollBehaviorX = 'none';

        this.scrollableContainer = document.getElementById('pageContent') as HTMLDivElement;

        this.props.loadOrganizations();

        const budgetId = this.getBudgetIdFromURLQuery();
        if (budgetId) {
            const pageMode = this.getCurrentPageMode();
            let budgetStatus;
            if (pageMode === PageMode.PlanTable) {
                budgetStatus = BudgetStatus.Plan;
            } else if (pageMode === PageMode.ExecutionTable || pageMode === PageMode.CorrectionsList) {
                budgetStatus = BudgetStatus.Execution;
            }

            this.props.saveUserConfig({
                budgetStatus,
                payload: {
                    budgetId,
                },
            });
        }
    }

    public componentWillUnmount(): void {
        document.body.style.overscrollBehaviorX = null;
    }

    public componentDidUpdate(prevProps: Props): void {
        const pageModeChanged = this.props.match.url !== prevProps.match.url;
        const planningTotalBudgetsChanged = this.props.planningTotalBudget !== prevProps.planningTotalBudget;
        const executionTotalBudgetsChanged = this.props.executionTotalBudget !== prevProps.executionTotalBudget;

        if (pageModeChanged || planningTotalBudgetsChanged || executionTotalBudgetsChanged) {
            this.setState(
                {
                    currentPageMode: this.getCurrentPageMode(),
                },
                () => {
                    this.updateHeader();
                },
            );
        }
    }

    public render(): JSX.Element {
        const { canRenderExecutionPage, canRenderPlanPage, selectedExecutionBudgetId, selectedPlanBudgetId } =
            this.props;
        const { currentPageMode } = this.state;

        return React.createElement(BudgetListPage, {
            selectedPlanBudgetId,
            selectedExecutionBudgetId,
            canRenderPlanPage,
            canRenderExecutionPage,
            currentPage: currentPageMode,
            scrollPageToTop: this.scrollPageToTop,
        });
    }

    private getCurrentPageMode(): PageMode {
        return PAGE_MODE_NAMES_BY_URL[this.props.match.url];
    }

    private updateHeader() {
        const { match, executionTotalBudget, planningTotalBudget } = this.props;

        const currentUrl = match.url;

        const currentPage = this.getCurrentPageMode();

        let budgetStatus: BudgetStatus = null;
        let totalBudget: number = null;

        if (currentPage === PageMode.ExecutionTable || currentPage === PageMode.CorrectionsList) {
            budgetStatus = BudgetStatus.Execution;
            totalBudget = executionTotalBudget;
        } else if (currentPage === PageMode.PlanTable) {
            budgetStatus = BudgetStatus.Plan;
            totalBudget = planningTotalBudget;
        } else {
            console.warn(`Unknown pageMode: ${currentPage}`);
        }

        this.props.setHeaderView({
            firstLine: HeaderTop({ currentUrl, budgetStatus, totalBudget, currentPage }),
            additionalContent: HeaderBottom({ currentUrl, currentPage }),
        });
    }

    @autobind
    private scrollPageToTop() {
        this.scrollableContainer.scrollTo(0, 0);
    }

    private getBudgetIdFromURLQuery(): string | null {
        const {
            location: { search },
        } = this.props;
        const query = queryString.parse(search);

        return (query.budgetId as string) || null;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { budgetId: selectedPlanBudgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Plan);
    const { budgetId: selectedExecutionBudgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
    const { loadingStatus: planLoadingStatus, budgets: planBudgets } = getBudgetState(state, BudgetStatus.Plan);
    // tslint:disable-next-line:max-line-length
    const { loadingStatus: executionLoadingStatus, budgets: executionBudgets } = getBudgetState(
        state,
        BudgetStatus.Execution,
    );

    const tagsAreLoaded = getTagsState(state).loadingStatus === LoadingStatus.LOADED;

    const selectedPlanBudget = Boolean(planBudgets.find((planBudget) => planBudget.id === selectedPlanBudgetId));
    // tslint:disable-next-line:max-line-length
    const selectedExecutionBudget = Boolean(
        executionBudgets.find((executionBudget) => executionBudget.id === selectedExecutionBudgetId),
    );

    const canRenderPlanPage = selectedPlanBudget && planLoadingStatus === LoadingStatus.LOADED && tagsAreLoaded;
    const canRenderExecutionPage =
        selectedExecutionBudget && executionLoadingStatus === LoadingStatus.LOADED && tagsAreLoaded;

    const executionTotalBudget = getExecutionTableTotalBudget(state);
    const planningTotalBudget = getPlanningTableTotalBudget(state);

    return {
        selectedPlanBudgetId,
        selectedExecutionBudgetId,
        canRenderPlanPage,
        canRenderExecutionPage,
        executionTotalBudget,
        planningTotalBudget,
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        updatePageOptions: (options: PageOptions) => dispatch(updatePageOptions(options)),
        loadOrganizations: () =>
            dispatch(
                loadOrganizations({
                    store: StoreTypes.BUDGET_FILTERS,
                }),
            ),
        saveUserConfig: (payload: SaveBudgetByStatusUserConfigPayload) =>
            dispatch(saveBudgetByStatusUserConfig(payload)),
    };
}
