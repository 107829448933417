import * as React from 'react';
import classNames from 'classnames';
import { sanitize } from 'dompurify';

import * as styles from './HighlightQuery.scss';

export enum Theme {
    Bold = 'bold',
}

interface Props {
    theme?: Theme;
    text: string;
    query: string;
    multiline?: boolean;
    elRef?: (el: HTMLDivElement) => void;
}

function joinSplitedWithQuery(
    splited: string[],
    queryRenderer: (index: number) => string | JSX.Element,
): React.ReactNode {
    return splited.reduce((acc, part, i) => [...acc, part, i < splited.length - 1 ? queryRenderer(i) : ''], []);
}

export function HighlightQuery({ theme, text, query, multiline, elRef }: Props): JSX.Element {
    let themeClass: string;
    switch (theme) {
        case Theme.Bold:
            themeClass = styles.root_themeBold;
            break;
    }

    let queryRegexp: RegExp = null;
    if (query) {
        const escapedQuery = query.replace(/(\[|\\|\^|\$|\.|\||\?|\*|\+|\(|\))/g, '\\$&'); // auto-escaping
        queryRegexp = new RegExp(escapedQuery, 'ig');
    }
    const textWithHighlightedMatches =
        text && queryRegexp
            ? text.replace(queryRegexp, `<span style='background: #d7eaff'>${query}</span>`)
            : text || null;

    let title: string = sanitize(text);

    return (
        <div
            className={classNames(styles.root, multiline && styles.root_multiline, themeClass)}
            title={title}
            ref={elRef}
        >
            <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                    __html: sanitize(textWithHighlightedMatches, {
                        ALLOWED_TAGS: [
                            'a',
                            'hr',
                            'br',
                            'span',
                            'b',
                            'strong',
                            'i',
                            'em',
                            'color',
                            'ul',
                            'li',
                            'ol',
                            'div',
                            'p',
                            'h1',
                            'h2',
                            'h3',
                        ],
                        ALLOWED_ATTR: ['target', 'href', 'class', 'title', 'style', 'color'],
                    }),
                }}
            />
        </div>
    );
}
