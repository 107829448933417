// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-CommentAtTheStartOfReplyChain-_CommentAtTheStartOfReplyChain__commentAtTheStartOfReplyChainGoBackButton{margin:12px 0 10px 0;display:flex;align-items:center;gap:10px;font-family:'Open Sans';font-weight:600;font-size:16px;line-height:22px;color:#000000;cursor:pointer}.src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-CommentAtTheStartOfReplyChain-_CommentAtTheStartOfReplyChain__commentAtTheStartOfReplyChainCommentWrapper{position:relative}\n", "",{"version":3,"sources":["webpack://./src/client/modules/activity/pages/creative/Sidebar/widgets/Comments/CommentAtTheStartOfReplyChain/CommentAtTheStartOfReplyChain.scss"],"names":[],"mappings":"AAAA,6KACI,oBAAqB,CAErB,YAAa,CACb,kBAAmB,CACnB,QAAS,CAET,uBAAwB,CACxB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,aAAc,CAEd,cAAe,CAClB,+KAGG,iBAAkB","sourcesContent":[".commentAtTheStartOfReplyChainGoBackButton {\n    margin: 12px 0 10px 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    font-family: 'Open Sans';\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 22px;\n    color: #000000;\n\n    cursor: pointer;\n}\n\n.commentAtTheStartOfReplyChainCommentWrapper {\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentAtTheStartOfReplyChainGoBackButton": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-CommentAtTheStartOfReplyChain-_CommentAtTheStartOfReplyChain__commentAtTheStartOfReplyChainGoBackButton",
	"commentAtTheStartOfReplyChainCommentWrapper": "src-client-modules-activity-pages-creative-Sidebar-widgets-Comments-CommentAtTheStartOfReplyChain-_CommentAtTheStartOfReplyChain__commentAtTheStartOfReplyChainCommentWrapper"
};
export default ___CSS_LOADER_EXPORT___;
