import { compact, intersection, keys } from 'lodash';

import { Constraints, ErrorMessage, Value } from './types';
import { constraintHandlerMap } from './constraintHandlerMap';

interface Params {
    constraints: Constraints;
    value: Value;
}

interface Validate {
    (params: Params): ErrorMessage[];
}

const HREF_REGEX = /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/;

export const validate: Validate = (params) => {
    const constraintsErrors = getConstraintsErrors(params);
    const hrefFormatError = getFormatError(params);

    return compact([...constraintsErrors, hrefFormatError]);
};

const getConstraintsErrors = ({ constraints, value }: Params): string[] => {
    const constraintKeys = intersection(keys(constraints), keys(constraintHandlerMap));
    return compact(
        constraintKeys.map((constraintKey) => constraintHandlerMap[constraintKey](constraints[constraintKey], value)),
    );
};

const getFormatError = ({ value }: Params): string => {
    return HREF_REGEX.test(value) ? undefined : 'Некорректная ссылка';
};
