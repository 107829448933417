import * as React from 'react';
import classNames from 'classnames';

import { useGetTaskQuery, useSetTaskMutation } from '@api';

import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';

import { TextareaOnChangeHandler, Chip, ChipProps, Textarea } from '@sbermarketing/mrm-ui';

import * as styles from './TaskDescription.scss';

export interface TaskDescriptionProps extends Omit<ChipProps, 'defaultValue' | 'onFocus' | 'onBlur'> {
    taskId: string;
    activityId?: number;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLDivElement>;
    onFocus?: React.FocusEventHandler<HTMLTextAreaElement | HTMLDivElement>;
}

export function TaskDescription({ taskId, activityId, loading, onBlur, className, ...props }: TaskDescriptionProps) {
    const { data: { task } = {}, isLoading: isTaskLoading } = useGetTaskQuery({ id: taskId });
    const [description, setDescription] = React.useState(task?.description || '');
    const [setTask, { isLoading: isTaskUpdating }] = useSetTaskMutation();
    const {
        isLoading: isAccessLoading,
        access: [canChange],
    } = useTaskAccess([TaskAccessAction.changeDescription], taskId, activityId);
    const isLoading = loading || isTaskUpdating || isTaskLoading || isAccessLoading;

    React.useEffect(() => {
        setDescription(task?.description || '');
    }, [task?.description]);

    const handleChange: TextareaOnChangeHandler = (value) => {
        setDescription(value);
    };

    const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
        onBlur?.(e);

        if (description !== task?.description) {
            setTask({
                id: taskId,
                description,
            });
        }
    };

    return canChange ? (
        <Textarea
            data-qa-id="TaskDescription"
            {...props}
            className={classNames(styles.root, className)}
            inputClassName={styles.textarea}
            loading={isLoading}
            placeholder="Заполните описание..."
            onBlur={handleBlur}
            minRows={1}
            onChange={handleChange}
            value={description}
        />
    ) : (
        <Chip
            data-qa-id="TaskDescription"
            {...props}
            className={classNames(styles.root, styles.onlyRead, className)}
            loading={isLoading}
        >
            {description || 'Без описания'}
        </Chip>
    );
}
