import * as React from 'react';
import * as style from './GroupCard.scss';
import classNames from 'classnames';

interface Props {
    title: string;
    isActive: boolean;
    onClick?: () => void;
    children: string | JSX.Element | JSX.Element[];
}

const GroupCard = ({ title, isActive, onClick, children }: Props): JSX.Element => {
    return (
        <div
            className={classNames(
                style.root,
                isActive ? style.activeCard : style.notActiveCard,
                onClick && style.pointer,
            )}
            onClick={onClick || (() => {})}
            id={isActive ? 'stagesListGroupCardActive' : null}
        >
            <div className={classNames(style.title, isActive && style.activeTitle)}>{title}</div>
            <div className={style.content}>{children}</div>
        </div>
    );
};
export default GroupCard;
