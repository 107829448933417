import * as React from 'react';

import { Icon, IconType } from 'sber-marketing-ui';
import { Button } from '../Button';
import * as styles from './styles.scss';

interface Props {
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const CreateButton: React.FC<Props> = ({ onClick }) => {
    return (
        <Button onClick={onClick}>
            <div className={styles.icon}>
                <Icon type={IconType.MAGIC_WAND} />
            </div>
            <div className={styles.text}>Сгенерировать автоматически</div>
        </Button>
    );
};
