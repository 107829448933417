import * as React from 'react';

import * as styles from './styles.scss';

interface Props {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Button: React.FC<Props> = ({ children, onClick }) => {
    return (
        <div className={styles.root} onClick={onClick}>
            {children}
        </div>
    );
};
