import { BudgetItem, Month } from '@mrm/budget';
import { MonthItemType } from './hooks/useMonthItem';
import { Itemtype } from './CreateReserve';

export enum FundsFilds {
    plannedFunds = 'plannedFunds',
    reservedFunds = 'reservedFunds',
}

interface GetIdCardDataProps {
    budgetItem: BudgetItem;
}

interface GetIdCardDataResult {
    division: string;
    tool: string;
    direction: string;
    item: string;
    planByYear: number;
    reserveByYear: number;
}

export const getIdCardData = ({ budgetItem }: GetIdCardDataProps): GetIdCardDataResult => {
    return {
        division: budgetItem?.dictionary?.division?.value || '-',
        tool: budgetItem?.dictionary?.tool?.value || '-',
        direction: budgetItem?.dictionary?.direction?.value || '-',
        item: budgetItem?.dictionary?.item?.value || '-',
        planByYear: getSummFromBudgetItem(budgetItem, FundsFilds.plannedFunds),
        reserveByYear: getSummFromBudgetItem(budgetItem, FundsFilds.reservedFunds),
    };
};

export const getSummFromBudgetItem = (budgetItem: BudgetItem, fundsFild: FundsFilds): number =>
    Object.entries(budgetItem[fundsFild])
        .map((item) => item[1])
        .reduce((accumulator, currentValue) => accumulator + currentValue);

export const getZeroIfMinus = (amount: number): number => (amount > 0 ? amount : 0);

export const formatAmount = (value: number) => new Intl.NumberFormat('ru-RU').format(value / 100);

interface GetResultAmountProps {
    allMonthItemList: MonthItemType[];
    itemType: Itemtype;
    month: Month;
}

export const getResultAmount = ({ allMonthItemList, month, itemType }: GetResultAmountProps): number | null => {
    return allMonthItemList
        ?.filter((item) => item.month === month && item.itemType === itemType)
        ?.map((item) => item.value)
        ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};
