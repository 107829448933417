import * as React from 'react';

import { UserResponseParams } from 'sber-marketing-types/frontend';

import { Option, OptionProps, SkeletonText } from '@common/components';
import { useUserVacation } from '@modules/user/hooks';

export interface UserOptionProps extends OptionProps<UserResponseParams> {}

export function UserOption({ value: user, title, titleContent: titleContentRaw, ...props }: UserOptionProps) {
    const { isLoading: isVacationLoading, isVacation, description } = useUserVacation(user?.id, !user?.id);
    const titleContent = isVacationLoading ? (
        <>
            <SkeletonText width={42} /> <SkeletonText width={50} />
        </>
    ) : !titleContentRaw && isVacation ? (
        <span title={description}>🌴 {title}</span>
    ) : (
        titleContentRaw
    );

    return <Option {...props} title={title} titleContent={titleContent} value={user} />;
}
