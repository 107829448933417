import type { PercentageDistributionByWeekCount, PercentageDistribution } from './types';

const percentageDistributionByWeekCount: PercentageDistributionByWeekCount = {
    1: [
        {
            videoWithMinDuration: 50,
            videoWithMaxDuration: 50,
        },
    ],
    2: [
        {
            videoWithMinDuration: 40,
            videoWithMaxDuration: 60,
        },
        {
            videoWithMinDuration: 80,
            videoWithMaxDuration: 20,
        },
    ],
    3: [
        {
            videoWithMinDuration: 30,
            videoWithMaxDuration: 70,
        },
        {
            videoWithMinDuration: 50,
            videoWithMaxDuration: 50,
        },
        {
            videoWithMinDuration: 80,
            videoWithMaxDuration: 20,
        },
    ],
    4: [
        {
            videoWithMinDuration: 20,
            videoWithMaxDuration: 80,
        },
        {
            videoWithMinDuration: 40,
            videoWithMaxDuration: 60,
        },
        {
            videoWithMinDuration: 70,
            videoWithMaxDuration: 30,
        },
        {
            videoWithMinDuration: 100,
            videoWithMaxDuration: 0,
        },
    ],
    5: [
        {
            videoWithMinDuration: 20,
            videoWithMaxDuration: 80,
        },
        {
            videoWithMinDuration: 40,
            videoWithMaxDuration: 60,
        },
        {
            videoWithMinDuration: 50,
            videoWithMaxDuration: 50,
        },
        {
            videoWithMinDuration: 80,
            videoWithMaxDuration: 20,
        },
        {
            videoWithMinDuration: 100,
            videoWithMaxDuration: 0,
        },
    ],
    6: [
        {
            videoWithMinDuration: 0,
            videoWithMaxDuration: 100,
        },
        {
            videoWithMinDuration: 30,
            videoWithMaxDuration: 70,
        },
        {
            videoWithMinDuration: 50,
            videoWithMaxDuration: 50,
        },
        {
            videoWithMinDuration: 50,
            videoWithMaxDuration: 50,
        },
        {
            videoWithMinDuration: 70,
            videoWithMaxDuration: 30,
        },
        {
            videoWithMinDuration: 100,
            videoWithMaxDuration: 0,
        },
    ],
    7: [
        {
            videoWithMinDuration: 0,
            videoWithMaxDuration: 100,
        },
        {
            videoWithMinDuration: 20,
            videoWithMaxDuration: 80,
        },
        {
            videoWithMinDuration: 30,
            videoWithMaxDuration: 70,
        },
        {
            videoWithMinDuration: 50,
            videoWithMaxDuration: 50,
        },
        {
            videoWithMinDuration: 70,
            videoWithMaxDuration: 30,
        },
        {
            videoWithMinDuration: 80,
            videoWithMaxDuration: 20,
        },
        {
            videoWithMinDuration: 100,
            videoWithMaxDuration: 0,
        },
    ],
    8: [
        {
            videoWithMinDuration: 0,
            videoWithMaxDuration: 100,
        },
        {
            videoWithMinDuration: 15,
            videoWithMaxDuration: 85,
        },
        {
            videoWithMinDuration: 30,
            videoWithMaxDuration: 70,
        },
        {
            videoWithMinDuration: 45,
            videoWithMaxDuration: 55,
        },
        {
            videoWithMinDuration: 60,
            videoWithMaxDuration: 40,
        },
        {
            videoWithMinDuration: 75,
            videoWithMaxDuration: 25,
        },
        {
            videoWithMinDuration: 90,
            videoWithMaxDuration: 10,
        },
        {
            videoWithMinDuration: 100,
            videoWithMaxDuration: 0,
        },
    ],
};

export const getPercentageDistributionByWeakCount = (weakCount: number): PercentageDistribution[] => {
    return percentageDistributionByWeekCount[weakCount] || [];
};
