import * as React from 'react';
import classNames from 'classnames';

import { AccordionContext } from './useAccordionContext';
import * as styles from './styles.scss';

interface Props {}

export const Accordion: React.FC<Props> = ({ children }) => {
    const [isExpended, setExpansion] = React.useState(false);

    return (
        <AccordionContext.Provider value={{ isExpended, setExpansion }}>
            <div className={classNames(styles.root, !isExpended && styles.folded)}>{children}</div>
        </AccordionContext.Provider>
    );
};
