import * as React from 'react';
import classNames from 'classnames';

import { Input_redesign as InputUI, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';
import * as style from './styles.scss';

interface Props {
    value?: string;
    placeholder: string;
    readOnly?: boolean;
    autoFocus?: boolean;
    viewComponent?: ViewComponentType;
    validate?: (value: Value) => boolean;
    onValueChange?: (value: Value) => void;
    onFinish?: (params: { value: Value; isValid: boolean }) => void;
}

type Value = string;

type ViewComponentType = React.FC<{ value: Value }>;

export const Input: React.FC<Props> = ({
    value: initValue = '',
    placeholder,
    readOnly = false,
    autoFocus = false,
    viewComponent,
    validate = () => true,
    onValueChange = () => {},
    onFinish = () => {},
}): JSX.Element => {
    const rootRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isValid, setValidation] = React.useState(true);
    const [value, setValue] = React.useState(initValue);
    const [lastClearedValue, setLastClearedValue] = React.useState(null);
    const [editModeEnabled, setEditMode] = React.useState(!value);

    React.useEffect(() => {
        if (value !== initValue) {
            onValueChange(value);
        }
    }, [value]);

    const onAnimationEnd = React.useCallback(() => {
        setValidation(true);
    }, []);

    React.useEffect(() => {
        if (rootRef.current) {
            rootRef.current.addEventListener('animationend', onAnimationEnd);
        }

        return () => {
            rootRef?.current?.removeEventListener('animationend', onAnimationEnd);
        };
    }, [rootRef.current]);

    React.useEffect(() => {
        if (initValue !== value) {
            setValue(initValue);
        }
    }, [initValue]);

    const onInputChange = React.useCallback(
        (newValue: string) => {
            if (newValue !== value) {
                setValue(newValue);
            }
        },
        [value],
    );

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            if (event.key === 'Enter') {
                const isValid = validate(value);

                if (value !== '') {
                    setEditMode(!isValid);
                    setValidation(isValid);
                    !isValid && inputRef.current.focus();
                    onFinish({ value, isValid });
                } else {
                    setValidation(false);
                }
            }
        },
        [value, inputRef.current],
    );

    const onInputBlur: React.FocusEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            const isValid = validate(value);

            if (value !== '') {
                setEditMode(!isValid);
                setValidation(isValid);
            }

            onFinish({ value, isValid });
        },
        [value, validate, onFinish, inputRef.current],
    );

    const onClearButtonClick = React.useCallback(() => {
        setLastClearedValue(value);
        setValue('');
        setEditMode(true);
        setValidation(true);
    }, [value]);

    return (
        <div ref={rootRef} className={classNames(style.root, !isValid && style.invalid)}>
            {!editModeEnabled && (
                <>
                    {viewComponent ? (
                        React.createElement(viewComponent, { value })
                    ) : (
                        <div className={style.value}>{value}</div>
                    )}

                    {!readOnly && (
                        <div className={style.clearButton} onClick={onClearButtonClick}>
                            Стереть
                        </div>
                    )}
                </>
            )}

            {editModeEnabled && (
                <div className={style.input}>
                    <InputUI
                        inputRef={(element) => (inputRef.current = element)}
                        autoFocus={editModeEnabled || autoFocus}
                        value={value}
                        placeholder={placeholder}
                        theme={InputTheme.Borderless}
                        onInputChange={onInputChange}
                        onBlur={onInputBlur}
                        onKeyDown={onKeyDown}
                    />
                </div>
            )}
        </div>
    );
};

export type { ViewComponentType, Props };
