import { Client, MrmClient } from '@api';
import { CreativeRequestByBudgetEntry } from '@sbermarketing/mrm-metacom-client';
import { useEffect, useState } from 'react';

export const useRelatedCreative = ({ budgetItemId }: { budgetItemId: string }) => {
    const [client, setClient] = useState<Client<'creativeRequests' | 'tags' | 'projects' | 'budgets'> | null>(null);
    const [data, setData] = useState<CreativeRequestByBudgetEntry[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        const getClient = async () => {
            const client = await MrmClient.getInstance();
            setClient(client);
        };
        getClient();
    }, []);

    useEffect(() => {
        if (!client || !budgetItemId) return;
        const getData = async () => {
            setIsLoading(true);
            const data = await client.api.creativeRequests.getCreativeRequestsByBudget({
                budgetItemId,
            });
            setData(data);
            setIsLoading(false);
        };
        getData();
    }, [client, budgetItemId]);

    return { data, isLoading };
};
