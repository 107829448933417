import * as React from 'react';
import classNames from 'classnames';
import * as lodash from 'lodash';

import * as style from './EditExecutionBudgetPage.scss';

import {
    ResponsiveLayout,
    LeftSidebar,
    CentralContent,
    RightSidebar,
    OpacityTransition,
    WithTooltip,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    Preloader,
} from 'sber-marketing-ui';

import { MultiReferenceDictionaryApi } from '@api';

import { QuickAccessIcons } from '@common/QuickAccessIcons';

import { ContentNav } from './ContentNav';
import { ActivityBudgetCard } from './ActivityBudgetCard';
import { BudgetList } from './BudgetList';
import { useAutocomplete } from './autocompleteHook';

interface Props {
    budgetId: string;
    dataLoaded: boolean;
    localPreloader: boolean;
    isSaveButtonDisabled: boolean;
    showActivitySuggest: boolean;
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi;
    onCreateButtonClick: () => void;
}

export const EditExecutionBudgetPage = ({
    budgetId,
    dataLoaded,
    localPreloader,
    isSaveButtonDisabled,
    showActivitySuggest,
    multiReferenceDictionaryApi,
    onCreateButtonClick,
}: Props): JSX.Element => {
    const { autocompleteLoading } = useAutocomplete();

    return (
        <div className={style.root}>
            <OpacityTransition start={dataLoaded}>
                <ResponsiveLayout>
                    <LeftSidebar fixed>
                        <div className={style.addLineButton}>
                            <Button theme={ButtonTheme.GhostRounded} onClick={onCreateButtonClick} qaId="addLineButton">
                                Добавить новую строку
                            </Button>
                        </div>

                        <div className={style.contentNav}>
                            <ContentNav />
                        </div>
                    </LeftSidebar>

                    <CentralContent>
                        <div className={classNames(style.activityCard, 'section', 'activity')}>
                            <ActivityBudgetCard budgetId={budgetId} showActivitySuggest={showActivitySuggest} />
                        </div>

                        <div className={style.budgetList}>
                            <BudgetList budgetId={budgetId} multiReferenceDictionaryApi={multiReferenceDictionaryApi} />
                        </div>

                        {(localPreloader || autocompleteLoading) && (
                            <div className={style.localPreloader}>
                                <Preloader />
                            </div>
                        )}
                    </CentralContent>

                    <RightSidebar />
                </ResponsiveLayout>
            </OpacityTransition>
        </div>
    );
};

interface HeaderProps {
    title: string;
    unvalidFieldNames: string[];
    onSaveButtonClick: () => void;
}

export const HeaderTop = ({ title, unvalidFieldNames, onSaveButtonClick }: HeaderProps): JSX.Element => {
    return (
        <div
            className={style.header}
            {...{
                'qa-id': 'editExecutionBudgetPageHeader',
            }}
        >
            <ResponsiveLayout>
                <LeftSidebar />

                <CentralContent>
                    <div className={style.headerCenter}>
                        <div className={style.headerTitle}>{title}</div>
                    </div>
                </CentralContent>

                <RightSidebar>
                    <div className={style.headerRight}>
                        <div className={style.quickAccessIcons}>
                            <QuickAccessIcons />
                        </div>

                        <div className={style.sendButtonWrapper}>
                            <WithTooltip
                                content={<TooltipContent unvalidFieldNames={unvalidFieldNames} />}
                                hidden={lodash.isEmpty(unvalidFieldNames)}
                            >
                                <div className={style.sendButton}>
                                    <Button
                                        theme={ButtonTheme.MainRounded}
                                        onClick={onSaveButtonClick}
                                        qaId="sendButton"
                                    >
                                        Отправить на согласование
                                    </Button>
                                </div>
                            </WithTooltip>
                        </div>
                    </div>
                </RightSidebar>
            </ResponsiveLayout>
        </div>
    );
};

interface ActivityBudgetCreateMessageProps {
    activityId: string;
    budgetId: string;
    name: string;
}

export const ActivityBudgetCreateMessage = ({
    activityId,
    budgetId,
    name,
}: ActivityBudgetCreateMessageProps): JSX.Element => {
    const link = `/budget/execution/${activityId}?budgetId=${budgetId}`;

    return (
        <span className={style.message}>
            Активность &nbsp;
            <a className={style.messageLink} href={link}>
                {name}
            </a>
            &nbsp; создана успешно
        </span>
    );
};

interface BudgetItemSaveMessageProps {
    activityId: string;
    budgetId: string;
    serialNumber: number;
    sapComment: string;
}

export const BudgetItemSaveMessage = ({
    activityId,
    budgetId,
    serialNumber,
    sapComment,
}: BudgetItemSaveMessageProps): JSX.Element => {
    const link = `/budget/execution/${activityId}?budgetId=${budgetId}`;

    return (
        <span className={style.message}>
            Строка &nbsp;
            <a className={style.messageLink} href={link}>
                ID {serialNumber} - {sapComment}
            </a>
            &nbsp; успешно отправлена на согласование
        </span>
    );
};

type TooltipContentProps = Pick<HeaderProps, 'unvalidFieldNames'>;

function TooltipContent({ unvalidFieldNames }: TooltipContentProps): JSX.Element {
    return (
        <div className={style.tooltipContent}>
            <span className={style.tooltipContentBold}>Заполните обязательные поля:</span>

            <ul className={style.tooltipContentList}>
                {unvalidFieldNames.map((fieldName) => (
                    <li key={fieldName}>{fieldName}</li>
                ))}
            </ul>
        </div>
    );
}
