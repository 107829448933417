import * as React from 'react';

import type { CreativeRequest, CreativeRequestCorrectionEntry as Correction } from '@api';

export function useCreativeRequestCorrections(creativeRequest: CreativeRequest) {
    const [corrections, setCorrections] = React.useState<Correction[]>([]);
    const [groupedCorrections, setGroupedCorrections] = React.useState<Record<'plan' | 'reserve', Correction[]>>({
        plan: [],
        reserve: [],
    });
    const [loading, setLoading] = React.useState(true);

    async function loadCorrections() {
        const corrections = (await creativeRequest?.model.findCorrections()) || [];
        const groupedCorrections = groupCorrections(corrections);

        setCorrections(corrections);
        setGroupedCorrections(groupedCorrections);
        setLoading(false);
    }

    React.useEffect(() => {
        loadCorrections();
    }, [creativeRequest]);

    return {
        loading,
        corrections,
        groupedCorrections,
        refetch: loadCorrections,
    };
}

function groupCorrections(corrections: Correction[]): Record<'plan' | 'reserve', Correction[]> {
    const groupedCorrections: Record<'plan' | 'reserve', Correction[]> = {
        plan: [],
        reserve: [],
    };

    corrections.forEach((item) => {
        if (item.type === 'plan_funds_transfer') {
            groupedCorrections.plan.push(item);
        } else if (item.type === 'reserved_funds') {
            groupedCorrections.reserve.push(item);
        }
    });

    return groupedCorrections;
}
