import * as React from 'react';
import * as styles from './styles.scss';

import { CreativeGroupField } from '@modules/activity/pages/autopilot/PlacementList';
import type { ChangeHandler, ChangeParams } from '@modules/activity/pages/autopilot/PlacementList';
import { Field } from '@store/autopilot/creativeForm';

interface Props {
    fields: Field[];
    onChange: ChangeHandler;
}

export const CreativeFieldList: React.FC<Props> = ({ fields, onChange }) => {
    return (
        <div className={styles.root}>
            {fields.map((field) => (
                <CreativeGroupField key={field.id} field={field} onChange={onChange} />
            ))}
        </div>
    );
};

export { ChangeHandler, ChangeParams };
