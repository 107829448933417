import * as React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { Icon, IconType } from 'sber-marketing-ui';

import { useGetTaskQuery, useGetWorkTypesQuery, useSetTaskMutation } from '@api';

import { Utils } from '@common/Utils';
import { DropdownOptions } from '@common/hooks';
import { TaskAccessAction, useIsTaskAuthor, useTaskAccess } from '@modules/task/hooks';
import { useUserVacation } from '@modules/user/hooks';
import { TaskStatus } from '@common/TaskCard/types';

import { Button, Buttons, DropdownTag, DropdownTagProps, Flex, Form, FormProps, Tag } from '@common/components';
import { WorkTypeUserFormSelector } from '@modules/user/components';

import { TaskExecutorForm } from '@modules/task/components';

import * as styles from './TaskExecutor.scss';

export interface TaskExecutorProps extends DropdownTagProps {
    taskId: string;
    actionId?: number;
}

export type TaskExecutorValue = [string | null, number | null];

export const TaskExecutor: React.FC<TaskExecutorProps> = ({
    taskId,
    actionId,
    stretch,
    absolute,
    className,
    loading,
    flex,
    onShowChangeDropdown,
    ...props
}) => {
    const { isLoading: isAuthorLoading, isAuthor } = useIsTaskAuthor(taskId);
    const { isLoading: isTaskLoading, data: { task } = {} } = useGetTaskQuery({ id: taskId });
    const { isLoading: isWorkTypesLoading, data: workTypes } = useGetWorkTypesQuery({});
    const [setTask, { isLoading: isSettingTask }] = useSetTaskMutation();
    const {
        isLoading: isAccessLoading,
        access: [canChange],
    } = useTaskAccess([TaskAccessAction.changeExecutor], taskId, actionId);
    const {
        isLoading: isVacationLoading,
        isVacation,
        description,
    } = useUserVacation(task?.executorId, !task?.executorId);
    const isLoading =
        isAuthorLoading || isTaskLoading || isSettingTask || isWorkTypesLoading || isAccessLoading || isVacationLoading;
    const defaultValue: TaskExecutorValue = [task?.workTypeId, task?.executorId];
    const [value = defaultValue, setValue] = React.useState<TaskExecutorValue | undefined>();
    const dropdownRef = React.useRef<DropdownOptions>();
    const inputRef = React.useRef<HTMLInputElement>();
    const selectedWorkType = workTypes && value && workTypes.find(({ id }) => id === value[0]);
    const showBudgetForm = Utils.addBudgetControlsForSelectedWorkType(selectedWorkType?.name);

    const defaultFormData = { workTypeId: defaultValue[0], executorId: defaultValue[1], ...task?.budgetApproval };

    const renderContent = () => {
        if (task.executor) {
            return (
                <>
                    <span title={description}>
                        {isVacation && '🌴 '}
                        {task.executor.firstName} {task.executor.secondName}
                    </span>{' '}
                    <span className={styles.department}>({task.executor.departmentName})</span>
                </>
            );
        }

        return isAuthor ? 'Выберите' : 'Без исполнителя';
    };

    const content = (
        <>
            <Icon className={styles.icon} svgSize={16} type={IconType.USER_ICON} />
            {renderContent()}
        </>
    );

    if (!canChange || (task?.budgetApproval && task?.status !== TaskStatus.Draft)) {
        return (
            <Tag
                ghost
                aligned
                {...props}
                flex={isLoading || loading || flex}
                loading={isLoading || loading}
                className={classNames(styles.root, className)}
            >
                {content}
            </Tag>
        );
    }

    const handleSelect = (workTypeId: string, userId: number) => {
        setValue([workTypeId, userId]);
    };

    const handleCancel = () => {
        dropdownRef.current.close();
    };

    const handleShowChangeDropdown = (show: boolean) => {
        if (!show) {
            setValue(undefined);
        }

        onShowChangeDropdown?.(show);
    };

    const handleSubmit: FormProps<any>['onSubmit'] = (fields) => {
        if (!isEqual(defaultFormData, fields)) {
            const { workTypeId, executorId, ...budgetApproval } = fields;

            const budgetApprovalToSave = showBudgetForm ? { ...budgetApproval } : null;
            if (budgetApprovalToSave?.budgetItemIds?.[0] === null) {
                budgetApprovalToSave.budgetItemIds = null;
            }

            setTask({
                id: taskId,
                budgetApproval: showBudgetForm ? budgetApprovalToSave : null,
                executionOrder: {
                    workTypeId,
                    executorId,
                },
            });
        }

        dropdownRef.current.close();
    };

    const budgetForm = showBudgetForm ? <TaskExecutorForm padding={[0, 16]} activityId={task?.activityId} /> : null;

    return (
        <DropdownTag
            data-qa-id="TaskExecutor"
            absolute={absolute}
            stretch={stretch}
            tagContent={content}
            {...props}
            loading={isLoading || loading}
            dropdownRef={dropdownRef}
            className={classNames(styles.root, className)}
            dropdownClassName={styles.dropdown}
            onShowChangeDropdown={handleShowChangeDropdown}
        >
            <Form defaultValue={defaultFormData} onSubmit={handleSubmit} gap={12} vertical>
                <Flex vertical gap={12} padding={[12, 16, 0]}>
                    <h3 className={styles.header}>Выбрать исполнителя</h3>
                    <WorkTypeUserFormSelector
                        autoFocus
                        required
                        userFieldName="executorId"
                        workTypeFieldName="workTypeId"
                        inputRef={inputRef}
                        exact
                        searchable
                        selectOnFocus
                        label="Исполнитель"
                        selected={value}
                        onSelect={handleSelect}
                    />
                </Flex>
                {budgetForm}
                <Buttons padding={[0, 16, 12]} justify="flex-end">
                    <Button data-qa-id="TaskExecutor__cancel" type="button" onClick={handleCancel}>
                        Отменить
                    </Button>
                    <Button data-qa-id="TaskExecutor__save" type="submit" view="primary">
                        Сохранить
                    </Button>
                </Buttons>
            </Form>
        </DropdownTag>
    );
};
