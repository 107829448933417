import * as React from 'react';
import * as lodash from 'lodash';

import type { CreativeRequestContract } from '@api';

import { useMrmClient } from '@api';

export function useContracts() {
    const [contracts, setContracts] = React.useState<CreativeRequestContract[]>([]);
    const [grouppedContracts, setGrouppedContracts] = React.useState<
        Record<'lot1' | 'lot2', CreativeRequestContract[]>
    >({ lot1: [], lot2: [] });
    const [loading, setLoading] = React.useState(true);

    const client = useMrmClient();

    React.useEffect(() => {
        async function loadContracts() {
            const contracts = await client.domain.creativeRequests.getContracts();
            const grouppedContracts = await groupContractsByLot(contracts);

            setContracts(contracts);
            setGrouppedContracts(grouppedContracts);
            setLoading(false);
        }

        if (client) {
            loadContracts();
        }
    }, [client]);

    return {
        loading,
        contracts,
        grouppedContracts,
    };
}

async function groupContractsByLot(
    contracts: CreativeRequestContract[],
): Promise<Record<'lot1' | 'lot2', CreativeRequestContract[]>> {
    const groupedContracts: Record<'lot1' | 'lot2', CreativeRequestContract[]> = {
        lot1: [],
        lot2: [],
    };

    await Promise.all(
        contracts.map(async (item) => {
            const lotDictionary = await item.model.lot;

            const lotNumber = lodash.first(lotDictionary.value.match(/\d/g)) as '1' | '2';

            groupedContracts[`lot${lotNumber}`].push(item);
        }),
    );

    groupedContracts.lot1 = lodash.sortBy(groupedContracts.lot1, (item) => item.model.createdAt);
    groupedContracts.lot2 = lodash.sortBy(groupedContracts.lot2, (item) => item.model.createdAt);

    return groupedContracts;
}
