import * as React from 'react';
import classNames from 'classnames';

import { UserResponseParams } from 'sber-marketing-types/frontend';

import { useGetTaskQuery } from '@api';

import { TaskAccessAction, useTaskAccess } from '@modules/task/hooks';
import { useAuthUserId } from '@modules/auth/hooks';
import { useUserVacation } from '@modules/user/hooks';

import { Option, OptionProps } from '@common/components';

import { DeleteTaskParticipantIcon } from '@modules/task/components';

import * as styles from './TaskParticipantOption.scss';

export type TaskParticipantOptionExclusionProps = 'title' | 'subtitle' | 'titleContent' | 'subtitleContent';

export interface TaskParticipantOptionProps
    extends Omit<OptionProps<UserResponseParams>, TaskParticipantOptionExclusionProps> {
    taskId: string;
    activityId?: number;
    deletable?: boolean;
    hideVacation?: boolean;
    onRemove?: () => void;
}

export function TaskParticipantOption({
    value: user,
    taskId,
    activityId,
    deletable,
    hideVacation,
    loading,
    className,
    onRemove,
    ...props
}: TaskParticipantOptionProps) {
    const { isLoading: isTaskLoading, data: { task } = {} } = useGetTaskQuery({ id: taskId });
    const { isLoading: isVacationLoading, isVacation, description } = useUserVacation(user.id, hideVacation);
    const { authUserId } = useAuthUserId();
    const {
        isLoading: isAccessLoading,
        access: [canRemove, canRemoveOwn],
    } = useTaskAccess([TaskAccessAction.removeParticipant, TaskAccessAction.removeOwnParticipant], taskId, activityId);
    const isLoading = loading || isAccessLoading || isTaskLoading || isVacationLoading;

    const isTaskAuthor = task?.authorId === user.id;
    const isTaskExecutor = task?.executorId === user.id;
    const isDeletable = deletable && !isTaskAuthor && !isTaskExecutor;

    const status =
        isTaskAuthor && isTaskExecutor
            ? 'Автор, Исполнитель'
            : isTaskAuthor
            ? 'Автор'
            : isTaskExecutor
            ? 'Исполнитель'
            : '';

    const isDeleteAccess =
        canRemove ||
        (canRemoveOwn && task?.participants.find(({ userId }) => user.id === userId)?.invitingUserId === authUserId) ||
        user.id === authUserId;

    const deleteButton = isDeletable && isDeleteAccess && (
        <span>
            <DeleteTaskParticipantIcon
                onRemove={onRemove}
                className={styles._delete}
                taskId={taskId}
                userId={user.id}
            />
        </span>
    );

    const buttons =
        status || deleteButton ? (
            <div className={styles.buttons}>
                {status ? (
                    <div data-qa-id="TaskParticipantOption__role" className={styles.tag}>
                        {status}
                    </div>
                ) : (
                    deleteButton
                )}
            </div>
        ) : null;

    const titleContent = (
        <div data-qa-id="TaskParticipantOption__title" className={styles.subtitle}>
            <span title={description}>
                {isVacation && '🌴 '}
                {user.firstName} {user.secondName} {isVacation && <span className={styles.tag}>(в отпуске)</span>}
            </span>
            <span>{buttons}</span>
        </div>
    );

    return (
        <Option
            data-qa-id="TaskParticipantOption"
            {...props}
            loading={isLoading}
            className={classNames(styles.root, className)}
            value={user}
            titleContent={titleContent}
            subtitleContent={user.departmentName}
        />
    );
}
