import * as React from 'react';
import * as styles from './IdCard.scss';
import classnames from 'classnames';
import { BudgetItemDirection } from '../PlanTransferModal';
import { formatAmount } from '../utils';

interface Props {
    data: {
        id: string | number;
        name: string;
        division: string;
        tool: string;
        direction: string;
        plan: string | number;
    };
    directionType: BudgetItemDirection;
}

export const IdCard = ({ data, directionType }: Props): JSX.Element => {
    const { id, name, division, tool, direction, plan } = data;

    return (
        <div className={styles.root}>
            <div className={classnames(styles.row, styles.idRow)}>
                <span>ID: {id}</span>
                <span>{name}</span>
            </div>
            <div className={styles.row}>
                <span>МВЗ/Дивизион: </span>
                <span>{division || '-'}</span>
            </div>
            <div className={styles.row}>
                <span>Инструмент: </span>
                <span>{tool || '-'}</span>
            </div>
            <div className={styles.row}>
                <span>Направление: </span>
                <span>{direction || '-'}</span>
            </div>
            <div className={classnames(styles.row, styles.planRow)}>
                <span>
                    {directionType === BudgetItemDirection.from ? 'План-Резерв' : 'Резерв-План'} за год (
                    {directionType === BudgetItemDirection.from ? 'доступно' : 'требуется внести'}):{' '}
                </span>
                <span>{formatAmount(+plan)} руб</span>
            </div>
        </div>
    );
};
