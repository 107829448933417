import * as React from 'react';
import { useSelector } from 'react-redux';
import { DictionaryForBudgetQuery } from '@mrm/dictionary';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { OptionMultiple, OptionProps, FormSelector, FormSelectorProps } from '@common/components';
import { useGetDictionariesForBudgetQuery, DictionaryData } from '@api';

export interface DictionaryFormSelectorProps<M extends OptionMultiple>
    extends Omit<
            FormSelectorProps<
                M,
                OptionProps<DictionaryData>,
                DictionaryData,
                'id',
                string,
                M extends true ? string[] : string
            >,
            'options'
        >,
        Omit<DictionaryForBudgetQuery, 'userId'> {
    skip?: boolean;
}

export function DictionaryFormSelector<M extends OptionMultiple>({
    organizationId,
    loading,
    types,
    ids,
    skip,
    budgetId,
    ...props
}: DictionaryFormSelectorProps<M>) {
    const userId = useSelector((state: StoreState) => getLoginUser(state).attributes.id);

    const { isLoading: isDivisionsLoading, data: divisions } = useGetDictionariesForBudgetQuery(
        {
            userId,
            ids,
            types,
            organizationId,
            budgetId,
        },
        { skip },
    );

    const options: OptionProps<DictionaryData>[] = React.useMemo(
        () =>
            divisions?.map((division) => ({
                title: division.value,
                value: division,
            })),
        [divisions],
    );

    return <FormSelector loading={isDivisionsLoading || loading} {...props} optionId="id" options={options} />;
}
