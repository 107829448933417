import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BudgetStatus } from '@mrm/budget';
import { UserResponseParams as User } from 'sber-marketing-types/frontend';
import type { User as CurrentUser } from '@store/user/types';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user/selector';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import * as CommonStore from '@store/common';
import type { ActivityReferenceMenu } from '@store/budgetExecution/activityReferenceMenu';
import { LoadingStatus } from '@store/budgetExecution/activityReferenceMenu';
import * as activityReferenceMenuStore from '@store/budgetExecution/activityReferenceMenu';
import * as budgetTransferMenuStore from '@store/budgetExecution/budgetTransferMenu';

interface Props extends OwnProps, Partial<MapProps & DispatchProps> {}

interface OwnProps {
    data: {
        selectedActivityId: string;
        filters: any;
    };
    children: (props: ChildrenProps) => JSX.Element;
}

interface ChildrenProps extends MapProps, DispatchProps {}

interface MapProps {
    loading: boolean;
    budgetId: string;
    currentUser: CurrentUser;
    selectedActivityId: number;
    selectedBudgetItemIds: string[];
    expertUsers: User[];
    selectedExpertId: number;
}

interface DispatchProps {
    setActivityReferenceMenu: (data: ActivityReferenceMenu) => void;
    setNotification: (requestNotification: CommonStore.NotificationMessage) => void;
    loadExperts: () => void;
}

export const WithStore: React.FC<Props> = ({ children, ...restProps }) => {
    const prevBudgetIdRef = React.useRef<string>(null);
    const dispatch = useDispatch();

    const currentUser = useSelector(getLoginUser);

    const { current: prevBudgetId } = prevBudgetIdRef;

    const { selectedActivityId, selectedExpertId, selectedBudgetItemIds, loadingStatus } = useSelector(
        (state: StoreState) => activityReferenceMenuStore.getActivityReferenceMenu(state),
    );
    const { budgetId } = useSelector((state: StoreState) => getBudgetByStatusUserConfig(state, BudgetStatus.Execution));
    const {
        experts: { entities: expertUsers },
    } = useSelector((state: StoreState) => budgetTransferMenuStore.getBudgetTransferMenuState(state));

    const setActivityReferenceMenu = React.useCallback((params) => {
        dispatch(activityReferenceMenuStore.setActivityReferenceMenu(params));
    }, []);

    const setNotification = React.useCallback((params) => {
        dispatch(CommonStore.setNotification(params));
    }, []);

    const loadExperts = React.useCallback(() => {
        dispatch(budgetTransferMenuStore.loadExperts(null));
    }, []);

    React.useEffect(() => {
        return () => {
            dispatch(activityReferenceMenuStore.reset());
        };
    }, []);

    React.useEffect(() => {
        const { data } = restProps;

        if (budgetId !== prevBudgetId) {
            prevBudgetIdRef.current = budgetId;
            dispatch(
                activityReferenceMenuStore.init({
                    budgetId,
                    selectedActivityId: data.selectedActivityId,
                    filters: data.filters,
                }),
            );
        }
    }, [budgetId]);

    return React.createElement(children, {
        ...restProps,
        loading: loadingStatus === LoadingStatus.LOADING,
        budgetId,
        currentUser,
        selectedActivityId,
        selectedExpertId,
        selectedBudgetItemIds,
        expertUsers,
        setActivityReferenceMenu,
        setNotification,
        loadExperts,
    });
};

export { ActivityReferenceMenu };
